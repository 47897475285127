import { CustomTextareaProps } from "@/types/form";

export const CustomTextarea = ({ id, placeholder, value, onChange: handleChange, onKeyDown }: CustomTextareaProps) => {
  return (
    <textarea
      className={
        "p-2 w-full h-[10vh] h-max-[25dvh] text-base text-blue-100 rounded-lg resize-none placeholder:text-blue-100 pt-3 pl-4 placeholder:text-sm focus-visible:outline-none"
      }
      id={id}
      value={value}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      {...{ ...(handleChange && { onChange: handleChange }) }}
    />
  );
};

import { gql } from "@apollo/client";

export const COMMERCIAL_INVOICE_DOCUMENT = gql`
  query commercialInvoiceDocument($link: String!, $parserVersion: Int!) {
    commercialInvoiceDocument(documentURL: $link, parserVersion: $parserVersion) {
      fields {
        key
        kind
        value {
          confidence
          rawContent
          valueString
        }
        valueArray {
          key
          kind
          value {
            rawContent
            confidence
            valueString
          }
          valueArray {
            key
            kind
            value {
              confidence
              rawContent
              valueString
            }
          }
        }
      }
    }
  }
`;

export const NIGERIAN_CUSTOMS_DOCUMENT = gql`
  query nigerianCustomsDocument($link: String!, $parserVersion: Int!) {
    nigerianCustomsDocument(documentURL: $link, parserVersion: $parserVersion) {
      fields {
        key
        kind
        value {
          confidence
          rawContent
          valueString
        }
        valueArray {
          key
          kind
          value {
            rawContent
            confidence
            valueString
          }
          valueArray {
            key
            kind
            value {
              confidence
              rawContent
              valueString
            }
          }
        }
      }
    }
  }
`;

export const BL_DOCUMENT = gql`
  query blDocument($link: String!, $parserVersion: Int!) {
    billOfLadingDocument(documentURL: $link, parserVersion: $parserVersion) {
      fields {
        key
        kind
        value {
          confidence
          rawContent
          valueString
        }
        valueArray {
          key
          kind
          value {
            rawContent
            confidence
            valueString
          }
          valueArray {
            key
            kind
            value {
              confidence
              rawContent
              valueString
            }
          }
        }
      }
    }
  }
`;

export const THREE_SP_DOCUMENT = gql`
  query serviceProviderInvoiceDocument(
    $link: String! #$parserVersion: Int!
  ) {
    serviceProviderInvoiceDocument(documentURL: $link) #parserVersion: $parserVersion
    {
      fields {
        key
        kind
        value {
          confidence
          rawContent
          valueString
        }
        valueArray {
          key
          kind
          value {
            rawContent
            confidence
            valueString
          }
          valueArray {
            key
            kind
            value {
              confidence
              rawContent
              valueString
            }
          }
        }
      }
    }
  }
`;

import { AxiosInstance, AxiosPromptInstance } from "@/config";
import { IPrompt } from "@/types/prompt";

export const chatHistory = async () => {
  const { data } = await AxiosInstance.get("/tracey/history");
  return data;
};
export const processPrompt = async (payload: IPrompt) => {
  const { data } = await AxiosPromptInstance.post("/tracey/prompt", payload);
  return data;
};

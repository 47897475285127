import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import Stack from "@mui/material/Stack";
import { ParseServerDate } from "../../utils/helpers";
import { StyledTableCell } from "../StyledComponents";
import { useHistory } from "react-router-dom";
import MoreMenu from "../Tables/MoreMenu";

const ShipmentRow = (props) => {
  const { row, showEventMenu } = props;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset !important" } }}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack>
            <div>
              {row?.containerNumber ? row?.containerNumber : "N/A"}
              {"\n"}
            </div>
            <div>
              <small style={{ textAlign: "center" }}>{`${row?.containerSize ? row?.containerSize : "-"}/${
                row?.containerType ? row?.containerType : "-"
              }`}</small>
            </div>
          </Stack>
        </StyledTableCell>
        <StyledTableCell align="left">{row?.billOfLadingNumber || "N/A"}</StyledTableCell>
        <StyledTableCell align="left">
          {row?.lastEvent
            ? row?.lastEvent.length > 10
              ? `${row?.lastEvent.substr(0, 7)}...`
              : row?.lastEvent.length
            : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          <MoreMenu
            trackingId={row?.trackingId}
            containers={row?.containerNumber}
            billOfLading={row?.billOfLadingNumber}
            isActive={row?.active}
            {...{ showEventMenu }}
          />
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell style={{ paddingBottom: " 0 !important", paddingTop: " 0 !important" }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, mx: "auto" }}>
              <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                  <TableRow sx={{ margin: 1, mx: "10px", width: "fit-content", borderTop: 1 }}>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Container №
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Stack>
                        <div>
                          {row?.containerNumber ? row?.containerNumber : "N/A"}
                          {"\n"}
                        </div>
                        <div>
                          <small>{`${row?.containerSize ? row?.containerSize : "-"}/${
                            row?.containerType ? row?.containerType : "-"
                          }`}</small>
                        </div>
                      </Stack>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Tracked
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className="stage"
                      sx={{
                        color:
                          row?.active === false && row?.active != null
                            ? "red"
                            : row?.createStatus == "CREATED"
                            ? "green"
                            : "orange",
                      }}
                    >
                      {row?.active === false && row?.active != null
                        ? "Closed"
                        : row?.createStatus == "CREATED"
                        ? "Tracked"
                        : "Pending"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Bill of lading
                    </StyledTableCell>
                    <StyledTableCell align="left">{row?.billOfLadingNumber || "N/A"}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Shipping Line
                    </StyledTableCell>
                    <StyledTableCell align="left">{row?.shippingLine || "N/A"}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Stage
                    </StyledTableCell>
                    <StyledTableCell align="left">{row?.lastStage || "N/A"}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Ageing
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.ageing && row.ageing >= 0 ? row.ageing : "N/A"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.status ? (
                        <span
                          style={{
                            color:
                              row?.status?.toLowerCase() == "on-time"
                                ? "#4bd37b"
                                : row?.status?.toLowerCase() == "late"
                                ? "#ff6700"
                                : row?.status?.toLowerCase() == "critical"
                                ? "#ff1000"
                                : "#033b6c",
                            fontWeight: "400",
                          }}
                        >
                          {row?.status}
                        </span>
                      ) : (
                        <span style={{ fontWeight: "400" }}>N/A</span>
                      )}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Last Event
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.lastEvent || "N/A"}
                      <small>
                        &nbsp;-&nbsp;{row?.lastEventTime ? ParseServerDate(row?.lastEventTime, "DD/MM/YYYY") : "N/A"}
                      </small>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="center" colSpan={2} sx={{ borderBottom: "0 !important" }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          history.push(`/dashboard/tracking/trackingid/${row?.trackingId}`);
                        }}
                        sx={{
                          backgroundColor: "#033b6c",
                          mx: "auto",
                        }}
                      >
                        View
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ShipmentRow;

import React from "react";
import {
  CREATE_STRIPE_PAYMENT_INTENT,
  SUBSCRIBE_WITH_STRIPE,
  GET_SUBSCRIPTION_INFO,
  GET_SUBSCRIPTION_MANAGEMENT_LINK,
  CREATE_STRIPE_SUB_SESSION,
} from "../graphql/credits";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setShowNotification, setSubscriptionInfo } from "../redux/data/data.actions";
import useDashboardInfo from "./useDashboardInfo";

const usePayment = () => {
  const dispatch = useDispatch();
  const { logOut } = useDashboardInfo();
  const [createStripePaymentIntent] = useMutation(CREATE_STRIPE_PAYMENT_INTENT, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.createPaymentIntent?.code && data?.createPaymentIntent?.message) {
        if (data?.createPaymentIntent?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.createPaymentIntent?.message, type: "error" }));
      } else {
        return data?.createStripePaymentIntent;
      }
    },
  });

  const handleCreateStripePaymentIntent = async (description, amount, currency) => {
    let res = await createStripePaymentIntent({
      variables: {
        paymentIntent: {
          description,
          amount,
          currency,
        },
      },
    });
    return res.data;
  };

  const [subscribeWithStripe] = useMutation(SUBSCRIBE_WITH_STRIPE, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.subscribeWithStripe?.code && data?.subscribeWithStripe?.message) {
        if (data?.subscribeWithStripe?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.createPaymentIntent?.message, type: "error" }));
      } else {
        if (data?.subscribeWithStripe?.value) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: "Hooray 🎉! your subscription was successful and your account would be credited soon.",
              type: "success",
            })
          );
        } else {
          dispatch(setShowNotification({ isOpen: true, message: data?.subscribeWithStripe?.message, type: "error" }));
        }
        return data?.subscribeWithStripe;
      }
    },
    onError(err) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message:
            "Opps 😞 an error occured while verifying your Payment. kindly reachout to us at sales@atrace.co to rectify the issue. We apologise for any inconvinience caused.",
          type: "error",
        })
      );
    },
  });

  const handleSubscribeWithStripe = async (planCode, amount, currency, paymentReference) => {
    let res = await subscribeWithStripe({
      variables: {
        paymentIntent: {
          planCode,
          paymentReference,
          amount,
          currency,
        },
      },
    });
    return res.data;
  };

  const [getSubscriptionInfo] = useLazyQuery(GET_SUBSCRIPTION_INFO, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.subscriptionMe?.code && data?.subscriptionMe?.message) {
        if (data?.subscriptionMe?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.subscriptionMe?.message, type: "error" }));
      } else {
        dispatch(setSubscriptionInfo(data?.subscriptionMe));
        return data?.getSubscriptionInfo;
      }
    },
    onError(err) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `Opps 😞 an error occured while fetching your subscription information. ${err.message}`,
          type: "error",
        })
      );
    },
  });

  const handleGetSubscriptionInfo = () => getSubscriptionInfo();

  const [getSubMgtLink] = useLazyQuery(GET_SUBSCRIPTION_MANAGEMENT_LINK, {
    onError(err) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `Opps 😞 an error occured while getting your management link. ${err.message}`,
          type: "error",
        })
      );
    },
    onCompleted(data) {
      return data?.getSubscriptionManagementLink;
    },
  });

  const handleGetSubscriptionManagementLink = async (returnURL) => {
    let res = await getSubMgtLink({
      variables: { returnURL },
    });
    return res.data;
  };

  const [createStripeSubscriptionSession] = useMutation(CREATE_STRIPE_SUB_SESSION, {
    errorPolicy: "all",
    onCompleted(data) {
      return data?.createStripeSubscriptionSession;
    },
    onError(err) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `Opps 😞 an error occured while creating your subscription session. ${err.message}`,
          type: "error",
        })
      );
    },
  });
  const handleCreateStripeSubscriptionSession = async (planCode, billingPeriod, currency, successURL, cancelURL) => {
    let res = await createStripeSubscriptionSession({
      variables: {
        input: {
          planCode,
          billingPeriod,
          currency,
          successURL,
          cancelURL,
        },
      },
    });
    return res.data;
  };
  return {
    handleCreateStripePaymentIntent,
    handleSubscribeWithStripe,
    handleGetSubscriptionInfo,
    handleGetSubscriptionManagementLink,
    handleCreateStripeSubscriptionSession,
  };
};

export default usePayment;

import React, { useState } from "react";
import { FormControl,Checkbox,  FormControlLabel, Box,  } from "@mui/material";
import "./acceptTermsPane.scss";
import useProfile from "../../hooks/useProfile";
const AcceptTermsPane = () =>{
    const [acceptTerms, setAcceptTerms] = useState(false);
    const {handleAcceptPlatformTerms} = useProfile();
    const handleChange =()=>{
        setAcceptTerms(true);
        handleAcceptPlatformTerms();
    };
    return(
        <Box zIndex={100} position="fixed" p={1} display={acceptTerms?"none":"block"} boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"} bottom="0" right="0" bgcolor="#feee" left="0" textAlign="center">
            <FormControl>
                <FormControlLabel control={<Checkbox onChange={handleChange} name="acceptTerms" size="large"/>} 
                labelPlacement="end"
                sx={{fontSize:"10px"}}
                label={
                <Box sx={{fontSize:".9rem", lineHeight:"1.2rem"}}>
                    I agree to aTrace{" "}
                    <a href="https://atrace.co/terms/" target="terms" referrerPolicy="no-referer" className="text-red-400 link">
                    Terms &amp; Conditions
                    </a>{" "}
                    and{" "}
                    <a href="https://atrace.co/privacy/" target="privacy" referrerPolicy="no-referer" className="text-red-400 link">
                    {" "}
                    Privacy Policy
                    </a>
                </Box>
                }
                ></FormControlLabel>
            </FormControl> 
        </Box>
   
  );  
};

export default AcceptTermsPane;
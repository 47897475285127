import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { StyledToolTip } from "../StyledComponents";
import TimelineDot from "@mui/lab/TimelineDot";
import { BsExclamation, BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import NestedNavItem from "./NestedNavItem";
import { IoNotificationsOutline } from "react-icons/io5";
// import { useIntercom } from "react-use-intercom";
import { sha256 } from "js-sha256";
import {
  DashboardIcon,
  ServicesIcon,
  SettingsIcon,
  // TrackingIcon,
  // SupportIcon,
  DownloadIcon,
  CostIcon,
  ReportIcon,
} from "../SvgIcons";
import { ParseServerDate } from "../../utils/helpers";
import { NavLink, useHistory } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Notification from "../Modals/Notification";
import Popover from "@mui/material/Popover";

import { FaRegUserCircle } from "react-icons/fa";
import { RiCoinLine } from "react-icons/ri";

import SearchIcon from "../../assets/icons/search-icon.svg";
import MobileLogo from "../../assets/images/dashboard-mobile-logo.png";
import "./dash.scss";
import useDashboardInfo from "../../hooks/useDashboardInfo";
import { Box } from "@mui/material";

export const NavMenu = ({ shipmentOpen, setShipmentOpen, reportOpen, setReportOpen }) => {
  // const { show, boot, update, hardShutdown } = useIntercom();

  const user = useSelector((state) => state.user.userData);
  const [openedName, setOpenedName] = useState("");
  // useEffect(() => {
  //   // load the intercom script when the user is logged in
  //   // hardShutdown();

  //   if (user && user.email && user.id && user.name) {
  //     setTimeout(() => {
  //       boot({
  //         email: user.email,
  //         userId: user.id,
  //         name: user.name,
  //         userHash: sha256.hmac(process.env.REACT_APP_INTERCOM_HASH, user.id),
  //       });
  //     }, 1500);
  //   }
  // }, []);

  return (
    <div className="menu-tabs flex flex-col gap-y-3">
      <NavLink to="/dashboard/overview" activeClassName="active">
        <MenuButton>
          <DashboardIcon color="white" />
          <div className="nav-item">Dashboard</div>
        </MenuButton>
      </NavLink>
      <NestedNavItem
        parentLink="Shipments"
        {...{ openedName, setOpenedName }}
        childLinks={[
          { name: "Track", link: "/dashboard/tracking" },
          { name: "Summary", link: "/dashboard/shipments" },
          { name: "Closed", link: "/dashboard/closedshipments" },
          { name: "Manage", link: "/dashboard/edit/shipment" },
        ]}
      />

      {/* <NavLink to="/dashboard/tracking" activeClassName="active">
        <MenuButton>
          <TrackingIcon color="white" />
          <div className="nav-item">Tracking</div>
        </MenuButton>
      </NavLink> */}

      {/* <NavLink to="/dashboard/costs" activeClassName="active">
        <MenuButton>
          <CostIcon color="white" />
          <div className="nav-item">Costs</div>
        </MenuButton>
      </NavLink> */}

      <NestedNavItem
        parentLink="Costs"
        {...{ openedName, setOpenedName }}
        Icon={CostIcon}
        childLinks={[
          { name: "Analysis", link: "/dashboard/costs/analysis" },
          { name: "Prediction", link: "/dashboard/costs/prediction" },
          { name: "3P Services", link: "/dashboard/3pservices" },
        ]}
      />
      <NestedNavItem
        parentLink="Integrations"
        {...{ openedName, setOpenedName }}
        childLinks={[
          { name: "", disabled: true, altText: "coming soon" },
          //{ name: "CHED", link: "#cs", disabled: true },
          { name: "Customs Decl.", link: "#cs", disabled: true },
          { name: "Health Decl.", link: "#cs", disabled: true },
          { name: "Transit Decl.", link: "#cs", disabled: true },
        ]}
      />
      <NestedNavItem
        parentLink="Reports"
        {...{ openedName, setOpenedName }}
        Icon={ReportIcon}
        childLinks={[
          { name: "Form M Utilization", link: "/dashboard/reports/formm" },
          { name: "Efficiency Report", link: "/dashboard/reports/efficiency" },
        ]}
      />

      <NavLink to="/dashboard/subscription" activeClassName="active">
        <MenuButton>
          <ServicesIcon color="white" />
          <div className="nav-item">Subscription</div>
        </MenuButton>
      </NavLink>

      <div className="py-8 4xl:py-11">
        <div className="border-b border-[#f2f2f233] border-solid w-full h-0"></div>
      </div>

      <NavLink to="/dashboard/settings" activeClassName="active">
        <MenuButton>
          <SettingsIcon color="white" />
          <div className="nav-item">Settings</div>
        </MenuButton>
      </NavLink>

      {/* <MenuButton>
        <SupportIcon color="white" />
        <div className="nav-item" onClick={show}>
          Support
        </div>
      </MenuButton> */}
    </div>
  );
};

export const Search = ({
  onContainerChange,
  onBilChange,
  containerNumber,
  bill,
  searchContainerHandler,
  searchBillHandler,
}) => {
  return (
    <div className="flex items-center search-row-two">
      <div className="flex flex-1 items-center bg-[#ffffff99] search-box px-2 4xl:px-7 border border-solid border-[#ffffff85]  rounded-[10px] form-control-dashboard w-full py-1">
        <input
          type="search"
          onChange={onContainerChange}
          onKeyDown={(e) => {
            e.key === "Enter" && searchContainerHandler();
          }}
          value={containerNumber}
          placeholder="Container no"
          className="h-full w-full outline-none placeholder-[#b3b3b3] lg:placeholder-[#b3b3b3] text-[#1A1A1A] lg:text-[#393939] text-base"
        />
        <button className="hidden lg:block bg-[#033B6C] rounded-[4px] py-2 px-5" onClick={searchContainerHandler}>
          <h1 className="text-base lg:text-sm text-white-100">Search</h1>
        </button>
        <button className="outline-none  lg:hidden" onClick={searchContainerHandler}>
          <img src={SearchIcon} alt="" />
        </button>
      </div>

      <div className="lg:block px-2">
        <h1 className="font-medium text-base text-[##393939]">or</h1>
      </div>

      <div className="flex flex-1 items-center bg-[#ffffff99] search-box px-4 4xl:px-7 border border-solid border-[#ffffff85] rounded-[10px] form-control-dashboard w-full  py-1">
        <input
          type="search"
          onChange={onBilChange}
          onKeyDown={(e) => {
            e.key === "Enter" && searchBillHandler();
          }}
          value={bill}
          placeholder="Bill of lading"
          className="h-full w-full outline-none placeholder-[#b3b3b3] text-[#393939] text-base"
        />
        <button className="hidden lg:block bg-[#033B6C] rounded-[4px] py-2 px-5" onClick={searchBillHandler}>
          <h1 className="text-base lg:text-sm text-white-100">Search</h1>
        </button>
        <button className="outline-none  lg:hidden" onClick={searchBillHandler}>
          <img src={SearchIcon} alt="" />
        </button>
      </div>
    </div>
  );
};

export const NumberOfShipment = ({ number }) => {
  return (
    <div className="w-full h-full py-4 px-9 lg:!p-5 xl:p-4 rounded-[10px] border-2 border-solid border-[#F0F0F0] bg-[#033B6C]  lg:bg-white-100 flex justify-between lg:justify-center items-center relative">
      <div className="absolute top-1 4xl:top-3 right-2 lg:right-[-20px] pb-2"></div>
      <div className="flex lg:flex-col 4xl:flex-row gap-x-6 lg:gap-x-4 items-center justify-center">
        <div className="w-[110px] h-[110px] lg:w-[79.71px] lg:h-[79.71px] 4xl:w-[89.71px] 4xl:h-[89.71px] border-[10px] border-solid border-[#FB8B23] lg:border-[#033B6C] rounded-[50%] bg-white-100 lg:bg-[#033B6C]">
          <div className="w-[90px] h-[90px] lg:w-[64.91px] lg:h-[64.91px] 4xl:w-[74.91px] 4xl:h-[74.91px] border-[10px] border-solid border-white lg:border-[#FB8B23] bg-[#033B6C] lg:bg-white-100 flex items-center justify-center rounded-[50%]">
            <h1 className="font-bold text-base lg:text-sm 4xl:text-base text-[#FB8B23] lg:text-[#033B6C]">{number}</h1>
          </div>
        </div>
        <h1 className="pt-2 4xl:pt-0 text-[#FB8B23] lg:text-[#4A4A4A] font-bold text-sm 4xl:text-base whitespace-nowrap leading-[19px]">
          Total Number Of <br /> active Shipments
        </h1>
      </div>
    </div>
  );
};

export const TimelineExceptions = ({ data }) => {
  return (
    <div>
      {data?.items && data?.items.length === 0 ? (
        <div style={{ marginTop: "70px", paddingBottom: "20px", textAlign: "center", fontSize: "14px", color: "grey" }}>
          You have no exceptions!
        </div>
      ) : null}

      <Timeline>
        {data?.items.map((item, i) => (
          <TimelineItem key={Math.random()}>
            <TimelineSeparator>
              <TimelineDot
                style={{
                  color: "transparent",
                  background: "transparent",
                  boxShadow: "none",
                }}
              >
                <div className="border-[3px] border-solid border-[#033B6C] rounded-[50%] w-[15px] h-[15px]"></div>
              </TimelineDot>
              {i === 3 ? " " : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <div style={{ marginBottom: "15px" }}>
                <div className="flex flex-row justify-between  gap-x-5" style={{ marginBottom: "10px" }}>
                  <h1 className="font-bold text-sm lg:text-[12px] text-[#393939] mb-1">{item?.containerNumber}</h1>

                  <div className="rounded-[10px] bg-[#FB8B23] bg-opacity-10 py-1 px-2 ">
                    <h1 className="font-poppins text-[9px] text-[#FB8B23] flex align-center">
                      {ParseServerDate(item?.date, "DD/MM/YYYY, hh:mm  a")}
                    </h1>
                  </div>
                </div>
                <h1 className="font-normal text-[12px] lg:text-[10px] text-[#4a4a4a] pb-2">{item?.description}</h1>
                <div className="hidden lg:flex items-center gap-x-1 pt-[2px]">
                  <div
                    className="w-[17px] h-[17px] rounded-[50%] flex items-center justify-center bg-[#E88D18]"
                    style={{
                      background: item?.severity.toLowerCase().includes("high")
                        ? "#FF0000"
                        : item?.severity.toLowerCase().includes("medium")
                        ? "#FF6400"
                        : "#E88D18",
                    }}
                  >
                    <BsExclamation className="text-white-100 text-6xl" />
                  </div>
                  <h1
                    className="font-normal text-[10px] leading-tight capitalize text-[#E88D18]"
                    style={{
                      color: item?.severity.toLowerCase().includes("high")
                        ? "#FF0000"
                        : item?.severity.toLowerCase().includes("medium")
                        ? "#FF6400"
                        : "#E88D18",
                    }}
                  >
                    {item?.severity}
                  </h1>
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>

      {/* view more btn */}
      {data?.pageInfo?.hasNextPage && (
        <div className="py-8 lg:py-4 w-full flex justify-center lg:justify-end">
          <button className="bg-[#033B6C] py-3 px-5 rounded-[100px]">
            <h1 className="font-poppins text-[10px] text-white-100">View more</h1>
          </button>
        </div>
      )}
    </div>
  );
};

export const Marker = ({ location, parent }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      component="div"
      className="marker-out w-[28px] h-[28px] border-4 rounded-[100%] p-1 overflow-hidden"
      anchorEl={anchorEl}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      onClick={() => {
        if (parent === "dashboard") {
          history.push(`/dashboard/tracking/container/${location?.shippingLine}/${location?.containerNo}`);
        }
      }}
    >
      <div className="marker-inner w-full h-full rounded-[100%]">
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {parent === "dashboard" ? (
            <div className="marker-content">
              <div className="container232">
                <span>Container NO: </span>
                {location?.containerNo}
              </div>
              <div className="container232">
                <span>Shipping Line: </span>
                {location?.shippingLine}
              </div>
              <div className="container232">{`${location?.location?.state}, ${location?.location?.country}`}</div>
            </div>
          ) : location?.state ? (
            <div className="marker-content">
              <div className="container232">
                <span>State: </span>
                {location?.state}
              </div>
              <div className="container232">
                <span>Country: </span>
                {location?.country}
              </div>
            </div>
          ) : null}
        </Popover>
      </div>
    </Box>
  );
};

export const NotificationCard = ({ icon, title, content, time }) => {
  return (
    <div className="notif-card py-4 px-5 flex gap-x-5 border-b-[0.25px] last:border-b-0 border-solid border-[#4A4A4A]">
      <div className="w-[34px] h-[32px] rounded-md flex items-center justify-center bg-[#033b6c4d]">
        {icon || <DownloadIcon color="#033B6C" />}
      </div>
      <div className="flex-1">
        <h1 className="font-bold text-sm text-[#033B6C]">{title}</h1>
        <h1 className="font-normal text-[12px] text-[#979797] pt-2 leading-[21px]">{content}</h1>
        <h1 className="font-normal text-[10px] text-[#979797] pt-1 leading-[21px]">{time}</h1>
      </div>
    </div>
  );
};

export const HeaderMobile = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const history = useHistory();
  const unreadNotifications = useSelector((state) => state.data.unreadNotifications);
  const userCredits = useSelector((state) => state.data.userCredits);
  const creditInfo = useSelector((state) => state.data.creditInfo);
  const [showMenu, setShowMenu] = useState(false);
  const [currentCursor, setCurrentCursor] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { handleMarkNotificationsAsRead, handleGetNotifications, handleGetUserCreditInformation } = useDashboardInfo();

  const closeNotifications = (notificationId) => {
    setShowNotification(false);
    setNotificationAnchorEl(null);
    const [expiry, setExpiry] = useState(0);
    // handleMarkNotificationsAsRead(notificationId);
  };

  // const { hardShutdown } = useIntercom();

  const loadMoreNotifications = (cursor) => {
    handleGetNotifications(cursor * 10);
  };

  useEffect(() => {
    handleGetUserCreditInformation();
  }, []);

  return (
    <>
      {showNotification ? (
        <Notification
          {...{
            setCurrentCursor,
            currentCursor,
            loadMoreNotifications,
            anchorEl: notificationAnchorEl,
            handleClose: closeNotifications,
          }}
        />
      ) : null}
      <div className="top w-full flex justify-between items-center py-6">
        <div className="logo">
          <img src={MobileLogo} className="w-[57%]" alt="atrace_logo" />
        </div>
        <div className="flex items-center justify-end gap-x-4">
          <div
            className="flex items-center gap-[2px]"
            onClick={() => {
              history.push("/dashboard/subscription");
            }}
          >
            <StyledToolTip>
              <>
                <h1
                  className="text-[#000000] tracking-[-0.428571px] text-base 4xl:text-xl "
                  style={{ color: `${creditInfo?.meter?.trackingsRemaining === 0 ? "#D42222" : "#000"} ` }}
                >
                  {userCredits}
                </h1>
                <RiCoinLine
                  color={`${creditInfo?.meter?.trackingsRemaining === 0 ? "#D42222" : "#D48E00"}`}
                  size="25"
                />
              </>
            </StyledToolTip>
          </div>
          <div
            className="relative cursor-pointer "
            onClick={(event) => {
              setShowNotification(true);
              setNotificationAnchorEl(event.currentTarget);
            }}
          >
            <div className="notification-icon">
              <IoNotificationsOutline color="#033b6c" size="25" />
              {unreadNotifications ? <div className="unread-count">{unreadNotifications}</div> : null}
            </div>
          </div>
          <FaRegUserCircle color="#033b6c" size="30" />
        </div>
      </div>
    </>
  );
};

export const UserDropdown = ({ setResetTraceyChatHistory }) => {
  const userData = useSelector((state) => state.user.userData);
  const unreadNotifications = useSelector((state) => state.data.unreadNotifications);
  const creditInfo = useSelector((state) => state.data.creditInfo);
  const [showMenu, setShowMenu] = useState(false);
  const [currentCursor, setCurrentCursor] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const userCredits = useSelector((state) => state.data.userCredits);

  const {
    handleMarkNotificationsAsRead,
    handleGetNotifications,
    handleMarkSingleNotificationAsRead,
    handleGetUserCreditInformation,
    handleGetUserCredits,
  } = useDashboardInfo();

  const closeNotifications = (notificationId) => {
    setShowNotification(false);
    setNotificationAnchorEl(null);
    if (typeof notificationId === "undefined") {
      handleMarkNotificationsAsRead();
    } else if (notificationId && typeof notificationId === "string") {
      handleMarkSingleNotificationAsRead(notificationId);
    }

    // if(notificationId){
    //
    // }
    // else{}
    // handleMarkNotificationsAsRead(notificationId);
  };

  // const { hardShutdown } = useIntercom();

  const loadMoreNotifications = (cursor) => {
    handleGetNotifications(cursor * 10);
  };

  const handleLogout = () => {
    setShowMenu(false);
    // hardShutdown();
    dispatch({ type: "DESTROY_SESSION" });
    dispatch(setResetTraceyChatHistory(true));
    localStorage.clear();
    sessionStorage.clear();
    history.push("/login");
  };

  useEffect(() => {
    handleGetUserCreditInformation();
    handleGetUserCredits();
  }, []);
  return (
    <>
      {" "}
      {showNotification ? (
        <Notification
          {...{
            setCurrentCursor,
            currentCursor,
            loadMoreNotifications,
            anchorEl: notificationAnchorEl,
            handleClose: closeNotifications,
          }}
        />
      ) : null}
      <div className="flex items-center justify-end gap-x-8">
        <div
          className="flex items-center gap-[2px] cursor-pointer"
          onClick={() => {
            history.push("/dashboard/subscription");
          }}
        >
          <StyledToolTip>
            <>
              <h1
                className="text-[#000000] tracking-[-0.428571px] text-base 4xl:text-xl "
                style={{ color: `${creditInfo?.meter?.trackingsRemaining === 0 ? "#D42222" : "#000"} ` }}
              >
                {userCredits}
              </h1>
              <RiCoinLine color={`${creditInfo?.meter?.trackingsRemaining === 0 ? "#D42222" : "#D48E00"}`} size="25" />
            </>
          </StyledToolTip>
        </div>
        <button className="outline-none flex items-center">
          <FaRegUserCircle color="#033b6c" size="30" style={{ marginRight: "5px" }} />
          <div
            className="flex username"
            onClick={(event) => {
              setShowMenu(true);
              setAnchorEl(event.currentTarget);
            }}
          >
            <h1 className="text-[#4A4A4A] mr-2">{userData?.name || "Welcome"}</h1>
            {showMenu ? (
              <BsFillCaretUpFill color="#4A4A4A" style={{ margin: "auto 0" }} />
            ) : (
              <BsFillCaretDownFill color="#4A4A4A" style={{ margin: "auto 0" }} />
            )}
          </div>
          <Menu
            className="nav-dropdown"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={showMenu}
            onClose={() => {
              setShowMenu(false);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                history.push("/dashboard/settings");
                setShowMenu(false);
              }}
            >
              Account Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </button>
        <div
          className="relative cursor-pointer "
          onClick={(event) => {
            setShowNotification(true);
            setNotificationAnchorEl(event.currentTarget);
          }}
        >
          <div className="notification-icon">
            <IoNotificationsOutline color="#033b6c" size="25" />
            {unreadNotifications ? <div className="unread-count">{unreadNotifications}</div> : null}
          </div>
        </div>
      </div>
    </>
  );
};

// internal
const MenuButton = ({ children, ...props }) => (
  <button
    type="button"
    className="outline-none flex items-center gap-x-3 py-3 px-5 "
    style={{
      background: props.active ? "#FB8B23" : "transparent",
      borderRadius: props.active ? "19px" : 0,
    }}
  >
    {children}
  </button>
);

export const NestedMenuButton = ({ children, ...props }) => (
  <button
    type="button"
    disabled={props.disabled}
    className="outline-none flex items-center gap-x-3 py-2 px-5 nested-menu-button"
    // style={{
    //   borderRadius: props.active ? "17px" : 0,
    //   fontSize: ".95rem",
    //   marginLeft: "2.5rem",
    // }}
  >
    {children}
  </button>
);

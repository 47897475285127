import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Box, Button, Typography, Autocomplete, TextField, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { MdCheck, MdCloudUpload } from "react-icons/md";
import { useFile } from "../../hooks/useFile";
import Mapbg from "../../assets/images/map-bg.svg";
import "./index.scss";
import { useDocument } from "../../hooks/useDocument";
import { cleanUpEnum } from "../../utils/helpers";

const DocumentUpload = ({ setParsedData, cleanUpFxn, parent }) => {
  const { handleCreateDocument, handlePrepareFileUpload } = useDocument();
  const [stage, setStage] = useState(0);
  const { uploadFile } = useFile();
  const [file, setFile] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [fileError, setFileError] = useState("");
  const theme = useTheme();
  const fileTypes = ["PDF"];
  const DOCUMENT_TYPES_ENUM = [
    "SERVICE_PROVIDER_INVOICE",
    "BILL_OF_LADING",
    "CUSTOMS_DECLARATION",
    "PACKING_LIST",
    "COMMERCIAL_INVOICE",
    "DELIVERY_NOTE",
  ];
  const DOCUMENT_TYPES_ENUM_MAP = {
    SERVICE_PROVIDER_INVOICE: "Service Provider Invoice",
    BILL_OF_LADING: "Bill of Lading",
    CUSTOMS_DECLARATION: "Customs Declaration",
    PACKING_LIST: "Packing List",
    COMMERCIAL_INVOICE: "Commercial Invoice",
    DELIVERY_NOTE: "Delivery Note",
  };
  const handleSubmit = async () => {
    setStage(1);
    let filename = file?.name || "file";
    let contentType = file?.type || "application/pdf";
    let contentLength = file?.size || 1024;
    let extension = filename?.split(".")?.length > 1 ? filename?.split(".")?.pop() : "";
    if (!file) {
      setStage(0);
      toast.error("file is required");
      return;
    }

    const url = await handlePrepareFileUpload(file);
    if (!url) {
      setStage(0);
      toast.error("No valid upload URL found.");
    }

    setStage(2);

    try {
      await uploadFile(url, file);
      setStage(3);
    } catch (error) {
      setStage(0);
      return toast.error("Error uploading file");
    }

    try {
      const data = await handleCreateDocument(
        documentName || filename || "",
        parent || "",
        url?.url,
        documentType,
        extension,
        contentLength,
        contentType
      );
      if (data) {
        setParsedData(data);
      }
      cleanUpFxn();
    } catch (error) {
      setStage(0);
      return toast.error("An Error Occured while fetching the file");
    }

    setStage(0);
  };

  return (
    <Box
      display="flex"
      gap={1}
      width="80%"
      maxWidth={"500px"}
      minHeight={"300px"}
      component="form"
      className="base"
      py={2}
      px={1.5}
      flexDirection="column"
      bgcolor="white"
      sx={{
        backgroundImage: `url(${Mapbg})`,
        backgroundSize: "cover",
        borderRadius: "5px",
      }}
    >
      {stage == 0 ? (
        <>
          <Autocomplete
            value={documentType}
            onChange={(_, v) => {
              setDocumentType(v);
            }}
            size="small"
            options={DOCUMENT_TYPES_ENUM}
            getOptionLabel={(option) => DOCUMENT_TYPES_ENUM_MAP[option] || ""}
            renderInput={(params) => <TextField {...params} label="Document Type" />}
          />

          <FileUploader
            classes="drop_area drop_zone"
            dropMessageStyle={{ textAlign: "center" }}
            prompt="Drag and drop a file here or click to select one"
            name="file"
            file=""
            types={fileTypes}
            handleChange={(file) => {
              setFile(file);
              setFileError("");
            }}
            maxSize={2}
            onSizeError={(file) => {
              setFileError("File size is too large, maximum file size is 2MB");
            }}
            onTypeError={(file) => {
              setFileError("Invalid file type, only PDF files are allowed");
            }}
          >
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap=".5rem"
              px="2rem"
              style={{
                cursor: "pointer",
                "&:focus-within": {
                  outline: "none",
                },
              }}
            >
              <span>
                <MdCloudUpload size="3rem" />
              </span>
              <Typography color={fileError ? "error" : "primary"} textAlign="center">
                {" "}
                {fileError
                  ? fileError
                  : file
                  ? "File uploaded successfully. Change upload?"
                  : "Upload or drop a file right here"}
              </Typography>
            </Box>
          </FileUploader>
          <TextField
            size="small"
            fullWidth
            label="Document Name (optional)"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
          />
          <Box width="100%" display="flex" justifyContent="center" alignItems="center" gap={1}>
            <Button fullWidth variant={"outlined"} onClick={cleanUpFxn}>
              Cancel
            </Button>

            <Button disabled={!file || !documentType} fullWidth variant={"contained"} onClick={handleSubmit}>
              Upload
            </Button>
          </Box>
        </>
      ) : (
        <>
          {stage >= 1 && (
            <Box width="100%" display="flex" alignItems="center" gap={1}>
              {stage > 1 ? (
                <MdCheck size={20} color={theme.palette.success.main} />
              ) : (
                <Loader type="ThreeDots" color={theme.palette.primary.main} height={20} width={20} />
              )}
              <Typography variant="body1" fontSize={"1rem"}>
                Generating file link
              </Typography>
            </Box>
          )}
          {stage >= 2 && (
            <Box width="100%" display="flex" alignItems="center" gap={1}>
              {stage > 2 ? (
                <MdCheck size={20} color={theme.palette.success.main} />
              ) : (
                <Loader type="ThreeDots" color={theme.palette.primary.main} height={20} width={20} />
              )}
              <Typography variant="body1" fontSize={"1rem"}>
                Uploading file
              </Typography>
            </Box>
          )}

          {stage >= 3 && (
            <Box width="100%" display="flex" alignItems="center" gap={1}>
              {stage > 3 ? (
                <MdCheck size={20} color={theme.palette.success.main} />
              ) : (
                <Loader type="ThreeDots" color={theme.palette.primary.main} height={20} width={20} />
              )}
              <Typography variant="body1" fontSize={"1rem"}>
                Creating document
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
export default DocumentUpload;

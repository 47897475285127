import React, { useContext } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SubscriptionContext from "../../contexts/SubscriptionContext";
import usePayment from "../../hooks/usePayment";
import { useHistory } from "react-router-dom";
const CheckoutForm = ({ closeUp, clientCode }) => {
  const history = useHistory();
  const { plan } = useContext(SubscriptionContext);
  const { handleSubscribeWithStripe } = usePayment();
  const [loading, setLoading] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setLoading(false);
      return;
    }
    try {
      const res = await stripe.confirmPayment({
        clientSecret: clientCode,
        elements,
        confirmParams: {
          return_url: `${process.env.REACT_APP_URL}/dashboard/subscription/buy/coins?payment_intent&curr=${
            plan.symbl
          }&plncode=${plan.id}&amt=${(plan.amount || 0) * 100}`,
        },
        redirect: "if_required",
      });
      if (res.error) {
        toast.error(`${res.error.message}`);
      } else {
        await handleSubscribeWithStripe(plan.id, plan.amount, plan.symbl, res.paymentIntent.id);
        closeUp();
        history.push("/dashboard/subscription/");
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Stack gap={2} py={1} direction="row-reverse">
        <LoadingButton
          disabled={!stripe || !elements}
          variant="contained"
          loading={loading}
          sx={{ textTransform: "capitalize" }}
          color="primary"
          type="submit"
        >
          Pay {plan.symbl} {plan.amount}
        </LoadingButton>
        <LoadingButton sx={{ textTransform: "capitalize" }} variant="contained" color="error" onClick={() => closeUp()}>
          Cancel
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default CheckoutForm;

export const color = {
  primary: "#033B6C",
  warning: "#FB8B23",
  default: "#4A4A4A",
  dark: "#393939",
};


export const fontSize = {
  tiny: "12px",
  small: "14px",
  medium: "16px",
  large: "18px",
  huge: "24px",
  mega: "48px",
};

export const fontWeight = {
  ultraLight: 100,
  thin: 200,
  light: 300,
  regular: 400,
  medium: 500,
  demiBold: 600,
  bold: 700,
  heavy: 800,
};

export const size = {
  tiny: "2px",
  small: "4px",
  medium: "8px",
  large: "16px",
  huge: "24px",
  enormous: "32px",
};

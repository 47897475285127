import React, { useState } from "react";
import { Grid, Typography, ButtonGroup, Button } from "@mui/material";

const Report = ({
  title = "Efficiency Report",
  renderFilter = () => "filter",
  renderData = () => "data",
  renderChart = () => "chart",
  divisions = true,
}) => {
  const [activeView, setActiveView] = useState("chart");
  const setViewActive = (view) => setActiveView(view);
  return (
    <Grid container flexDirection="column" spacing={1.5} px={1}>
      <Grid item xs={12}>
        <Typography color="primary" variant="body1" fontWeight={600}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>{renderFilter && renderFilter()}</Grid>
      {divisions && (
        <Grid item xs={12}>
          <ButtonGroup variant="outlined" aria-label="data chart switch">
            <Button onClick={() => setViewActive("chart")} color={activeView === "chart" ? "warning" : "primary"}>
              Chart
            </Button>
            <Button onClick={() => setViewActive("data")} color={activeView === "data" ? "warning" : "inherit"}>
              Data
            </Button>
          </ButtonGroup>
        </Grid>
      )}
      <Grid xs={12} item>{activeView === "data" ? renderData && renderData() : renderChart && renderChart()}</Grid>
    </Grid>
  );
};

export default Report;

import { gql } from "@apollo/client";

export const GET_USER_CREDITS = gql`
  query GetAvailableUserCoins {
    subscriptionMe {
      meter {
        trackingsRemaining
      }
    }
  }
`;

export const GET_CREDIT_INFORMATION = gql`
  query subscriptionMe {
    subscriptionMe {
      id
      startDate
      expiryDate
      planCode
      planName
      meter {
        trackingsUsed
        trackingsRemaining
        overageUsed
        overageRemaining
      }
    }
  }
`;

export const CREATE_STRIPE_PAYMENT_INTENT = gql`
  mutation createStripePaymentIntent($paymentIntent: IntentInput!) {
    createStripePaymentIntent(input: $paymentIntent) {
      ... on StringType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const SUBSCRIBE_WITH_STRIPE = gql`
  mutation subscribeWithStripe($paymentIntent: SubscribeStripeInput!) {
    subscribeWithStripe(input: $paymentIntent) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_SUBSCRIPTION_INFO = gql`
  query subscriptionMe {
    subscriptionMe {
      id
      startDate
      expiryDate
      planCode
      planName
      billingPeriod
      processor
      meter {
        trackingsUsed
        trackingsRemaining
        overageUsed
        overageRemaining
        nextReset
      }
    }
  }
`;
export const GET_SUBSCRIPTION_MANAGEMENT_LINK = gql`
  query mgtLink($returnURL: String!) {
    subscriptionManagementLink(returnURL: $returnURL)
  }
`;

export const CREATE_STRIPE_SUB_SESSION = gql`
  mutation session($input: InputCreateStripeSubscriptionSession!) {
    createStripeSubscriptionSession(input: $input)
  }
`;

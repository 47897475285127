import { gql } from "@apollo/client";

export const LOGIN_BY_EMAIL = gql`
  query LoginByEmail($loginRequest: LoginRequest!) {
    login(input: $loginRequest) {
      ... on AuthResponse {
        token
        refresh
        user {
          id
          accountId
          name
          email
          emailVerified
          gender
          profilePicture
          telephone
          companyName
          address
          averageMonthlyShippingCount
          usageInfo
          lastLoggedInAt
          localCurrency
          country
          dateTermsAccepted
          createdAt
          deletedAt
          updatedAt
          status
        }
        accessTokenExpiry
        refreshTokenExpiry
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const SIGN_UP_BY_EMAIL = gql`
  mutation SignupByEmail($emailRequest: SignUpRequest!) {
    signupByEmail(input: $emailRequest) {
      ... on AuthResponse {
        token
        refresh
        user {
          id
          signupMode
          isActive
          name
          email
          emailVerified
          gender
          profilePicture
          telephone
          usageInfo
          averageMonthlyShippingCount
          address
          localCurrency
          country
          dateTermsAccepted
          lastLoggedInAt
          createdAt
          deletedAt
          updatedAt
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const AUTHENTICATE_WITH_GOOGLE = gql`
  mutation authenticateWithGoogle($token: String!) {
    authenticateWithGoogle(token: $token) {
      ... on ResponseAuth {
        user {
          id
          email
          name
          address
          gender
          isActive
          signupMode
          telephone
          profilePicture
          emailVerified
          phoneNumberVerified
          createdAt
          updatedAt
          deletedAt
          lastLoggedInAt
          companyName
          usageInfo
          averageMonthlyShippingCount
          accountId
          localCurrency
          country
          dateTermsAccepted
          status
        }
        token
      }
    }
  }
`;

export const VERIFY_CUSTOMER_EMAIL = gql`
  query VerifyEmail($code: String!, $userID: String!) {
    verifyEmail(code: $code, userID: $userID) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const RESEND_EMAIL_VERIFICATION = gql`
  query ResendEmailVerification($userID: String!) {
    resendEmailVerification(userID: $userID) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  query ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($resetPassRequest: ResetPasswordRequest!) {
    resetPassword(input: $resetPassRequest) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const RESEND_PASSWORD_RESET_EMAIL = gql`
  query ResendPasswordResetEmail($userID: String!) {
    sendResetPasswordVerification(userID: $userID) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const USER_UPDATE_PROFILE = gql`
  mutation UpdateUser($userUpdateRequest: UpdateUserRequest!) {
    updateUser(input: $userUpdateRequest) {
      ... on User {
        id
        email
        name
        address
        gender
        isActive
        signupMode
        telephone
        profilePicture
        emailVerified
        phoneNumberVerified
        createdAt
        updatedAt
        deletedAt
        lastLoggedInAt
        companyName
        usageInfo
        averageMonthlyShippingCount
        accountId
        localCurrency
        country
        dateTermsAccepted
        status
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_ALL_CONTAINERS = gql`
  query getUserContainerNumbers($filter: GetUserContainerNumbersFilters) {
    getUserContainerNumbers(filters: $filter) {
      ... on StringResult {
        result
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;
export const GET_BILL_CONTAINERS = gql`
  query getUserContainerNumbers($filter: GetUserContainerNumbersFilters) {
    getUserContainerNumbers(filters: $filter) {
      ... on StringResult {
        result
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_ALL_MANAGED_VENDORS = gql`
  query getVendoredTrackings {
    getVendoredTrackings {
      ... on TrackedContainerResult {
        result {
          id
          containerNumber
          billOfLadingNumber
          clearingAgent
          transporter
          externalReferenceNumber
          commodityDescription
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const START_TRACKING = gql`
  mutation startTracking($input: [StartTrackingRequest!]!) {
    startTracking(input: $input) {
      ... on StartTrackingResults {
        results {
          container
          success
          message
        }
      }

      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_DASHBOARD_DATA = gql`
  query getDashboardData {
    getDashboardData {
      ... on DashboardSummary {
        totalShipments
        shipmentsByStage {
          stage
          stageName
          shipments
          critical
          onTime
          late
        }
        etaStatus {
          unknown
          onTime
          late
          critical
        }
      }

      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_SERVICE_PROVIDERS = gql`
  query serviceProviders($filter: FilterServiceProviders!) {
    serviceProviders(filter: $filter) {
      id
      name
    }
  }
`;
export const SAVE_TRADE_TERM = gql`
  mutation saveTradeTerms($input: TradeTermsRequest!) {
    saveTradeTerms(input: $input) {
      ... on TradeTermsResult {
        result {
          id
          country
          serviceType
          provider
          currency
          freeTime
          containerSize
          containerType
          slabs {
            startDay
            endDay
            rate
          }
        }
      }

      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const UPDATE_FORM_M = gql`
  mutation editFormMNumber($input: EditFormMNumberData!) {
    editFormMNumber(input: $input) {
      ... on EditFormMResponse {
        success
        results {
          baNumber
          formMNumber
          formMValue {
            rate
            value
            currency
          }
          formMApprovalDate
          formMExpiryDate
          bankName
        }
      }

      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const DELETE_TRADE_TERM = gql`
  mutation deleteTradeTerms($input: String!) {
    deleteTradeTerms(id: $input) {
      ... on TradeTermsResult {
        result {
          id
          country
          serviceType
          provider
          currency
          freeTime
          containerSize
          containerType
          slabs {
            startDay
            endDay
            rate
          }
        }
      }

      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_TRADE_TERMS = gql`
  query getTradeTerms {
    getTradeTerms {
      ... on TradeTermsResult {
        result {
          id
          country
          serviceType
          provider
          currency
          freeTime
          containerSize
          containerType
          slabs {
            startDay
            endDay
            rate
          }
        }
      }

      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_EXCEPTIONS = gql`
  query getExceptions($page: Page!) {
    getExceptions(page: $page) {
      ... on ExceptionsSearchResult {
        items {
          id
          userId
          eventId
          name
          date
          severity
          description
          containerNumber
          createdAt
          updatedAt
        }
        pageInfo {
          page
          size
          hasNextPage
          hasPreviousPage
          totalCount
        }
      }

      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_SHIPMENTS_LAST_LOCATIONS = gql`
  query GetShipmentsLastLocations {
    getShipmentsLastLocations {
      ... on ShipmentDetailsContainerLocationsResult {
        result {
          containerNo
          shippingLine
          location {
            id
            lat
            lng
            name
            state
            country
            countryCode
          }
        }
      }

      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query getNotifications($limit: Int!) {
    getRecentNotifications(limit: $limit) {
      ... on NotificationResult {
        result {
          id
          user_id
          description
          severity
          read
          read_at
          updatedAt
          createdAt
          url
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const COUNT_UNREAD_NOTIFICATIONS = gql`
  query Query {
    countUnreadNotification {
      ... on IntType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation markNotificationsAsRead($count: Int) {
    markNotificationsAsRead(count: $count) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const MARK_SINGLE_NOTIFICATION_AS_READ = gql`
  mutation markNotificationWithIDAsRead($id: String!) {
    markNotificationWithIDAsRead(id: $id) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_COST_ESTIMATES = gql`
  query getEstimatedCosts($projectionDate: String) {
    getEstimatedCosts(projectionDate: $projectionDate) {
      ... on CostResultResult {
        result {
          cost {
            provider {
              code
              name
            }
            serviceType
            events {
              name
              date
            }
            freeTime {
              days
            }
            slabs {
              days
              rate
              amount
            }
            total {
              days
              amount
            }
            currency
          }
          billOfLading
          container
          containerType
          containerSize
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const UPDATE_ETA_STATUS_PREFERENCE = gql`
  mutation setETAStatusPreferences($input: ETAStatusPreferences!) {
    setETAStatusPreferences(input: $input) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const ADD_CONTAINER_EVENT = gql`
  mutation addContainerEvent($input: ContainerEventRequest!) {
    addContainerEvent(input: $input) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;
export const GET_ETA_STATUS_PREFERENCE = gql`
  query getETAStatusPreferences {
    getETAStatusPreferences {
      ... on ETAStatusPreferencesResult {
        originLand {
          late
          critical
        }
        originPort {
          late
          critical
        }
        vessel {
          late
          critical
        }
        destinationPort {
          late
          critical
        }
        destinationLand {
          late
          critical
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;
export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($paymentIntent: PaymentIntentRequest!) {
    createPaymentIntent(input: $paymentIntent) {
      ... on PaymentIntentResponse {
        Reference
        PlanCode
        Amount
        Currency
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_FORM_M_DATA = gql`
  query getFormMData($q: GetFormMFilters) {
    getFormMData(q: $q) {
      ... on FormMDataResult {
        result {
          baNumber
          formMNumber
          formMValue {
            rate
            value
            currency
          }
          formMApprovalDate
          formMExpiryDate
          bankName
          billsOfLading {
            billOfLadingNumber
            blValue {
              rate
              value
              currency
            }
          }
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_ALL_TRACKED_INVOICES = gql`
  query getAllTrackInvoices($trackingNumber: String!) {
    getAllTrackInvoices(trackingNumber: $trackingNumber) {
      ... on InvoiceResponse {
        result {
          id
          serviceType
          serviceProvider
          invoiceNumber
          invoiceValue {
            rate
            value
            currency
          }
          description
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_ALL_USER_TRACKED_INVOICES = gql`
  query getAllUserInvoices($page: Page, $q: InvoiceFilterInput) {
    getAllUserInvoices(page: $page, q: $q) {
      ... on GetAllUserInvoicesResponse {
        success
        message
        data {
          blNumber
          statistics {
            invoiceCount
            totalInvoiceAmount
          }
          invoices {
            id
            serviceType
            serviceProvider
            invoiceNumber
            invoiceValue {
              rate
              value
              currency
            }
            description
          }
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const ADD_INVOICE = gql`
  mutation addInvoice($blNumber: String!, $invoice: Invoice) {
    addInvoice(blNumber: $blNumber, invoice: $invoice) {
      ... on InvoiceResult {
        success
        message
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const EDIT_INVOICE = gql`
  mutation editInvoice($id: String!, $invoice: Invoice) {
    editInvoice(id: $id, invoice: $invoice) {
      ... on InvoiceResult {
        success
        message
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation deleteInvoice($id: String!) {
    deleteInvoice(id: $id) {
      ... on InvoiceResult {
        success
        message
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const DELETE_TRACKING = gql`
  mutation deleteTracking($tId: String!) {
    deleteTracking(trackingId: $tId) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

import ProfileTabs from "./ProfileTabs";
import { color, fontSize, fontWeight } from "../../styles/constants";
import "./settings.scss";

const Settings = () => {
  document.title = `aTrace - Visibility - Setting`;
  return (
    <>
      <Container maxWidth="100%" mt={2}>
        <CssBaseline />
        <Box
          component="h1"
          pt={2}
          pb={2}
          sx={{
            color: color.primary,
            fontSize: fontSize.huge,
            fontWeight: fontWeight.demiBold,
          }}
        >
          Settings
        </Box>
      </Container>
      <ProfileTabs />
    </>
  );
};

export default Settings;

import React from "react";
import { NavMenu } from "../Dash";

import DashboardLogo from "../../assets/images/logo_wide_name_white.png";

const Sidebar = (props) => {
  return (
    <div className="menu-panel h-screen overflow-y-auto overflow-x-hidden flex flex-col items-center px-5 4xl:px-11 bg-[#033B6C]">
      <div className="logo pt-8 pb-8 4xl:py-16">
        <img src={DashboardLogo} alt="atrace_logo" className="w-[75%] mx-auto" />
      </div>

      <NavMenu {...props} />
    </div>
  );
};

export default Sidebar;

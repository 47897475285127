import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, TextField, Autocomplete, Button, Box } from "@mui/material";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import {Fieldset} from "../../../../components/v2/form/fieldset";
import { LoadingButton } from "@mui/lab";
import { useService } from "../../../../hooks/useService";

export const Commodities = ({ data, controls }) => {
  const [counter, setCounter] = useState(0);
  const { handleUpdateCommonHealthDeclaration } = useService();
  const getCreateCommonHealthDeclarationData = JSON.parse(localStorage.getItem("commonHealthDeclaration"));

  const formik = useFormik({
    initialValues: {
      commodities: [
        {
          id: counter,
          commodityCode: "",
          netWeight: "",
          numberOfPackages: "",
          typeOfPackage: "",
          temperature: "",
          containerOrTrailler: "",
        },
      ],
      totalNetWeight: "",
      totalPackages: "",
      totalGrossWeight: "",
      importReason: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, { setSubmitting }) => {
      if (!getCreateCommonHealthDeclarationData) {
        return;
      }

      const { data } = await handleUpdateCommonHealthDeclaration({
        ...getCreateCommonHealthDeclarationData.commonHealthDeclaration,
        commodities: values.commodities.map((item) => {
          return {
            code: item.commodityCode,
            packageKind: item.typeOfPackage,
            netWeight: Number(item.netWeight),
            grossWeight: Number(item.grossWeight),
            numPackages: Number(item.numberOfPackages),
            temperature: item.temperature.toUpperCase(),
          };
        }),
        numPackages: Number(values.totalPackages),
        netWeight: Number(values.totalNetWeight),
        grossWeight: Number(values.totalGrossWeight),
      });

      if (data?.updateCommonHealthDeclaration) {
        localStorage.setItem(
          "commonHealthDeclaration",
          JSON.stringify({ commonHealthDeclaration: data?.updateCommonHealthDeclaration })
        );
        setSubmitting(false);
        controls(2);
      }
    },
  });

  const { values, touched, errors, setValues, setFieldValue, handleSubmit, handleChange, handleBlur, isSubmitting } =
    formik;

  useEffect(() => {
    if (data && Object.values(data)?.length) {
      setValues(data);
    }
  }, [data]);

  return (
    <Box mb="5rem">
      <Box>
        <Grid container spacing={2}>
          {values.commodities?.map((item, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <Fieldset
                key={index}
                fieldsetStyles={{ fontFamily: "monospace" }}
                marginBottom="0"
                legend={`commodity ${`${index + 1}`.padStart(2, "0")}`}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Commodity Code"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      name={`commodities[${item.id}].commodityCode`}
                      value={item?.commodityCode}
                      error={
                        touched?.commodities?.[index]?.commodityCode &&
                        Boolean(errors?.commodities?.[index]?.commodityCode)
                      }
                      helperText={
                        touched?.commodities?.[index]?.commodityCode && errors?.commodities?.[index]?.commodityCode
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Net Weight (Kg)"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      name={`commodities[${index}].netWeight`}
                      value={item?.netWeight}
                      error={
                        touched?.commodities?.[index]?.netWeight && Boolean(errors?.commodities?.[index]?.netWeight)
                      }
                      helperText={touched?.commodities?.[index]?.netWeight && errors?.commodities?.[index]?.netWeight}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Gross Weight (Kg)"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      name={`commodities[${index}].grossWeight`}
                      value={item?.grossWeight}
                      error={
                        touched?.commodities?.[index]?.grossWeight && Boolean(errors?.commodities?.[index]?.grossWeight)
                      }
                      helperText={
                        touched?.commodities?.[index]?.grossWeight && errors?.commodities?.[index]?.grossWeight
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Number of Packages"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      name={`commodities[${index}].numberOfPackages`}
                      value={item?.numberOfPackages}
                      onChange={handleChange}
                      error={
                        touched?.commodities?.[index]?.numberOfPackages &&
                        Boolean(errors?.commodities?.[index]?.numberOfPackages)
                      }
                      helperText={
                        touched?.commodities?.[index]?.numberOfPackages &&
                        errors?.commodities?.[index]?.numberOfPackages
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id={`commodities[${index}].temperature`}
                      name={`commodities[${index}].temperature`}
                      options={["Ambient", "Chilled", "Frozen"]}
                      value={item?.temperature}
                      onChange={(e, value) => setFieldValue(`commodities[${index}].temperature`, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Temperature"
                          InputLabelProps={{ shrink: true }}
                          error={
                            touched?.commodities?.[index]?.temperature && errors?.commodities?.[index]?.temperature
                          }
                          helperText={
                            touched?.commodities?.[index]?.temperature && errors?.commodities?.[index]?.temperature
                          }
                          size="small"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Type of Package"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      name={`commodities[${item.id}].typeOfPackage`}
                      value={item?.typeOfPackage}
                      error={
                        touched?.commodities?.[index]?.typeOfPackage &&
                        Boolean(errors?.commodities?.[index]?.typeOfPackage)
                      }
                      helperText={
                        touched?.commodities?.[index]?.typeOfPackage && errors?.commodities?.[index]?.typeOfPackage
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        onClick={() =>
                          values?.commodities?.length > 1
                            ? setFieldValue(
                                "commodities",
                                values?.commodities.filter(
                                  (commodity) => commodity.id !== values?.commodities?.[index]?.id
                                )
                              )
                            : toast.error("At least one commodity is required")
                        }
                        variant="outlined"
                        color="error"
                        endIcon={<MdDelete />}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Fieldset>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button
              onClick={(_) => {
                setFieldValue("commodities", [
                  ...(Array.isArray(values.commodities) ? values.commodities : []),
                  {
                    id: counter + 1,
                    commodityCode: "",
                    netWeight: "",
                    numberOfPackages: "",
                    typeOfPackage: "",
                    temperature: "",
                    containerOrTrailler: "",
                  },
                ]);
                setCounter(counter + 1);
              }}
              variant="outlined"
            >
              Add Commodity
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Fieldset fieldsetStyles={{ fontFamily: "monospace", marginTop: "2rem" }} legend="Commodity Total">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Total Net Weight (Kg)"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              name="totalNetWeight"
              value={values?.totalNetWeight}
              error={touched?.totalNetWeight && Boolean(errors?.totalNetWeight)}
              helperText={touched?.totalNetWeight && errors?.totalNetWeight}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Total Packages"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              name="totalPackages"
              value={values?.totalPackages}
              error={touched?.totalPackages && Boolean(errors?.totalPackages)}
              helperText={touched?.totalPackages && errors?.totalPackages}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Total Gross Weight (Kg)"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              name="totalGrossWeight"
              value={values?.totalGrossWeight}
              error={touched?.totalGrossWeight && Boolean(errors?.totalGrossWeight)}
              helperText={touched?.totalGrossWeight && errors?.totalGrossWeight}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>

        <Grid container xs={12} mt="1rem" gap="1rem">
          <Grid item>
            <Box>
              <Button
                onClick={() => {
                  controls(0);
                }}
                type="submit"
                variant="contained"
                sx={{ textAlign: "right" }}
              >
                Previous
              </Button>
            </Box>
          </Grid>

          <Grid item>
            <Box>
              <LoadingButton
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                disabled={isSubmitting || !values.totalGrossWeight || !values.totalNetWeight || !values.totalPackages}
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save and Continue
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Fieldset>
    </Box>
  );
};
export default Commodities;

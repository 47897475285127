import { useEffect, useState } from "react";
import { TraceyModal } from "../modals/tracey-modal";
import "../../../styles/global.css";
import TraceyIcon from "@/assets/icons/tracey/tracey-icon.svg"

const TraceyAI = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const getQueryParam = window.location.search;
  const traceyPopup = getQueryParam === "?traceyPopup=false";

  useEffect(() => {
    setModalOpen(!traceyPopup);
  }, [traceyPopup]);

  return (
    <div id="tracey-ai">
      <div className="fixed right-4 bottom-24 lg:bottom-4 z-30">
        <div onClick={() => setModalOpen(true)} className="text-orange-100 cursor-pointer">
          <img src={TraceyIcon} alt="Tracey Icon" />
        </div>
      </div>

      <div className="h-full flex items-center justify-center bg-gray-100">
        <TraceyModal {...{ isOpen: isModalOpen, onClose: () => setModalOpen(false) }} />
      </div>
    </div>
  );
};

export default TraceyAI;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ReactComponent as Download } from "../../assets/icons/cloud-download-icon.svg";
import "./modals.scss";
import { Portal } from "@mui/base";
import { useFile } from "../../hooks/useFile";
import { useModels } from "../../hooks/useModels";
import { fieldsArrayToObject } from "../../utils/helpers";
import mapBg from "../../assets/images/map-bg.svg";
import GenericFileUpload from "../FileUpload/GenericFileUpload";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
// fieldsArrayToObject;
const boxStyle = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -30%)",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  backgroundImage: `url(${mapBg})`,
};

export const ShipmentUploadModal = ({ open, handleClose, handleUploadShipping, setOpen, setPDFData }) => {
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const { handleGetCustomFileLink, handleGetBlFileLink } = useFile();
  const { handleGetNigerianCustomsDocument, handleBlDocument } = useModels();

  const preFillToShipment = (data, _, file, url) => {
    setPDFData(fieldsArrayToObject(data?.fields), file, url);
    handleClose();
  };

  const documentTypeData = [
    {
      name: "Customs SGD",
      linkFxn: handleGetCustomFileLink,
      modelFxn: handleGetNigerianCustomsDocument,
    },
    {
      name: "Bill of Lading",
      linkFxn: handleGetBlFileLink,
      modelFxn: handleBlDocument,
    },
  ];

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle}>
            <div className="button-container">
              <button
                className="upload-button"
                onClick={() => {
                  setOpenFileUpload(open);
                  setOpen(false);
                }}
              >
                Upload PDF
              </button>
            </div>
            <div className="button-container">
              <button className="upload-button" onClick={handleUploadShipping}>
                Upload excel/csv
              </button>
            </div>
            <a href="https://atrace-assets.s3.us-east-2.amazonaws.com/downloads/template_file.xlsx" download>
              <button className="outline-none bg-transparent flex items-center gap-x-[2px]">
                <h1 className="font-medium text-[#033B6C] text-base mr-2">Download template file</h1>
                <Download />
              </button>
            </a>
          </Box>
        </Modal>
      </div>
      <Portal>
        <Modal open={openFileUpload}>
          <Box
            height="100%"
            width="100%"
            display="flex"
            position="relative"
            justifyContent="center"
            alignItems="center"
          >
            <GenericFileUpload
              OPTIONS={documentTypeData}
              setParsedData={preFillToShipment}
              cleanUpFxn={() => setOpenFileUpload(false)}
            />
          </Box>
        </Modal>
      </Portal>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, TextField, Autocomplete, Button, Box, Typography } from "@mui/material";
import { MdDelete, MdOutlineSave } from "react-icons/md";
import {Fieldset} from "../../../../components/v2/form/fieldset";
import { toast } from "react-toastify";
import { countries, findCountry } from "../../../../utils/all-countries";
import { IpaffTable } from "../../../../components/Tables/IpaffTable";
import { useService } from "../../../../hooks/useService";
import { LoadingButton } from "@mui/lab";

export const Traders = ({ data, controls }) => {
  const [counter, setCounter] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const { handleUpdateCommonHealthDeclaration } = useService();
  const getCreateCommonHealthDeclarationData = JSON.parse(localStorage.getItem("commonHealthDeclaration"));

  const formik = useFormik({
    initialValues: {
      tradersSelectedContact: [],
      traders: [],
      placeOfDestinations: [
        {
          id: counter,
          name: "",
          email: "",
          phone: "",
          address1: "",
          address2: "",
          address3: "",
          city: "",
          country: "",
          conditions: "",
          postcode: "",
        },
      ],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, { setSubmitting }) => {
      if (!getCreateCommonHealthDeclarationData) {
        return;
      }

      const { data } = await handleUpdateCommonHealthDeclaration({
        ...getCreateCommonHealthDeclarationData.commonHealthDeclaration,
        traders:
          values.traders.length > 0
            ? values.traders
            : values.tradersSelectedContact.map((item) => {
                return {
                  country: item.country,
                  name: item.name,
                  condition: item.type,
                  email: item.email,
                  phone: item.phone,
                  city: item.city,
                  zipCode: item.postcode,
                  addressLine1: item.address1,
                  addressLine2: item.address2,
                  addressLine3: item.address3,
                };
              }),
        destinations: values.placeOfDestinations.map((item) => {
          return {
            country: item.country,
            name: item.name,
            condition: item.type,
            email: item.email,
            phone: item.phone,
            city: item.city,
            zipCode: item.postcode,
            addressLine1: item.address1,
            addressLine2: item.address2,
            addressLine3: item.address3,
          };
        }),
      });

      if (data?.updateCommonHealthDeclaration) {
        localStorage.setItem(
          "commonHealthDeclaration",
          JSON.stringify({ commonHealthDeclaration: data?.updateCommonHealthDeclaration })
        );
        setSubmitting(false);
        controls(4);
      }
    },
  });

  const { values, touched, errors, setFieldValue, setValues, handleSubmit, handleChange, handleBlur, isSubmitting } =
    formik;
  const clonedSelectedTraders = values?.tradersSelectedContact ? [...values.tradersSelectedContact] : [];

  const headCells = [
    {
      id: "traderType",
      numeric: false,
      disablePadding: false,
      label: "Trader Type",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "address1",
      numeric: false,
      disablePadding: false,
      label: "Address",
    },
    {
      id: "city",
      numeric: false,
      disablePadding: false,
      label: "City",
    },
    {
      id: "postcode",
      numeric: false,
      disablePadding: false,
      label: "Postcode",
    },
    {
      id: "country",
      numeric: false,
      disablePadding: false,
      label: "Country",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const createData = (traderType, name, address1, city, postcode, country) => {
    return {
      traderType,
      name,
      address1,
      city,
      postcode,
      country,
    };
  };

  const rows = values?.tradersSelectedContact?.map((item) =>
    createData(item.traderType, item.name, item.address1, item.city, item.postcode, item.country)
  );

  const pageCount = 5;

  const handleRowClick = (idx, row, isDelete) => {
    setSelectedRow(idx);
    setValues((prevValues) => {
      if (isDelete) {
        const updatedTraders = prevValues.tradersSelectedContact.filter((item) => item.traderType !== row.traderType);
        setSelectedRow(null);
        return { ...prevValues, tradersSelectedContact: updatedTraders };
      } else {
        return { ...prevValues, traders: [row] };
      }
    });
  };

  const handleRowClickSave = (e) => {
    e.preventDefault();
    handleSubmit();

    setValues((prevValues) => {
      const updatedTradersSelectedContact = prevValues.tradersSelectedContact.map((trader) => {
        if (trader.traderType === values.traders[0]?.traderType) {
          return values.traders[0];
        }
        return trader;
      });

      return {
        ...prevValues,
        tradersSelectedContact: updatedTradersSelectedContact,
      };
    });
  };

  useEffect(() => {
    if (data && Object?.values(data)?.length) {
      setValues(data);
    }
  }, [data]);

  return (
    <Box mb="5rem" pt="1rem">
      <Autocomplete
        id="searchTraders"
        name="searchTraders"
        label="Search for traders"
        options={[]}
        value={values.searchTraders}
        onChange={(e, value) => {
          setFieldValue("searchTraders", value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            label="Search for traders"
            size="small"
            error={touched.searchTraders && Boolean(errors.searchTraders)}
            helperText="*If you can't find any saved traders, proceed to add them below."
          />
        )}
      />

      {clonedSelectedTraders?.length > 0 && (
        <Box mt="1rem">
          <Typography variant="h2" color="primary" my="1rem" fontSize="1rem" fontWeight="600">
            List of Selected Contact
          </Typography>
          <IpaffTable {...{ headCells, rows, pageCount, handleRowClick, isTraders: true }} />
        </Box>
      )}

      <Grid container spacing={2} mt="1rem">
        {values?.traders?.map((item, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              <Fieldset
                marginBottom=""
                legendStyles={{ fontFamily: "monospace" }}
                fieldsetStyles={{ margin: 0 }}
                legend={`Trader ${`${index + 1}`.padStart(2, "0")}`}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} xl={4}>
                    <Autocomplete
                      id={`traders[${index}].traderType`}
                      name={`traders[${index}].traderType`}
                      options={["Consignee", "Consignor", "Importer", "Transporter", "Recipients"]}
                      value={item?.traderType}
                      onChange={(e, value) => {
                        setFieldValue(`traders[${index}].traderType`, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Trader Type"
                          size="small"
                          error={touched?.traders?.[index]?.traderType && Boolean(errors?.traders?.[index]?.traderType)}
                          helperText={touched?.traders?.[index]?.traderType && errors?.traders?.[index]?.traderType}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Name"
                      size="small"
                      fullWidth
                      name={`traders[${index}].name`}
                      value={item?.name}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.name && Boolean(errors?.name)}
                      helperText={touched?.name && errors?.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Email"
                      size="small"
                      fullWidth
                      type="email"
                      name={`traders[${index}].email`}
                      value={item?.email}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.email && Boolean(errors?.email)}
                      helperText={touched?.email && errors?.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Phone Number"
                      size="small"
                      fullWidth
                      type="tel"
                      name={`traders[${index}].phone`}
                      value={item?.phone}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.phone && Boolean(errors?.phone)}
                      helperText={touched?.phone && errors?.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address 1"
                      size="small"
                      fullWidth
                      name={`traders[${index}].address1`}
                      value={item?.address1}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.address1 && Boolean(errors?.address1)}
                      helperText={touched?.address1 && errors?.address1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address 2"
                      size="small"
                      fullWidth
                      name={`traders[${index}].address2`}
                      value={item?.address2}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.address2 && Boolean(errors?.address2)}
                      helperText={touched?.address2 && errors?.address2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address 3"
                      size="small"
                      fullWidth
                      name={`traders[${index}].address3`}
                      value={item?.address3}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.address3 && Boolean(errors?.address3)}
                      helperText={touched?.address3 && errors?.address3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="City"
                      size="small"
                      fullWidth
                      name={`traders[${index}].city`}
                      value={item?.city}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.city && Boolean(errors?.city)}
                      helperText={touched?.city && errors?.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Postcode"
                      size="small"
                      fullWidth
                      name={`traders[${index}].postcode`}
                      value={item?.postcode}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.postcode && Boolean(errors?.postcode)}
                      helperText={touched?.postcode && errors?.postcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <Autocomplete
                      id={`traders[${index}].country`}
                      name={`traders[${index}].country`}
                      options={countries}
                      getOptionLabel={(option) => option.name || ""}
                      value={findCountry(item?.country) || ""}
                      onChange={(e, value) => {
                        setFieldValue(`traders[${index}].country`, value?.code);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          size="small"
                          error={touched?.country && Boolean(errors?.country)}
                          helperText={touched?.country && errors?.country}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>

                  {selectedRow !== null && (
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Button
                          type="submit"
                          startIcon={<MdOutlineSave />}
                          onClick={(e) => {
                            handleRowClickSave(e);
                            setSelectedRow(null);
                            setFieldValue(
                              "traders",
                              values?.traders?.filter((e) => e.id !== values?.traders?.[index]?.id)
                            );
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={selectedRow !== null ? 6 : 12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        endIcon={<MdDelete />}
                        onClick={() => {
                          setFieldValue(
                            "traders",
                            values?.traders?.filter((e) => e.id !== values?.traders?.[index]?.id)
                          );
                          selectedRow !== null && setSelectedRow(null);
                        }}
                        variant="outlined"
                        color="error"
                      >
                        Delete
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Fieldset>
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <Button
            disabled={selectedRow !== null}
            onClick={() => {
              setFieldValue("traders", [
                ...(Array.isArray(values.traders) ? values.traders : []),
                {
                  id: counter,
                  traderType: "",
                  name: "",
                  address1: "",
                  address2: "",
                  address3: "",
                  city: "",
                  postcode: "",
                  country: "",
                  email: "",
                  phone: "",
                },
              ]);
              setCounter(counter + 1);
            }}
            variant="outlined"
          >
            Add Trader
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt="2rem">
        {values?.placeOfDestinations?.map((item, index) => (
          <Grid key={index} item xs={12} md={6}>
            <Fieldset
              marginBottom=""
              legendStyles={{ fontFamily: "monospace" }}
              fieldsetStyles={{ margin: 0 }}
              key={index}
              legend={`Place of Destination ${`${index + 1}`.padStart(2, "0")}`}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="Name"
                    size="small"
                    fullWidth
                    name={`placeOfDestinations[${index}].name`}
                    value={item?.name}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.placeOfDestinations?.[index]?.name && Boolean(errors?.placeOfDestinations?.[index]?.name)
                    }
                    helperText={
                      touched?.placeOfDestinations?.[index]?.name && errors?.placeOfDestinations?.[index]?.name
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="Email"
                    size="small"
                    fullWidth
                    type="email"
                    name={`placeOfDestinations[${index}].email`}
                    value={item?.email}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.placeOfDestinations?.[index]?.email &&
                      Boolean(errors?.placeOfDestinations?.[index]?.email)
                    }
                    helperText={
                      touched?.placeOfDestinations?.[index]?.email && errors?.placeOfDestinations?.[index]?.email
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="Phone"
                    size="small"
                    fullWidth
                    type="tel"
                    name={`placeOfDestinations[${index}].phone`}
                    value={item?.phone}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.placeOfDestinations?.[index]?.phone &&
                      Boolean(errors?.placeOfDestinations?.[index]?.phone)
                    }
                    helperText={
                      touched?.placeOfDestinations?.[index]?.phone && errors?.placeOfDestinations?.[index]?.phone
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="Address 1"
                    size="small"
                    fullWidth
                    name={`placeOfDestinations[${index}].address1`}
                    value={item?.address1}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.placeOfDestinations?.[index]?.address1 &&
                      Boolean(errors?.placeOfDestinations?.[index]?.address1)
                    }
                    helperText={
                      touched?.placeOfDestinations?.[index]?.address1 && errors?.placeOfDestinations?.[index]?.address1
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="Address 2"
                    size="small"
                    fullWidth
                    name={`placeOfDestinations[${index}].address2`}
                    value={item?.address2}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.placeOfDestinations?.[index]?.address2 &&
                      Boolean(errors?.placeOfDestinations?.[index]?.address2)
                    }
                    helperText={
                      touched?.placeOfDestinations?.[index]?.address2 && errors?.placeOfDestinations?.[index]?.address2
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="Address 3"
                    size="small"
                    fullWidth
                    name={`placeOfDestinations[${index}].address3`}
                    value={item?.address3}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.placeOfDestinations?.[index]?.address3 &&
                      Boolean(errors?.placeOfDestinations?.[index]?.address3)
                    }
                    helperText={
                      touched?.placeOfDestinations?.[index]?.address3 && errors?.placeOfDestinations?.[index]?.address3
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="City"
                    size="small"
                    fullWidth
                    name={`placeOfDestinations[${index}].city`}
                    value={item?.city}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.placeOfDestinations?.[index]?.city && Boolean(errors?.placeOfDestinations?.[index]?.city)
                    }
                    helperText={
                      touched?.placeOfDestinations?.[index]?.city && errors?.placeOfDestinations?.[index]?.city
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="Postcode"
                    size="small"
                    fullWidth
                    name={`placeOfDestinations[${index}].postcode`}
                    value={item?.postcode}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.placeOfDestinations?.[index]?.postcode &&
                      Boolean(errors?.placeOfDestinations?.[index]?.postcode)
                    }
                    helperText={
                      touched?.placeOfDestinations?.[index]?.postcode && errors?.placeOfDestinations?.[index]?.postcode
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <Autocomplete
                    id={`placeOfDestinations[${index}].country`}
                    name={`placeOfDestinations[${index}].country`}
                    options={countries}
                    getOptionLabel={(option) => option.name || ""}
                    value={findCountry(item?.country) || ""}
                    onChange={(e, value) => {
                      setFieldValue(`placeOfDestinations[${index}].country`, value?.code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        size="small"
                        error={
                          touched?.placeOfDestinations?.[index]?.country &&
                          Boolean(errors?.placeOfDestinations?.[index]?.country)
                        }
                        helperText={
                          touched?.placeOfDestinations?.[index]?.country &&
                          errors?.placeOfDestinations?.[index]?.country
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="Condition"
                    size="small"
                    fullWidth
                    name={`placeOfDestinations[${index}].conditions`}
                    value={item?.conditions}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.placeOfDestinations?.[index]?.conditions &&
                      Boolean(errors?.placeOfDestinations?.[index]?.conditions)
                    }
                    helperText={
                      touched?.placeOfDestinations?.[index]?.conditions &&
                      errors?.placeOfDestinations?.[index]?.conditions
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      endIcon={<MdDelete />}
                      onClick={() =>
                        values?.placeOfDestinations?.length > 1
                          ? setFieldValue(
                              "placeOfDestinations",
                              values?.placeOfDestinations?.filter(
                                (document) => document.id !== values?.placeOfDestinations?.[index]?.id
                              )
                            )
                          : toast.error("At least one place of destination is required")
                      }
                      variant="outlined"
                      color="error"
                    >
                      Delete
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Box display="flex">
            <Button
              onClick={() => {
                setFieldValue("placeOfDestinations", [
                  ...(Array.isArray(values.placeOfDestinations) ? values.placeOfDestinations : []),
                  {
                    id: counter,
                    name: "",
                    email: "",
                    phone: "",
                    address1: "",
                    address2: "",
                    address3: "",
                    city: "",
                    postcode: "",
                    country: "",
                    conditions: "",
                  },
                ]);
                setCounter(counter + 1);
              }}
              variant="outlined"
            >
              Add Place of Destination
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container xs={12} mt="1rem" gap="1rem">
        <Grid item>
          <Box>
            <Button
              onClick={() => {
                controls(2);
              }}
              type="submit"
              variant="contained"
              sx={{ textAlign: "right" }}
            >
              Previous
            </Button>
          </Box>
        </Grid>

        <Grid item>
          <Box>
            <LoadingButton
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={
                isSubmitting ||
                (!values?.traders[0]?.name &&
                  !values?.traders[0]?.country &&
                  values?.tradersSelectedContact?.length === 0)
              }
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save and Continue
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>

      {/* 
      <Grid item xs={12}>
      <Box display="flex">
        <Button
          onClick={() => {
            setFieldValue("consignors", [
              ...values.consignors,
              {
                id: counter,
                name: "",
                address1: "",
                address2: "",
                address3: "",
                city: "",
                country: "",
                postcode: "",
                email: "",
                phone: "",
              },
            ]);
            setCounter(counter + 1);
          }}
          variant="outlined"
        >
          Add Consignor
        </Button>
      </Box>
    </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {values?.consignees?.map((_, index) => (
            <Grid item xs={12} key={index}>
              <Fieldset
                marginBottom=""
                legendStyles={{ fontFamily: "monospace" }}
                fieldsetStyles={{ margin: 0 }}
                key={index}
                legend={`Consignee ${`${index + 1}`.padStart(2, "0")}`}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Name"
                      size="small"
                      fullWidth
                      name={`consignees[${index}].name`}
                      value={values?.consignees?.[index]?.name}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.consignees?.[index]?.name && Boolean(errors?.consignees?.[index]?.name)}
                      helperText={touched?.consignees?.[index]?.name && errors?.consignees?.[index]?.name}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Email"
                      size="small"
                      fullWidth
                      type="email"
                      name={`consignees[${index}].email`}
                      value={values?.consignees?.[index]?.email}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.consignees?.[index]?.email && Boolean(errors?.consignees?.[index]?.email)}
                      helperText={touched?.consignees?.[index]?.email && errors?.consignees?.[index]?.email}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Phone"
                      size="small"
                      fullWidth
                      type="tel"
                      name={`consignees[${index}].phone`}
                      value={values?.consignees?.[index]?.phone}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.consignees?.[index]?.phone && Boolean(errors?.consignees?.[index]?.phone)}
                      helperText={touched?.consignees?.[index]?.phone && errors?.consignees?.[index]?.phone}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address 1"
                      size="small"
                      fullWidth
                      name={`consignees[${index}].address1`}
                      value={values?.consignees?.[index]?.address1}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.consignees?.[index]?.address1 && Boolean(errors?.consignees?.[index]?.address1)}
                      helperText={touched?.consignees?.[index]?.address1 && errors?.consignees?.[index]?.address1}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address 2"
                      size="small"
                      fullWidth
                      name={`consignees[${index}].address2`}
                      value={values?.consignees?.[index]?.address2}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.consignees?.[index]?.address2 && Boolean(errors?.consignees?.[index]?.address2)}
                      helperText={touched?.consignees?.[index]?.address2 && errors?.consignees?.[index]?.address2}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address 3"
                      size="small"
                      fullWidth
                      name={`consignees[${index}].address3`}
                      value={values?.consignees?.[index]?.address3}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.consignees?.[index]?.address3 && Boolean(errors?.consignees?.[index]?.address3)}
                      helperText={touched?.consignees?.[index]?.address3 && errors?.consignees?.[index]?.address3}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="City"
                      size="small"
                      fullWidth
                      name={`consignees[${index}].city`}
                      value={values?.consignees?.[index]?.city}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.consignees?.[index]?.city && Boolean(errors?.consignees?.[index]?.city)}
                      helperText={touched?.consignees?.[index]?.city && errors?.consignees?.[index]?.city}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Postcode"
                      size="small"
                      fullWidth
                      name={`consignees[${index}].postcode`}
                      value={values?.consignees?.[index]?.postcode}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.consignees?.[index]?.postcode && Boolean(errors?.consignees?.[index]?.postcode)}
                      helperText={touched?.consignees?.[index]?.postcode && errors?.consignees?.[index]?.postcode}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <Autocomplete
                      name={`consignees[${index}].country`}
                      options={[]}
                      value={values?.consignees?.[index]?.country}
                      onChange={(e, value) => {
                        setFieldValue(`consignees[${index}].country`, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          size="small"
                          error={touched?.consignees?.[index]?.country && Boolean(errors?.consignees?.[index]?.country)}
                          helperText={touched?.consignees?.[index]?.country && errors?.consignees?.[index]?.country}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>

                  

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        endIcon={<MdDelete />}
                        onClick={(_) =>
                          setFieldValue(
                            "consignees",
                            values?.consignees?.filter((document) => document.id !== values?.consignees?.[index]?.id)
                          )
                        }
                        variant="outlined"
                        color="error"
                      >
                        Delete
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Fieldset>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Box display="flex">
              <Button
                onClick={(_) => {
                  setFieldValue("consignees", [
                    ...values.consignees,
                    {
                      id: counter,
                      name: "",
                      address1: "",
                      address2: "",
                      address3: "",
                      city: "",
                      postcode: "",
                      country: "",
                      email: "",
                      phone: "",
                    },
                  ]);
                  setCounter(counter + 1);
                }}
                variant="outlined"
              >
                Add Consignee
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {values?.importers?.map((_, index) => (
            <Grid item xs={12} key={index}>
              <Fieldset
                marginBottom=""
                legendStyles={{ fontFamily: "monospace" }}
                fieldsetStyles={{ margin: 0 }}
                key={index}
                legend={`Importer ${`${index + 1}`.padStart(2, "0")}`}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Name"
                      size="small"
                      fullWidth
                      name={`importers[${index}].name`}
                      value={values?.importers?.[index]?.name}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.importers?.[index]?.name && Boolean(errors?.importers?.[index]?.name)}
                      helperText={touched?.importers?.[index]?.name && errors?.importers?.[index]?.name}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Email"
                      size="small"
                      fullWidth
                      type="email"
                      name={`importers[${index}].email`}
                      value={values?.importers?.[index]?.email}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.importers?.[index]?.email && Boolean(errors?.importers?.[index]?.email)}
                      helperText={touched?.importers?.[index]?.email && errors?.importers?.[index]?.email}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Phone"
                      size="small"
                      fullWidth
                      type="tel"
                      name={`importers[${index}].phone`}
                      value={values?.importers?.[index]?.phone}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.importers?.[index]?.phone && Boolean(errors?.importers?.[index]?.phone)}
                      helperText={touched?.importers?.[index]?.phone && errors?.importers?.[index]?.phone}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address 1"
                      size="small"
                      fullWidth
                      name={`importers[${index}].address1`}
                      value={values?.importers?.[index]?.address1}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.importers?.[index]?.address1 && Boolean(errors?.importers?.[index]?.address1)}
                      helperText={touched?.importers?.[index]?.address1 && errors?.importers?.[index]?.address1}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address 2"
                      size="small"
                      fullWidth
                      name={`importers[${index}].address2`}
                      value={values?.importers?.[index]?.address2}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.importers?.[index]?.address2 && Boolean(errors?.importers?.[index]?.address2)}
                      helperText={touched?.importers?.[index]?.address2 && errors?.importers?.[index]?.address2}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address 3"
                      size="small"
                      fullWidth
                      name={`importers[${index}].address3`}
                      value={values?.importers?.[index]?.address3}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.importers?.[index]?.address3 && Boolean(errors?.importers?.[index]?.address3)}
                      helperText={touched?.importers?.[index]?.address3 && errors?.importers?.[index]?.address3}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="City"
                      size="small"
                      fullWidth
                      name={`importers[${index}].city`}
                      value={values?.importers?.[index]?.city}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.importers?.[index]?.city && Boolean(errors?.importers?.[index]?.city)}
                      helperText={touched?.importers?.[index]?.city && errors?.importers?.[index]?.city}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Postcode"
                      size="small"
                      fullWidth
                      name={`importers[${index}].postcode`}
                      value={values?.importers?.[index]?.postcode}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.importers?.[index]?.postcode && Boolean(errors?.importers?.[index]?.postcode)}
                      helperText={touched?.importers?.[index]?.postcode && errors?.importers?.[index]?.postcode}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <Autocomplete
                      name={`importers[${index}].country`}
                      options={[]}
                      value={values?.importers?.[index]?.country}
                      onChange={(e, value) => {
                        setFieldValue(`importers[${index}].country`, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          size="small"
                          error={touched?.importers?.[index]?.country && Boolean(errors?.importers?.[index]?.country)}
                          helperText={touched?.importers?.[index]?.country && errors?.importers?.[index]?.country}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>

                  

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        endIcon={<MdDelete />}
                        onClick={(_) =>
                          setFieldValue(
                            "importers",
                            values?.importers?.filter((document) => document.id !== values?.importers?.[index]?.id)
                          )
                        }
                        variant="outlined"
                        color="error"
                      >
                        Delete
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Fieldset>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Box display="flex">
              <Button
                onClick={(_) => {
                  setFieldValue("importers", [
                    ...values.importers,
                    {
                      id: counter,
                      name: "",
                      address1: "",
                      address2: "",
                      address3: "",
                      city: "",
                      postcode: "",
                      country: "",
                      email: "",
                      phone: "",
                    },
                  ]);
                  setCounter(counter + 1);
                }}
                variant="outlined"
              >
                Add Importer
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      */}
    </Box>
  );
};

export default Traders;

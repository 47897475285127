import React, { useState } from "react";
import { NestedMenuButton } from ".";
import { AiOutlineCaretDown, AiOutlineCaretRight } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { ShippingIcon } from "../SvgIcons";
import { useTheme } from "@mui/material";

const NestedNavItem = ({ parentLink, childLinks, openedName, setOpenedName, Icon = ShippingIcon }) => {
  const open = () => parentLink === openedName;
  const theme = useTheme();
  const toggleOpen = () => {
    open() ? setOpenedName("") : setOpenedName(parentLink);
  };
  return (
    <div
      className={`transition-all ease-in-out duration-100 ${
        open() ? "h-full border-2 border-[#fb8b23] rounded-lg" : "h-proper"
      }`}
    >
      <div
        className={`${"flex items-center gap-x-3 px-5 py-2.5 cursor-pointer"} ${
          open() ? "active-shipping-header" : "shipping-header"
        }`}
        onClick={() => toggleOpen()}
      >
        <Icon color="white" />
        <div className={`flex nav-item gap-x-3 items-center `}>
          {parentLink}
          {!open() ? <AiOutlineCaretRight color="white" /> : <AiOutlineCaretDown color="white" />}
        </div>
      </div>
      {open() ? (
        <div className={``}>
          <ul className="menu">
            {childLinks?.map((child, index) =>
              !child.disabled ? (
                <NavLink key={index} to={child?.link} activeClassName={"active-shipping"}>
                  <li className="menu-item pl-1">
                    <NestedMenuButton>
                      <div className="nav-item">
                        {child?.name} <small style={{ color: theme.palette.secondary }}>{child?.altText || ""}</small>
                      </div>
                    </NestedMenuButton>
                  </li>
                </NavLink>
              ) : (
                <div key={index}>
                  <li className="menu-item ">
                    <NestedMenuButton>
                      <div className="nav-item">
                        <span>{child?.name}</span>{" "}
                        <small style={{ color: theme.palette.secondary.main}}>{child.altText}</small>
                      </div>
                    </NestedMenuButton>
                  </li>
                </div>
              )
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default NestedNavItem;

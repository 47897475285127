import { AxiosDocumentUploadInstance, AxiosGenerateUploadInstance } from "@/config";
import { ICreateDocument, IDocumentUpload, IUploadDocumentUrl } from "@/types/document-upload";

export const generateDocumentUploadLink = async (payload: IDocumentUpload) => {
  const { data } = await AxiosGenerateUploadInstance.post("/documents/prepare-upload", payload);
  return data;
};

export const uploadDocumentUrl = async ({ payload, file }: { payload: IUploadDocumentUrl; file: File }) => {
  if (!payload?.signedURL) return null;

  const formData = new FormData();
  formData.append(file?.name || "file", file);

  const { data } = await AxiosDocumentUploadInstance.put(payload?.signedURL, formData, {
    headers: {
      ...payload?.headers,
    },
  });

  return data;
};

export const createDocument = async (payload: ICreateDocument) => {
  const { data } = await AxiosGenerateUploadInstance.post("/documents", payload);
  return data;
};

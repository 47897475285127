import React, { useState } from "react";
import "./App.css";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { from } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingBar from "react-redux-loading-bar";
// import { IntercomProvider } from "react-use-intercom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateAccount from "./pages/Auth/CreateAccount.jsx";
import ForgotPassword from "./pages/Auth/ForgotPassword.jsx";
import Login from "./pages/Auth/Login.jsx";
import ResetPassword from "./pages/Auth/ResetPassword.jsx";
import VerifyEmail from "./pages/Auth/VerifyEmail.jsx";
import Container from "./pages/Loggedin/Container";
import Notification from "./components/ToastNotification/Notification";
import TagManager from "react-gtm-module";
import { setPreviousLocation } from "./redux/user/user.actions";
import TraceyAI from "./components/v2/chatbot/tracey";

const heap = window.heap;
const analytics = window?.analytics;
// const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM;
function App() {
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const history = useHistory();
  analytics?.identify(userData?.id || `anon-${Date.now()}`, {
    name: userData?.name || "Anonymous",
    email: userData?.email || "",
    company: userData?.companyName || "",
    accountId: userData?.accountId || "",
  });
  const theme = createTheme({
    typography: {
      fontFamily: ["Inter"].join(","),
    },
    palette: {
      primary: {
        main: "#033B6C",
        light: "#cdd8e2",
      },
      secondary: {
        main: "#FB8B23",
        light: "#fee8d3",
      },
    },
  });

  const [defaultOptions] = useState({
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  });

  const token = userData?.accessToken;

  const getHeaders = () => {
    const headers = {};
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    return headers;
  };

  // eslint-disable-next-line new-cap
  const apolloLink = new createUploadLink({
    uri: `${process.env.REACT_APP_BACKEND_URL}/graphql/v1`,
    headers: getHeaders(),
  });

  const client = new ApolloClient({
    link: from([apolloLink]),
    cache: new InMemoryCache(),
    fetchOptions: {
      mode: "cors",
    },
    connectToDevTools: true,
    defaultOptions: defaultOptions,
  });

  if (process.env.NODE_APP === "production") {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
    });
  }

  heap?.load(process.env.REACT_APP_HEAP_APP_ID);

  return (
    <>
      {/* <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}> */}
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Notification />
          <div className="App">
            <TraceyAI />
            <div className="loading-bar">
              <LoadingBar
                style={{
                  backgroundColor: "#033B6C",
                  height: "2px",
                  position: "fixed",
                  top: "0",
                }}
              />
            </div>
            <Switch>
              <Route exact path="/create-account">
                <CreateAccount />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/reset-password">
                <ResetPassword />
              </Route>
              <Route exact path="/verify-email">
                <VerifyEmail />
              </Route>

              {/*
               <Route exact path="/create-account">
                {userData ? <Redirect to="/dashboard/overview" /> : <CreateAccount />}
              </Route>
              <Route exact path="/forgot-password">
                {userData ? <Redirect to="/dashboard/overview" /> : <ForgotPassword />}
              </Route>
              <Route exact path="/login">
                {userData ? <Redirect to="/dashboard/overview" /> : <Login />}
              </Route>
              <Route exact path="/reset-password">
                {userData ? <Redirect to="/dashboard/overview" /> : <ResetPassword />}
              </Route>
              <Route exact path="/verify-email">
                {userData ? <Redirect to="/dashboard/overview" /> : <VerifyEmail />}
              </Route>
              */}
              <Route path="/dashboard">
                {userData ? (
                  <Container />
                ) : (
                  dispatch(setPreviousLocation(history?.location?.pathname || "")) && <Redirect to="/login" />
                )}
              </Route>
              <Route exact path="/">
                {userData ? <Redirect to="/dashboard/overview" /> : <Login />}
              </Route>
            </Switch>
          </div>
          <ToastContainer autoClose={2500} style={{ width: "500px" }} />
        </ThemeProvider>
      </ApolloProvider>
      {/* </IntercomProvider> */}
    </>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import PieChart from "../../components/Charts";
import { useSelector } from "react-redux";
import { shipmentData, shipmentOptions, doughnutStatus, etaData, etaOptions } from "../../utils/charts";
import { useShipment } from "../../hooks/useShipment";
import { NumberOfShipment, TimelineExceptions } from "../../components/Dash";
import { useHistory } from "react-router-dom";
import { Maps } from "../../components/Map/Map";
import NotificationModal from "../../components/NotificationModal";
import { SearchBillContainer } from "../../components/SearchBillContainer/SearchBillContainer";
import "./dashboard.scss";
import Loader from "../../components/Loader/Loader";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS } from "chart.js";
import { Alert, Collapse, IconButton } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { MdClose } from "react-icons/md";
import AcceptTermsPane from "../../components/AcceptTermsPane/AcceptTermsPane";
import UpdateUserModal from "../../components/Modals/UpdateUserModal";
import useDashboardInfo from "../../hooks/useDashboardInfo";
ChartJS.register(ChartDataLabels);

const index = () => {
  const history = useHistory();
  const { handleShipmentWaypoints } = useShipment();
  const { handleGetDashboardData } = useDashboardInfo();
  document.title = `aTrace - Visibility - Dashboard`;
  const [notificationModal, setNotificationModal] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const [openAlert, setOpenAlert] = useState(true);
  const dashboardData = useSelector((state) => state.data.dashboardData);
  const exceptions = useSelector((state) => state.data.exceptions);
  const shipmentWaypointsData = useSelector((state) => state.data.shipmentWaypoints);
  const handleNotificationModal = () => setNotificationModal(!notificationModal);
  const [shipmentDataCopy, setShipmentDataCopy] = useState(null);
  const [etaDataCopy, setEtaDataCopy] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const incompleteReg = !userData?.localCurrency || !userData?.country || !userData?.telephone;
  const termNotAccepted = !userData.dateTermsAccepted;
  const key = ["On-time", "Late", "Critical", "Unknown"];

  const STAGES = ["", "Origin Land", "Origin Port", "Ocean", "Destination Port", "Destination Land"];

  useEffect(() => {
    let shipmentData1 = shipmentData;
    let etaData1 = etaData;
    if (dashboardData?.etaStatus) {
      etaData1.datasets[0].data = dashboardData?.etaStatus;

      setEtaDataCopy(etaData1);
    }

    if (dashboardData?.shipmentsByStage) {
      shipmentData1.datasets[0].data = dashboardData?.shipmentsByEta?.onTime || [];
      shipmentData1.datasets[1].data = dashboardData?.shipmentsByEta?.late || [];
      shipmentData1.datasets[2].data = dashboardData?.shipmentsByEta?.critical || [];
      setShipmentDataCopy(shipmentData1);
    }
  }, [dashboardData]);

  useEffect(() => {
    handleShipmentWaypoints();
    handleGetDashboardData();
  }, []);

  useEffect(() => {
    setOpenModal(true);
  }, [openModal]);

  const getOnboardingStatus = JSON.parse(localStorage.getItem("onboardingComplete"));

  return (
    <>
      {(!userData?.onboardingComplete || !getOnboardingStatus) && (
        <UpdateUserModal open={openModal} close={setOpenModal} />
      )}

      {(!dashboardData || !exceptions) && <Loader />}

      {dashboardData && exceptions ? (
        <div>
          <div className="dashboard-large">
            <div className="main-panel mt-2">
              {/* shipments by stage */}

              <div className="items-wrapper">
                <div className="left border-r border-solid border-[#cccccc] pr-7">
                  <SearchBillContainer presentRoute="dashboard" />
                  {incompleteReg && (
                    <Collapse in={openAlert}>
                      <Alert
                        color="error"
                        action={
                          <IconButton color="warning" size="small" onClick={() => setOpenAlert((alert) => !alert)}>
                            <MdClose />
                          </IconButton>
                        }
                        sx={{ my: 1, color: "error" }}
                        severity="warning"
                        variant="outlined"
                      >
                        <span style={{ color: "darkorange" }}>Your Registration is incomplete! </span>{" "}
                        <Link to="/dashboard/settings" className="text-red-400 link">
                          Complete it here
                        </Link>
                      </Alert>
                    </Collapse>
                  )}
                  <div className="">
                    <h1 className="font-bold text-xl 4xl:text-[22px] text-[#033B6C]">Shipments by Stage and Status</h1>
                    <div className="flex flex-row justify-between ">
                      <div style={{ width: "60%" }} className="interact">
                        {shipmentDataCopy ? (
                          <Bar
                            data={shipmentDataCopy}
                            height="200"
                            options={{
                              ...shipmentOptions,
                              onClick: function (evt, element) {
                                history.push(
                                  `shipments?stage=${STAGES[element[0]?.index + 1]}&status=${
                                    key[element[0]?.datasetIndex]
                                  }`
                                );
                              },
                            }}
                          />
                        ) : null}
                      </div>
                      <div style={{ width: "37%", margin: "auto 0" }}>
                        <NumberOfShipment number={dashboardData?.totalShipments} />
                      </div>
                    </div>
                  </div>
                  {/* track your shipment desktop view */}
                  <div className="pt-8">
                    <h1 className="font-bold text-xl 4xl:text-[22px] text-[#033B6C]">Track your Shipment</h1>
                    <div className="onboard-map mt-4 w-full h-[394px] rounded-[20px] overflow-hidden">
                      {shipmentWaypointsData ? (
                        <Maps data={shipmentWaypointsData} parent="dashboard" />
                      ) : (
                        <p className="text-center pt-10">No shipment available</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="h-full">
                  <div className="pl-5 xl:pl-8">
                    <div>
                      <h1 className="font-bold text-[18px] text-[#033B6C]">ETA Status</h1>
                      <div className="pt-4 flex justify-around">
                        <div className="w-[140px] relative interact">
                          {etaDataCopy ? (
                            <PieChart
                              data={etaDataCopy}
                              options={{
                                ...etaOptions,
                                onClick: function (evt, element) {
                                  history.push(`shipments?status=${key[element[0]?.index]}`);
                                },
                              }}
                            />
                          ) : null}
                        </div>
                        <div className="block gap-x-6 pl-4" style={{ margin: "auto 0" }}>
                          {doughnutStatus.map((data) => (
                            <div key={data.status} className="flex gap-x-3" style={{ margin: "7px 0" }}>
                              <div
                                className="w-[20px] h-[20px] rounded-[4px]"
                                style={{
                                  background: data.color,
                                }}
                              ></div>
                              <h1
                                className="text-base eta-option"
                                style={{
                                  color: data.color,
                                  cursor: "pointer",
                                }}
                              >
                                {data.status}
                              </h1>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* exceptions */}
                    <div className="pt-11">
                      <h1 className="font-bold text-[18px] text-[#033B6C]">Exceptions</h1>
                      <div className="pt-5 w-full">
                        <TimelineExceptions data={exceptions} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {termNotAccepted && <AcceptTermsPane />}
            </div>
          </div>

          {/* mobile */}
          <div className="dashboard-mobile bg-[#FBFBFB] lg:hidden">
            <SearchBillContainer presentRoute="dashboard" />
            {incompleteReg && (
              <Collapse className="mt-2" in={openAlert}>
                <Alert
                  color="error"
                  action={
                    <IconButton color="warning" size="small" onClick={() => setOpenAlert((alert) => !alert)}>
                      <MdClose />
                    </IconButton>
                  }
                  sx={{ my: 1, textAlign: "center" }}
                  severity="warning"
                  variant="outlined"
                >
                  <span style={{ color: "darkorange" }}>Your Registration is incomplete! </span>{" "}
                  <Link to="/dashboard/settings" style={{ textDecoration: "underline" }}>
                    Complete it here
                  </Link>
                </Alert>
              </Collapse>
            )}
            <div className="py-5 ">
              <h1 className="font-bold text-lg text-[#033B6C]">Shipments by Stage & Status</h1>
              <div className="">
                {shipmentDataCopy ? (
                  <Bar
                    data={shipmentDataCopy}
                    height="265"
                    options={{
                      ...shipmentOptions,
                      onClick: function (evt, element) {
                        let key = ["On-time", "Late", "Critical"];
                        history.push(
                          `shipments?stage=${STAGES[element[0]?.index + 1]}&status=${key[element[0]?.datasetIndex]}`
                        );
                      },
                    }}
                  />
                ) : null}
                <div className="pt-5 md:grid grid-cols-[1fr,1.5fr] items-center gap-x-8">
                  <NumberOfShipment number={dashboardData?.totalShipments} />
                  <div className="pt-7 md:pt-0">
                    <h1 className="font-bold text-lg text-[#033B6C]">ETA Status</h1>
                    <div className="pt-4 pl-7 flex justify-around items-center gap-x-14">
                      <div className="w-[140px] relative">
                        {etaDataCopy ? (
                          <PieChart
                            data={etaDataCopy}
                            options={{
                              ...etaOptions,
                              onClick: function (_, element) {
                                history.push(`shipments?status=${key[element[0]?.index]}`);
                              },
                            }}
                          />
                        ) : null}
                      </div>
                      <div className="flex flex-col gap-5">
                        {doughnutStatus.map((data) => (
                          <div key={data.status} className="flex gap-x-3">
                            <div
                              className="w-[20px] h-[20px] rounded-[4px]"
                              style={{
                                background: data.color,
                              }}
                            ></div>
                            <h1
                              className="text-base capitalize eta-option"
                              style={{
                                color: data.color,
                              }}
                            >
                              {data.status}
                            </h1>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* track your shipment mobile view */}
            <div className="pt-9">
              <h1 className="font-bold text-lg text-[#033B6C]">Track your Shipment</h1>
              <div className="onboard-map-mobile mt-4 w-full h-[394px] rounded-[20px] bg-red-400 overflow-hidden">
                {shipmentWaypointsData ? (
                  <Maps data={shipmentWaypointsData} parent="dashboard" />
                ) : (
                  <p className="text-center pt-10">No shipment available</p>
                )}
              </div>
            </div>

            {/* exceptions */}
            <div className="pt-11 pb-28">
              <h1 className="font-bold text-lg text-[#033B6C]">Exceptions</h1>
              <div className="pt-5 w-full">
                <TimelineExceptions data={exceptions} />
              </div>
            </div>

            {/* bottom nav */}
            {termNotAccepted && <AcceptTermsPane />}
            {/* notification modal */}
            <NotificationModal open={notificationModal} handleNotificationModal={handleNotificationModal} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default index;

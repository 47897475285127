import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, TextField, Autocomplete, Button, Box, IconButton } from "@mui/material";
import { MdDelete } from "react-icons/md";
import {Fieldset} from "../../../../components/v2/form/fieldset";
import { countries, findCountry } from "../../../../utils/all-countries";
import { useService } from "../../../../hooks/useService";
import { LoadingButton } from "@mui/lab";
import { ParseServerDate } from "../../../../utils/helpers";
import { useParams } from "react-router-dom";

const Consignment = ({ data, controls }) => {
  const [counter, setCounter] = useState(0);
  const { handleCreateCommonHealthDeclaration, handleUpdateCommonHealthDeclaration } = useService();
  const getCreateCommonHealthDeclarationData = localStorage.getItem("createCommonHealthDeclaration");
  const { id } = useParams();

  const convertToSnakeCase = (inputString) => {
    return inputString.toUpperCase().replace(/\s+/g, "_");
  };

  const formik = useFormik({
    initialValues: {
      consignmentName: "",
      consignmentOrigin: "",
      requiresRegionOrigin: "",
      consignedOrigin: "",
      requiresRegulatoryRequirement: "",
      provideBCPDetails: "",
      riskCategory: "",
      importReason: "",
      marketPurpose: "",
      movementReferenceNumber: "",
      destinationCountry: "",
      exitBCP: "",
      consigmentExitTime: "",
      transitedCountries: [],
      internalReferenceNumber: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, { setSubmitting }) => {
      const transitedCountryCodes = values.transitedCountries.map((item) => item.value);
      const payload = {
        id: id ? id : undefined,
        originCountry: values.consignmentOrigin,
        externalReference: values.internalReferenceNumber,
        consignment: {
          kind: convertToSnakeCase(values.consignmentName),
          description: "description",
          country: values.consignedOrigin,
          regulatory: true,
        },
        importReason: convertToSnakeCase(values.importReason),
        marketPurpose: values.marketPurpose,
        movementReferenceNumber: values.movementReferenceNumber,
        consignmentExitDate: ParseServerDate(values.consigmentExitTime),
        transitedCountries: transitedCountryCodes,
        destinationCountry: "UNSET",
        commodities: [],
        numPackages: 0,
        netWeight: 0,
        grossWeight: 0,
        documents: [],
        establishments: [],
        traders: [],
        destinations: [],
        transporters: [],
        portOfEntry: "",
        transportMode: "UNSET",
        arrivalDate: new Date().toISOString(),
        vehicleIdentification: "",
        useGVMS: false,
        bcpTransportMode: "UNSET",
        bcpVehicleIdentification: "",
        bcpDepartureDate: new Date().toISOString(),
        bcpWaybillNumber: 0,
        contact: {
          name: "",
          email: "",
          phone: "",
        },
        nominees: [],
      };

      const handler =
        getCreateCommonHealthDeclarationData || id
          ? handleUpdateCommonHealthDeclaration
          : handleCreateCommonHealthDeclaration;

      const { data } = await handler(payload);

      if (data?.createCommonHealthDeclaration) {
        delete data?.createCommonHealthDeclaration?.status;
        delete data?.createCommonHealthDeclaration?.createdAt;
        delete data?.createCommonHealthDeclaration?.updatedAt;

        data.createCommonHealthDeclaration.destinationCountry = "UNSET";

        localStorage.setItem(
          "commonHealthDeclaration",
          JSON.stringify({ commonHealthDeclaration: data?.createCommonHealthDeclaration })
        );

        setSubmitting(false);
        controls(1);
      }

      if (data?.updateCommonHealthDeclaration) {
        localStorage.setItem(
          "commonHealthDeclaration",
          JSON.stringify({ commonHealthDeclaration: data?.updateCommonHealthDeclaration })
        );

        setSubmitting(false);
        controls(1);
      }
    },
  });

  const { values, touched, errors, setFieldValue, handleSubmit, handleChange, handleBlur, setValues, isSubmitting } =
    formik;

  useEffect(() => {
    if (data && Object?.values(data)?.length) {
      setValues(data);
    }
  }, [data]);

  return (
    <Box mb="5rem">
      <Fieldset fieldsetStyles={{ fontFamily: "monospace" }} legend="Consignment">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} xl={4}>
            <Autocomplete
              id="consignmentName"
              name="consignmentName"
              label="What are you importing?"
              options={["Live Animals", "Animal Origin", "High Risk Food", "Plants"]}
              value={values.consignmentName || ""}
              onChange={(e, value) => {
                setFieldValue("consignmentName", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="What are you importing?"
                  name="consignmentName"
                  size="small"
                  error={touched.shipment && Boolean(errors.shipment)}
                  helperText={touched.shipment && errors.shipment}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} xl={4}>
            <Autocomplete
              id="consignmentOrigin"
              name="consignmentOrigin"
              label="Where is it from?"
              options={countries}
              getOptionLabel={(option) => option.name || ""}
              value={findCountry(values.consignmentOrigin) || ""}
              onChange={(e, value) => {
                setFieldValue("consignmentOrigin", value?.code);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Where is it from (Country Origin)?"
                  size="small"
                  error={touched.consignmentOrigin && Boolean(errors.consignmentOrigin)}
                  helperText={touched.consignmentOrigin && errors.consignmentOrigin}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} xl={4}>
            <Autocomplete
              id="requiresRegionOrigin"
              name="requiresRegionOrigin"
              options={["Yes", "No"]}
              value={values.requiresRegionOrigin}
              onChange={(e, value) => {
                setFieldValue("requiresRegionOrigin", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Requires a health certificate showing region or region code?"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={touched.requiresRegionOrigin && Boolean(errors.requiresRegionOrigin)}
                  helperText={touched.requiresRegionOrigin && errors.requiresRegionOrigin}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} xl={4}>
            <Autocomplete
              id="consignedOrigin"
              name="consignedOrigin"
              options={countries}
              getOptionLabel={(option) => option.name || ""}
              value={findCountry(values.consignedOrigin) || ""}
              onChange={(e, value) => {
                setFieldValue("consignedOrigin", value?.code);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Country from where consigned?"
                  size="small"
                  error={touched.consignedOrigin && Boolean(errors.consignedOrigin)}
                  helperText={touched.consignedOrigin && errors.consignedOrigin}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} xl={4}>
            <Autocomplete
              id="requiresRegulatoryRequirement"
              name="requiresRegulatoryRequirement"
              options={["Yes", "No"]}
              value={values.requiresRegulatoryRequirement || ""}
              onChange={(e, value) => {
                setFieldValue("requiresRegulatoryRequirement", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Conforms to regulatory requirement?"
                  size="small"
                  error={touched.requiresRegulatoryRequirement && Boolean(errors.requiresRegulatoryRequirement)}
                  helperText={touched.requiresRegulatoryRequirement && errors.requiresRegulatoryRequirement}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} xl={4}>
            <Autocomplete
              id="provideBCPDetails"
              name="provideBCPDetails"
              options={["Yes", "No"]}
              value={values.provideBCPDetails}
              onChange={(e, value) => {
                setFieldValue("provideBCPDetails", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Need to provide detail for means of transport after Border Control Post?"
                  size="small"
                  error={touched.provideBCPDetails && Boolean(errors.provideBCPDetails)}
                  helperText={touched.provideBCPDetails && errors.provideBCPDetails}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} xl={4}>
            <Autocomplete
              id="riskCategory"
              name="riskCategory"
              options={["High", "Medium", "Low"]}
              value={values.riskCategory}
              onChange={(e, value) => {
                setFieldValue("riskCategory", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select the highest risk category for the commodities in this consignment?"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={touched.riskCategory && Boolean(errors.riskCategory)}
                  helperText={touched.riskCategory && errors.riskCategory}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} xl={4}>
            <Autocomplete
              id="importReason"
              name="importReason"
              options={["Internal Market", "Transshipment", "Transit", "Re-entry"]}
              value={values.importReason}
              onChange={(e, value) => {
                setFieldValue("importReason", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Reason for importing consignment"
                  size="small"
                  error={touched.importReason && Boolean(errors.importReason)}
                  helperText={touched.importReason && errors.importReason}
                />
              )}
            />
          </Grid>

          {values?.importReason &&
            (values?.importReason === "Internal market" ? (
              <>
                <Grid item xs={12} sm={6} xl={4}>
                  <Autocomplete
                    id="marketPurpose"
                    name="marketPurpose"
                    options={["Human consumption"]}
                    value={values?.marketPurpose}
                    onChange={(e, value) => {
                      setFieldValue("marketPurpose", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Purpose in Internal market"
                        size="small"
                        error={touched?.marketPurpose && Boolean(errors?.marketPurpose)}
                        helperText={touched?.marketPurpose && errors?.marketPurpose}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="Movement reference number (opt.)?"
                    size="small"
                    fullWidth
                    name="movementReferenceNumber"
                    value={values.movementReferenceNumber}
                    error={touched.movementReferenceNumber && Boolean(errors.movementReferenceNumber)}
                    helperText={touched.movementReferenceNumber && errors.movementReferenceNumber}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </>
            ) : values?.importReason === "Transhipment or onward travel" ? (
              <>
                <Grid item xs={12} sm={6} xl={4}>
                  <Autocomplete
                    id="destinationCountry"
                    name="destinationCountry"
                    options={countries}
                    getOptionLabel={(option) => option.name || ""}
                    value={findCountry(values.destinationCountry) || ""}
                    onChange={(e, value) => {
                      setFieldValue("destinationCountry", value?.code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Destination country"
                        size="small"
                        error={touched.destinationCountry && Boolean(errors.destinationCountry)}
                        helperText={touched.destinationCountry && errors.destinationCountry}
                      />
                    )}
                  />
                </Grid>
              </>
            ) : values?.importReason === "Transit" ? (
              <>
                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    label="Movement reference number (opt.)?"
                    size="small"
                    fullWidth
                    name="movementReferenceNumber"
                    value={values.movementReferenceNumber}
                    error={touched.movementReferenceNumber && Boolean(errors.movementReferenceNumber)}
                    helperText={touched.movementReferenceNumber && errors.movementReferenceNumber}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <Autocomplete
                    id="exitBCP"
                    name="exitBCP"
                    options={["Yes", "No"]}
                    value={values.exitBCP || ""}
                    onChange={(e, value) => {
                      setFieldValue("exitBCP", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Exit border control post"
                        size="small"
                        error={touched?.exitBCP && Boolean(errors?.exitBCP)}
                        helperText={touched?.exitBCP && errors?.exitBCP}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Consignment leaves at?"
                    InputLabelProps={{ shrink: true }}
                    name="consigmentExitTime"
                    type="date"
                    error={touched.consigmentExitTime && errors.consigmentExitTime}
                    helperText={
                      touched.consigmentExitTime && errors.consigmentExitTime ? errors.consigmentExitTime : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.consigmentExitTime}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Fieldset
                    legend="Transited countries"
                    marginBottom=""
                    legendStyles={{ fontFamily: "monospace" }}
                    fieldsetStyles={{ marginTop: "2rem" }}
                  >
                    <Grid container spacing={2}>
                      {values?.transitedCountries?.map((tc, index) => (
                        <Grid key={index} item xs={12} sm={6} xl={4}>
                          <Grid container spacing={0} alignItems="center">
                            <Grid item xs={10}>
                              <Autocomplete
                                id="transitedCountry"
                                name="transitedCountry"
                                options={countries}
                                getOptionLabel={(option) => option?.name || ""}
                                value={findCountry(values?.transitedCountries?.[index]?.value) || ""}
                                onChange={(e, value) => {
                                  setFieldValue(`transitedCountries[${index}]`, { value: value?.code, id: tc?.id });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label={`Transited country ${index + 1}`}
                                    size="small"
                                    error={
                                      touched?.transitedCountries?.[index] &&
                                      Boolean(errors?.transitedCountries?.[index])
                                    }
                                    helperText={
                                      touched?.transitedCountries?.[index] && errors?.transitedCountries?.[index]
                                    }
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={2}>
                              <IconButton
                                color="error"
                                onClick={() => {
                                  setFieldValue(
                                    "transitedCountries",
                                    values?.transitedCountries?.filter((val) => val?.id != tc?.id)
                                  );
                                }}
                              >
                                <MdDelete />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}

                      <Grid item xs={12}>
                        <Box display="flex">
                          <Button
                            onClick={() => {
                              setFieldValue("transitedCountries", [
                                ...values.transitedCountries,
                                { id: counter + 1, value: "" },
                              ]);
                              setCounter(counter + 1);
                            }}
                            variant="outlined"
                          >
                            Add Transit Country
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Fieldset>
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <Autocomplete
                    id="destinationCountry"
                    name="destinationCountry"
                    options={countries}
                    getOptionLabel={(option) => option.name || ""}
                    value={findCountry(values.destinationCountry) || ""}
                    onChange={(e, value) => {
                      setFieldValue("destinationCountry", value?.code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Destination country"
                        size="small"
                        error={touched.destinationCountry && Boolean(errors.destinationCountry)}
                        helperText={touched.destinationCountry && errors.destinationCountry}
                      />
                    )}
                  />
                </Grid>
              </>
            ) : values?.importReason === "Re-entry" ? (
              <></>
            ) : null)}

          <Grid item xs={12} sm={6} xl={4}>
            <TextField
              label="Internal reference number (opt.)?"
              size="small"
              fullWidth
              name="internalReferenceNumber"
              value={values.internalReferenceNumber}
              error={touched.internalReferenceNumber && Boolean(errors.internalReferenceNumber)}
              helperText={touched.internalReferenceNumber && errors.internalReferenceNumber}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={isSubmitting || !values.consignmentName}
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save and Continue
            </LoadingButton>
          </Grid>
        </Grid>
      </Fieldset>
    </Box>
  );
};

export default Consignment;

import { useState } from "react";
import { Fieldset } from "../fieldset";
import { Grid, TextField, Autocomplete } from "@mui/material";
import { FormMInputProps, FormMData } from "@/types/formM";
import { ALL_CURRENCIES, findCompleteCurrency } from "@/utils/data";
import {
  fixDate,
  fixMoneyUpload,
  formattedNumber,
  getAmountInForeign,
  getAmountInLocal,
  patchRate,
} from "@/utils/helpers";
import dayjs from "dayjs";

export const FormM = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  getRateOrDefault,
  formMData,
  localCurrency,
  rate,
}: FormMInputProps) => {
  const setFormMFields = (formM: FormMData) => {
    setFieldValue("formMNumber", formM.formMNumber);
    setFieldValue("formMValueCurrency", formM.formMValue.currency || localCurrency);
    setFieldValue("formMValue", formattedNumber(getAmountInForeign(formM.formMValue)));
    setFieldValue("formMValueRate", getRateOrDefault(formM.formMValue) || 1);
    setFieldValue("formMApprovalDate", fixDate(formM.formMApprovalDate) || "");
    setFieldValue("formMExpiryDate", fixDate(formM.formMExpiryDate) || "");
    setFieldValue("baNumber", formM.baNumber);
    setFieldValue("bankName", formM.bankName || "");
  };

  const getAmountUsed = (formM: FormMData): number => {
    return formM.billsOfLading.reduce((acc, curr) => acc + getAmountInLocal(curr.blValue), 0);
  };

  const getAmountLeft = (formM: FormMData): number => {
    return getAmountInLocal(formM.formMValue) - getAmountUsed(formM);
  };

  const getAmountLeftInForMCurrency = (formM: FormMData): number => {
    const amount = getAmountLeft(formM);
    return amount / (formM.formMValue.rate || 1);
  };

  const handleFormMChange = (_: React.SyntheticEvent, newInputValue: FormMData | string | null) => {
    setAmountLeft(null);
    if (!newInputValue) {
      const formM = {
        formMNumber: "",
        formMValue: { currency: "", rate: 0, value: 0 },
        formMApprovalDate: "",
        formMExpiryDate: "",
        baNumber: "",
        bankName: "",
        billsOfLading: [],
      };
      setFormMFields(formM);
    } else if (typeof newInputValue === "string") {
      let formM = findFormM(newInputValue);
      if (formM) {
        setAmountLeft(getAmountLeftInForMCurrency(formM));
      } else if (newInputValue === values.formMNumber) {
        formM = {
          formMNumber: values.formMNumber,
          formMValue: {
            currency: values.formMValueCurrency || "",
            rate: patchRate(values.formMValueRate) || 0,
            value: fixMoneyUpload(values.formMValue) || 0,
          },
          formMApprovalDate: values.formMApprovalDate || "",
          formMExpiryDate: values.formMExpiryDate || "",
          baNumber: values.baNumber || "",
          bankName: values.bankName || "",
          billsOfLading: [],
        };
      } else {
        formM = {
          formMNumber: newInputValue || "",
          formMValue: { currency: "", rate: 100, value: 0 },
          formMApprovalDate: "",
          formMExpiryDate: "",
          baNumber: "",
          bankName: "",
          billsOfLading: [],
        };
      }

      setFormMFields(formM);
    } else {
      setAmountLeft(getAmountLeftInForMCurrency(newInputValue));
      setFormMFields(newInputValue);
    }
  };

  const findFormM = (formMNumber: string): FormMData | undefined => {
    return formMData.find((formM) => formM.formMNumber === formMNumber);
  };

  const [amountLeft, setAmountLeft] = useState<number | null>(null);
  return (
    <Fieldset legend={"form M"}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            value={values?.formMNumber || ""}
            isOptionEqualToValue={(option, value) =>
              option?.formMNumber?.toLocaleLowerCase() === value?.formMNumber?.toLocaleLowerCase()
            }
            size="small"
            onChange={handleFormMChange}
            onInputChange={handleFormMChange}
            options={formMData}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              return option.formMNumber;
            }}
            fullWidth
            freeSolo
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                label="Form M Number"
                sx={{
                  ".MuiFormHelperText-root": { color: amountLeft && amountLeft <= 0 ? "warning.main" : "default.main" },
                }}
                helperText={
                  amountLeft !== null
                    ? `you have ${values.formMValueCurrency} ${formattedNumber(amountLeft)} left on this Form M`
                    : ""
                }
              />
            )}
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <Grid container>
            <Grid item xs={3}>
              <Autocomplete
                value={findCompleteCurrency(values?.formMValueCurrency || localCurrency || "") || undefined}
                fullWidth
                disabled={!values.formMNumber}
                disableClearable
                size="small"
                onChange={(_, v) => {
                  setFieldValue("formMValueCurrency", v?.value);
                  setFieldValue("formMValueRate", v?.value === localCurrency ? 1 : values.formMValueRate||rate || 1);
                }}
                getOptionLabel={(option) => `${option.value || ""}`}
                options={ALL_CURRENCIES}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      sx: { borderRadius: "4px 0 0 4px" },
                    }}
                    helperText={touched?.formMValueCurrency && errors?.formMValueCurrency}
                    error={!!(touched?.formMValueCurrency && errors?.formMValueCurrency)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                fullWidth
                variant="outlined"
                size="small"
                disabled={!values.formMNumber}
                label="form M Value"
                name="formMValue"
                type="text"
                value={values?.formMValue}
                helperText={touched?.formMValue && errors?.formMValue}
                error={!!(touched?.formMValue && errors?.formMValue)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                onFocus={(e) => {
                  const val = values?.formMValue?.toString()?.replaceAll(",", "");
                  setFieldValue("formMValue", val);
                  e.target.setAttribute("type", "number");
                }}
                onBlur={(e) => {
                  e.target.setAttribute("type", "text");
                  setFieldValue("formMValue", formattedNumber(values?.formMValue));
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <TextField
            InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
            fullWidth
            variant="outlined"
            size="small"
            disabled={!values.formMNumber || values.formMValueCurrency === localCurrency}
            label="form M Exchange Rate"
            name="formMValueRate"
            type="text"
            value={values?.formMValueRate}
            helperText={touched?.formMValueRate && errors?.formMValueRate}
            error={!!(touched?.formMValue && errors?.formMValueRate)}
            onChange={handleChange}
            onFocus={(e) => {
              e.target.setAttribute("type", "number");
              setFieldValue("formMValueRate", values?.formMValueRate?.toString()?.replaceAll(",", ""));
            }}
            onBlur={(e) => {
              e.target.setAttribute("type", "text");
              setFieldValue("formMValueRate", formattedNumber(values?.formMValueRate));
            }}
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={"Form M Approval Date"}
            name="formMApprovalDate"
            type="date"
            InputLabelProps={{ shrink: true }}
            disabled={!values.formMNumber}
            value={values.formMApprovalDate || ""}
            helperText={touched?.formMApprovalDate && errors?.formMApprovalDate}
            error={!!(touched?.formMApprovalDate && errors?.formMApprovalDate)}
            onChange={(e) => {
              if (e.target.value) {
                setFieldValue("formMExpiryDate", fixDate(dayjs(e.target.value).add(6, "month")));
              }
              setFieldValue("formMApprovalDate", e.target.value);
            }}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={"Form M Expiry Date"}
            name="formMExpiryDate"
            disabled={!values.formMNumber}
            type="date"
            InputLabelProps={{ shrink: true }}
            value={values.formMExpiryDate || ""}
            helperText={touched?.formMExpiryDate && errors?.formMExpiryDate}
            error={!!(touched?.formMExpiryDate && errors?.formMExpiryDate)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={"BA Number"}
            InputLabelProps={{ shrink: true }}
            name="baNumber"
            disabled={!values.formMNumber}
            value={values.baNumber || ""}
            helperText={touched?.baNumber && errors?.baNumber}
            error={!!(touched?.baNumber && errors?.baNumber)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Bank Name"
            InputLabelProps={{ shrink: true }}
            name="bankName"
            disabled={!values.formMNumber}
            value={values.bankName || ""}
            helperText={touched?.bankName && errors?.bankName}
            error={!!(touched?.bankName && errors?.bankName)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
    </Fieldset>
  );
};

import {  Grid, TextField, Autocomplete } from "@mui/material";
import { Fieldset } from "../fieldset";
import { BlValueInputProps } from "@/types/blvalueForm";
import { ALL_CURRENCIES, findCompleteCurrency } from "@/utils/data";
import { formattedNumber, } from "@/utils/helpers";

const BlValue = ({values, touched, errors, localCurrency, handleChange, setFieldValue, setFieldTouched, disabled}:BlValueInputProps) => {
  return (
    <Fieldset legend="Bl Value" disabled={disabled} >
       <Grid columnSpacing={2} container>
        <Grid item xs={6}>
          <Grid container >
            <Grid item xs={4}>
              <Autocomplete
                value={findCompleteCurrency(values?.blValueCurrency) || { value: "", label: "", name: "" }}
                fullWidth
                disableClearable
                size="small"
                onChange={(_, v) => {
                  setFieldValue("blValueCurrency", v?.value);
                  setFieldValue("blValueRate", 1);
                }}
                getOptionLabel={(option) => `${option.value || ""}`}
                options={ALL_CURRENCIES}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      sx: {  borderRadius: "4px 0 0 4px" },
                    }}
                    helperText={touched?.blValueCurrency && errors?.blValueCurrency}
                  />
                )}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                InputProps={{ sx: { borderRadius: "0 0px 0px 0" } }}
                fullWidth
                variant="outlined"
                size="small"
                label="blValue"
                name="blValue"
                type="text"
                value={values?.blValue}
                helperText={touched?.blValue && errors?.blValue}
                error={!!(touched?.blValue && errors?.blValue)}
                onChange={handleChange}
                onFocus={(e) => {
                  e.target.setAttribute("type", "number");
                  setFieldValue("blValue", values?.blValue?.toString()?.replaceAll(",", ""));
                }}
                onBlur={(e) => {
                  e.target.setAttribute("type", "text");
                  setFieldValue("blValue", formattedNumber(e.target.value));
                  setFieldTouched && setFieldTouched("blValue", true, true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              id="blValueRate"
              name="blValueRate"
              label="BL Value Rate"
              disabled={values.blValueCurrency === localCurrency}
              InputLabelProps={{ shrink: true }}
              InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
              value={values.blValueRate}
              type="number"
              onChange={handleChange}
              error={(!!touched?.blValueRate && Boolean(errors?.blValueRate))}
              helperText={
                touched?.blValueRate && errors?.blValueRate
                  ? errors?.blValueRate
                  : !values.blValueCurrency
                  ? ""
                  : values.blValueCurrency !== localCurrency
                  ? `This would be used for all ${values.blValueCurrency} to ${localCurrency || ""} conversion`
                  : "BL Currency = Local currency, No need to convert to"
              }
            />
        </Grid>
    </Grid>
    </Fieldset>
  );
}

export { BlValue };

import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Grid,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Autocomplete,
  Button,
  Box,
  Modal,
  Portal,
} from "@mui/material/";
import { LoadingButton } from "@mui/lab";
import { MdEdit, MdDelete, MdCancel, MdAdd, MdUpdate } from "react-icons/md";
import * as Yup from "yup";
import {Fieldset} from "../../components/v2/form/fieldset";
import {
  fieldsArrayToObject,
  formattedNumber,
  getNormalizedRate,
  patchRate,
  printNCharacters,
  stringToCurrency,
 getAmountInForeign, getAmountInLocal } from "../../utils/helpers";
import { terminalOption, getTerminalOption, ALL_CURRENCIES, findCompleteCurrency } from "../../utils/data";
import { shippingOptions, getShippingLine } from "../../utils/shippingLine";
import useTracking from "../../hooks/useTracking";
import { useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import ConfirmationDialog from "../../components/Confirmation/ConfirmationDialog";
import GenericFileUpload from "../../components/FileUpload/GenericFileUpload";
import { useFile } from "../../hooks/useFile";
import { useModels } from "../../hooks/useModels";
import { useDocument } from "../../hooks/useDocument";

const ServiceManagement = ({ bills, transporterOpt, clearingAgentOpt }) => {
  const { handleGet3SPFileLink } = useFile();
  const { handle3SPDocument } = useModels();
  const { handleCreateDocument } = useDocument();
  const services = useSelector((state) => state?.data?.blInvoices?.invoices);
  const localCurrency = useSelector((state) => state?.user?.userData?.localCurrency);
  const [_, setCurrencies] = React.useState([findCompleteCurrency("USD"), findCompleteCurrency(localCurrency)]);
  const [file, setFile] = React.useState(null);
  const [url, setUrl] = React.useState("");
  const [createDocument, setCreateDocument] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [bl, setBl] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);
  const serviceTypes = ["Clearing Agency", "Transporter", "Shipping Line", "Terminal", "Others"];
  const { handleAddInvoice, handleDeleteInvoice, handleEditInvoice, handleGetBLInvoices } = useTracking();
  const [openFileUpload, setOpenFileUpload] = React.useState(false);
  const handleOnDelete = (id) => {
    setDeleteId(id);
    setOpenDeleteConfirmation(true);
  };
  const handleDelete = (id) => {
    handleDeleteInvoice(id, bl);
  };

  const handleEdit = (id) => {
    setEditMode(true);
    const service = services.find((service) => service.id === id);
    formik.setValues({
      ...service,
      invoiceValue: formattedNumber(getAmountInForeign(service?.invoiceValue)) || "",
      rate: getNormalizedRate(service.invoiceValue?.rate || 1),
      currency: service.invoiceValue?.currency || localCurrency,
    });
  };

  const handleUpdate = (values) => {
    let { serviceProvider, invoiceNumber, invoiceValue, serviceType, currency, description } = values;
    invoiceValue = Math.floor(parseFloat(`${formik.values.invoiceValue}`?.replaceAll(",", "")) * 100);
    handleEditInvoice(
      values?.id,
      {
        serviceProvider,
        invoiceNumber,
        invoiceValue: {
          currency,
          rate: patchRate(values?.rate),
          value: invoiceValue,
        },
        serviceType,
        description,
      },
      bl
    );
  };

  const handleAdd = () => {
    let { serviceProvider, invoiceNumber, invoiceValue, serviceType, currency, description, rate } = values;
    invoiceValue = Math.floor(parseFloat(`${formik.values.invoiceValue}`?.replaceAll(",", "")) * 100);
    handleAddInvoice(bl, {
      serviceProvider,
      invoiceNumber,
      invoiceValue: { currency, rate: patchRate(rate), value: invoiceValue },
      serviceType,
      description,
    });
    setEditMode(false);
  };

  const cancelEdit = () => {
    setEditMode(false);
    formik.resetForm();
  };
  const calculateTotal = () => {
    const totalPerCurrency = services?.reduce((prev, curr) => {
      prev += getAmountInLocal(curr.invoiceValue) * 1;
      return prev;
    }, 0);

    return `${localCurrency || "USD"} ${formattedNumber(totalPerCurrency)}`;
  };

  const getCurrentBlData = async (bl) => {
    setLoading(true);
    await handleGetBLInvoices(bl);
    setLoading(false);
  };
  useEffect(() => {
    if (services) {
      setTotalAmount(calculateTotal());
    }
  }, [services]);

  useEffect(() => {
    getCurrentBlData(bl);
    setEditMode(false);
    formik.resetForm();
  }, [bl]);

  useEffect(() => {
    if (localCurrency) {
      setCurrencies([findCompleteCurrency("USD"), findCompleteCurrency(localCurrency)]);
    }
  }, [localCurrency]);

  const formik = useFormik({
    initialValues: {
      serviceType: "",
      serviceProvider: "",
      currency: localCurrency,
      invoiceNumber: "",
      invoiceValue: "",
      rate: 1,
      description: "",
    },
    validationSchema: Yup.object({
      invoiceNumber: Yup.string().typeError("Enter a valid invoice number").required("Invoice number is required"),
      invoiceValue: Yup.string().required("Amount is required"),
      rate: Yup.number()
        .typeError("Enter a valid number")
        .moreThan(0, "rate must be greater than 0")
        .required("Rate is required"),
      currency: Yup.string().typeError("enter a valid currency").required("Currency is required"),
      serviceType: Yup.string().typeError("enter a valid service type").required("Service type is required"),
      serviceProvider: Yup.string()
        .typeError("enter a valid service provider")
        .required("Service provider is required"),
      description: Yup.string().typeError("Enter a valid description").required("Description is required"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if (editMode) {
        handleUpdate(values);
      } else {
        handleAdd();
      }
      setEditMode(false);
      setSubmitting(false);
      resetForm();
    },
  });
  const { handleSubmit, handleBlur, handleChange, isSubmitting, values, errors, touched, setFieldValue } = formik;

  const handleChangeCustom = (e) => {
    handleChange(e);
  };

  const preFillServices = (data, serviceType, file, url) => {
    const res = fieldsArrayToObject(data?.fields);
    const money = stringToCurrency(
      res?.InvoiceTotal?.valueString,
      values?.rate || 1,
      values?.currency || localCurrency
    );
    const newData = {
      serviceType,
      serviceProvider: res?.VendorName?.valueString || formik.values.serviceProvider || "",
      currency: money?.currency || localCurrency,
      invoiceNumber: res?.InvoiceId?.valueString || formik.values.invoiceNumber || "",
      invoiceValue: formattedNumber(getAmountInForeign(money)) || formik?.values?.invoiceValue || "",
      rate: getNormalizedRate(money?.rate || 1) || formik?.values?.rate || 1,
      description:
        res?.Items?.reduce((cum, cur) => {
          let desc = fieldsArrayToObject(cur?.valueArray)?.Description?.valueString;
          if (desc) {
            return cum + desc + ", ";
          } else {
            return cum;
          }
        }, "") ||
        res?.PaymentTerm?.valueString ||
        formik.values.description ||
        "",
    };
    formik.setValues({ ...newData });
    setFile(file);
    setUrl(url);
    setCreateDocument(true);
  };
  return (
    <>
      <Grid container p={1.2} spacing={1.2}>
        <Grid item xs={12}>
          <Typography color={"primary"} fontWeight={"bold"} component={"h1"} variant="h2" fontSize={"1.2rem"}>
            3<sup>rd</sup> Party Services{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {" "}
          <Fieldset legend="add invoice">
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12}>
                <Grid container width={"100%"} alignItems="center" columnGap={0} columnSpacing={0}>
                  <Grid item xs={8}>
                    <Autocomplete
                      getOptionLabel={(option) => option}
                      fullWidth
                      options={bills || []}
                      value={bl}
                      onInputChange={(e, v) => {
                        setBl(v);
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: !bills.length && (
                              <Loader type="Oval" color="rgb(3,59,108,0.85)" height={20} width={20} />
                            ),
                            sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                          }}
                          label="Bill Of Lading"
                          helperText={touched.billOfLading && errors.billOfLading ? errors.billOfLading : ""}
                          error={touched.billOfLading && errors.billOfLading}
                          name="billOfLading"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      sx={{ textTransform: "none", py: 0.95, borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                      variant="contained"
                      disabled={!bl}
                      onClick={() => setOpenFileUpload(true)}
                    >
                      {" "}
                      Upload&nbsp;File
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  getOptionLabel={(option) => option}
                  fullWidth
                  options={serviceTypes}
                  value={values.serviceType}
                  onChange={(e, v) => {
                    setFieldValue("serviceType", v);
                    setFieldValue("serviceProvider", "");
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Service Type"
                      helperText={touched.serviceType && errors.serviceType ? errors.serviceType : ""}
                      error={touched.serviceType && errors.serviceType}
                      name="serviceType"
                      InputProps={{
                        ...params.InputProps,
                        sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                {(values.serviceType === "Clearing Agency" || values.serviceType === "Transporter") && (
                  <Autocomplete
                    freeSolo
                    getOptionLabel={(option) => option}
                    fullWidth
                    options={
                      values.serviceType === "Clearing Agency"
                        ? clearingAgentOpt
                        : values.serviceType === "Transporter"
                        ? transporterOpt
                        : []
                    }
                    value={values.serviceProvider}
                    onInputChange={(e, v) => {
                      setFieldValue("serviceProvider", v);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label="Service Provider"
                        helperText={touched.serviceProvider && errors.serviceProvider ? errors.serviceProvider : ""}
                        error={touched.serviceProvider && errors.serviceProvider}
                        name="serviceProvider"
                      />
                    )}
                  />
                )}
                {values.serviceType === "Shipping Line" && (
                  <Autocomplete
                    getOptionLabel={(option) => option?.label || ""}
                    fullWidth
                    options={shippingOptions}
                    value={getShippingLine(values.serviceProvider) || ""}
                    onChange={(e, v) => {
                      setFieldValue("serviceProvider", v?.value);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label="Service Provider"
                        name="serviceProvider"
                        helperText={touched.serviceProvider && errors.serviceProvider ? errors.serviceProvider : ""}
                        error={touched.serviceProvider && errors.serviceProvider}
                      />
                    )}
                  />
                )}
                {values.serviceType === "Terminal" && (
                  <Autocomplete
                    getOptionLabel={(option) => option?.name || ""}
                    fullWidth
                    options={terminalOption}
                    value={getTerminalOption(values.serviceProvider) || ""}
                    helperText={touched.serviceProvider && errors.serviceProvider ? errors.serviceProvider : ""}
                    error={touched.serviceProvider && errors.serviceProvider}
                    onChange={(e, v) => {
                      setFieldValue("serviceProvider", v?.name);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label="Service Provider"
                        name="serviceProvider"
                      />
                    )}
                  />
                )}
                {(values.serviceType === "Others" || !values.serviceType) && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    label="Service Provider"
                    name="serviceProvider"
                    onChange={handleChangeCustom}
                    value={values.serviceProvider}
                    helperText={touched.serviceProvider && errors.serviceProvider ? errors.serviceProvider : ""}
                    error={touched.serviceProvider && errors.serviceProvider}
                  />
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  onBlur={handleBlur}
                  label="Invoice №"
                  name="invoiceNumber"
                  onChange={handleChangeCustom}
                  value={values.invoiceNumber?.toLocaleUpperCase()}
                  helperText={touched.invoiceNumber && errors.invoiceNumber ? errors.invoiceNumber : ""}
                  error={touched.invoiceNumber && errors.invoiceNumber}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Autocomplete
                      value={findCompleteCurrency(values?.currency) || ""}
                      fullWidth
                      disableClearable
                      variant="outlined"
                      size="small"
                      name="currency"
                      onChange={(_, v) => {
                        setFieldValue("currency", v?.value);
                        setFieldValue("rate", 1);
                      }}
                      getOptionLabel={(option) => `${option.value}`}
                      options={ALL_CURRENCIES}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                          }}
                          helperText={touched.currency && errors.currency ? errors.currency : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Rate"
                      name="rate"
                      type="number"
                      disabled={values.currency === localCurrency}
                      onChange={handleChangeCustom}
                      onBlur={handleBlur}
                      value={values.rate}
                      helperText={touched.rate && errors.rate ? errors.rate : `${values?.currency} → ${localCurrency}`}
                      error={touched.rate && errors.rate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Invoice Amount."
                  name="invoiceValue"
                  type="string"
                  onChange={handleChangeCustom}
                  onBlur={(e) => {
                    setFieldValue("invoiceValue", formattedNumber(e.target.value));
                    e.target.type = "text";
                    handleBlur(e);
                  }}
                  onFocus={(e) => {
                    e.target.value = `${e.target.value}`.replaceAll(",", "");
                    setFieldValue("invoiceValue", e.target.value);
                    e.target.type = "number";
                  }}
                  value={values.invoiceValue}
                  helperText={touched.invoiceValue && errors.invoiceValue ? errors.invoiceValue : ""}
                  error={touched.invoiceValue && errors.invoiceValue}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Description"
                  name="description"
                  type="text"
                  onChange={handleChangeCustom}
                  onBlur={handleBlur}
                  value={values.description}
                  helperText={touched.description && errors.description ? errors.description : ""}
                  error={touched.description && errors.description}
                />
              </Grid>
            </Grid>
          </Fieldset>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={3} columnSpacing={2}>
            {editMode && (
              <Grid item xs={6}>
                <LoadingButton
                  loading={isSubmitting}
                  fullWidth
                  variant="outlined"
                  color="error"
                  onClick={cancelEdit}
                  startIcon={<MdCancel />}
                  loadingPosition="start"
                >
                  Cancel
                </LoadingButton>
              </Grid>
            )}
            <Grid item xs={editMode ? 6 : 12}>
              <LoadingButton
                loading={isSubmitting}
                fullWidth
                onClick={handleSubmit}
                startIcon={editMode ? <MdUpdate /> : <MdAdd />}
                loadingPosition="start"
                variant="contained"
              >
                {editMode ? "Update" : "Add"} Service
              </LoadingButton>
            </Grid>
            <Grid item xs={12} mt={4}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography color="#033b6c" fontWeight="bold" fontSize="1rem" variant="h6" gutterBottom>
                  Services
                </Typography>
                <Typography color="#033b6c" fontWeight="bold" fontSize=".9rem" variant="h6" gutterBottom>
                  Total Amount: <span style={{ fontWeight: "normal" }}>{totalAmount}</span>
                </Typography>
              </Box>

              <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: "rgba(3,59,108,.1)", maxWidth: "6rem" }}>
                        Service&nbsp;Type
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "rgba(3,59,108,.1)", maxWidth: "9rem" }}>
                        Service&nbsp;Provider
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "rgba(3,59,108,.1)", maxWidth: "6rem" }}>
                        Invoice&nbsp;№
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "rgba(3,59,108,.1)", maxWidth: "6rem" }}>
                        Invoice&nbsp;Amount
                      </TableCell>
                      <TableCell sx={{ backgroundColor: "rgba(3,59,108,.1)", maxWidth: "3rem" }}>Rate</TableCell>
                      <TableCell sx={{ backgroundColor: "rgba(3,59,108,.1)", maxWidth: "9rem" }}>Description</TableCell>
                      <TableCell align="right" sx={{ backgroundColor: "rgba(3,59,108,.1)", maxWidth: "6rem" }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading || !services ? (
                      <TableCell colSpan={7} align="center">
                        <Grid container justifyContent="center" alignItems="center">
                          <Grid item>
                            <Loader type="Grid" color="rgb(3,59,108,0.85)" height={30} width={30} />
                          </Grid>
                        </Grid>
                      </TableCell>
                    ) : services?.length ? (
                      services?.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell sx={{ maxWidth: "6rem" }}>{row.serviceType}</TableCell>
                          <TableCell sx={{ minWidth: "9rem" }} title={row.serviceProvider || ""}>
                            {printNCharacters(row.serviceProvider || "", 35)}
                          </TableCell>
                          <TableCell sx={{ maxWidth: "6rem" }}>{row.invoiceNumber}</TableCell>
                          <TableCell sx={{ maxWidth: "6rem" }}>{`${row.invoiceValue?.currency || ""} ${formattedNumber(
                            getAmountInForeign(row?.invoiceValue)
                          )}`}</TableCell>
                          <TableCell sx={{ maxWidth: "3rem" }}>
                            {getNormalizedRate(row?.invoiceValue?.rate) || 1}
                          </TableCell>
                          <TableCell sx={{ minWidth: "7rem" }} title={row?.description || ""}>
                            {printNCharacters(row?.description || "", 35)}
                          </TableCell>
                          <TableCell align="right" sx={{ maxWidth: "6rem" }}>
                            <IconButton
                              color="warning"
                              disabled={(editMode && values.id === row.id) || deleteId === row.id}
                              onClick={() => {
                                handleEdit(row.id);
                              }}
                            >
                              <MdEdit />
                            </IconButton>
                            <IconButton
                              color="error"
                              disabled={(editMode && values.id === row.id) || deleteId === row.id}
                              onClick={() => {
                                handleOnDelete(row.id);
                              }}
                            >
                              <MdDelete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No Services Added
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Portal>
        <Modal open={openFileUpload}>
          <Box
            height="100%"
            width="100%"
            display="flex"
            position="relative"
            justifyContent="center"
            alignItems="center"
          >
            <GenericFileUpload
              OPTIONS={serviceTypes.map((s) => ({
                name: s,
                linkFxn: handleGet3SPFileLink,
                modelFxn: handle3SPDocument,
                additionalData: s,
              }))}
              setParsedData={preFillServices}
              cleanUpFxn={() => setOpenFileUpload(false)}
              inputLabel="Service Type"
            />
          </Box>
        </Modal>
        <ConfirmationDialog
          isOpen={openDeleteConfirmation}
          handleClose={() => {
            setOpenDeleteConfirmation(false);
            setDeleteId(null);
          }}
          onConfirm={() => {
            handleDelete(deleteId);
            setOpenDeleteConfirmation(false);
            setDeleteId(null);
          }}
          severity="error"
          desc={`Are you sure you want to delete ${values.serviceType} service with invoice number ${values.invoiceNumber}?`}
        />
        <ConfirmationDialog
          isOpen={createDocument}
          handleClose={() => {
            setFile(null);
            setUrl("");
            setCreateDocument(false);
          }}
          onConfirm={async () => {
            let filename = file?.name || "file";
            let contentType = file?.type || "application/pdf";
            let contentLength = file?.size || 1024;
            let extension = filename?.split(".")?.length > 1 ? filename?.split(".")?.pop() : "";
            await handleCreateDocument(
              `3P-service-extraction${Date.now()}`,
              bl,
              url,
              "COMMERCIAL_INVOICE",
              extension,
              contentLength,
              contentType
            );
            setFile(null);
            setUrl("");
            setCreateDocument(false);
          }}
          severity="success"
          desc={`Do you want to save a copy of the uploaded document?`}
        />
      </Portal>
    </>
  );
};

export default ServiceManagement;

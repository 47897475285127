import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { IoCreateOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { IpaffTable } from "../../../components/Tables/IpaffTable";
import { useService } from "../../../hooks/useService";
import { TiWarningOutline } from "react-icons/ti";
import Loader from "../../../components/Loader/Loader";

export const IpaffOverview = () => {
  const [overviewData, setOverviewData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteData, setDeleteData] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { getAllSummarizeHealthDeclarations, getAllCommonHealthDeclarations, handleDeleteCommonHealthDeclaration } =
    useService();
  const history = useHistory();

  const headCells = [
    {
      id: "referenceNumber",
      numeric: false,
      disablePadding: false,
      label: "Reference Number",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "kind",
      numeric: false,
      disablePadding: true,
      label: "Kind",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: true,
      label: "Created At",
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: true,
      label: "Updated At",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const createData = (referenceNumber, status, kind, createdAt, updatedAt, id) => {
    return {
      referenceNumber,
      status,
      kind,
      createdAt,
      updatedAt,
      id,
    };
  };

  const rows = tableData?.commonHealthDeclarations?.data?.map((item) =>
    createData(
      item?.documents?.[0]?.externalReference,
      item?.status,
      item?.documents?.[0]?.kind,
      item?.createdAt,
      item?.updatedAt,
      item?.id
    )
  );

  const handleDocDelete = async (id) => {
    const { data } = await handleDeleteCommonHealthDeclaration(id);

    if (data?.deleteCommonHealthDeclaration) {
      const filteredData = tableData?.commonHealthDeclarations?.data?.filter((item) => item.id !== id);
      setTableData({
        commonHealthDeclarations: {
          data: filteredData,
        },
      });
      setDeleteData(data?.deleteCommonHealthDeclaration);
    }
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    const { data } = await getAllCommonHealthDeclarations(newPage + 1);
    setTableData(data);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value));
    const { data } = await getAllCommonHealthDeclarations(page, event.target.value);
    setPage(0);
    setTableData(data);
  };

  useEffect(async () => {
    setIsLoading(true);

    const { data: summarizeData } = await getAllSummarizeHealthDeclarations();
    const { data } = await getAllCommonHealthDeclarations();

    setTableData(data);
    setOverviewData(summarizeData?.summarizeCommonHealthDeclarations);

    setIsLoading(false);
    setDeleteData(false);
  }, [deleteData]);

  return (
    <Box p="1rem">
      <Typography color="primary" variant="h2" fontSize="1.2rem" fontWeight="600">
        Overview
      </Typography>

      <Grid container rowSpacing="2rem" columnSpacing="4rem" my={1}>
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ pt: "1.5rem", pb: ".8rem" }}>
            <CardContent>
              <Typography color="primary" variant="h2" fontSize="1rem" fontWeight="medium" textAlign="center">
                Total Submitted
              </Typography>
              <Typography fontSize="2rem" mt="2rem" textAlign="center" color="#fb8b23">
                {overviewData?.totalSubmitted || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ pt: "1.5rem", pb: ".8rem" }}>
            <CardContent>
              <Typography color="primary" variant="h2" fontSize="1rem" fontWeight="medium" textAlign="center">
                Total Approved
              </Typography>
              <Typography fontSize="2rem" mt="2rem" textAlign="center" color="#fb8b23">
                {overviewData?.totalApproved || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ pt: "1.5rem", pb: ".8rem" }}>
            <CardContent>
              <Typography color="primary" variant="h2" fontSize="1rem" fontWeight="medium" textAlign="center">
                Total In Progress
              </Typography>
              <Typography fontSize="2rem" mt="2rem" textAlign="center" color="#fb8b23">
                {overviewData?.totalDrafted || 0}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box mt="4rem">
        <Button
          startIcon={<IoCreateOutline />}
          variant="outlined"
          onClick={() => history.push("/dashboard/services/ipaff/create")}
          sx={{ textTransform: "none" }}
        >
          Create New Notification
        </Button>
      </Box>

      <Box mt="1rem">
        {isLoading ? (
          <Loader />
        ) : (
          <IpaffTable
            {...{
              headCells,
              rows,
              data: tableData?.commonHealthDeclarations,
              setTableData,
              handleDocDelete,
              page,
              setPage,
              handleChangePage,
              handleChangeRowsPerPage,
              rowsPerPage,
              setRowsPerPage,
            }}
          />
        )}
      </Box>

      {tableData?.commonHealthDeclarations?.data === null && (
        <div className="no-data">
          <div className="icon">
            <TiWarningOutline color="#fb8b23" style={{ margin: "10px auto" }} size="100" />
          </div>
          <div className="text">No notification found!</div>
        </div>
      )}
    </Box>
  );
};

export default IpaffOverview;
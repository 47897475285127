import React, { useRef, useState , useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import { Popover, Button, Card, CardContent, FormGroup, FormControlLabel, Checkbox , Autocomplete } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getShippingLineOptions, shippingOptions } from "../../utils/shippingLine";
import { useHistory , Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { FaGreaterThanEqual, FaLessThanEqual, FaEquals } from "react-icons/fa";
import { MdViewColumn } from "react-icons/md";
import AddEventModal from "../Modals/AddEventModal";
import { ReactComponent as ContainerIcon } from "../../assets/icons/container-icon.svg";
import "./shipmenttable.scss";
import { ParseServerDate , formattedRate, formattedMoney } from "../../utils/helpers";
import SortButton from "../SortButton";
import ShipmentRow from "../CollapsibleTable/ShipmentRow";
import MoreMenu from "./MoreMenu";
import ColumnMenu from "./ColumnMenu";
import useQuery from "../../hooks/useQuery";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const FIELDS_UPDATE_NUMBER = 1;

const addFilterToUrlTemplate = (history) => (key, value, query) => {
  if (!value && value !== 0) {
    query.delete(key);
  } else if (query.get(key)) {
    query.set(key, value);
  } else {
    query.append(key, value);
  }
  history.replace({ search: query.toString() });
};

const removeAllFilters = (history) => {
  history.push({ search: "" });
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledToolTip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#033b6c",
  },
});
const ShipmentTable = (props) => {
  return (
    <div>
      <TableLarge {...props} />

      <TableSmall {...props} />
    </div>
  );
};

const TableLarge = (props) => {
  const {
    setSelectedStage,
    setSelectedStatus,
    setSelectedBill,
    selectedBill,
    setSelectedShippingLine,
    selectedShippingLine,
    data,
    bill,
    getStage,
    selectedStage,
    selectedAge,
    stagesArray,
    selectedSign,
    setSelectedSign,
    setSelectedAge,
    selectedStatus,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    // sorting data
    changeSortOrder,
    sortOrder,
    sortValue,
  } = props;
  const query = useQuery();
  const history = useHistory();

  const addFilterToUrl = addFilterToUrlTemplate(history);
  const [ageEl, setAgeEl] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showEventModal, setShowEventModal] = useState(false);
  const [eventContainers, setEventContainers] = useState("");
  const [eventBillOfLading, setEventBillOfLading] = useState("");

  const showEventMenu = (billOfLading, containerNo) => {
    setEventContainers(containerNo);
    setEventBillOfLading(billOfLading);
    setShowEventModal(true);
  };

  const hideEventMenu = () => {
    setShowEventModal(false);
  };

  const defaultPreference = [
    { title: "Bill of Lading", show: true, filterBy: ["billOfLading"] },
    { title: "Stage", show: true, filterBy: ["stage"] },
    { title: "Age", show: true, filterBy: ["ageing"] },
    { title: "Status", show: true, filterBy: ["status"] },
    { title: "Last Event", show: true, filterBy: ["lastEvent"] },
    { title: "Commodity", show: true, filterBy: ["commodityDescription"] },
    { title: "Agency", show: true, filterBy: ["clearingAgent"] },
    { title: "Transporter", show: false, filterBy: ["transporter"] },
    { title: "External Reference ", show: false, filterBy: ["externalReferenceNumber"] },
    { title: "Commodity code", show: false, filterBy: ["commodityCode"] },
    { title: "Shipment Advice", show: false, filterBy: ["shipmentAdviceReceptionDate"] },
    { title: "FCL", show: false, filterBy: ["fcl", "fclType"] },
    { title: "Custom Command", show: false, filterBy: ["customsCommand"] },
    { title: "Draft Documents", show: false, filterBy: ["draftDocumentsReceptionDate"] },
    { title: "Original Documents", show: false, filterBy: ["originalDocumentsReceptionDate"] },
    { title: "PAAR", show: false, filterBy: ["paarSubmissionDate", "paarCopyReceptionDate"] },
    { title: "SGD", show: false, filterBy: ["sgdNumber", "sgdDate"] },
    { title: "Assessment", show: false, filterBy: ["assessmentReceptionDate"] },
    { title: "Duty Assessment №", show: false, filterBy: ["dutyAssessmentNumber"] },
    { title: "Invoice", show: false, filterBy: ["invoiceNumber", "invoiceQuantity", "blValue"] },
    { title: "Form M", show: false, filterBy: ["formMNumber", "formMValue", "formMApprovalDate", "formMExpiryDate"] },
    { title: "BA №", show: false, filterBy: ["baNumber"] },
    { title: "Bank Name", show: false, filterBy: ["bankName"] },
    { title: "Customs Duty", show: false, filterBy: ["customsDutyExcludingVAT", "vat", "customsDutyIncludingVAT"] },
    { title: "Duty Rate", show: false, filterBy: ["dutyRate"] },
    { title: "Duty Exchange Rate", show: false, filterBy: ["dutyExchangeRate"] },
    { title: "Duty Payment", show: false, filterBy: ["dutyPaymentDate"] },
    { title: "HS CODE", show: false, filterBy: ["hscode"] },
    { title: "NAFDAC Charges", show: false, filterBy: ["nafdacCharges"] },
    { title: "Quarantine Charges", show: false, filterBy: ["quarantineCharges"] },
    { title: "SON Charges", show: false, filterBy: ["sonCharges"] },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [columnPreferences, setCustomPreferences] = useState(() =>
    localStorage.getItem("fieldsUpdateNumber") >= FIELDS_UPDATE_NUMBER
      ? JSON.parse(localStorage.getItem("columnPreferences")) || defaultPreference
      : defaultPreference
  );

  const setColumnPreference = (column, show) => {
    let newPreference = [...columnPreferences];
    newPreference[column] = { ...columnPreferences[column], show: show || false };
    setCustomPreferences([...newPreference]);
  };

  const reset = () => {
    setCustomPreferences([...defaultPreference]);
  };

  const getChecked = () =>
    columnPreferences?.reduce((res, curr) => {
      res += curr?.show ? 1 : 0;
      return res;
    }, 0);

  const [allCheckboxes, setAllCheckboxes] = React.useState(getChecked() == columnPreferences?.length);

  const handleAllCheckboxes = () => {
    let action = getChecked() == columnPreferences?.length ? "uncheck" : "check";
    setCustomPreferences([...columnPreferences?.map((curr) => ({ ...curr, show: action === "check" }))]);
  };

  useEffect(() => {
    if (localStorage.getItem("columnPreferences") !== JSON.stringify(columnPreferences)) {
      localStorage.setItem("columnPreferences", JSON.stringify(columnPreferences));
      localStorage.setItem("fieldsUpdateNumber", FIELDS_UPDATE_NUMBER);
    }
    setAllCheckboxes(getChecked() == columnPreferences?.length);
  }, [columnPreferences]);

  return (
    <div className="table-large-container">
      <div className="filter-bar">
        <div className="row-two">
          <div className="search-type">
            <Autocomplete
              value={getShippingLineOptions(selectedShippingLine) || ""}
              onChange={(e, v) => {
                setSelectedShippingLine(v ? v.value : "default");
                addFilterToUrl("shippingLine", v?.value || "default", query);
              }}
              sx={{
                minWidth: { sm: "13.3rem", md: "13.2rem", lg: "18.2rem" },
              }}
              fullWidth
              options={[{ label: "All Shipping Lines", value: "default" }, ...shippingOptions]}
              isOptionEqualToValue={(option, value) => option?.label == value?.label || option?.value == value?.value}
              getOptionLabel={(option) => option?.label || ""}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Shipping Lines" />}
            />
          </div>

          <div className="search-type">
            <Autocomplete
              value={selectedBill}
              onChange={(e, v) => {
                setSelectedBill(v && v != "All Bill of Lading" ? v : "default");
                addFilterToUrl("bill", v && v != "All Bill of Lading" ? v : "default", query);
              }}
              sx={{
                minWidth: { sm: "10.1rem", md: "10rem", lg: "13rem" },
              }}
              fullWidth
              options={["All Bill of Lading", ...bill]}
              isOptionEqualToValue={(option, value) =>
                (option === "All Bill of Lading" && value === "default") ||
                option?.toLowerCase() === value?.toLowerCase()
              }
              getOptionLabel={(option) => (option === "default" ? "All Bill of Lading" : option)}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Bill of Lading" />}
            />
          </div>

          <div className="search-type">
            <Autocomplete
              value={getStage(selectedStage)}
              onChange={(e, v) => {
                setSelectedStage(v && v.label != "All Stages" ? v.label : "default");
                addFilterToUrl("stage", v && v.label != "All Stages" ? v.label : "default", query);
              }}
              sx={{
                minWidth: { sm: "10.8rem", md: "10.5rem", lg: "13.7rem" },
              }}
              fullWidth
              options={[...stagesArray]}
              isOptionEqualToValue={(option, value) => option?.label == value?.label || option?.value == value?.value}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Stages" />}
            />
          </div>
          <div className="search-type">
            <Grid container justifyContent="flex-start" alignItems={"center"}>
              <Grid item sm={1}>
                <IconButton
                  onClick={() => {
                    // filter({ sign: (selectedSign + 1) % 3 });
                    addFilterToUrl("sign", (selectedSign + 1) % 3, query);
                    setSelectedSign((selectedSign + 1) % 3);
                    ageEl?.focus();
                  }}
                  sx={{ opacity: 0.9, color: "#555", fontSize: ".8rem" }}
                >
                  {selectedSign == 2 ? <FaGreaterThanEqual /> : selectedSign == 1 ? <FaLessThanEqual /> : <FaEquals />}
                </IconButton>
              </Grid>
              <Grid item sm={11}>
                <TextField
                  inputRef={(age) => setAgeEl(age)}
                  fullWidth
                  type="number"
                  value={selectedAge}
                  sx={{ fontSize: "1rem" }}
                  placeholder={`Age ${
                    selectedSign == 2 ? "greater than" : selectedSign == 1 ? "less than" : "equal to"
                  }`}
                  onChange={(e) => {
                    setSelectedAge(e.currentTarget.value);
                    addFilterToUrl("age", e.currentTarget.value, query);
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="search-type">
            <Autocomplete
              value={selectedStatus}
              onChange={(e, v) => {
                setSelectedStatus(v != "All Status" ? v : "default");
                addFilterToUrl("status", v != "All Status" ? v : "default", query);
              }}
              sx={{
                minWidth: { sm: "10.8rem", md: "10.5rem", lg: "13.7rem" },
              }}
              fullWidth
              options={["All Status", "On-time", "Late", "Critical"]}
              isOptionEqualToValue={(option, value) =>
                option?.toLowerCase() == value?.toLowerCase() || (value == "default" && option == "All Status")
              }
              getOptionLabel={(option) => (option == "default" ? "All Status" : option)}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Status" />}
            />
          </div>
        </div>
      </div>

      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Button
          aria-describedby={id}
          variant="outlined"
          onClick={handleClick}
          startIcon={<MdViewColumn color="#033b6c" />}
          sx={{ color: "#033b6c", [":hover"]: { color: "#033b9e", borderColor: "#033b9e" }, borderColor: "#033b6c" }}
        >
          Columns
        </Button>

        <ColumnMenu
          {...{
            open,
            anchorEl,
            handleClose,
            columnPreferences,
            setColumnPreference,
            reset,
            handleAllCheckboxes,
            allCheckboxes,
          }}
        />

        {showEventModal && (eventBillOfLading || eventContainers) ? (
          <AddEventModal
            open={showEventModal}
            handleClose={hideEventMenu}
            billOfLading={eventBillOfLading}
            containerNo={eventContainers}
          />
        ) : null}
        <TableContainer className="styled-scrollbars" component={Paper} sx={{ maxHeight: "75vh" }}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" className="table-header">
                  <div className="flex">
                    <span>Container&nbsp;Info</span>
                    <SortButton
                      {...{
                        filterBy: ["containerNumber", "shippingLine", "containerSize", "containerType", "createStatus"],
                        sortValue,
                        sortOrder,
                        changeSortOrder,
                      }}
                    />
                  </div>
                </StyledTableCell>
                <>
                  {columnPreferences?.map((pref, index) => (
                    <StyledTableCell
                      key={`tableHead${index}`}
                      align="left"
                      className={`table-header ${pref?.show ? "" : "hide-column"}`}
                      sx={{ width: "fit-content" }}
                    >
                      <div className="flex">
                        <span>{pref?.title?.replaceAll(" ", "\u00A0")}</span>
                        <SortButton {...{ filterBy: pref?.filterBy, sortValue, sortOrder, changeSortOrder }} />
                      </div>
                    </StyledTableCell>
                  ))}
                </>
                <StyledTableCell align="right" className="table-header">
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row) => {
                return (
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell
                      component="th"
                      align="center"
                      scope="row"
                      sx={{ "&:hover": { backgroundColor: "#d7e2f4" } }}
                    >
                      <Link to={`/dashboard/tracking/trackingid/${row?.trackingId}`}>
                        <div className="flex container">
                          <ContainerIcon className="mr-2 container-image" />
                          <Stack>
                            <div className={sortValue == "containerNumber" ? "sorting" : ""}>
                              {row?.containerNumber ? row?.containerNumber : "NA"}
                              {"\n"}
                            </div>
                            <div style={{ textAlign: "left" }}>
                              <small>
                                <span className={sortValue == "shippingLine" ? "sorting" : ""}>{`${
                                  row?.shippingLine ? row?.shippingLine : "NA"
                                }`}</span>
                                {" - "}
                                <span className={sortValue == "containerSize" ? "sorting" : ""}>
                                  {`${row?.containerSize ? row?.containerSize : "NA"}`}
                                </span>
                                /
                                <span className={sortValue == "containerType" ? "sorting" : ""}>{`${
                                  row?.containerType ? row?.containerType : "NA"
                                }`}</span>
                              </small>
                            </div>

                            <div style={{ textAlign: "left" }}>
                              <small
                                className={sortValue == "createStatus" ? "sorting" : ""}
                                style={{
                                  color:
                                    row?.active === false && row?.active != null
                                      ? "red"
                                      : row?.createStatus == "CREATED"
                                      ? "green"
                                      : "orange",
                                }}
                              >
                                {row?.active === false && row?.active != null
                                  ? "Closed"
                                  : row?.createStatus == "CREATED"
                                  ? "Active"
                                  : "Pending"}{" "}
                                {!row.billingFulfilled ? "/ Hold" : ""}
                              </small>
                            </div>
                          </Stack>
                        </div>
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`stage ${columnPreferences?.[0].show ? "" : "hide-column"}`}
                    >
                      {row?.billOfLadingNumber ? row?.billOfLadingNumber : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`stage ${columnPreferences?.[1]?.show ? "" : "hide-column"}`}
                      align="left"
                    >
                      {row?.lastStage ? row?.lastStage : "NA"}
                    </StyledTableCell>
                    <StyledTableCell className={`${columnPreferences?.[2]?.show ? "" : "hide-column"}`} align="left">
                      {row?.ageing && row.ageing >= 0 ? row?.ageing : <span style={{ fontWeight: "400" }}>NA</span>}
                    </StyledTableCell>
                    <StyledTableCell className={`${columnPreferences?.[3]?.show ? "" : "hide-column"}`} align="left">
                      {row?.status ? (
                        <span
                          style={{
                            color:
                              row?.status?.toLowerCase() == "on-time"
                                ? "#4bd37b"
                                : row?.status?.toLowerCase() == "late"
                                ? "#ff6700"
                                : row?.status?.toLowerCase() == "critical"
                                ? "#ff1000"
                                : "#033b6c",
                            fontWeight: "400",
                          }}
                        >
                          {row?.status}
                        </span>
                      ) : (
                        <span style={{ fontWeight: "400" }}>NA</span>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={`grey-text ${columnPreferences?.[4]?.show ? "" : "hide-column"}`}
                    >
                      {row?.lastEvent ? row?.lastEvent : "NA"}
                      <small>
                        &nbsp;-&nbsp;{row?.lastEventTime ? ParseServerDate(row?.lastEventTime, "DD/MM/YYYY") : "NA"}
                      </small>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[5]?.show ? "" : "hide-column"}`}
                    >
                      {row?.commodityDescription ? row?.commodityDescription : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[6]?.show ? "" : "hide-column"}`}
                    >
                      {row?.clearingAgent || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[7]?.show ? "" : "hide-column"}`}
                    >
                      {row?.transporter || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[8]?.show ? "" : "hide-column"}`}
                    >
                      {row?.externalReferenceNumber || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[9]?.show ? "" : "hide-column"}`}
                    >
                      {row?.commodityCode || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[10]?.show ? "" : "hide-column"}`}
                    >
                      <small>received&nbsp;-&nbsp;</small>
                      {row?.shipmentAdviceReceptionDate
                        ? ParseServerDate(row?.shipmentAdviceReceptionDate, "DD/MM/YYYY")
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[11]?.show ? "" : "hide-column"}`}
                    >
                      <Stack>
                        <div className={sortValue == columnPreferences?.[11]?.filterBy[0] ? "sorting" : ""}>
                          {row?.fcl || "NA"}
                        </div>
                        <div className={sortValue == columnPreferences?.[11]?.filterBy[1] ? "sorting" : ""}>
                          <small>type&nbsp;-&nbsp;{row?.fclType || "NA"}</small>
                        </div>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[12]?.show ? "" : "hide-column"}`}
                    >
                      {row?.customsCommand || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[13]?.show ? "" : "hide-column"}`}
                    >
                      <small>received&nbsp;-&nbsp;</small>
                      {row?.draftDocumentsReceptionDate
                        ? ParseServerDate(row?.draftDocumentsReceptionDate, "DD/MM/YYYY")
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[14]?.show ? "" : "hide-column"}`}
                    >
                      <small>received&nbsp;-&nbsp;</small>
                      {row?.originalDocumentsReceptionDate
                        ? ParseServerDate(row?.originalDocumentsReceptionDate, "DD/MM/YYYY")
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[15]?.show ? "" : "hide-column"}`}
                    >
                      <Stack>
                        <div className={sortValue == columnPreferences?.[15]?.filterBy[0] ? "sorting" : ""}>
                          <span>
                            <small>Submitted&nbsp;-&nbsp;</small>
                            {row?.paarSubmissionDate ? ParseServerDate(row?.paarSubmissionDate, "DD/MM/YYYY") : "NA"}
                          </span>
                        </div>
                        <div className={sortValue == columnPreferences?.[15]?.filterBy[1] ? "sorting" : ""}>
                          <span>
                            <small>Copy&nbsp;received&nbsp;-&nbsp;</small>
                            {row?.assessmentReceptionDate
                              ? ParseServerDate(row?.assessmentReceptionDate, "DD/MM/YYYY")
                              : "NA"}
                          </span>
                        </div>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[16]?.show ? "" : "hide-column"}`}
                    >
                      <Stack>
                        <div className={sortValue == columnPreferences?.[16]?.filterBy[0] ? "sorting" : ""}>
                          <span>
                            <small>№&nbsp;-&nbsp;</small>
                            {row?.sgdNumber || "NA"}
                          </span>
                        </div>
                        <div className={sortValue == columnPreferences?.[16]?.filterBy[1] ? "sorting" : ""}>
                          <span>
                            <small>date&nbsp;-&nbsp;</small>
                            {row?.sgdDate ? ParseServerDate(row?.sgdDate, "DD/MM/YYYY") : "NA"}
                          </span>
                        </div>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[17]?.show ? "" : "hide-column"}`}
                    >
                      <div>
                        <span>
                          <small>received&nbsp;-&nbsp;</small>
                          {row?.sgdDate ? ParseServerDate(row?.sgdDate, "DD/MM/YYYY") : "NA"}
                        </span>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[18]?.show ? "" : "hide-column"}`}
                    >
                      {row?.dutyAssessmentNumber || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[19]?.show ? "" : "hide-column"}`}
                    >
                      <Stack>
                        <div className={sortValue == columnPreferences?.[19]?.filterBy[0] ? "sorting" : ""}>
                          <span>
                            <small>№&nbsp;-&nbsp;</small>
                            {row?.invoiceNumber || "NA"}
                          </span>
                        </div>
                        <div className={sortValue == columnPreferences?.[19]?.filterBy[1] ? "sorting" : ""}>
                          <span>
                            <small>qty&nbsp;-&nbsp;</small>
                            {row?.invoiceQuantity || "NA"}
                          </span>
                        </div>
                        <div className={sortValue == columnPreferences?.[19]?.filterBy[2] ? "sorting" : ""}>
                          <span>
                            <small>BL&nbsp;Value&nbsp;-&nbsp;</small>
                            {formattedMoney(row?.blValue) || "NA"}
                          </span>
                        </div>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[20]?.show ? "" : "hide-column"}`}
                    >
                      <Stack>
                        <div className={sortValue == columnPreferences?.[20]?.filterBy?.[0] ? "sorting" : ""}>
                          <span>
                            <small>№&nbsp;-&nbsp;</small>
                            {row?.formMNumber || "NA"}
                          </span>
                        </div>
                        <div className={sortValue == columnPreferences?.[20]?.filterBy?.[1] ? "sorting" : ""}>
                          <span>
                            <small>value&nbsp;-&nbsp;</small>
                            {`${formattedMoney(row?.formMValue)}` || "NA"}
                          </span>
                        </div>
                        <div>
                          <span>
                            <span className={sortValue == columnPreferences?.[20]?.filterBy?.[2] ? "sorting" : ""}>
                              <small>approved&nbsp;-&nbsp;</small>
                              {row?.formMApprovalDate ? ParseServerDate(row?.formMApprovalDate, "DD/MM/YYYY") : "NA"}
                            </span>
                            <span className={sortValue == columnPreferences?.[20]?.filterBy?.[3] ? "sorting" : ""}>
                              <small>&nbsp;expires&nbsp;-&nbsp;</small>
                              {row?.formMExpiryDate ? ParseServerDate(row?.formMExpiryDate, "DD/MM/YYYY") : "NA"}
                            </span>
                          </span>
                        </div>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[21]?.show ? "" : "hide-column"}`}
                    >
                      {row?.baNumber || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[22]?.show ? "" : "hide-column"}`}
                    >
                      {row?.bankName || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[23]?.show ? "" : "hide-column"}`}
                    >
                      <Stack>
                        <div className={sortValue == columnPreferences?.[23]?.filterBy?.[0] ? "sorting" : ""}>
                          <span>
                            <small>amount&nbsp;-&nbsp;</small>
                            {formattedMoney(row?.customsDutyExcludingVAT) || "NA"}
                          </span>
                        </div>
                        <div className={sortValue == columnPreferences?.[23]?.filterBy?.[1] ? "sorting" : ""}>
                          <span>
                            <small>VAT&nbsp;-&nbsp;</small>
                            {formattedMoney(row?.vat) || "NA"}
                          </span>
                        </div>
                        <div className={sortValue == columnPreferences?.[23]?.filterBy?.[2] ? "sorting" : ""}>
                          <span>
                            <small>total&nbsp;-&nbsp;</small>
                            {formattedMoney(row?.customsDutyIncludingVAT) || "NA"}
                          </span>
                        </div>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[24]?.show ? "" : "hide-column"}`}
                    >
                      {formattedRate(row?.dutyRate) || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[25]?.show ? "" : "hide-column"}`}
                    >
                      {formattedRate(row?.dutyExchangeRate) || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[26]?.show ? "" : "hide-column"}`}
                    >
                      <small>received&nbsp;-&nbsp;</small>
                      {row?.dutyPaymentDate ? ParseServerDate(row?.dutyPaymentDate, "DD/MM/YYYY") : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[27]?.show ? "" : "hide-column"}`}
                    >
                      {row?.hscode || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[28]?.show ? "" : "hide-column"}`}
                    >
                      {formattedMoney(row?.nafdacCharges) || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[29]?.show ? "" : "hide-column"}`}
                    >
                      {formattedMoney(row?.nafdacCharges) || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[30]?.show ? "" : "hide-column"}`}
                    >
                      {formattedMoney(row?.quarantineCharges) || "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className={` stage ${columnPreferences?.[31]?.show ? "" : "hide-column"}`}
                    >
                      {formattedMoney(row?.sonCharges) || "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <MoreMenu
                        trackingId={row?.trackingId}
                        containers={row?.containerNumber}
                        billOfLading={row?.billOfLadingNumber}
                        isActive={row?.active}
                        {...{ showEventMenu }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="view-more"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
};

const TableSmall = (props) => {
  const {
    selectedStatus,
    setSelectedStatus,

    bill,
    selectedBill,
    setSelectedBill,

    setSelectedShippingLine,
    selectedShippingLine,

    stagesArray,
    setSelectedStage,
    getStage,
    selectedStage,

    data,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    // sorting data
    changeSortOrder,
    sortOrder,
    sortValue,
  } = props;
  const query = useQuery();
  const history = useHistory();
  const addFilterToUrl = addFilterToUrlTemplate(history);
  const [showEventModal, setShowEventModal] = useState(false);
  const [eventContainers, setEventContainers] = useState("");
  const [eventBillOfLading, setEventBillOfLading] = useState("");
  const showEventMenu = (billOfLading, containerNo) => {
    setEventContainers(containerNo);
    setEventBillOfLading(billOfLading);
    setShowEventModal(true);
  };
  const hideEventMenu = () => {
    setShowEventModal(false);
  };
  return (
    <div className="table-small-container">
      <div className="page-title">Shipments</div>

      <div className="filter-bar">
        <div className="row-two">
          <div className="search-type">
            <Autocomplete
              value={getShippingLineOptions(selectedShippingLine) || ""}
              size="small"
              onChange={(e, v) => {
                setSelectedShippingLine(v ? v.value : "default");
                addFilterToUrl("shippingLine", v?.value || "default", query);
              }}
              fullWidth
              options={[{ label: "All Shipping Lines", value: "default" }, ...shippingOptions]}
              isOptionEqualToValue={(option, value) => option?.label == value?.label || option?.value == value?.value}
              getOptionLabel={(option) => option?.label || ""}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Shipping Lines" />}
            />
          </div>
        </div>
        <div className="row-two">
          <div className="search-type">
            <Autocomplete
              size="small"
              value={selectedBill}
              onChange={(e, v) => {
                setSelectedBill(v && v != "All Bill of Lading" ? v : "default");
                addFilterToUrl("bill", v && v != "All Bill of Lading" ? v : "default", query);
              }}
              sx={{
                minWidth: { sm: "12.1", md: "10rem", lg: "12rem" },
              }}
              fullWidth
              options={["All Bill of Lading", ...bill]}
              isOptionEqualToValue={(option, value) =>
                (option === "All Bill of Lading" && value === "default") || option === value
              }
              getOptionLabel={(option) => (option === "default" ? "All Bill of Lading" : option)}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Bill of Lading" />}
            />
          </div>
        </div>
        <div className="row-two">
          <div className="search-type">
            <Autocomplete
              size="small"
              value={getStage(selectedStage)}
              onChange={(e, v) => {
                setSelectedStage(v && v.label != "All Stages" ? v.label : "default");
                addFilterToUrl("stage", v && v.label != "All Stages" ? v.label : "default", query);
              }}
              sx={{
                minWidth: { sm: "12.8", md: "10.5rem", lg: "12.7rem" },
              }}
              fullWidth
              options={[{ label: "All Stages", value: "default" }, ...stagesArray]}
              isOptionEqualToValue={(option, value) => option?.label == value?.label || option?.value == value?.value}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Stages" />}
            />
          </div>
        </div>
        <div className="row-two">
          <div className="search-type">
            <Autocomplete
              size="small"
              value={selectedStatus}
              onChange={(e, v) => {
                setSelectedStatus(v != "All Status" ? v : "default");
                addFilterToUrl("status", v != "All Status" ? v : "default", query);
              }}
              sx={{
                minWidth: { sm: "10.8rem", md: "10.5rem", lg: "13.7rem" },
              }}
              fullWidth
              options={["All Status", "on-time", "late", "critical"]}
              isOptionEqualToValue={(option, value) =>
                option == value || (value == "default" && option == "All Status")
              }
              getOptionLabel={(option) => (option == "default" ? "All Status" : option)}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Status" />}
            />
          </div>
        </div>
      </div>
      {showEventModal && (eventBillOfLading || eventContainers) ? (
        <AddEventModal
          open={showEventModal}
          handleClose={hideEventMenu}
          billOfLading={eventBillOfLading}
          containerNo={eventContainers}
        />
      ) : null}
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="left" className="table-header">
                <div className="flex">
                  <span>Container №</span>
                  <SortButton {...{ filterBy: "containerNumber", sortValue, sortOrder, changeSortOrder }} />
                </div>
              </StyledTableCell>
              <StyledTableCell align="left" className="table-header">
                <div className="flex">
                  <span>Bill of Lading</span>
                  <SortButton {...{ filterBy: "billOfLadingNumber", sortValue, sortOrder, changeSortOrder }} />
                </div>
              </StyledTableCell>

              <StyledTableCell align="left" className="table-header">
                <div className="flex">
                  <span> Last Event</span>
                  <SortButton {...{ filterBy: "lastEventTime", sortValue, sortOrder, changeSortOrder }} />
                </div>
              </StyledTableCell>
              <StyledTableCell align="left" className="table-header"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row) => (
              <ShipmentRow key={row?.id} {...{ row, showEventMenu }} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="view-more"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default ShipmentTable;

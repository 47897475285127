import * as React from "react";
import { Button, Portal } from "@mui/material";
import ConfirmationDialog from "../Confirmation/ConfirmationDialog";

const ConfirmationButton = ({
  title,
  desc,
  okayText = "Sure",
  cancelText = "Not Sure",
  severity = "primary",
  onConfirm,
  closeAfterConfirm = true,
  ButtonElement,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      {ButtonElement ? (
        <ButtonElement
          onClick={() => {
            setOpen(true);
          }}
        />
      ) : (
        <Button
          variant="contained"
          size="small"
          fullWidth
          onClick={() => {
            setOpen(true);
          }}
        >
          Open
        </Button>
      )}
      <Portal>
        <ConfirmationDialog
          isOpen={open}
          {...{ title, desc, okayText, cancelText, severity, onConfirm, closeAfterConfirm, handleClose }}
        />
      </Portal>
    </>
  );
};

export default ConfirmationButton;

import React from "react";
import { NotificationCard } from "../Dash";
import { DownloadIcon, StopIcon } from "../SvgIcons";
import { IoArrowBackOutline } from "react-icons/io5";

const index = (props) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-white-100 md:hidden px-6 pt-10 pb-8 z-50  overflow-auto"
      style={{
        transform: props.open ? "translateX(0)" : "translateX(-100%)",
      }}
    >
      <div>
        <div className="flex justify-between gap-x-11">
          <h1 className="text-[22px] font-bold text-[#393939] capitalize">your notifications</h1>
          <button onClick={props.handleNotificationModal} className="outline-none">
            <IoArrowBackOutline className="text-3xl text-[#4A4A4A]" />
          </button>
        </div>
        {/* notifications */}
        <div className="border-l-[0.25px] border-solid border-[#4A4A4A] mt-5">
          <div className="notif-cards">
            <NotificationCard
              title="Arrived container"
              content="TCNU2187869 has arrived at the destination"
              time="8 minutes ago"
              icon={<DownloadIcon color="#033B6C" />}
            />
            <NotificationCard
              title="Arrived container"
              content="TCNU2187869 has arrived at the destination"
              time="8 minutes ago"
              icon={<DownloadIcon color="#033B6C" />}
            />
            <NotificationCard
              title="Delayed Container"
              content="TCNU2187869 yet to arrive the port
                of origin after 3 days of the ETA"
              time="17 minutes ago"
              icon={<StopIcon color="#033B6C" />}
            />
            <NotificationCard
              title="Delayed Container"
              content="TCNU2187869 yet to arrive the port
                of origin after 3 days of the ETA"
              time="35 minutes ago"
              icon={<StopIcon color="#033B6C" />}
            />
            <NotificationCard
              title="Arrived container"
              content="TCNU2187869 has arrived at the destination"
              time="2 days ago"
              icon={<DownloadIcon color="#033B6C" />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;

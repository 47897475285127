import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  Box,
  TableSortLabel,
  Paper,
  IconButton,
  MenuItem,
  ListItemIcon,
  Menu,
} from "@mui/material";
import { CgMoreO } from "react-icons/cg";
import { MdDelete, MdEdit, MdPreview } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { findCountry } from "../../utils/all-countries";
import { convertToLocalTime } from "../../utils/helpers";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";

const IpaffTable = ({
  headCells,
  rows,
  data,
  handleRowClick,
  handleDocDelete,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  isTraders = false,
}) => {
  if (!rows) {
    return <Fragment></Fragment>;
  }

  return (
    <Box>
      <Paper>
        <Fragment>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "center" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      <TableSortLabel>{headCell.label}</TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row, idx) => {
                  return (
                    <TableRow key={row.id}>
                      {!isTraders ? (
                        <Fragment>
                          <TableCell align="left">{row.referenceNumber || "N/A"}</TableCell>
                          <TableCell align="left">{row.status}</TableCell>
                          <TableCell align="left">{row.kind || "N/A"}</TableCell>
                          <TableCell align="left">{convertToLocalTime(row.createdAt)}</TableCell>
                          <TableCell align="left">{convertToLocalTime(row.updatedAt)}</TableCell>
                          <TableCell align="left">
                            <TableActions {...{ row, handleDocDelete }} />
                          </TableCell>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <TableCell align="left">{row.traderType}</TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.address1}</TableCell>
                          <TableCell align="left">{row.city}</TableCell>
                          <TableCell align="left">{row.postcode}</TableCell>
                          <TableCell align="left">{findCountry(row.country).name}</TableCell>
                          <TableCell align="left">
                            <Fragment>
                              <IconButton
                                onClick={() => {
                                  handleRowClick(idx, row);
                                }}
                              >
                                <MdEdit color="#033b6c" />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => {
                                  const isDelete = true;
                                  handleRowClick(idx, row, isDelete);
                                }}
                              >
                                <MdDelete />
                              </IconButton>
                            </Fragment>
                          </TableCell>
                        </Fragment>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.paginator?.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Fragment>
      </Paper>
    </Box>
  );
};

export { IpaffTable };

const TableActions = ({ row, handleDocDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const open = Boolean(anchorEl);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioed-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpenPopover}
      >
        <CgMoreO color="#033b6c" />
      </IconButton>
      <Menu
        dense
        id={`ipaffId-${row.id}-menu`}
        aria-labelledby="more menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          elevation: 1,
          sx: {
            borderColor: "#033b6c",
            borderWidth: "2px",
            "&:after": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 25,
              right: -6,
              width: 12,
              height: 12,
              bgcolor: "#393939",
              borderWidth: "2px",
              borderColor: "#033b6c",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 800,
            },
          },
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClosePopover();
            history.push(`/dashboard/services/ipaff/view/${row.id}`);
          }}
          inset
        >
          <ListItemIcon>
            <MdPreview color="#033b6c" />
          </ListItemIcon>
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(`/dashboard/services/ipaff/edit/${row.id}`);
          }}
          inset
        >
          <ListItemIcon>
            <MdEdit color="#033b6c" />
          </ListItemIcon>
          Edit
        </MenuItem>

        <ConfirmationButton
          severity="primary"
          desc={"Are you sure you want to delete this Notification?"}
          onConfirm={() => {
            handleClosePopover();
            handleDocDelete(row.id);
          }}
          ButtonElement={(props) => (
            <MenuItem {...props} inset>
              <ListItemIcon>
                <MdDelete color="#ff1000" />
              </ListItemIcon>
              Delete
            </MenuItem>
          )}
        />
      </Menu>
    </Fragment>
  );
};
import React from "react";
import { useField } from "formik";
import { IoIosCheckmark, IoIosClose } from "react-icons/io";

const FormInput = ({ label, placeholder, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="flex flex-col form-input">
      <label htmlFor={field.name} className="form-label">
        {label}
      </label>
      <input
        placeholder={placeholder}
        {...field}
        {...props}
        className={`form-field border-b-2 focus:outline-none font-normal text-[1.2rem] pb-2  text-[#282C40] ${
          meta.touched && meta.error && "border-red-500"
        } ${meta.touched && !meta.error && "border-[#2DD348]"}`}
      />

      {meta.touched && meta.error ? (
        <IoIosClose className="cancel-icon" />
      ) : meta.touched && !meta.error ? (
        <IoIosCheckmark className="check-icon" />
      ) : null}
    </div>
  );
};

export default FormInput;

const INITIAL_STATE = {
  userData: null,
  notifications: [],
  previousLocation: "",
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload.userData,
      };
      case "SET_ACCEPTED_TERMS_DATE":
        return {
          ...state,
          userData: {...state?.userData,dateTermsAccepted: action.payload.dateAccepted },
        };
    case "SET_PREVIOUS_LOCATION":
      return {
        ...state,
        previousLocation: action.payload.previousLocation,
      };
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload.notifications,
      };
    // case "SET_LOCAL_CURRENCY":
    //   return {
    //     ...state,
    //     localCurrency: action.payload.localCurrency,
    //   };

    default:
      return state;
  }
};

export default userReducer;

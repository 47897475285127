import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { StyledToolTip , StyledTableCell } from "../StyledComponents";
import { countries } from "../../utils/countries";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import { getSlabText } from "../../utils/helpers";
import { Grid } from "@mui/material";

const TradeTermsRow = (props) => {
  const { loadData, ttd, ttid, deleteTradeTerm, country } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset !important" } }}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="left">
          {countries.find((country) => country.code == ttd.country || country.name == ttd.country)?.name}
        </StyledTableCell>
        <StyledTableCell align="left">{ttd.provider || "N/A"}</StyledTableCell>
        <StyledTableCell align="left">{ttd.serviceType || "N/A"}</StyledTableCell>
        <StyledTableCell align="left">{ttd.freeTime || "N/A"}</StyledTableCell>
        <StyledTableCell align="left">
          <StyledToolTip size="small" title="edit" arrow followCursor>
            <IconButton onClick={(e) => loadData(ttd.id)} disabled={ttid == ttd.id} color="warning">
              <FiEdit2 />
            </IconButton>
          </StyledToolTip>
          <ConfirmationButton
            desc="You are about delete this Trade Term. This cannot be undone or recovered. Are you sure you want to do this?"
            onConfirm={() => deleteTradeTerm(ttd.id)}
            severity="error"
            ButtonElement={(props) => (
              <StyledToolTip title="delete" arrow followCursor>
                <IconButton size="small" color="error" disabled={ttid == ttd.id} {...props}>
                  <FiTrash2 />
                </IconButton>
              </StyledToolTip>
            )}
          />
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell style={{ paddingBottom: " 0 !important", paddingTop: " 0 !important" }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, mx: "auto" }}>
              <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                  <TableRow sx={{ margin: 1, mx: "10px", width: "fit-content", borderTop: 1 }}>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Country
                    </StyledTableCell>
                    <StyledTableCell align="left">{country}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Provider
                    </StyledTableCell>
                    <StyledTableCell align="left">{ttd.provider || "N/A"}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Service Type
                    </StyledTableCell>
                    <StyledTableCell align="left">{ttd.serviceType || "N/A"}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Container Type
                    </StyledTableCell>
                    <StyledTableCell align="left">{`${ttd?.containerSize ? ttd?.containerSize : "-"}/${
                      ttd?.containerType ? ttd?.containerType : "-"
                    }`}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                      Free Time{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {ttd.freeTime
                        ? `0 ➝ ${ttd.freeTime ? ttd.freeTime : "-"} @ ${ttd?.currency ? ttd?.currency : "-"}0 per day`
                        : "-"}
                    </StyledTableCell>
                  </TableRow>
                  {[0, 1, 2, 3].map((i) => (
                    <TableRow key={i}>
                      <StyledTableCell align="left" component="th" scope="row" sx={{ fontWeight: 600 }}>
                        Slab&nbsp;{i + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">{getSlabText(ttd.slabs[i], ttd.currency) || "-"}</StyledTableCell>
                    </TableRow>
                  ))}

                  <TableRow>
                    <StyledTableCell align="center" colSpan={2} sx={{ borderBottom: "0 !important" }}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <StyledToolTip title="edit" arrow followCursor>
                            <Button
                              variant="contained"
                              onClick={(e) => loadData(ttd.id)}
                              disabled={ttid == ttd.id}
                              fullWidth
                              color="warning"
                            >
                              Edit
                            </Button>
                          </StyledToolTip>
                        </Grid>
                        <Grid item xs={6}>
                          <ConfirmationButton
                            desc="You are about delete this Trade Term. This cannot be undone or recovered. Are you sure you want to do this?"
                            onConfirm={() => deleteTradeTerm(ttd.id)}
                            severity="error"
                            ButtonElement={(props) => (
                              <StyledToolTip title="delete" arrow followCursor>
                                <Button
                                  variant="contained"
                                  {...props}
                                  disabled={ttid == ttd.id}
                                  color="error"
                                  fullWidth
                                >
                                  Delete
                                </Button>
                              </StyledToolTip>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default TradeTermsRow;

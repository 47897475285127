import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, TextField, Autocomplete, Button, Box } from "@mui/material";
import { MdDelete } from "react-icons/md";
import {Fieldset} from "../../../../components/v2/form/fieldset";
import { toast } from "react-toastify";
import { countries, findCountry } from "../../../../utils/all-countries";
import { LoadingButton } from "@mui/lab";
import { useService } from "../../../../hooks/useService";
import { ParseServerDate } from "../../../../utils/helpers";

export const Documents = ({ data, controls }) => {
  const [counter, setCounter] = useState(0);
  const { handleUpdateCommonHealthDeclaration } = useService();
  const getCreateCommonHealthDeclarationData = JSON.parse(localStorage.getItem("commonHealthDeclaration"));

  const formik = useFormik({
    initialValues: {
      documents: [
        {
          id: counter,
          type: "",
          reference: "",
          attachment: "",
          issueDate: "",
        },
      ],
      establishmentofOrigin: [
        {
          id: counter,
          name: "",
          shipmentValue: "",
          country: "",
          type: "",
          approvalNumber: "",
        },
      ],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, { setSubmitting }) => {
      if (!getCreateCommonHealthDeclarationData) {
        return;
      }
      const { data } = await handleUpdateCommonHealthDeclaration({
        ...getCreateCommonHealthDeclarationData.commonHealthDeclaration,
        documents: values.documents.map((item) => {
          return {
            title: item.type,
            kind: item.type,
            url: "",
            externalReference: item.reference,
            issueDate: ParseServerDate(item.issueDate),
          };
        }),
        establishments: values.establishmentofOrigin.map((item) => {
          return {
            name: item.name,
            kind: item.type,
            country: item.country,
            approvalNumber: item.approvalNumber,
            shipmentValue: item.shipmentValue,
          };
        }),
      });

      if (data?.updateCommonHealthDeclaration) {
        localStorage.setItem(
          "commonHealthDeclaration",
          JSON.stringify({ commonHealthDeclaration: data?.updateCommonHealthDeclaration })
        );
        setSubmitting(false);
        controls(3);
      }
    },
  });

  const { values, touched, errors, setFieldValue, setValues, handleSubmit, handleChange, handleBlur, isSubmitting } =
    formik;

  useEffect(() => {
    if (data && Object?.values(data)?.length) {
      setValues(data);
    }
  }, [data]);

  return (
    <Box mb="5rem">
      <Box>
        <Grid container spacing={2}>
          {values?.documents?.map((item, index) => {
            return (
              <Grid key={index} item xs={12} md={6}>
                <Fieldset
                  key={index}
                  legendStyles={{ fontFamily: "monospace" }}
                  fieldsetStyles={{ margin: 0 }}
                  marginBottom="0"
                  legend={`Document ${`${index + 1}`.padStart(2, "0")}`}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} xl={4}>
                      <Autocomplete
                        id={`documents[${index}].type`}
                        name={`documents[${index}].type`}
                        options={[
                          "Custom Invoice",
                          "CMR",
                          "EUROPESE Export Documents",
                          "Parking List",
                          "Waybill / Delivery Note",
                        ]}
                        value={item?.type}
                        onChange={(e, value) => {
                          setFieldValue(`documents[${index}].type`, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Document Type"
                            size="small"
                            error={touched?.documents?.[index]?.type && Boolean(errors?.documents?.[index]?.type)}
                            helperText={touched?.documents?.[index]?.type && errors?.documents?.[index]?.type}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} xl={4}>
                      <TextField
                        label="Reference"
                        size="small"
                        fullWidth
                        name={`documents[${index}].reference`}
                        value={item?.reference}
                        InputLabelProps={{ shrink: true }}
                        error={touched?.reference && Boolean(errors?.reference)}
                        helperText={touched?.reference && errors?.reference}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} xl={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Date of Issue"
                        name={`documents[${index}].issueDate`}
                        InputLabelProps={{ shrink: true }}
                        value={item?.issueDate}
                        type="date"
                        error={touched?.documents?.[index]?.issueDate && errors?.documents?.[index]?.issueDate}
                        helperText={
                          touched?.documents?.[index]?.issueDate && errors?.documents?.[index]?.issueDate
                            ? errors?.documents?.[index]?.issueDate
                            : ""
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} xl={4}>
                      <TextField
                        type="file"
                        label="Attachement"
                        size="small"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        name={`documents[${index}].attachment`}
                        value={item?.attachment}
                        error={touched?.attachment && Boolean(errors?.attachment)}
                        helperText={
                          values?.documents[index]?.attachment
                            ? `*${values?.documents[index]?.attachment?.split("\\").pop()}`
                            : item?.type && "*Document attached"
                          // : touched?.attachment && errors?.attachment
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          endIcon={<MdDelete />}
                          onClick={() =>
                            values?.documents?.length > 1
                              ? setFieldValue(
                                  "documents",
                                  values?.documents?.filter(
                                    (document) => document.id !== values?.documents?.[index]?.id
                                  )
                                )
                              : toast.error("At least one document is required")
                          }
                          variant="outlined"
                          color="error"
                        >
                          Delete
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Fieldset>
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <Button
              onClick={(_) => {
                setFieldValue("documents", [
                  ...(Array.isArray(values.documents) ? values.documents : []),
                  {
                    id: counter + 1,
                    type: "",
                    reference: "",
                    attachment: "",
                    issueDate: "",
                  },
                ]);
                setCounter(counter + 1);
              }}
              variant="outlined"
            >
              Add Document
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2} mt="2rem">
        {values.establishmentofOrigin?.map((item, index) => (
          <Grid key={index} item xs={12} md={6}>
            <Fieldset
              marginBottom="0"
              legendStyles={{ fontFamily: "monospace" }}
              fieldsetStyles={{ margin: 0 }}
              legend={`Establishment of Origin ${`${index + 1}`.padStart(2, "0")}`}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    size="small"
                    fullWidth
                    name={`establishmentofOrigin.[${index}].name`}
                    InputLabelProps={{ shrink: true }}
                    value={item?.name}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6} xl={4}>
                  <Autocomplete
                    id={`establishmentofOrigin[${index}].country`}
                    name={`establishmentofOrigin[${index}].country`}
                    options={countries}
                    getOptionLabel={(option) => option.name || ""}
                    value={findCountry(item?.country) || ""}
                    onChange={(e, value) => {
                      setFieldValue(`establishmentofOrigin[${index}].country`, value?.code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        size="small"
                        error={
                          touched?.establishmentofOrigin?.[index]?.country &&
                          Boolean(errors?.establishmentofOrigin?.[index]?.country)
                        }
                        helperText={
                          touched?.establishmentofOrigin?.[index]?.country &&
                          errors?.establishmentofOrigin?.[index]?.country
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Shipment Value"
                    size="small"
                    fullWidth
                    name={`establishmentofOrigin.[${index}].shipmentValue`}
                    InputLabelProps={{ shrink: true }}
                    value={item?.shipmentValue}
                    error={
                      touched?.establishmentofOrigin?.[index]?.shipmentValue &&
                      Boolean(errors?.establishmentofOrigin?.[index]?.shipmentValue)
                    }
                    helperText={
                      touched?.establishmentofOrigin?.[index]?.shipmentValue &&
                      errors?.establishmentofOrigin?.[index]?.shipmentValue
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id={`establishmentofOrigin.[${index}].type`}
                    name={`establishmentofOrigin.[${index}].type`}
                    options={["Yes", "No"]}
                    value={item?.type}
                    onChange={(e, value) => setFieldValue(`establishmentofOrigin[${index}].type`, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        error={
                          touched?.establishmentofOrigin?.[index]?.type &&
                          Boolean(errors?.establishmentofOrigin?.[index]?.type)
                        }
                        helperText={
                          touched?.establishmentofOrigin?.[index]?.type && errors?.establishmentofOrigin?.[index]?.type
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Approval Number"
                    size="small"
                    fullWidth
                    name={`establishmentofOrigin.[${index}].approvalNumber`}
                    InputLabelProps={{ shrink: true }}
                    value={values?.establishmentofOrigin?.[index]?.approvalNumber}
                    error={
                      touched.establishmentofOrigin?.[index]?.approvalNumber &&
                      Boolean(errors.establishmentofOrigin?.[index]?.approvalNumber)
                    }
                    helperText={
                      touched.establishmentofOrigin?.[index]?.approvalNumber &&
                      errors.establishmentofOrigin?.[index]?.establishmentofOrigin?.[index]?.approvalNumber
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      endIcon={<MdDelete />}
                      onClick={() =>
                        values?.establishmentofOrigin?.length > 1
                          ? setFieldValue(
                              "establishmentofOrigin",
                              values?.establishmentofOrigin?.filter(
                                (e) => e.id !== values?.establishmentofOrigin?.[index]?.id
                              )
                            )
                          : toast.error("At least one establishment of origin is required")
                      }
                      variant="outlined"
                      color="error"
                    >
                      Delete
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button
            onClick={(_) => {
              setFieldValue("establishmentofOrigin", [
                ...(Array.isArray(values.establishmentofOrigin) ? values.establishmentofOrigin : []),
                {
                  id: counter + 1,
                  name: "",
                  country: "",
                  shipmentValue: "",
                  type: "",
                  approvalNumber: "",
                },
              ]);
              setCounter(counter + 1);
            }}
            variant="outlined"
          >
            Add Establishment of Origin
          </Button>
        </Grid>
      </Grid>

      <Grid container xs={12} mt="1rem" gap="1rem">
        <Grid item>
          <Box>
            <Button
              onClick={() => {
                controls(1);
              }}
              type="submit"
              variant="contained"
              sx={{ textAlign: "right" }}
            >
              Previous
            </Button>
          </Box>
        </Grid>

        <Grid item>
          <Box>
            <LoadingButton
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={
                isSubmitting ||
                !values?.documents?.[0].type ||
                !values?.documents?.[0].reference ||
                !values?.documents?.[0].issueDate
              }
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save and Continue
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Documents;

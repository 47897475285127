import { FieldsetProps } from "@/types/form";

export const Fieldset = ({
  children,
  legend,
  marginBottom = "1.2rem",
  padding = ".75rem .5rem",
  fieldsetStyles = {},
  legendStyles = {},
  disabled = false,
}: FieldsetProps) => {
  return (
    <fieldset
      disabled={disabled}
      style={{
        marginBottom,
        border: "1.25px solid rgba(3,59,108,.1)",
        borderRadius: "5px",
        padding,
        ...fieldsetStyles,
      }}
    >
      <legend style={{ fontWeight: "600", textTransform: "capitalize", color: "#033b6c", ...legendStyles }}>
        {legend}
      </legend>
      {children}
    </fieldset>
  );
};

export default Fieldset;

import React from "react";
import { Link } from "react-router-dom";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormHeader from "../../components/Form/FormHeader";
import FormInput from "../../components/Form/FormInput";
import SignupImage from "../../assets/images/sign_up_image.png";
import FormFooter from "../../components/Form/FormFooter";

import { useLazyQuery } from "@apollo/client";
import { FORGOT_PASSWORD } from "../../graphql/auth";

import { toast } from "react-toastify";
const analytics = window?.analytics;

const ForgotPassword = () => {
  document.title = `aTrace - Visibility - Forgot password`;

  const [forgotPassword, { loading }] = useLazyQuery(FORGOT_PASSWORD, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.forgotPassword?.value) {
        analytics?.track("Forgot Password", {
          method: "Email",
          message: "We have sent the next instructions to your email. Please check your inbox",
          username: Formik.values?.email,
        });

        toast.success("We have sent the next instructions to your email. Please check your inbox");
      } else {
        if (data?.forgotPassword?.code && data?.forgotPassword?.message) {
          analytics?.track("Forgot Password", {
            method: "Email",
            message: data.forgotPassword.message,
            username: Formik.values?.email,
          });

          toast.error(data.forgotPassword.message);
        } else {
          analytics?.track("Forgot Password", {
            method: "Email",
            message: "Error sending email. Please try again",
            username: Formik.values?.email,
          });

          toast.error("Error sending email. Please try again");
        }
      }
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const forgotPasswordHandler = (email) => {
    forgotPassword({
      variables: {
        email,
      },
    });
  };

  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          forgotPasswordHandler(values.email);
        }}
      >
        {(formik) => (
          <div className="auth-page">
            {/* Left Container */}

            <div className="left">
              <div className="form-wrapper center1">
                <FormHeader title={"Forgot Password?"} paragraph={<span>It happens to all of us</span>} />

                <Form className="form-content">
                  <FormInput label="Email address" name="email" type="email" placeholder="Enter your email address" />

                  <button
                    type="submit"
                    className={`form-button create ${!(formik.isValid && formik.dirty) ? "disabled" : ""}`}
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    {!loading ? "Reset Password" : "Resetting new password ..."}
                  </button>
                </Form>

                <FormFooter parag="Have an account?" linkTitle="Log In" linkPath="/login" />
              </div>
            </div>

            {/* Right Container */}
            <div className="right create">
              <div className="top-create">
                <p className="text-1">Get started with tracking your shipments from</p>
                <p className="text-2">anywhere in the world.</p>
              </div>

              <div className="middle-create">
                <img src={SignupImage} alt="packages image" />
              </div>

              <div className="bottom flex justify-center text-[18px]">
                <span className="text-white-100 pr-1">Already have an account?</span>
                <span>
                  <Link to="/login">
                    <div className="text-red-400">Sign In</div>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;

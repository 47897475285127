export const getUserToken = () => {
  if (typeof window === "undefined") {
    return null;
  }

  const getUserAuthstate = localStorage.getItem("persist:root");
  if (!getUserAuthstate) return null;

  try {
    const parsedData = JSON.parse(getUserAuthstate);
    const parsedUserToken = parsedData && JSON.parse(parsedData.user);
    return parsedUserToken ? parsedUserToken.userData : null;
  } catch (error) {
    return null;
  }
};

export const getCurrentSessionId = () => {
  const sessionId = localStorage.getItem("promptSessionId");
  if (!sessionId) return null;

  return sessionId;
};

export const saveToLS = (key: string, value: unknown) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLS = (key: string) => {
  if (typeof window === "undefined") {
    return null;
  }

  return localStorage.getItem(key);
};

export const removeFromLS = (key: string) => {
  return localStorage.removeItem(key);
};

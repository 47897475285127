import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Grid,
  TextField,
  Autocomplete,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Portal,
} from "@mui/material/";
import { LoadingButton } from "@mui/lab";
import { MdUpdate, MdExpandMore, MdSave } from "react-icons/md";
import { Fieldset } from "../../../components/v2/form/fieldset";
import { FormM } from "@/components/v2/form/formM";
import {
  fixMoneyUpload,
  fixNumber,
  formattedNumber,
  formattedRate,
  getAmountInForeign,
  patchRate,
} from "../../../utils/helpers";
import { ALL_CURRENCIES, findCompleteCurrency } from "../../../utils/data";
import ConfirmationDialog from "../../../components/Confirmation/ConfirmationDialog";
import useTracking from "../../../hooks/useTracking";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { BlValue } from "@/components/v2/form/blvalue";

const Finance = ({
  updateTracking,
  initialValues,
  setValuesChanged,
  getRateOrDefault,
  getDefaultRateFromCurrency,
  getCurrencyOrDefault,
  disabled,
}) => {
  const formMObjectArray = useSelector((state) => state?.data?.formMData);
  const localCurrency = useSelector((state) => state?.user?.userData?.localCurrency);
  const country = useSelector((state) => state.user?.userData?.country);
  const [showSaveFormMConfirmation, setShowSaveFormMConfirmation] = React.useState(false);

  const { handleUpdateFormM } = useTracking();

  const FxnsAndAllowedCountries = {
    formM: ["NG"],
  };

  const formik = useFormik({
    initialValues: {
      blValue:
        initialValues?.blValue || initialValues?.blValue === 0
          ? formattedNumber(getAmountInForeign(initialValues?.blValue))
          : "",
      blValueCurrency: getCurrencyOrDefault(initialValues?.blValue, localCurrency || "USD") || localCurrency,
      blValueRate: getRateOrDefault(initialValues?.blValue),
      nafdacCharges: getAmountInForeign(initialValues?.nafdacCharges),
      nafdacChargesCurrency: getCurrencyOrDefault(initialValues?.nafdacCharges),
      nafdacChargesRate: getRateOrDefault(initialValues?.nafdacCharges),
      sonCharges: getAmountInForeign(initialValues?.sonCharges),
      sonChargesCurrency: getCurrencyOrDefault(initialValues?.sonCharges),
      sonChargesRate: getRateOrDefault(initialValues?.sonCharges),
      quarantineCharges: getAmountInForeign(initialValues?.quarantineCharges),
      quarantineChargesCurrency: getCurrencyOrDefault(initialValues?.quarantineCharges),
      quarantineChargesRate: getRateOrDefault(initialValues?.quarantineCharges),
      formMValue:
        initialValues?.formMValue || initialValues?.formMValue === 0
          ? formattedNumber(getAmountInForeign(initialValues?.formMValue))
          : "",
      formMValueCurrency: getCurrencyOrDefault(initialValues?.formMValue, "USD"),
      formMValueRate: getRateOrDefault(initialValues?.formMValue, getRateOrDefault(initialValues?.blValue)),
      formMNumber: initialValues?.formMNumber || "",
      formMApprovalDate: initialValues?.formMApprovalDate || "",
      formMExpiryDate: initialValues?.formMExpiryDate || "",
      baNumber: initialValues?.baNumber || "",
      bankName: initialValues?.bankName || "",
      customsDutyExcludingVAT:
        initialValues?.customsDutyExcludingVAT || initialValues?.customsDutyExcludingVAT === 0
          ? formattedNumber(getAmountInForeign(initialValues?.customsDutyExcludingVAT))
          : "",
      customsDutyExcludingVATCurrency: getCurrencyOrDefault(
        initialValues?.customsDutyExcludingVAT,
        localCurrency || "USD"
      ),
      customsDutyExcludingVATRate: getRateOrDefault(
        initialValues?.customsDutyExcludingVAT,
        initialValues?.dutyExchangeRate
      ),
      customsDutyIncludingVAT:
        initialValues?.customsDutyIncludingVAT || initialValues?.customsDutyIncludingVAT === 0
          ? formattedNumber(getAmountInForeign(initialValues?.customsDutyIncludingVAT))
          : "",
      customsDutyIncludingVATCurrency: getCurrencyOrDefault(
        initialValues?.customsDutyIncludingVAT,
        localCurrency || "USD"
      ),
      customsDutyIncludingVATRate: getRateOrDefault(
        initialValues?.customsDutyIncludingVAT,
        initialValues?.dutyExchangeRate
      ),
      vat:
        initialValues?.vat || initialValues?.vat === 0 ? formattedNumber(getAmountInForeign(initialValues?.vat)) : "",
      vatCurrency: getCurrencyOrDefault(initialValues?.vat, localCurrency || "USD"),
      vatRate: getRateOrDefault(initialValues?.vat, initialValues?.dutyExchangeRate),
      ciss:
        initialValues?.ciss || initialValues?.ciss === 0
          ? formattedNumber(getAmountInForeign(initialValues?.ciss))
          : "",
      cissCurrency: getCurrencyOrDefault(initialValues?.ciss, localCurrency || "USD"),
      cissRate: getRateOrDefault(initialValues?.ciss, initialValues?.dutyExchangeRate),
      etls:
        initialValues?.etls || initialValues?.etls === 0
          ? formattedNumber(getAmountInForeign(initialValues?.etls))
          : "",
      etlsCurrency: getCurrencyOrDefault(initialValues?.etls, localCurrency || "USD"),
      etlsRate: getRateOrDefault(initialValues?.etls, initialValues?.dutyExchangeRate),
      surcharge:
        initialValues?.surcharge || initialValues?.surcharge === 0
          ? formattedNumber(getAmountInForeign(initialValues?.surcharge))
          : "",
      surchargeCurrency: getCurrencyOrDefault(initialValues?.surcharge, localCurrency || "USD"),
      surchargeRate: getRateOrDefault(initialValues?.surcharge, initialValues?.dutyExchangeRate),
      levies:
        initialValues?.levies || initialValues?.levies === 0
          ? formattedNumber(getAmountInForeign(initialValues?.levies))
          : "",
      leviesCurrency: getCurrencyOrDefault(initialValues?.levies, localCurrency || "USD"),
      leviesRate: getRateOrDefault(initialValues?.levies, initialValues?.dutyExchangeRate),
      dutyExchangeRate: initialValues.dutyExchangeRate,
      dutyRate: formattedRate(initialValues.dutyRate),
      dutyPaymentDate: initialValues?.dutyPaymentDate || "",
      invoiceQuantity: initialValues?.invoiceQuantity || "",
    },
    validationSchema: Yup.object({
      formMNumber: Yup.string(),
      formMValue: Yup.string().when("formMNumber", {
        is: (val) => val && val !== 0,
        then: Yup.string().required("Required"),
      }),
      formMValueCurrency: Yup.string().when("formMNumber", {
        is: (val) => val && val !== 0,
        then: Yup.string().required("Required"),
      }),
      formMValueRate: Yup.string().when("formMNumber", {
        is: (val) => val && val !== 0,
        then: Yup.string().required("Required"),
      }),
      formMApprovalDate: Yup.string().when("formMNumber", {
        is: (val) => val && val !== 0,
        then: Yup.string().required("Required"),
      }),
      formMExpiryDate: Yup.string().when("formMNumber", {
        is: (val) => val && val === 0,
        then: Yup.string().required("Required"),
      }),
      baNumber: Yup.string().when("formMNumber", {
        is: (val) => val && val !== 0,
        then: Yup.string().required("Required"),
      }),
      bankName: Yup.string().when("formMNumber", {
        is: (val) => val && val !== 0,
        then: Yup.string().required("Required"),
      }),
    }),
    onSubmit: async (values) => {
      values = {
        ...initialValues,
        blValue: {
          value: fixMoneyUpload(values.blValue),
          currency: values.blValueCurrency || "",
          rate: patchRate(values.blValueRate || 0),
        },
        formMValue: {
          value: fixMoneyUpload(values?.formMValue),
          currency: values.formMValueCurrency || "",
          rate: patchRate(values.formMValueRate || 0),
        },
        customsDutyExcludingVAT: {
          value: fixMoneyUpload(values?.customsDutyExcludingVAT),
          currency: values.customsDutyExcludingVATCurrency || "",
          rate: patchRate(values.customsDutyExcludingVATRate || 0),
        },
        nafdacCharges: {
          value: fixMoneyUpload(values?.nafdacCharges),
          currency: values.nafdacChargesCurrency || "",
          rate: patchRate(values.nafdacChargesRate || 0),
        },
        sonCharges: {
          value: fixMoneyUpload(values?.sonCharges),
          currency: values.sonChargesCurrency || "",
          rate: patchRate(values.sonChargesRate || 0),
        },
        quarantineCharges: {
          value: fixMoneyUpload(values?.quarantineCharges),
          currency: values.quarantineChargesCurrency || "",
          rate: patchRate(values.quarantineChargesRate || 0),
        },
        customsDutyIncludingVAT: {
          value: fixMoneyUpload(values?.customsDutyIncludingVAT),
          currency: values.customsDutyIncludingVATCurrency || "",
          rate: patchRate(values.customsDutyIncludingVATRate || 0),
        },
        vat: {
          value: fixMoneyUpload(values?.vat),
          currency: values.vatCurrency || "",
          rate: patchRate(values.vatRate || 0),
        },
        etls: {
          value: fixMoneyUpload(values?.etls),
          currency: values.etlsCurrency || "",
          rate: patchRate(values.etlsRate || 0),
        },
        ciss: {
          value: fixMoneyUpload(values?.ciss),
          currency: values.cissCurrency || "",
          rate: patchRate(values.cissRate || 0),
        },
        surcharge: {
          value: fixMoneyUpload(values?.surcharge),
          currency: values.surchargeCurrency || "",
          rate: patchRate(values.surchargeRate || 0),
        },
        levies: {
          value: fixMoneyUpload(values?.levies),
          currency: values.leviesCurrency || "",
          rate: patchRate(values.leviesRate || 0),
        },
        dutyRate: values.dutyRate?.replaceAll(/%/g, "") || 0,
        dutyPaymentDate: values.dutyPaymentDate || null,
        formMNumber: values.formMNumber,
        hscode: values.hscode,
        formMApprovalDate: values.formMApprovalDate || null,
        formMExpiryDate: values.formMExpiryDate || null,
        baNumber: values.baNumber,
        bankName: values.bankName,
      };
      const result = await updateTracking(values);
      if (result) {
        setValuesChanged(false);
      }
    },
  });

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setSubmitting,
  } = formik;
  const showOthers =
    values?.formMNumber ||
    values.formMNumber != 0 ||
    values?.hscode ||
    values.nafdacCharges ||
    values.sonCharges ||
    values.quarantineCharges ||
    values?.etls ||
    values?.ciss ||
    values?.surcharge ||
    values?.levies ||
    false;

  const handleChangeCustom = (e) => {
    const { name, value } = e.target;
    if (value !== initialValues[name]) {
      setValuesChanged(true);
    }
    handleChange(e);
  };

  const formMUpdated = () => {
    if (!values?.formMNumber || !initialValues.formMNumber) return false;

    const initialFormM = (Array.isArray(formMObjectArray) ? formMObjectArray : [])?.find(
      (formM) => formM?.formMNumber === values?.formMNumber
    );
    if (
      initialFormM &&
      (initialFormM?.formMValue?.value !== fixMoneyUpload(values?.formMValue) ||
        initialFormM?.formMValue?.currency !== values?.formMValueCurrency ||
        initialFormM?.formMValue?.rate !== patchRate(values?.formMValueRate) ||
        initialFormM?.baNumber !== values?.baNumber ||
        initialFormM?.bankName !== values?.bankName ||
        `${initialFormM?.formMApprovalDate}`.replaceAll(" 00:00:00", "") !==
          `${values?.formMApprovalDate}`.replaceAll(" 00:00:00", "") ||
        `${initialFormM?.formMExpiryDate}`.replaceAll(" 00:00:00", "") !==
          `${values?.formMExpiryDate}`.replaceAll(" 00:00:00", ""))
    ) {
      return true;
    }

    return false;
  };

  const updateFormM = async () => {
    const {
      formMNumber,
      formMValue,
      formMValueCurrency,
      formMValueRate,
      baNumber,
      bankName,
      formMApprovalDate,
      formMExpiryDate,
    } = values;

    return await handleUpdateFormM({
      formMNumber,
      formMValue: {
        value: fixMoneyUpload(formMValue),
        currency: formMValueCurrency,
        rate: patchRate(formMValueRate),
      },
      baNumber,
      bankName,
      formMApprovalDate: formMApprovalDate || null,
      formMExpiryDate: formMExpiryDate || null,
    });
  };

  return (
    <>
      <BlValue
        {...{ values, touched, errors, handleChange, setFieldValue, setFieldTouched, localCurrency, disabled }}
      />
      {(FxnsAndAllowedCountries.formM.includes(country) && (
        <FormM
          {...{
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            getRateOrDefault,
            localCurrency,
            rate: values.blValueRate,
            formMData: Array.isArray(formMObjectArray) ? formMObjectArray : [],
          }}
        />
      )) ||
        null}

      <Fieldset legend="Customs Duty">
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Duty Payment Date"
              InputLabelProps={{ shrink: true }}
              name="dutyPaymentDate"
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              type="date"
              error={touched.dutyPaymentDate && errors.dutyPaymentDate}
              helperText={touched.dutyPaymentDate && errors.dutyPaymentDate ? errors.dutyPaymentDate : ""}
              value={values.dutyPaymentDate}
            />
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Duty Rate"
              name="dutyRate"
              value={values.dutyRate}
              helperText={touched.dutyRate && errors.dutyRate ? errors.dutyRate : ""}
              error={touched.dutyRate && errors.dutyRate}
              onChange={handleChangeCustom}
              onBlur={(e) => {
                e.target.type = "text";
                e.target.value = formattedRate(e.target.value);
                handleChange(e);
                handleBlur(e);
              }}
              onFocus={(e) => {
                e.target.type = "number";
                e.target.value = parseFloat(values.dutyRate?.toString()?.replaceAll("%", "")) / 1;
                handleChange(e);
              }}
              max={1}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Duty Exchange Rate"
              name="dutyExchangeRate"
              value={values.dutyExchangeRate}
              helperText={touched.dutyExchangeRate && errors.dutyExchangeRate}
              error={touched.dutyExchangeRate && errors.dutyExchangeRate}
              onChange={(e) => {
                const { value } = e.target;
                handleChangeCustom(e);
                setFieldValue(
                  "customsDutyExcludingVATRate",
                  getDefaultRateFromCurrency(values?.customsDutyExcludingVATCurrency, value)
                );
                setFieldValue(
                  "customsDutyIncludingVATRate",
                  getDefaultRateFromCurrency(values?.customsDutyIncludingVATCurrency, value)
                );
                setFieldValue("vatRate", getDefaultRateFromCurrency(values?.vatCurrency, value));
                setFieldValue("etlsRate", getDefaultRateFromCurrency(values?.etlsCurrency, value));
                setFieldValue("cissRate", getDefaultRateFromCurrency(values?.cissCurrency, value));
                setFieldValue("surchargeRate", getDefaultRateFromCurrency(values?.surchargeCurrency, value));
                setFieldValue("leviesRate", getDefaultRateFromCurrency(values.leviesCurrency, value));
              }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Grid container>
              <Grid item xs={3}>
                <Autocomplete
                  value={findCompleteCurrency(values?.customsDutyExcludingVATCurrency || localCurrency) || ""}
                  fullWidth
                  disableClearable
                  variant="outlined"
                  size="small"
                  name="customsDutyExcludingVATCurrency"
                  onChange={(_, v) => {
                    setFieldValue("customsDutyExcludingVATCurrency", v?.value);
                    setFieldValue("customsDutyIncludingVATCurrency", v?.value);
                    setFieldValue("vatCurrency", v?.value || "");
                    setFieldValue(
                      "customsDutyExcludingVATRate",
                      v?.value === localCurrency ? 1 : initialValues?.dutyExchangeRate || 1
                    );
                    setFieldValue("vatRate", v?.value === localCurrency ? 1 : values?.dutyExchangeRate || 1);
                    setFieldValue(
                      "customsDutyIncludingVATRate",
                      v?.value === localCurrency ? 1 : values?.dutyExchangeRate || 1
                    );
                  }}
                  getOptionLabel={(option) => `${option.value || ""}`}
                  options={ALL_CURRENCIES}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                      }}
                      helperText={
                        touched.customsDutyExcludingVATCurrency && errors.customsDutyExcludingVATCurrency
                          ? errors.customsDutyExcludingVATCurrency
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Customs Duty excl. VAT"
                  name="customsDutyExcludingVAT"
                  type="text"
                  value={values?.customsDutyExcludingVAT}
                  helperText={
                    touched.customsDutyExcludingVAT && errors.customsDutyExcludingVAT
                      ? errors.customsDutyExcludingVAT
                      : (values?.customsDutyExcludingVATCurrency &&
                          `At: ${values?.customsDutyExcludingVATRate || 0} ${localCurrency} = 1 ${
                            values?.customsDutyExcludingVATCurrency || ""
                          } ${
                            localCurrency !== values?.customsDutyExcludingVATCurrency ? "(duty exchange rate)" : ""
                          }`) ||
                        ""
                  }
                  error={touched.customsDutyExcludingVAT && errors.customsDutyExcludingVAT}
                  onChange={(e) => {
                    const { value } = e.target;
                    const sum = fixNumber(values?.vat) + fixNumber(value);
                    setFieldValue("customsDutyIncludingVAT", formattedNumber(sum));
                    handleChangeCustom(e);
                  }}
                  onFocus={(e) => {
                    e.target.type = "number";
                    e.target.value = values?.customsDutyExcludingVAT?.toString()?.replaceAll(",", "");
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                    e.target.value = formattedNumber(e.target.value);
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <Grid container>
              <Grid item xs={3}>
                <Autocomplete
                  value={findCompleteCurrency(values?.vatCurrency || localCurrency) || ""}
                  fullWidth
                  disabled
                  disableClearable
                  variant="outlined"
                  size="small"
                  name="vatCurrency"
                  getOptionLabel={(option) => `${option.value || ""}`}
                  options={ALL_CURRENCIES}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                      }}
                      helperText={(touched.vatCurrency && errors.vatCurrency) || ""}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="VAT"
                  name="vat"
                  type="text"
                  value={values?.vat}
                  helperText={
                    touched.vat && errors.vat
                      ? errors.vat
                      : (values?.vatCurrency &&
                          `At: ${values?.vatRate || 0} ${localCurrency} = 1 ${values?.vatCurrency || ""} ${
                            (localCurrency !== values?.vatCurrency && "(duty exchange rate)") || ""
                          }`) ||
                        ""
                  }
                  error={touched.vat && errors.vat}
                  onChange={(e) => {
                    const { value } = e.target;
                    const sum = fixNumber(values?.customsDutyExcludingVAT) + fixNumber(value);
                    setFieldValue("customsDutyIncludingVAT", formattedNumber(sum));
                    handleChangeCustom(e);
                  }}
                  onFocus={(e) => {
                    e.target.type = "number";
                    e.target.value = values?.vat?.toString()?.replaceAll(",", "");
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                    e.target.value = formattedNumber(e.target.value);
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <Grid container>
              <Grid item xs={3}>
                <Autocomplete
                  value={findCompleteCurrency(values?.customsDutyIncludingVATCurrency || localCurrency) || ""}
                  fullWidth
                  disabled
                  disableClearable
                  variant="outlined"
                  size="small"
                  name="customsDutyIncludingVATCurrency"
                  onChange={(_, v) => {
                    setFieldValue("customsDutyIncludingVATCurrency", v?.value);
                    setFieldValue(
                      "customsDutyIncludingVATRate",
                      v?.value === localCurrency ? 1 : values?.dutyExchangeRate || 0
                    );
                  }}
                  getOptionLabel={(option) => `${option.value || ""}`}
                  options={ALL_CURRENCIES}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                      }}
                      helperText={
                        touched.customsDutyIncludingVATCurrency && errors.customsDutyIncludingVATCurrency
                          ? errors.customsDutyIncludingVATCurrency
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Customs Duty VAT incl."
                  name="customsDutyIncludingVAT"
                  type="text"
                  value={values?.customsDutyIncludingVAT}
                  helperText={
                    touched.customsDutyIncludingVAT && errors.customsDutyIncludingVAT
                      ? errors.customsDutyIncludingVAT
                      : (values?.customsDutyIncludingVATCurrency &&
                          `At: ${values?.customsDutyIncludingVATRate || 0} ${localCurrency} = 1 ${
                            values?.customsDutyIncludingVATCurrency || ""
                          } ${
                            localCurrency !== values?.customsDutyIncludingVATCurrency ? "(duty exchange rate)" : ""
                          }`) ||
                        ""
                  }
                  error={touched.customsDutyIncludingVAT && errors.customsDutyIncludingVAT}
                  onChange={(e) => {
                    handleChangeCustom(e);
                  }}
                  onFocus={(e) => {
                    e.target.type = "number";
                    e.target.value = values?.customsDutyIncludingVAT?.toString()?.replaceAll(",", "");
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                    e.target.value = formattedNumber(e.target.value);
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fieldset>

      <Accordion
        elevation={0}
        expanded={showOthers}
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          ".MuiAccordionSummary-expandIconWrapper": {
            border: "2px solid #033b6c",
            borderRadius: "50%",
          },
        }}
      >
        <AccordionSummary expandIcon={<MdExpandMore />}>
          <Typography sx={{ fontWeight: "600", textTransform: "capitalize", color: "#033b6c" }}>Others</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Fieldset legend="Other Charges">
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item lg={4} sm={6} xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Autocomplete
                      value={findCompleteCurrency(values?.etlsCurrency || localCurrency) || ""}
                      fullWidth
                      disableClearable
                      variant="outlined"
                      size="small"
                      name="etlsCurrency"
                      onChange={(_, v) => {
                        setFieldValue("etlsCurrency", v?.value);
                        setFieldValue("etlsRate", v?.value === localCurrency ? 1 : values?.dutyExchangeRate || 0);
                      }}
                      getOptionLabel={(option) => `${option.value || ""}`}
                      options={ALL_CURRENCIES}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                          }}
                          helperText={touched.etlsCurrency && errors.etlsCurrency ? errors.etlsCurrency : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="ETL"
                      name="etls"
                      type="text"
                      value={values?.etls}
                      helperText={
                        touched.etls && errors.etls
                          ? errors.etls
                          : (values?.etlsCurrency &&
                              `At: ${values?.etlsRate || 0} ${localCurrency} = 1 ${values?.etlsCurrency || ""} ${
                                localCurrency !== values?.etlsCurrency ? "(duty exchange rate)" : ""
                              }`) ||
                            ""
                      }
                      error={touched.etls && errors.etls}
                      onChange={(e) => {
                        handleChangeCustom(e);
                      }}
                      onFocus={(e) => {
                        e.target.type = "number";
                        e.target.value = values?.etls?.toString()?.replaceAll(",", "");
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        e.target.type = "text";
                        e.target.value = formattedNumber(e.target.value);
                        handleChange(e);
                        handleBlur(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={4} sm={6} xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Autocomplete
                      value={findCompleteCurrency(values?.cissCurrency || localCurrency) || ""}
                      fullWidth
                      disableClearable
                      variant="outlined"
                      size="small"
                      name="cissCurrency"
                      onChange={(_, v) => {
                        setFieldValue("cissCurrency", v?.value);
                        setFieldValue("cissRate", v?.value === localCurrency ? 1 : values?.dutyExchangeRate || 0);
                      }}
                      getOptionLabel={(option) => `${option.value || ""}`}
                      options={ALL_CURRENCIES}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                          }}
                          helperText={touched.cissCurrency && errors.cissCurrency ? errors.cissCurrency : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="CIS"
                      name="ciss"
                      type="text"
                      value={values?.ciss}
                      helperText={
                        touched.ciss && errors.ciss
                          ? errors.ciss
                          : (values?.cissCurrency &&
                              `At: ${values?.cissRate || 0} ${localCurrency} = 1 ${values?.cissCurrency || ""} ${
                                localCurrency !== values?.cissCurrency ? "(duty exchange rate)" : ""
                              }`) ||
                            ""
                      }
                      error={touched.ciss && errors.ciss}
                      onChange={(e) => {
                        handleChangeCustom(e);
                      }}
                      onFocus={(e) => {
                        e.target.type = "number";
                        e.target.value = values?.ciss?.toString()?.replaceAll(",", "");
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        e.target.type = "text";
                        e.target.value = formattedNumber(e.target.value);
                        handleChange(e);
                        handleBlur(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={4} sm={6} xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Autocomplete
                      value={findCompleteCurrency(values?.surchargeCurrency || localCurrency) || ""}
                      fullWidth
                      disableClearable
                      variant="outlined"
                      size="small"
                      name="surchargeCurrency"
                      onChange={(_, v) => {
                        setFieldValue("surchargeCurrency", v?.value);
                        setFieldValue("surchargeRate", v?.value === localCurrency ? 1 : values?.dutyExchangeRate || 0);
                      }}
                      getOptionLabel={(option) => `${option.value || ""}`}
                      options={ALL_CURRENCIES}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                          }}
                          helperText={
                            touched.surchargeCurrency && errors.surchargeCurrency ? errors.surchargeCurrency : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="SUR"
                      name="surcharge"
                      type="text"
                      value={values?.surcharge}
                      helperText={
                        touched.surcharge && errors.surcharge
                          ? errors.surcharge
                          : (values?.surchargeCurrency &&
                              `At: ${values?.surchargeRate || 0} ${localCurrency} = 1 ${
                                values?.surchargeCurrency || ""
                              } ${localCurrency !== values?.surchargeCurrency ? "(duty exchange rate)" : ""}`) ||
                            ""
                      }
                      error={touched.surcharge && errors.surcharge}
                      onChange={(e) => {
                        handleChangeCustom(e);
                      }}
                      onFocus={(e) => {
                        e.target.type = "number";
                        e.target.value = values?.surcharge?.toString()?.replaceAll(",", "");
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        e.target.type = "text";
                        e.target.value = formattedNumber(e.target.value);
                        handleChange(e);
                        handleBlur(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={4} sm={6} xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Autocomplete
                      value={findCompleteCurrency(values?.leviesCurrency || localCurrency) || ""}
                      fullWidth
                      disableClearable
                      variant="outlined"
                      size="small"
                      name="leviesCurrency"
                      onChange={(_, v) => {
                        setFieldValue("leviesCurrency", v?.value);
                        setFieldValue("leviesRate", v?.value === localCurrency ? 1 : values?.dutyExchangeRate || 0);
                      }}
                      getOptionLabel={(option) => `${option.value || ""}`}
                      options={ALL_CURRENCIES}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                          }}
                          helperText={touched.leviesCurrency && errors.leviesCurrency ? errors.leviesCurrency : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="LEVY"
                      name="levies"
                      type="text"
                      value={values?.levies}
                      helperText={
                        touched.levies && errors.levies
                          ? errors.levies
                          : (values?.leviesCurrency &&
                              `At: ${values?.leviesRate || 0} ${localCurrency} = 1 ${values?.leviesCurrency || ""} ${
                                localCurrency !== values?.leviesCurrency ? "(duty exchange rate)" : ""
                              }`) ||
                            ""
                      }
                      error={touched.levies && errors.levies}
                      onChange={(e) => {
                        handleChangeCustom(e);
                      }}
                      onFocus={(e) => {
                        e.target.type = "number";
                        e.target.value = values?.levies?.toString()?.replaceAll(",", "");
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        e.target.type = "text";
                        e.target.value = formattedNumber(e.target.value);
                        handleChange(e);
                        handleBlur(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Autocomplete
                      value={findCompleteCurrency(values?.nafdacChargesCurrency || localCurrency) || ""}
                      fullWidth
                      disableClearable
                      variant="outlined"
                      size="small"
                      name="nafdacChargesCurrency"
                      onChange={(_, v) => {
                        setFieldValue("nafdacChargesCurrency", v?.value);
                        setFieldValue(
                          "nafdacChargesRate",
                          v?.value === localCurrency ? 1 : getRateOrDefault(initialValues?.blValue) || 0
                        );
                      }}
                      getOptionLabel={(option) => `${option.value || ""}`}
                      options={ALL_CURRENCIES}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                          }}
                          helperText={
                            touched.nafdacChargesCurrency && errors.nafdacChargesCurrency
                              ? errors.nafdacChargesCurrency
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Nafdac Charges"
                      name="nafdacCharges"
                      type="text"
                      value={values?.nafdacCharges}
                      helperText={
                        touched.formMValue && errors.formMValue
                          ? errors.formMValue
                          : (values?.nafdacChargesCurrency &&
                              `At: ${values?.nafdacChargesRate || 0} ${localCurrency} = 1 ${
                                values?.nafdacChargesCurrency || ""
                              } ${localCurrency !== values?.nafdacChargesCurrency ? "(BL rate)" : ""}`) ||
                            ""
                      }
                      error={touched.nafdacChargesCurrency && errors.nafdacChargesCurrency}
                      onChange={(e) => {
                        handleChangeCustom(e);
                      }}
                      onFocus={(e) => {
                        e.target.type = "number";
                        e.target.value = values?.nafdacCharges?.toString()?.replaceAll(",", "");
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        e.target.type = "text";
                        e.target.value = formattedNumber(e.target.value);
                        handleChange(e);
                        handleBlur(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Autocomplete
                      value={findCompleteCurrency(values?.sonChargesCurrency || localCurrency) || ""}
                      fullWidth
                      disableClearable
                      variant="outlined"
                      size="small"
                      name="sonChargesCurrency"
                      onChange={(_, v) => {
                        setFieldValue("sonChargesCurrency", v?.value);
                        setFieldValue(
                          "sonChargesRate",
                          v?.value === localCurrency ? 1 : getRateOrDefault(initialValues?.blValue) || 0
                        );
                      }}
                      getOptionLabel={(option) => `${option.value || ""}`}
                      options={ALL_CURRENCIES}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                          }}
                          helperText={
                            touched.sonChargesCurrency && errors.sonChargesCurrency ? errors.sonChargesCurrency : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="SON Charges"
                      name="sonCharges"
                      type="text"
                      value={values?.sonCharges}
                      helperText={
                        touched.sonCharges && errors.sonCharges
                          ? errors.sonCharges
                          : (values?.sonChargesCurrency &&
                              `At: ${values?.sonChargesRate || 0} ${localCurrency} = 1 ${
                                values?.sonChargesCurrency || ""
                              } ${localCurrency !== values?.sonChargesCurrency ? "(BL rate)" : ""}`) ||
                            ""
                      }
                      error={touched.sonCharges && errors.sonCharges}
                      onChange={(e) => {
                        handleChangeCustom(e);
                      }}
                      onFocus={(e) => {
                        e.target.type = "number";
                        e.target.value = values?.sonCharges?.toString()?.replaceAll(",", "");
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        e.target.type = "text";
                        e.target.value = formattedNumber(e.target.value);
                        handleChange(e);
                        handleBlur(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Autocomplete
                      value={findCompleteCurrency(values?.quarantineChargesCurrency || localCurrency) || ""}
                      fullWidth
                      disableClearable
                      variant="outlined"
                      size="small"
                      name="quarantineChargesCurrency"
                      onChange={(_, v) => {
                        setFieldValue("quarantineChargesCurrency", v?.value);
                        setFieldValue(
                          "quarantineChargesRate",
                          v?.value === localCurrency ? 1 : getRateOrDefault(initialValues?.blValue) || 0
                        );
                      }}
                      getOptionLabel={(option) => `${option.value || ""}`}
                      options={ALL_CURRENCIES}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                          }}
                          helperText={
                            touched.quarantineChargesCurrency && errors.quarantineChargesCurrency
                              ? errors.quarantineChargesCurrency
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Quarantine Charges"
                      name="quarantineCharges"
                      type="text"
                      value={values?.quarantineCharges}
                      helperText={
                        touched.quarantineCharges && errors.quarantineCharges
                          ? errors.quarantineCharges
                          : (values?.quarantineChargesCurrency &&
                              `At: ${values?.quarantineChargesRate || 0} ${localCurrency} = 1 ${
                                values?.quarantineChargesCurrency || ""
                              } ${localCurrency !== values?.quarantineChargesCurrency ? "(BL rate)" : ""}`) ||
                            ""
                      }
                      error={touched.quarantineCharges && errors.quarantineCharges}
                      onChange={(e) => {
                        handleChangeCustom(e);
                      }}
                      onFocus={(e) => {
                        e.target.type = "number";
                        e.target.value = values?.quarantineCharges?.toString()?.replaceAll(",", "");
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        e.target.type = "text";
                        e.target.value = formattedNumber(e.target.value);
                        handleChange(e);
                        handleBlur(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fieldset>
          {!FxnsAndAllowedCountries?.formM?.includes(country) && (
            <FormM
              {...{
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                getRateOrDefault,
                localCurrency,
                rate: getRateOrDefault(initialValues?.blValue),
                formMData: Array.isArray(formMObjectArray) ? formMObjectArray : [],
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>

      <Grid container rowSpacing={3} mt={0.5} columnSpacing={2}>
        <Grid item xs={12}>
          <LoadingButton
            loading={isSubmitting}
            fullWidth
            disabled={!initialValues?.containerNumber}
            onClick={(e) => {
              if (formMUpdated()) {
                setShowSaveFormMConfirmation(true);
                return;
              }
              handleSubmit(e);
            }}
            startIcon={<MdUpdate />}
            loadingPosition="start"
            variant="contained"
          >
            update
          </LoadingButton>
        </Grid>
      </Grid>
      <Portal>
        <ConfirmationDialog
          isOpen={showSaveFormMConfirmation}
          handleClose={() => {
            setShowSaveFormMConfirmation(false);
          }}
          desc={[
            "Are you sure you want to update Form M?",
            "Changes you apply to this Form M will update all other BLs linked to this Form M number.",
          ]}
          okayText="Yes"
          onConfirm={async () => {
            setSubmitting(true);
            setShowSaveFormMConfirmation(false);
            let res = await updateFormM();
            setSubmitting(false);
            if (res?.data?.editFormMNumber?.success) {
              handleSubmit();
            }
          }}
        />
      </Portal>
    </>
  );
};

export default Finance;

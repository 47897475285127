import React, { useState, useEffect } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { AiFillCheckCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import "./eventstimeline.scss";
import { ParseServerDate } from "../../utils/helpers";

export default function CustomizedTimeline({ type, land, port, ocean, handleDynamicMapCenter }) {
  const [topData, setTopData] = useState([]);
  const [bottomData, setBottomData] = useState([]);

  useEffect(() => {
    const fnx = async () => {
      await determineOrder();
    };
    fnx();
  }, []);

  const determineOrder = () => {
    if (type === "origin") {
      setTopData(land);
      setBottomData(port);
    } else if (type === "destination") {
      setTopData(port);
      setBottomData(land);
    }
  };

  return (
    <Timeline position="right">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ bgcolor: "#4BD37B" }} />
          <TimelineConnector sx={{ bgcolor: "#4BD37B" }} />
        </TimelineSeparator>
        <TimelineContent>
          {type === "origin" && <div className="title112">Origin Land</div>}
          {type === "ocean" && <div className="title112">Vessel</div>}
          {type === "destination" && <div className="title112">Destination Port</div>}

          {type !== "ocean" && topData
            ? topData.map((item) => (
                <TimelineListItem handleDynamicMapCenter={handleDynamicMapCenter} item={item} key={Math.random()} />
              ))
            : type !== "ocean" && <div className="no-info">No information available</div>}

          {type === "ocean" && ocean
            ? ocean.map((item) => (
                <TimelineListItem handleDynamicMapCenter={handleDynamicMapCenter} item={item} key={Math.random()} />
              ))
            : type === "ocean" && <div className="no-info">No information available</div>}
        </TimelineContent>
      </TimelineItem>
      {type !== "ocean" && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ bgcolor: "#4BD37B" }} />
            <TimelineConnector sx={{ bgcolor: "#4BD37B" }} />
          </TimelineSeparator>
          <TimelineContent>
            {type === "origin" && <div className="title112">Origin Port</div>}
            {type === "destination" && <div className="title112">Destination Land</div>}

            {bottomData ? (
              bottomData.map((item) => (
                <TimelineListItem handleDynamicMapCenter={handleDynamicMapCenter} item={item} key={Math.random()} />
              ))
            ) : (
              <div className="no-info">No information available</div>
            )}
          </TimelineContent>
        </TimelineItem>
      )}
    </Timeline>
  );
}

const TimelineListItem = ({ item, handleDynamicMapCenter }) => {
  const getShipment = useSelector((state) => state.data.getShipment);

  return (
    <div
      className="timeline-item"
      onClick={() => {
        handleDynamicMapCenter(item?.location);
      }}
    >
      <div className="left12">
        {/* pref actual color  #D37B4B*/}
        <AiFillCheckCircle
          color={item?.actual ? (item?.source === "USER" ? "#033B8C" : "#4BD37B") : "#fb8b23"}
          size="20"
          className="icon12"
        />
        <div className="block w-full">
          <div className="title12">
            {`${item?.name}`?.replaceAll(",", "") ||
              `${item?.kind}`?.replaceAll(",", "") ||
              `${item?.description}`?.replaceAll(",", "")}
          </div>
          <div className="flex flex-row justify-between w-full">
            <div className="desc12">
              {" "}
              {getShipment?.locations.map((location) =>
                location?.id === item?.location
                  ? `${location?.name ? `${location?.name},` : ""} ${location?.country || ""}`
                  : ""
              )}
            </div>
            <div className="time12">
              <div>{ParseServerDate(item?.date, "DD/MM/YYYY, hh:mm  a")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

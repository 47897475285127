import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setShowNotification } from "../redux/data/data.actions";
import {
  BL_DOCUMENT,
  COMMERCIAL_INVOICE_DOCUMENT,
  NIGERIAN_CUSTOMS_DOCUMENT,
  THREE_SP_DOCUMENT,
} from "../graphql/models";

export const useModels = () => {
  const dispatch = useDispatch();
  const [getCommercialInvoiceDocument] = useLazyQuery(COMMERCIAL_INVOICE_DOCUMENT, {
    onCompleted: (data) => {
      return data?.commercialInvoiceDocument;
    },
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });

  const handleGetCommercialInvoiceDocument = async (link, parserVersion = 1) => {
    let result = await getCommercialInvoiceDocument({ variables: { link, parserVersion } });
    return result;
  };

  const [getNigerianCustomsDocument] = useLazyQuery(NIGERIAN_CUSTOMS_DOCUMENT, {
    onCompleted: (data) => {
      return data?.nigerianCustomsDocument;
    },
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });

  const handleGetNigerianCustomsDocument = async (link, parserVersion = 1) => {
    let result = await getNigerianCustomsDocument({ variables: { link, parserVersion } });
    return result?.data?.nigerianCustomsDocument;
  };

  const [getBlDocument] = useLazyQuery(BL_DOCUMENT, {
    onCompleted: (data) => {
      return data?.billOfLadingDocument;
    },
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });
  const handleBlDocument = async (link, parserVersion = 1) => {
    let result = await getBlDocument({ variables: { link, parserVersion } });
    return result?.data?.billOfLadingDocument;
  };

  const [get3SPDocument] = useLazyQuery(THREE_SP_DOCUMENT, {
    onCompleted: (data) => {
      return data?.serviceProviderInvoiceDocument;
    },
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });
  const handle3SPDocument = async (link, parserVersion = 1) => {
    let result = await get3SPDocument({ variables: { link /*, parserVersion*/ } });
    return result?.data?.serviceProviderInvoiceDocument;
  };

  return { handleGetCommercialInvoiceDocument, handleGetNigerianCustomsDocument, handleBlDocument, handle3SPDocument };
};

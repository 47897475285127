import React, { useState, useEffect, useRef } from "react";
import Popover from "@mui/material/Popover";
import { ReactComponent as NotificationIcon } from "../../assets/icons/notification.svg";
import { useSelector } from "react-redux";
import { ParseServerDate } from "../../utils/helpers";
import { IconButton, CardHeader, Tooltip, Divider, Button } from "@mui/material";
import Loader from "react-loader-spinner";
import { createTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { MdOutlineMarkAsUnread } from "react-icons/md";

export default function Notification({
  handleClose,
  anchorEl,
  id,
  currentCursor,
  setCurrentCursor,
  loadMoreNotifications,
}) {
  const notifications = useSelector((state) => state.user.notifications);
  const filteredNotification = notifications ? notifications.filter((item) => !item?.read) : [];
  const unreadnotifications = useSelector((state) => state.data.unreadNotifications);
  const [topmost, setTopmost] = useState(0);
  const history = useHistory();

  const onScrollHandle = (e) => {
    const scrollRef = e.target;

    const scrollTop = Math.floor(scrollRef.scrollTop);

    const clientHeight = Math.floor(scrollRef.clientHeight);

    const scrollHeight = Math.floor(scrollRef.scrollHeight);

    if (scrollHeight > topmost) {
      setTopmost(scrollHeight);
    }
    const currentPos = scrollTop + clientHeight;
    if (currentPos >= topmost) {
      setTopmost(currentPos);
      loadMoreNotifications(currentCursor + 1);
      setCurrentCursor(currentCursor + 1);
    }
  };
  return (
    <Popover
      id={id}
      open={Boolean(open)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{ maxHeight: "90vh", overflowY: "scroll" }}
      onScrollCapture={onScrollHandle}
    >
      <div style={{ margin: "2px auto", width: "95%" }}>
        <Tooltip title="Mark all as read">
          <Button
            aria-label="clear"
            sx={{ textTransform: "none", color: "#033b6c" }}
            onClick={() => handleClose()}
            startIcon={<MdOutlineMarkAsUnread />}
          >
            Mark all as read
          </Button>
        </Tooltip>
      </div>
      <Divider />
      <div className="notification-container">
        {filteredNotification.length === 0 && <div className="no-notification">You have no notification!</div>}
        {filteredNotification.length > 0 &&
          filteredNotification.map((item) => {
            return (
              <a
                key={item?.id}
                onClick={() => {
                  history.push(item?.url?.replaceAll(process.env.REACT_APP_URL, ""));
                  handleClose(item?.id);
                }}
              >
                <NotificationItem item={item} />
              </a>
            );
          })}
        {unreadnotifications > filteredNotification.length && <LoadingNotificationItem />}
      </div>
    </Popover>
  );
}

const NotificationItem = ({ item }) => {
  // const theme = createTheme();
  return (
    <div className="notification-item-container">
      <div className="left111">
        <NotificationIcon className="img111" />
      </div>
      <div className="right111">
        <div className="title111">{item?.event_type}</div>
        <div className="content111">{item?.description}</div>
        <div className="time111"> {ParseServerDate(item?.createdAt, "DD/MM/YYYY, hh:mm  a")}</div>
      </div>
    </div>
  );
};
const LoadingNotificationItem = () => {
  return (
    <div className="notification-item-container loading">
      <Loader type="TailSpin" color="grey" height="1.5rem" />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useCost from "../../hooks/useCost";
import { Autocomplete, Box, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { LocalizationProvider , DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Report from "../../components/Report/Report";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { addMoneySameCurrency, formattedNumber, getAmountInLocal } from "../../utils/helpers";
import CostAnalyticsTable from "../../components/Tables/CostAnalyticsTable";
import { Select } from "@mui/base";
import Loader from "react-loader-spinner";

const SERVICE_TYPES = [
  "Clearing Agency",
  "Transporter",
  "Shipping Line",
  "Terminal",
  "Nafdac Charges",
  "Customs Charges",
  "Quarantine Charges",
  "Son Charges",
  "ETLS",
  "CISS",
  "Surcharge",
  "Others",
];

const analytics = window.analytics;

ChartJS.register(ChartDataLabels);

const options = {
  plugins: {
    datalabels: {
      display: (context) => context.dataset.data[context.dataIndex] > 0,
      formatter: (value) => formattedNumber(value),
      align: "center",
      anchor: "center",
      padding: 2,
      font: (context) => ({ size: context.dataset.data[context.dataIndex] > 1 ? 10 : 10, weight: "bold" }),
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  skipNull: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      title: { display: true, align: "left", text: "Costs" },
    },
  },
};
const Analysis = () => {
  const theme = useTheme();
  const userData = useSelector((state) => state.user.userData);
  const localCurrency = useSelector((state) => state.user?.userData?.localCurrency);
  const [costData, setCostData] = useState({ datasets: [] });
  const [tableData, setTableData] = useState([]);
  const [totalBlValue, setTotalBlValue] = useState(0);
  const [shipments, setShipments] = useState("loading");
  const { handleGetAllUserInvoicesWithBL, handleGetInvoiceReportShipment } = useCost();

  const trasformDataUsing = (a, b) => {
    let res = {};
    b = {
      billOfLadingNumber: b?.billOfLadingNumber,
      status: b?.status,
      active: b?.active,
      blValue: b?.blValue,
      customs: addMoneySameCurrency(b?.customsDutyExcludingVAT, b?.vat),
      nafdac: b?.nafdacCharges,
      quarantineCharges: b?.quarantineCharges,
      sonCharges: b?.sonCharges,
      surcharge: b?.surcharge,
      ciss: b?.ciss,
      levies: b?.levies,
      etls: b?.etls,
      clearingMonth: b?.clearingMonth,
    };

    const keys = Object.keys(b);
    keys.forEach((key) => {
      if (a && a[key]) {
        res[key] = a[key];
      } else {
        res[key] = b[key];
      }
    });
    return res;
  };

  const downloadableInvoices = useSelector((state) => state?.data?.allUserDownloadInvoices);
  const [allBLs, setAllBLs] = useState([]);

  const formik = useFormik(
    {
      initialValues: {
        blFilter: [],
        serviceTypesFilter: SERVICE_TYPES,
        startDate: "",
        endDate: "",
      },
      onSubmit: (values) => {},
    },
    []
  );

  useEffect(() => {
    options.scales.y.title.text = `Cost in ${localCurrency}`;
  }, [localCurrency]);

  useEffect(() => {
    document.title = "aTrace - Visibility - Cost Analysis";
    analytics?.track("Using cost analysis", {
      email: userData?.email,
    });
    handleGetAllUserInvoicesWithBL();
    const fetchShipment = async () => {
      let shms = await handleGetInvoiceReportShipment();
      setShipments(shms);
    };
    fetchShipment();
  }, []);

  // useEffect(() => {
  //   if (downloadableInvoices) {
  //     const bls = downloadableInvoices.map((invoice) => invoice?.blNumber);
  //     setAllBLs(bls);
  //     formik.setFieldValue("blFilter", bls);
  //   }
  // }, [downloadableInvoices]);

  useEffect(() => {
    if (shipments && shipments !== "loading") {
      const bls = shipments?.trackings?.map((shipment) => ({
        bl: shipment?.tracking?.billOfLadingNumber,
        active: shipment?.tracking?.active,
        date: shipment?.tracking?.clearingMonth,
      }));
      const distinctBl = {};
      bls.forEach((bl) => {
        if (!distinctBl[bl.bl]) {
          distinctBl[bl.bl] = bl;
        }
      });
      const distinctBlArray = Object.values(distinctBl);
      distinctBlArray.sort((a, b) => {
        if (a.active && !b.active) {
          return -1;
        } else if (!a.active && b.active) {
          return 1;
        }
        return a?.bl.localeCompare(b?.bl);
      });
      setAllBLs(distinctBlArray);
      const activeBls = distinctBlArray.filter((bl) => bl.active);
      formik.setFieldValue("blFilter", activeBls);
      formik.setFieldValue("status", "active");
    }
  }, [shipments]);

  useEffect(() => {
    if (downloadableInvoices && shipments && shipments !== "loading") {
      const data = { ["BL Value"]: 0 };
      const tmp = {};
      const sD = {};
      const labels = [];
      formik?.values?.serviceTypesFilter?.forEach((serviceType) => {
        data[serviceType] = 0;
        tmp[serviceType] = true;
      });

      let sh;
      shipments?.trackings?.forEach((sht) => {
        sh = sht?.tracking;

        sD[sh?.billOfLadingNumber] = trasformDataUsing(sD?.[sh?.billOfLadingNumber], sh);
      });
      const blObject = downloadableInvoices?.reduce((acc, invoice) => {
        acc[invoice?.blNumber] = {};
        invoice?.invoices?.forEach((service) => {
          if (tmp[service?.serviceType]) {
            if (!acc[invoice?.blNumber][service?.serviceType]) {
              acc[invoice?.blNumber][service?.serviceType] = {};
            }
            acc[invoice?.blNumber][service?.serviceType]["value"] =
              (acc?.[invoice?.blNumber]?.[service?.serviceType]?.["value"] || 0) +
              getAmountInLocal(service?.invoiceValue);
            acc[invoice?.blNumber][service?.serviceType]["data"] = {
              ...service,
              ...(sD?.[invoice?.blNumber] || {}),
            };
            acc[invoice?.blNumber]["data"] = {
              "Clearing Agency": acc?.[invoice?.blNumber]?.["Clearing Agency"]?.["value"] || 0,
              Transporter: acc?.[invoice?.blNumber]?.["Transporter"]?.["value"] || 0,
              "Shipping Line": acc?.[invoice?.blNumber]?.["Shipping Line"]?.["value"] || 0,
              Terminal: acc?.[invoice?.blNumber]?.["Terminal"]?.["value"] || 0,
              Others: acc?.[invoice?.blNumber]?.["Others"]?.["value"] || 0,
              ...(sD?.[invoice?.blNumber] || {}),
            };
          }
        });
        return acc;
      }, {});
      Object.keys(sD).forEach((bl) => {
        if (!blObject[bl]) {
          blObject[bl] = {
            data: { "Clearing Agency": 0, Transporter: 0, "Shipping Line": 0, Terminal: 0, Others: 0, ...sD[bl] },
          };
        }
      });
      let td = [];
      formik?.values?.blFilter?.forEach((bl) => {
        bl = bl?.bl;
        Object.keys(data).forEach((serviceType) => {
          data[serviceType] += blObject[bl]?.[serviceType]?.["value"] || 0;
        });
        data["BL Value"] += getAmountInLocal(blObject[bl]?.data?.blValue) || 0;
        data["Nafdac Charges"] += getAmountInLocal(blObject[bl]?.data?.nafdac) || 0;
        data["Customs Charges"] += getAmountInLocal(blObject[bl]?.data?.customs) || 0;
        data["Customs Charges"] += getAmountInLocal(blObject[bl]?.data?.customs) || 0;
        data["Quarantine Charges"] += getAmountInLocal(blObject[bl]?.data?.quarantineCharges) || 0;
        data["Son Charges"] += getAmountInLocal(blObject[bl]?.data?.sonCharges) || 0;
        data["ETLS"] += getAmountInLocal(blObject[bl]?.data?.etls) || 0;
        data["CISS"] += getAmountInLocal(blObject[bl]?.data?.ciss) || 0;
        data["Surcharge"] += getAmountInLocal(blObject[bl]?.data?.surcharge) || 0;

        td.push(blObject[bl]?.data || null);
      });
      const reportData = (formik?.values?.serviceTypesFilter || []).map((serviceType, index) =>
        (data[serviceType] || 0).toFixed(2)
      );
      const datasets = [
        {
          data: reportData,
          label: "Cost Analysis",
          backgroundColor: `${theme.palette.primary.light}`,
        },
      ];
      setCostData({ labels: formik?.values?.serviceTypesFilter || [], datasets });
      setTableData(td);
      setTotalBlValue(data["BL Value"]);
    }
  }, [downloadableInvoices, formik.values.blFilter, formik.values.serviceTypesFilter, shipments]);

  return (
    <Report
      title="Cost Analysis"
      renderChart={() => (
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              <Grid item xs={12} md={4} lg={2} display={"flex"} gap={1} flexDirection="column">
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  height="100%"
                  border={`3px double ${theme.palette.primary.light}`}
                  p={".5rem"}
                  borderRadius={2}
                >
                  <Typography fontWeight="bold" variant="subtitle2">
                    BL Value Total
                  </Typography>
                  <Typography
                    sx={{
                      overflowWrap: "break-word",
                    }}
                    variant="h4"
                    fontSize="1rem"
                  >
                    {localCurrency + " "} {formattedNumber(totalBlValue)}
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  height="100%"
                  border={`3px double ${theme.palette.primary.light}`}
                  p={".5rem"}
                  borderRadius={2}
                >
                  <Typography fontWeight="bold" variant="subtitle2">
                    Services Total
                  </Typography>
                  <Typography
                    sx={{
                      overflowWrap: "break-word",
                    }}
                    variant="h4"
                    fontSize="1rem"
                  >
                    {localCurrency + " "}{" "}
                    {formattedNumber(costData?.datasets?.[0]?.data?.reduce((a, b) => a * 1 + b * 1, 0) * 1)}
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  height="100%"
                  border={`3px double ${theme.palette.primary.light}`}
                  p={".5rem"}
                  borderRadius={2}
                >
                  <Typography fontWeight="bold" variant="subtitle2">
                    Grand Total
                  </Typography>
                  <Typography
                    sx={{
                      overflowWrap: "break-word",
                    }}
                    variant="h4"
                    fontSize="1rem"
                  >
                    {localCurrency + " "}{" "}
                    {formattedNumber(
                      costData?.datasets?.[0]?.data?.reduce((a, b) => a * 1 + b * 1, 0) * 1 + totalBlValue * 1
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={10}>
                <Bar data={costData} options={options} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {tableData ? (
              <CostAnalyticsTable keys={formik?.values?.serviceTypesFilter} data={tableData} />
            ) : (
              <Typography textAlign="center">Loading...</Typography>
            )}
          </Grid>
        </Grid>
      )}
      divisions={false}
      renderFilter={() => (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={0}>
              <Grid item xs={9}>
                <Autocomplete
                  options={allBLs || []}
                  multiple
                  limitTags={1}
                  fullWidth
                  size="small"
                  value={formik.values.blFilter || []}
                  onChange={(e, value) => formik.setFieldValue("blFilter", value)}
                  getOptionLabel={(option) =>
                    option?.bl ? `${option?.bl} - ${option?.active ? "active" : "closed"}` : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,

                        sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                      }}
                      label="Bill of Lading"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  fullWidth
                  label="Status"
                  value={formik.values.status || ""}
                  type="select"
                  select
                  InputProps={{
                    sx: { borderRadius: "0 4px 4px 0" },
                  }}
                  onChange={(e) => {
                    formik.setFieldValue("status", e.target.value);
                    if (e.target.value === "all") {
                      formik.setFieldValue("blFilter", allBLs);
                    } else if (e.target.value === "active") {
                      formik.setFieldValue(
                        "blFilter",
                        allBLs.filter((bl) => bl.active)
                      );
                    } else {
                      formik.setFieldValue(
                        "blFilter",
                        allBLs.filter((bl) => !bl.active)
                      );
                    }
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="closed">Closed</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Autocomplete
              options={SERVICE_TYPES}
              fullWidth
              size="small"
              multiple
              limitTags={1}
              onChange={(e, value) => formik.setFieldValue("serviceTypesFilter", value)}
              value={formik.values.serviceTypesFilter || []}
              renderInput={(params) => <TextField {...params} label="Service Types" />}
            />
          </Grid>

          {/* <Grid item xs={12} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                  },
                }}
                label={"Start Date"}
                onChange={(date) => {}}
              />
            </LocalizationProvider>
          </Grid> */}

          {/* <Grid item xs={12} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                  },
                }}
                label={"Start Date"}
                onChange={(date) => {}}
              />
            </LocalizationProvider>
          </Grid> */}
        </Grid>
      )}
    />
  );
};

export default Analysis;

import React from "react";
import { Popover, Card, Button, CardContent, FormGroup, FormControlLabel, Checkbox, Grid, Stack } from "@mui/material";

const ColumnMenu = ({
  open,
  anchorEl,
  handleClose,
  columnPreferences,
  setColumnPreference,
  reset,
  handleAllCheckboxes,
  allCheckboxes,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{
        elevation: 1,
        sx: {
          borderColor: "#033b6c",
          borderWidth: "2px",
          "&:after": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 45,
            left: -6,
            width: 12,
            height: 12,
            bgcolor: "#393939",
            borderWidth: "2px",
            borderColor: "#033b6c",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 800,
          },
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{}}
    >
      <Card className="styled-scrollbars" sx={{ minWidth: 250, maxWidth: 250, maxHeight: 450, overflowY: "scroll" }}>
        <CardContent>
          <Stack
            container
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              minHeight: "2.85rem",
              backgroundColor: "#f8f9ff",
              width: "100%",
              zIndex: 1,
              px: 2,
            }}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            PaperProps={{ elevation: 1 }}
          >
            <Button size="small" onClick={reset}>
              reset
            </Button>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onClick={handleAllCheckboxes} checked={allCheckboxes} />}
                label="all"
              />
            </FormGroup>
          </Stack>
          <Grid container sx={{ marginTop: "2.85rem", flexDirection: "column" }}>
            {columnPreferences.map((key, index) => (
              <Grid item key={`${index}oo1`} xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox onClick={(e) => setColumnPreference(index, e.target.checked)} checked={key?.show} />
                    }
                    label={key?.title}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </CardContent>
        {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
      </Card>
    </Popover>
  );
};

export default ColumnMenu;

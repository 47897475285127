import { useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import useDashboardInfo from "./useDashboardInfo";
import { GET_COST_REPORT_SHIPMENT, GET_USER_INVOICES_DOWNLOAD } from "../graphql/cost";
import { setShowNotification , setAllUserDownloadInvoices } from "../redux/data/data.actions";
const useCost = () => {
  const dispatch = useDispatch();
  const { logOut } = useDashboardInfo();

  const [getAllUserInvoicesWithBL] = useLazyQuery(GET_USER_INVOICES_DOWNLOAD, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getAllUserInvoicesWithBL?.code && data?.getCost?.message) {
        if (data?.getAllUserInvoicesWithBL?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getCost?.message, type: "error" }));
      } else {
        dispatch(setAllUserDownloadInvoices(data?.getAllUserInvoicesWithBL?.data));
        return data?.getAllUserInvoicesWithBL?.data;
      }
    },
    onError(error) {
      console.error(error);
      setAllUserDownloadInvoices([]);
    },
  });

  const handleGetAllUserInvoicesWithBL = async () => await getAllUserInvoicesWithBL();

  const [getInvoicesTracking] = useLazyQuery(GET_COST_REPORT_SHIPMENT, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getDownloadableTrackings?.code && data?.getDownloadableTrackings?.message) {
        if (data?.getDownloadableTrackings?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.getDownloadableTrackings?.message, type: "error" })
        );
        return null;
      } else {
        return data?.getDownloadableTrackings;
      }
    },
    onError(error) {
      console.error(error);
      dispatch(
        setShowNotification({ isOpen: true, message: "An error occured while trying to download", type: "error" })
      );
      return null;
    },
  });

  const handleGetInvoiceReportShipment = async (q = {}) => {
    let res = await getInvoicesTracking({
      variables: { q },
    });
    let dat = res?.data;
    return dat?.getDownloadableTrackings || null;
  };
  return { handleGetAllUserInvoicesWithBL, handleGetInvoiceReportShipment };
};

export default useCost;

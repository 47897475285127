import React, { useEffect, useState } from "react";
import Report from "../../components/Report";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { bulletProofSum, firstDayCurrentYear, getMonthRange, stringToColour, fixDate } from "../../utils/helpers";
import { Grid, Fab, Button, ButtonGroup } from "@mui/material";
import { MdDownload } from "react-icons/md";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import writeXlsxFile from "write-excel-file";
import { useSelector } from "react-redux";
import useTracking from "../../hooks/useTracking";
import Loader from "../../components/Loader/Loader";
import EfficiencyReportTable from "../../components/Tables/EfficiencyReportTable";
import { months as getMonth } from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Efficiency Report Chart",
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const EfficiencyReport = () => {
  const { handleGetEfficiencyReport, handleGetETAStatusPrefences } = useTracking();
  const [startDate, setStartDate] = useState(dayjs(firstDayCurrentYear()));
  const [endDate, setEndDate] = useState(dayjs());
  const [currentChart, setCurrentChart] = useState("ES");
  const [labels, setLabels] = useState(getMonthRange(startDate, endDate));
  const efficiencyData = useSelector((state) => state?.data?.reports?.efficiency);
  const ETAStatusPreferences = useSelector((state) => state?.data?.ETAStatusPreferences);
  const [datasets, setDatasets] = useState([]);
  const options = {};
  const [downloadingXcel, setDownloadingXcel] = useState(false);
  useEffect(() => {
    handleGetETAStatusPrefences();
  }, []);

  useEffect(() => {
    setLabels(getMonthRange(startDate, endDate));
    handleGetEfficiencyReport({
      fromDate: startDate ? dayjs(startDate).format("YYYY-MM-DD HH:mm:ss") : null,
      toDate: endDate ? dayjs(endDate).format("YYYY-MM-DD HH:mm:ss") : null,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (efficiencyData?.length && efficiencyData !== "loading" && ETAStatusPreferences) {
      const tableData = efficiencyData?.map((er) => {
        const totalClearingDays =
          parseInt(bulletProofSum(er?.tracking?.ageDestinationPort, er.tracking?.ageDestinationLand)) || 0;
        const expectedClearingDays =
          parseInt(
            bulletProofSum(
              ETAStatusPreferences?.destinationLand?.critical,
              ETAStatusPreferences?.destinationPort?.critical
            )
          ) || 0;
        const efficiencyScore = parseInt(expectedClearingDays - totalClearingDays);
        const clearingMonth = dayjs(er?.tracking?.clearingMonth);
        const deliveryLeadTime =
          bulletProofSum(er.tracking.ageOriginLand, er.tracking.ageOriginPort, er.tracking.ageOcean) || 0;
        return {
          ...er.tracking,
          ...er.leadTime,
          totalClearingDays,
          expectedClearingDays,
          deliveryLeadTime,
          efficiencyScore,
          cY: clearingMonth.year(),
          cM: clearingMonth.month(),
          clearingMonth: er?.tracking.clearingMonth ? clearingMonth.format("DD/MM/YYYY") : "N/A",
          closed: !er?.tracking?.active ? "True" : "False",
        };
      });
      const chartData = tableData?.reduce((cum, cur) => {
        let months = cum?.[cur?.cY]?.months || [];
        let data = cum?.[cur?.cY]?.data || [];
        let cmpos = cur?.cM - startDate.month();
        cmpos = cmpos < 0 ? cmpos + 12 : cmpos;
        let cm = months[cmpos] || {};
        months[cmpos] = {
          count: +(cm?.count || 0) + 1,
          tCD: +(cm?.tCD || 0) + cur?.totalClearingDays,
          tES: +(cm?.tES || 0) + cur?.efficiencyScore,
          data: [...(cm.data || []), cur],
          year: cur?.cY,
          month: getMonth(cur?.cM),
        };
        let { tES, tCD, count } = months[cmpos];
        months[cmpos].avgTES = (tES / count).toFixed(1);
        months[cmpos].avgTCD = (tCD / count).toFixed(1);
        data[cmpos] = currentChart === "ES" ? months[cmpos].avgTES : months[cmpos].avgTCD;
        cum[cur?.cY] = {
          id: cum[cur?.cY]?.id || cur?.cY,
          label: cum[cur?.cY]?.label || cur?.cY + ` ${currentChart === "ES" ? "Efficiency Score" : "Clearing Data"}`,
          backgroundColor: cum[cur?.cY]?.backgroundColor || stringToColour(cur?.cY + currentChart),
          months,
          data,
          stack: cur?.cY + "",
        };
        return cum;
      }, {});

      setDatasets(Object.values(chartData));
    }
  }, [efficiencyData, currentChart]);

  const getfilter = () => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Start Date"
            value={startDate}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
              },
            }}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="End Date"
            value={endDate}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
              },
            }}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );

  const getChart = () => {
    return efficiencyData === "loading" ? (
      <Loader />
    ) : (
      <Grid container flexDirection={"column"} spacing={1}>
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <ButtonGroup variant="text" color="inherit" aria-label="text button group">
            <Button onClick={() => setCurrentChart("ES")} color={currentChart === "ES" ? "warning" : "inherit"}>
              Efficiency Score
            </Button>
            <Button onClick={() => setCurrentChart("CD")} color={currentChart === "CD" ? "warning" : "inherit"}>
              Clearing Days
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <Bar options={options} data={{ datasets, labels }} />
        </Grid>
      </Grid>
    );
  };

  const getData = () => {
    return efficiencyData === "loading" ? (
      <Loader />
    ) : (
      <>
        <EfficiencyReportTable {...{ data: datasets }} />
        <Fab
          color="primary"
          aria-label="add"
          disabled={downloadingXcel}
          size="small"
          onClick={downloadFormMExcel}
          sx={{
            position: "fixed",
            opacity: 0.85,
            right: 12,
            bottom: "50%",
            "&:hover": {
              opacity: 0.95,
            },
          }}
        >
          <MdDownload />
        </Fab>
      </>
    );
  };

  const downloadFormMExcel = async () => {
    const efficiencyDataSchema = [
      {
        column: "Container Number",
        value: (row) => row?.containerNumber || "N/A",
        width: 20,
        type: String,
      },
      {
        column: "Bill of Lading",
        value: (row) => row?.billOfLadingNumber || "N/A",
        width: 15,
        type: String,
      },
      {
        column: "Closed",
        value: (row) => row?.closed || "N/A",
        width: 8,
        type: String,
      },
      {
        column: "Commodity Description",
        value: (row) => row?.commodityDescription || "N/A",
        width: 25,
        type: String,
      },
      {
        column: "Size",
        value: (row) => row?.containerSize || "N/A",
        width: 13,
        type: String,
      },
      {
        column: "Type",
        value: (row) => row?.containerType || "N/A",
        width: 13,
        type: String,
      },
      {
        column: "Clearing Agent",
        value: (row) => row?.clearingAgent || "N/A",
        width: 25,
        type: String,
      },
      {
        column: "Transporter",
        value: (row) => row?.transporter || "N/A",
        width: 25,
        type: String,
      },
      {
        column: "Clearing Date",
        value: (row) => (fixDate(row?.clearingMonth) ? new Date(fixDate(row?.clearingMonth)) : null),
        width: 15,
        format: "dd/mm/yyyy",
        type: Date,
      },

      {
        column: "Goods Ready Days",
        value: (row) => parseInt(row?.goodsReadyDays) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Origin Land (Days)",
        value: (row) => parseInt(row?.ageOriginLand) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "origin Port (Days)",
        value: (row) => parseInt(row?.ageOriginPort) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Ocean (Days)",
        value: (row) => parseInt(row?.ageOcean) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Destination Port Days",
        value: (row) => parseInt(row?.ageDestinationPort) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Destination Land Days",
        value: (row) => parseInt(row?.ageDestinationLand) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Total Clearing Days",
        value: (row) => parseInt(row?.totalClearingDays) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Expected Clearing Days",
        value: (row) => parseInt(row?.expectedClearingDays) || 0,
        width: 20,
        type: Number,
      },

      {
        column: "Efficiency Score",
        value: (row) => row?.efficiencyScore || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Delivery Lead Time",
        value: (row) => parseInt(row?.deliveryLeadTime) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Total Lead Time",
        value: (row) => parseInt(row?.total) || 0,
        width: 20,
        type: Number,
      },
    ];
    const efficiencyChartSchema = [
      {
        column: "Year",
        value: (row) => row?.year + "" || "N/A",
        width: 8,
        type: String,
      },
      {
        column: "Month",
        value: (row) => row?.month || "N/A",
        width: 8,
        type: String,
      },
      {
        column: "№ of Containers",
        value: (row) => parseInt(row?.count) || 0,
        width: 15,
        type: Number,
      },
      {
        column: "Total Clearing Days",
        value: (row) => parseInt(row?.tCD) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Total Efficiency Score",
        value: (row) => parseInt(row?.tES) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Average Clearing Days Score",
        value: (row) => parseInt(row?.avgTCD) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Average Efficiency Score",
        value: (row) => parseInt(row?.avgTES) || 0,
        width: 20,
        type: Number,
      },
    ];
    const efficiencyChartData = datasets
      .map((data) => data?.months)
      .flat()
      ?.filter((data) => data);
    const efficiencyData = efficiencyChartData?.map((data) => data?.data).flat();
    let result = await writeXlsxFile([efficiencyChartData, efficiencyData], {
      schema: [efficiencyChartSchema, efficiencyDataSchema],
      sheets: ["Efficiency Summary", "Efficiency Data"],
    });
    saveAs(result, "efficiency-report.xlsx");
  };
  return (
    <Grid container>
      <Grid item xs={12} sx={{ position: "relative" }}>
        <Report title="Efficiency Report" renderChart={getChart} renderFilter={getfilter} renderData={getData} />
      </Grid>
    </Grid>
  );
};

export default EfficiencyReport;

import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { getCurrentSessionId, getUserToken } from "../utils/storage";

export const AxiosPromptInstance = axios.create({
  baseURL: process.env.REACT_APP_INSIGHT_BASE_URL,
  timeout: 180000,
});

export const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_INSIGHT_BASE_URL,
  timeout: 180000,
});

export const AxiosGenerateUploadInstance = axios.create({
  baseURL: process.env.REACT_APP_ATRACE_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const AxiosDocumentUploadInstance = axios.create({
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

let refreshPromptSessionTimer: ReturnType<typeof setTimeout>;

const scheduleSessionRefresh = () => {
  if (refreshPromptSessionTimer) {
    clearTimeout(refreshPromptSessionTimer); // Clear the existing timer if it exists
  }

  // Set the timer to refresh the session ID after 24 hours (86400000 milliseconds)
  refreshPromptSessionTimer = setTimeout(() => {
    localStorage.removeItem("promptSessionId");
  }, 86400000); // 24 hours in milliseconds
};

// Common request interceptor
const commonRequestInterceptor = async (
  request: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const session = await getUserToken();
  const currSessionId = getCurrentSessionId();

  if (session) {
    request.headers = request.headers || {};
    request.headers["authorization"] = `Bearer ${session.accessToken}`;
  }

  if (currSessionId) {
    request.headers = request.headers || {};
    request.headers["x-session-id"] = currSessionId;
  }

  return request;
};

const handleRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

// Adding the common request interceptor to both instances
AxiosInstance.interceptors.request.use(
  commonRequestInterceptor,
  handleRequestError
);
AxiosPromptInstance.interceptors.request.use(
  commonRequestInterceptor,
  handleRequestError
);

AxiosPromptInstance.interceptors.response.use(
  (response) => {
    const res = response.headers["x-session-id"];

    localStorage.setItem("promptSessionId", res);

    const currSessionId = getCurrentSessionId();

    if (currSessionId) {
      scheduleSessionRefresh();
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosGenerateUploadInstance.interceptors.request.use(
  async (request) => {
    const session = await getUserToken();

    if (session) {
      request.headers["authorization"] = `Bearer ${session?.accessToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

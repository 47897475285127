import React from "react";
import { Grid, Button, Typography } from "@mui/material";
import { MdUpgrade, MdWorkspacePremium, MdCreditCard, MdReceipt } from "react-icons/md";
import { useHistory } from "react-router-dom";
import usePayment from "../../../hooks/usePayment";
const ActionBox = ({ plan, processor, planCode, billingPeriod }) => {
  const history = useHistory();
  const { handleGetSubscriptionManagementLink, handleCreateStripeSubscriptionSession } = usePayment();
  const [linkLoading, setLinkLoading] = React.useState(false);
  const handleManageSubscription = async () => {
    setLinkLoading(true);
    if (processor === "PAYSTACK" || processor === "STRIPE") {
      const link = await handleGetSubscriptionManagementLink(`${process.env.REACT_APP_URL}/dashboard/subscription`);
      if (link?.subscriptionManagementLink && typeof link?.subscriptionManagementLink === "string") {
        window.open(link?.subscriptionManagementLink, "_blank", "toolbar=0,location=0,menubar=0,width=700,height=900");
      }
    }
    setLinkLoading(false);
  };
  return (
    <Grid my={2} container spacing={1} alignItems="flex-end">
      {(plan !== "enterprise" && (
        <Grid item xs={12} md={plan === "free" ? 12 : 6}>
          <Typography fontSize={"1rem"}>
            {plan === "free"
              ? "Unleash the full potential of visibility with a premium plan."
              : "Upgrade to a higher plan to get more features."}
          </Typography>
          <Button
            fullWidth
            endIcon={plan === "free" ? <MdWorkspacePremium /> : <MdUpgrade />}
            onClick={() => {
              history.push("/dashboard/subscription/subscribe");
            }}
            variant="outlined"
            color="secondary"
            sx={{
              mt: 2,
              textTransform: "capitalize",
              py: 1,
              "&:hover": { transform: "scale(1.03)" },
              transition: "transform 0.3s",
            }}
          >
            {`${plan === "free" ? "Subscribe" : "Upgrade"} `}
          </Button>
        </Grid>
      )) ||
        ""}
      {(plan !== "free" && (
        <>
          <Grid item xs={12} md={6}>
            <Typography fontSize={"1rem"}>Manage current Subscription on your payment platform.</Typography>
            <Button
              onClick={handleManageSubscription}
              disabled={linkLoading || !processor}
              fullWidth
              endIcon={<MdCreditCard />}
              variant="outlined"
              color="primary"
              sx={{
                mt: 2,
                py: 1,
                "&:hover": { transform: "scale(1.03)" },
                transition: "transform 0.3s",
                textTransform: "capitalize",
              }}
            >
              Manage{" "}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography fontSize={"1rem"}>Activate, View, and Manage Extra Charges (Overage).</Typography>
            <Button
              fullWidth
              variant="outlined"
              disabled
              color="info"
              endIcon={<MdReceipt />}
              sx={{
                mt: 2,
                textTransform: "capitalize",
                py: 1,
                "&:hover": { transform: "scale(1.03)" },
                transition: "transform 0.3s",
              }}
            >
              Overage{" "}
            </Button>
          </Grid>
        </>
      )) ||
        ""}
    </Grid>
  );
};

export default ActionBox;

import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Box, Portal, Modal, Autocomplete } from "@mui/material";
import useTracking from "../../hooks/useTracking";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { useLazyQuery } from "@apollo/client";
import { GET_BILL_CONTAINERS } from "../../graphql/auth";
import { GET_SINGLE_TRACKINGS } from "../../graphql/tracking";
import { setShowNotification } from "../../redux/data/data.actions";
import Shipment from "./Forms/Shipment";
import Clearance from "./Forms/Clearance";
import Finance from "./Forms/Finance";
import Document from "./Forms/Document";
import ConfirmationDialog from "../../components/Confirmation/ConfirmationDialog";
import { useParams } from "react-router-dom";
import {
  addMoney,
  fieldsArrayToObject,
  fixDate,
  fixPDFDate,
  getNormalizedRate,
  stringToCurrency,
  toTitleCase,
} from "../../utils/helpers";
import Pills from "../../components/Pills";
import { MdUploadFile } from "react-icons/md";
import { useFile } from "../../hooks/useFile";
import { useModels } from "../../hooks/useModels";
import { useDocument } from "../../hooks/useDocument";
import GenericFileUpload from "../../components/FileUpload/GenericFileUpload";
import { findCountry } from "../../utils/all-countries";

const ManageShipment = () => {
  const { logOut, handleUpdateTracking, handleGetBill, handleGetHelperData, handleGetFormMData } = useTracking();
  const { handleGetCustomFileLink } = useFile();
  const { handleGetNigerianCustomsDocument } = useModels();
  const { handleCreateDocument } = useDocument();
  // const history = useHistory();
  const params = useParams();
  // const query = useQuery();
  const helperData = useSelector((state) => state?.data?.vendors);
  const dispatch = useDispatch();
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [prefilledData, setPrefilledData] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [containerNo, setContainerNo] = React.useState(params?.container ? [params?.container] : []);
  const [currentCurrency, setCurrentCurrency] = React.useState(initialValues?.blValue?.currency);
  const [value, setValue] = useState(-1);
  const [tmpValue, setTmpValue] = useState(null);
  const [valuesChange, setValuesChanged] = useState(false);
  const [openUnsaved, setOpenUnsaved] = useState(false);
  const [billOfLadingValue, setBillOfLadingValue] = useState("");
  const [shippingLineValue, setShippingLineValue] = useState("");
  const [allContainers, setAllContainers] = useState([...containerNo]);
  const [clearingAgentOpt, setClearingAgentOpt] = useState([]);
  const [transporterOpt, setTransporterOpt] = useState([]);
  const [commodityDescriptionOpt, setCommodityDescriptionOpt] = useState([]);
  const [commodityCodeOpt, setCommodityCodeOpt] = useState([]);
  const [customsCommandOpt, setCustomsCommandOpt] = useState([]);
  const [bill, setBill] = useState([]);
  const [file, setFile] = React.useState(null);
  const [url, setUrl] = React.useState("");
  const [createDocument, setCreateDocument] = React.useState(false);
  const [prevValues, setPrevValues] = useState(0);
  const localCurrency = useSelector((state) => state?.user?.userData?.localCurrency);
  const serverBill = useSelector((state) => state.data.bills);

  const preFillToShipment = (data, _, file, url) => {
    const fieldsData = fieldsArrayToObject(data?.fields);
    // const containerData = fieldsArrayToObject(fieldsData?.containerDetails?.valueArray);

    let documentCurrency = fieldsData?.documentCurrency?.valueString || "";
    const {
      billOfLading,
      clearingAgent,
      transporter,
      commodityDescription,
      externalReferenceNumber,
      goodsReadyDays,
      commodityCode,
      shipmentAdviceReceptionDate,
      fcl,
      fclType,
      customsCommand,
      draftDocumentsReceptionDate,
      originalDocumentsReceptionDate,
      paarSubmissionDate,
      paarCopyReceptionDate,
      sgdNumber,
      sgdDate,
      assessmentReceptionDate,
      dutyAssessmentNumber,
      invoiceNumber,
      invoiceQuantity,
      formMNumber,
      formMValue,
      formMApprovalDate,
      formMExpiryDate,
      baNumber,
      bankName,
      customsDutyExcludingVAT,
      vat,
      customsDutyIncludingVAT,
      dutyRate,
      dutyExchangeRate,
      dutyPaymentDate,
      hscode,
      nafdacCharges,
      quarantineCharges,
      sonCharges,
      blValue,
      surcharge,
      ciss,
      levies,
      etls,
      manifestNumber,
      paarNumber,
      destinationCountry,
      originCountry,
      shipperName,
      shipperAddress,
      shipperContacts,
      consigneeName,
      consigneeAddress,
      consigneeContacts,
      notifyPartyName,
      notifyPartyAddress,
      deliveryName,
      deliveryAddress,
      deliveryContacts,
      clearingAgentAddress,
      transporterAddress,
      shippingMode,
      shippingTerms,
      deliveryTerms,
      shippingRemarks,
      grossWeight,
      netWeight,
      sealNumber,
      customsRegistrationNumber,
      serviceContractNumber,
    } = initialValues;

    let res = {
      ...initialValues,
      containerNumber: fieldsData?.containerNumber,
      billOfLading: fieldsData?.billofLading?.valueString,
      clearingAgent: fieldsData?.declarantName?.valueString || clearingAgent,
      transporter,
      commodityDescription: fieldsData?.commodityDescription?.valueString || commodityDescription,
      externalReferenceNumber,
      goodsReadyDays,
      commodityCode: fieldsData?.commodityCode?.valueString || commodityCode,
      shipmentAdviceReceptionDate,
      fcl,
      fclType,
      customsCommand: fieldsData?.exitOffice?.valueString || customsCommand,
      draftDocumentsReceptionDate,
      originalDocumentsReceptionDate,
      paarSubmissionDate,
      paarCopyReceptionDate,
      sgdNumber: fieldsData?.sgdNumber?.valueString || sgdNumber,
      sgdDate: fixPDFDate(fieldsData?.registrationDate?.valueString) || fixDate(sgdDate),
      assessmentReceptionDate: fixPDFDate(fieldsData?.assessmentDate?.valueString) || fixDate(assessmentReceptionDate),
      dutyAssessmentNumber: fieldsData?.assessmentNumber?.valueString || dutyAssessmentNumber,
      invoiceNumber: fieldsData?.receiptNumber?.valueString || invoiceNumber,
      invoiceQuantity: fieldsData?.quantity?.valueString || invoiceQuantity,
      formMNumber,
      formMValue,
      formMApprovalDate,
      formMExpiryDate,
      baNumber,

      bankName: fieldsData?.bank?.valueString || bankName,
      customsDutyExcludingVAT:
        stringToCurrency(fieldsData?.dutyAmount?.valueString, 1, documentCurrency) || customsDutyExcludingVAT,
      vat: stringToCurrency(fieldsData?.vatAmount?.valueString, 1, documentCurrency) || vat,
      customsDutyIncludingVAT:
        addMoney(
          stringToCurrency(fieldsData?.vatAmount?.valueString, 1, documentCurrency),
          stringToCurrency(fieldsData?.dutyAmount?.valueString, 1, documentCurrency)
        ) || customsDutyIncludingVAT,
      dutyRate: fieldsData?.dutyRate?.valueString || dutyRate,
      dutyExchangeRate: fieldsData?.exchangeRate?.valueString || dutyExchangeRate,
      dutyPaymentDate: fixPDFDate(fieldsData?.receiptDate?.valueString) || fixDate(dutyPaymentDate),
      hscode: fieldsData?.commodityCode?.valueString || hscode,
      nafdacCharges,
      quarantineCharges,
      sonCharges,
      blValue:
        addMoney(
          stringToCurrency(fieldsData?.blValueAmount?.valueString, fieldsData?.exchangeRate?.valueString),
          stringToCurrency(fieldsData?.exfreightAmount?.valueString, 1, "")
        ) || blValue,
      surcharge: stringToCurrency(fieldsData?.surchargeAmount?.valueString, 1, documentCurrency) || surcharge,
      ciss: stringToCurrency(fieldsData?.cisAmount?.valueString, 1, documentCurrency) || ciss,
      levies: stringToCurrency(fieldsData?.leviesAmount?.valueString, 1, documentCurrency) || levies,
      etls: stringToCurrency(fieldsData?.etlAmount?.valueString, 1, documentCurrency) || etls,
      manifestNumber: fieldsData?.manifestNumber?.valueString || manifestNumber,
      paarNumber: fieldsData?.paarNumber?.valueString || paarNumber,
      destinationCountry: findCountry(fieldsData?.countryDestination?.valueString)?.code || destinationCountry,
      originCountry: findCountry(fieldsData?.countryOrigin?.valueString)?.code || originCountry,
      shipperName: fieldsData?.payeeName?.valueString || shipperName,
      shipperAddress: fieldsData?.payeeAddress?.valueString || shipperAddress,
      shipperContacts: fieldsData?.payeeCode?.valueString || shipperContacts,
      consigneeName: fieldsData?.consgineeName?.valueString || consigneeName,
      consigneeAddress: fieldsData?.consigneeAddress?.valueString || consigneeAddress,
      consigneeContacts: consigneeContacts,
      notifyPartyName,
      notifyPartyAddress,
      deliveryName,
      deliveryAddress,
      deliveryContacts,
      clearingAgentAddress,
      transporterAddress,
      shippingMode,
      customsRegistrationNumber: fieldsData?.registrationNumber?.valueString || customsRegistrationNumber,
      shippingTerms: fieldsData?.shippingTerms?.valueString || shippingTerms,
      deliveryTerms: fieldsData?.deliveryTerms?.valueString || deliveryTerms,
      shippingRemarks: fieldsData?.remarks?.valueString || shippingRemarks,
      grossWeight: fieldsData?.grossWeight?.valueString || grossWeight,
      netWeight: fieldsData?.netWeight?.valueString || netWeight,
      sealNumber,
      serviceContractNumber,
    };

    setFile(file);
    setUrl(url);
    if (
      `${fieldsData?.billOfLadingNumber?.valueString || ""}`?.trim()?.toLowerCase() ===
      `${billOfLading || ""}`?.trim()?.toLowerCase()
    ) {
      setInitialValues(res);
      setCreateDocument(true);
    } else {
      setPrefilledData(res);
    }
    setValue(prevValues);
  };

  const [getTrackings] = useLazyQuery(GET_SINGLE_TRACKINGS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getAllTrackings?.code && data?.getAllTrackings?.message) {
        if (data?.getAllTrackings?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getAllTrackings?.message, type: "error" }));
        setInitialValues({ containerNumber: containerNo });
        setBillOfLadingValue("");
        setShippingLineValue("");
        setValue(0);
      } else {
        let fdata = data?.getAllTrackings?.items?.[0];
        setShippingLineValue(`${fdata?.shippingLine}`);
        const {
          containerNumber,
          billOfLadingNumber,
          clearingAgent,
          transporter,
          commodityDescription,
          externalReferenceNumber,
          goodsReadyDays,
          commodityCode,
          shipmentAdviceReceptionDate,
          fcl,
          fclType,
          customsCommand,
          draftDocumentsReceptionDate,
          originalDocumentsReceptionDate,
          paarSubmissionDate,
          paarCopyReceptionDate,
          sgdNumber,
          sgdDate,
          assessmentReceptionDate,
          dutyAssessmentNumber,
          invoiceNumber,
          invoiceQuantity,
          formMNumber,
          formMValue,
          formMApprovalDate,
          formMExpiryDate,
          baNumber,
          bankName,
          customsDutyExcludingVAT,
          vat,
          customsDutyIncludingVAT,
          dutyRate,
          dutyExchangeRate,
          dutyPaymentDate,
          hscode,
          nafdacCharges,
          quarantineCharges,
          sonCharges,
          blValue,
          surcharge,
          ciss,
          levies,
          etls,
          manifestNumber,
          paarNumber,
          destinationCountry,
          originCountry,
          shipperName,
          shipperAddress,
          shipperContacts,
          consigneeName,
          consigneeAddress,
          consigneeContacts,
          notifyPartyName,
          notifyPartyAddress,
          deliveryName,
          deliveryAddress,
          deliveryContacts,
          clearingAgentAddress,
          transporterAddress,
          shippingMode,
          shippingTerms,
          deliveryTerms,
          shippingRemarks,
          grossWeight,
          netWeight,
          sealNumber,
          serviceContractNumber,
        } = fdata;

        fdata = {
          containerNumber,
          clearingAgent,
          transporter,
          commodityDescription,
          externalReferenceNumber,
          goodsReadyDays,
          commodityCode,
          fcl,
          fclType,
          customsCommand,
          sgdNumber,
          dutyAssessmentNumber,
          invoiceNumber,
          invoiceQuantity,
          formMNumber,
          formMValue,
          baNumber,
          bankName,
          customsDutyExcludingVAT,
          vat,
          customsDutyIncludingVAT,
          dutyRate,
          dutyExchangeRate,
          hscode,
          nafdacCharges,
          quarantineCharges,
          sonCharges,
          blValue,
          surcharge,
          ciss,
          levies,
          etls,
          billOfLading: billOfLadingNumber,
          draftDocumentsReceptionDate: fixDate(draftDocumentsReceptionDate),
          shipmentAdviceReceptionDate: fixDate(shipmentAdviceReceptionDate),
          originalDocumentsReceptionDate: fixDate(originalDocumentsReceptionDate),
          paarSubmissionDate: fixDate(paarSubmissionDate),
          paarCopyReceptionDate: fixDate(paarCopyReceptionDate),
          sgdDate: fixDate(sgdDate),
          assessmentReceptionDate: fixDate(assessmentReceptionDate),
          formMApprovalDate: fixDate(formMApprovalDate),
          formMExpiryDate: fixDate(formMExpiryDate),
          dutyPaymentDate: fixDate(dutyPaymentDate),
          manifestNumber,
          paarNumber,
          destinationCountry,
          originCountry,
          shipperName,
          shipperAddress,
          shipperContacts,
          consigneeName,
          consigneeAddress,
          consigneeContacts,
          notifyPartyName,
          notifyPartyAddress,
          deliveryName,
          deliveryAddress,
          deliveryContacts,
          clearingAgentAddress,
          transporterAddress,
          shippingMode,
          shippingTerms,
          deliveryTerms,
          shippingRemarks,
          grossWeight,
          netWeight,
          sealNumber,
          serviceContractNumber,
        };

        setInitialValues(fdata);
        setBillOfLadingValue(fdata?.billOfLading);
        setValue(0);
      }
    },
    onError(error) {
      console.error(error);
      setInitialValues({ containerNumber: containerNo });
      setBillOfLadingValue("");
      setShippingLineValue("");
      setValue(0);
    },
  });

  const reset = () => {
    setInitialValues({});
  };

  const [getBillOfLadingContainers, { loading }] = useLazyQuery(GET_BILL_CONTAINERS, {
    onCompleted(data) {
      if (data?.getUserContainerNumbers?.code && data?.getUserContainerNumbers?.message) {
        if (data?.getUserContainerNumbers?.code == 401) {
          return logOut();
        }
        setAllContainers(containerNo);
      } else {
        setAllContainers([...data?.getUserContainerNumbers.result]);
        if (containerNo.length === 0 && billOfLadingValue) {
          changeContainerNo([data?.getUserContainerNumbers.result[0]]);
        }
      }
    },
    onError(error) {
      console.error(error);
      setAllContainers(containerNo);
    },
  });

  const changeContainerNo = (containerNumber) => {
    if (containerNumber.length > 0) {
      getTrackings({
        variables: {
          page: { number: 1, size: -1, sortBy: "created_at", sortDirectionDesc: true },
          q: {
            containerNumber: containerNumber[containerNumber?.length - 1 || 0],
          },
        },
      });
      setValue(-1);
      setContainerNo(containerNumber);
    }
    if (!billOfLadingValue && !containerNumber?.length) {
      setContainerNo([]);
    }
  };

  const getBLContainers = () => {
    if (billOfLadingValue || /*nulify if*/ 1 == "1") {
      getBillOfLadingContainers({ variables: { filter: { billOfLading: billOfLadingValue || "" } } });
    }
  };

  const refresh = (containerNumber) => {
    getTrackings({
      variables: {
        page: { number: 1, size: -1, sortBy: "created_at", sortDirectionDesc: true },
        q: {
          containerNumber,
        },
      },
    });
  };

  const updateTracking = async (values) => {
    let findata = {
      ...values,
    };
    const { containerNumber, shippingLine } = findata;
    delete findata?.shippingLine;
    delete findata.containerNumber;
    let finalResult;
    if (billOfLadingValue && allContainers?.length === containerNo?.length) {
      finalResult = await handleUpdateTracking(findata, { billOfLading: billOfLadingValue, containerNumber: "" });
    } else {
      const result = containerNo.map(async (containerNumber) => {
        return await handleUpdateTracking(findata, { billOfLading: "", containerNumber });
      });
      finalResult = result.reduce((promiseChain, currentPromise) => promiseChain && currentPromise);
    }
    if (finalResult) {
      setInitialValues({ ...findata, containerNumber, shippingLine });
    }
    return finalResult;
  };

  const documentTypeData = [
    {
      name: "Customs SGD",
      linkFxn: handleGetCustomFileLink,
      modelFxn: handleGetNigerianCustomsDocument,
    },
    //{
    //  name: "Bill of Lading",
    //  linkFxn: handleGetBlFileLink,
    //  modelFxn: handleBlDocument,
    //},
  ];

  const handleChange = (_, v) => {
    if (valuesChange) {
      setTmpValue(v);
      setOpenUnsaved(true);
    } else {
      setValue(v);
    }
  };

  const switchTab = (state) => {
    if (state) {
      setValue(tmpValue);
      setValuesChanged(false);
    }
    setTmpValue(null);
    setOpenUnsaved(false);
  };

  useEffect(() => {
    if (initialValues) {
      setCurrentCurrency(initialValues?.blValue?.currency);
    }
  }, [initialValues]);

  useEffect(() => {
    if (containerNo.length > 0 && containerNo[0]) {
      refresh(containerNo[0]);
    }
    handleGetHelperData();
    handleGetBill("all", {});
  }, []);
  useEffect(() => {
    if (containerNo.length > 0 && containerNo[0]) {
      handleGetFormMData();
    }
  }, [
    initialValues.formMNumber,
    initialValues.formMValue,
    initialValues.formMApprovalDate,
    initialValues.formMExpiryDate,
    initialValues?.blValue?.rate,
    initialValues?.blValue?.currency,
    initialValues?.blValue?.value,
  ]);
  useEffect(() => {
    if (billOfLadingValue || /*nulify if*/ 1 == "1") {
      getBLContainers();
    }
    if (!billOfLadingValue) {
      reset();
    }
    setContainerNo([]);
    setValue(-1);
  }, [billOfLadingValue]);

  const updateAddByBL = () => {
    setContainerNo([...allContainers]);
  };

  useEffect(() => {
    if (serverBill && serverBill?.length > 0) {
      setBill(serverBill?.filter((item) => item));
    }
  }, [serverBill]);

  const getRateOrDefault = (money, def = 1) =>
    money?.value
      ? getNormalizedRate(money?.rate)
      : getNormalizedRate(money?.rate) || (money?.currency && money?.currency !== localCurrency ? def : 1);
  const getDefaultRateFromCurrency = (currency, def = 1) => (currency && currency !== localCurrency ? def : 1);
  const getCurrencyOrDefault = (money, def = localCurrency || "USD") => (!money ? null : money?.currency || def);

  useEffect(() => {
    if (!loading && value === -1 && (initialValues?.containerNumber || !containerNo || !params?.container)) {
      setValue(0);
    }
  }, [loading, initialValues]);

  useEffect(() => {
    const clearingAgent = new Set();
    const transporter = new Set();
    const commodityDescription = new Set();
    const commodityCode = new Set();
    const customsCommand = new Set();
    if (helperData) {
      helperData?.forEach((helperDatum) => {
        if (helperDatum?.clearingAgent) {
          clearingAgent?.add(helperDatum?.clearingAgent);
        }
        if (helperDatum?.transporter) {
          transporter?.add(helperDatum?.transporter);
        }
        if (helperDatum?.commodityDescription) {
          commodityDescription?.add(toTitleCase(helperDatum?.commodityDescription));
        }
        if (helperDatum?.commodityCode) {
          commodityCode?.add(toTitleCase(helperDatum?.commodityCode));
        }
        if (helperDatum?.customsCommand) {
          customsCommand?.add(toTitleCase(helperDatum?.customsCommand));
        }
      });
      setClearingAgentOpt(Array.from(clearingAgent).sort((a, b) => a.localeCompare(b)));
      setTransporterOpt(Array.from(transporter).sort((a, b) => a.localeCompare(b)));
      setCommodityDescriptionOpt(Array.from(commodityDescription).sort((a, b) => a.localeCompare(b)));
      setCommodityCodeOpt(Array.from(commodityCode).sort((a, b) => a.localeCompare(b)));
      setCustomsCommandOpt(Array.from(customsCommand).sort((a, b) => a.localeCompare(b)));
    }
  }, [helperData]);

  return (
    <>
      <Box sx={{ pt: { xs: 2, md: 3, lg: 5 }, pb: { xs: 10, md: 0 }, px: { xs: 1, sm: 2 } }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <Box
              component="form"
              sx={{
                mb: "1rem",
              }}
              noValidate
            >
              <Grid container direction={{ xs: "column", md: "row" }} spacing={2}>
                <Grid item md={12} xs={12}>
                  <Grid container rowSpacing={2} columnSpacing={3}>
                    <Grid item md={7} xs={12} alignSelf={"flex-end"}>
                      <Grid container spacing={1}>
                        <Grid item md={8} xs={12} alignSelf={"flex-end"}>
                          {/* <TextField fullWidth label="Bill of Lading" variant="standard" value={billOfLadingValue} /> */}
                          <Autocomplete
                            options={bill || []}
                            getOptionLabel={(option) => option || ""}
                            loading={bill.length === 0}
                            disabled={params?.container || bill.length === 0}
                            value={billOfLadingValue}
                            onChange={(e, v) => setBillOfLadingValue(v || "")}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment:
                                    bill.length === 0 && !params?.container ? (
                                      <Loader type="Oval" color="grey" height={20} width={20} />
                                    ) : null,
                                  // params.InputProps.startAdornment
                                }}
                                fullWidth
                                label="Bill of Lading"
                                variant="standard"
                              />
                            )}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={4} alignSelf={"flex-end"}>
                          <Button
                            disabled={!billOfLadingValue || allContainers?.length === containerNo?.length}
                            variant="contained"
                            elevation={0}
                            onClick={updateAddByBL}
                            // sx={{ borderRadius: 0 }}
                            fullWidth
                          >
                            apply on bl
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        fullWidth
                        disabled
                        value={shippingLineValue || ""}
                        label="Shipping Line"
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    options={allContainers}
                    getOptionLabel={(option) => option}
                    value={containerNo}
                    ChipProps={{
                      variant: "outlined",
                      color: "primary",
                    }}
                    onChange={(e, v) => changeContainerNo(v)}
                    renderInput={(params) => (
                      <TextField {...params} label="Container" placeholder="Containers" variant="standard" />
                    )}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box width={"100%"} display="flex" justifyContent="flex-end">
                    <Button
                      disabled={containerNo?.length === 0}
                      endIcon={<MdUploadFile />}
                      variant="outlined"
                      onClick={() => {
                        setPrevValues(value);
                        setValue(-1);
                        setOpenFileUpload(true);
                      }}
                      sx={{ textAlign: "right", textTransform: "none" }}
                    >
                      Prefill with a file upload
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* Tabs  */}
            <Pills
              disabled={!billOfLadingValue && !containerNo?.length}
              value={value}
              handleChange={handleChange}
              setValue={setValue}
              elements={[
                {
                  title: "Shipment",
                  component: () => (
                    <Shipment
                      {...{
                        bill,
                        initialValues,
                        handleUpdateTracking,
                        updateTracking,
                        setValuesChanged,
                        commodityCodeOpt,
                        commodityDescriptionOpt,
                        getRateOrDefault,
                        getDefaultRateFromCurrency,
                        getCurrencyOrDefault,
                        setCurrentCurrency,
                      }}
                      disabled={!billOfLadingValue && !containerNo?.length}
                    />
                  ),
                },
                {
                  title: "Clearance",
                  component: () => (
                    <Clearance
                      {...{
                        initialValues,
                        handleUpdateTracking,
                        updateTracking,
                        setValuesChanged,
                        customsCommandOpt,
                        clearingAgentOpt,
                        transporterOpt,
                        getRateOrDefault,
                        getCurrencyOrDefault,
                        getDefaultRateFromCurrency,
                      }}
                      disabled={!billOfLadingValue && !containerNo?.length}
                    />
                  ),
                },
                {
                  title: "Government Charges",
                  component: () => (
                    <Finance
                      {...{
                        initialValues,
                        handleUpdateTracking,
                        updateTracking,
                        setValuesChanged,
                        getRateOrDefault,
                        getCurrencyOrDefault,
                        getDefaultRateFromCurrency,
                        setCurrentCurrency,
                      }}
                    />
                  ),
                },
                {
                  title: "Documents",
                  component: () => (
                    <Document
                      useBl={containerNo?.length === allContainers?.length && billOfLadingValue}
                      containers={containerNo}
                      bl={billOfLadingValue}
                    />
                  ),
                },
              ]}
            ></Pills>
          </Grid>
        </Grid>
      </Box>
      <Portal>
        <Modal open={openFileUpload}>
          <Box
            height="100%"
            width="100%"
            display="flex"
            position="relative"
            justifyContent="center"
            alignItems="center"
          >
            <GenericFileUpload
              OPTIONS={documentTypeData}
              setParsedData={preFillToShipment}
              cleanUpFxn={() => {
                setValue(prevValues);
                setOpenFileUpload(false);
              }}
            />
          </Box>
        </Modal>
        <ConfirmationDialog
          desc={["You have unsaved changes.", "Are you sure you want to leave?"]}
          isOpen={openUnsaved}
          handleClose={() => switchTab(false)}
          onConfirm={() => switchTab(true)}
        />
        <ConfirmationDialog
          desc={["Do you want to save a copy of the uploaded file?"]}
          severity={"success"}
          isOpen={createDocument}
          handleClose={() => {
            setFile(null);
            setUrl("");
            setCreateDocument(false);
          }}
          onConfirm={async () => {
            let filename = file?.name || "file";
            let contentType = file?.type || "application/pdf";
            let contentLength = file?.size || 1024;
            let extension = filename?.split(".")?.length > 1 ? filename?.split(".")?.pop() : "";
            await handleCreateDocument(
              `Shipment-extraction-${Date.now()}`,
              billOfLadingValue,
              url,
              "CUSTOMS_DECLARATION",
              extension,
              contentLength,
              contentType
            );
            setFile(null);
            setUrl("");
            setCreateDocument(false);
          }}
        />
        <ConfirmationDialog
          desc={[
            `The Extracted BL is ${prefilledData?.billOfLading}, and the Existing BL is ${initialValues?.billOfLading} !`,
            "Are you sure you want to continue the Prefill?",
          ]}
          isOpen={Boolean(prefilledData)}
          handleClose={() => {
            setPrefilledData(null);
          }}
          additionalClose={() => {
            setFile(null);
            setUrl("");
            setCreateDocument(false);
          }}
          onConfirm={() => {
            // use current BL and container
            prefilledData.billOfLading = initialValues.billOfLading;
            prefilledData.containerNumber = initialValues.containerNumber;
            setInitialValues(prefilledData);
            setPrefilledData(null);
            setCreateDocument(true);
          }}
        />
      </Portal>
    </>
  );
};

export default ManageShipment;

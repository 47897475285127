import { useDispatch } from "react-redux";
import {
  CREATE_COMMON_HEALTH_DECLARATION,
  DELETE_COMMON_HEALTH_DECLARATION,
  GET_ALL_COMMON_HEALTH_DECLARATION,
  GET_ALL_SUMMARIZE_COMMON_HEALTH_DECLARATION,
  GET_SINGLE_COMMON_HEALTH_DECLARATION,
  UPDATE_COMMON_HEALTH_DECLARATION,
} from "../graphql/service";
import { useLazyQuery, useMutation } from "@apollo/client";
import { setShowNotification } from "../redux/data/data.actions";
import useDashboardInfo from "./useDashboardInfo";

export const useService = () => {
  const dispatch = useDispatch();
  const { logOut } = useDashboardInfo();

  const [summarizeCommonHealthDeclarations] = useLazyQuery(GET_ALL_SUMMARIZE_COMMON_HEALTH_DECLARATION, {
    errorPolicy: "all",
    onCompleted(data) {
      return data;
    },
    onError(error) {
      dispatch(setShowNotification({ isOpen: true, message: error.message, type: "error" }));
    },
  });

  const getAllSummarizeHealthDeclarations = () => {
    const data = summarizeCommonHealthDeclarations();

    return data;
  };

  const [commonHealthDeclarations] = useLazyQuery(GET_ALL_COMMON_HEALTH_DECLARATION, {
    errorPolicy: "all",
    onCompleted(data) {
      return data;
    },
    onError(error) {
      dispatch(setShowNotification({ isOpen: true, message: error.message, type: "error" }));
    },
  });

  const getAllCommonHealthDeclarations = (page = 1, perPage = 10) => {
    const data = commonHealthDeclarations({
      variables: {
        input: {
          page,
          perPage,
        },
      },
    });

    return data;
  };

  const [getCommonHealthDeclaration] = useLazyQuery(GET_SINGLE_COMMON_HEALTH_DECLARATION, {
    errorPolicy: "all",
    onCompleted(data) {
      return data;
    },
    onError(error) {
      dispatch(setShowNotification({ isOpen: true, message: error.message, type: "error" }));
    },
  });

  const getSingleCommonHealthDeclarations = (identifier) => {
    const data = getCommonHealthDeclaration({
      variables: {
        identifier,
      },
    });

    return data;
  };

  const [createCommonHealthDeclaration] = useMutation(CREATE_COMMON_HEALTH_DECLARATION, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.createCommonHealthDeclaration?.code && data?.createCommonHealthDeclaration?.message) {
        if (data?.createCommonHealthDeclaration?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.createCommonHealthDeclaration?.message, type: "error" })
        );
      } else {
        if (data?.createCommonHealthDeclaration) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Form has been saved successfully!`,
              type: "success",
              color: "success",
            })
          );
        } else {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Something went wrong. Please try again or contact support@atrace.co`,
              type: "error",
              color: "error",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred adding Event. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
    },
  });

  const handleCreateCommonHealthDeclaration = (input) => {
    const data = createCommonHealthDeclaration({
      variables: {
        input,
      },
    });

    return data;
  };

  const [updateCommonHealthDeclaration] = useMutation(UPDATE_COMMON_HEALTH_DECLARATION, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.updateCommonHealthDeclaration?.code && data?.updateCommonHealthDeclaration?.message) {
        if (data?.updateCommonHealthDeclaration?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.updateCommonHealthDeclaration?.message, type: "error" })
        );
      } else {
        if (data?.updateCommonHealthDeclaration) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Form has been saved successfully!`,
              type: "success",
              color: "success",
            })
          );
        } else {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Something went wrong. Please try again or contact support@atrace.co`,
              type: "error",
              color: "error",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred adding Event. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
    },
  });

  const handleUpdateCommonHealthDeclaration = (input) => {
    const data = updateCommonHealthDeclaration({
      variables: {
        input,
      },
    });

    return data;
  };

  const [deleteCommonHealthDeclaration] = useMutation(DELETE_COMMON_HEALTH_DECLARATION, {
    refetchQueries: [{ query: GET_ALL_SUMMARIZE_COMMON_HEALTH_DECLARATION }],
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.deleteCommonHealthDeclaration?.code && data?.deleteCommonHealthDeclaration?.message) {
        if (data?.deleteCommonHealthDeclaration?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.deleteCommonHealthDeclaration?.message, type: "error" })
        );
      } else {
        if (data?.deleteCommonHealthDeclaration) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Notification has been deleted successfully!`,
              type: "success",
              color: "success",
            })
          );
        } else {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Something went wrong. Please try again or contact support@atrace.co`,
              type: "error",
              color: "error",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while deleting Notification. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
    },
  });

  const handleDeleteCommonHealthDeclaration = (identifier) => {
    const data = deleteCommonHealthDeclaration({
      variables: {
        identifier,
      },
    });

    return data;
  };

  return {
    getAllSummarizeHealthDeclarations,
    getAllCommonHealthDeclarations,
    getSingleCommonHealthDeclarations,
    handleCreateCommonHealthDeclaration,
    handleUpdateCommonHealthDeclaration,
    handleDeleteCommonHealthDeclaration,
  };
};

import { gql } from "@apollo/client";

export const UPDATE_USER_DATA = gql`
  mutation UpdateUser($userUpdateRequest: UpdateUserRequest!) {
    updateUser(input: $userUpdateRequest) {
      ... on User{
        id
        name
        email
        emailVerified
        gender
        signupMode
        profilePicture
        telephone
        companyName,
        usageInfo
        address
        country
        localCurrency
        lastLoggedInAt
        createdAt
        deletedAt
        updatedAt
        dateTermsAccepted
      }

      ... on ResponseError{
        message
        code
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword){
      ... on BooleanType {
        value
      }
      ... on ResponseError{
        message
        code
      }
    }
  }
`;
export const ACCEPT_PLATFORM_TERMS = gql`
  mutation acceptPlatformTerms{
    acceptPlatformTerms{
      ... on BooleanType {
        value
      }
      ... on ResponseError{
        message
        code
      }
    }
  }
`;
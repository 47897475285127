import { gql } from "@apollo/client";

export const GET_USER_INVOICES_DOWNLOAD = gql`
  query getAllUserInvoicesWithBL {
    getAllUserInvoicesWithBL {
      message
      success
      data {
        blNumber
        invoices {
          id
          serviceType
          serviceProvider
          invoiceNumber
          invoiceValue {
            rate
            currency
            value
          }
          description
          blNumber
        }
      }
    }
  }
`;

export const GET_COST_REPORT_SHIPMENT = gql`
  query getDownloadableTrackings($q: DownloadableTrackingFilters) {
    getDownloadableTrackings(q: $q) {
      ... on DownloadableTrackings {
        trackings {
          tracking {
            id
            billOfLadingNumber
            status
            active
            blValue {
              rate
              value
              currency
            }
            customsDutyExcludingVAT {
              rate
              value
              currency
            }
            vat {
              rate
              value
              currency
            }

            nafdacCharges {
              rate
              value
              currency
            }
            quarantineCharges {
              rate
              value
              currency
            }
            sonCharges {
              rate
              value
              currency
            }
            blValue {
              rate
              value
              currency
            }
            surcharge {
              rate
              value
              currency
            }
            ciss {
              rate
              value
              currency
            }
            levies {
              rate
              value
              currency
            }
            etls {
              rate
              value
              currency
            }
            clearingMonth
          }
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

import React from "react";
import { Box, Typography, List, ListItemIcon, ListItem, ListItemText, Button, useTheme } from "@mui/material";
import { MdCheck } from "react-icons/md";
const SubscriptionCard = ({
  name = "Lite",
  desc = "lightweight plan",
  curr = "NGN",
  price = "5000",
  frequency = "anually",
  selected = false,
  disabled = false,
  loading = false,
  boldFirst = true,
  featureList = [],
  handleSelect = () => {},
  buttonText = "Subscribe",
  ...others
}) => {
  const theme = useTheme();

  return (
    <Box
      width="90%"
      boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
      borderRadius="5px"
      flexGrow={1}
      border={`1px solid ${
        disabled ? theme.palette.primary.light : selected ? theme.palette.warning.light : "#E0E0E0"
      }`}
      bgcolor={`${selected ? "#fee8d3" : loading ? "#E0E0E0" : "inherit"}`}
      sx={{
        maxWidth: "450px",
        padding: { xs: 1.5, sm: 2, md: 2.5, lg: 2, xl: 3.5 },
        cursor: loading ? "not-allowed" : "pointer",

        "&:hover": {
          transform: loading ? "none" : "scale(1.02)",
          borderColor: loading ? "#E0E0E0" : "#cdd8e2",
        },
        transition: "all 0.2s ease",
      }}
      {...others}
    >
      <Typography
        variant="h2"
        fontSize="1.7rem"
        lineHeight={3}
        color="primary"
        component={"h2"}
        textTransform="uppercase"
      >
        {name}
      </Typography>
      <Typography minHeight={"4rem"} variant="body2" lineHeight={2} component={"p"}>
        {desc?.split("*")[0]}
        {(desc?.split("*")[1] && (
          <>
            <br />
            <b>
              <small>
                <i>*{desc?.split("*")[1]}</i>
              </small>
            </b>
          </>
        )) ||
          ""}
      </Typography>
      <Typography py={1} fontSize={"1.2rem"} minHeight={"3.5rem"} lineHeight={2}>
        {(curr &&
          price &&
          `${curr}${price} per ${frequency === "monthly" ? "month" : frequency === "annually" ? "year" : frequency}`) ||
          ""}
      </Typography>
      <Button
        disabled={loading || disabled}
        onClick={handleSelect}
        sx={{ textTransform: "capitalize", my: "1.5rem" }}
        variant="outlined"
        fullWidth
      >
        {buttonText}
      </Button>
      <Typography variant="subtitle2">This Plan Includes</Typography>
      <List dense>
        {featureList?.map((feature, index) => (
          <ListItem disablePadding disableRipple disableGutters key={index}>
            <ListItemIcon sx={{ minWidth: 30 }} color="success">
              <MdCheck color={theme.palette.success.main} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontWeight: index == 0 && boldFirst ? 500 : 300 }}
              primary={feature}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
export default SubscriptionCard;

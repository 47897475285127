import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Box ,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Autocomplete,
} from "@mui/material/";
import { FiPlus, FiTrash2, FiEdit2 } from "react-icons/fi";
import { countries, findCountry } from "../../utils/all-countries";
import { Fieldset } from "../../components/v2/form/fieldset";
import useTracking from "../../hooks/useTracking";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import SortButton from "../../components/SortButton";
import { StyledToolTip } from "../../components/StyledComponents";
import TradeTermsRow from "../../components/CollapsibleTable/TradeTermsRow";
import { setTablePreference } from "../../redux/data/data.actions";
import {
  ALL_CURRENCIES,
  CONTAINER_TYPES,
  SERVICE_TYPES,
  findCompleteCurrency,
  findContainerType,
  findProvider,
} from "../../utils/data";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getSlabText } from "../../utils/helpers";
import ConfirmationButton from "../../components/ConfirmationButton/ConfirmationButton";
import Loader from "../../components/Loader/Loader";
let uid = 0;
const TradeTerms = () => {
  /* Variables */
  const {
    handleGetServiceProviders,
    handleGetTradeTerms,
    handleSaveTradeTerm,
    handleUpdateTradeTerm,
    handleDeleteTradeTerm,
  } = useTracking();

  const formik = useFormik({
    initialValues: {
      country: "",
      serviceType: "",
      provider: "",
      currency: "",
      containerType: "",
      containerSize: "",
      freeTime: "",
      slabs: [
        {
          id: ++uid,
          startDay: "",
          endDay: "",
          rate: "",
        },
      ],
    },
    validationSchema: Yup.object({
      country: Yup.string().required("Country is required"),
      serviceType: Yup.string().required("Service Type is required"),
      provider: Yup.string().required("Provider is required"),
      currency: Yup.string().required("Currency is required"),
      containerType: Yup.string().required("Container Type is required"),
      containerSize: Yup.string().required("Container Size is required"),
      freeTime: Yup.number().required("Free Time is required").min(0, "Free Time cannot be less than 0"),
      slabs: Yup.array()
        .min(1, "You should have at Least one slab")
        .of(
          Yup.object().shape({
            id: Yup.number().required("id is required"),
            startDay: Yup.number().required("Start Day is required"),
            endDay: Yup.number()
              .required("End Day is required")
              .moreThan(Yup.ref("startDay"), "End Day must be greater than Start Day"),
            rate: Yup.number().required("Rate is required"),
          })
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      let tradeterm = {
        ...values,
        slabs: values.slabs.map((slab) => ({ startDay: slab.startDay, endDay: slab.endDay, rate: slab.rate })),
      };
      let res;
      if (ttid && ttid != -1) {
        res = await handleUpdateTradeTerm({ id: ttid, ...tradeterm });
      } else {
        res = await handleSaveTradeTerm(tradeterm);
      }
      if (res?.data?.saveTradeTerms?.result || res?.data?.updateTradeTerms?.result) {
        resetForm();
        setTtid(-1);
      }
    },
  });

  const dispatch = useDispatch();
  const tradeTermsData = useSelector((state) => state?.data?.tradeTerms);
  const tablePreference = useSelector((state) => state.data.tablePreference);
  const providers = useSelector((state) => state?.data?.providers);

  const [ttid, setTtid] = useState(-1);
  const [tableData, setTableData] = useState([]);

  /* Use Effects */
  useEffect(() => {
    handleGetTradeTerms();
  }, []);

  useEffect(() => {
    setRowsPerPage(tablePreference);
  }, [tablePreference]);

  useEffect(() => {
    if (tradeTermsData && typeof tradeTermsData != "string") {
      setTableData(tradeTermsData);
    }
  }, [tradeTermsData]);

  /* Functions */
  const deleteTradeTerm = (id) => handleDeleteTradeTerm(id);

  const loadData = (id) => {
    setTtid(id);
    let data = tradeTermsData.find((data) => data.id == id);
    if (data) {
      data.slabs =
        data?.slabs?.map((slab) => ({ id: ++uid, startDay: slab.startDay, endDay: slab.endDay, rate: slab.rate })) ||
        [];
      setValues(data);
    }
  };

  const addSlab = () => {
    const { slabs } = values;
    const len = slabs?.length;
    if (len && len < 4 && !errors?.slabs?.length) {
      const lastSlab = slabs[len - 1];
      setFieldValue("slabs", [...slabs, { id: ++uid, startDay: lastSlab.endDay * 1 + 1, endDay: "", rate: "" }]);
    }
  };

  const deleteSlab = (id) => {
    const fSlabs = values.slabs.filter((slab) => slab.id != id);
    const newSlabs = fSlabs?.map((slab, index) => ({
      ...slab,
      startDay: index == 0 ? values?.freeTime * 1 + 1 : fSlabs[index - 1]?.endDay * 1 + 1,
    }));
    setFieldValue("slabs", newSlabs);
  };

  // Table functions and Data

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortValue, setSortValue] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    if (event.target.value != tablePreference) {
      dispatch(setTablePreference(event.target.value));
    }
    setPage(0);
  };
  /*functions*/

  const sortBy = (value, order = "asc", vendordata) => {
    vendordata = vendordata ? vendordata : tableData ? tableData : [];
    if (value) {
      if (order == "asc") {
        setTableData([
          ...vendordata?.sort((b, a) => {
            if (typeof a[value] == "number") {
              return a[value] - b[value];
            } else {
              return a[value]?.localeCompare(b[value]);
            }
          }),
        ]);
      } else {
        setTableData([
          ...vendordata?.sort((a, b) => {
            if (typeof a[value] == "number") {
              return a[value] - b[value];
            } else {
              return a[value]?.localeCompare(b[value]);
            }
          }),
        ]);
      }
    } else {
      setTableData(vendordata);
    }
  };

  const changeSortOrder = (kind, order) => {
    if (order == "asc") {
      sortBy(kind, "asc");
      setSortOrder("desc");
    } else {
      sortBy(kind, "desc");
      setSortOrder("asc");
    }
    setSortValue(kind);
    setPage(0);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setValues,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldTouched,
  } = formik;

  useEffect(() => {
    if (values?.country && values?.serviceType) {
      handleGetServiceProviders(values?.country, values?.serviceType);
    }
  }, [values?.country, values?.serviceType]);

  return (
    <>
      <Box sx={{ pb: { xs: 10, md: 0 } }}>
        <Grid container py={1} spacing={2.5}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              onSubmit={handleSubmit}
              onReset={(e) => {
                setTtid(-1);
                handleReset(e);
              }}
              component={"form"}
            >
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="country"
                      options={countries}
                      onChange={(_, v) => {
                        setFieldValue("country", v?.code || "");
                        setFieldValue("provider", "");
                      }}
                      fullWidth
                      getOptionLabel={(option) => option?.name || ""}
                      value={findCountry(values.country) || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          name="country"
                          onBlur={handleBlur}
                          helperText={(touched.country && errors.country) || ""}
                          error={touched?.country && Boolean(errors.country)}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      onChange={(_, v) => {
                        setFieldValue("serviceType", v || "");
                        setFieldValue("provider", "");
                      }}
                      fullWidth
                      options={SERVICE_TYPES}
                      value={values.serviceType}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Service Type"
                          name="serviceType"
                          onBlur={handleBlur}
                          helperText={(touched.serviceType && errors.serviceType) || ""}
                          error={touched.serviceType && Boolean(errors.serviceType)}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={findProvider(values.provider, typeof providers == "string" ? [] : providers) || ""}
                      onChange={(_, v) => setFieldValue("provider", v?.id)}
                      fullWidth
                      disabled={typeof providers == "string" || !values.serviceType}
                      options={typeof providers == "string" || !providers ? [] : providers}
                      getOptionLabel={(option) => option?.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Provider"
                          name="provider"
                          onBlur={handleBlur}
                          helperText={(touched.provider && errors.provider) || ""}
                          error={touched.provider && Boolean(errors.provider)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {providers == "loading" ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : (
                                  <>{params.InputProps.endAdornment}</>
                                )}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1} direction={{ xs: "column", sm: "row" }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      value={findCompleteCurrency(values.currency) || ""}
                      onChange={(_, v) => setFieldValue("currency", v?.value)}
                      fullWidth
                      size="small"
                      options={ALL_CURRENCIES}
                      getOptionLabel={(option) => option?.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Currency"
                          name="currency"
                          onBlur={handleBlur}
                          helperText={(touched.currency && errors.currency) || ""}
                          error={touched.currency && Boolean(errors.currency)}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      type="number"
                      fullWidth
                      label="Free Time"
                      name="freeTime"
                      onBlur={handleBlur}
                      size="small"
                      value={values?.freeTime}
                      onChange={(e) => {
                        const { value } = e.target;
                        const { slabs } = values;
                        let newSlabs = [...slabs];
                        newSlabs[0].startDay = value * 1 + 1;
                        setFieldValue("freeTime", value);
                        setFieldValue("slabs", newSlabs);
                      }}
                      helperText={(touched.freeTime && errors.freeTime) || ""}
                      error={touched.freeTime && Boolean(errors.freeTime)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      value={findContainerType(values.containerType) || ""}
                      onChange={(_, v) => setFieldValue("containerType", v?.type)}
                      fullWidth
                      size="small"
                      options={CONTAINER_TYPES}
                      getOptionLabel={(option) => option?.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Container type"
                          name="containerType"
                          onBlur={handleBlur}
                          helperText={(touched.containerType && errors.containerType) || ""}
                          error={touched.containerType && Boolean(errors.containerType)}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      value={values.containerSize || ""}
                      onChange={(_, v) => {
                        setFieldValue("containerSize", v);
                      }}
                      fullWidth
                      size="small"
                      options={["20ft", "40ft"]}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Container size"
                          name="containerSize"
                          onBlur={handleBlur}
                          helperText={(touched.containerSize && errors.containerSize) || ""}
                          error={touched.containerSize && Boolean(errors.containerSize)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {/* Slabs */}
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12}>
                        {values?.slabs?.map((slab, index) => (
                          <Fieldset marginBottom=".5rem" key={slab.id} legend={`Slab ${index + 1}`}>
                            <Grid alignItems="flex-start" container spacing={1}>
                              <Grid item xs={4} md={3}>
                                <TextField
                                  size="small"
                                  value={slab.startDay}
                                  name={`slabs[${index}].startDay`}
                                  error={touched?.slabs?.[index]?.startDay && errors?.slabs?.[index]?.startDay}
                                  helperText={
                                    (touched?.slabs?.[index]?.startDay && errors?.slabs?.[index]?.startDay) || ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  disabled
                                  label="Start Day"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4} md={3}>
                                <TextField
                                  size="small"
                                  value={slab.endDay}
                                  name={`slabs[${index}].endDay`}
                                  error={touched?.slabs?.[index]?.endDay && errors?.slabs?.[index]?.endDay}
                                  helperText={(touched?.slabs?.[index]?.endDay && errors?.slabs?.[index]?.endDay) || ""}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    handleChange(e);
                                    if (values.slabs.length - 1 > index) {
                                      setFieldValue(`slabs[${index + 1}].startDay`, value * 1 + 1);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  label="End Day"
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={4} md={3}>
                                <TextField
                                  size="small"
                                  value={slab.rate}
                                  name={`slabs[${index}].rate`}
                                  error={touched?.slabs?.[index]?.rate && errors?.slabs?.[index]?.rate}
                                  helperText={(touched?.slabs?.[index]?.rate && errors?.slabs?.[index]?.rate) || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label="Rate"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Button
                                  endIcon={<FiTrash2 />}
                                  color="error"
                                  variant="contained"
                                  disabled={values?.slabs?.length <= 1}
                                  fullWidth
                                  sx={{ textTransform: "capitalize", py: 0.89 }}
                                  onClick={() => deleteSlab(slab.id)}
                                >
                                  Delete
                                </Button>
                              </Grid>
                            </Grid>
                          </Fieldset>
                        ))}
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container flexDirection={"row-reverse"}>
                          <Grid item xs={12} sm={3}>
                            <Button
                              disabled={
                                (!values.freeTime && values?.freeTime != "0") ||
                                values?.slabs?.length >= 4 ||
                                errors?.slabs?.length > 0
                              }
                              onClick={addSlab}
                              variant="contained"
                              fullWidth
                              sx={{ textTransform: "capitalize" }}
                              startIcon={<FiPlus />}
                            >
                              {" "}
                              Add Slab
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {ttid && ttid != -1 && (
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        color="warning"
                        disabled={isSubmitting}
                        type="reset"
                        variant="contained"
                        sx={{ py: 1.5 }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={ttid && ttid != -1 ? 6 : 12}>
                    <LoadingButton
                      loadingPosition="end"
                      fullWidth
                      type="submit"
                      loading={isSubmitting}
                      variant="contained"
                      sx={{ py: 1.5 }}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* end of selects */}

            {/* Table  */}
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              {/* large table  */}
              <Table sx={{ minWidth: 650, display: { md: "table", xs: "none" } }} aria-label="Trade Terms">
                {/* <caption>A basic table example with a caption</caption> */}
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>
                      <div className="flex">
                        Country <SortButton {...{ filterBy: "country", sortValue, sortOrder, changeSortOrder }} />
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      <div className="flex">
                        Provider <SortButton {...{ filterBy: "provider", sortValue, sortOrder, changeSortOrder }} />
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      {" "}
                      <div className="flex">
                        Service Type{" "}
                        <SortButton {...{ filterBy: "serviceType", sortValue, sortOrder, changeSortOrder }} />
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      <div className="flex">
                        Container Type
                        <SortButton {...{ filterBy: "containerSize", sortValue, sortOrder, changeSortOrder }} />
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      <div className="flex">
                        Free Time <SortButton {...{ filterBy: "freeTime", sortValue, sortOrder, changeSortOrder }} />
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      Slab&nbsp;1
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      Slab&nbsp;2
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      Slab&nbsp;3
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      Slab&nbsp;4
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!tradeTermsData || tradeTermsData === "loading" ? (
                    <TableRow>
                      <TableCell sx={{ py: 1 }} colSpan={10}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((ttd, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{findCountry(ttd?.country)?.name || ttd?.country || ""}</TableCell>
                        <TableCell align="left">{ttd.provider}</TableCell>
                        <TableCell align="left">{ttd.serviceType}</TableCell>
                        <TableCell align="left">{`${ttd?.containerSize || "-"}/${
                          ttd?.containerType || "-"
                        }`}</TableCell>
                        <TableCell align="left">
                          {ttd.freeTime ? `0 ➝ ${ttd?.freeTime || "-"} @ ${ttd?.currency || "-"}0 per day` : "-"}
                        </TableCell>
                        {[0, 1, 2, 3].map((i) => (
                          <TableCell key={i} align="left">
                            {getSlabText(ttd?.slabs[i], ttd?.currency)}
                          </TableCell>
                        ))}
                        <TableCell align="left">
                          <Grid container justifyContent={"space-between"}>
                            <Grid item>
                              <StyledToolTip title="Edit" arrow followCursor>
                                <IconButton
                                  size="small"
                                  color="warning"
                                  disabled={ttid == ttd.id}
                                  onClick={(e) => loadData(ttd.id)}
                                >
                                  <FiEdit2 />
                                </IconButton>
                              </StyledToolTip>
                            </Grid>
                            <Grid item>
                              <ConfirmationButton
                                desc="You are about delete this Trade Term. This cannot be undone or recovered. Are you sure you want to do this?"
                                onConfirm={() => deleteTradeTerm(ttd.id)}
                                severity="error"
                                ButtonElement={({ onClick }) => (
                                  <StyledToolTip title="Delete" arrow followCursor>
                                    <IconButton onClick={onClick} color="error" size="small" disabled={ttid == ttd.id}>
                                      <FiTrash2 />
                                    </IconButton>
                                  </StyledToolTip>
                                )}
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>

              {/* end of large table  */}
              {/* large table  */}
              <Table sx={{ display: { md: "none", xs: "table" } }} aria-label="Trade Terms">
                {/* <caption>A basic table example with a caption</caption> */}
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>
                      <div className="flex">
                        Country <SortButton {...{ filterBy: "country", sortValue, sortOrder, changeSortOrder }} />
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      <div className="flex">
                        Provider <SortButton {...{ filterBy: "provider", sortValue, sortOrder, changeSortOrder }} />
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      {" "}
                      <div className="flex">
                        Service Type{" "}
                        <SortButton {...{ filterBy: "serviceType", sortValue, sortOrder, changeSortOrder }} />
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left">
                      <div className="flex">
                        Free Time <SortButton {...{ filterBy: "freeTime", sortValue, sortOrder, changeSortOrder }} />
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!tradeTermsData || tradeTermsData === "loading" ? (
                    <TableRow>
                      <TableCell sx={{ py: 1 }} colSpan={7}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((ttd, index) => (
                      <TradeTermsRow
                        key={index}
                        {...{
                          loadData,
                          ttd,
                          ttid,
                          deleteTradeTerm,
                          country: findCountry(ttd?.country)?.name || "",
                        }}
                      />
                    ))
                  )}
                </TableBody>
              </Table>

              {/* end of large table  */}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TradeTerms;

import { Box, Button, Grid, TextField, Portal, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { TiWarningOutline } from "react-icons/ti";
import { MdUploadFile } from "react-icons/md";
import { useDocument } from "../../../hooks/useDocument";
import DocumentUpload from "../../../components/DocumentUpload";
import { ShipmentDocumentTable } from "../../../components/Tables/ShipmentDocumentTable";
import { fixNumber, convertToSize } from "../../../utils/helpers";
import useTracking from "../../../hooks/useTracking";

const Document = ({ bl = "", containers = [], useBl = false }) => {
  const { handleGetDocuments } = useDocument();
  const { handleGetAllContainerID } = useTracking();
  const [search, setSearch] = useState("");
  const [allContainers, setAllContainers] = useState([]);
  const [allContainersId, setAllContainersId] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [openDocumentUpload, setOpenDocumentUpload] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSuccess = (data) => {
    let newTableData = { ...(tableData || {}), documents: [data, ...(tableData?.documents || [])] };
    setTableData(newTableData);
  };

  const headCells = [
    {
      id: "documentName",
      numeric: false,
      disablePadding: false,
      label: "Document Name",
      align: "left",
    },
    {
      id: "documentType",
      numeric: false,
      disablePadding: false,
      label: "Type",
      align: "left",
    },
    {
      id: "size",
      numeric: false,
      disablePadding: false,
      label: "Size",
      align: "right",
    },
    {
      id: "owner",
      numeric: false,
      disablePadding: false,
      label: "Linked to",
      align: "right",
    },
    {
      id: "download",
      numeric: false,
      disablePadding: true,
      label: "Download",
      align: "right",
    },
  ];

  const createData = (documentName, size, owner, url, documentType) => {
    return {
      documentName,
      size,
      owner,
      url,
      documentType,
    };
  };

  const rows = tableData?.documents?.map((item) =>
    createData(
      `${item?.title || ""}`,
      convertToSize(fixNumber(item?.contentLength)),
      `${allContainersId[item?.parent] || item?.parent}`,
      item?.url,
      item?.documentType
    )
  );

  const handleChangePage = async (_, newPage) => {
    setPage(newPage);
    const data = await handleGetDocuments(newPage + 1, rowsPerPage, "", [
      bl,
      ...containers?.map((container) => allContainers[container] || container),
    ]);
    setTableData(data);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value));
    const data = await handleGetDocuments(page, event.target.value, "", [
      bl,
      ...containers?.map((container) => allContainers[container] || container),
    ]);
    setPage(0);
    setTableData(data);
  };

  useEffect(() => {
    const deleteData = async () => {
      setIsLoading(true);

      const allContainers = await handleGetAllContainerID(bl);
      let allContainersId = {};
      Object.keys(allContainers)?.forEach((k) => {
        allContainersId[allContainers[k]] = k;
      });
      setAllContainersId(allContainersId);
      const data = await handleGetDocuments(page + 1, rowsPerPage, "", [
        bl,
        ...containers?.map((container) => allContainers[container] || container),
      ]);
      setAllContainers(allContainers);

      setTableData(data);

      setIsLoading(false);
      setDeleteData(false);
    };
    deleteData();
  }, [deleteData]);

  const searchData = (data, search) => {
    search = search?.trim()?.toLowerCase();
    let res = data?.filter(
      (row) =>
        `${row?.documentType || ""}`.toLowerCase().includes(search) ||
        `${row?.owner || ""}`.toLowerCase().includes(search)
    );
    return res;
  };
  return (
    <>
      <Box>
        <Box>
          <Grid container spacing={2} mt="1rem">
            <Grid item xs={12} sm={6} xl={4}>
              <TextField
                label="Search for a document"
                size="small"
                fullWidth
                type="search"
                name="search"
                placeholder="Search for a document"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setSearch(`${e?.target?.value || ""}`);
                }}
                // onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={4} display="flex" justifyContent="flex-end">
              <Button
                startIcon={<MdUploadFile />}
                variant="outlined"
                onClick={() => {
                  setOpenDocumentUpload(true);
                }}
                sx={{ textTransform: "none" }}
                disabled={!(bl || containers.length > 0)}
              >
                Upload Document
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box mt="1rem">
          {isLoading ? (
            <Loader />
          ) : (
            <ShipmentDocumentTable
              {...{
                headCells,
                rows: search?.trim() ? searchData(rows, search?.trim()) : rows,
                data: tableData,
                setTableData,

                // handleDocDelete,
                page,
                setPage,
                handleChangePage,
                handleChangeRowsPerPage,
                rowsPerPage,
                setRowsPerPage,
              }}
              bl={bl}
            />
          )}
        </Box>

        {!isLoading && !(search?.trim() ? searchData(rows, search?.trim()) : rows) && (
          <div className="no-data">
            <div className="icon">
              <TiWarningOutline color="#fb8b23" style={{ margin: "10px auto" }} size="65" />
            </div>
            <div className="text">No Documents found!</div>
          </div>
        )}
      </Box>
      <Portal>
        <Modal open={openDocumentUpload}>
          <Box
            height="100%"
            width="100%"
            display="flex"
            position="relative"
            justifyContent="center"
            alignItems="center"
          >
            <DocumentUpload
              setParsedData={handleSuccess}
              cleanUpFxn={() => setOpenDocumentUpload(false)}
              parent={useBl || containers?.length > 1 ? bl : allContainers[containers[0]]}
            />
          </Box>
        </Modal>
      </Portal>
    </>
  );
};

export default Document;

import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { shippingStyles, shippingOptions, getShippingLine } from "../../utils/shippingLine";
import { Search } from "../../components/Dash";
import readXlsxFile from "read-excel-file";
import { setParsedShipments, setTrackingStartedModal, setShowNotification } from "../../redux/data/data.actions";
import { ShipmentUploadModal } from "../Modals/ShipmentUploadModal";
import SelectShipmentsToTrack from "../../components/Modals/SelectShipmentsToTrack";
import {
  fixDate,
  stringToCurrency,
  fixPDFDate,
  fixNumber,
  addMoney,
  fieldsArrayToObject,
  sanitizeContainerNumber,
} from "../../utils/helpers";
// kk change
import TrackingStarted from "../Modals/TrackingStarted";
import { Portal } from "@mui/base";
import { ShipmentPdfModal } from "../Modals/ShipmentPdfModal";
import { useDocument } from "../../hooks/useDocument";
import ConfirmationDialog from "../Confirmation/ConfirmationDialog";

export function SearchBillContainer({
  presentRoute,
  containerNo = "",
  billOfLading = "",
  shipingLine = null,
  onClickContinueAdditionalFxns = () => null,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleCreateDocument } = useDocument();
  const [uploadModal, setUploadModal] = useState(false);
  const [selectedShippingLine, setSelectedShippingLine] = useState(() =>
    typeof shipingLine === "string" ? getShippingLine(shipingLine) : null
  );
  const [PDFData, setPDFData] = useState(null);
  const [containerNumber, setContainerNumber] = useState(containerNo);
  const [billNumber, setBillNumber] = useState(billOfLading);
  const [shipmentModal, setShipmentModal] = useState(false);
  const [file, setFile] = React.useState(null);
  const [url, setUrl] = React.useState("");
  const [bl, setBl] = React.useState("");
  const [createDocument, setCreateDocument] = React.useState(false);
  // kk changes
  const trackingStartedModal = useSelector((state) => state.data.trackingStartedModal);

  const uploadRef = useRef();

  const handleUploadShipping = () => {
    uploadRef.current.click();
    setUploadModal(false);
  };

  const handleFileChange = (e) => {
    let selectedFile = e.target.files[0];

    readXlsxFile(selectedFile).then((rows) => {
      dispatch(setParsedShipments(mapper(removeFirstItem(rows))));
      setShipmentModal(true);
    });

    const removeFirstItem = (array) => {
      let data = array;
      array.shift();
      return data;
    };

    const mapper = (array) =>
      array.map((shipmentList) => ({
        containerNumber: shipmentList[1]?.toString() || null,
        billOfLading: shipmentList[0]?.toString() || null,
        shippingLine: shipmentList[2]?.toString() || null,
        clearingAgent: shipmentList[3]?.toString() || null,
        transporter: shipmentList[4]?.toString() || null,
        commodityDescription: shipmentList[5]?.toString() || null,
        externalReferenceNumber: shipmentList[6]?.toString() || null,
        commodityCode: shipmentList[7]?.toString() || null,
        shipmentAdviceReceptionDate: fixDate(shipmentList[8]),
        fcl: shipmentList[9]?.toString() || null,
        fclType: shipmentList[10]?.toString() || null,
        customsCommand: shipmentList[11]?.toString() || null,
        draftDocumentsReceptionDate: fixDate(shipmentList[12]),
        originalDocumentsReceptionDate: fixDate(shipmentList[13]),
        paarSubmissionDate: fixDate(shipmentList[14]),
        paarCopyReceptionDate: fixDate(shipmentList[15]),
        sgdNumber: shipmentList[16]?.toString() || null,
        sgdDate: fixDate(shipmentList[17]),
        assessmentReceptionDate: fixDate(shipmentList[18]),
        dutyAssessmentNumber: shipmentList[19]?.toString() || null,
        invoiceNumber: shipmentList[20]?.toString() || null,
        invoiceQuantity: shipmentList[21]?.toString() || null,
        blValue: shipmentList[22]?.toString() || null,
        formMNumber: shipmentList[23]?.toString() || null,
        formMValue: shipmentList[24]?.toString() || null,
        formMApprovalDate: fixDate(shipmentList[25]),
        formMExpiryDate: fixDate(shipmentList[26]),
        baNumber: shipmentList[27]?.toString() || null,
        bankName: shipmentList[28]?.toString() || null,
        customsDutyExcludingVAT: shipmentList[29]?.toString() || null,
        vat: shipmentList[30]?.toString() || null,
        customsDutyIncludingVAT: shipmentList[31]?.toString() || null,
        dutyRate: shipmentList[32]?.toString() || null,
        dutyExchangeRate: shipmentList[33]?.toString() || null,
        dutyPaymentDate: fixDate(shipmentList[34]),
        hscode: shipmentList[35]?.toString() || null,
        nafdacCharges: shipmentList[36]?.toString() || null,
        quarantineCharges: shipmentList[37]?.toString() || null,
        sonCharges: shipmentList[38]?.toString() || null,
        status: "ready",
        success: false,
        message: "",
      }));
  };

  const handleBillContainerSearch = (type) => {
    if (!selectedShippingLine) {
      return dispatch(setShowNotification({ isOpen: true, message: "Please select Shipping Line!", type: "info" }));
    }
    if (type === "container") {
      if (!containerNumber) {
        return dispatch(setShowNotification({ isOpen: true, message: "Please input Container No!", type: "info" }));
      }

      history.push(`/dashboard/tracking/container/${selectedShippingLine?.value}/${containerNumber}`);
    } else if (type === "bill") {
      if (!billNumber) {
        return dispatch(setShowNotification({ isOpen: true, message: "Please input Bill of Laden!", type: "info" }));
      }

      history.push(`/dashboard/tracking/bill/${selectedShippingLine?.value}/${billNumber}`);
    }
  };

  const onClickContinue = () => {
    dispatch(setTrackingStartedModal(false));
    setShipmentModal(false);
    history.push("/dashboard/shipments");
    onClickContinueAdditionalFxns();
  };

  const mapPDFData = (fieldsData, file, url) => {
    const documentCurrency = fieldsData?.documentCurrency?.valueString || "";

    const containerNumber = (
      (fieldsData?.containerDetails || fieldsData?.containerList || []).map((c) => {
        const container = fieldsArrayToObject(c?.valueArray || [])?.containerNumber;
        return sanitizeContainerNumber(container?.valueString || "");
      }) || []
    ).filter((c) => c !== "");

    const CD = (fieldsData?.containerList || [])?.map(
      (c) => fieldsArrayToObject(c?.valueArray || [])?.commodityDescription?.valueString
    );
    const firstCommodityDescription = CD.length > 0 ? CD[0] : "";
    let res = {
      // NG_Customs
      containerNumber,
      billOfLading: fieldsData?.billofLading?.valueString || fieldsData?.blNumber?.valueString,
      clearingAgent: fieldsData?.declarantName?.valueString || "",
      transporter: "",
      //commodityDescription: fieldsData?.commodityNote
      //  ? fieldsData?.commodityDescription?.valueString + " - " + fieldsData?.commodityNote?.valueString
      //  : firstCommodityDescription,
      commodityDescription: fieldsData?.commodityDescription?.valueString
        ? fieldsData?.commodityDescription?.valueString
        : firstCommodityDescription,
      externalReferenceNumber: fieldsData?.bookingRef?.valueString || "",
      goodsReadyDays: 0,
      commodityCode: fieldsData?.commodityCode?.valueString || "",
      shipmentAdviceReceptionDate: "",
      fcl: 0,
      fclType: 0,
      customsCommand: fieldsData?.exitOffice?.valueString || "",
      draftDocumentsReceptionDate: "",
      originalDocumentsReceptionDate: "",
      paarSubmissionDate: "",
      paarCopyReceptionDate: "",
      sgdNumber: fieldsData?.sgdNumber?.valueString || "",
      sgdDate: fixPDFDate(fieldsData?.registrationDate?.valueString) || "",
      assessmentReceptionDate: fixPDFDate(fieldsData?.assessmentDate?.valueString) || "",
      dutyAssessmentNumber: fieldsData?.assessmentNumber?.valueString || "",
      invoiceNumber: "",
      invoiceQuantity: fixNumber(fieldsData?.quantity?.valueString || ""),
      // formMValue: stringToCurrency("", fieldsData?.exchangeRate?.valueString, documentCurrency),
      // formMApprovalDate: "",
      // formMExpiryDate: "",
      bankName: fieldsData?.bank?.valueString || "",
      customsDutyExcludingVAT: stringToCurrency(fieldsData?.dutyAmount?.valueString, 1, documentCurrency),
      vat: stringToCurrency(fieldsData?.vatAmount?.valueString, 1, documentCurrency),
      customsDutyIncludingVAT: addMoney(
        stringToCurrency(fieldsData?.vatAmount?.valueString, 1, documentCurrency),
        stringToCurrency(fieldsData?.dutyAmount?.valueString, 1, documentCurrency)
      ),
      dutyRate: fixNumber(fieldsData?.dutyRate?.valueString || ""),
      dutyExchangeRate: fixNumber(fieldsData?.exchangeRate?.valueString || ""),
      dutyPaymentDate: fixPDFDate(fieldsData?.receiptDate?.valueString) || "",
      hscode: fieldsData?.commodityCode?.valueString || "",
      nafdacCharges: stringToCurrency("", 1, documentCurrency),
      quarantineCharges: stringToCurrency("", 1, documentCurrency),
      sonCharges: stringToCurrency("", 1, documentCurrency),
      blValue: stringToCurrency(fieldsData?.blValueAmount?.valueString, fieldsData?.exchangeRate?.valueString),
      surcharge: stringToCurrency(fieldsData?.surchargeAmount?.valueString, 1, documentCurrency),
      ciss: stringToCurrency(fieldsData?.cisAmount?.valueString, 1, documentCurrency),
      levies: stringToCurrency(fieldsData?.leviesAmount?.valueString, 1, documentCurrency),
      etls: stringToCurrency(fieldsData?.etlAmount?.valueString, 1, documentCurrency),

      // BL_Document
      baNumber: fieldsData?.ba_number?.valueString || "",
      // consigneeAddress: fieldsData?.consigneeAddress?.valueString || "",
      // consigneeContacts: fieldsData?.consigneeContacts?.valueString || "",
      // containerList:
      //   (fieldsData?.containerList || [])?.map(
      //     (c) => fieldsArrayToObject(c?.valueArray || [])?.containerNumber?.valueString
      //   ) || [],
      formMNumber: fieldsData?.formM_number?.valueString || "",
      // imoNumber: fieldsData?.imoNumber?.valueString || "",
      // notifyParty: fieldsData?.notifyParty?.valueString || "",
      // notifyPartyAddress: fieldsData?.notifyPartyAddress?.valueString || "",
      // notifyPartyContacts: fieldsData?.notifyPartyContacts?.valueString || "",
      // pod: fieldsData?.pod?.valueString || "",
      // pol: fieldsData?.pol?.valueString || "",
      // remarks: fieldsData?.remarks?.valueString || "",
      // scacCode: fieldsData?.scacCode?.valueString || "",
      // serviceContractNo: fieldsData?.serviceContractNo?.valueString || "",
      // shipmentMode: fieldsData?.shipmentMode?.valueString || "",
      // shipmentTerms: fieldsData?.shipmentTerms?.valueString || "",
      // shipperAddress: fieldsData?.shipperAddress?.valueString || "",
      // shipperContacts: fieldsData?.shipperContacts?.valueString || "",
      // shipperName: fieldsData?.shipperName?.valueString || "",
      shippingLine: fieldsData?.shippingLine?.valueString || fieldsData?.scacCode?.valueString,
      // totalGrossWeight: fieldsData?.totalGrossWeight?.valueString || "",
      // totalItems: fieldsData?.totalItems?.valueString || "",
      // vessel: fieldsData?.vessel?.valueString || "",
      // voyage: fieldsData?.voyage?.valueString || "",
    };
    setPDFData(res);
    setBl(res?.billOfLading || "");
    setFile(file);
    setUrl(url);
  };

  return (
    <>
      {/* kk change  */}
      <TrackingStarted
        open={trackingStartedModal}
        handleClose={() => {
          setShipmentModal(false);
          dispatch(setTrackingStartedModal(false));
        }}
        onClickContinue={onClickContinue}
      />
      <ShipmentUploadModal
        open={uploadModal}
        handleClose={() => {
          setUploadModal(false);
        }}
        setOpen={setUploadModal}
        handleUploadShipping={handleUploadShipping}
        setPDFData={mapPDFData}
      />

      <SelectShipmentsToTrack
        open={shipmentModal}
        setShipmentModal={setShipmentModal}
        handleClose={() => {
          setShipmentModal(false);
        }}
      />
      <div className="search">
        {presentRoute === "dashboard" && (
          <h1 className="text-[#033B6C] font-bold text-xl 4xl:text-[22px] leading-[26px] pb-4 4xl:pb-5">
            Start your Search here
          </h1>
        )}
        <div className="onboard-search form-control-dashboard relative left-0 top-0 z-50 w-full py-1 lg:grid grid-cols-[1.3fr,1fr] items-center bg-[#ffffff99]  border border-solid border-[#ffffff85] rounded-[10px] mb-6">
          <div className="lg:border-r border-solid flex flex-col justify-center  w-full h-full border-[#cccccc] hover:cursor-pointer">
            <Select
              value={selectedShippingLine}
              onChange={(selected) => {
                setSelectedShippingLine(selected);
              }}
              placeholder="Select a shipping line"
              options={shippingOptions}
              styles={shippingStyles}
            />
          </div>
          <div className="h-full hidden lg:flex items-center justify-center px-4 4xl:px-7">
            <label htmlFor="upload">
              <input
                ref={uploadRef}
                type="file"
                name="upload-shipping"
                id="upload"
                onChange={(e) => {
                  handleFileChange(e);
                }}
                value=""
                accept="text/csv, .csv,  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                className="hidden"
              />
              <button
                className="bg-[#033B6C] rounded-[4px] py-2 px-5"
                onClick={() => {
                  setUploadModal(true);
                }}
              >
                <h1 className="text-base text-white-100">Upload BL / Container no list</h1>
              </button>
            </label>
          </div>
        </div>
        <Search
          onContainerChange={(e) => {
            setContainerNumber(e.target.value?.toString()?.toUpperCase());
          }}
          containerNumber={containerNumber}
          onBilChange={(e) => {
            setBillNumber(e.target.value?.toString()?.toUpperCase());
          }}
          searchContainerHandler={() => {
            handleBillContainerSearch("container");
          }}
          searchBillHandler={() => {
            handleBillContainerSearch("bill");
          }}
          bill={billNumber}
        />
      </div>

      <Portal>
        {!!PDFData && (
          <ShipmentPdfModal
            open={!!PDFData}
            PDFData={PDFData}
            setPDFData={setPDFData}
            setCreateDocument={() => setCreateDocument(true)}
          />
        )}
        <ConfirmationDialog
          desc={["Do you want to save a copy of the uploaded file?"]}
          isOpen={createDocument}
          handleClose={() => {
            setFile(null);
            setUrl("");
            setCreateDocument(false);
          }}
          severity={"success"}
          onConfirm={async () => {
            let filename = file?.name || "file";
            let contentType = file?.type || "application/pdf";
            let contentLength = file?.size || 1024;
            let extension = filename?.split(".")?.length > 1 ? filename?.split(".")?.pop() : "";
            await handleCreateDocument(
              `Shipment-tracking-extraction-${Date.now()}`,
              bl,
              url,
              "BILL_OF_LADING",
              extension,
              contentLength,
              contentType
            );
            setFile(null);
            setUrl("");
            setCreateDocument(false);
          }}
        />
      </Portal>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import FormHeader from "../../components/Form/FormHeader";
import classes from "../../styles/FormButton.module.css";
import FormFooter from "../../components/Form/FormFooter";

import Ship from "../../assets/images/container_ship_1.png";
import LogoWhite from "../../assets/images/logo_wide_name_white.png";
import AirSupport from "../../assets/images/air_support_1.png";
// graphql
import { useLazyQuery } from "@apollo/client";
import { RESEND_EMAIL_VERIFICATION, VERIFY_CUSTOMER_EMAIL } from "../../graphql/auth";
import { toast } from "react-toastify";
const analytics = window?.analytics;

const VerifyEmail = () => {
  useEffect(() => {
    document.title = `aTrace - Visibility - Verify email`;
  }, []);
  const history = useHistory();
  const [userID, setUserID] = useState("");
  const [unverified, setUnverified] = useState(false);
  const [newuser, setNewuser] = useState(false);
  const [isVerificationSuccessful, setIsVerificationSuccessful] = useState(false);
  const [verifyCustomerEmail, { loading }] = useLazyQuery(VERIFY_CUSTOMER_EMAIL, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.verifyEmail?.value) {
        setIsVerificationSuccessful(true);
        analytics?.track("Email Verified", {
          userId: userID,
        });

        toast.success("Email verified successfully");
      } else if (data?.verifyEmail?.code && data?.verifyEmail?.message) {
        analytics?.track("Email Verification Failed", {
          userId: userID,
          message: data?.verifyEmail?.message,
        });

        toast.error(data?.verifyEmail?.message);
      }
    },
    onError(error) {
      analytics?.track("Email Verification Failed", {
        userId: userID,
        message: error.message,
      });

      toast.error("Error verifying your email.");
    },
  });

  const [resendEmailVerification, { loading: isResendingEmailVerification }] = useLazyQuery(RESEND_EMAIL_VERIFICATION, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data.resendEmailVerification) {
        toast.success("Email sent. Please check your inbox");
      } else {
        if (data.resendEmailVerification.code && data.resendEmailVerification.message) {
          toast.error(data.resendEmailVerification.message);
        } else {
          toast.error("Unable to resend email. Please try again.");
        }
      }
    },
    onError() {
      toast.error("We encountered an error while trying to resend the email verification link. Please try again.");
    },
  });

  const resendEmailHandler = () => {
    resendEmailVerification({
      variables: {
        userID,
      },
    });
  };

  useEffect(() => {
    const fnx = async () => {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const code = params.get("code");
      const userID = params.get("userID");
      const unverified = params.get("unverified");
      const newuser = params.get("newuser");
      setUnverified(unverified);
      setNewuser(newuser);
      setUserID(userID);
      if (code) {
        verifyCustomerEmail({
          variables: {
            code,
            userID,
          },
        });
      }
    };
    fnx();
  }, []);

  return (
    <>
      <div className="auth-page">
        {/* Left Container */}

        <div className="left">
          <div className="form-wrapper">
            {!unverified ? (
              <FormHeader title="Email address verification" paragraph={``} />
            ) : !newuser ? (
              <FormHeader title="Unverified accounts:" paragraph={``} />
            ) : (
              <FormHeader title="Thank you for choosing aTrace." paragraph={``} />
            )}
            {loading ? (
              <div className="w-full flex flex-col justify-center align-items-center">
                <Loader type="Puff" color="#033B6C" height={60} width={60} />
                <p>Verifying your email address ...</p>
              </div>
            ) : (
              <>
                {isVerificationSuccessful ? (
                  <>
                    <p className="text-green-500">Email verified successfully. You can now proceed to login</p>
                    <button type="submit" className="form-button" onClick={() => history.push("/login")}>
                      Proceed to login
                    </button>
                  </>
                ) : (
                  <>
                    {!unverified ? (
                      <p className="text-red-500">
                        We were unable to verify your email. Please try initiating the process again
                      </p>
                    ) : !newuser ? (
                      <>
                        <p className="font-bold mb-3">Thank you for choosing aTrace.</p>
                        <p className="mb-3">
                          Our system noticed that your registered email has not been verified. Pardon our mistake!
                          Please verify your email to continue to have unrestricted access to a limitless visibility of
                          all your shipments across multiple stakeholders and much more.
                        </p>
                        <p className="mb-3">
                          To access your account, please click resend button below. A link will be sent to your email,
                          please click on the verify button.
                        </p>
                        <p className="font-bold">Once again – thank you for being a part of our aTrace family!</p>
                      </>
                    ) : (
                      <>
                        <p className="mb-3">
                          You are almost ready to gain unrestricted access to insightful visibility of all your
                          shipments across multiple stakeholders and much more.
                        </p>
                        <p className="mb-3">
                          To access your account, please check your email and click on the verify button.
                          <br />
                          If you don’t have the link, click resend button below:
                        </p>
                        <p className="font-bold">Once again – thank you for joining our aTrace family! </p>
                      </>
                    )}
                    <button
                      onClick={() => resendEmailHandler()}
                      className={`${classes.sign_button}  bg-[#033B6C] w-[499.64px] h-[73.09px] hover:cursor-pointer flex items-center justify-center mb-[29.91px] mt-[56.31px] rounded-[10px] phone:mt-2 phone:w-[347px] phone:h-[53px] phone:mb-[43px] tab:mt-0 tab:w-[642px] tab:h-[98px] tab:mb-[79.6px] medium:mt-0 medium:w-[867.5px] medium:h-[132.5px] medium:mb-[107.5px] laptop:mt-[37.54px] laptop:w-[333.1px] laptop:h-[48.73px] laptop:mb-[19.94px] large:mt-[45.048px] large:w-[429.7px] large:h-[58.47px] large:mb-[23.9px]`}
                      disabled={isResendingEmailVerification}
                    >
                      <h1
                        type="submit"
                        className="text-white-100 w-auto text-[1rem] leading-[1.1rem] font-medium phone:text-[0.875rem] phone:leading-[0.96rem] tab:text-[1.62rem] tab:leading-[1.8rem] medium:text-[2.2rem] medium:leading-[2.4rem] laptop:text-[0.67rem] laptop:leading-[0.73rem] large:text-[0.8rem] large:leading-[0.88rem]"
                      >
                        {!isResendingEmailVerification ? "Resend Email Verification" : "Resending ..."}
                      </h1>
                    </button>
                  </>
                )}
              </>
            )}
          </div>

          {/* Only for Smaller Screens */}
          <FormFooter parag={"Don't have an account?"} linkTitle="Sign Up" linkPath="/create-account" />
        </div>

        {/* Right Container */}
        <div className="right">
          <div className="top">
            <div className="img-wrapper">
              <img src={LogoWhite} alt="background" />
            </div>
            <p className="desc text-[18px]">Supply chain visibility management</p>
          </div>

          <div className="middle">
            <div className="air">
              <img src={AirSupport} alt="logo flight" />
            </div>

            <div className="ship">
              <img src={Ship} alt="logo container" />
            </div>
          </div>

          {/* <div className="bottom flex justify-center text-[18px]">
            <span className="text-white-100 pr-1">Don&#8217;t have an account?</span>
            <Link to="/create-account">
              <div className="text-red-400">Sign Up</div>
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import YupPassword from "yup-password";
YupPassword(Yup);
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Box,
  Grid,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import MuiPhone from "../../components/MuiPhone";
import { FcGoogle } from "react-icons/fc";
import classes from "../../styles/FormButton.module.css";
import FormHeader from "../../components/Form/FormHeader";
import FormFooter from "../../components/Form/FormFooter";
import { setUserData } from "./../../redux/user/user.actions";
import WorldMap from "../../assets/images/world_map.png";
import SignupImage from "../../assets/images/sign_up_image.png";
import "./auth.scss";
import { countries, findCountry } from "../../utils/all-countries";
import { findCountryFromCity } from "../../utils/data";
import { useAuth } from "../../hooks/useAuth";
const analytics = window?.analytics;

const SignUp = () => {
  const [defaultCountry, setDefaultCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { getAuthUser, getSignupByEmail } = useAuth();

  const handleGoogleAuth = async (res) => {
    const { data } = await getAuthUser(res.accessToken);
    const { user } = data?.authenticateWithGoogle;

    if (user) {
      history.push("/dashboard");
    }
  };

  const signupHandler = async (firstname, lastname, email, phone, password, country, currency) => {
    setIsLoading(true);

    const { data } = await getSignupByEmail({
      password: password,
      email: email,
      name: `${firstname} ${lastname}`,
      preferredCurrency: currency,
      phone: phone,
      country,
    });

    if (!data?.signupByEmail?.user) {
      setIsLoading(false);
      return;
    }

    if (!data?.signupByEmail?.user?.emailVerified) {
      return history.push("/verify-email?unverified=true&&newuser=true&&userID=" + data?.signupByEmail?.user?.id);
    }

    if (data?.signupByEmail?.user) {
      localStorage.setItem("onboardingComplete", false);

      dispatch(
        setUserData({
          ...data?.signupByEmail?.user,
          accessToken: data?.signupByEmail?.token,
          refreshToken: data?.signupByEmail?.refresh,
          onboardingComplete: false,
        })
      );
    }
    setIsLoading(false);
  };

  const validate = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string(),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    phone: Yup.string()
      .min(6, "Enter a valid Phone Number")
      .required("Phone Number is required")
      .matches(/\+?([0-9]){3,}/, "Enter a valid Phone Number"),
    country: Yup.string().required("Country is required"),
    acceptTerms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
    password: Yup.string().password().required("Password is required"),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm password is required"),
  });

  useEffect(() => {
    document.title = `aTrace - Visibility - Sign up`;
    const city = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/")[1];
    setDefaultCountry(findCountryFromCity(city) || 1);
    analytics?.track("Viewed signup page");
  }, []);

  return (
    <>
      {defaultCountry && (
        <Grid container minHeight={"100vh"}>
          <Grid
            item
            xs={12}
            lg={7}
            xl={6}
            sx={{
              backgroundImage: { xs: `url(${WorldMap})` },
              overflowY: "scroll",
              backgroundPosition: "top right",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Grid container height="100vh" className="invinsible-scroll" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Box p={1} width="90%" maxWidth="700px" margin="1rem auto">
                  <FormHeader title={"Sign Up"} paragraph={<span>Don&#8217;t have an account?</span>} />
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      phone: "",
                      country: defaultCountry === 1 || !defaultCountry ? "UK" : defaultCountry,
                      acceptTerms: false,
                      password: "",
                      repeatPassword: "",
                    }}
                    validationSchema={validate}
                    onSubmit={(values) => {
                      const currency = findCountry(values.country)?.currency?.code;
                      signupHandler(
                        values.firstName,
                        values.lastName,
                        values.email,
                        values.phone,
                        values.password,
                        values.country,
                        currency
                      );
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      isValid,
                      dirty,
                      handleBlur,
                      handleChange,
                      setFieldTouched,
                      setFieldValue,
                      handleSubmit,
                    }) => (
                      <>
                        <Grid spacing={2} container component="form" onSubmit={handleSubmit}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              name="firstName"
                              label="First Name"
                              placeholder="Enter Your First Name"
                              required
                              value={values.firstName}
                              error={touched.firstName && errors.firstName}
                              helperText={(touched.firstName && errors.firstName) || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              name="lastName"
                              label="Last Name"
                              placeholder="Enter Your Last Name"
                              value={values.lastName}
                              error={touched.lastName && errors.lastName}
                              helperText={(touched.lastName && errors.lastName) || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              name="email"
                              label="Email"
                              type="email"
                              required
                              value={values.email}
                              error={touched.email && errors.email}
                              helperText={(touched.email && errors.email) || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MuiPhone
                              label="Phone Number"
                              required
                              defaultCountry={(defaultCountry === 1 || !defaultCountry ? "" : defaultCountry)
                                .trim()
                                .toLocaleLowerCase()}
                              error={touched.phone && errors.phone}
                              helperText={(touched.phone && errors.phone) || ""}
                              name="phone"
                              placeholder="Enter your Phone Number"
                              onBlur={handleBlur}
                              value={values.phone}
                              onChange={(phone) => {
                                setFieldValue("phone", phone);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              options={countries}
                              getOptionLabel={(country) => country?.name || ""}
                              value={findCountry(values?.country) || ""}
                              onChange={(_, v) => {
                                setFieldTouched("country");
                                setFieldValue("country", v?.code || "");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="country"
                                  onBlur={handleBlur}
                                  error={touched.country && errors.country}
                                  helperText={(touched.country && errors.country) || ""}
                                  label="Country"
                                  required
                                  placeholder="Select your country"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              error={touched.password && errors.password}
                              helperText={touched.password && errors.password}
                              name="password"
                              type="password"
                              label="Password"
                              required
                              placeholder="Enter a Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            {errors.password && (
                              <FormHelperText>
                                *Your password must contain at least 1 number, 1 symbol, 1 uppercase letter, and have a
                                minimum length of 8 characters.
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              error={touched.repeatPassword && errors.repeatPassword}
                              helperText={(touched.repeatPassword && errors.repeatPassword) || ""}
                              name="repeatPassword"
                              label="Confirm Password"
                              placeholder="Repeat the Password"
                              type="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl required error={touched.acceptTerms && errors.acceptTerms}>
                              <FormControlLabel
                                // required
                                control={
                                  <Checkbox
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="acceptTerms"
                                    size="large"
                                  />
                                }
                                labelPlacement="end"
                                sx={{ fontSize: "10px" }}
                                label={
                                  <Box>
                                    <Typography sx={{ fontSize: ".9rem", lineHeight: "1.2rem" }}>
                                      I agree to aTrace{" "}
                                      <a
                                        href="https://atrace.co/terms/"
                                        target="terms"
                                        referrerPolicy="no-referer"
                                        className="underline"
                                      >
                                        Terms &amp; Conditions
                                      </a>{" "}
                                      and{" "}
                                      <a
                                        href="https://atrace.co/privacy/"
                                        target="privacy"
                                        referrerPolicy="no-referer"
                                        className="underline"
                                      >
                                        {" "}
                                        Privacy Policy
                                      </a>{" "}
                                      *
                                    </Typography>
                                    <FormHelperText sx={{ ml: "auto" }}>
                                      {touched.acceptTerms && errors.acceptTerms}
                                    </FormHelperText>
                                  </Box>
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <button
                              type="submit"
                              className={`form-button create ${!(isValid && dirty) ? "disabled" : ""}`}
                              disabled={!(isValid && dirty)}
                            >
                              {isLoading ? "Creating account ..." : "Sign Up"}
                            </button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Formik>
                  {/* Paragraph div */}
                  <div>
                    <p className={classes.side_dashes}>
                      <span className={classes.side_dashes_span} style={{ fontSize: "17px" }}>
                        or continue with
                      </span>
                    </p>
                  </div>

                  {/* Social Button */}
                  <div>
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_AUTH_KEY}
                      onSuccess={handleGoogleAuth}
                      onFailure={(error) => console.error(error)}
                      cookiePolicy={"single_host_origin"}
                      render={(renderProps) => (
                        <button onClick={renderProps.onClick} className="google-button">
                          <FcGoogle className="icon" />
                          <span> Google</span>
                        </button>
                      )}
                    />
                  </div>
                  <FormFooter parag="Already have an account?" linkTitle="Log In" linkPath="/login" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            height="100vh"
            className="right"
            lg={5}
            xl={6}
            item
            sx={{ display: { xs: "none", lg: "block" }, position: "relative" }}
          >
            <Grid
              container
              py={1.5}
              px={1}
              gap={1.5}
              flexDirection={"column"}
              height="100%"
              justifyContent={"center"}
              bgcolor="#033b6c"
              alignItems={"center"}
            >
              <Grid item>
                <Typography px={1} className="hero-text">
                  <span style={{ color: "white" }}>Get started with tracking your shipments from</span> <br />
                  <span style={{ color: "#ff9900" }}>anywhere in the world.</span>
                </Typography>
              </Grid>
              <Grid item my={1}>
                <img
                  style={{
                    margin: "0 auto",
                    width: "auto",
                    height: "40vh",
                  }}
                  src={SignupImage}
                  alt="packages image"
                />
              </Grid>

              {/* <Grid item my={0.5}>
                <Typography fontSize={"1rem"} fontWeight="400" color="white">
                  <span className="pr-2">Already have an account?</span>
                  <span className="text-red-400">
                    <Link to="/login">Sign In</Link>
                  </span>
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SignUp;

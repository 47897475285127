import React, { Fragment, useEffect, useRef, useState } from "react";

import Markdown from "react-markdown";

import { FaRegFilePdf } from "react-icons/fa";
import { MdCancel, MdOutlineCancel } from "react-icons/md";
import { CgAttachment } from "react-icons/cg";
import { BsSend } from "react-icons/bs";
import { toast } from "react-toastify";
import { FaCircleUser } from "react-icons/fa6";

import { DocumentUploadModal } from "./document-upload-modal";
import { ErrorStatus } from "../status/error-status";

import { CustomTextarea } from "../form/textarea";
import { AxiosError } from "axios";
import { ITraceyModal, Message } from "@/types/modal";
import { IUploadDocumentUrl } from "@/types/document-upload";
import { getFromLS, getUserToken } from "@/utils/storage";
import { chatHistory, processPrompt } from "@/queries/prompt";
import { onError } from "@/utils/error";
import { CustomError } from "@/types/utils";
import { transformToEnumFormat } from "@/utils/text";
import { createDocument, generateDocumentUploadLink, uploadDocumentUrl } from "@/queries/document-upload";
import { handleKeyDown } from "@/utils/event";
import { QuickActions } from "@/templates/chatbot/quick-actions";
import { openLinksOnANewTab } from "@/utils/link";
import { RootStateOrAny, useSelector } from "react-redux";

import Loader from "../../../assets/icons/tracey/loader.svg";
import Spinner from "../../../assets/icons/tracey/spinner.svg";
import TraceyIcon from "@/assets/icons/tracey/tracey-icon.svg";

export const TraceyModal = ({ isOpen, onClose }: ITraceyModal) => {
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [messageList, setMessageList] = useState<Message[]>([]);

  const [limit, setLimit] = useState<number>(0);
  const [warningShown, setWarningShown] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");

  const [fileUpload, setFileUpload] = useState<IUploadDocumentUrl | null>(null);
  const [openFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [docType, setDocType] = useState<string>("");

  const [errorStatus, setErrorStatus] = useState<number>(0);

  const [isLoadingProcessPrompt, setIsLoadingProcessPrompt] = useState<boolean>(false);
  const [isLoadingFileUpload, setIsLoadingFileUpload] = useState<boolean>(false);
  const [isLoadingChatHistory, setIsLoadingChatHistory] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const messageEndRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  const session = getUserToken();

  const isDisabled =
    isLoadingFileUpload || isLoadingProcessPrompt || !currentMessage.trim() || (fileName && !currentMessage);

  const resetTraceyChatHistory = useSelector((state: RootStateOrAny) => state.data.resetTraceyChatHistory);

  const handleChatHistory = async () => {
    setIsLoadingChatHistory(true);
    try {
      const { history } = await chatHistory();

      if (history.length > 0) {
        setMessageList((prevMessages) => [...prevMessages, ...history]);
        setIsLoadingChatHistory(false);
      }
    } catch (error) {
      setIsLoadingChatHistory(false);
      onError(error as AxiosError);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      handleGenerateDocumentLink(file);
      setOpenFileUploadModal(false);
    }
  };

  const handleAttachmentClick = () => {
    if (!session) return toast.error("Sorry, you have to be logged in to use this feature.");

    if (session && fileInputRef.current) {
      fileInputRef.current?.click();
    }
  };

  const handleRemoveFile = () => {
    setFileName("");
    setFileUpload(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleGenerateDocumentLink = async (file: File) => {
    setIsLoadingFileUpload(true);

    try {
      if (session) {
        const data = {
          content_type: file?.type || "application/pdf",
          content_length: file?.size || 1024,
          document_type: transformToEnumFormat(docType),
        };
        const res = await generateDocumentUploadLink(data);

        setFileUpload(res?.payload);

        if (res) {
          const uploadedUrl = await uploadDocumentUrl({
            payload: res?.payload,
            file,
          });

          if (!uploadedUrl) {
            const payload = {
              ...data,
              title: file.name,
              extension: "pdf",
              url: res?.payload?.url,
              parent: "",
            };
            const documentData = await createDocument(payload);
            setFileUpload(documentData?.payload);
          }
        }
      }
    } catch (error) {
      onError(error as AxiosError);
    }

    setIsLoadingFileUpload(false);
  };

  const addMessage = (sender: string, text: string) => {
    setMessageList((prevMessages) => [...prevMessages, { sender, text }]);
  };

  const handleSendMessageCheck = (trimmedMessage: string) => {
    if (fileUpload) {
      addMessage("user", fileName);
      setFileUpload(null);
      setFileName("");
    }

    if (trimmedMessage) {
      addMessage("user", trimmedMessage);
      setCurrentMessage("");
    }

    setCurrentMessage("");
    handleRemoveFile();
  };

  const handleSendMessage = async () => {
    const trimmedMessage = currentMessage.trim();

    if (!trimmedMessage) return;

    handleSendMessageCheck(trimmedMessage);

    setIsLoadingProcessPrompt(true);

    try {
      const res = await processPrompt({
        prompt: fileUpload ? fileUpload?.url + " " + trimmedMessage : trimmedMessage,
      });
      addMessage("AI", res.response);
      setLimit((prev) => prev + 1);

      setFileUpload(null);
      setIsLoadingProcessPrompt(false);
    } catch (err) {
      const typedErr = err as CustomError;

      setErrorStatus(typedErr.response?.status ?? 0);
      toast.error(typedErr.response?.data?.response);
      setIsLoadingProcessPrompt(false);
    }
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getPromptSessionId = getFromLS("promptSessionId");

  useEffect(() => {
    openLinksOnANewTab();
    scrollToBottom();

    if (limit === 3 && !session && !warningShown) {
      toast.warn(
        "It looks like you are about to reach your messaging limit, 2 more to go. Sign up for FREE to keep using the service."
      );
      setWarningShown(true);
    }
  }, [limit, messageList]);

  useEffect(() => {
    if (getPromptSessionId) {
      handleChatHistory();
    }
  }, []);

  useEffect(() => {
    if (resetTraceyChatHistory) {
      setMessageList([]);
    }
  }, [messageList]);

  return (
    <div
      className={`fixed inset-0 bg-dark-200 bg-opacity-50 transition-opacity duration-1000 z-[999] ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className={`flex flex-col justify-between w-full md:w-[55%] lg:w-[45%] xl:w-[35%] h-[90vh] bg-white-100  fixed bottom-0 right-0 z-[999] p-6 rounded-t-lg shadow-lg transform transition-transform ${
          isOpen ? "translate-y-0" : "translate-y-full"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <div className="text-center text-blue-100">
            <img src={TraceyIcon} alt="Logo" className="h-[4rem] mx-auto" />
            <div className="pb-4">
              <p className="font-light">I can help with shipments enquiries</p>
            </div>
          </div>

          <div onClick={onClose} className="text-blue-100 cursor-pointer w-max absolute top-2 right-2">
            <MdCancel size="1.5rem" />
          </div>
        </div>

        <div className="py-4 overflow-y-scroll h-full">
          {isLoadingChatHistory && (
            <div className="flex flex-col justify-center h-full">
              <p className="text-center text-blue-100">Fetching chat history</p>
              <img src={Spinner} alt="Spinner" className="h-[5.5rem] mx-auto" />
            </div>
          )}

          {!isLoadingChatHistory && messageList.length === 0 && <QuickActions {...{ setCurrentMessage }} />}

          <div>
            {errorStatus === 429 && <ErrorStatus />}

            {!isLoadingChatHistory && (
              <Fragment>
                {messageList.map((msg, index) => (
                  <div
                    key={index}
                    className={`flex ${msg.sender === "user" ? "justify-end" : "justify-start"} items-start mb-6`}
                  >
                    {msg.sender === "user" ? (
                      <span className="text-blue-100 mr-2">
                        <FaCircleUser size="1.5rem" />
                      </span>
                    ) : (
                      <img src={TraceyIcon} alt="Tracey Icon" className="h-[2rem] mr-2" />
                    )}
                    <div
                      ref={messageEndRef}
                      className={`markdown-style text-sm font-light max-w-full p-3 overflow-x-scroll rounded-lg ${
                        msg.sender === "user" ? "bg-blue-300" : "bg-blue-400"
                      }`}
                    >
                      <Markdown>{msg.text}</Markdown>
                    </div>
                  </div>
                ))}

                {isLoadingProcessPrompt && (
                  <div className="flex justify-start items-center mb-4">
                    <img src={TraceyIcon} alt="Avatar" className="h-[2rem] mr-2" />

                    <div className="max-w-[60%] py-2 px-4 rounded-lg bg-blue-400">
                      <img src={Loader} alt="Loader" className="h-[1.5rem] mr-2" />
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>

        <div>
          <hr className="w-full border-[.05rem] border-blue-2000 mb-4" />

          <div>
            <div className="border border-blue-100 rounded-lg font-light relative">
              <div>
                {fileName && (
                  <div className="flex items-center justify-between p-4">
                    <div className="flex items-center gap-1">
                      <span className="text-red-100 -mt-[.2rem]">
                        {isLoadingFileUpload ? (
                          <img src={Spinner} alt="Spinner" className="h-[1.5rem]" />
                        ) : (
                          <FaRegFilePdf />
                        )}
                      </span>
                      <p className="overflow-hidden text-ellipsis text-[.9rem] mb-[.15rem]">{fileName}</p>
                    </div>

                    <div onClick={handleRemoveFile} className="text-blue-100 cursor-pointer">
                      <MdOutlineCancel size="1rem" />
                    </div>
                  </div>
                )}

                {!fileName && openFileUploadModal && (
                  <div className="p-4">
                    <DocumentUploadModal
                      {...{
                        isOpen: openFileUploadModal,
                        onClose: () => setOpenFileUploadModal(true),
                        setDocType,
                        docType,
                        handleAttachmentClick,
                        fileInputRef,
                        handleFileChange,
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="flex">
                <CustomTextarea
                  {...{
                    onChange: (e) => setCurrentMessage(e.target.value),
                    onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) =>
                      handleKeyDown({
                        event,
                        isDisabled,
                        handleSendMessage,
                      }),
                    defaultValue: "",
                    value: currentMessage,
                    id: "",
                    type: "text",
                    placeholder: "Chat with Tracey",
                  }}
                />

                <div className="flex items-end justify-end gap-2 px-2 pb-2">
                  <div
                    onClick={handleSendMessage}
                    className={`cursor-pointer ${
                      isDisabled ? "not-allowed opacity-30" : "pointer opacity-100"
                    } text-blue-100 `}
                  >
                    <BsSend />
                  </div>

                  <div>
                    <div
                      onClick={() =>
                        !session
                          ? toast.error("Sorry, you have to be logged in to use this feature.")
                          : setOpenFileUploadModal((prev) => !prev)
                      }
                      className="cursor-pointer text-blue-100"
                    >
                      {!fileName && openFileUploadModal ? <MdCancel /> : <CgAttachment />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

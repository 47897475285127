import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead, Autocomplete, TextField, Stack, Button, TablePagination } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { shippingOptions } from "../../utils/shippingLine";
import CollapsableRow from "./CollapsableRow";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as ContainerIcon } from "../../assets/icons/container-icon.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    backgroundColor: theme.palette.common.white,
  },
}));

const CostTable = (props) => {
  return (
    <div>
      <TableLarge {...props} />
      <TableSmall {...props} />
    </div>
  );
};

const TableLarge = (props) => {
  const {
    setSelectedProvider,
    selectedProvider,
    setSelectedContainer,
    selectedContainer,
    setSelectedServiceType,
    selectedServiceType,
    setSelectedBill,
    selectedBill,
    data,
    handleFilterCosts,
    sumTotal,
    containers,
    bill,
    forecastEndDate,
    setForecastEndDateValid,
    forecastError,
    estimateForeCast,
    serviceTypeTemp,
    setServiceTypeTemp,
    providerTemp,
    setProviderTemp,
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const serviceTypeOptions = [
    { value: "default", label: "All Service Type" },
    { value: "demurrage", label: "DEMURRAGE" },
    { value: "detention", label: "DETENTION" },
    { value: "merged", label: "MERGED" },
    { value: "storage", label: "STORAGE" },
  ];

  return (
    <div className="table-large-container">
      <div className="filter-bar">
        <div className="row-two">
          <div className="search-type">
            <Autocomplete
              value={selectedBill}
              onChange={(e, v) => {
                setSelectedBill(!v ? null : v === "All Bill of Lading" ? "default" : v);
                handleFilterCosts(
                  !v || v === "All Bill of Lading" ? "default" : v,
                  selectedServiceType,
                  selectedContainer,
                  selectedProvider
                );
              }}
              sx={{
                minWidth: { sm: "11rem", md: "10rem", lg: "12.2rem" },
              }}
              fullWidth
              options={["All Bill of Lading", ...bill]}
              isOptionEqualToValue={(option, value) =>
                (option === "All Bill of Lading" && value === "default") || option === value
              }
              getOptionLabel={(option) => (option === "default" ? "All Bill of Lading" : option)}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Bill of Lading" />}
            />
          </div>

          <div className="search-type">
            <Autocomplete
              value={selectedContainer}
              onChange={(e, v) => {
                setSelectedContainer(!v ? null : v === "All Containers" ? "default" : v);
                handleFilterCosts(
                  selectedBill,
                  selectedServiceType,
                  !v || v === "All Containers" ? "default" : v,
                  selectedProvider
                );
              }}
              sx={{
                minWidth: { sm: "11rem", md: "10rem", lg: "12.2rem" },
              }}
              fullWidth
              options={["All Containers", ...containers]}
              isOptionEqualToValue={(option, value) =>
                (option === "All Containers" && value === "default") || option === value
              }
              getOptionLabel={(option) => (option === "default" ? "All Containers" : option)}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Containers" />}
            />
          </div>

          <div className="search-type">
            <Autocomplete
              value={providerTemp}
              onChange={(e, v) => {
                setProviderTemp(v ? v : null);
                setSelectedProvider(v ? v.value : null);
                handleFilterCosts(selectedBill, selectedServiceType, selectedContainer, v ? v.value : null);
              }}
              sx={{
                minWidth: { sm: "16.8rem", md: "15.2rem", lg: "16.7rem" },
              }}
              fullWidth
              options={[{ label: "All Providers", value: "" }, ...shippingOptions]}
              isOptionEqualToValue={(option, value) => option?.label == value?.label || option?.value == value?.value}
              getOptionLabel={(option) => option?.label.replaceAll(" ", "\u00A0")}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Providers" />}
            />
          </div>

          <div className="search-type">
            <Autocomplete
              value={serviceTypeTemp}
              onChange={(e, v) => {
                setServiceTypeTemp(v ? v : null);
                setSelectedServiceType(v ? v.value : null);
                handleFilterCosts(selectedBill, v ? v.value : null, selectedContainer, selectedProvider);
              }}
              sx={{
                minWidth: { sm: "12rem", md: "10.9rem", lg: "12.9rem" },
              }}
              fullWidth
              options={serviceTypeOptions}
              isOptionEqualToValue={(option, value) => option?.label == value?.label || option?.value == value?.value}
              getOptionLabel={(option) => option?.label.replaceAll(" ", "\u00A0")}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Service Type" fullWidth />}
            />
          </div>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={5}>
                <Stack direction="row" justifyContent="flex-start" spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast
                      label={"Prediction End-date"}
                      views={["year", "month", "day"]}
                      value={forecastEndDate}
                      onChange={(date) => {
                        setForecastEndDateValid(date);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} error={Boolean(forecastError)} helperText={forecastError} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                  <Button
                    onClick={() => estimateForeCast()}
                    variant="contained"
                    size="small"
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#033B6C",
                      "&:hover": {
                        opacity: 0.9,
                        backgroundColor: "#033B6C",
                      },
                    }}
                  >
                    Predict
                  </Button>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="right" className="table-header" colSpan={2}>
                Sum Total
              </StyledTableCell>
              <StyledTableCell align="left" className="table-header" colSpan={3}>
                {sumTotal}
              </StyledTableCell>
              <StyledTableCell colSpan={1}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="table-header">
                Container
              </StyledTableCell>
              <StyledTableCell align="left" className="table-header">
                Provider
              </StyledTableCell>
              <StyledTableCell align="left" className="table-header">
                Service&nbsp;Type
              </StyledTableCell>
              <StyledTableCell align="center" className="table-header">
                Event
              </StyledTableCell>
              <StyledTableCell align="center" className="table-header">
                Date
              </StyledTableCell>
              <StyledTableCell align="center" className="table-header">
                Free&nbsp;time
              </StyledTableCell>
              <StyledTableCell align="center" className="table-header">
                Slab&nbsp;1
              </StyledTableCell>
              <StyledTableCell align="center" className="table-header">
                Slab&nbsp;2
              </StyledTableCell>
              <StyledTableCell align="center" className="table-header">
                Slab&nbsp;3
              </StyledTableCell>
              <StyledTableCell align="center" className="table-header">
                Slab&nbsp;4
              </StyledTableCell>
              <StyledTableCell align="center" className="table-header">
                Total
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data === "loading" ||
              data.slice(rowsPerPage * page, (page + 1) * rowsPerPage)?.map((estimate, index) =>
                estimate?.cost?.map((cost, index2) => (
                  <React.Fragment key={`cost0${index2}`}>
                    <TableRow>
                      {index2 === 0 ? (
                        <StyledTableCell align="center" className="table-body" rowSpan={estimate?.cost.length * 2}>
                          <div className="flex">
                            <ContainerIcon className="mr-2 container-image" />
                            <Stack
                              sx={{
                                fontSize: ".85rem !important",
                              }}
                            >
                              <div>{estimate?.container}</div>
                              <div>
                                <small>{`${estimate?.containerSize ? estimate?.containerSize : "-"}/${
                                  estimate?.containerType ? estimate?.containerType : "-"
                                }`}</small>
                              </div>
                            </Stack>
                          </div>
                        </StyledTableCell>
                      ) : null}
                      <StyledTableCell align="left" className="" rowSpan={2}>
                        {cost?.provider?.name ? cost?.provider?.name : ""}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="" rowSpan={2}>
                        {cost?.serviceType ? cost?.serviceType : ""}
                      </StyledTableCell>
                      <StyledTableCell align="center" className="" rowSpan={1}>
                        {cost?.events?.[0]?.name ? cost?.events?.[0]?.name : "-"}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className=""
                        rowSpan={1}
                        sx={{
                          color: cost?.events?.[0]?.date?.toLowerCase() === "not yet occurred" ? "red " : "green",
                        }}
                      >
                        {cost?.events?.[0]?.date ? cost?.events?.[0]?.date : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="center" className="" rowSpan={2}>
                        {cost?.freeTime?.days ? `${cost?.freeTime?.days} days x 0 = 0` : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="center" className="" rowSpan={2}>
                        {cost?.slabs?.[0]
                          ? `${cost?.slabs?.[0]?.days ? cost?.slabs?.[0]?.days : "-"} days x ${
                              cost?.currency ? cost?.currency : ""
                            }${cost?.slabs?.[0]?.rate ? cost?.slabs?.[0]?.rate?.toLocaleString("en-US") : "-"} = ${
                              cost?.currency ? cost?.currency : ""
                            }${cost?.slabs?.[0]?.amount ? cost?.slabs?.[0]?.amount?.toLocaleString("en-US") : "-"}`
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="center" className="" rowSpan={2}>
                        {cost?.slabs?.[1]
                          ? `${cost?.slabs?.[1]?.days ? cost?.slabs?.[1]?.days : "-"} days x ${
                              cost?.slabs?.[1]?.rate ? cost?.slabs?.[1]?.rate?.toLocaleString("en-US") : "-"
                            } = ${cost?.currency ? cost?.currency : ""}${
                              cost?.slabs?.[1]?.amount ? cost?.slabs?.[1]?.amount?.toLocaleString("en-US") : "-"
                            }`
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="center" className="" rowSpan={2}>
                        {cost?.slabs?.[2]
                          ? `${cost?.slabs?.[2]?.days ? cost?.slabs?.[2]?.days : "-"} days x ${
                              cost?.slabs?.[2]?.rate ? cost?.slabs?.[2]?.rate?.toLocaleString("en-US") : "-"
                            } = ${cost?.currency ? cost?.currency : ""}${
                              cost?.slabs?.[2]?.amount ? cost?.slabs?.[2]?.amount?.toLocaleString("en-US") : "-"
                            }`
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="center" className="" rowSpan={2}>
                        {cost?.slabs?.[3]
                          ? `${cost?.slabs?.[3]?.days ? cost?.slabs?.[3]?.days : "-"} days x ${
                              cost?.slabs?.[3]?.rate ? cost?.slabs?.[3]?.rate?.toLocaleString("en-US") : "-"
                            } = ${cost?.currency ? cost?.currency : ""}${
                              cost?.slabs?.[3]?.amount ? cost?.slabs?.[3]?.amount?.toLocaleString("en-US") : "-"
                            }`
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="center" className="" rowSpan={2}>
                        {cost?.total?.days
                          ? `${cost?.total?.days} days @ ${
                              cost?.currency ? cost?.currency : ""
                            }${cost?.total?.amount.toLocaleString("en-US")}`
                          : "-"}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell align="center" className="" rowSpan={1}>
                        {cost?.events?.[1]?.name ? cost?.events?.[1]?.name.replaceAll(" ", "\u00A0") : "-"}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        className=""
                        rowSpan={1}
                        sx={{
                          color: cost?.events?.[1]?.date?.toLowerCase() === "not yet occurred" ? "red " : "green",
                        }}
                      >
                        {cost?.events?.[1]?.date ? cost?.events?.[1]?.date : "-"}
                      </StyledTableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="view-more"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
const TableSmall = (props) => {
  const {
    setSelectedProvider,
    selectedProvider,
    setSelectedContainer,
    selectedContainer,
    setSelectedServiceType,
    selectedServiceType,
    setSelectedBill,
    selectedBill,
    data,
    handleFilterCosts,
    sumTotal,
    containers,
    bill,
    forecastEndDate,
    setForecastEndDateValid,
    forecastError,
    estimateForeCast,
    serviceTypeTemp,
    setServiceTypeTemp,
    providerTemp,
    setProviderTemp,
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const serviceTypeOptions = [
    { value: "default", label: "All Service Type" },
    { value: "demurrage", label: "DEMURRAGE" },
    { value: "detention", label: "DETENTION" },
    { value: "merged", label: "MERGED" },
    { value: "storage", label: "STORAGE" },
  ];

  return (
    <div className="table-small-container">
      <div className="filter-bar">
        <div className="row-two">
          <div className="search-type">
            <Autocomplete
              value={selectedBill}
              size="small"
              onChange={(e, v) => {
                setSelectedBill(!v ? null : v === "All Bill of Lading" ? "default" : v);
                handleFilterCosts(
                  !v || v === "All Bill of Lading" ? "default" : v,
                  selectedServiceType,
                  selectedContainer,
                  selectedProvider
                );
              }}
              options={["All Bill of Lading", ...bill]}
              isOptionEqualToValue={(option, value) =>
                (option === "All Bill of Lading" && value === "default") || option === value
              }
              getOptionLabel={(option) => (option === "default" ? "All Bill of Lading" : option)}
              renderInput={(params) => <TextField {...params} fullWidth placeholder="Filter by Bill of Lading" />}
            />
          </div>
        </div>

        <div className="row-two">
          <div className="search-type">
            <Autocomplete
              value={selectedContainer}
              onChange={(e, v) => {
                setSelectedContainer(!v ? null : v === "All Containers" ? "default" : v);
                handleFilterCosts(
                  selectedBill,
                  selectedServiceType,
                  !v || v === "All Containers" ? "default" : v,
                  selectedProvider
                );
              }}
              size="small"
              options={["All Containers", ...containers]}
              isOptionEqualToValue={(option, value) =>
                (option === "All Containers" && value === "default") || option === value
              }
              getOptionLabel={(option) => (option === "default" ? "All Containers" : option)}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Containers" />}
            />
          </div>
        </div>

        <div className="row-two">
          <div className="search-type">
            <Autocomplete
              value={providerTemp}
              onChange={(e, v) => {
                setProviderTemp(v ? v : null);
                setSelectedProvider(v ? v.value : null);
                handleFilterCosts(selectedBill, selectedServiceType, selectedContainer, v ? v.value : null);
              }}
              size="small"
              options={[{ label: "All Providers", value: "" }, ...shippingOptions]}
              isOptionEqualToValue={(option, value) => option?.label == value?.label || option?.value == value?.value}
              getOptionLabel={(option) => option?.label.replaceAll(" ", "\u00A0")}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Providers" />}
            />
          </div>
        </div>

        <div className="row-two">
          <div className="search-type">
            <Autocomplete
              value={serviceTypeTemp}
              onChange={(e, v) => {
                setServiceTypeTemp(v ? v : null);
                setSelectedServiceType(v ? v.value : null);
                handleFilterCosts(selectedBill, v ? v.value : null, selectedContainer, selectedProvider);
              }}
              size="small"
              options={serviceTypeOptions}
              isOptionEqualToValue={(option, value) => option?.label == value?.label || option?.value == value?.value}
              getOptionLabel={(option) => option?.label.replaceAll(" ", "\u00A0")}
              renderInput={(params) => <TextField {...params} placeholder="Filter by Service Type" fullWidth />}
            />
          </div>
        </div>

        <div className="row-two">
          <div className="search-type">
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast
                  label={"Prediction End-date"}
                  views={["year", "month", "day"]}
                  value={forecastEndDate}
                  onChange={(date) => {
                    setForecastEndDateValid(date);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} error={Boolean(forecastError)} size="small" helperText={forecastError} />
                  )}
                />
              </LocalizationProvider>
              <Button
                onClick={() => estimateForeCast()}
                variant="contained"
                size="small"
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#033B6C",
                  "&:hover": {
                    opacity: 0.9,
                    backgroundColor: "#033B6C",
                  },
                }}
              >
                Predict
              </Button>
            </Stack>
          </div>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="right" className="table-header" colSpan={1}>
                Sum Total
              </StyledTableCell>
              <StyledTableCell align="left" className="table-header" colSpan={3}>
                {sumTotal}
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="table-header">
                Container
              </StyledTableCell>

              <StyledTableCell align="left" className="table-header">
                Service&nbsp;Type
              </StyledTableCell>
              <StyledTableCell align="center" className="table-header">
                Total
              </StyledTableCell>
              <StyledTableCell align="left" className="table-header"></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data === "loading" ||
              data?.map((estimate, index) => (
                <React.Fragment key={index}>
                  {estimate?.cost?.map((cost, index2) =>
                    index2 === 0 ? (
                      <CollapsableRow
                        key={`outer${index}inner${index2}`}
                        estimate={{
                          containerSize: estimate?.containerSize,
                          containerType: estimate?.containerType,
                          container: estimate?.container,
                          span: estimate?.cost.length,
                        }}
                        cost={cost}
                      />
                    ) : (
                      <CollapsableRow key={`altouter${index}inner${index2}`} cost={cost} />
                    )
                  )}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="view-more"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
export default CostTable;

import { SelectProps } from "@/types/form";

export const CustomSelect = ({
  options,
  value,
  onChange,
  label,
}: SelectProps) => {
  return (
    <div className="w-full">
      {label && (
        <label className="font-medium text-sm text-blue-100">{label}</label>
      )}
      <div className="relative">
        <select
          className="w-full text-sm border-[.05rem] border-blue-100 p-2 my-2 cursor-pointer rounded-lg focus:outline-none focus:border-blue-100 hover:border-blue-100 appearance-none"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-blue-100">
          <svg
            className="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { IoCaretForward } from "react-icons/io5";
import Joyride, { STATUS } from "react-joyride";
import { useSelector, useDispatch } from "react-redux";
import { setUserData } from "./../../redux/user/user.actions";
import { setGetShipment } from "../../redux/data/data.actions";

export const OnboardingProcessTracking = () => {
  const history = useHistory();
  const [tourHelpers, setTourHelpers] = useState(null);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);

  const handleOnboardingLogic = (data) => {
    const { status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(
        setUserData({
          ...userData,
          onboarding: true,
        })
      );
      dispatch(setGetShipment(null));
      history.push("/dashboard/overview");
    }
  };

  const controlTourHandler = (helpers) => {
    setTourHelpers(helpers);
  };

  const onboardingTrackngSteps = [
    {
      content: (
        <Fragment>
          <div className="px-6 py-7 w-full text-center">
            <h1 className="text-[#033B6C] text-xl">View your shipment events</h1>
          </div>
          <div className="w-full flex justify-between items-center">
            <button className="py-2 px-4 w-[fit-content]" onClick={tourHelpers && tourHelpers.skip}>
              <h1 className="text-[#FB8B23]  text-base font-medium ">Skip</h1>
            </button>
            <button
              className="py-2 px-4 w-[fit-content] flex items-center gap-x-[2px] ml-auto"
              onClick={tourHelpers && tourHelpers.next}
            >
              <h1 className="text-[#033B6C]  text-base font-bold ">Explore</h1>
              <IoCaretForward className="text-[#033B6C] " />
            </button>
          </div>
        </Fragment>
      ),
      target: ".onboard-tracking",
    },
  ];

  const Tooltip = ({ step, tooltipProps }) => {
    return (
      <>
        <div
          className="bg-white-100 rounded-[20px] pt-6 pb-2 flex flex-col items-center px-4 w-screen lg:w-auto lg:px-6"
          {...tooltipProps}
        >
          {step.content}
        </div>
      </>
    );
  };

  return (
    <>
      <Joyride
        run={true}
        steps={onboardingTrackngSteps}
        continuous={true}
        tooltipComponent={Tooltip}
        callback={handleOnboardingLogic}
        getHelpers={controlTourHandler}
        scrollToFirstStep={true}
        spotlightClicks={true}
        spotlightPadding={0}
        styles={{
          options: {
            arrowColor: "#fff",
            zIndex: 2000000,
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
        }}
      />
    </>
  );
};

export const setParsedShipments = (parsedShipments) => ({
  type: "SET_PARSED_SHIPMENTS",
  payload: { parsedShipments },
});

export const setGetShipment = (getShipment) => ({
  type: "SET_GET_SHIPMENT",
  payload: { getShipment },
});

export const setTrackedShipments = (trackedShipments) => ({
  type: "SET_TRACKED_SHIPMENTS",
  payload: { trackedShipments },
});

export const deleteTrackedShipment = (trackedShipmentId) => ({
  type: "DELETE_TRACKED_SHIPMENT",
  payload: { trackedShipmentId },
});

export const setDownloadableTrackedShipments = (downloadableTrackedShipments) => ({
  type: "SET_TRACKED_DOWNLOADABLE_SHIPMENTS",
  payload: { downloadableTrackedShipments },
});

export const setEfficiencyReportData = (efficiencyReport) => ({
  type: "SET_EFFICIENCY_REPORT_DATA",
  payload: { efficiencyReport },
});

export const setETAStatusPreferences = (ETAStatusPreferences) => ({
  type: "SET_ETA_STATUS_PREFERENCES",
  payload: { ETAStatusPreferences },
});

export const setManagedVendors = (managedVendors) => ({
  type: "SET_MANAGED_VENDORS",
  payload: { managedVendors },
});

//kk change
export const setHelperData = (vendors) => ({
  type: "SET_ALL_VENDORS",
  payload: { vendors },
});
// kkchange

//kk change
export const setAllBill = (bills) => ({
  type: "SET_ALL_BILL",
  payload: { bills },
});
// kkchange

//kk change
export const setAllContainers = (containers) => ({
  type: "SET_ALL_CONTAINERS",
  payload: { containers },
});
// kkchange

//kk change
export const setFormMData = (getFormMData) => ({
  type: "SET_ALL_FORM_M",
  payload: { getFormMData },
});
// kkchange

//kk change
export const setServiceProviders = (providers) => ({
  type: "SET_ALL_SERVICE_PROVIDERS",
  payload: { providers: providers ? providers : null },
});
// kkchange

//kk change
export const setTradeTerm = (tradeTerm) => ({
  type: "SET_TRADE_TERMS",
  payload: { tradeTerm: tradeTerm ? tradeTerm : [] },
});
// kkchange

//kk change
export const addTradeTerm = (tradeTerm) => ({
  type: "ADD_TRADE_TERMS",
  payload: { tradeTerm: tradeTerm ? tradeTerm : [] },
});
// kkchange

//kk change
export const setCostEstimates = (costEstimates) => ({
  type: "SET_COST_ESTIMATES",
  payload: { costEstimates: costEstimates ? costEstimates : [] },
});
// kk change

export const setShowNotification = (showNotification) => ({
  type: "SET_SHOW_NOTIFICATION",
  payload: { showNotification },
});

export const setTrackingStartedModal = (trackingStartedModal) => ({
  type: "SET_TRACKING_STARTED_MODAL",
  payload: { trackingStartedModal },
});

export const setDynamicMapCenter = (dynamicMapCenter) => ({
  type: "SET_DYNAMIC_MAP_CENTER",
  payload: { dynamicMapCenter },
});

export const setDashboardData = (dashboardData) => ({
  type: "SET_DASHBOARD_DATA",
  payload: { dashboardData },
});

export const setExceptions = (exceptions) => ({
  type: "SET_EXCEPTIONS",
  payload: { exceptions },
});

export const setShipmentsLastLocations = (shipmentsLastLocations) => ({
  type: "SET_SHIPMENTS_LAST_LOCATIONS",
  payload: { shipmentsLastLocations },
});

export const setUnreadNotifications = (unreadNotifications) => ({
  type: "SET_UNREAD_NOTIFICATIONS",
  payload: { unreadNotifications },
});

export const setUserCredits = (userCredits) => ({
  type: "SET_USER_CREDITS",
  payload: { userCredits },
});

export const setCreditInfo = (creditInfo) => ({
  type: "SET_CREDITS_INFO",
  payload: { creditInfo },
});

export const setSubscriptionInfo = (subscriptionInfo) => ({
  type: "SET_SUBSCRIPTION_INFO",
  payload: { subscriptionInfo },
});

export const setTablePreference = (tablePreference) => ({
  type: "SET_TABLE_PREFERENCE",
  payload: { tablePreference },
});

export const setPaymentIntent = (paymentIntent) => ({
  type: "SET_PAYMENT_INTENT",
  payload: { paymentIntent },
});

export const setBLInvoices = (getAllUserInvoices) => ({
  type: "SET_BL_INVOICES",
  payload: { getAllUserInvoices },
});

export const setAllInvoices = (getAllUserInvoices) => ({
  type: "SET_USER_INVOICES",
  payload: { getAllUserInvoices },
});

export const setAllUserDownloadInvoices = (getAllUserInvoices) => ({
  type: "SET_USER_DOWNLOAD_INVOICES",
  payload: { getAllUserInvoices },
});

export const setShipmentWaypoints = (shipmentWaypoints) => ({
  type: "SET_SHIPMENT_WAYPOINTS",
  payload: { shipmentWaypoints },
});

export const setResetTraceyChatHistory = (resetTraceyChatHistory) => ({
  type: "SET_RESET_TRACEY_CHAT_HISTORY",
  payload: { resetTraceyChatHistory },
});

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, TextField, Autocomplete, Button, Box } from "@mui/material";
import {Fieldset} from "../../../../components/v2/form/fieldset";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { countries, findCountry } from "../../../../utils/all-countries";
import { useService } from "../../../../hooks/useService";
import { LoadingButton } from "@mui/lab";
import { ParseServerDate } from "../../../../utils/helpers";

export const Transport = ({ data, controls }) => {
  const [counter, setCounter] = useState(0);
  const { handleUpdateCommonHealthDeclaration } = useService();
  const getCreateCommonHealthDeclarationData = JSON.parse(localStorage.getItem("commonHealthDeclaration"));

  const convertToSnakeCase = (inputString) => {
    return inputString.toUpperCase().replace(/\s+/g, "_");
  };

  const formik = useFormik({
    initialValues: {
      portOfEntry: "",
      transportMeansToPort: "",
      transportMeansAfterBCP: "",
      transportIdentification: "",
      waybill: "",
      waybillBCP: "",
      gvms: "",
      containersOrTrailers: "",
      estimatedArrivalTime: "",
      estimatedDepartureTime: "",
      containerNumberOrTrailerNumber: "",
      sealNumber: "",
      transporter: [
        {
          id: counter,
          name: "",
          address1: "",
          address2: "",
          city: "",
          country: "",
          postcode: "",
        },
      ],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, { setSubmitting }) => {
      if (!getCreateCommonHealthDeclarationData) {
        return;
      }

      const { data } = await handleUpdateCommonHealthDeclaration({
        ...getCreateCommonHealthDeclarationData.commonHealthDeclaration,
        portOfEntry: values.portOfEntry,
        transportMode: convertToSnakeCase(values.transportMeansToPort.toUpperCase()),
        arrivalDate: ParseServerDate(values.estimatedArrivalTime),
        vehicleIdentification: values.transportIdentification,
        useGVMS: values.gvms === "Yes",
        bcpTransportMode: convertToSnakeCase(values.transportMeansAfterBCP?.toUpperCase()),
        bcpVehicleIdentification: values.transportIdentification,
        bcpDepartureDate: ParseServerDate(values.estimatedDepartureTime),
        bcpWaybillNumber: values.waybillNumber,
        transporters: values.transporter.map((item) => {
          return {
            name: item.name,
            city: item.city,
            country: item.country,
            zipCode: item.postcode,
            address1: item.address1,
            address2: item.address2,
          };
        }),
      });

      if (data?.updateCommonHealthDeclaration) {
        localStorage.setItem(
          "commonHealthDeclaration",
          JSON.stringify({ commonHealthDeclaration: data?.updateCommonHealthDeclaration })
        );
        setSubmitting(false);
        controls(5);
      }
    },
  });

  const { values, touched, errors, setFieldValue, setValues, handleSubmit, handleChange, handleBlur, isSubmitting } =
    formik;

  useEffect(() => {
    if (data && Object?.values(data)?.length) {
      setValues(data);
    }
  }, [data]);

  return (
    <Box mb="5rem">
      <Fieldset fieldsetStyles={{ fontFamily: "monospace" }} legend="Transport to the Port of Entry">
        <Grid container spacing={2}>
          <>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="containersOrTrailers"
                name="containersOrTrailers"
                options={["Yes", "No"]}
                value={values?.containersOrTrailers}
                onChange={(e, value) => setFieldValue("containersOrTrailers", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    error={errors?.containersOrTrailers && touched?.containersOrTrailers}
                    helperText={errors?.containersOrTrailers && touched?.containersOrTrailers}
                    label="Containers or Trailers"
                    size="small"
                  />
                )}
              />
            </Grid>

            {values?.containersOrTrailers === "Yes" && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Container number / Trailer number"
                    size="small"
                    fullWidth
                    name="containerNumberOrTrailerNumber"
                    InputLabelProps={{ shrink: true }}
                    value={values.containerNumberOrTrailerNumber}
                    error={touched.containerNumberOrTrailerNumber && Boolean(errors.containerNumberOrTrailerNumber)}
                    helperText={touched.containerNumberOrTrailerNumber && errors.containerNumberOrTrailerNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Seal Number"
                    size="small"
                    fullWidth
                    name="sealNumber"
                    value={values.sealNumber}
                    error={touched.sealNumber && Boolean(errors.sealNumber)}
                    helperText={touched.sealNumber && errors.sealNumber}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </>
            )}
          </>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Port Of Entry"
              size="small"
              fullWidth
              name="portOfEntry"
              InputLabelProps={{ shrink: true }}
              value={values?.portOfEntry}
              error={touched.portOfEntry && Boolean(errors.portOfEntry)}
              helperText={touched.portOfEntry && errors.portOfEntry}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              name="transportMeansToPort"
              options={["Airplane", "Railway", "Road vehicle", "Vessel"]}
              value={values?.transportMeansToPort}
              onChange={(e, value) => setFieldValue("transportMeansToPort", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Means of transport to port"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  error={touched.transportMeansToPort && Boolean(errors?.transportMeansToPort)}
                  helperText={touched.transportMeansToPort && errors?.transportMeansToPort}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Port Of Discharge"
              size="small"
              fullWidth
              name="portOfDischarge"
              InputLabelProps={{ shrink: true }}
              value={values?.portOfDischarge}
              error={touched.portOfDischarge && Boolean(errors.portOfDischarge)}
              helperText={touched.portOfDischarge && errors.portOfDischarge}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Transport document reference (Waybill / Manifest / Bill of Lading / Vessel name)"
              size="small"
              fullWidth
              name="waybillBCP"
              InputLabelProps={{ shrink: true }}
              value={values.waybillBCP}
              error={touched.waybillBCP && Boolean(errors.waybillBCP)}
              helperText={touched.waybillBCP && errors.waybillBCP}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              name="gvms"
              options={["Yes", "No"]}
              value={values?.gvms}
              onChange={(e, value) => setFieldValue("gvms", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Will use GVMS?"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  error={touched?.gvms && Boolean(errors?.gvms)}
                  helperText={touched?.gvms && errors?.gvms}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Estimated Port Arrival"
              InputLabelProps={{ shrink: true }}
              name="estimatedArrivalTime"
              type="date"
              error={touched.estimatedArrivalTime && errors.estimatedArrivalTime}
              helperText={
                touched.estimatedArrivalTime && errors.estimatedArrivalTime ? errors.estimatedArrivalTime : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.estimatedArrivalTime}
            />
          </Grid>
        </Grid>
      </Fieldset>

      <Fieldset fieldsetStyles={{ fontFamily: "monospace" }} legend="Transport after Border Control Post (BCP)">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              name="transportMeansAfterBCP"
              options={["Airplane", "Railway", "Road vehicle", "Vessel"]}
              value={values?.transportMeansAfterBCP}
              onChange={(e, value) => setFieldValue("transportMeansAfterBCP", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Means of transport after BCP"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  error={touched.transportMeansAfterBCP && Boolean(errors?.transportMeansAfterBCP)}
                  helperText={touched.transportMeansAfterBCP && errors?.transportMeansAfterBCP}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Transport identification (flight no, vessel name, etc)"
              size="small"
              fullWidth
              name="transportIdentification"
              InputLabelProps={{ shrink: true }}
              value={values?.transportIdentification}
              error={touched.transportIdentification && Boolean(errors.transportIdentification)}
              helperText={touched.transportIdentification && errors.transportIdentification}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Transport document reference (Waybill / Manifest / Bill of Lading / Vessel name)"
              size="small"
              fullWidth
              name="waybill"
              InputLabelProps={{ shrink: true }}
              value={values.waybill}
              error={touched.waybill && Boolean(errors.waybill)}
              helperText={touched.waybill && errors.waybill}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Estimated departure from BCP"
              InputLabelProps={{ shrink: true }}
              name="estimatedDepartureTime"
              type="date"
              error={touched.estimatedDepartureTime && errors.estimatedDepartureTime}
              helperText={
                touched.estimatedDepartureTime && errors.estimatedDepartureTime ? errors.estimatedDepartureTime : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.estimatedDepartureTime}
            />
          </Grid>
        </Grid>
      </Fieldset>

      <Grid container spacing={2} mt="2rem">
        {values?.transporter?.map((item, index) => {
          return (
            <Grid key={index} item xs={12} md={6}>
              <Fieldset
                marginBottom=""
                legendStyles={{ fontFamily: "monospace" }}
                fieldsetStyles={{ margin: 0 }}
                key={index}
                legend={`Transporter ${`${index + 1}`.padStart(2, "0")}`}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Name"
                      size="small"
                      fullWidth
                      name={`transporter[${index}].name`}
                      value={item?.name}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.transporter?.[index]?.name && Boolean(errors?.transporter?.[index]?.name)}
                      helperText={touched?.transporter?.[index]?.name && errors?.transporter?.[index]?.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address1"
                      size="small"
                      fullWidth
                      name={`transporter[${index}].address1`}
                      value={item?.address1}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.transporter?.[index]?.address1 && Boolean(errors?.transporter?.[index]?.address1)}
                      helperText={touched?.transporter?.[index]?.address1 && errors?.transporter?.[index]?.address1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Address2"
                      size="small"
                      fullWidth
                      name={`transporter[${index}].address2`}
                      value={item?.address2}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.transporter?.[index]?.address2 && Boolean(errors?.transporter?.[index]?.address2)}
                      helperText={touched?.transporter?.[index]?.address2 && errors?.transporter?.[index]?.address2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="City"
                      size="small"
                      fullWidth
                      name={`transporter[${index}].city`}
                      value={item?.city}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.transporter?.[index]?.city && Boolean(errors?.transporter?.[index]?.city)}
                      helperText={touched?.transporter?.[index]?.city && errors?.transporter?.[index]?.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <TextField
                      label="Postcode"
                      size="small"
                      fullWidth
                      name={`transporter[${index}].postcode`}
                      value={item?.postcode}
                      InputLabelProps={{ shrink: true }}
                      error={touched?.transporter?.[index]?.postcode && Boolean(errors?.transporter?.[index]?.postcode)}
                      helperText={touched?.transporter?.[index]?.postcode && errors?.transporter?.[index]?.postcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} xl={4}>
                    <Autocomplete
                      id={`transporter[${index}].country`}
                      name={`transporter[${index}].country`}
                      options={countries}
                      getOptionLabel={(option) => option.name || ""}
                      value={findCountry(item?.country) || ""}
                      onChange={(e, value) => {
                        setFieldValue(`transporter[${index}].country`, value?.code);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          size="small"
                          error={
                            touched?.transporter?.[index]?.country && Boolean(errors?.transporter?.[index]?.country)
                          }
                          helperText={touched?.transporter?.[index]?.country && errors?.transporter?.[index]?.country}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        onClick={() =>
                          values?.transporter?.length > 1
                            ? setFieldValue(
                                "transporter",
                                values?.transporter.filter((e) => e.id !== values?.transporter?.[index]?.id)
                              )
                            : toast.error("At least one transporter is required")
                        }
                        variant="outlined"
                        color="error"
                        endIcon={<MdDelete />}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Fieldset>
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <Box display="flex">
            <Button
              onClick={() => {
                setFieldValue("transporter", [
                  ...(Array.isArray(values.transporter) ? values.transporter : []),
                  {
                    id: counter + 1,
                    name: "",
                    address1: "",
                    address2: "",
                    city: "",
                    postcode: "",
                    country: "",
                  },
                ]);
                setCounter(counter + 1);
              }}
              variant="outlined"
            >
              Add Transporter
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container xs={12} mt="1rem" gap="1rem">
        <Grid item>
          <Box>
            <Button
              onClick={() => {
                controls(3);
              }}
              type="submit"
              variant="contained"
              sx={{ textAlign: "right" }}
            >
              Previous
            </Button>
          </Box>
        </Grid>

        <Grid item>
          <Box>
            <LoadingButton
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={isSubmitting || !values.transporter?.[0]?.country || !values.estimatedArrivalTime}
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save and Continue
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Transport;

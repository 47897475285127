import React, { useState, useEffect } from "react";
import CollapsibleRow from "../CollapsibleTable";
import { TableContainer, Table, TableBody, TableHead, TableCell, TableRow, Grid } from "@mui/material";
import ColumnFilter from "../ColumnFilter/ColumnFilter";
import {
  EFFICIENCY_TABLE_DEFAULT_DATA as ETDD,
  SMALL_EFFICIENCY_TABLE_DEFAULT_DATA as SETDD,
  SMALL_EFFICIENCY_TABLE_DEFAULT_DATA_VERSION as SV,
  EFFICIENCY_TABLE_DEFAULT_DATA_VERSION as EV,
} from "../../utils/data";

const EfficiencyReportTable = ({ data, changeSortOrder, sortOrder, sortValue, sortFieldName }) => {
  const [defaultPreference, setDefaultPreference] = useState(() => {
    const storedPref = JSON.parse(localStorage.getItem("efficiencyReportDefaultColumnPreferences"));
    return storedPref?.data && storedPref?.version == EV ? storedPref?.data : ETDD;
  });
  const [smallPreferences, setSmallPreferences] = useState(() => {
    const storedPref = JSON.parse(localStorage.getItem("efficiencyReportSmallColumnPreferences"));
    return storedPref?.version && storedPref?.version == SV ? storedPref?.data : SETDD;
  });

  useEffect(() => {
    if (
      localStorage.getItem("efficiencyReportSmallColumnPreferences") !==
      JSON.stringify({ data: smallPreferences, version: SV })
    ) {
      let version = JSON.parse(localStorage.getItem("efficiencyReportSmallColumnPreferences"))?.version;
      localStorage.setItem(
        "efficiencyReportSmallColumnPreferences",
        JSON.stringify({ data: version === SV ? smallPreferences : SETDD, version: SV })
      );
    }
  }, [smallPreferences]);

  useEffect(() => {
    if (
      localStorage.getItem("efficiencyReportDefaultColumnPreferences") !==
      JSON.stringify({ data: defaultPreference, version: EV })
    ) {
      let version = JSON.parse(localStorage.getItem("efficiencyReportDefaultColumnPreferences"))?.version;
      localStorage.setItem(
        "efficiencyReportDefaultColumnPreferences",
        JSON.stringify({ data: version === EV ? defaultPreference : ETDD, version: EV })
      );
    }
  }, [defaultPreference]);

  return (
    <TableContainer>
      <Grid container spacing={1}>
        <Grid item>
          <ColumnFilter
            preferences={smallPreferences}
            setPreferences={setSmallPreferences}
            buttonTitle="Inner columns"
          />
        </Grid>
        <Grid item>
          <ColumnFilter preferences={defaultPreference} setPreferences={setDefaultPreference} buttonTitle="Columns" />
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          {defaultPreference.map(
            (pref) =>
              pref?.show && (
                <TableCell key={pref.title} title={pref.fullName}>
                  <div style={{ display: "flex", justifyContent: "space-between", gap: "5px" }}>
                    <div>{pref.title}</div>
                  </div>
                </TableCell>
              )
          )}
          <TableCell></TableCell>
        </TableHead>
        <TableBody>
          {data.map((data) =>
            data?.months?.map((eRt, i) => (
              <CollapsibleRow
                key={eRt.id}
                buttonPosition="end"
                row={defaultPreference
                  .map(
                    (pref, i) =>
                      pref.show && (
                        <TableCell key={`${eRt.id}${pref.title}`}>
                          {eRt?.[pref.filterBy[pref.filterBy.length - 1]]}
                        </TableCell>
                      )
                  )
                  .filter((r) => r)}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      {smallPreferences.map(
                        (pref) =>
                          pref.show && (
                            <TableCell title={pref.fullName} key={`${eRt.id}-details-${pref.title}`}>
                              {pref.title}
                            </TableCell>
                          )
                      )}
                    </TableHead>
                    <TableBody>
                      {eRt?.data?.map((data) => (
                        <TableRow key={data.id}>
                          {smallPreferences.map(
                            (pref) =>
                              pref.show && (
                                <TableCell
                                  title={`${pref?.fullName} Data`}
                                  key={`${eRt?.id}-details-${pref?.title}-data`}
                                >
                                  {data[pref?.filterBy?.[pref?.filterBy?.length - 1]]}
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CollapsibleRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EfficiencyReportTable;

import axios from "axios";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPLOAD_DOCUMENT_LINK, CREATE_DOCUMENT, GET_DOCUMENTS } from "../graphql/documents";
import { useDispatch } from "react-redux";
import { setShowNotification } from "../redux/data/data.actions";

export const useDocument = () => {
  const dispatch = useDispatch();

  const [prepareFileUpload] = useMutation(UPLOAD_DOCUMENT_LINK, {
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });

  const handlePrepareFileUpload = async (file) => {
    let contentType = file?.type || "application/pdf";
    let contentLength = file?.size || 1024;
    const res = await prepareFileUpload({
      variables: {
        input: {
          contentType,
          contentLength,
        },
      },
    });
    let linkData = res.data;
    return linkData?.prepareUpload || null;
  };

  const [createDocument] = useMutation(CREATE_DOCUMENT, {
    onCompleted: (_) => {
      dispatch(setShowNotification({ isOpen: true, message: "Document successfully created! 🎉", type: "success" }));
    },
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });

  const handleCreateDocument = async (
    title,
    parent,
    url,
    documentType,
    extension,
    contentLength = 1024,
    contentType = "application/pdf"
  ) => {
    const res = await createDocument({
      variables: {
        input: {
          title,
          url,
          documentType,
          extension,
          contentType,
          contentLength,
          parent,
        },
      },
    });
    let linkData = res.data;
    return linkData?.createDocument || null;
  };

  const [getDocuments] = useLazyQuery(GET_DOCUMENTS, {
    onCompleted: (data) => {
      return data?.documents;
    },
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });

  const handleGetDocuments = async (page = 1, perPage = 10, id = "", references = []) => {
    const res = await getDocuments({
      variables: {
        filter: {
          page,
          perPage,
          id,
          references,
        },
      },
    });
    let linkData = res.data;
    return linkData?.documents || null;
  };

  return {
    handlePrepareFileUpload,
    handleCreateDocument,
    handleGetDocuments,
  };
};

import React from "react";
import { Doughnut } from "react-chartjs-2";
import { etaData, etaOptions } from "../../utils/charts";

const index = ({options}) => {
  return (
    <>
      <Doughnut data={etaData} options={{...options,...etaOptions}} />
      {/* <div className="lg:eta-per w-[28px] h-[28px] ontime p-5 rounded-[50%] lg:bg-white-100 flex items-center justify-center  absolute -right-7 lg:right-0 top-0 lg:top-[10%]">
        <h1 className="text-[#2626264d] lg:text-[#000000] text-lg lg:text-sm font-bold">{props.ontime}</h1>
      </div>
      <div className="lg:eta-per w-[28px] h-[28px] late p-5 rounded-[50%] lg:bg-white-100 flex items-center justify-center text-[#000000] text-sm font-bold absolute -left-7 lg:left-0 top-0 lg:top-[10%]">
        <h1 className="text-[#2626264d] lg:text-[#000000] text-lg lg:text-sm font-bold">{props.late}</h1>
      </div>
      <div className="lg:eta-per w-[28px] h-[28px] critical p-5 rounded-[50%] lg:bg-white-100 flex items-center justify-center text-[#000000] text-sm font-bold absolute left-[50%] transform translate-x-[-50%] -bottom-11 lg:bottom-[-5px]">
        <h1 className="text-[#2626264d] lg:text-[#000000] text-lg lg:text-sm font-bold">{props.critical}</h1>
      </div> */}
    </>
  );
};

export default index;

import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Button, Modal } from "@mui/material";
import { MdUploadFile } from "react-icons/md";
import { Commodities, Consignment, ContactDetails, Documents, Traders, Transport } from "./forms";
import { ServicesFileUpload } from "../../../components/FileUpload/ServicesFileUpload";
import { fuzzyFindCountryCode } from "../../../utils/all-countries";
import Pills from "../../../components/Pills";
import { fieldsArrayToObject, fixPDFDate } from "../../../utils/helpers";
import { useSelector } from "react-redux";

const CreateIpaff = () => {
  const [pillIndex, setPillIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [parsedData, setParsedData] = useState();
  const [data, setData] = useState();
  const userData = useSelector((state) => state.user.userData);
  const documentFileType = localStorage.getItem("documentFileType");

  useEffect(() => {
    if (!parsedData) {
      return;
    }

    const { fields } = parsedData.data.commercialInvoiceDocument;
    const fieldsData = fieldsArrayToObject(fields);
    const commoditiesArr = fieldsArrayToObject(fieldsData.itemDetails);
    const nestedCommoditiesArr = Object.values(commoditiesArr);
    const mappedcommoditiesArr = nestedCommoditiesArr.map((item) => fieldsArrayToObject(item));

    const countryOriginCode = fuzzyFindCountryCode(fieldsData.countryOrigin.valueString);
    const transporterCountryCode = fuzzyFindCountryCode(fieldsData.transporterCountry.valueString);
    const placeOfDestinationsCountryCode = fuzzyFindCountryCode(fieldsData.deliveryCountry.valueString);

    const consignments = {
      requiresRegulatoryRequirement: "Yes",
      provideBCPDetails: "No",
      internalReferenceNumber: fieldsData.referenceNo.valueString,
      consignmentName: "",
      consignmentOrigin: countryOriginCode,
      requiresRegionOrigin: "Yes",
      consignedOrigin: countryOriginCode,
      importReason: "Internal market",
      marketPurpose: "Human consumption",
      movementReferenceNumber: "",
      destinationCountry: "",
      exitBCP: "",
      riskCategory: "Low",
      consigmentExitTime: "",
      transitedCountries: [],
    };

    const commodities = {
      commodities: mappedcommoditiesArr.map((item, idx) => {
        return {
          id: `prefiled-${idx + 1}`,
          commodityCode: item.commodityCode.valueString,
          netWeight: item.netWeight.valueString.replace(/,/g, "") || 0,
          grossWeight: item.grossWeight.valueString.replace(/,/g, "") || 0,
          numberOfPackages: item.packageQty.valueString,
          typeOfPackage: item.packageType.valueString,
          temperature: "Ambient",
        };
      }),
      totalNetWeight: mappedcommoditiesArr
        .reduce((acc, curr) => acc + Number(curr.netWeight?.valueString.replace(/,/g, "") || 0), 0)
        .toFixed(2),
      totalPackages: mappedcommoditiesArr.reduce((acc, curr) => acc + Number(curr.packageQty?.valueString || 0), 0),
      totalGrossWeight: mappedcommoditiesArr
        .reduce((acc, curr) => acc + Number(curr.grossWeight?.valueString.replace(/,/g, "") || 0), 0)
        .toFixed(2),
    };

    const documents = {
      documents: [
        {
          type: documentFileType || fieldsData.documentType.valueString,
          reference: fieldsData.documentNo.valueString,
          attachment: "",
          issueDate: fixPDFDate(fieldsData.issueDate.valueString),
        },
      ],
      establishmentofOrigin: [
        {
          name: "",
          shipmentValue: "",
          country: "",
          type: "",
          approvalNumber: "",
        },
      ],
    };

    const traders = {
      tradersSelectedContact: [
        {
          traderType: "Consignor",
          name: fieldsData.billingName.valueString,
          address1: fieldsData.billingAddress1.valueString,
          address2: fieldsData.billingAddress2.valueString,
          address3: "",
          city: fieldsData.billingCity.valueString,
          country: fuzzyFindCountryCode(fieldsData.billingCountry.valueString),
          postcode: fieldsData.billingPostcode.valueString,
          email: "",
          phone: "",
        },
        {
          traderType: "Consignee",
          name: fieldsData.customerName.valueString,
          email: "",
          phone: fieldsData.customerNo.valueString,
          address1: fieldsData.customerAddress1.valueString,
          address2: fieldsData.customerAddress2.valueString,
          address3: "",
          city: fieldsData.customerCity.valueString,
          country: fuzzyFindCountryCode(fieldsData.customerCountry.valueString),
          postcode: fieldsData.customerPostcode.valueString,
        },
        {
          traderType: "Importer",
          name: fieldsData.customerName.valueString,
          email: "",
          phone: fieldsData.customerNo.valueString,
          address1: fieldsData.customerAddress1.valueString,
          address2: fieldsData.customerAddress2.valueString,
          address3: "",
          city: fieldsData.customerCity.valueString,
          country: fuzzyFindCountryCode(fieldsData.customerCountry.valueString),
          postcode: fieldsData.customerPostcode.valueString,
        },
      ],
      traders: [],
      placeOfDestinations: [
        {
          name: fieldsData.deliveryName.valueString,
          email: "",
          phone: "",
          address1: fieldsData.deliveryAddress1.valueString,
          address2: fieldsData.deliveryAddress2.valueString,
          address3: "",
          city: fieldsData.deliveryCity.valueString,
          country: placeOfDestinationsCountryCode,
          conditions: fieldsData.deliveryConditions.valueString,
          postcode: fieldsData.deliveryPostcode.valueString,
        },
      ],
    };

    const transport = {
      portOfEntry: fieldsData.portOfLoading.valueString,
      portOfDischarge: fieldsData.portOfDischarge.valueString,
      transportMeansToPort: fieldsData.meansTransport.valueString,
      transportIdentification: fieldsData.transportIdentification.valueString,
      waybill: fieldsData.wayBill.valueString,
      gvms: "No",
      estimatedArrivalTime: "",
      containersOrTrailers: fieldsData.isContainer.valueString === "CONTAINER" ? "Yes" : "No",
      containerNumberOrTrailerNumber: fieldsData.containerNumber.valueString,
      sealNumber: fieldsData.sealNumber.valueString,
      transporter: [
        {
          name: fieldsData.transporterName.valueString,
          address1: fieldsData.transporterAddress1.valueString,
          address2: fieldsData.transporterAddress2.valueString,
          city: fieldsData.transporterCity.valueString,
          country: transporterCountryCode,
          postcode: fieldsData.transporterPostcode.valueString,
        },
      ],
    };

    const contactDetails = {
      name: userData?.name,
      email: userData?.email,
      phone: userData?.telephone,
      nominatedContacts: [
        {
          name: "",
          email: "",
          phone: "",
        },
      ],
    };

    setData((prevData) => ({
      ...prevData,
      consignments,
      commodities,
      documents,
      traders,
      transport,
      contactDetails,
    }));
  }, [parsedData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography color="primary" variant="h2" fontSize="1.2rem" fontWeight="600" px="1rem">
          Common Health Entry Document UK Import / Export{" "}
        </Typography>
      </Grid>

      <Grid item xs={12} component="form">
        <Box
          px={2}
          py={1}
          width={"100%"}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={{ xs: "2rem", sm: 0 }}
          justifyContent="space-between"
          alignItems={{ xs: "left", sm: "center" }}
          mt="2rem"
          mb="1rem"
        >
          <Typography color="primary" variant="h2" fontSize="1.2rem">
            Create New Notification
          </Typography>

          <Button
            startIcon={<MdUploadFile />}
            variant="outlined"
            onClick={() => setOpenModal(!openModal)}
            sx={{ textTransform: "none" }}
          >
            Prefill with a file upload
          </Button>
        </Box>

        <Pills
          value={pillIndex}
          setValue={setPillIndex}
          loading={false}
          customOnChange={() => {}}
          elements={[
            {
              title: "Consignment",
              component: () => <Consignment data={data?.consignments} controls={setPillIndex} />,
            },
            {
              title: "Commodities",
              component: () => <Commodities data={data?.commodities} controls={setPillIndex} />,
            },
            { title: "Documents", component: () => <Documents data={data?.documents} controls={setPillIndex} /> },
            { title: "Traders", component: () => <Traders data={data?.traders} controls={setPillIndex} /> },
            { title: "Transport", component: () => <Transport data={data?.transport} controls={setPillIndex} /> },
            {
              title: "Contact Details",
              component: () => <ContactDetails data={data?.contactDetails} controls={setPillIndex} />,
            },
          ]}
        >
          <Modal open={openModal}>
            <Box
              height="100%"
              width="100%"
              display="flex"
              position="relative"
              justifyContent="center"
              alignItems="center"
            >
              <ServicesFileUpload setParsedData={setParsedData} cleanUpFxn={() => setOpenModal(false)} />
            </Box>
          </Modal>
        </Pills>
      </Grid>
    </Grid>
  );
};

export default CreateIpaff;

import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid, Box } from "@mui/material";
import Loader from "react-loader-spinner";
import Panel from "./Panel";
import { a11yPropsPanel } from "../../utils/helpers";

const Pills = ({
  value,
  setValue,
  loading,
  pt = 0,
  pb = 0,
  px = 0,
  disabled = false,
  customOnChange,
  handleChange = (_, v) => {
    setValue(v);
  },
  children,
  elements = [{ title: "shipment", displayCondition: () => true, component: () => <>Hello world</> }],
  genericDisplayCondition = () => true,
}) => {
  useEffect(() => {
    if (loading) {
      setValue(-1);
    }
  }, [loading]);

  return (
    <>
      <Box sx={{ pt, pb, px }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            {/* Tabs  */}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={customOnChange || handleChange}
                variant="scrollable"
                scrollButtons="auto"
                textColor="secondary"
                indicatorColor="primary"
                allowScrollButtonsMobile
                aria-label="edit pills"
                sx={{ backgroundColor: "rgba(3,59,108,.1)" }}
              >
                {elements.map(({ displayCondition, title }, index) => {
                  if ((displayCondition && displayCondition()) || !displayCondition) {
                    return <Tab disabled={disabled} key={index} label={title} {...a11yPropsPanel(index)} />;
                  }
                })}
              </Tabs>
            </Box>
            {/* Generic */}
            {((genericDisplayCondition && genericDisplayCondition()) || !genericDisplayCondition) && (
              <Grid container>
                <Grid item xs={12}>
                  {children}
                </Grid>
              </Grid>
            )}
            {/* Tabs  */}
            <Panel value={value} index={-1}>
              <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item>
                  <Loader type="Grid" color="rgb(3,59,108,0.85)" height={30} width={30} />
                </Grid>
              </Grid>
            </Panel>
            {elements.map(({ displayCondition, component }, index) => {
              if ((displayCondition && displayCondition()) || !displayCondition) {
                return (
                  <Panel key={index} value={value} index={index}>
                    {component()}
                  </Panel>
                );
              }
            })}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Pills;

import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import { styled } from "@mui/material/styles";
import { color, fontSize } from "../../../styles/constants";

const Input = styled("input")({
  display: "none",
});

const ProfilePicture = () => {
  return (
    <Box>
      <CssBaseline />
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box
              component="img"
              src="https://via.placeholder.com/150"
              sx={{
                borderRadius: 50,
                display: "block",
                margin: "0 auto",
              }}
            />
            <Stack
              sx={{
                marginTop: "30px",
                textAlign: "center",
              }}
              direction="row"
              spacing={2}
            >
              <label htmlFor="contained-button-file" style={{ margin: "0 auto" }}>
                <Input accept="image/*" id="contained-button-file" type="file" />
                <Box
                  sx={{
                    color: "#033B6C",
                    fontSize: fontSize.medium,
                    cursor: "pointer",
                    "&:hover": {
                      color: "#033B6C",
                      opacity: 0.9,
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  component="a"
                >
                  Change picture
                </Box>
              </label>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProfilePicture;

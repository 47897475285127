import React, { Fragment, useEffect, useState } from "react";
import { GoogleMap, Polyline, Marker, useJsApiLoader, InfoWindow } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "25rem",
  border: "1px solid #033B6C",
  borderRadius: "1rem",
  overflow: "hidden",
  "&:focusVisible": {
    outline: "none",
  },
};

const Maps = ({ data, parent }) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  // For individual container (Tracking shipment)
  const path = data?.waypoints?.path?.map((location) => {
    return {
      lat: Number(location.latitude.toFixed(3)),
      lng: Number(location.longitude.toFixed(3)),
    };
  });

  const pin = {
    lat: Number(data?.waypoints?.pin?.latitude.toFixed(3)),
    lng: Number(data?.waypoints?.pin?.longitude.toFixed(3)),
  };

  const matchingDestination = path?.find((point) => point.lat === pin.lat && point.lng === pin.lng);

  const defaultPath = {
    first: {
      lat: path?.[0]?.lat,
      lng: path?.[0]?.lng,
    },
    current: {
      ...matchingDestination,
    },
    last: {
      lat: path?.[path?.length - 1]?.lat,
      lng: path?.[path?.length - 1]?.lng,
    },
  };

  const showStartToFinishLocation = [defaultPath.first, defaultPath.last];
  const getCurrentLocation = [defaultPath.first, defaultPath.current];
  const checkIfCurrLocationIsFinishLocation =
    defaultPath.last.lat === defaultPath.current.lat && defaultPath.last.lng === defaultPath.current.lng;

  // Map through different events stages
  const eventStages = ["destinationLand", "destinationPort", "ocean", "originLand", "originPort"];

  const allEventIds =
    data?.containers?.flatMap((item) =>
      eventStages.reduce((ids, property) => ids.concat(item?.[property]?.map((event) => event.location) ?? []), [])
    ) ?? [];

  const shipmentLocations = data?.locations?.filter((location) => allEventIds.includes(location.id));

  const extractedCoordinates = shipmentLocations
    ?.map((location) => {
      if (location.lat !== 0 && location.lng !== 0) {
        return {
          lat: location.lat,
          lng: location.lng,
        };
      }
      return null;
    })
    .filter(Boolean);

  // For dashboard rendering (All shipments)
  const dashboardCoordinates =
    parent === "dashboard" &&
    data
      ?.map((location) => {
        if (location.waypoints !== null && location.waypoints !== null) {
          return {
            lat: location?.waypoints?.pin?.latitude,
            lng: location?.waypoints?.pin?.longitude,
            containerNumber: location?.containerNumber,
            shippingLine: location?.shippingLine,
          };
        }
        return null;
      })
      .filter(Boolean);

  const renderMarkers = (coordinates) => {
    return coordinates?.map((coordinate, idx) => (
      <div key={idx}>
        <Marker
          position={coordinate}
          onMouseOver={() => {
            setIsHovered(true);
            setSelectedMarker(coordinate);
          }}
          onMouseOut={() => {
            setIsHovered(false);
          }}
        />

        {selectedMarker ? (
          <InfoWindow position={selectedMarker}>
            <div className="">
              <h3 className="font-medium pt-2 pb-4 text-center pr-4">
                {selectedMarker?.containerNumber} / {selectedMarker?.shippingLine}
              </h3>
            </div>
          </InfoWindow>
        ) : null}
      </div>
    ));
  };

  useEffect(() => {
    if (!isHovered) {
      setTimeout(() => {
        setSelectedMarker(null);
      }, 300);
    }
  }, [isHovered]);

  if (!isLoaded) {
    return <Fragment></Fragment>;
  }

  return (
    <div className="w-full h-full">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={parent === "dashboard" ? dashboardCoordinates?.[0] : defaultPath.first}
        zoom={2}
      >
        {parent === "dashboard" ? (
          renderMarkers(dashboardCoordinates)
        ) : (
          <>
            {showStartToFinishLocation?.map((coordinate, idx) => (
              <div key={idx}>
                <Marker position={coordinate} />
              </div>
            ))}

            {!checkIfCurrLocationIsFinishLocation &&
              getCurrentLocation?.map((coordinate, idx) => (
                <div key={idx}>
                  <Marker
                    position={coordinate}
                    icon={{
                      url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle.png",
                      fillColor: "#000",
                      fillOpacity: 1,
                      scale: 4,
                      strokeColor: "#000",
                      strokeWeight: 4,
                      strokeOpacity: 1,
                    }}
                  />
                </div>
              ))}

            {/* Stages Events */}
            {extractedCoordinates?.map((coordinate, idx) => (
              <div key={idx}>
                <Marker position={coordinate} />
              </div>
            ))}

            <Polyline
              path={path}
              options={{
                strokeColor: "rgba(3, 59, 108, .5)",
                strokeOpacity: 1,
                strokeWeight: 3,
              }}
            />

            {/* Render Polyline from start to matchingDestination */}
            {matchingDestination && (
              <Polyline
                path={[...path.slice(0, path.indexOf(matchingDestination) + 1)]}
                options={{
                  strokeColor: "#033b6c",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            )}

            {/* Render Polyline from matchingDestination to end */}
            {matchingDestination && (
              <Polyline
                path={[...path.slice(path.indexOf(matchingDestination))]}
                options={{
                  strokeColor: "#fff",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            )}
          </>
        )}
      </GoogleMap>
    </div>
  );
};

export { Maps };

import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { RiSortAsc, RiSortDesc } from "react-icons/ri";

const SortButton = ({
  filterBy,
  sortValue,
  sortOrder,
  changeSortOrder,
  base = [],
  sortFieldName = "",
  filterField = "",
}) => {
  const tmpPos = typeof filterBy == "object" ? filterBy?.findIndex((filter) => filter == sortValue) : 0;

  const SwitchFxn = () => {
    if (
      ((typeof filterBy == "object" && tmpPos > -1) || (typeof filterBy == "string" && sortValue == filterBy)) &&
      ((filterField && filterField == sortFieldName) || !filterField)
    ) {
      if (sortOrder == "asc") {
        return (
          <IconButton
            onClick={() =>
              changeSortOrder(
                typeof filterBy == "object" ? filterBy[tmpPos % filterBy?.length] : filterBy,
                "asc",
                base,
                filterField
              )
            }
            className="opacity-75 hover:opacity-100"
            size="small"
            color="primary"
            sx={{ width: "1.5rem" }}
          >
            <RiSortAsc />
          </IconButton>
        );
      } else {
        return (
          <IconButton
            onClick={() =>
              changeSortOrder(
                typeof filterBy == "object" ? filterBy[(tmpPos + 1) % filterBy?.length] : filterBy,
                "desc",
                base,
                filterField
              )
            }
            className="opacity-75 hover:opacity-100"
            size="small"
            color="primary"
            sx={{ width: "1.5rem" }}
          >
            <RiSortDesc />
          </IconButton>
        );
      }
    } else {
      return (
        <IconButton
          onClick={() =>
            changeSortOrder(typeof filterBy == "object" ? filterBy[0] : filterBy, "desc", base, filterField)
          }
          className="opacity-20 hover:opacity-100"
          size="small"
          sx={{ width: "1.5rem" }}
        >
          <RiSortAsc />
        </IconButton>
      );
    }
  };

  return <SwitchFxn />;
};

export default SortButton;

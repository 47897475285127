import React, { useState } from "react";
import { useFormik } from "formik";
import { Grid, Autocomplete, TextField, Box, Modal, Button, Typography, useTheme } from "@mui/material/";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import mapBg from "../../assets/images/map-bg.svg";
import { useDispatch, useSelector } from "react-redux";
import { setShowNotification } from "../../redux/data/data.actions";
import { shippingOptions } from "../../utils/shippingLine";
import useTracking from "../../hooks/useTracking";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { MdCheck } from "react-icons/md";
import { toast } from "react-toastify";

const analytics = window?.analytics;

const getShippingLine = (shippingLine) => {
  if (!shippingLine) {
    return;
  }
  const sanitizedString = shippingLine.replace(/[^a-zA-Z0-9 ]/g, "");
  const arr = shippingOptions?.map((line) => line?.label);

  for (const str of shippingOptions) {
    if (str?.label?.toLowerCase()?.includes(sanitizedString?.toLowerCase())) {
      return str;
    }
  }

  return null;
};

const trackShipment = async (
  dataArray,
  handleTrackingShipments,
  handleGetShipmentTrackingOperation,
  setStage,
  setPercentCount,
  setTrackProgress,
  toast,
  setCreateDocument
) => {
  let progressData = null;

  setStage(1);
  try {
    const { data } = await handleTrackingShipments(dataArray);
    progressData = data;
    setStage(2);
  } catch (error) {
    setStage(0);
    toast.error("Error initiating tracking");
    return;
  }

  const pollForProgress = async () => {
    try {
      const { data } = await handleGetShipmentTrackingOperation(progressData?.trackShipments?.operationId);

      if (data?.shipmentTrackingOperation?.percentComplete !== 100) {
        setPercentCount(data?.shipmentTrackingOperation?.percentComplete);
        setTimeout(() => pollForProgress(), 2000);
        return;
      }

      setTrackProgress(data?.shipmentTrackingOperation);
    } catch (error) {
      setStage(0);
      toast.error("Error getting tracking result");
    }
  };

  await pollForProgress();

  setCreateDocument();
};

export const ShipmentPdfModal = ({ open, PDFData, setPDFData, setCreateDocument }) => {
  const { handleTrackingShipments, handleGetShipmentTrackingOperation } = useTracking();
  const dispatch = useDispatch();
  const history = useHistory();
  const creditInfo = useSelector((state) => state?.data?.userCredits);
  const [trackProgress, setTrackProgress] = useState(null);
  const [stage, setStage] = useState(0);
  const [percentCount, setPercentCount] = useState(0);
  const theme = useTheme();
  const user = useSelector((state) => state.user.userData);

  const insufficientCreditAction = () => {
    analytics?.track("Insufficient Credits", {
      username: user?.email || "",
      message: "User has insufficient tracking credit",
    });
    dispatch(
      setShowNotification({
        isOpen: true,
        message: [
          "Oops you have insufficient tracking credit.",
          "Upgrade to a higer plan to enjoy more trackings and features.",
        ],
        severity: "warning",
        title: "Insufficient credits",
        actionText: "subscribe",
        action: () => history.push("/dashboard/subscription/subscribe"),
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      billOfLading: PDFData.billOfLading || PDFData.blNumber,
      shippingLine: getShippingLine(PDFData.shippingLine || "") || "",
      containerNumber: PDFData.containerNumber,
    },
    onSubmit: async (values) => {
      if (creditInfo * 1 < values?.containerNumber?.length * 1) {
        return insufficientCreditAction();
      }

      trackShipment(
        values?.containerNumber?.map((containerNumber) => ({
          ...PDFData,
          ...values,
          shippingLine: values?.shippingLine?.value || "",
          containerNumber,
        })),
        handleTrackingShipments,
        handleGetShipmentTrackingOperation,
        setStage,
        setPercentCount,
        setTrackProgress,
        toast,
        setCreateDocument
      );

      analytics?.track("Start Tracking", {
        method: "PDF Upload",
        container: formik.values?.containerNumber,
        BL: formik.values?.billOfLading,
        ShippingLine: formik.values?.shippingLine,
        username: user?.email || "",
      });
    },

    validationSchema: Yup.object({
      billOfLading: Yup.string().required("The Bill of lading is required"),
      shippingLine: Yup.object()
        .shape({
          label: Yup.string().required("The Shipping Line is required"),
          value: Yup.string().required("The Shipping Line is required"),
        })
        .typeError("Please enter a valid shipping line")
        .required("The Shipping Line is required"),
      containerNumber: Yup.array().when("billOfLading", {
        is: (billOfLading) => !billOfLading,
        then: Yup.array().notRequired(),
        otherwise: Yup.array()
          .of(Yup.string().required("The Container number is required"))
          .min(1, "At least one container number is required"),
      }),
    }),
  });

  const { errors, touched, values, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting } = formik;

  // useEffect(() => {
  //   fetchData();

  //   return () => clearTimeout(fetchData);
  // }, [progressData]);

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {stage === 0 ? (
        <Box
          maxWidth="550px"
          width="90%"
          bgcolor="white"
          p={2}
          borderRadius={0.5}
          sx={{
            backgroundImage: "url(" + mapBg + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid onSubmit={handleSubmit} component="form" container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                label="Bill of Lading"
                name="billOfLading"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.billOfLading || ""}
                error={errors?.billOfLading && touched?.billOfLading}
                helperText={errors?.billOfLading && touched?.billOfLading ? errors?.billOfLading : ""}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                size="small"
                name="shippingLine"
                options={shippingOptions || []}
                getOptionLabel={(option) => option?.label || ""}
                value={values?.shippingLine || ""}
                onChange={(_, v) => {
                  setFieldValue("shippingLine", v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Shipping Line"
                    onBlur={handleBlur}
                    error={touched?.shippingLine && errors?.shippingLine}
                    helperText={touched?.shippingLine && errors?.shippingLine}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                size="small"
                name="containerNumber"
                multiple
                ChipProps={{
                  variant: "outlined",
                  color: "primary",
                }}
                options={PDFData?.containerNumber || []}
                value={values?.containerNumber || []}
                onChange={(_, v) => {
                  setFieldValue("containerNumber", v || []);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Container Number"
                    onBlur={handleBlur}
                    error={touched?.containerNumber && errors?.containerNumber}
                    helperText={touched?.containerNumber && errors?.containerNumber}
                  />
                )}
              />
            </Grid>

            <Grid container spacing={1} mt=".5rem">
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() => {
                    setPDFData(null);
                  }}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  Close
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <LoadingButton loading={isSubmitting} type="submit" fullWidth variant="contained" color="primary">
                  Track
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          maxWidth="550px"
          width="90%"
          height="95%"
          bgcolor="white"
          p={2}
          borderRadius={0.5}
          sx={{
            backgroundImage: "url(" + mapBg + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          {!trackProgress ? (
            <Box>
              {stage >= 1 && (
                <Box display="flex" alignItems="center" gap={1}>
                  {stage > 1 ? (
                    <MdCheck size={20} color={theme.palette.success.main} />
                  ) : (
                    <Loader type="ThreeDots" color={theme.palette.primary.main} height={20} width={20} />
                  )}
                  <Typography variant="body1" fontSize={"1rem"}>
                    Initiating tracking
                  </Typography>
                </Box>
              )}

              {stage >= 2 && (
                <Box display="flex" alignItems="center" gap={1} mt="1rem">
                  {stage > 2 ? (
                    <MdCheck size={20} color={theme.palette.success.main} />
                  ) : (
                    <Loader type="ThreeDots" color={theme.palette.primary.main} height={20} width={20} />
                  )}
                  <Typography variant="body1" fontSize={"1rem"}>
                    Getting tracking result...{percentCount}%
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box overflow="scroll !important">
              <Box border="1px solid #033B6C" p="1rem" borderRadius=".5rem">
                <Typography>Success: {trackProgress.numSuccesses}</Typography>
                <Typography>Failure: {trackProgress.numFailures}</Typography>
                <Typography>Percentage: {trackProgress.percentComplete}%</Typography>
                <Typography>Status: {trackProgress.status}</Typography>
              </Box>

              {trackProgress?.items?.map((item, idx) => (
                <Box key={idx} my="1rem" border="1px solid #033B6C" p="1rem" borderRadius=".5rem">
                  <Typography>
                    BL / Container: {item.billOfLading} / {item.containerNumber}
                  </Typography>
                  <Typography>Shipping Line: {item.shippingLine}</Typography>
                  <Typography textTransform="capitalize">Failure: {item.failureReason}</Typography>
                </Box>
              ))}
            </Box>
          )}

          <Grid container spacing={1} mt=".5rem">
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  setPDFData(null);
                  history.push("/dashboard/shipments");
                }}
                fullWidth
                variant="outlined"
                color="primary"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Modal>
  );
};

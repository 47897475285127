import { gql } from "@apollo/client";

const TRACKEDCONTAINER = `
  ageDestinationLand
  ageDestinationPort
  ageOcean
  ageOriginLand
  ageOriginPort
  ciss {
    rate
    value
    currency
  }
  clearingMonth
  cycle
  etls {
    rate
    value
    currency
  }
  levies {
    rate
    value
    currency
  }
  surcharge {
    rate
    value
    currency
  }
  active
  ageing
  assessmentReceptionDate
  baNumber
  bankName
  customsRegistrationNumber
  billOfLadingNumber
    blValue {
    rate
    value
    currency
  }
  clearingAgent
  commodityCode
  commodityDescription
  containerNumber
  containerSize
  containerType
  createStatus
  customsCommand
  customsDutyExcludingVAT {
    rate
    value
    currency
  }
  customsDutyIncludingVAT {
    rate
    value
    currency
  }
  draftDocumentsReceptionDate
  dutyAssessmentNumber
  dutyExchangeRate
  dutyPaymentDate
  dutyRate
  externalReferenceNumber
  facilityName
  fcl
  fclType
  formMApprovalDate
  formMExpiryDate
  formMNumber
  formMValue {
    rate
    value
    currency
  }
  goodsReadyDays
  hscode
  id
  invoiceNumber
  invoiceQuantity
  lastEvent
  lastEventTime
  lastSearchedAt
  lastStage
  nafdacCharges {
    rate
    value
    currency
  }
  originalDocumentsReceptionDate
  paarCopyReceptionDate
  paarSubmissionDate
  quarantineCharges {
    rate
    value
    currency
  }
  sgdDate
  sgdNumber
  shipmentAdviceReceptionDate
  shippingLine
  sonCharges {
    rate
    value
    currency
  }
  status
  storagePaidThroughDate
  terminalID
  trackingId
  transporter
  vat {
    rate
    value
    currency
  }
  billingFulfilled
  customsRegistrationNumber
  manifestNumber
  paarNumber
  destinationCountry
  originCountry
  shipperName
  shipperAddress
  shipperContacts
  consigneeName
  consigneeAddress
  consigneeContacts
  notifyPartyName
  notifyPartyAddress
  deliveryName
  deliveryAddress
  deliveryContacts
  clearingAgentAddress
  transporterAddress
  shippingMode
  shippingTerms
  deliveryTerms
  shippingRemarks
  grossWeight
  netWeight
  sealNumber
  serviceContractNumber
`;

export const TRACK_SHIPMENTS = gql`
  mutation trackShipments($input: [StartTrackingRequest!]!) {
    trackShipments(input: $input) {
      ... on ResponseTrackShipments {
        operationId
      }
    }
  }
`;

export const GET_SHIPMENT_TRACKING_OPERATION = gql`
  query shipmentTrackingOperation($id: Identifier!) {
    shipmentTrackingOperation(id: $id) {
      id
      status
      numSuccesses
      numFailures
      percentComplete
      items {
        containerNumber
        status
        shippingLine
        billOfLading
        failureReason
      }
    }
  }
`;

export const UPDATE_TRACKING = gql`
  mutation updateTracking($filter: UpdateTrackingFilters!, $updateFields: UpdateTrackingFields!) {
    updateTracking(filter: $filter, updateFields: $updateFields) {
      ... on BooleanType {
        value
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_ALL_BILL = gql`
  query getTrackings($page: Page!, $q: TrackingSearchRequest!) {
    getAllTrackings(page: $page, q: $q) {
      ... on TrackingSearchResult {
        items {
          billOfLadingNumber
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_ALL_CONTAINER_ID = gql`
  query getTrackings($page: Page!, $q: TrackingSearchRequest!) {
    getAllTrackings(page: $page, q: $q) {
      ... on TrackingSearchResult {
        items {
          containerNumber
          id
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;
export const GET_ALL_TRACKINGS = gql`
  query getTrackings($page: Page!, $q: TrackingSearchRequest!) {
    getAllTrackings(page: $page, q: $q) {
      ... on TrackingSearchResult {
        items {
          ${TRACKEDCONTAINER}
        }
        pageInfo {
          page
          size
          hasNextPage
          hasPreviousPage
          totalCount
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_SINGLE_TRACKINGS = gql`
  query getTrackings($page: Page!, $q: TrackingSearchRequest!) {
    getAllTrackings(page: $page, q: $q) {
      ... on TrackingSearchResult {
        items {
          ${TRACKEDCONTAINER}
        }
        pageInfo {
          page
          size
          hasNextPage
          hasPreviousPage
          totalCount
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_ALL_DOWNLOADABLE_TRACKINGS = gql`
  query getDownloadableTrackings($q: DownloadableTrackingFilters) {
    getDownloadableTrackings(q: $q) {
      ... on DownloadableTrackings {
        trackings {
          tracking {
            ${TRACKEDCONTAINER}
          }
          leadTime {
            goodsReadyDays
            transitDays
            deliveryDays
            total
          }
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

export const GET_HELPER_DATA = gql`
  query getTrackings($page: Page!, $q: TrackingSearchRequest!) {
    getAllTrackings(page: $page, q: $q) {
      ... on TrackingSearchResult {
        items {
          clearingAgent
          transporter
          commodityDescription
          commodityCode
          customsCommand
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;
export const GET_EFFICIENCY_REPORT = gql`
  query getDownloadableTrackings($q: DownloadableTrackingFilters) {
    getDownloadableTrackings(q: $q) {
      ... on DownloadableTrackings {
        trackings {
          tracking {
            id
            containerNumber
            billOfLadingNumber
            shippingLine
            status
            goodsReadyDays
            containerType
            containerSize
            clearingAgent
            transporter
            commodityDescription
            active
            hscode
            clearingMonth
            ageOriginLand
            ageOriginPort
            ageOcean
            ageDestinationPort
            ageDestinationLand
          }
          leadTime {
            goodsReadyDays
            transitDays
            deliveryDays
            total
          }
        }
      }
      ... on ResponseError {
        message
        code
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, TextField, Button, Box } from "@mui/material";
import {Fieldset} from "../../../../components/v2/form/fieldset";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useService } from "../../../../hooks/useService";
import { useHistory } from "react-router-dom";

export const ContactDetails = ({ data, controls }) => {
  const [counter, setCounter] = useState(0);
  const history = useHistory();
  const { handleUpdateCommonHealthDeclaration } = useService();
  const getCreateCommonHealthDeclarationData = JSON.parse(localStorage.getItem("commonHealthDeclaration"));

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      nominatedContacts: [
        {
          id: counter,
          name: "",
          email: "",
          phone: "",
        },
      ],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, { setSubmitting }) => {
      if (!getCreateCommonHealthDeclarationData) {
        return;
      }
      const { data } = await handleUpdateCommonHealthDeclaration({
        ...getCreateCommonHealthDeclarationData.commonHealthDeclaration,
        contact: {
          name: values.name,
          email: values.email,
          phone: values.phone,
        },
        nominees: values.nominatedContacts.map((item) => {
          return {
            name: item.name,
            email: item.email,
            phone: item.phone,
          };
        }),
      });

      if (data?.updateCommonHealthDeclaration) {
        localStorage.setItem(
          "commonHealthDeclaration",
          JSON.stringify({ commonHealthDeclaration: data?.updateCommonHealthDeclaration })
        );
        setSubmitting(false);
        localStorage.removeItem("commonHealthDeclaration");
        history.push("/dashboard/services/ipaff");
      }
    },
  });

  const { values, touched, errors, setFieldValue, setValues, handleChange, handleSubmit, handleBlur, isSubmitting } =
    formik;

  useEffect(() => {
    if (data && Object?.values(data)?.length) {
      setValues(data);
    }
  }, [data]);

  return (
    <Box mb="5rem">
      <Fieldset fieldsetStyles={{ fontFamily: "monospace" }} legend="Contact Details">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              size="small"
              fullWidth
              name={`name`}
              value={values?.name}
              InputLabelProps={{ shrink: true }}
              error={touched?.name && Boolean(errors?.name)}
              helperText={touched?.name && errors?.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              size="small"
              fullWidth
              type="email"
              name={`email`}
              value={values?.email}
              InputLabelProps={{ shrink: true }}
              error={touched?.email && Boolean(errors?.email)}
              helperText={touched?.email && errors?.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              size="small"
              fullWidth
              type="tel"
              name={`phone`}
              value={values?.phone}
              InputLabelProps={{ shrink: true }}
              error={touched?.phone && Boolean(errors?.phone)}
              helperText={touched?.phone && errors?.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
      </Fieldset>

      <Grid container spacing={2}>
        {values?.nominatedContacts?.map((_, index) => (
          <Grid key={index} item xs={12} sm={6}>
            <Fieldset
              key={index}
              legendStyles={{ fontFamily: "monospace" }}
              // fieldsetStyles={{ margin: 0 }}
              marginBottom="0"
              legend={`Nominee ${`${index + 1}`.padStart(2, "0")}`}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    size="small"
                    fullWidth
                    name={`nominatedContacts[${index}].name`}
                    value={values?.nominatedContacts?.[index]?.name}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.nominatedContacts?.[index]?.name && Boolean(errors?.nominatedContacts?.[index]?.name)
                    }
                    helperText={touched?.nominatedContacts?.[index]?.name && errors?.nominatedContacts?.[index]?.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    size="small"
                    fullWidth
                    type="email"
                    name={`nominatedContacts[${index}].email`}
                    value={values?.nominatedContacts?.[index]?.email}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.nominatedContacts?.[index]?.email && Boolean(errors?.nominatedContacts?.[index]?.email)
                    }
                    helperText={touched?.nominatedContacts?.[index]?.email && errors?.nominatedContacts?.[index]?.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone Number"
                    size="small"
                    fullWidth
                    type="tel"
                    name={`nominatedContacts[${index}].phone`}
                    value={values?.nominatedContacts?.[index]?.phone}
                    InputLabelProps={{ shrink: true }}
                    error={
                      touched?.nominatedContacts?.[index]?.phone && Boolean(errors?.nominatedContacts?.[index]?.phone)
                    }
                    helperText={touched?.nominatedContacts?.[index]?.phone && errors?.nominatedContacts?.[index]?.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      endIcon={<MdDelete />}
                      onClick={() =>
                        values?.nominatedContacts?.length > 1
                          ? setFieldValue(
                              "nominatedContacts",
                              values?.nominatedContacts?.filter((c) => c.id !== values?.nominatedContacts?.[index]?.id)
                            )
                          : toast.error("At least one nominated contact is required")
                      }
                      variant="outlined"
                      color="error"
                    >
                      Delete
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Box display="flex">
            <Button
              onClick={(_) => {
                setFieldValue("nominatedContacts", [
                  ...values.nominatedContacts,
                  {
                    id: counter + 1,
                    email: "",
                    name: "",
                    phone: "",
                  },
                ]);
                setCounter(counter + 1);
              }}
              variant="outlined"
            >
              Nominate
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container xs={12} mt="1rem" gap="1rem">
        <Grid item>
          <Box>
            <Button
              onClick={() => {
                controls(4);
              }}
              type="submit"
              variant="contained"
              sx={{ textAlign: "right" }}
            >
              Previous
            </Button>
          </Box>
        </Grid>

        <Grid item>
          <Box>
            <LoadingButton
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={isSubmitting || !values.name || !values.email}
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save and Submit
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ContactDetails;

import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useService } from "../../../hooks/useService";
import { useParams } from "react-router-dom";
import { findCountry } from "../../../utils/all-countries";
import Loader from "../../../components/Loader/Loader";
import {Fieldset} from "../../../components/v2/form/fieldset";
import { fixPDFDate } from "../../../utils/helpers";
import { MdDownload } from "react-icons/md";

const ViewIpaff = () => {
  const [viewData, setViewData] = useState(null);
  const { id } = useParams();
  const { getSingleCommonHealthDeclarations } = useService();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { getCommonHealthDeclaration: data } = viewData || {};

  useEffect(async () => {
    const { data } = await getSingleCommonHealthDeclarations(id);

    setViewData(data);
  }, [id]);

  return (
    <Box pb="10rem">
      <Box p="1rem" ref={componentRef}>
        <Typography color="primary" variant="h2" fontSize="1.2rem" fontWeight="600">
          Common Health Entry Document UK Import / Export{" "}
        </Typography>
        <Typography color="primary" variant="h2" fontSize="1.2rem" mt="1rem">
          View Notification
        </Typography>

        {!viewData ? (
          <Loader />
        ) : (
          <Box mt="1rem">
            <Box>
              <Fieldset legendStyles={{ fontFamily: "monospace" }} marginBottom="0" legend="Consignment">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} p="1rem">
                    <Typography textTransform="capitalize">
                      <b className="font-semibold">Kind:</b> {data.consignment.kind.replace(/_/g, " ").toLowerCase()}
                    </Typography>
                    <Typography>
                      <b className="font-semibold">Country:</b> {findCountry(data.consignment.country).name}
                    </Typography>
                    <Typography textTransform="capitalize">
                      <b className="font-semibold">Port of Entry:</b> {data.portOfEntry || "N/A"}
                    </Typography>
                    <Typography>
                      <b className="font-semibold">Regulatory:</b> {data.consignment.regulatory ? "Yes" : "No"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} p="1rem">
                    <Typography textTransform="capitalize">
                      <b className="font-semibold">Market Purpose:</b> {data.consignment.marketPurpose || "N/A"}
                    </Typography>
                    <Typography textTransform="capitalize">
                      <b className="font-semibold">Import Reason:</b>{" "}
                      {data.importReason.replace(/_/g, " ").toLowerCase()}
                    </Typography>
                    <Typography>
                      <b className="font-semibold">Movement Ref No:</b>{" "}
                      {data.consignment.movementReferenceNumber || "N/A"}
                    </Typography>
                    <Typography textTransform="capitalize">
                      <b className="font-semibold">Consignment Exit Date:</b> {data.consignmentExitDate || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Fieldset>
            </Box>

            <Box mt="2rem">
              <Fieldset legendStyles={{ fontFamily: "monospace" }} marginBottom="0" legend="Commodities">
                <Grid container spacing={2}>
                  {data?.commodities?.map((item, idx) => (
                    <Grid key={idx} item xs={12} sm={4} p="1rem">
                      <Typography textTransform="capitalize">
                        <b className="font-semibold">Commodity Code:</b> {item?.code}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Net Weight(KG):</b>: {item?.netWeight}KG
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Gross Weight(KG):</b> {item?.grossWeight}KG
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Type of Package:</b> {item?.kind}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Number of Packages:</b> {item?.numPackages}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Typography>
                  <b className="font-semibold">Total Net Weight(KG):</b> {data?.netWeight.toFixed(2)}KG
                </Typography>
                <Typography>
                  <b className="font-semibold">Total Gross Weight(KG):</b> {data?.grossWeight.toFixed(2)}KG
                </Typography>
                <Typography>
                  <b className="font-semibold">Total Packages:</b> {data?.numPackages}
                </Typography>
              </Fieldset>
            </Box>

            <Box mt="2rem">
              <Fieldset legendStyles={{ fontFamily: "monospace" }} marginBottom="0" legend="Documents">
                <Grid container spacing={2}>
                  {data?.documents?.map((item, idx) => (
                    <Grid key={idx} item xs={12} sm={4} p="1rem">
                      <Typography textTransform="capitalize">
                        <b className="font-semibold">Title:</b> {item?.title}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Kind:</b>: {item?.kind}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Issue Date:</b> {fixPDFDate(item?.issueDate)}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">External Reference:</b> {item?.externalReference}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Fieldset>
            </Box>

            <Box mt="2rem">
              <Fieldset legendStyles={{ fontFamily: "monospace" }} marginBottom="0" legend="Establishments">
                <Grid container spacing={2}>
                  {data?.establishments?.map((item, idx) => (
                    <Grid key={idx} item xs={12} sm={4} p="1rem">
                      <Typography textTransform="capitalize">
                        <b className="font-semibold">Name:</b>
                        {item?.name}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Approval Number: </b>
                        {item?.approvalNumber}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Kind:</b> {item?.kind}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Shipment Value:</b> {item?.shipmentValue}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Country:</b> {findCountry(item?.country).name}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Fieldset>
            </Box>

            <Box mt="2rem">
              <Fieldset legendStyles={{ fontFamily: "monospace" }} marginBottom="0" legend="Traders">
                <Grid container spacing={2}>
                  {data?.traders?.map((item, idx) => (
                    <Grid key={idx} item xs={12} sm={4} p="1rem">
                      <Typography textTransform="capitalize">
                        <b className="font-semibold">Name:</b> {item?.name}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Address Line1:</b> {item?.addressLine1}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Address Line2: </b>
                        {item?.addressLine2}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">City: </b>
                        {item?.city}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">ZipCode:</b> {item?.zipCode}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Country:</b> {findCountry(item?.country).name}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Fieldset>
            </Box>

            <Box mt="2rem">
              <Fieldset legendStyles={{ fontFamily: "monospace" }} marginBottom="0" legend="Place of destinations">
                <Grid container spacing={2}>
                  {data?.destinations?.map((item, idx) => (
                    <Grid key={idx} item xs={12} sm={4} p="1rem">
                      <Typography textTransform="capitalize">
                        <b className="font-semibold">Name:</b> {item?.name}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Address Line1:</b> {item?.addressLine1}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Address Line2: </b>
                        {item?.addressLine2}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">City: </b>
                        {item?.city}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">ZipCode:</b> {item?.zipCode}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Country:</b> {findCountry(item?.country).name}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Fieldset>
            </Box>

            <Box mt="2rem">
              <Fieldset
                legendStyles={{ fontFamily: "monospace" }}
                marginBottom="0"
                legend="Transport to the port of entry"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} p="1rem">
                    <Typography textTransform="capitalize">
                      <b className="font-semibold">Containers or Trailers:</b>{" "}
                      {data.shippedByContainerOrRoad ? "Yes" : "No"}
                    </Typography>
                    <Typography>
                      <b className="font-semibold">Means of transport to port:</b> {data.transportMode}
                    </Typography>
                    <Typography textTransform="capitalize">
                      <b className="font-semibold">Port of Entry:</b> {data.portOfEntry || "N/A"}
                    </Typography>
                    <Typography>
                      <b className="font-semibold">Port of Discharge:</b> {data.portOfDischarge}
                    </Typography>
                    <Typography>
                      <b className="font-semibold">useGVMS:</b> {data.useGVMS ? "Yes" : "No"}
                    </Typography>
                  </Grid>
                </Grid>
              </Fieldset>
            </Box>

            <Box mt="2rem">
              <Fieldset legendStyles={{ fontFamily: "monospace" }} marginBottom="0" legend="Transporters">
                <Grid container spacing={2}>
                  {data?.transporters?.map((item, idx) => (
                    <Grid key={idx} item xs={12} sm={4} p="1rem">
                      <Typography textTransform="capitalize">
                        <b className="font-semibold">Name:</b> {item?.name}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Address1: </b>
                        {item?.address1}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Address2: </b>
                        {item?.address2}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">City: </b>
                        {item?.city}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">ZipCode:</b> {item?.zipCode}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Country:</b> {findCountry(item?.country).name}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Fieldset>
            </Box>

            <Box mt="2rem">
              <Fieldset legendStyles={{ fontFamily: "monospace" }} marginBottom="0" legend="Contact">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} p="1rem">
                    <Typography textTransform="capitalize">
                      <b className="font-semibold">Name:</b> {data.contact.name}
                    </Typography>
                    <Typography>
                      <b className="font-semibold">Email</b> {data.contact.email}
                    </Typography>
                    <Typography textTransform="capitalize">
                      <b className="font-semibold">Phone:</b> {data.contact.phone}
                    </Typography>
                  </Grid>
                </Grid>
              </Fieldset>
            </Box>

            <Box mt="2rem">
              <Fieldset legendStyles={{ fontFamily: "monospace" }} marginBottom="0" legend="Nominees">
                <Grid container spacing={2}>
                  {data?.nominees?.map((item, idx) => (
                    <Grid key={idx} item xs={12} sm={4} p="1rem">
                      <Typography textTransform="capitalize">
                        <b className="font-semibold">Name:</b> {item?.name}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Email:</b> {item?.email}
                      </Typography>
                      <Typography>
                        <b className="font-semibold">Phone: </b>
                        {item?.phone}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Fieldset>
            </Box>
          </Box>
        )}
      </Box>

      {viewData && (
        <Box mt="2rem" px="1rem">
          <Button
            startIcon={<MdDownload />}
            onClick={handlePrint}
            type="submit"
            variant="contained"
            sx={{ textAlign: "right" }}
          >
            Download
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ViewIpaff;
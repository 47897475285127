import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ReactComponent as MapBg } from "../../assets/images/map-bg.svg";
import { ReactComponent as Ellipse } from "../../assets/images/blue-ellipse.svg";
import { ReactComponent as Tick } from "../../assets/icons/tick.svg";
import "./modals.scss";

const TrackingStartedClass = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  maxWidth: 450,
  minWidth: 300,
  width: "90%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

export default function TrackingStarted({ open, handleClose, onClickContinue }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={TrackingStartedClass}>
          <div className="tracking-started-container">
            <div className="background">
              <MapBg className="bg-image" />
            </div>
            <div className="tracking-started-wrapper">
              <div className="row-1212">
                <Tick className="tick" />
                <Ellipse />
              </div>
              <div className="row-2212">Tracking Started</div>
              {/* <div className="row-3212">MRKU2054967</div> */}
              <div className="row-4212">
                We will collect all events of your container on your behalf and update your dashboard as soon as
                possible.
              </div>
              <div
              style={{
                margin: "auto"
              }}
                className="pry-btn"
                onClick={() => {
                  onClickContinue();
                }}
              >
                Continue
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

import { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ResponseData {
  message?: string;
}

export const handleAxiosError = async (error: AxiosError) => {
  const { response } = error;

  toast.error(response && (response.data as ResponseData)?.message);

  const handleUnauthorized = () => {
    // localStorage.clear();
  };

  if (response && [401, 403].includes(response.status)) {
    handleUnauthorized();
  }
};

export const onError = (error: AxiosError) => handleAxiosError(error);

import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  Box,
  TableSortLabel,
  Paper,
  IconButton,
  Chip,
} from "@mui/material";
import { MdDownload } from "react-icons/md";
import { cleanUpEnum } from "../../utils/helpers";

const ShipmentDocumentTable = ({
  headCells,
  rows,
  data,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  bl,
}) => {
  if (!rows) {
    return <Fragment></Fragment>;
  }

  return (
    <Box>
      <Paper>
        <Fragment>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell?.align || (headCell.numeric ? "right" : "left")}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      <TableSortLabel>{headCell.label}</TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell align="left">{row.documentName || "N/A"}</TableCell>
                      <TableCell align="left">{cleanUpEnum(row.documentType) || "N/A"}</TableCell>
                      <TableCell align="right">{row.size || "N/A"}</TableCell>
                      <TableCell align="right">
                        <Chip
                          sx={{ borderRadius: 0 }}
                          size="small"
                          variant="outlined"
                          color={
                            `${bl}`.trim()?.toLocaleLowerCase() == `${row.owner}`.trim().toLocaleLowerCase()
                              ? "primary"
                              : "secondary"
                          }
                          label={
                            `${bl}`.trim()?.toLocaleLowerCase() == `${row.owner}`.trim().toLocaleLowerCase()
                              ? "BL"
                              : "COT"
                          }
                        />
                        <span style={{ padding: "0 3px", display: "inline-block" }}>{row.owner}</span>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          href={row.url}
                          download={row.documentType?.replaceAll(/[^a-z0-9.]/gi, "_")}
                          color="primary"
                        >
                          <MdDownload />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.pageInfo?.totalCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Fragment>
      </Paper>
    </Box>
  );
};

export { ShipmentDocumentTable };

import { MdUploadFile } from "react-icons/md";
import { CustomSelect } from "../form/select";
import { IDocumentUploadModal } from "@/types/modal";

export const DocumentUploadModal = ({
  docType,
  setDocType,
  handleAttachmentClick,
  fileInputRef,
  handleFileChange,
}: IDocumentUploadModal) => {
  const options = [
    { value: "", label: "Select an option" },
    { value: "Service Provider Invoice", label: "Service Provider Invoice" },
    { value: "Bill of Lading", label: "Bill of Lading" },
    { value: "Customs Declaration", label: "Customs Declaration" },
    { value: "Packing List", label: "Packing List" },
    { value: "Commercial Invoice", label: "Commercial Invoice" },
    { value: "Delivery Note", label: "Delivery Note" },
  ];

  return (
    <div className="p-4 slide-up border-[.05rem] border-blue-100 rounded-lg text-sm">
      <CustomSelect options={options} value={docType} onChange={(val) => setDocType(val)} label="Select an option" />

      <div className="relative">
        <button
          className={`w-full rounded-lg flex items-center justify-center gap-1 mt-2 bg-blue-100 text-white-100-100 p-2 cursor-pointer ${
            !docType ? "opacity-50" : "opacity-100"
          }`}
          onClick={handleAttachmentClick}
          disabled={!docType}
        >
          <span>
            <MdUploadFile size="1.2rem" />
          </span>
          <p>Upload file</p>
        </button>
        <input type="file" ref={fileInputRef} className="hidden" accept="application/pdf" onChange={handleFileChange} />
      </div>
    </div>
  );
};

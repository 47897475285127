import React from "react";
import { Box, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import Trophy from "../../../assets/icons/trophy-1.svg";
import Star1 from "../../../assets/icons/Group-303.svg";
import { addDate, formatDate } from "../../../utils/helpers";
import { FEATURE_LIST, getDescriptions } from "../../../utils/data";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { MdCheck } from "react-icons/md";

const SubscriptionInfo = ({ subscriptionInfo }) => {
  const theme = useTheme();
  const plan = (subscriptionInfo?.planName || "").toLowerCase().trim();
  const user = useSelector((state) => state.user.userData);
  const radialChartOption = {
    fill: { colors: [theme.palette.primary.main] },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "70%",
        },

        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -5,
            show: true,
            color: "#777",
            fontSize: "13px",
          },
          value: {
            color: "#333",
            fontSize: "1rem",
            show: true,
          },
        },
      },
    },

    stroke: {
      lineCap: "round",
    },
  };
  const getPercent = (used, remaining) =>
    used * 1 + remaining * 1 === 0 ? 100 : ((used * 1) / (used * 1 + remaining * 1)) * 100;
  const featureList =
    plan === "free"
      ? FEATURE_LIST?.free
      : plan === "lite"
      ? [...FEATURE_LIST?.free, ...FEATURE_LIST?.lite.slice(1)]
      : plan === "standard"
      ? [...FEATURE_LIST?.free, ...FEATURE_LIST?.lite.slice(1), ...FEATURE_LIST?.standard.slice(1)]
      : plan === "enterprise"
      ? [
          ...FEATURE_LIST?.free,
          ...FEATURE_LIST?.lite.slice(1),
          ...FEATURE_LIST?.standard.slice(1),
          ...FEATURE_LIST?.enterprise.slice(1),
        ]
      : [];
  return (
    <Box
      border="1px solid"
      borderColor={theme.palette.secondary.light}
      // boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
      px={2}
      py={1.5}
      borderRadius="5px"
      sx={{
        backgroundImage: `linear-gradient(${theme.palette.primary.light}, rgba(255,255,255,0.5)), url(${Trophy})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right top",
      }}
    >
      <Typography color="primary" component={"h2"} lineHeight={2.5} fontSize="1.5rem">
        {subscriptionInfo?.planName} {"Plan"}
      </Typography>

      <Typography lineHeight={1.5} component={"h3"} fontSize=".9rem">
        {getDescriptions(plan, user?.localCurrency)?.split("*")[0]}
        {(getDescriptions(plan, user?.localCurrency)?.split("*")[1] && (
          <>
            <br />
            <b>
              <small>
                <i>*{getDescriptions(plan, user?.localCurrency)?.split("*")[1]}</i>
              </small>
            </b>
          </>
        )) ||
          ""}
      </Typography>

      <Grid mt={1} container justifyContent="center" spacing={1}>
        <Grid item xs={12}>
          <Typography fontSize="1rem" lineHeight={1.5} variant="subtitle2">
            Usage :
          </Typography>
          {/* <Divider sx={{ borderColor: theme.palette.primary.light }} /> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Chart
            height={205}
            type="radialBar"
            options={{
              ...radialChartOption,
              labels: [
                `Tracking : ${subscriptionInfo?.meter?.trackingsUsed}/${
                  subscriptionInfo?.meter?.trackingsUsed * 1 + subscriptionInfo?.meter?.trackingsRemaining * 1
                }`,
              ],
            }}
            series={[getPercent(subscriptionInfo?.meter?.trackingsUsed, subscriptionInfo?.meter?.trackingsRemaining)]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Chart
            height={205}
            type="radialBar"
            options={{
              ...radialChartOption,
              labels: [
                `Overage : ${subscriptionInfo?.meter?.overageUsed}/${
                  subscriptionInfo?.meter?.overageUsed * 1 + subscriptionInfo?.meter?.overageRemaining * 1
                }`,
              ],
            }}
            series={[getPercent(subscriptionInfo?.meter?.overageUsed, subscriptionInfo?.meter?.overageRemaining)]}
          />
        </Grid>
      </Grid>

      <Grid my={1} flexWrap="wrap" container justifyContent="space-evenly" spacing={1}>
        <Grid item>
          <Typography
            display="block"
            variant="subtitle2"
            textAlign="center"
            color="primary.main"
            fontSize=".8rem"
            component="span"
            pl={"1rem"}
            sx={{ display: { xs: "block", lg: "inline-block" } }}
          >
            Starts
          </Typography>{" "}
          <Typography
            display="block"
            variant="subtitle2"
            textAlign="center"
            fontSize=".8rem"
            color="primary.main"
            component="span"
            sx={{ display: { xs: "block", lg: "inline-block" } }}
          >
            {formatDate(subscriptionInfo?.startDate)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            display="block"
            variant="subtitle2"
            textAlign="center"
            fontSize=".8rem"
            color={"error.main"}
            component="span"
            sx={{ display: { xs: "block", lg: "inline-block" } }}
          >
            {"Expires"}
          </Typography>{" "}
          <Typography
            display="block"
            variant="subtitle2"
            color={"error.main"}
            textAlign="center"
            fontSize=".8rem"
            component="span"
            sx={{ display: { xs: "block", lg: "inline-block" } }}
          >
            {formatDate(subscriptionInfo?.expiryDate)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            display="block"
            variant="subtitle2"
            textAlign="center"
            fontSize=".8rem"
            color={"success.main"}
            component="span"
            sx={{ display: { xs: "block", lg: "inline-block" } }}
          >
            {"Renews"}
          </Typography>{" "}
          <Typography
            display="block"
            variant="subtitle2"
            textAlign="center"
            fontSize=".8rem"
            color={"success.main"}
            component="span"
            sx={{ display: { xs: "block", lg: "inline-block" } }}
          >
            {formatDate(subscriptionInfo?.meter?.nextReset)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container my={1.5} spacing={1}>
        <Grid item xs={12}>
          <Typography fontSize="1rem" color="#353935" lineHeight={2} variant="subtitle2">
            This Plan includes
          </Typography>
          <Divider sx={{ borderColor: theme.palette.secondary.light }} />
        </Grid>
        <Grid item xs={12}>
          <List dense>
            <Grid container spacing={1}>
              {featureList?.map((feature, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <ListItem alignItems="center" disablePadding disableRipple disableGutters>
                    <ListItemIcon sx={{ p: 0.01, minWidth: "30px" }}>
                      <MdCheck color={theme.palette.success.main} />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SubscriptionInfo;

export const shippingStyles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    border: "none",
    display: "none",
  }),
  input: (styles) => ({
    ...styles,
    fontSize: "16px",
    color: "#393939",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "16px",
    color: "#b3b3b3",
    padding: 0,
  }),
  control: (styles, state) => ({
    display: "flex",
    alignItems: "center",
    padding: "0",
    lineHeight: 1.25,
    backgroundColor: "white",
    border: "none",
  }),
};

export const shippingOptionsFullName = (abbr) => shippingOptions.find(({ value }) => value == abbr)?.label;

export const shippingOptions = [
  { label: "AC Container Line", value: "ALRB" },
  {
    label: "Admiral Container Lines",
    value: "ADMU",
  },
  { label: "Alianca", value: "ANRM" },
  {
    label: "American President Lines (APL)",
    value: "APLU",
  },
  { label: "Arkas", value: "ARKU" },
  {
    label: "Atlantic Container Line (ACL)",
    value: "ACLU",
  },
  {
    label: "Australia National Line (ANL)",
    value: "ANNU",
  },
  {
    label: "Avana Global FZCO (BALAJI)",
    value: "BLJU",
  },
  { label: "BAL Container Line", value: "BURU" },
  { label: "Bee Logistics Corp", value: "BELC" },
  { label: "BLPL Singapore", value: "BLZU" },
  {
    label: "Blue Anchor America Line",
    value: "BANQ",
  },
  {
    label: "Blue Water Lines (BWL)",
    value: "BWLU",
  },
  { label: "Blue World Line", value: "BWLE" },
  { label: "BMC Line Shipping", value: "BMSU" },
  {
    label: "China Navigation Company (Swire Shipping)",
    value: "CHVW",
  },
  { label: "China United Lines", value: "CULU" },
  { label: "CK Line", value: "CKLU" },
  { label: "CMA CGM", value: "CMDU" },
  {
    label: "CNC (Cheng Lie Navigation)",
    value: "11DX",
  },
  { label: "COSCO", value: "COSU" },
  { label: "Cosiarma S.p.A.", value: "CRAU" },
  { label: "Crowley Maritime", value: "CMCU" },
  { label: "Crowley Maritime", value: "CAMN" },
  { label: "Dachser", value: "DTRA" },
  { label: "Damco", value: "DMCQ" },
  { label: "DB Schenker", value: "SHKK" },
  {
    label: "Deutsche Afrika Linien (DAL)",
    value: "DAYU",
  },
  { label: "Dongjin Shipping", value: "11PG" },
  { label: "Dong Young Shipping", value: "PCSL" },
  { label: "DsvOceanTransport", value: "DSVF" },
  { label: "Econship", value: "ECNU" },
  { label: "ECU Worldwide", value: "ECUW" },
  {
    label: "Emirates Shipping Line",
    value: "ESPU",
  },
  { label: "Emkay Lines", value: "EMKU" },
  {
    label: "Ethiopian Shipping Line",
    value: "ESLU",
  },
  { label: "Eukor", value: "EUKO" },
  { label: "Evergreen", value: "EGLV" },
  { label: "FESCO", value: "FESO" },
  { label: "Gold Star Line", value: "GSLU" },
  {
    label: "Grimaldi Deep Sea S.P.A.",
    value: "GRIU",
  },
  { label: "Hamburg Sud", value: "SUDU" },
  { label: "Hapag-Lloyd", value: "HLCU" },
  {
    label: "Hellmann Worldwide Logistics",
    value: "HIFI",
  },
  { label: "Heung-A Shipping", value: "11QU" },
  { label: "Hillebrand", value: "HGLU" },
  {
    label: "Hyundai Merchant Marine (HMM)",
    value: "HDMU",
  },
  { label: "Ignazio Messina", value: "LMCU" },
  { label: "Indus Container Lines", value: "IDCL" },
  { label: "Interasia Lines", value: "12AT" },
  { label: "JAS Worldwide (Ocean)", value: "JASO" },
  {
    label: "Jin Jiang Shipping (SHJJ)",
    value: "11WJ",
  },
  { label: "Kambara Kisen", value: "KKCL" },
  {
    label: "Kawasaki Kisen Kaisha (K Line)",
    value: "KKLU",
  },
  {
    label: "Korea Marine Transport (KMTC)",
    value: "KMTU",
  },
  { label: "Kuehne + Nagel (KN)", value: "KHNN" },
  { label: "Leschaco", value: "LEHO" },
  { label: "MacAndrews", value: "MCAW" },
  { label: "Maersk Shipping Lines", value: "MAEU" },
  {
    label: "Marguisa Shipping Line",
    value: "MGSU",
  },
  {
    label: "Mariana Express Lines (MELL)",
    value: "MEXU",
  },
  { label: "Maritime Marfret", value: "MFTU" },
  {
    label: "Matson Navigation Company Inc (MATS)",
    value: "MATS",
  },
  {
    label: "Maxicon Container Line (MCL)",
    value: "MXCU",
  },
  {
    label: "Mediterranean Shipping Company (MSC)",
    value: "MSCU",
  },
  { label: "Meratus Line", value: "MRTU" },
  {
    label: "Minsheng Ocean Shipping",
    value: "13CQ",
  },
  { label: "Mitsui O.S.K. Lines", value: "MOLU" },
  { label: "Namsung Shipping", value: "NSRU" },
  {
    label: "Neptune Pacific DirectLine (NPDL)",
    value: "PDLU",
  },
  {
    label: "Nile Dutch Africa Line",
    value: "NIDU",
  },
  {
    label: "Nippon Yusen Kaisha (NYK Line)",
    value: "NYKS",
  },
  { label: "Nirint Shipping", value: "32GH" },
  {
    label: "Ocean Network Express (ONE)",
    value: "ONEY",
  },
  {
    label: "Orient Overseas Container Line (OOCL)",
    value: "OOLU",
  },
  { label: "Orient Star", value: "OSTI" },
  {
    label: "Pacific International Lines (PIL)",
    value: "PCIU",
  },
  { label: "Pan Asia Line", value: "PALU" },
  {
    label: "Pan Continental Shipping",
    value: "15AC",
  },
  { label: "Pan Ocean", value: "POBU" },
  { label: "Pasha Hawaii", value: "PSHI" },
  { label: "Perma", value: "PMLU" },
  {
    label: "Qatar Navigation Lines (QNL)",
    value: "QNLU",
  },
  {
    label: "Regional Container Lines (RCL)",
    value: "REGU",
  },
  { label: "Route Planner", value: "SRRP" },
  { label: "Safmarine", value: "SAFM" },
  {
    label: "Salam Pacific Indonesia Lines (SPIL)",
    value: "SPNU",
  },
  {
    label: "Samudera Shipping Line",
    value: "SIKU",
  },
  {
    label: "Sarjak Container Lines",
    value: "SJKU",
  },
  { label: "Sea Hawk Lines (SHAL)", value: "SHKU" },
  { label: "Sealand", value: "SEJJ" },
  { label: "Sealand", value: "MCCQ" },
  { label: "Sealand", value: "SEAU" },
  { label: "Seino Logix Co", value: "SEIN" },
  { label: "SETH Shipping", value: "SSPH" },
  { label: "Shipco Transport", value: "SHPT" },
  {
    label: "Shipping Corporation of India (SCI)",
    value: "SCIU",
  },
  { label: "Sinokor", value: "SKLU" },
  {
    label: "Sinotrans Container Lines",
    value: "12IH",
  },
  { label: "SITC Container Lines", value: "12PD" },
  {
    label: "SM Line Corporation (SML)",
    value: "SMLM",
  },
  {
    label: "Stolt Tank Containers (STC)",
    value: "SNTU",
  },
  {
    label: "Sunmarine Shipping Services",
    value: "BAXU",
  },
  {
    label: "Taicang Container Lines",
    value: "32GG",
  },
  { label: "Tarros", value: "GETU" },
  { label: "TOTE Maritime", value: "TOTE" },
  {
    label: "Trans Asian Shipping Services",
    value: "TLXU",
  },
  { label: "Transvision", value: "TVSU" },
  { label: "Tropical", value: "TSCW" },
  { label: "T.S. Lines", value: "13DF" },
  { label: "Turkon", value: "TRKU" },
  { label: "Vasco Maritime (VAS)", value: "VMLU" },
  { label: "Wallenius Wilhelmsen", value: "WLWH" },
  { label: "Wan Hai", value: "22AA" },
  {
    label: "W.E.C. (West European Container) Lines",
    value: "WECU",
  },
  {
    label: "Westwood Shipping Lines",
    value: "WWSU",
  },
  { label: "Yang Ming", value: "YMLU" },
  { label: "Yusen Logistics", value: "YASV" },
  { label: "ZIM", value: "ZIMU" },
];

export const getShippingLine = (shippingLine) =>
  shippingOptions?.find((line) => line?.value?.toLowerCase() === shippingLine?.toLowerCase());

export const getShippingLineOptions = (shippingLine) =>
  [{ label: "All Shipping Lines", value: "default" }, ...shippingOptions].find(
    (line) => line?.value?.toLowerCase() === shippingLine?.toLowerCase()
  );

export const setUserData = (userData) => ({
  type: "SET_USER_DATA",
  payload: { userData },
});
export const setPreviousLocation = (previousLocation) => ({
  type: "SET_PREVIOUS_LOCATION",
  payload: { previousLocation },
});

export const setNotifications = (notifications) => ({
  type: "SET_NOTIFICATIONS",
  payload: { notifications },
});

export const setDateAccepted = (dateAccepted) => ({
  type: "SET_ACCEPTED_TERMS_DATE",
  payload: { dateAccepted },
});

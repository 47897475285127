import React from "react";
import { Link } from "react-router-dom";

const FormFooter = ({ linkTitle, linkPath, parag }) => {
  return (
    <div className="justify-center font-medium phone:flex phone:justify-center  text-center phone:text-[1rem] tab:flex tab:justify-center medium:flex medium:w-full mt-2 p-2">
      <span className="mr-2 text-[#033B6C] text-center phone:mr-1">{parag}</span>
      <span className="text-red-400">
        <Link to={linkPath}>{linkTitle}</Link>
      </span>
    </div>
  );
};

export default FormFooter;

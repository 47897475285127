import  React,{useState}  from 'react';
import { TableRow, TableCell,TableContainer, tableCellClasses, Collapse, TableBody, Table, TableHead, Box,  IconButton } from '@mui/material';

import { styled } from "@mui/material/styles";
import {BsChevronDown, BsChevronUp} from "react-icons/bs";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    backgroundColor: theme.palette.common.white,
  },
}));


const CollapsableRow = ({cost,estimate}) =>{
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {estimate?<StyledTableCell align="center" className="table-body" rowSpan={estimate.span}>
                    {estimate?.container}
                    <br/>
                    {`${estimate?.containerSize?estimate?.containerSize:"-"}/${estimate?.containerType?estimate?.containerType:"-"}`}
                  </StyledTableCell>:null }
        <StyledTableCell align="left" className="">
          {cost?.serviceType?cost?.serviceType:"" }
        </StyledTableCell>
        <StyledTableCell align="center" className="">
          {cost?.total?.days?`${cost?.total?.days} days @ ${cost?.currency?cost?.currency:""}${cost?.total?.amount.toLocaleString("en-US")}`:"-"}
        </StyledTableCell>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <BsChevronUp /> : <BsChevronDown />}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow sx={{display:open?"table-row":"none"}}>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="customer Costs">
                <TableHead>
                  <TableRow >
                    <StyledTableCell>Field</StyledTableCell>
                    <StyledTableCell>Value</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell >
                      Provider
                    </StyledTableCell>
                    <StyledTableCell align="left" className="">
                        {cost?.provider?.name?cost?.provider?.name:"" }
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell >
                      Service Type
                    </StyledTableCell>
                    <StyledTableCell align="left" className="">
                      {cost?.serviceType?cost?.serviceType:"" }
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell >
                      Start Event & Date
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{
                        color:cost?.events?.[0]?.date?.toLowerCase() ==="not yet occurred"?"red ":"green"
                      }}>
                    {cost?.events?.[0]?.name?cost?.events?.[0]?.name:"-"} {cost?.events?.[0]?.date?cost?.events?.[0]?.date:"-"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell >
                      End Event & Date
                    </StyledTableCell>
                    <StyledTableCell align="left" className="" sx={{
                        color:cost?.events?.[1]?.date?.toLowerCase() ==="not yet occurred"?"red ":"green"
                      }}>
                    {cost?.events?.[1]?.name?cost?.events?.[1]?.name:"-"} {cost?.events?.[1]?.date?cost?.events?.[1]?.date:"-"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell >
                      FreeTime
                    </StyledTableCell>
                    <StyledTableCell align="left" className="">
                    {cost?.freeTime?.days?`${cost?.freeTime?.days} days x 0 = 0`:"-"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell >
                      Slab 1
                    </StyledTableCell>
                    <StyledTableCell align="left" className="">
                      {cost?.slabs?.[0]?`${cost?.slabs?.[0]?.days?cost?.slabs?.[0]?.days:"-"} days x ${cost?.currency?cost?.currency:""}${cost?.slabs?.[0]?.rate?cost?.slabs?.[0]?.rate?.toLocaleString("en-US"):"-"} = ${cost?.currency?cost?.currency:""}${cost?.slabs?.[0]?.amount?cost?.slabs?.[0]?.amount?.toLocaleString("en-US"):"-"}`:"-"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell >
                      Slab 2
                    </StyledTableCell>
                    <StyledTableCell align="left" className="">
                      {cost?.slabs?.[1]?`${cost?.slabs?.[1]?.days?cost?.slabs?.[1]?.days:"-"} days x ${cost?.slabs?.[1]?.rate?cost?.slabs?.[1]?.rate?.toLocaleString("en-US"):"-"} = ${cost?.currency?cost?.currency:""}${cost?.slabs?.[1]?.amount?cost?.slabs?.[1]?.amount?.toLocaleString("en-US"):"-"}`:"-"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell >
                      Slab 3
                    </StyledTableCell>
                    <StyledTableCell align="left" className="">
                      {cost?.slabs?.[2]?`${cost?.slabs?.[2]?.days?cost?.slabs?.[2]?.days:"-"} days x ${cost?.slabs?.[2]?.rate?cost?.slabs?.[2]?.rate?.toLocaleString("en-US"):"-"} = ${cost?.currency?cost?.currency:""}${cost?.slabs?.[2]?.amount?cost?.slabs?.[2]?.amount?.toLocaleString("en-US"):"-"}`:"-"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell >
                      Slab 4
                    </StyledTableCell>
                    <StyledTableCell align="left" className="">
                      {cost?.slabs?.[3]?`${cost?.slabs?.[3]?.days?cost?.slabs?.[3]?.days:"-"} days x ${cost?.slabs?.[3]?.rate?cost?.slabs?.[3]?.rate?.toLocaleString("en-US"):"-"} = ${cost?.currency?cost?.currency:""}${cost?.slabs?.[3]?.amount?cost?.slabs?.[3]?.amount?.toLocaleString("en-US"):"-"}`:"-"}
                    </StyledTableCell>
                  </TableRow>

                </TableBody>
                <TableHead>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0, } }}>
                    <StyledTableCell >
                      Total
                    </StyledTableCell>
                    <StyledTableCell align="left" className="">
                      {cost?.total?.days?`${cost?.total?.days} days @ ${cost?.currency?cost?.currency:""}${cost?.total?.amount.toLocaleString("en-US")}`:"-"}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export default CollapsableRow;

import axios from "axios";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  UPLOAD_FILE_LINK,
  UPLOAD_CUSTOMS_DOCUMENT_LINK,
  UPLOAD_BL_DOCUMENT_LINK,
  UPLOAD_3SP_DOCUMENT_LINK,
} from "../graphql/files";
import { useDispatch } from "react-redux";
import { setShowNotification } from "../redux/data/data.actions";

export const useFile = () => {
  const dispatch = useDispatch();

  const uploadFile = async (url, file) => {
    const fD = new FormData();
    fD.append(file?.name || "file", file);
    const headers = url?.headers?.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});
    const { data } = await axios.put(url?.signedUrl, fD, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...headers,
      },
    });
    return await data;
  };

  const [getFileLink] = useLazyQuery(UPLOAD_FILE_LINK, {
    onCompleted: (data) => {
      return data?.commercialInvoiceUploadURL;
    },
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });

  const handleGetFileLink = async (
    filename = "",
    contentType = "application/pdf",
    contentLength = 1024,
    extension = "pdf"
  ) => {
    const res = await getFileLink({
      variables: {
        input: {
          filename,
          contentType,
          contentLength,
          extension,
        },
      },
    });
    return res.data;
  };

  const [getCustomsDocumentLink] = useLazyQuery(UPLOAD_CUSTOMS_DOCUMENT_LINK, {
    onCompleted: (data) => {
      return data?.customsDocumentUploadURL;
    },
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });

  const handleGetCustomFileLink = async (file) => {
    let filename = file?.name || "file";
    let contentType = file?.type || "application/pdf";
    let contentLength = file?.size || 1024;
    let extension = file?.name?.split(".")?.length > 1 ? file?.name?.split(".").pop() : "";
    const res = await getCustomsDocumentLink({
      variables: {
        input: {
          filename,
          contentType,
          contentLength,
          extension,
        },
      },
    });
    let linkData = res.data;
    return linkData?.nigerianCustomsUploadURL || null;
  };

  const [getBlDocumentLink] = useLazyQuery(UPLOAD_BL_DOCUMENT_LINK, {
    onCompleted: (data) => {
      return data?.billOfLadingDocumentUploadURL;
    },
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });

  const handleGetBlFileLink = async (file) => {
    let filename = file?.name || "file";
    let contentType = file?.type || "application/pdf";
    let contentLength = file?.size || 1024;
    let extension = file?.name?.split(".")?.length > 1 ? file?.name?.split(".").pop() : "";
    const res = await getBlDocumentLink({
      variables: {
        input: {
          filename,
          contentType,
          contentLength,
          extension,
        },
      },
    });
    let linkData = res.data;
    return linkData?.billOfLadingDocumentUploadURL || null;
  };

  const [get3SPDocumentLink] = useLazyQuery(UPLOAD_3SP_DOCUMENT_LINK, {
    onCompleted: (data) => {
      return data?.serviceProviderInvoiceDocumentUploadURL;
    },
    onError: (error) => {
      dispatch(setShowNotification({ isOpen: true, message: error, type: "error" }));
    },
  });

  const handleGet3SPFileLink = async (file) => {
    let filename = file?.name || "file";
    let contentType = file?.type || "application/pdf";
    let contentLength = file?.size || 1024;
    let extension = file?.name?.split(".")?.length > 1 ? file?.name?.split(".").pop() : "";
    const res = await get3SPDocumentLink({
      variables: {
        input: {
          filename,
          contentType,
          contentLength,
          extension,
        },
      },
    });
    let linkData = res.data;
    return linkData?.serviceProviderInvoiceDocumentUploadURL || null;
  };

  return {
    uploadFile,
    handleGetFileLink,
    handleGetCustomFileLink,
    handleGetBlFileLink,
    handleGet3SPFileLink,
  };
};

import { CustomButton } from "../form/button";

export const ErrorStatus = () => {
  return (
    <div className="fixed left-0 -translate-y-[2%] backdrop-blur-sm bg-white-200 h-screen w-full">
      <div className="absolute w-[90%] translate-y-[40%] md:translate-y-[70%] translate-x-[5.8%] bg-white-100  border border-blue-100 p-4 text-center rounded-lg">
        <h2 className="font-bold text-[2rem]">&#128542;</h2>
        <p className="text-blue-100 font-light">
          Oops!! You’ve reached your messaging limit. To enjoy unlimited access
          and keep the conversation going, please sign up for FREE. We’d love to
          have you!
        </p>

        <div className="mt-4">
          <a
            href={`${process.env.REACT_APP_URL}/create-account?traceyPopup=false`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomButton
              {...{
                bgColor: "bg-blue-100",
                textColor: "text-white-100-100",
                borderColor: "border border-blue-100",
                px: "px-10",
                fontWeight: "font-semibold",
              }}
            >
              Sign Up
            </CustomButton>
          </a>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import Box from "@mui/material/Box";
import { useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { color, fontSize } from "../../../styles/constants";
import { CHANGE_PASSWORD } from "../../../graphql/profile";
import { TextField } from "@mui/material";
import YupPassword from "yup-password";
YupPassword(Yup);

function ChangePassword() {
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().password().required("Required"),
      newPassword: Yup.string().password().required("Required"),
      confirmPassword: Yup.string()
        .password()
        .required("Required")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      handleFormSubmit(values.oldPassword, values.newPassword, values.confirmPassword);
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset } = formik;

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data.changePassword) {
        toast.success("Your password was updated successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        handleReset();
      } else {
        toast.error("Incorrect password!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    },
    onError(error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
  });

  const handleFormSubmit = (oldPassword, newPassword, confirmPassword) => {
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirmation password does not match!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return;
    }

    changePassword({
      variables: { oldPassword, newPassword, confirmPassword },
    });
    // eslint-disable-next-line no-console
  };

  return (
    <Box>
      <Box>
        <Box component="form" className="form-settings" validate onSubmit={handleSubmit}>
          <Grid container py={1} spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                type="password"
                label="Current Password"
                placeholder="Current Password"
                name="oldPassword"
                value={values.oldPassword || ""}
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.oldPassword && errors.oldPassword}
                error={touched.oldPassword && errors.oldPassword}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                type="password"
                label="New Password"
                name="newPassword"
                value={values.newPassword || ""}
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  (touched.newPassword && errors.newPassword) ||
                  "Password should have at least 8 alphaNumeric characters with special characters"
                }
                error={touched.newPassword && errors.newPassword}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                value={values.confirmPassword || ""}
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.confirmPassword && errors.confirmPassword}
                error={touched.confirmPassword && errors.confirmPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                disabled={!values?.newPassword || values?.newPassword !== values?.confirmPassword}
                sx={{
                  borderRadius: "8px",
                  padding: "15px 40px",
                  background: "#033B6C",
                  textAlign: "center",
                  color: "#FFFFFF",
                  display: "block",
                  textTransform: "Capitalize",
                  "&:hover": {
                    backgroundColor: "#033B6C",
                    opacity: 0.9,
                    boxShadow: "none",
                  },
                  "&:disabled": {
                    background: "#93a0ab",
                    color: "#FFFFFF",
                  },
                }}
              >
                {!loading ? "Update" : "Loading..."}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default ChangePassword;

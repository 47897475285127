import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormHeader from "../../components/Form/FormHeader";
import FormInput from "../../components/Form/FormInput";
import Loader from "react-loader-spinner";

// graphql
import { useLazyQuery, useMutation } from "@apollo/client";
import { RESEND_PASSWORD_RESET_EMAIL, RESET_PASSWORD } from "../../graphql/auth";
import SignupImage from "../../assets/images/sign_up_image.png";
import { toast } from "react-toastify";
const analytics = window?.analytics;

const PasswordReset = () => {
  document.title = `aTrace - Visibility - Reset password`;

  const history = useHistory();
  const [userID, setUserID] = useState("");
  const [code, setCode] = useState("");
  const [resetCustomerPassword, { loading }] = useMutation(RESET_PASSWORD, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.resetPassword?.value) {
        analytics?.track("Reset Password", {
          method: "Link",
          message: "Password reset successfully",
          userId: userID,
        });

        toast.success("Password reset successfully. Redirecting you to login ...");

        history.push("/login");
      } else {
        if (data?.resetPassword?.code && data?.resetPassword?.message) {
          analytics?.track("Reset Password", {
            method: "Link",
            message: data?.resetPassword?.message,
            userId: userID,
          });

          toast.error(data?.resetPassword?.message);
        } else {
          analytics?.track("Reset Password", {
            method: "Link",
            message: "Error resetting password",
            userId: userID,
          });

          toast.error("Error resetting password. Please try again");
        }
      }
    },
    onError(error) {
      toast.error(error);
    },
  });

  const resetPasswordHandler = (password) => {
    resetCustomerPassword({
      variables: {
        resetPassRequest: {
          otpCode: code,
          newPassword: password,
          userId: userID,
        },
      },
    });
  };

  const [resendPasswordResetEmail, { loading: isResendingPasswordVerification }] = useLazyQuery(
    RESEND_PASSWORD_RESET_EMAIL,
    {
      errorPolicy: "all",
      onCompleted(data) {
        if (data?.resendEmailVerification?.value) {
          analytics?.track("Reset Password", {
            method: "Email",
            message: "Email sent. Please check your inbox",
            userId: userID,
          });

          toast.success("Email sent. Please check your inbox");
        } else {
          if (data?.resendEmailVerification?.code && data?.resendEmailVerification?.message) {
            analytics?.track("Reset Password", {
              method: "Email",
              message: data?.resendEmailVerification?.message,
              userId: userID,
            });

            toast.error(data?.resendEmailVerification?.message);
          } else {
            analytics?.track("Reset Password", {
              method: "Email",
              message: "Unable to resend email",
              userId: userID,
            });

            toast.error("Unable to resend email. Please try again.");
          }
        }
      },
      onError() {
        analytics?.track("Reset Password", {
          method: "Email",
          message: "We encountered an error while trying to resend the reset password email. Please try again.",
          userId: userID,
        });

        toast.error("We encountered an error while trying to resend the reset password email. Please try again.");
      },
    }
  );

  const resendPasswordEmailHandler = () => {
    resendPasswordResetEmail({
      variables: {
        userID,
      },
    });
  };

  useEffect(() => {
    const fxn = async () => {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const userID = params.get("userID");
      const code = params.get("code");
      setCode(code);
      setUserID(userID);
    };
    fxn();
  }, []);

  const validate = Yup.object({
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm password is required"),
  });

  return (
    <>
      <Formik
        initialValues={{
          password: "",
          repeatPassword: "",
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          resetPasswordHandler(values.password);
        }}
      >
        {(formik) => (
          <div className="auth-page">
            {/* Left Container */}

            <div className="left">
              <div className="form-wrapper">
                {/* Logo container */}
                <FormHeader title={"Password Reset"} paragraph={""} />

                <Form className="form-content">
                  <FormInput label="Password" name="password" type="password" placeholder="Enter your new password" />
                  <FormInput
                    label="Repeat Password"
                    name="repeatPassword"
                    type="password"
                    placeholder="Re-enter your new password"
                  />

                  <button
                    type="submit"
                    className={`form-button create ${!(formik.isValid && formik.dirty) ? "disabled" : ""}`}
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    {!loading ? "Reset Password" : "Resetting new password ..."}
                  </button>
                </Form>

                <div className="w-full flex align-center justify-center">
                  <span className="text-black pr-1">Error resetting password?</span>
                  <span className="text-red-400 cursor-pointer mr-3">
                    <button onClick={() => resendPasswordEmailHandler()} className="bg-transparent">
                      {!isResendingPasswordVerification ? "Resend email" : "Resending email ..."}
                    </button>
                  </span>

                  {isResendingPasswordVerification && <Loader type="Puff" color="#033B6C" height={20} width={20} />}
                </div>
              </div>
            </div>
            {/* Right Container */}
            <div className="right create">
              <div className="top-create">
                <p className="text-1">Get started with tracking your shipments from</p>
                <p className="text-2">anywhere in the world.</p>
              </div>

              <div className="middle-create">
                <img src={SignupImage} alt="packages image" />
              </div>

              <div className="bottom flex justify-center text-[18px]">
                <span className="text-white-100 pr-1">Already have an account?</span>
                <span>
                  <Link to="/login">
                    <div className="text-red-400">Sign In</div>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default PasswordReset;

import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function LimitTags({ values, label, options, onChange }) {
  return (
    <Autocomplete
      multiple
      limitTags={2}
      id="id"
      onChange={onChange}
      options={options}
      getOptionLabel={(option) => option}
      value={[...values]}
      ChipProps={{ color: "primary" }}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder="Containers" variant="standard" disableCloseOnSelect />
      )}
      fullWidth
    />
  );
}

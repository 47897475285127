import React, { useState, useEffect } from "react";
import ServiceManagement from "../Shipments/ServiceManagement";
import useTracking from "../../hooks/useTracking";
import { useSelector } from "react-redux";
import { toTitleCase } from "../../utils/helpers";

const ThreePService = () => {
  const helperData = useSelector((state) => state?.data?.vendors);
  const serverBill = useSelector((state) => state.data.bills);
  const { handleGetBill, handleGetHelperData } = useTracking();
  const [bill, setBill] = useState([]);
  const [clearingAgentOpt, setClearingAgentOpt] = useState([]);
  const [transporterOpt, setTransporterOpt] = useState([]);
  const [loadingBill, setLoadingBill] = useState(false);

  useEffect(() => {
    handleGetBill("all", {});
    handleGetHelperData();
    document.title = "aTrace - Visibility - 3P Services";
  }, []);

  useEffect(() => {
    if (serverBill && serverBill?.length > 0) {
      setBill(serverBill?.filter((item) => item));
    }
  }, [serverBill]);

  useEffect(() => {
    const clearingAgent = new Set();
    const transporter = new Set();
    const commodityDescription = new Set();
    const commodityCode = new Set();
    const customsCommand = new Set();
    if (helperData) {
      helperData?.forEach((helperDatum) => {
        if (helperDatum?.clearingAgent) {
          clearingAgent?.add(helperDatum?.clearingAgent);
        }
        if (helperDatum?.transporter) {
          transporter?.add(helperDatum?.transporter);
        }
        if (helperDatum?.commodityDescription) {
          commodityDescription?.add(toTitleCase(helperDatum?.commodityDescription));
        }
        if (helperDatum?.commodityCode) {
          commodityCode?.add(toTitleCase(helperDatum?.commodityCode));
        }
        if (helperDatum?.customsCommand) {
          customsCommand?.add(toTitleCase(helperDatum?.customsCommand));
        }
      });
      setClearingAgentOpt(Array.from(clearingAgent).sort((a, b) => a.localeCompare(b)));
      setTransporterOpt(Array.from(transporter).sort((a, b) => a.localeCompare(b)));
    }
  }, [helperData]);

  return (
    <ServiceManagement
      loadingBill={loadingBill}
      bills={bill}
      clearingAgentOpt={clearingAgentOpt}
      transporterOpt={transporterOpt}
    />
  );
};

export default ThreePService;

import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
// import { useIntercom } from "react-use-intercom";
import { toast } from "react-toastify";
import {
  GET_NOTIFICATION,
  GET_DASHBOARD_DATA,
  GET_EXCEPTIONS,
  GET_SHIPMENTS_LAST_LOCATIONS,
  COUNT_UNREAD_NOTIFICATIONS,
  MARK_NOTIFICATIONS_AS_READ,
  MARK_SINGLE_NOTIFICATION_AS_READ,
  CREATE_PAYMENT_INTENT,
} from "../graphql/auth";
import { useHistory } from "react-router-dom";
import { GET_USER_CREDITS, GET_CREDIT_INFORMATION } from "../graphql/credits";

import { setShowNotification ,
  setExceptions,
  setDashboardData,
  setShipmentsLastLocations,
  setUnreadNotifications,
  setUserCredits,
  setCreditInfo,
  setPaymentIntent,
} from "../redux/data/data.actions";
import { setNotifications } from "../redux/user/user.actions";

export default function useDashboardInfo() {
  const dispatch = useDispatch();
  // const { hardShutdown } = useIntercom();
  const history = useHistory();

  const logOut = () => {
    // hardShutdown();
    dispatch({ type: "DESTROY_SESSION" });
    localStorage.clear();
    sessionStorage.clear();
    history.push("/login");
  };

  const [getNotifications] = useLazyQuery(GET_NOTIFICATION, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getRecentNotifications?.code && data?.getRecentNotifications?.message) {
        if (data?.getRecentNotifications?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getRecentNotifications?.message, type: "error" }));
      } else {
        dispatch(setNotifications(data?.getRecentNotifications?.result));
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleGetNotifications = (limit) => {
    getNotifications({
      variables: {
        limit,
      },
    });
  };

  const [getDashboardData] = useLazyQuery(GET_DASHBOARD_DATA, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getDashboardData?.code && data?.getDashboardData?.message) {
        if (data?.getDashboardData?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getDashboardData?.message, type: "error" }));
      } else {
        let shipmentsByStage = [];
        let shipmentsByEta = {};
        let onTime = [];
        let late = [];
        let critical = [];

        if (data?.getDashboardData?.shipmentsByStage) {
          for (let i = 0; i < 5; i++) {
            let shipment = data?.getDashboardData?.shipmentsByStage.find((shipment) => shipment.stage == i + 1);

            shipmentsByStage.push(shipment?.shipments || 0);
            onTime[i] = shipment?.onTime || 0;
            late[i] = shipment?.late || 0;
            critical[i] = shipment?.critical || 0;
          }
        }
        shipmentsByEta = { onTime, late, critical };

        dispatch(
          setDashboardData({
            shipmentsByStage,
            shipmentsByEta,
            totalShipments: data?.getDashboardData?.totalShipments,
            etaStatus: [
              data?.getDashboardData?.etaStatus?.onTime,
              data?.getDashboardData?.etaStatus?.late,
              data?.getDashboardData?.etaStatus?.critical,
            ],
          })
        );
      }
    },

    onError(error) {
      console.error(error);
    },
  });
  const handleGetDashboardData = () => {
    getDashboardData({});
  };

  const [getExceptions] = useLazyQuery(GET_EXCEPTIONS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getExceptions?.code && data?.getExceptions?.message) {
        if (data?.getExceptions?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getExceptions?.message, type: "error" }));
        dispatch(setExceptions({ items: [] }));
      } else {
        dispatch(setExceptions(data?.getExceptions));
      }
    },
    onError(error) {
      console.error(error);
      dispatch(setExceptions({ items: [] }));
    },
  });

  const handleGetExceptions = (
    page = {
      number: 1,
      size: 10,
      sortBy: "created_at",
      sortDirectionDesc: true,
    }
  ) => {
    getExceptions({
      variables: {
        page,
      },
    });
  };

  const [getShipmentsLastLocations] = useLazyQuery(GET_SHIPMENTS_LAST_LOCATIONS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getShipmentsLastLocations?.code && data?.getShipmentsLastLocations?.message) {
        if (data?.getShipmentsLastLocations?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.getShipmentsLastLocations?.message, type: "error" })
        );
      } else {
        dispatch(setShipmentsLastLocations(data?.getShipmentsLastLocations?.result));
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleGetShipmentsLastLocations = () => {
    getShipmentsLastLocations({});
  };

  const [getUnreadNotifications] = useLazyQuery(COUNT_UNREAD_NOTIFICATIONS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.countUnreadNotification?.code && data?.countUnreadNotification?.message) {
        if (data?.countUnreadNotification?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.countUnreadNotification?.message, type: "error" }));
      } else {
        dispatch(setUnreadNotifications(data?.countUnreadNotification?.value));
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleGetUnreadNotifications = () => {
    getUnreadNotifications({});
  };

  const [getUserCredits] = useLazyQuery(GET_USER_CREDITS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getAvailableUserCoins?.code && data?.getAvailableUserCoins?.message) {
        if (data?.getAvailableUserCoins?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getAvailableUserCoins?.message, type: "error" }));
      } else {
        dispatch(setUserCredits(data?.subscriptionMe?.meter?.trackingsRemaining || 0));
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleGetUserCredits = () => {
    getUserCredits({});
  };
  const [markNotificationsAsRead] = useMutation(MARK_NOTIFICATIONS_AS_READ, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.markNotificationsAsRead?.code && data?.markNotificationsAsRead?.message) {
        if (data?.markNotificationsAsRead?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.markNotificationsAsRead?.message, type: "error" }));
      } else {
        handleGetNotifications(20);
        handleGetUnreadNotifications();
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleMarkSingleNotificationAsRead = (id) => {
    markSingleNotificationAsRead({
      variables: {
        id,
      },
    });
  };
  const [markSingleNotificationAsRead] = useMutation(MARK_SINGLE_NOTIFICATION_AS_READ, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.markNotificationWithIDAsRead?.code && data?.markNotificationWithIDAsRead?.message) {
        if (data?.markNotificationWithIDAsRead?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.markNotificationWithIDAsRead?.message, type: "error" })
        );
      } else {
        handleGetNotifications(20);
        handleGetUnreadNotifications();
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleMarkNotificationsAsRead = (input = "") => {
    markNotificationsAsRead({
      variables: {
        input,
      },
    });
  };

  const [getUserCreditInformation, { loading }] = useLazyQuery(GET_CREDIT_INFORMATION, {
    errorPolicy: "all",
    onCompleted(data) {
      dispatch(setCreditInfo(data.getCreditInformation));
    },
    onError(error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
  });

  const handleGetUserCreditInformation = () => getUserCreditInformation();

  const [createPaymentIntent] = useMutation(CREATE_PAYMENT_INTENT, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.createPaymentIntent?.code && data?.createPaymentIntent?.message) {
        if (data?.createPaymentIntent?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.createPaymentIntent?.message, type: "error" }));
      } else {
        dispatch(setPaymentIntent(data?.createPaymentIntent));
      }
    },
  });

  const handleCreatePaymentIntent = (PlanCode, Amount, Currency) => {
    createPaymentIntent({
      variables: {
        paymentIntent: {
          PlanCode,
          Amount,
          Currency,
        },
      },
    });
  };

  return {
    logOut,
    handleGetNotifications,
    handleGetDashboardData,
    handleGetExceptions,
    handleGetShipmentsLastLocations,
    handleGetUnreadNotifications,
    handleGetUserCredits,
    handleGetUserCreditInformation,
    handleMarkNotificationsAsRead,
    handleMarkSingleNotificationAsRead,
    handleCreatePaymentIntent,
  };
}

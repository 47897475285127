import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Box, Button, Typography, Autocomplete, TextField, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { MdCheck, MdCloudUpload } from "react-icons/md";
import { useFile } from "../../hooks/useFile";
import Mapbg from "../../assets/images/map-bg.svg";
import "./index.scss";
import { set } from "rsuite/esm/internals/utils/date";

const GenericFileUpload = ({ setParsedData, cleanUpFxn, OPTIONS, inputLabel = "" }) => {
  const [stage, setStage] = useState(0);
  const [uploadedFileTypes, setUploadedFileTypes] = useState();
  const { uploadFile } = useFile();
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const fileTypes = ["PDF"];
  const [fileError, setFileError] = useState("");

  const handleSubmit = async () => {
    setStage(1);

    if (!file) {
      setStage(0);
      toast.error("file is required");
      return;
    }
    const url = uploadedFileTypes?.linkFxn ? await uploadedFileTypes?.linkFxn(file) : "";
    if (!url) {
      setStage(0);
      toast.error("No valid upload URL found.");
    }

    setStage(2);

    try {
      await uploadFile(url, file);
      setStage(3);
    } catch (error) {
      setStage(0);
      return toast.error("Error uploading file");
    }

    try {
      const data = await uploadedFileTypes?.modelFxn(url?.url);

      setParsedData(data, uploadedFileTypes?.additionalData || null, file, url?.url);
      cleanUpFxn();
    } catch (error) {
      setStage(0);
      return toast.error("Error parsing file");
    }

    setStage(0);
  };

  return (
    <Box
      display="flex"
      gap={2}
      width="80%"
      maxWidth={"500px"}
      minHeight={"300px"}
      component="form"
      className="base"
      py={2}
      px={1.5}
      flexDirection="column"
      bgcolor="white"
      sx={{
        backgroundImage: `url(${Mapbg})`,
        backgroundSize: "cover",
        borderRadius: "5px",
      }}
    >
      {stage == 0 ? (
        <>
          <Autocomplete
            options={OPTIONS}
            fullWidth
            onChange={(e, val) => setUploadedFileTypes(val)}
            getOptionLabel={(option) => option?.name || ""}
            value={uploadedFileTypes}
            renderInput={(params) => <TextField {...params} size="small" label={inputLabel || "Select File Type"} />}
          />
          <FileUploader
            classes="drop_area drop_zone"
            dropMessageStyle={{ textAlign: "center" }}
            prompt="Drag and drop a file here or click to select one"
            name="file"
            file=""
            onSizeError={(file) => {
              setFileError("File size is too large, maximum file size is 2MB");
            }}
            onTypeError={(file) => {
              setFileError("Invalid file type, only PDF files are allowed");
            }}
            types={fileTypes}
            handleChange={(file) => {
              setFile(file);
              setFileError("");
            }}
            maxSize={2}
          >
            <Box
              // height="20vh"
              // w="100%"
              // display="flex"
              // flexDirection="column"
              // alignItems="center"
              // justifyContent="center"
              // gap=".5rem"
              // border="2px dashed #033B6C"
              // borderRadius=".5rem"
              // px="2rem"
              // color="#033B6C"
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap=".5rem"
              px="2rem"
              style={{
                cursor: "pointer",
                "&:focus-within": {
                  outline: "none",
                },
              }}
            >
              <span>
                <MdCloudUpload size="3rem" />
              </span>
              <Typography color={fileError ? "error" : "primary"} textAlign="center">
                {" "}
                {fileError
                  ? fileError
                  : file
                  ? "File uploaded successfully. Change upload?"
                  : "Upload or drop a file right here"}
              </Typography>
            </Box>
          </FileUploader>

          <Box width="100%" display="flex" justifyContent="center" alignItems="center" gap={1}>
            <Button fullWidth variant={"outlined"} onClick={cleanUpFxn}>
              Cancel
            </Button>

            <Button disabled={!file || !uploadedFileTypes} fullWidth variant={"contained"} onClick={handleSubmit}>
              Upload
            </Button>
          </Box>
        </>
      ) : (
        <>
          {stage >= 1 && (
            <Box width="100%" display="flex" alignItems="center" gap={1}>
              {stage > 1 ? (
                <MdCheck size={20} color={theme.palette.success.main} />
              ) : (
                <Loader type="ThreeDots" color={theme.palette.primary.main} height={20} width={20} />
              )}
              <Typography variant="body1" fontSize={"1rem"}>
                Generating file link
              </Typography>
            </Box>
          )}
          {stage >= 2 && (
            <Box width="100%" display="flex" alignItems="center" gap={1}>
              {stage > 2 ? (
                <MdCheck size={20} color={theme.palette.success.main} />
              ) : (
                <Loader type="ThreeDots" color={theme.palette.primary.main} height={20} width={20} />
              )}
              <Typography variant="body1" fontSize={"1rem"}>
                Uploading file
              </Typography>
            </Box>
          )}

          {stage >= 3 && (
            <Box width="100%" display="flex" alignItems="center" gap={1}>
              {stage > 3 ? (
                <MdCheck size={20} color={theme.palette.success.main} />
              ) : (
                <Loader type="ThreeDots" color={theme.palette.primary.main} height={20} width={20} />
              )}
              <Typography variant="body1" fontSize={"1rem"}>
                Parsing file
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
export default GenericFileUpload;
export { GenericFileUpload as ShipmentFileUpload };

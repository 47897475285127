import React, { useState, useEffect } from "react";
import "./shipmenttable.scss";
import { formattedNumber, formattedRate, fixDate, formattedMoney, getAmountInLocal } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { Grid, Table, TableBody, TableCell, TablePagination, TableRow, TableHead, TableContainer } from "@mui/material";
const SHOW_LIST_KEYS = {
  "Clearing Agency": "Clearing Agency",
  "Shipping Line": "Shipping Line",
  Terminal: "Terminal",
  Transporter: "Transporter",
  customs: "Customs Charges",
  nafdac: "Nafdac Charges",
  quarantineCharges: "Quarantine Charges",
  etls: "ETLS",
  ciss: "CISS",
  sonCharges: "Son Charges",
  surcharge: "Surcharge",
  Others: "Others",
};
const CostAnalyticsTable = (props) => {
  const localCurrency = useSelector((state) => state.user?.userData?.localCurrency);
  const { data, keys } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showList, setShowList] = useState({});

  const getMoneyValue = (val) => {
    if (typeof val === "object") {
      return 1 * getAmountInLocal(val);
    }
    return 1 * val;
  };

  const isAPermittedKey = (key) => showList[SHOW_LIST_KEYS[key]] || key === "blValue";

  const getRowTotal = (row) => {
    let total = 0;
    for (let key in row) {
      if (key !== "billOfLadingNumber" && isAPermittedKey(key)) {
        total += getMoneyValue(row[key]) || 0;
      }
    }
    return total;
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);

    setPage(0);
  };
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (Array.isArray(keys)) {
      setShowList(keys.reduce((list, curr) => ({ ...list, [curr]: true }), {}));
    }
  }, [keys]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1}></Grid>
        <TableContainer>
          <Table aria-label="Cost Analysis Table">
            <TableHead>
              <TableCell sx={{ verticalAlign: "bottom" }} align="left">
                BL Number
              </TableCell>
              <></>
              <TableCell align="right" sx={{ verticalAlign: "bottom" }}>
                Total
              </TableCell>
              <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                BL Value
              </TableCell>
              {/* {show} */}
              {showList["Clearing Agency"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  Clearing Agency
                </TableCell>
              )}
              {showList["Shipping Line"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  Shipping Line
                </TableCell>
              )}
              {showList["Terminal"] && (
                <TableCell align="right" sx={{ verticalAlign: "bottom" }}>
                  Terminal
                </TableCell>
              )}
              {showList["Transporter"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  Transporter
                </TableCell>
              )}
              {showList["Customs Charges"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  Customs
                </TableCell>
              )}
              {showList["Nafdac Charges"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  Nafdac
                </TableCell>
              )}
              {showList["Quarantine Charges"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  Quarantine
                </TableCell>
              )}
              {showList["ETLS"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  ETLS
                </TableCell>
              )}
              {showList["CISS"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  CISS
                </TableCell>
              )}
              {showList["Son Charges"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  SON
                </TableCell>
              )}
              {showList["Surcharge"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  Surcharge
                </TableCell>
              )}
              {showList["Others"] && (
                <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                  Others
                </TableCell>
              )}
            </TableHead>
            <TableBody>
              {data?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)?.map((row, i) => (
                <>
                  <TableRow sx={{ "& > *": { borderBottom: "unset !important" } }}>
                    <TableCell align="left">{row?.billOfLadingNumber || "N/A"}</TableCell>
                    <TableCell align="right">
                      <b>{formattedNumber(getRowTotal(row))}</b>
                    </TableCell>
                    <TableCell align="right">{`${formattedNumber(getAmountInLocal(row?.blValue)) || "0"}`}</TableCell>
                    {/* {show} */}
                    {showList["Clearing Agency"] && (
                      <TableCell align="right">{`${formattedNumber(row?.["Clearing Agency"]) || "0"}`}</TableCell>
                    )}
                    {showList["Shipping Line"] && (
                      <TableCell align="right">{`${formattedNumber(row?.["Shipping Line"]) || "0"}`}</TableCell>
                    )}
                    {showList["Terminal"] && (
                      <TableCell align="right">{`${formattedNumber(row?.["Terminal"]) || "0"}`}</TableCell>
                    )}
                    {showList["Transporter"] && (
                      <TableCell align="right">{`${formattedNumber(row?.["Transporter"]) || "0"}`}</TableCell>
                    )}
                    {showList["Customs Charges"] && (
                      <TableCell align="right">{`${formattedNumber(getAmountInLocal(row?.customs)) || "0"}`}</TableCell>
                    )}
                    {showList["Nafdac Charges"] && (
                      <TableCell align="right">{`${formattedNumber(getAmountInLocal(row?.nafdac)) || "0"}`}</TableCell>
                    )}
                    {showList["Quarantine Charges"] && (
                      <TableCell align="right">
                        {`${formattedNumber(getAmountInLocal(row?.quarantineCharges)) || "0"}`}
                      </TableCell>
                    )}
                    {showList["ETLS"] && (
                      <TableCell align="right">{`${formattedNumber(getAmountInLocal(row?.etls)) || "0"}`}</TableCell>
                    )}
                    {showList["CISS"] && (
                      <TableCell align="right">{`${formattedNumber(getAmountInLocal(row?.ciss)) || "0"}`}</TableCell>
                    )}
                    {showList["Surcharge"] && (
                      <TableCell align="right">
                        {`${formattedNumber(getAmountInLocal(row?.surcharge)) || "0"}`}
                      </TableCell>
                    )}
                    {showList["Son Charges"] && (
                      <TableCell align="right">
                        {`${formattedNumber(getAmountInLocal(row?.sonCharges)) || "0"}`}
                      </TableCell>
                    )}
                    {showList["Others"] && (
                      <TableCell align="right">{`${formattedNumber(row?.["Others"]) || "0"}`}</TableCell>
                    )}
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="view-more"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default CostAnalyticsTable;

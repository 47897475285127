import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";

import { color, fontWeight } from "../../styles/constants";
import { ProfilePicture, ChangePassword, UpdateProfile } from "./Form";

const Profile = () => {
  return (
    <>
      <Box sx={{ pb: { xs: 10, md: 0 } }}>
        <CssBaseline />
        <Grid container columnSpacing={2} rowSpacing={3} direction={{ xs: "column", md: "row" }}>
          <Grid item sm={12} md={8}>
            <Box
              component="h1"
              py={1}
              sx={{
                color: color.default,
                fontSize: { xs: "19px", md: "25px" },
                fontWeight: fontWeight.regular,
              }}
            >
              Your Profile
            </Box>
            <Box>
              <UpdateProfile />
            </Box>

          </Grid>
          <Grid item sm={12} md={4}>
            <Box
              component="h1"
              pt={2}
              pb={2}
              sx={{
                color: color.default,
                fontSize: { xs: "16px", md: "28.43px" },
                fontWeight: fontWeight.regular,
                textAlign: "center",
              }}
            >
              Profile Picture
            </Box>
            <Box pb={4}>
              <ProfilePicture />
            </Box>
          </Grid>
          <Grid item xs={12}>
          <Box
              component="h1"
              mt={2}
              py={1}
              sx={{
                color: color.default,
                fontSize: { xs: "19px", md: "25px" },
                fontWeight: fontWeight.regular,
              }}
            >
              Update Your Password
            </Box>
            <Box>
              <ChangePassword />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Profile;

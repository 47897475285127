import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import Stack from "@mui/material/Stack";
import { ParseServerDate } from "../../utils/helpers";
import { CgMoreO } from "react-icons/cg";
import { StyledToolTip , PaleStyledTableCell as StyledTableCell } from "../StyledComponents";

const CollapsibleRow = ({ row, buttonPosition = "start", children, cellClass, rowClass }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow className={rowClass} sx={{ "& > *": { borderBottom: "unset !important" } }}>
        {buttonPosition === "start" && (
          <StyledTableCell className={cellClass || ""}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </IconButton>
          </StyledTableCell>
        )}
        {row?.map((col) => {
          {
            if (typeof col !== "object") {
              return (
                <StyledTableCell className={cellClass || ""} align="left">
                  {col}
                </StyledTableCell>
              );
            } else {
              return { ...col };
            }
          }
        })}
        {buttonPosition === "end" && (
          <StyledTableCell className={cellClass || ""}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </IconButton>
          </StyledTableCell>
        )}
      </TableRow>
      <TableRow>
        <StyledTableCell colSpan={row?.length + 1} sx={{ paddingBottom: " 0 !important", paddingTop: " 0 !important" }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0, mx: "auto", px: 1, borderLeft: "1px solid #ddd", borderRight: "1px solid #ddd" }}>
              {children}
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default CollapsibleRow;

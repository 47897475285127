import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Grid, Autocomplete, TextField, Button, Stack } from "@mui/material/";
import { LoadingButton } from "@mui/lab";
import { MdAddHomeWork, MdUpdate, MdDelete } from "react-icons/md";
import * as Yup from "yup";
import { ALL_CURRENCIES, findCompleteCurrency } from "../../../utils/data";
import { formattedNumber, fixMoneyUpload, getAmountInForeign, patchRate } from "../../../utils/helpers";
import { Fieldset } from "../../../components/v2/form/fieldset";
import { countries, findCountry } from "../../../utils/all-countries";
import { BlValue } from "@/components/v2/form/blvalue";

const TRADER_TYPES_MAP = {
  Shipper: {
    name: "shipperName",
    contacts: "shipperContacts",
    address: "shipperAddress",
  },
  Consignee: {
    name: "consigneeName",
    contacts: "consigneeContacts",
    address: "consigneeAddress",
  },
  Recipient: {
    name: "deliveryName",
    contacts: "deliveryContacts",
    address: "deliveryAddress",
  },
  "Notify Party": {
    name: "notifyPartyName",
    address: "notifyPartyAddress",
  },
};

const Shipment = ({
  bill = [],
  updateTracking,
  initialValues,
  setValuesChanged,
  commodityCodeOpt,
  commodityDescriptionOpt,
  setCurrentCurrency,
  getRateOrDefault,
  getCurrencyOrDefault,
  disabled = false,
}) => {
  const {
    billOfLading,
    externalReferenceNumber,
    sealNumber,
    serviceContractNumber,
    destinationCountry,
    originCountry,
    shippingRemarks,
    shippingTerms,
    deliveryTerms,
    fcl,
    fclType,
    goodsReadyDays,
    invoiceNumber,
    invoiceQuantity,
    blValue,
    commodityCode,
    commodityDescription,
    grossWeight,
    netWeight,
    shipperName,
    shipperAddress,
    shipperContacts,
    consigneeName,
    consigneeAddress,
    consigneeContacts,
    notifyPartyName,
    notifyPartyAddress,
    deliveryName,
    deliveryAddress,
    deliveryContacts,
  } = initialValues;

  const localCurrency = useSelector((state) => state?.user?.userData?.localCurrency);
  const formik = useFormik({
    initialValues: {
      billOfLading,
      externalReferenceNumber,
      sealNumber,
      serviceContractNumber,
      destinationCountry: destinationCountry || "",
      originCountry: originCountry || "",
      shippingRemarks,
      shippingTerms,
      deliveryTerms,
      fcl,
      fclType,
      goodsReadyDays,
      invoiceNumber,
      invoiceQuantity,
      blValue: blValue || blValue === 0 ? formattedNumber(getAmountInForeign(blValue)) : "",
      blValueCurrency: getCurrencyOrDefault(blValue, localCurrency || "USD") || localCurrency,
      blValueRate: getRateOrDefault(blValue),
      commodityCode,
      commodityDescription,
      grossWeight,
      netWeight,
      shipperName,
      shipperAddress,
      shipperContacts,
      consigneeName,
      consigneeAddress,
      consigneeContacts,
      notifyPartyName,
      notifyPartyAddress,
      deliveryName,
      deliveryAddress,
      deliveryContacts,
    },
    onSubmit: async (values, { setSubmitting }) => {
      const submitValues = { ...values };
      const { blValueCurrency, blValueRate, blValue } = submitValues;
      delete submitValues.blValueCurrency;
      delete submitValues.blValueRate;

      const result = await updateTracking({
        ...initialValues,
        ...submitValues,
        blValue: {
          value: fixMoneyUpload(blValue),
          currency: blValueCurrency || "",
          rate: patchRate(blValueRate || 0),
        },
      });
      console.log(result);
      if (result) {
        setValuesChanged(false);
      }
      setSubmitting(false);
    },

    validationSchema: Yup.object({
      fcl: Yup.number().typeError("invalid FCL"),
      fclType: Yup.number().typeError("invalid FCL type"),
      externalReferenceNumber: Yup.string(),
      commodityCode: Yup.string(),
      commodityDescription: Yup.string(),
      goodsReadyDays: Yup.number().typeError("invalid goods ready days").nullable(),
    }),
  });

  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    setValues,
  } = formik;

  useEffect(() => {
    const {
      billOfLading,
      externalReferenceNumber,
      sealNumber,
      serviceContractNumber,
      destinationCountry,
      originCountry,
      shippingRemarks,
      shippingTerms,
      deliveryTerms,
      fcl,
      fclType,
      goodsReadyDays,
      invoiceNumber,
      invoiceQuantity,
      blValue,
      commodityCode,
      commodityDescription,
      grossWeight,
      netWeight,
      shipperName,
      shipperAddress,
      shipperContacts,
      consigneeName,
      consigneeAddress,
      consigneeContacts,
      notifyPartyName,
      notifyPartyAddress,
      deliveryName,
      deliveryAddress,
      deliveryContacts,
    } = initialValues;
    setValues({
      billOfLading,
      externalReferenceNumber,
      sealNumber,
      serviceContractNumber,
      destinationCountry,
      originCountry,
      shippingRemarks,
      shippingTerms,
      deliveryTerms,
      fcl,
      fclType,
      goodsReadyDays,
      invoiceNumber,
      invoiceQuantity,
      blValue: blValue || blValue === 0 ? formattedNumber(getAmountInForeign(blValue)) : "",
      blValueCurrency: getCurrencyOrDefault(blValue, localCurrency || "USD") || localCurrency,
      blValueRate: getRateOrDefault(blValue),
      commodityCode,
      commodityDescription,
      grossWeight,
      netWeight,
      shipperName,
      shipperAddress,
      shipperContacts,
      consigneeName,
      consigneeAddress,
      consigneeContacts,
      notifyPartyName,
      notifyPartyAddress,
      deliveryName,
      deliveryAddress,
      deliveryContacts,
    });
  }, [initialValues]);
  const valuesToTradersArray = (values) => {
    const traders = [];
    for (const key in TRADER_TYPES_MAP) {
      let display = false;
      let pointer;
      for (const field in TRADER_TYPES_MAP[key]) {
        pointer = TRADER_TYPES_MAP[key][field];
        display = display || !!values[pointer];
      }
      if (display) {
        traders.push(key);
      }
    }

    return traders;
  };

  const [traders, setTraders] = useState(valuesToTradersArray(values));

  const handleAddTrader = () => {
    const type = Object.keys(TRADER_TYPES_MAP).find((type) => !traders.includes(type));
    const tradersCopy = [...traders, type];
    setTraders(tradersCopy);
  };

  const handleTraderDelete = (type) => {
    const tradersCopy = traders.filter((trader) => trader !== type);
    setTraders(tradersCopy);
    Object.keys(TRADER_TYPES_MAP[type]).forEach((field) => {
      if (TRADER_TYPES_MAP[type][field]) {
        setFieldValue(TRADER_TYPES_MAP[type][field], "");
      }
    });
  };

  const handleTradersChange = (newType, idx) => {
    let oldType = traders[idx];
    const tradersCopy = [...traders];
    tradersCopy[idx] = newType;
    let oldKey;
    let newKey;
    if (!!oldType && !!newType && oldType !== newType) {
      Object.keys(TRADER_TYPES_MAP[oldType]).forEach((field) => {
        newKey = TRADER_TYPES_MAP[newType][field];
        oldKey = TRADER_TYPES_MAP[oldType][field];
        setFieldValue(newKey, values[newKey]);
        setFieldValue(oldKey, "");
      });
    }
    setTraders(tradersCopy);
  };

  const handleChangeCustom = (e, v) => {
    const { name, value } = e.target;
    if (value !== initialValues[name]) {
      setValuesChanged(true);
    }
    handleChange(e);
  };

  return (
    <Grid component={"form"} container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12}>
        <BlValue
          {...{ values, touched, errors, handleChange, setFieldValue, setFieldTouched, localCurrency, disabled }}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          disabled={!!initialValues?.billOfLading}
          getOptionLabel={(option) => option || ""}
          fullWidth
          options={bill || []}
          error={touched.billOfLading && errors.billOfLading}
          helperText={touched.billOfLading && errors.billOfLading ? errors.billOfLading : ""}
          onInputChange={(e, v) => {
            if (v !== values?.billOfLading) {
              setValuesChanged(true);
            }
            setFieldValue("billOfLading", v);
          }}
          onBlur={handleBlur}
          value={values?.billOfLading}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" label="Bill of Lading" name="billOfLading" />
          )}
        />
      </Grid>

      <Grid item lg={4} sm={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="External Reference №"
          name="externalReferenceNumber"
          error={touched.externalReferenceNumber && errors.externalReferenceNumber}
          helperText={
            touched.externalReferenceNumber && errors.externalReferenceNumber ? errors.externalReferenceNumber : ""
          }
          onChange={handleChangeCustom}
          onBlur={handleBlur}
          value={values?.externalReferenceNumber}
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Seal Number"
          name="sealNumber"
          type="text"
          error={touched.sealNumber && errors.sealNumber}
          helperText={touched.sealNumber && errors.sealNumber ? errors.sealNumber : ""}
          onChange={handleChangeCustom}
          onBlur={handleBlur}
          value={values?.sealNumber || ""}
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Service Contract Number"
          name="serviceContractNumber"
          type="text"
          error={touched.serviceContractNumber && errors.serviceContractNumber}
          helperText={touched.serviceContractNumber && errors.serviceContractNumber}
          onChange={handleChangeCustom}
          onBlur={handleBlur}
          value={values?.serviceContractNumber || ""}
        />
      </Grid>

      <Grid item lg={4} sm={6} xs={12}>
        <Autocomplete
          getOptionLabel={(option) => option?.name || ""}
          fullWidth
          options={countries}
          error={touched.originCountry && errors.originCountry}
          helperText={touched.originCountry && errors.originCountry}
          onChange={(_, v) => {
            if (v !== values?.originCountry) {
              setValuesChanged(true);
            }
            setFieldValue("originCountry", v?.code || "");
          }}
          onBlur={handleBlur}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" label={"Origin Country"} name="originCountry" />
          )}
          value={findCountry(values?.originCountry) || ""}
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <Autocomplete
          getOptionLabel={(option) => option?.name || ""}
          fullWidth
          options={countries}
          error={touched.destinationCountry && errors.destinationCountry}
          helperText={touched.destinationCountry && errors.destinationCountry}
          onChange={(_, v) => {
            if (v !== values?.destinationCountry) {
              setValuesChanged(true);
            }
            setFieldValue("destinationCountry", v?.code || "");
          }}
          onBlur={handleBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              label="Destination Country"
              name="destinationCountry"
            />
          )}
          value={findCountry(values?.destinationCountry) || ""}
        />
      </Grid>

      <Grid item lg={4} sm={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Shipping Remarks"
          name="shippingRemarks"
          type="text"
          error={touched.shippingRemarks && errors.shippingRemarks}
          helperText={touched.shippingRemarks && errors.shippingRemarks}
          onChange={handleChangeCustom}
          onBlur={handleBlur}
          value={values?.shippingRemarks || ""}
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Shipping Terms"
          name="shippingTerms"
          type="text"
          error={touched.shippingTerms && errors.shippingTerms}
          helperText={touched.shippingTerms && errors.shippingTerms}
          onChange={handleChangeCustom}
          onBlur={handleBlur}
          value={values?.shippingTerms || ""}
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Delivery Terms"
          name="deliveryTerms"
          type="text"
          error={touched.deliveryTerms && errors.deliveryTerms}
          helperText={touched.deliveryTerms && errors.deliveryTerms}
          onChange={handleChangeCustom}
          onBlur={handleBlur}
          value={values?.deliveryTerms || ""}
        />
      </Grid>

      <Grid item lg={4} sm={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="FCL"
          name="fcl"
          error={touched.fcl && errors.fcl}
          helperText={touched.fcl && errors.fcl ? errors.fcl : ""}
          onChange={handleChangeCustom}
          onBlur={handleBlur}
          value={values?.fcl}
          type="number"
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="FCL Type"
          name="fclType"
          type="number"
          error={touched.fclType && errors.fclType}
          helperText={touched.fclType && errors.fclType ? errors.fclType : ""}
          onChange={handleChangeCustom}
          onBlur={handleBlur}
          value={values?.fclType}
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Goods Ready Days"
          name="goodsReadyDays"
          type="number"
          error={touched.goodsReadyDays && errors.goodsReadyDays}
          helperText={touched.goodsReadyDays && errors.goodsReadyDays ? errors.goodsReadyDays : ""}
          onChange={handleChangeCustom}
          onBlur={handleBlur}
          value={values?.goodsReadyDays || ""}
        />
      </Grid>

      {/* Invoice */}
      <Grid item xs={12}>
        <Fieldset disabled={disabled} legend="Commercial Invoice">
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                onBlur={handleBlur}
                label="Invoice №"
                name="invoiceNumber"
                onChange={handleChangeCustom}
                value={values.invoiceNumber}
                helperText={touched.invoiceNumber && errors.invoiceNumber ? errors.invoiceNumber : ""}
                error={touched.invoiceNumber && errors.invoiceNumber}
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Invoice Qty."
                name="invoiceQuantity"
                type="number"
                onChange={handleChangeCustom}
                onBlur={handleBlur}
                value={values.invoiceQuantity}
                helperText={touched.invoiceQuantity && errors.invoiceQuantity ? errors.invoiceQuantity : ""}
                error={touched.invoiceQuantity && errors.invoiceQuantity}
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Autocomplete
                    disabled
                    value={findCompleteCurrency(values?.blValueCurrency)}
                    fullWidth
                    disableClearable
                    variant="outlined"
                    size="small"
                    name="blValueCurrency"
                    onChange={(_, v) => {
                      setFieldValue("blValueCurrency", v?.value);
                      setCurrentCurrency(v?.value);
                      setFieldValue(
                        "blValueRate",
                        v?.value === localCurrency ? 1 : getRateOrDefault(values?.blValueRate) || 0
                      );
                    }}
                    getOptionLabel={(option) => `${option.value || ""}`}
                    options={ALL_CURRENCIES}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          sx: { ...params.InputProps.sx, borderRadius: "4px 0 0 4px" },
                        }}
                        helperText={touched.blValueCurrency && errors.blValueCurrency ? errors.blValueCurrency : ""}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={9}>
                  <TextField
                    disabled
                    InputProps={{ sx: { borderRadius: "0 4px 4px 0" } }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="blValue"
                    name="blValue"
                    type="text"
                    value={values?.blValue}
                    helperText={touched.blValue && errors.blValue}
                    error={touched.blValue && errors.blValue}
                    onChange={(e) => {
                      handleChangeCustom(e);
                    }}
                    onFocus={(e) => {
                      e.target.type = "number";
                      e.target.value = values?.blValue?.toString()?.replaceAll(",", "");
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      e.target.type = "text";
                      e.target.value = formattedNumber(e.target.value);
                      handleChange(e);
                      handleBlur(e);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fieldset>
      </Grid>

      {/* Commdity */}
      <Grid item xs={12}>
        <Fieldset disabled={disabled} legend="Commodity" marginBottom="0">
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <Autocomplete
                freeSolo
                getOptionLabel={(option) => option}
                fullWidth
                options={commodityCodeOpt}
                error={touched.commodityCode && errors.commodityCode}
                helperText={touched.commodityCode && errors.commodityCode}
                onInputChange={(e, v) => {
                  if (v !== values?.commodityCode) {
                    setValuesChanged(true);
                  }
                  setFieldValue("commodityCode", v);
                }}
                onBlur={handleBlur}
                value={values?.commodityCode}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" label="Commodity Code" name="commodityCode" />
                )}
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Autocomplete
                freeSolo
                getOptionLabel={(option) => option}
                fullWidth
                options={commodityDescriptionOpt}
                error={touched.commodityDescription && errors.commodityDescription}
                helperText={
                  touched.commodityDescription && errors.commodityDescription ? errors.commodityDescription : ""
                }
                onInputChange={(e, v) => {
                  if (v !== values?.commodityDescription) {
                    setValuesChanged(true);
                  }
                  setFieldValue("commodityDescription", v);
                }}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Commodity Description"
                    name="commodityDescription"
                  />
                )}
                value={values?.commodityDescription}
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Gross Weight"
                name="grossWeight"
                type="number"
                error={touched.grossWeight && errors.grossWeight}
                helperText={touched.grossWeight && errors.grossWeight ? errors.grossWeight : ""}
                onChange={handleChangeCustom}
                onBlur={handleBlur}
                value={values?.grossWeight || ""}
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Net Weight"
                name="netWeight"
                type="number"
                error={touched.netWeight && errors.netWeight}
                helperText={touched.netWeight && errors.netWeight ? errors.netWeight : ""}
                onChange={handleChangeCustom}
                onBlur={handleBlur}
                value={values?.netWeight || ""}
              />
            </Grid>
          </Grid>
        </Fieldset>
      </Grid>

      {/* Traders */}
      <Grid item xs={12}>
        <Fieldset disabled={disabled} legend="Traders" marginBottom="0" padding=".5rem">
          <Grid container spacing={1.25} p={0.5}>
            {traders.map((trader, idx) => (
              <Grid key={idx} item xl={4} sm={6} xs={12}>
                <Grid
                  container
                  p={1}
                  spacing={0.75}
                  borderRadius={".25rem"}
                  boxShadow={"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}
                >
                  <Grid item sm={6}>
                    <Autocomplete
                      fullWidth
                      options={Object.keys(TRADER_TYPES_MAP)}
                      getOptionDisabled={(option) => traders.includes(option)}
                      onInputChange={(e, v) => {
                        handleTradersChange(v, idx);
                      }}
                      renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Type" />}
                      value={trader}
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Name"
                      disabled={!TRADER_TYPES_MAP[trader].name}
                      name={TRADER_TYPES_MAP[trader].name}
                      error={touched?.[TRADER_TYPES_MAP[trader].name] && errors?.[TRADER_TYPES_MAP[trader].name]}
                      helperText={touched?.[TRADER_TYPES_MAP[trader].name] && errors?.[TRADER_TYPES_MAP[trader].name]}
                      value={values?.[TRADER_TYPES_MAP[trader].name] || ""}
                      onChange={handleChangeCustom}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Address"
                      multiline
                      rows={3}
                      type="textfield"
                      disabled={!TRADER_TYPES_MAP[trader].address}
                      name={TRADER_TYPES_MAP[trader].address}
                      error={touched?.[TRADER_TYPES_MAP[trader].address] && errors?.[TRADER_TYPES_MAP[trader].address]}
                      helperText={
                        touched?.[TRADER_TYPES_MAP[trader].address] && errors?.[TRADER_TYPES_MAP[trader].address]
                      }
                      onChange={handleChangeCustom}
                      onBlur={handleBlur}
                      value={values?.[TRADER_TYPES_MAP[trader].address] || ""}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Stack gap={1}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Contact"
                        disabled={!TRADER_TYPES_MAP[trader].contacts}
                        name={TRADER_TYPES_MAP[trader].contacts}
                        error={
                          touched?.[TRADER_TYPES_MAP[trader].contacts] && errors?.[TRADER_TYPES_MAP[trader].contacts]
                        }
                        helperText={
                          touched?.[TRADER_TYPES_MAP[trader].contacts] && errors?.[TRADER_TYPES_MAP[trader].contacts]
                        }
                        onChange={handleChangeCustom}
                        onBlur={handleBlur}
                        value={values?.[TRADER_TYPES_MAP[trader].contacts] || ""}
                      />
                      <Button
                        color="error"
                        variant="outlined"
                        onClick={() => handleTraderDelete(trader)}
                        fullWidth
                        endIcon={<MdDelete />}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12}>
              <Button
                color="primary"
                onClick={handleAddTrader}
                disabled={Object.keys(TRADER_TYPES_MAP).length === traders.length}
                startIcon={<MdAddHomeWork />}
                variant="outlined"
              >
                Add Trader
              </Button>
            </Grid>
          </Grid>
        </Fieldset>
      </Grid>

      {/* Submit */}
      <Grid item xs={12}>
        <LoadingButton
          disabled={!initialValues?.containerNumber}
          loading={isSubmitting}
          startIcon={<MdUpdate />}
          loadingPosition="start"
          fullWidth
          variant="contained"
          onClick={handleSubmit}
        >
          Update
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default Shipment;

// All countries
// length 252
export const countries = [
  {"id":1,"name":"Afghanistan","code":"AF"},
  {"id":2,"name":"Aland Islands","code":"AX"},
  {"id":3,"name":"Albania","code":"AL"},
  {"id":4,"name":"Algeria","code":"DZ"},
  {"id":5,"name":"American Samoa","code":"AS"},
  {"id":6,"name":"Andorra","code":"AD"},
  {"id":7,"name":"Angola","code":"AO"},
  {"id":8,"name":"Anguilla","code":"AI"},
  {"id":9,"name":"Antarctica","code":"AQ"},
  {"id":10,"name":"Antigua and Barbuda","code":"AG"},
  {"id":11,"name":"Argentina","code":"AR"},
  {"id":12,"name":"Armenia","code":"AM"},
  {"id":13,"name":"Aruba","code":"AW"},
  {"id":14,"name":"Australia","code":"AU"},
  {"id":15,"name":"Austria","code":"AT"},
  {"id":16,"name":"Azerbaijan","code":"AZ"},
  {"id":17,"name":"Bahamas","code":"BS"},
  {"id":18,"name":"Bahrain","code":"BH"},
  {"id":19,"name":"Bangladesh","code":"BD"},
  {"id":20,"name":"Barbados","code":"BB"},
  {"id":21,"name":"Belarus","code":"BY"},
  {"id":22,"name":"Belgium","code":"BE"},
  {"id":23,"name":"Belize","code":"BZ"},
  {"id":24,"name":"Benin","code":"BJ"},
  {"id":25,"name":"Bermuda","code":"BM"},
  {"id":26,"name":"Bhutan","code":"BT"},
  {"id":27,"name":"Bolivia","code":"BO"},
  {"id":28,"name":"Bonaire, Sint Eustatius and Saba","code":"BQ"},
  {"id":29,"name":"Bosnia and Herzegovina","code":"BA"},
  {"id":30,"name":"Botswana","code":"BW"},
  {"id":31,"name":"Bouvet Island","code":"BV"},
  {"id":32,"name":"Brazil","code":"BR"},
  {"id":33,"name":"British Indian Ocean Territory","code":"IO"},
  {"id":34,"name":"Brunei Darussalam","code":"BN"},
  {"id":35,"name":"Bulgaria","code":"BG"},
  {"id":36,"name":"Burkina Faso","code":"BF"},
  {"id":37,"name":"Burundi","code":"BI"},
  {"id":38,"name":"Cambodia","code":"KH"},
  {"id":39,"name":"Cameroon","code":"CM"},
  {"id":40,"name":"Canada","code":"CA"},
  {"id":41,"name":"Cape Verde","code":"CV"},
  {"id":42,"name":"Cayman Islands","code":"KY"},
  {"id":43,"name":"Central African Republic","code":"CF"},
  {"id":44,"name":"Chad","code":"TD"},
  {"id":45,"name":"Chile","code":"CL"},
  {"id":46,"name":"China","code":"CN"},
  {"id":47,"name":"Christmas Island","code":"CX"},
  {"id":48,"name":"Cocos (Keeling) Islands","code":"CC"},
  {"id":49,"name":"Colombia","code":"CO"},
  {"id":50,"name":"Comoros","code":"KM"},
  {"id":51,"name":"Congo","code":"CG"},
  {"id":52,"name":"Congo, Democratic Republic of the Congo","code":"CD"},
  {"id":53,"name":"Cook Islands","code":"CK"},
  {"id":54,"name":"Costa Rica","code":"CR"},
  {"id":55,"name":"Cote D'Ivoire","code":"CI"},
  {"id":56,"name":"Croatia","code":"HR"},
  {"id":57,"name":"Cuba","code":"CU"},
  {"id":58,"name":"Curacao","code":"CW"},
  {"id":59,"name":"Cyprus","code":"CY"},
  {"id":60,"name":"Czech Republic","code":"CZ"},
  {"id":61,"name":"Denmark","code":"DK"},
  {"id":62,"name":"Djibouti","code":"DJ"},
  {"id":63,"name":"Dominica","code":"DM"},
  {"id":64,"name":"Dominican Republic","code":"DO"},
  {"id":65,"name":"Ecuador","code":"EC"},
  {"id":66,"name":"Egypt","code":"EG"},
  {"id":67,"name":"El Salvador","code":"SV"},
  {"id":68,"name":"Equatorial Guinea","code":"GQ"},
  {"id":69,"name":"Eritrea","code":"ER"},
  {"id":70,"name":"Estonia","code":"EE"},
  {"id":71,"name":"Ethiopia","code":"ET"},
  {"id":72,"name":"Falkland Islands (Malvinas)","code":"FK"},
  {"id":73,"name":"Faroe Islands","code":"FO"},
  {"id":74,"name":"Fiji","code":"FJ"},
  {"id":75,"name":"Finland","code":"FI"},
  {"id":76,"name":"France","code":"FR"},
  {"id":77,"name":"French Guiana","code":"GF"},
  {"id":78,"name":"French Polynesia","code":"PF"},
  {"id":79,"name":"French Southern Territories","code":"TF"},
  {"id":80,"name":"Gabon","code":"GA"},
  {"id":81,"name":"Gambia","code":"GM"},
  {"id":82,"name":"Georgia","code":"GE"},
  {"id":83,"name":"Germany","code":"DE"},
  {"id":84,"name":"Ghana","code":"GH"},
  {"id":85,"name":"Gibraltar","code":"GI"},
  {"id":86,"name":"Greece","code":"GR"},
  {"id":87,"name":"Greenland","code":"GL"},
  {"id":88,"name":"Grenada","code":"GD"},
  {"id":89,"name":"Guadeloupe","code":"GP"},
  {"id":90,"name":"Guam","code":"GU"},
  {"id":91,"name":"Guatemala","code":"GT"},
  {"id":92,"name":"Guernsey","code":"GG"},
  {"id":93,"name":"Guinea","code":"GN"},
  {"id":94,"name":"Guinea-Bissau","code":"GW"},
  {"id":95,"name":"Guyana","code":"GY"},
  {"id":96,"name":"Haiti","code":"HT"},
  {"id":97,"name":"Heard Island and Mcdonald Islands","code":"HM"},
  {"id":98,"name":"Holy See (Vatican City State)","code":"VA"},
  {"id":99,"name":"Honduras","code":"HN"},
  {"id":100,"name":"Hong Kong","code":"HK"},
  {"id":101,"name":"Hungary","code":"HU"},
  {"id":102,"name":"Iceland","code":"IS"},
  {"id":103,"name":"India","code":"IN"},
  {"id":104,"name":"Indonesia","code":"ID"},
  {"id":105,"name":"Iran, Islamic Republic of","code":"IR"},
  {"id":106,"name":"Iraq","code":"IQ"},
  {"id":107,"name":"Ireland","code":"IE"},
  {"id":108,"name":"Isle of Man","code":"IM"},
  {"id":109,"name":"Israel","code":"IL"},
  {"id":110,"name":"Italy","code":"IT"},
  {"id":111,"name":"Jamaica","code":"JM"},
  {"id":112,"name":"Japan","code":"JP"},
  {"id":113,"name":"Jersey","code":"JE"},
  {"id":114,"name":"Jordan","code":"JO"},
  {"id":115,"name":"Kazakhstan","code":"KZ"},
  {"id":116,"name":"Kenya","code":"KE"},
  {"id":117,"name":"Kiribati","code":"KI"},
  {"id":118,"name":"Korea, Democratic People's Republic of","code":"KP"},
  {"id":119,"name":"Korea, Republic of","code":"KR"},
  {"id":120,"name":"Kosovo","code":"XK"},
  {"id":121,"name":"Kuwait","code":"KW"},
  {"id":122,"name":"Kyrgyzstan","code":"KG"},
  {"id":123,"name":"Lao People's Democratic Republic","code":"LA"},
  {"id":124,"name":"Latvia","code":"LV"},
  {"id":125,"name":"Lebanon","code":"LB"},
  {"id":126,"name":"Lesotho","code":"LS"},
  {"id":127,"name":"Liberia","code":"LR"},
  {"id":128,"name":"Libyan Arab Jamahiriya","code":"LY"},
  {"id":129,"name":"Liechtenstein","code":"LI"},
  {"id":130,"name":"Lithuania","code":"LT"},
  {"id":131,"name":"Luxembourg","code":"LU"},
  {"id":132,"name":"Macao","code":"MO"},
  {"id":133,"name":"Macedonia, the Former Yugoslav Republic of","code":"MK"},
  {"id":134,"name":"Madagascar","code":"MG"},
  {"id":135,"name":"Malawi","code":"MW"},
  {"id":136,"name":"Malaysia","code":"MY"},
  {"id":137,"name":"Maldives","code":"MV"},
  {"id":138,"name":"Mali","code":"ML"},
  {"id":139,"name":"Malta","code":"MT"},
  {"id":140,"name":"Marshall Islands","code":"MH"},
  {"id":141,"name":"Martinique","code":"MQ"},
  {"id":142,"name":"Mauritania","code":"MR"},
  {"id":143,"name":"Mauritius","code":"MU"},
  {"id":144,"name":"Mayotte","code":"YT"},
  {"id":145,"name":"Mexico","code":"MX"},
  {"id":146,"name":"Micronesia, Federated States of","code":"FM"},
  {"id":147,"name":"Moldova, Republic of","code":"MD"},
  {"id":148,"name":"Monaco","code":"MC"},
  {"id":149,"name":"Mongolia","code":"MN"},
  {"id":150,"name":"Montenegro","code":"ME"},
  {"id":151,"name":"Montserrat","code":"MS"},
  {"id":152,"name":"Morocco","code":"MA"},
  {"id":153,"name":"Mozambique","code":"MZ"},
  {"id":154,"name":"Myanmar","code":"MM"},
  {"id":155,"name":"Namibia","code":"NA"},
  {"id":156,"name":"Nauru","code":"NR"},
  {"id":157,"name":"Nepal","code":"NP"},
  {"id":158,"name":"Netherlands","code":"NL"},
  {"id":159,"name":"Netherlands Antilles","code":"AN"},
  {"id":160,"name":"New Caledonia","code":"NC"},
  {"id":161,"name":"New Zealand","code":"NZ"},
  {"id":162,"name":"Nicaragua","code":"NI"},
  {"id":163,"name":"Niger","code":"NE"},
  {"id":164,"name":"Nigeria","code":"NG"},
  {"id":165,"name":"Niue","code":"NU"},
  {"id":166,"name":"Norfolk Island","code":"NF"},
  {"id":167,"name":"Northern Mariana Islands","code":"MP"},
  {"id":168,"name":"Norway","code":"NO"},
  {"id":169,"name":"Oman","code":"OM"},
  {"id":170,"name":"Pakistan","code":"PK"},
  {"id":171,"name":"Palau","code":"PW"},
  {"id":172,"name":"Palestinian Territory, Occupied","code":"PS"},
  {"id":173,"name":"Panama","code":"PA"},
  {"id":174,"name":"Papua New Guinea","code":"PG"},
  {"id":175,"name":"Paraguay","code":"PY"},
  {"id":176,"name":"Peru","code":"PE"},
  {"id":177,"name":"Philippines","code":"PH"},
  {"id":178,"name":"Pitcairn","code":"PN"},
  {"id":179,"name":"Poland","code":"PL"},
  {"id":180,"name":"Portugal","code":"PT"},
  {"id":181,"name":"Puerto Rico","code":"PR"},
  {"id":182,"name":"Qatar","code":"QA"},
  {"id":183,"name":"Reunion","code":"RE"},
  {"id":184,"name":"Romania","code":"RO"},
  {"id":185,"name":"Russian Federation","code":"RU"},
  {"id":186,"name":"Rwanda","code":"RW"},
  {"id":187,"name":"Saint Barthelemy","code":"BL"},
  {"id":188,"name":"Saint Helena","code":"SH"},
  {"id":189,"name":"Saint Kitts and Nevis","code":"KN"},
  {"id":190,"name":"Saint Lucia","code":"LC"},
  {"id":191,"name":"Saint Martin","code":"MF"},
  {"id":192,"name":"Saint Pierre and Miquelon","code":"PM"},
  {"id":193,"name":"Saint Vincent and the Grenadines","code":"VC"},
  {"id":194,"name":"Samoa","code":"WS"},
  {"id":195,"name":"San Marino","code":"SM"},
  {"id":196,"name":"Sao Tome and Principe","code":"ST"},
  {"id":197,"name":"Saudi Arabia","code":"SA"},
  {"id":198,"name":"Senegal","code":"SN"},
  {"id":199,"name":"Serbia","code":"RS"},
  {"id":200,"name":"Serbia and Montenegro","code":"CS"},
  {"id":201,"name":"Seychelles","code":"SC"},
  {"id":202,"name":"Sierra Leone","code":"SL"},
  {"id":203,"name":"Singapore","code":"SG"},
  {"id":204,"name":"Sint Maarten","code":"SX"},
  {"id":205,"name":"Slovakia","code":"SK"},
  {"id":206,"name":"Slovenia","code":"SI"},
  {"id":207,"name":"Solomon Islands","code":"SB"},
  {"id":208,"name":"Somalia","code":"SO"},
  {"id":209,"name":"South Africa","code":"ZA"},
  {"id":210,"name":"South Georgia and the South Sandwich Islands","code":"GS"},
  {"id":211,"name":"South Sudan","code":"SS"},
  {"id":212,"name":"Spain","code":"ES"},
  {"id":213,"name":"Sri Lanka","code":"LK"},
  {"id":214,"name":"Sudan","code":"SD"},
  {"id":215,"name":"Suriname","code":"SR"},
  {"id":216,"name":"Svalbard and Jan Mayen","code":"SJ"},
  {"id":217,"name":"Swaziland","code":"SZ"},
  {"id":218,"name":"Sweden","code":"SE"},
  {"id":219,"name":"Switzerland","code":"CH"},
  {"id":220,"name":"Syrian Arab Republic","code":"SY"},
  {"id":221,"name":"Taiwan, Province of China","code":"TW"},
  {"id":222,"name":"Tajikistan","code":"TJ"},
  {"id":223,"name":"Tanzania, United Republic of","code":"TZ"},
  {"id":224,"name":"Thailand","code":"TH"},
  {"id":225,"name":"Timor-Leste","code":"TL"},
  {"id":226,"name":"Togo","code":"TG"},
  {"id":227,"name":"Tokelau","code":"TK"},
  {"id":228,"name":"Tonga","code":"TO"},
  {"id":229,"name":"Trinidad and Tobago","code":"TT"},
  {"id":230,"name":"Tunisia","code":"TN"},
  {"id":231,"name":"Turkey","code":"TR"},
  {"id":232,"name":"Turkmenistan","code":"TM"},
  {"id":233,"name":"Turks and Caicos Islands","code":"TC"},
  {"id":234,"name":"Tuvalu","code":"TV"},
  {"id":235,"name":"Uganda","code":"UG"},
  {"id":236,"name":"Ukraine","code":"UA"},
  {"id":237,"name":"United Arab Emirates","code":"AE"},
  {"id":238,"name":"United Kingdom","code":"GB"},
  {"id":239,"name":"United States","code":"US"},
  {"id":240,"name":"United States Minor Outlying Islands","code":"UM"},
  {"id":241,"name":"Uruguay","code":"UY"},
  {"id":242,"name":"Uzbekistan","code":"UZ"},
  {"id":243,"name":"Vanuatu","code":"VU"},
  {"id":244,"name":"Venezuela","code":"VE"},
  {"id":245,"name":"Viet Nam","code":"VN"},
  {"id":246,"name":"Virgin Islands, British","code":"VG"},
  {"id":247,"name":"Virgin Islands, U.s.","code":"VI"},
  {"id":248,"name":"Wallis and Futuna","code":"WF"},
  {"id":249,"name":"Western Sahara","code":"EH"},
  {"id":250,"name":"Yemen","code":"YE"},
  {"id":251,"name":"Zambia","code":"ZM"},
  {"id":252,"name":"Zimbabwe","code":"ZW"}
];

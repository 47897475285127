const INITIAL_STATE = {
  parsedShipments: [],
  getShipment: null,
  trackedShipments: "loading",
  downloadableTrackedShipments: [],
  costEstimates: "loading",
  showNotification: null,
  trackingStartedModal: false,
  dynamicMapCenter: {},
  dashboardData: null,
  exceptions: null,
  shipmentsLastLocations: [],
  unreadNotifications: null,
  userCredits: 0,
  creditInfo: null,
  subscriptionInfo: null,
  bills: [],
  containers: [],
  managedVendors: "loading",
  providers: [],
  formMData: [],
  ETAStatusPreferences: "loading",
  tablePreference: localStorage.getItem("tablePreference") || 10,
  tradeTerms: "loading",
  paymentIntent: "loading",
  blInvoices: null,
  allUserInvoices: null,
  allUserDownloadInvoices: null,
  reports: {
    efficiency: null,
  },
  shipmentWaypoints: null,
  resetTraceyChatHistory: false
};

const dataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_PARSED_SHIPMENTS":
      return {
        ...state,
        parsedShipments: action.payload.parsedShipments,
      };
    case "SET_GET_SHIPMENT":
      return {
        ...state,
        getShipment: action.payload.getShipment,
      };
    case "SET_SEARCH_DATA":
      return {
        ...state,
        searchData: action.payload.searchData,
      };
    case "SET_TRACKED_SHIPMENTS":
      return {
        ...state,
        trackedShipments: action.payload.trackedShipments,
      };
    case "DELETE_TRACKED_SHIPMENT":
      return {
        ...state,
        trackedShipments: state?.trackedShipments
          ? state?.trackedShipments?.items?.length
            ? {
                ...state.trackedShipments,
                items: state.trackedShipments?.items?.filter(
                  (shipment) => shipment?.trackingId !== action?.payload?.trackedShipmentId
                ),
              }
            : state.trackedShipments
          : state?.trackedShipments,
      };
    case "SET_TRACKED_DOWNLOADABLE_SHIPMENTS":
      return {
        ...state,
        downloadableTrackedShipments: action.payload.downloadableTrackedShipments,
      };
    case "SET_EFFICIENCY_REPORT_DATA":
      return {
        ...state,
        reports: {
          ...state.report,
          efficiency:
            action.payload.efficiencyReport !== "loading" ? action.payload.efficiencyReport?.trackings : "loading",
        },
      };
    case "SET_MANAGED_VENDORS":
      return {
        ...state,
        managedVendors: action.payload.managedVendors,
      };
    case "SET_ALL_BILL":
      return {
        ...state,
        bills: [...new Set(action.payload.bills?.sort((a, b) => a.localeCompare(b)))],
      };
    case "SET_ALL_VENDORS":
      return {
        ...state,
        vendors: action.payload.vendors,
      };
    case "SET_ALL_CONTAINERS":
      return {
        ...state,
        containers: [...new Set(action.payload.containers?.sort((a, b) => a.localeCompare(b)))],
      };
    case "SET_TRADE_TERMS":
      return {
        ...state,
        tradeTerms: action.payload.tradeTerm,
      };
    case "SET_COST_ESTIMATES":
      return {
        ...state,
        costEstimates: action.payload.costEstimates,
      };
    case "ADD_TRADE_TERMS":
      return {
        ...state,
        tradeTerms: [...state.tradeTerms, ...action.payload.tradeTerm],
      };
    case "SET_ALL_SERVICE_PROVIDERS":
      return {
        ...state,
        providers: action.payload.providers,
      };
    case "SET_SHOW_NOTIFICATION":
      return {
        ...state,
        showNotification: action.payload.showNotification,
      };
    case "SET_TRACKING_STARTED_MODAL":
      return {
        ...state,
        trackingStartedModal: action.payload.trackingStartedModal,
      };
    case "SET_DYNAMIC_MAP_CENTER":
      return {
        ...state,
        dynamicMapCenter: action.payload.dynamicMapCenter,
      };
    case "SET_DASHBOARD_DATA":
      return {
        ...state,
        dashboardData: action.payload.dashboardData,
      };
    case "SET_EXCEPTIONS":
      return {
        ...state,
        exceptions: action.payload.exceptions,
      };
    case "SET_SHIPMENTS_LAST_LOCATIONS":
      return {
        ...state,
        shipmentsLastLocations: action.payload.shipmentsLastLocations,
      };
    case "SET_UNREAD_NOTIFICATIONS":
      return {
        ...state,
        unreadNotifications: action.payload.unreadNotifications,
      };
    case "SET_USER_CREDITS":
      return {
        ...state,
        userCredits: action.payload.userCredits,
      };
    case "SET_CREDITS_INFO":
      return {
        ...state,
        creditInfo: action.payload.creditInfo,
      };
    case "SET_SUBSCRIPTION_INFO":
      return {
        ...state,
        subscriptionInfo: action.payload.subscriptionInfo,
      };
    case "SET_TABLE_PREFERENCE": {
      localStorage.setItem("tablePreference", action.payload.tablePreference);
      return {
        ...state,
        tablePreference: action.payload.tablePreference,
      };
    }
    case "SET_ETA_STATUS_PREFERENCES":
      return {
        ...state,
        ETAStatusPreferences: action.payload.ETAStatusPreferences,
      };
    case "SET_PAYMENT_INTENT":
      return {
        ...state,
        paymentIntent: action.payload.paymentIntent,
      };
    case "SET_ALL_FORM_M":
      return {
        ...state,
        formMData: action.payload.getFormMData,
      };
    case "SET_BL_INVOICES":
      return {
        ...state,
        blInvoices: action.payload.getAllUserInvoices,
      };
    case "SET_USER_INVOICES":
      return {
        ...state,
        allUserInvoices: action.payload.getAllUserInvoices,
      };
    case "SET_USER_DOWNLOAD_INVOICES":
      return {
        ...state,
        allUserDownloadInvoices: action.payload.getAllUserInvoices,
      };
    case "SET_SHIPMENT_WAYPOINTS":
      return {
        ...state,
        shipmentWaypoints: action.payload.shipmentWaypoints,
      };
      case "SET_RESET_TRACEY_CHAT_HISTORY":
        return {
          ...state,
          resetTraceyChatHistory: action.payload.resetTraceyChatHistory,
        };
    default:
      return state;
  }
};

export default dataReducer;

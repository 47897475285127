import React from "react";
import { MdViewColumn } from "react-icons/md";
import { Grid, Popover, Button, Card, CardContent, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

const ColumnFilter = ({ preferences, setPreferences, buttonTitle = "columns" }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setColumnPreference = (column, show) => {
    let newPreference = [...preferences];
    newPreference[column] = { ...preferences[column], show: show || false };
    setPreferences([...newPreference]);
  };
  return (
    <>
      <Button
        aria-describedby={`table ${buttonTitle} filter"`}
        variant="outlined"
        onClick={handleClick}
        startIcon={<MdViewColumn color="#033b6c" />}
        sx={{ color: "#033b6c", [":hover"]: { color: "#033b9e", borderColor: "#033b9e" }, borderColor: "#033b6c" }}
      >
        {buttonTitle}
      </Button>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            borderColor: "#033b6c",
            borderWidth: "2px",
            "&:after": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 45,
              left: -6,
              width: 12,
              height: 12,
              bgcolor: "#393939",
              borderWidth: "2px",
              borderColor: "#033b6c",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 800,
            },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card className="styled-scrollbars" sx={{ minWidth: 250, maxWidth: 250, maxHeight: 450, overflowY: "scroll" }}>
          <CardContent>
            <Grid container>
              {preferences.map((key, index) => (
                <Grid item key={`${index}oo1`} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!key.editable}
                          onClick={(e) => setColumnPreference(index, e.target.checked)}
                          checked={key?.show}
                        />
                      }
                      label={`${key?.title || ""} ${(key?.fullName && `(${key?.fullName})`) || ""}`}
                    />
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export default ColumnFilter;

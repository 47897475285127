import React, { useEffect, useState } from "react";
import { Typography, Grid, Button, Box } from "@mui/material";
import { Commodities, Consignment, ContactDetails, Documents, Traders, Transport } from "./forms";
import { findCountry, fuzzyFindCountryCode } from "../../../utils/all-countries";
import Pills from "../../../components/Pills";
import { useService } from "../../../hooks/useService";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { MdDashboard } from "react-icons/md";

const EditIpaff = () => {
  const [pillIndex, setPillIndex] = useState(0);
  const [data, setData] = useState();
  const { id } = useParams();
  const { getSingleCommonHealthDeclarations } = useService();
  const history = useHistory();

  useEffect(async () => {
    const { data } = await getSingleCommonHealthDeclarations(id);

    if (!data) {
      return;
    }

    const { getCommonHealthDeclaration: fieldsData } = data;

    const consignments = {
      requiresRegulatoryRequirement: "Yes",
      provideBCPDetails: "No",
      internalReferenceNumber: fieldsData?.consignment?.referenceNo,
      consignmentName: fieldsData?.consignment?.kind?.replace(/_/g, " "),
      consignmentOrigin: fuzzyFindCountryCode(findCountry(fieldsData?.consignment?.country)?.name),
      requiresRegionOrigin: "Yes",
      consignedOrigin: fuzzyFindCountryCode(findCountry(fieldsData?.consignment?.country)?.name),
      importReason: fieldsData.importReason?.replace(/_/g, " "),
      marketPurpose: fieldsData?.marketPurpose,
      movementReferenceNumber: fieldsData?.movementReferenceNumber,
      destinationCountry: "",
      exictBCP: "",
      consigmentExitTime: "",
      transitedCountries: [],
    };

    const commodities = {
      commodities: fieldsData?.commodities?.map((item, idx) => {
        return {
          id: `prefiled-${idx + 1}`,
          commodityCode: item?.code,
          netWeight: item?.netWeight,
          grossWeight: item?.grossWeight,
          numberOfPackages: item?.numPackages,
          typeOfPackage: item?.packageKind,
          temperature: item?.temperature,
        };
      }),

      totalNetWeight: fieldsData?.commodities?.reduce((acc, curr) => acc + Number(curr?.netWeight || 0), 0).toFixed(2),
      totalPackages: fieldsData?.commodities?.reduce((acc, curr) => acc + Number(curr?.numPackages || 0), 0),
      totalGrossWeight: fieldsData?.commodities
        ?.reduce((acc, curr) => acc + Number(curr?.grossWeight || 0), 0)
        .toFixed(2),
    };

    const documents = {
      documents: fieldsData?.documents?.map((item, idx) => {
        return {
          id: `prefiled-${idx + 1}`,
          type: item?.kind,
          reference: item?.externalReference,
          attachment: "",
          issueDate: item?.issueDate,
        };
      }),

      establishmentofOrigin: fieldsData?.establishments?.map((item, idx) => {
        return {
          id: `prefiled-${idx + 1}`,
          name: item?.name,
          shipmentValue: item?.shipmentValue,
          country: fuzzyFindCountryCode(findCountry(fieldsData?.consignment?.country)?.name),
          type: item?.kind,
          approvalNumber: item?.approvalNumber,
        };
      }),
    };

    const traders = {
      tradersSelectedContact: fieldsData?.traders?.map((item, idx) => {
        return {
          id: `prefiled-${idx + 1}`,
          traderType: `${idx + 1}`,
          name: item?.name,
          address1: item?.addressLine1,
          address2: item?.addressLine2,
          address3: item?.addressLine3,
          city: item?.city,
          country: fuzzyFindCountryCode(findCountry(fieldsData?.consignment?.country)?.name),
          postcode: item?.zipCode,
          email: item?.email,
          phone: item?.phone,
        };
      }),

      traders: [],

      placeOfDestinations: fieldsData?.destinations?.map((item, idx) => {
        return {
          id: `prefiled-${idx + 1}`,
          name: item?.name,
          address1: item?.addressLine1,
          address2: item?.addressLine2,
          address3: item?.addressLine3,
          city: item?.city,
          country: fuzzyFindCountryCode(findCountry(fieldsData?.consignment?.country)?.name),
          postcode: item?.zipCode,
          email: item?.email,
          phone: item?.phone,
          conditions: item?.condition,
        };
      }),
    };

    const transport = {
      portOfEntry: fieldsData?.portOfEntry,
      portOfDischarge: fieldsData?.portOfDischarge,
      transportMeansToPort: fieldsData?.transportMode,
      transportIdentification: fieldsData?.vehicleIdentification,
      waybill: fieldsData?.waybillNumber,
      gvms: fieldsData?.useGVMS ? "Yes" : "No",
      estimatedArrivalTime: fieldsData?.arrivalDate,
      containersOrTrailers: fieldsData?.shippedByContainerOrRoad ? "Yes" : "No",
      containerNumberOrTrailerNumber: "",
      sealNumber: "",
      transporter: fieldsData?.transporters?.map((item, idx) => {
        return {
          id: `prefiled-${idx + 1}`,
          name: item?.name,
          address1: item?.address1,
          address2: item?.address2,
          city: item?.city,
          country: fuzzyFindCountryCode(findCountry(item?.country)?.name),
          postcode: item?.zipCode,
        };
      }),
    };

    const contactDetails = {
      name: fieldsData?.contact?.name,
      email: fieldsData?.contact?.email,
      phone: fieldsData?.contact?.phone,
      nominatedContacts: fieldsData?.nominees?.map((item, idx) => {
        return {
          id: `prefiled-${idx + 1}`,
          name: item?.name,
          email: item?.email,
          phone: item?.phone,
        };
      }),
    };

    setData((prevData) => ({
      ...prevData,
      consignments,
      commodities,
      documents,
      traders,
      transport,
      contactDetails,
    }));
  }, [id]);

  return (
    <Grid container spacing={2}>
      <Box px="1rem">
        <Button
          startIcon={<MdDashboard />}
          variant="outlined"
          onClick={() => history.goBack()}
          sx={{ textTransform: "none" }}
        >
          Return to Dashboard
        </Button>
      </Box>
      <Grid item xs={12}>
        <Typography color="primary" variant="h2" fontSize="1.2rem" fontWeight="600" px="1rem">
          Common Health Entry Document UK Import / Export{" "}
        </Typography>
        <Typography color="primary" variant="h2" fontSize="1.2rem" px="1rem" mt="2rem" mb="1rem">
          Edit Notification
        </Typography>
      </Grid>

      <Grid item xs={12} component="form">
        {!data ? (
          <Loader />
        ) : (
          <Pills
            value={pillIndex}
            setValue={setPillIndex}
            loading={false}
            elements={[
              {
                title: "Consignment",
                component: () => <Consignment data={data?.consignments} controls={setPillIndex} />,
              },
              {
                title: "Commodities",
                component: () => <Commodities data={data?.commodities} controls={setPillIndex} />,
              },
              { title: "Documents", component: () => <Documents data={data?.documents} controls={setPillIndex} /> },
              { title: "Traders", component: () => <Traders data={data?.traders} controls={setPillIndex} /> },
              { title: "Transport", component: () => <Transport data={data?.transport} controls={setPillIndex} /> },
              {
                title: "Contact Details",
                component: () => <ContactDetails data={data?.contactDetails} controls={setPillIndex} />,
              },
            ]}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EditIpaff;
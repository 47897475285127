import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { GET_SHIPMENTS } from "../graphql/shipment";
import { setShipmentWaypoints, setShowNotification } from "../redux/data/data.actions";

export const useShipment = () => {
  const dispatch = useDispatch();

  const [shipments] = useLazyQuery(GET_SHIPMENTS, {
    errorPolicy: "all",
    onCompleted(data) {
      dispatch(setShipmentWaypoints(data.shipments));
    },
    onError(error) {
      dispatch(setShowNotification({ isOpen: true, message: error.message, type: "error" }));
    },
  });

  const handleShipmentWaypoints = (
    page = 1,
    perPage = 10,
    containerNumber = "",
    shippingLine = "",
    billOfLading = "",
    inTransit = true
  ) => {
    shipments({
      variables: {
        filters: {
          page,
          perPage,
          containerNumber,
          shippingLine,
          billOfLading,
          inTransit,
        },
      },
    });
  };

  return { handleShipmentWaypoints };
};

import { useDispatch } from "react-redux";
import { AUTHENTICATE_WITH_GOOGLE, LOGIN_BY_EMAIL, SIGN_UP_BY_EMAIL, USER_UPDATE_PROFILE } from "../graphql/auth";
import { useLazyQuery, useMutation } from "@apollo/client";
import { setUserData } from "../redux/user/user.actions";
import { toast } from "react-toastify";

export const useAuth = () => {
  const dispatch = useDispatch();
  const analytics = window?.analytics;

  const [signupByEmail] = useMutation(SIGN_UP_BY_EMAIL, {
    onCompleted(data) {
      if (data?.signupByEmail?.code && data?.signupByEmail?.message) {
        toast.error(data?.signupByEmail?.message);

        analytics?.track("Account Creation Failed", {
          method: "Email",
          error: data?.signupByEmail?.message,
        });
      } else {
        analytics?.track("Signed up", {
          method: "Email",
          email: data?.signupByEmail?.user?.email,
        });

        toast.success("Your account has been successfully created");
      }

      return data;
    },
    onError(error) {
      toast.error(error.message);

      analytics?.track("Account Creation Failed", {
        method: "Email",
        error: error.message,
      });
    },
  });

  const [loginUser] = useLazyQuery(LOGIN_BY_EMAIL, {
    onCompleted(data) {
      if (data?.login?.code && data?.login?.message) {
        return toast.error(data?.login?.message);
      }

      return data;
    },
    onError(error) {
      toast.error(error.message);

      analytics?.track("Login Failed", {
        method: "Email",
        error: error.message,
      });
    },
  });

  const [authenticateWithGoogle] = useMutation(AUTHENTICATE_WITH_GOOGLE, {
    errorPolicy: "all",
    onCompleted(data) {
      dispatch(
        setUserData({
          ...data?.authenticateWithGoogle?.user,
          accessToken: data?.authenticateWithGoogle?.token,
          onboardingComplete: data?.authenticateWithGoogle?.user?.status === "OK",
        })
      );
      localStorage.setItem("onboardingComplete", data?.authenticateWithGoogle?.user?.status === "OK");

      analytics?.track("Signed up", {
        method: "Google",
        email: data?.authenticateWithGoogle?.user?.email,
      });

      toast.success("Successfully signed in with Google");

      return data;
    },
    onError(error) {
      toast.error(error.message);

      analytics?.track("Google Sign In Failed", {
        method: "Google",
        error: error.message,
      });
    },
  });

  const getAuthUser = (token) => {
    const data = authenticateWithGoogle({
      variables: {
        token,
      },
    });

    return data;
  };

  const getSignupByEmail = (payload) => {
    const data = signupByEmail({
      variables: {
        emailRequest: payload,
      },
    });

    return data;
  };

  const getLoginByEmail = (payload) => {
    const data = loginUser({
      variables: {
        loginRequest: payload,
      },
    });

    return data;
  };

  const [updateUser] = useMutation(USER_UPDATE_PROFILE, {
    onCompleted(data) {
      toast.success(data.message);

      return data;
    },
    onError(data) {
      toast.error(data.message);
    },
  });

  const getUpdateUser = (payload) => {
    const data = updateUser({
      variables: {
        userUpdateRequest: payload,
      },
    });

    return data;
  };

  return {
    getAuthUser,
    getSignupByEmail,
    getLoginByEmail,
    getUpdateUser,
  };
};

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import { Typography, Skeleton, useTheme } from "@mui/material";
import Trophy from "../../assets/icons/trophy-1.svg";
import TransactionBox from "./components/TransactionBox";
import useDashboardInfo from "../../hooks/useDashboardInfo";
import usePayment from "../../hooks/usePayment";
import SubscriptionInfo from "./components/SubscriptionInfo";
import ActionBox from "./components/ActionBox";


export default function Subscription() {
  useEffect(() => {
    document.title = `aTrace - Visibility - Subscription`;
  }, []);

  const theme = useTheme();
  const subscriptionInfo = useSelector((state) => state.data.subscriptionInfo);
  const user = useSelector((state) => state.user.userData);
  const { handleGetUserCredits } = useDashboardInfo();
  const { handleGetSubscriptionInfo } = usePayment();
  const plan = (subscriptionInfo?.planName || "").toLowerCase().trim();

  useEffect(() => {
    setTimeout(() => {
      handleGetUserCredits();
      handleGetSubscriptionInfo();
    }, 1000);
  }, []);

  return (
    <Container maxWidth="100%" sx={{ pb: { xs: "100px", lg: 0 } }}>
      <Typography variant="h1" fontSize="2rem" color="primary" lineHeight={2} component="h1">
        Subscription
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          {!subscriptionInfo ? (
            <>
              <Skeleton
                variant="rounded"
                height={"300px"}
                sx={{
                  backgroundImage: `linear-gradient(${theme.palette.primary.light}, rgba(255,255,255,0.5)), url(${Trophy})`,
                  backgroundPosition: "top right",
                  backgroundRepeat: "no-repeat",
                  mb: 1,
                }}
                animation="wave"
              />
              <Skeleton my={2} animation="pulsate" />
              <Skeleton
                height="45px"
                variant="rounded"
                sx={{
                  backgroundImage: `linear-gradient(${theme.palette.primary.light}, rgba(255,255,255,0.5))`,
                  mt: 1,
                }}
                animation="pulsate"
              />
            </>
          ) : (
            <>
              <SubscriptionInfo {...{ subscriptionInfo }} />
              <ActionBox
                plan={plan}
                processor={subscriptionInfo?.processor}
                billingPeriod={subscriptionInfo?.billingPeriod}
                planCode={subscriptionInfo?.planCode}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={5}>
          <TransactionBox />
        </Grid>
      </Grid>
    </Container>
  );
}

import React from "react";
import RLoader from "react-loader-spinner";

export default function Loader() {
  return (
    <div className="loader-container">
      <RLoader type="Grid" color="#033b6c" height={35} width={35} timeout={30000000} />
    </div>
  );
}

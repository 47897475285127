import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import Sidebar from "../../components/Sidebar/Sidebar";
import MobileNav from "../../components/MobileNav/MobileNav";
import Dashboard from "../Dashboard/Dashboard";
import Shipments from "../Shipments/Shipments";
import ClosedShipments from "../Shipments/ClosedShipments";
import ManageShipment from "../Shipments/ManageShipment";
// import NoAccess from "../NoAccess";
import Costs from "../Costs/Costs";
import Actual from "../Costs/Actual";
import Analysis from "../Costs/Analysis";
import Services from "../Services/Services";
import Settings from "../Settings/Settings";
import Tracking from "../Tracking/Tracking";
import Subscription from "../Subscription/Subscription";
import BuyCoins from "../Subscription/Subscribe";
import IpaffOverview from "../Services/ipaff";
import CreateIpaff from "../Services/ipaff/CreateIpaff";
import FormMUtilization from "../Reports/FormMUtilization";
import EfficiencyReport from "../Reports/EfficiencyReport";
import { UserDropdown, HeaderMobile } from "../../components/Dash";
import useDashboardInfo from "../../hooks/useDashboardInfo";
import { setResetTraceyChatHistory, setShowNotification } from "../../redux/data/data.actions";
import "./container.scss";
import EditIpaff from "../Services/ipaff/EditIpaff";
import ViewIpaff from "../Services/ipaff/ViewIpaff";
import ThreePService from "../Costs/ThreePService";
export default function Container() {
  const {
    handleGetNotifications,
    handleGetDashboardData,
    handleGetExceptions,
    handleGetShipmentsLastLocations,
    handleGetUnreadNotifications,
    handleGetUserCredits,
    handleGetUserCreditInformation,
  } = useDashboardInfo();

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const creditInfo = useSelector((state) => state?.data?.creditInfo);
  const [shipmentOpen, setShipmentOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  // const [expired, setExpired] = useState(false);
  // const [overExpired, setOverExpired] = useState(false);

  useEffect(() => {
    if (userData) {
      handleGetNotifications(30);
      handleGetDashboardData();
      handleGetExceptions();
      checkTokenExpiry();
      handleGetShipmentsLastLocations();
      handleGetUnreadNotifications();
      handleGetUserCredits();
      handleGetUserCreditInformation();
    }
  }, []);

  const checkTokenExpiry = () => {
    const decoded = jwt_decode(userData?.accessToken);
    if (decoded.exp * 1000 < Date.now()) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: "Your session has expired! Please login to continue.",
          type: "info",
        })
      );
      setTimeout(() => {
        dispatch({ type: "DESTROY_SESSION" });
      }, 2500);
    }
  };

  return (
    <div className="loggedin-container">
      <div className="sidebar-large">
        <Sidebar {...{ shipmentOpen, setShipmentOpen, reportOpen, setReportOpen }} />
      </div>

      <div className="main-content-wrapper">
        <div className="main-content">
          <div className="header-large">
            <UserDropdown {...{ setResetTraceyChatHistory }} />
          </div>
          <div className="header-small">
            <HeaderMobile />
          </div>
          {/* {1 != 1 ? (
            <Switch>
              <Route exact path={`/dashboard/subscription`} component={Subscription} />
              <Route exact path={`/dashboard/subscription/subscribe`} component={BuyCoins} />
              <Route exact path={`/dashboard/*`} component={NoAccess} />
            </Switch>
          ) : expired ? (
            <Switch>
              <Route exact path={`/dashboard/overview`} component={Dashboard} />
              <Route exact path={`/dashboard/subscription`} component={Subscription} />
              <Route exact path={`/dashboard/services`} component={Services} />
              <Route exact path={`/dashboard/subscription/subscribe`} component={BuyCoins} />
              <Route exact path={`/dashboard/settings`} component={Settings} />
              <Route exact path={`/dashboard/*`} component={NoAccess} />
            </Switch>
          ) : ( */}
          {
            <Switch>
              <Route exact path={`/dashboard/overview`} component={Dashboard} />
              <Route exact path={`/dashboard/subscription`} component={Subscription} />
              <Route exact path={`/dashboard/subscription/subscribe`} component={BuyCoins} />
              <Route exact path={`/dashboard/shipments`} component={Shipments} />
              <Route exact path={`/dashboard/closedshipments`} component={ClosedShipments} />
              <Route exact path={`/dashboard/edit/shipment/:container?`} component={ManageShipment} />
              {/* <Route exact path={`/dashboard/edit/shipment/:container?`} component={EditShipment} /> */}
              <Route exact path={`/dashboard/costs`} component={Costs} />
              <Route exact path={`/dashboard/costs/prediction`} component={Costs} />
              <Route exact path={`/dashboard/costs/actual`} component={Actual} />
              <Route exact path={`/dashboard/costs/analysis`} component={Analysis} />
              <Route exact path={`/dashboard/3pservices`} component={ThreePService} />
              <Route exact path={`/dashboard/tracking`} component={Tracking} />
              <Route exact path={`/dashboard/tracking/:searchType/:shippingLine/:searchNumber`} component={Tracking} />
              <Route exact path={`/dashboard/tracking/:searchType/:searchNumber`} component={Tracking} />
              <Route exact path={`/dashboard/settings`} component={Settings} />
              <Route exact path={`/dashboard`} component={Dashboard} />
              <Route exact path={`/dashboard/reports/formm`} component={FormMUtilization} />
              <Route exact path={`/dashboard/reports/efficiency`} component={EfficiencyReport} />
              {/* services  */}
              <Route exact path={`/dashboard/services/ipaff`} component={IpaffOverview} />
              <Route exact path={`/dashboard/services/ipaff/create`} component={CreateIpaff} />
              <Route exact path={`/dashboard/services/ipaff/view/:id`} component={ViewIpaff} />
              <Route exact path={`/dashboard/services/ipaff/edit/:id`} component={EditIpaff} />
            </Switch>
          }
        </div>
      </div>

      <div className="mobile-nav">
        <MobileNav {...{ shipmentOpen, setShipmentOpen }} />
      </div>
    </div>
  );
}

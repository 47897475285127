import { gql } from "@apollo/client";

export const GET_ALL_SUMMARIZE_COMMON_HEALTH_DECLARATION = gql`
  query summarizeCommonHealthDeclarations {
    summarizeCommonHealthDeclarations {
      ... on ResponseCommonHealthDeclarationSummary {
        totalApproved
        totalDrafted
        totalSubmitted
      }
    }
  }
`;

export const GET_ALL_COMMON_HEALTH_DECLARATION = gql`
  query commonHealthDeclarations($input: FilterCommonHealthDeclarations!) {
    commonHealthDeclarations(input: $input) {
      ... on ResponseCommonHealthDeclarationList {
        data {
          id
          createdAt
          updatedAt
          status
          originCountry
          externalReference
          consignment {
            kind
            description
            country
            regulatory
          }
          importReason
          marketPurpose
          movementReferenceNumber
          terminalBorderControl
          consignmentExitDate
          transitedCountries
          destinationCountry
          commodities {
            code
            packageKind
            netWeight
            grossWeight
            numPackages
            temperature
          }
          numPackages
          netWeight
          grossWeight
          documents {
            title
            kind
            url
            externalReference
            issueDate
          }
          establishments {
            name
            kind
            country
            approvalNumber
            shipmentValue
          }
          traders {
            name
            kind
            email
            phone
            city
            country
            zipCode
            addressLine1
            addressLine2
            addressLine3
          }
          portOfEntry
          transportMode
          arrivalDate
          vehicleIdentification
          waybillNumber
          useGVMS
          contact {
            name
            email
            phone
          }
          nominees {
            name
            email
            phone
          }
          portOfDischarge
          bcpTransportMode
          bcpVehicleIdentification
          bcpDepartureDate
          bcpWaybillNumber
          requiresOriginCertification
          shippedByContainerOrRoad
          transporters {
            name
            address1
            address2
            city
            zipCode
            country
          }
          destinations {
            name
            email
            phone
            city
            country
            zipCode
            condition
            addressLine1
            addressLine2
            addressLine3
          }
        }

        paginator {
          page
          size
          totalCount
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const GET_SINGLE_COMMON_HEALTH_DECLARATION = gql`
  query getCommonHealthDeclaration($identifier: String!) {
    getCommonHealthDeclaration(identifier: $identifier) {
      ... on ResponseCommonHealthDeclaration {
        id
        externalReference
        originCountry
        destinationCountry
        portOfDischarge
        consignment {
          kind
          description
          country
          regulatory
        }
        importReason
        marketPurpose
        movementReferenceNumber
        terminalBorderControl
        consignmentExitDate
        transitedCountries
        shippedByContainerOrRoad
        commodities {
          code
          packageKind
          netWeight
          grossWeight
          numPackages
          temperature
        }
        numPackages
        netWeight
        grossWeight
        documents {
          title
          kind
          url
          externalReference
          issueDate
        }
        establishments {
          name
          kind
          country
          approvalNumber
          shipmentValue
        }
        traders {
          name
          kind
          email
          phone
          city
          country
          zipCode
          addressLine1
          addressLine2
          addressLine3
        }
        destinations {
          name
          condition
          email
          phone
          city
          country
          zipCode
          addressLine1
          addressLine2
          addressLine3
        }
        portOfEntry
        transportMode
        arrivalDate
        vehicleIdentification
        waybillNumber
        useGVMS
        transporters {
          name
          city
          country
          zipCode
          address1
          address2
        }
        contact {
          name
          email
          phone
        }
        nominees {
          name
          email
          phone
        }
      }
    }
  }
`;

export const CREATE_COMMON_HEALTH_DECLARATION = gql`
  mutation createCommonHealthDeclaration($input: InputCreateCommonHealthDeclaration!) {
    createCommonHealthDeclaration(input: $input) {
      ... on ResponseCommonHealthDeclaration {
        id
        createdAt
        updatedAt
        status
        originCountry
        externalReference
        consignment {
          kind
          description
          country
          regulatory
        }
        importReason
        marketPurpose
        movementReferenceNumber
        terminalBorderControl
        consignmentExitDate
        transitedCountries
        destinationCountry
        commodities {
          code
          packageKind
          netWeight
          grossWeight
          numPackages
          temperature
        }
        numPackages
        netWeight
        grossWeight
        documents {
          title
          kind
          url
          externalReference
          issueDate
        }
        establishments {
          name
          kind
          country
          approvalNumber
          shipmentValue
        }
        traders {
          name
          kind
          email
          phone
          city
          country
          zipCode
          addressLine1
          addressLine2
          addressLine3
        }
        portOfEntry
        transportMode
        arrivalDate
        vehicleIdentification
        waybillNumber
        useGVMS
        contact {
          name
          email
          phone
        }
        nominees {
          name
          email
          phone
        }
        portOfDischarge
        bcpTransportMode
        bcpVehicleIdentification
        bcpDepartureDate
        bcpWaybillNumber
        requiresOriginCertification
        shippedByContainerOrRoad
        transporters {
          name
          address1
          address2
          city
          zipCode
          country
        }
        destinations {
          name
          email
          phone
          city
          country
          zipCode
          condition
          addressLine1
          addressLine2
          addressLine3
        }
      }
    }
  }
`;

export const UPDATE_COMMON_HEALTH_DECLARATION = gql`
  mutation updateCommonHealthDeclaration($input: InputUpdateCommonHealthDeclaration!) {
    updateCommonHealthDeclaration(input: $input) {
      ... on ResponseCommonHealthDeclaration {
        id
        externalReference
        consignment {
          kind
          description
          country
          regulatory
        }
        importReason
        marketPurpose
        movementReferenceNumber
        terminalBorderControl
        consignmentExitDate
        transitedCountries
        commodities {
          code
          packageKind
          netWeight
          grossWeight
          numPackages
          temperature
        }
        documents {
          title
          kind
          url
          externalReference
          issueDate
        }
        establishments {
          name
          kind
          country
          approvalNumber
          shipmentValue
        }
        traders {
          name
          kind
          email
          phone
          city
          country
          zipCode
          addressLine1
          addressLine2
          addressLine3
        }
        destinations {
          name
          condition
          email
          phone
          city
          country
          zipCode
          addressLine1
          addressLine2
          addressLine3
        }
        portOfEntry
        transportMode
        arrivalDate
        vehicleIdentification
        waybillNumber
        useGVMS
        transporters {
          name
          city
          country
          zipCode
          address1
          address2
        }
        contact {
          name
          email
          phone
        }
        nominees {
          name
          email
          phone
        }
      }
    }
  }
`;

export const DELETE_COMMON_HEALTH_DECLARATION = gql`
  mutation deleteCommonHealthDeclaration($identifier: String!) {
    deleteCommonHealthDeclaration(identifier: $identifier)
  }
`;

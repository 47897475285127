import { useLazyQuery, useMutation } from "@apollo/client";
import { ACCEPT_PLATFORM_TERMS } from "../graphql/profile";
import { setShowNotification } from "../redux/data/data.actions";
import useDashboardInfo from "./useDashboardInfo";
import { useDispatch } from "react-redux";
import { setDateAccepted } from "../redux/user/user.actions";
export const useProfile =()=>{
    const dispatch = useDispatch();
  const { logOut } = useDashboardInfo();
    const [acceptPlatformTerms] = useMutation(ACCEPT_PLATFORM_TERMS, {
        errorPolicy: "all",
        onCompleted(data) {
        if (data?.acceptPlatformTerms?.code && data?.acceptPlatformTerms?.message) {
            if (data?.acceptPlatformTerms?.code == 401) {
                return logOut();
            }
            dispatch(setShowNotification({ isOpen: true, message: data?.acceptPlatformTerms?.message, type: "error" }));
        }
        else if(data?.acceptPlatformTerms?.value){
            dispatch(setDateAccepted((new Date()).toISOString()));
        }
        else{
            dispatch(setShowNotification({ isOpen: true, message: data?.acceptPlatformTerms?.message, type: "error" }));
        }
    },
    });

    const handleAcceptPlatformTerms = ()=>{
        acceptPlatformTerms();
    };
    return {handleAcceptPlatformTerms};
};

export default useProfile;
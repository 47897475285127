import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid, Autocomplete, TextField, Fab, Button, Stack } from "@mui/material/";
import { LoadingButton } from "@mui/lab";
import { MdAddBusiness, MdUpdate, MdDelete } from "react-icons/md";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Fieldset } from "../../../components/v2/form/fieldset";
import { toast } from "react-toastify";
import { formattedNumber, getAmountInForeign, patchRate, fixMoneyUpload } from "../../../utils/helpers";
import { BlValue } from "@/components/v2/form/blvalue";

const Clearance = ({
  updateTracking,
  initialValues,
  setValuesChanged,
  getRateOrDefault,
  getCurrencyOrDefault,
  disabled,
}) => {
  const PROVIDERS = {
    "Clearing Agent": { name: "clearingAgent", contact: "", address: "clearingAgentAddress" },
    Transporter: { name: "transporter", contact: "", address: "transporterAddress" },
  };

  const valuesToProvidersArray = (values) => {
    const providers = [];
    for (const key in PROVIDERS) {
      let display = false;
      let pointer;
      for (const field in PROVIDERS[key]) {
        pointer = PROVIDERS[key][field];
        display = display || !!values[pointer];
      }
      if (display) {
        providers.push(key);
      }
    }

    return providers;
  };

  const handleAddProvider = () => {
    const type = Object.keys(PROVIDERS).find((type) => !serviceProviders.includes(type));
    setServiceProviders([...serviceProviders, type]);
  };

  const handleProviderDelete = (type) => {
    const newServiceProviders = serviceProviders.filter((serviceProvider) => serviceProvider !== type);
    setServiceProviders(newServiceProviders);
    Object.keys(PROVIDERS[type]).forEach((field) => {
      if (PROVIDERS[type][field]) {
        setFieldValue(PROVIDERS[type][field], "");
      }
    });
  };

  const handleProviderChange = (newType, idx) => {
    let oldType = serviceProviders[idx];
    const serviceProviderCopy = [...serviceProviders];
    serviceProviderCopy[idx] = newType;
    let oldKey;
    let newKey;
    if (!!oldType && !!newType && oldType !== newType) {
      Object.keys(PROVIDERS[oldType]).forEach((field) => {
        newKey = PROVIDERS[newType][field];
        oldKey = PROVIDERS[oldType][field];
        setFieldValue(newKey, values[newKey]);
        setFieldValue(oldKey, "");
      });
    }
    setServiceProviders(serviceProviderCopy);
  };

  const localCurrency = useSelector((state) => state?.user?.userData?.localCurrency);
  const blValue = initialValues?.blValue;
  const formik = useFormik({
    initialValues: {
      blValue: blValue || blValue === 0 ? formattedNumber(getAmountInForeign(blValue)) : "",
      blValueCurrency: getCurrencyOrDefault(blValue, localCurrency || "USD") || localCurrency,
      blValueRate: getRateOrDefault(blValue),
      customsRegistrationNumber: initialValues?.customsRegistrationNumber || "",
      manifestNumber: initialValues?.manifestNumber || "",
      customsCommand: initialValues?.customsCommand || "",
      shipmentAdviceReceptionDate: initialValues?.shipmentAdviceReceptionDate || "",
      draftDocumentsReceptionDate: initialValues?.draftDocumentsReceptionDate || "",
      originalDocumentsReceptionDate: initialValues?.originalDocumentsReceptionDate || "",
      paarNumber: initialValues?.paarNumber || "",
      paarSubmissionDate: initialValues?.paarSubmissionDate || "",
      paarCopyReceptionDate: initialValues?.paarCopyReceptionDate || "",
      dutyAssessmentNumber: initialValues?.dutyAssessmentNumber || "",
      assessmentReceptionDate: initialValues?.assessmentReceptionDate || "",
      sgdNumber: initialValues?.sgdNumber || "",
      sgdDate: initialValues?.sgdDate || "",
      clearingAgent: initialValues?.clearingAgent || "",
      clearingAgentAddress: initialValues?.clearingAgentAddress || "",
      transporter: initialValues?.transporter || "",
      transporterAddress: initialValues?.transporterAddress || "",
    },
    validationSchema: Yup.object({
      customsCommand: Yup.string(),
      clearingAgent: Yup.string(),
      transporter: Yup.string(),
      sgdNumber: Yup.string(),
      dutyAssessmentNumber: Yup.string(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const submitValues = { ...values };
      const { assessmentReceptionDate, paarCopyReceptionDate, paarSubmissionDate, sgdDate } = values;
      const { blValueCurrency, blValueRate, blValue } = submitValues;
      delete submitValues.blValueCurrency;
      delete submitValues.blValueRate;
      const result = await updateTracking({
        ...initialValues,
        ...submitValues,
        blValue: {
          value: fixMoneyUpload(blValue),
          currency: blValueCurrency || "",
          rate: patchRate(blValueRate || 0),
        },
        assessmentReceptionDate: assessmentReceptionDate || null,
        paarCopyReceptionDate: paarCopyReceptionDate || null,
        paarSubmissionDate: paarSubmissionDate || null,
        sgdDate: sgdDate || null,
      });
      if (result) {
        setValuesChanged(false);
      }
      setSubmitting(false);
    },
  });
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = formik;
  const [serviceProviders, setServiceProviders] = useState(valuesToProvidersArray(values));
  const handleChangeCustom = (e) => {
    const { name, value } = e.target;
    if (value !== initialValues[name]) {
      setValuesChanged(true);
    }
    handleChange(e);
  };

  return (
    <>
      <BlValue
        {...{ values, touched, errors, handleChange, setFieldValue, setFieldTouched, localCurrency, disabled }}
      />
      <Fieldset legend={"Document Control"}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Registration Number"
              name="customsRegistrationNumber"
              error={touched.customsRegistrationNumber && errors.customsRegistrationNumber}
              helperText={
                touched.customsRegistrationNumber && errors.customsRegistrationNumber
                  ? errors.customsRegistrationNumber
                  : ""
              }
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              value={values?.customsRegistrationNumber}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Customs Command"
              name="customsCommand"
              error={touched.customsCommand && errors.customsCommand}
              helperText={touched.customsCommand && errors.customsCommand ? errors.customsCommand : ""}
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              value={values?.customsCommand}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Manifest"
              name="manifestNumber"
              error={touched.manifestNumber && errors.manifestNumber}
              helperText={touched.manifestNumber && errors.manifestNumber ? errors.manifestNumber : ""}
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              value={values?.manifestNumber}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Pre-Arrival Assessment Report Number"
              InputLabelProps={{ shrink: true }}
              name="paarNumber"
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              type="text"
              error={touched.paarNumber && errors.paarNumber}
              helperText={touched.paarNumber && errors.paarNumber}
              value={values.paarNumber}
            />
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Pre-Arrival Assessment Report Submission Date"
              InputLabelProps={{ shrink: true }}
              name="paarSubmissionDate"
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              type="date"
              error={touched.paarSubmissionDate && errors.paarSubmissionDate}
              helperText={touched.paarSubmissionDate && errors.paarSubmissionDate ? errors.paarSubmissionDate : ""}
              value={values.paarSubmissionDate}
            />
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Pre-Arrival Assessment Report copy Reception Date"
              InputLabelProps={{ shrink: true }}
              name="paarCopyReceptionDate"
              type="date"
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              error={touched.paarCopyReceptionDate && errors.paarCopyReceptionDate}
              helperText={
                touched.paarCopyReceptionDate && errors.paarCopyReceptionDate ? errors.paarCopyReceptionDate : ""
              }
              value={values.paarCopyReceptionDate}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="SGD Number"
              name="sgdNumber"
              sx={{ textTransform: "capitalize" }}
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              error={touched.sgdNumber && errors.sgdNumber}
              helperText={touched.sgdNumber && errors.sgdNumber ? errors.sgdNumber : ""}
              value={values?.sgdNumber?.toLocaleUpperCase()}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="SGD Date"
              InputLabelProps={{ shrink: true }}
              name="sgdDate"
              type="date"
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              error={touched.sgdDate && errors.sgdDate}
              helperText={touched.sgdDate && errors.sgdDate ? errors.sgdDate : ""}
              value={values.sgdDate}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label={"Shipment Advice Reception date"}
              InputLabelProps={{ shrink: true }}
              name="shipmentAdviceReceptionDate"
              type="date"
              error={touched.shipmentAdviceReceptionDate && errors.shipmentAdviceReceptionDate}
              helperText={
                touched.shipmentAdviceReceptionDate && errors.shipmentAdviceReceptionDate
                  ? errors.shipmentAdviceReceptionDate
                  : ""
              }
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              value={values.shipmentAdviceReceptionDate}
            />
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label={"Draft Documents Reception Date"}
              InputLabelProps={{ shrink: true }}
              name="draftDocumentsReceptionDate"
              type="date"
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              error={touched.draftDocumentsReceptionDate && errors.draftDocumentsReceptionDate}
              helperText={
                touched.draftDocumentsReceptionDate && errors.draftDocumentsReceptionDate
                  ? errors.draftDocumentsReceptionDate
                  : ""
              }
              value={values.draftDocumentsReceptionDate}
            />
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Original Documents Reception Date"
              InputLabelProps={{ shrink: true }}
              name="originalDocumentsReceptionDate"
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              type="date"
              error={touched.originalDocumentsReceptionDate && errors.originalDocumentsReceptionDate}
              helperText={
                touched.originalDocumentsReceptionDate && errors.originalDocumentsReceptionDate
                  ? errors.originalDocumentsReceptionDate
                  : ""
              }
              value={values.originalDocumentsReceptionDate}
            />
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Duty Assessment Number"
              name="dutyAssessmentNumber"
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              error={touched.dutyAssessmentNumber && errors.dutyAssessmentNumber}
              helperText={
                touched.dutyAssessmentNumber && errors.dutyAssessmentNumber ? errors.dutyAssessmentNumber : ""
              }
              value={values?.dutyAssessmentNumber?.toLocaleUpperCase()}
            />
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Assessment Reception Date"
              InputLabelProps={{ shrink: true }}
              type="date"
              name="assessmentReceptionDate"
              onChange={handleChangeCustom}
              onBlur={handleBlur}
              error={touched.assessmentReceptionDate && errors.assessmentReceptionDate}
              helperText={
                touched.assessmentReceptionDate && errors.assessmentReceptionDate ? errors.assessmentReceptionDate : ""
              }
              value={values.assessmentReceptionDate}
            />
          </Grid>
        </Grid>
      </Fieldset>
      {/* Service Providers */}
      <Grid item xs={12}>
        <Fieldset legend="Service Providers" marginBottom="2rem" padding=".5rem">
          <Grid container spacing={1.5} p={0.5}>
            {serviceProviders.map((provider, idx) => (
              <Grid key={idx} item xl={4} sm={6} xs={12}>
                <Grid
                  container
                  p={1}
                  spacing={0.5}
                  borderRadius={".25rem"}
                  boxShadow={"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}
                >
                  <Grid item sm={6} xs={12}>
                    <Autocomplete
                      freeSolo
                      fullWidth
                      options={Object.keys(PROVIDERS)}
                      getOptionDisabled={(option) => serviceProviders.includes(option)}
                      onInputChange={(e, v) => {
                        handleProviderChange(v, idx);
                      }}
                      value={provider}
                      renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Type" />}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Name"
                      disabled={!PROVIDERS[provider]?.name}
                      name={PROVIDERS[provider]?.name}
                      error={touched?.[PROVIDERS[provider]?.name] && errors?.[PROVIDERS[provider]?.name]}
                      helperText={touched?.[PROVIDERS[provider]?.name] && errors?.[PROVIDERS[provider]?.name]}
                      value={values?.[PROVIDERS[provider]?.name] || ""}
                      onChange={handleChangeCustom}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Address"
                      multiline
                      rows={3}
                      disabled={!PROVIDERS[provider].address}
                      name={PROVIDERS[provider].address}
                      error={touched?.[PROVIDERS[provider].address] && errors?.[PROVIDERS[provider].address]}
                      helperText={touched?.[PROVIDERS[provider].address] && errors?.[PROVIDERS[provider].address]}
                      value={values?.[PROVIDERS[provider].address] || ""}
                      onChange={handleChangeCustom}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Stack spacing={1}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Contact"
                        disabled={!PROVIDERS[provider].contacts}
                        name={PROVIDERS[provider].contacts}
                        error={touched?.[PROVIDERS[provider].contacts] && errors?.[PROVIDERS[provider].contacts]}
                        helperText={touched?.[PROVIDERS[provider].contacts] && errors?.[PROVIDERS[provider].contacts]}
                        value={values?.[PROVIDERS[provider].contacts] || ""}
                        onChange={handleChangeCustom}
                        onBlur={handleBlur}
                      />
                      <Button
                        onClick={() => handleProviderDelete(provider)}
                        color="error"
                        variant="outlined"
                        fullWidth
                        endIcon={<MdDelete />}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item sm={6} xs={12}></Grid>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={handleAddProvider}
                disabled={Object.keys(PROVIDERS).length === serviceProviders.length}
                color="primary"
                startIcon={<MdAddBusiness />}
              >
                Add Provider
              </Button>
            </Grid>
          </Grid>
        </Fieldset>
      </Grid>

      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            disabled={!initialValues?.containerNumber}
            loading={isSubmitting}
            onClick={(e) => {
              if (!getRateOrDefault(initialValues?.blValue) && initialValues?.blValue?.currency !== localCurrency) {
                toast.error("Please set the BL value Exchange rate for this container", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
                return;
              }
              handleSubmit(e);
            }}
            startIcon={<MdUpdate />}
            loadingPosition="start"
            variant="contained"
          >
            update
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default Clearance;

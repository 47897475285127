import React, { useEffect, useState } from "react";
import { TextField, Grid, Box , Backdrop } from "@mui/material/";
import Loader from "react-loader-spinner";
import useTracking from "../../hooks/useTracking";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { Fieldset } from "../../components/v2/form/fieldset";
import { useFormik } from "formik";
import eta from "../../assets/bg/eta-9.svg";
import * as Yup from "yup";

const EtaStatus = () => {
  /* Variables */
  const { handleGetETAStatusPrefences, handleUpdateETAStatusPreference } = useTracking();
  const formik = useFormik({
    initialValues: {
      originLand: {
        late: "",
        critical: "",
      },
      originPort: {
        late: "",
        critical: "",
      },
      vessel: {
        late: "",
        critical: "",
      },
      destinationPort: {
        late: "",
        critical: "",
      },
      destinationLand: {
        late: "",
        critical: "",
      },
    },
    validationSchema: Yup.object().shape({
      originLand: Yup.object().shape({
        late: Yup.number().required("Required"),
        critical: Yup.number().required("Required").moreThan(Yup.ref("late"), "Critical must be greater than Late"),
      }),
      originPort: Yup.object().shape({
        late: Yup.number().required("Required"),
        critical: Yup.number().required("Required").moreThan(Yup.ref("late"), "Critical must be greater than Late"),
      }),
      vessel: Yup.object().shape({
        late: Yup.number().required("Required"),
        critical: Yup.number().required("Required").moreThan(Yup.ref("late"), "Critical must be greater than Late"),
      }),
      destinationPort: Yup.object().shape({
        late: Yup.number().required("Required"),
        critical: Yup.number().required("Required").moreThan(Yup.ref("late"), "Critical must be greater than Late"),
      }),
      destinationLand: Yup.object().shape({
        late: Yup.number().required("Required"),
        critical: Yup.number().required("Required").moreThan(Yup.ref("late"), "Critical must be greater than Late"),
      }),
    }),

    onSubmit: async (values) => {
      await handleUpdateETAStatusPreference(values);
    },
  });

  const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setValues } = formik;
  const ETAStatusPreferences = useSelector((state) => state?.data?.ETAStatusPreferences);

  /* Functions */

  const getETAPreferenceValues = (eta) =>
    Object.keys(eta)?.reduce((prev, key) => {
      if (key !== "__typename") {
        prev[key] = { late: eta[key]?.late, critical: eta[key]?.critical };
      }
      return prev;
    }, {});

  useEffect(() => handleGetETAStatusPrefences(), []);

  useEffect(() => {
    if (typeof ETAStatusPreferences === "object" && ETAStatusPreferences && Object.keys(ETAStatusPreferences).length) {
      setValues(getETAPreferenceValues(ETAStatusPreferences));
    }
  }, [ETAStatusPreferences]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting || ETAStatusPreferences === "loading"}
      >
        <Loader type="Grid" color="#033b6c" height={35} />
      </Backdrop>
      <Box
        sx={{
          p: 1,
          pb: { xs: 10, md: 1 },

          backgroundPosition: "right 50%",
          backgroundSize: { md: "350px", lg: "400px", xl: "550px" },
          backgroundRepeat: "no-repeat",
          backgroundImage: { xs: "none", md: `url(${eta})` },
        }}
      >
        <Grid
          container
          maxWidth="500px"
          sx={{ mx: { xs: "auto", md: "0" } }}
          bgcolor="rgba(255,255,255,.95)"
          opacity={0.9}
          spacing={2}
        >
          <Grid item xs={12}>
            <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <Fieldset marginBottom="0" legend={"Origin Land"}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        label="Late After"
                        InputLabelProps={{ shrink: true }}
                        name="originLand.late"
                        size="small"
                        value={values.originLand.late}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.originLand?.late && Boolean(errors?.originLand?.late)}
                        helperText={touched?.originLand?.late && errors?.originLand?.late}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        label="Critical After"
                        InputLabelProps={{ shrink: true }}
                        name="originLand.critical"
                        size="small"
                        value={values.originLand.critical}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.originLand?.critical && Boolean(errors?.originLand?.critical)}
                        helperText={touched?.originLand?.critical && errors?.originLand?.critical}
                      />
                    </Grid>
                  </Grid>
                </Fieldset>
              </Grid>

              <Grid item xs={12}>
                <Fieldset marginBottom="0" legend={"Origin Port"}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        label="Late After"
                        InputLabelProps={{ shrink: true }}
                        name="originPort.late"
                        size="small"
                        value={values.originPort.late}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.originPort?.late && Boolean(errors?.originPort?.late)}
                        helperText={touched?.originPort?.late && errors?.originPort?.late}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        label="Critical After"
                        InputLabelProps={{ shrink: true }}
                        name="originPort.critical"
                        size="small"
                        value={values.originPort.critical}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.originPort?.critical && Boolean(errors?.originPort?.critical)}
                        helperText={touched?.originPort?.critical && errors?.originPort?.critical}
                      />
                    </Grid>
                  </Grid>
                </Fieldset>
              </Grid>

              <Grid item xs={12}>
                <Fieldset marginBottom="0" legend={"Vessel"}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        label="Late After"
                        InputLabelProps={{ shrink: true }}
                        name="vessel.late"
                        size="small"
                        value={values.vessel.late}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.vessel?.late && Boolean(errors?.vessel?.late)}
                        helperText={touched?.vessel?.late && errors?.vessel?.late}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        label="Critical After"
                        InputLabelProps={{ shrink: true }}
                        name="vessel.critical"
                        size="small"
                        value={values.vessel.critical}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.vessel?.critical && Boolean(errors?.vessel?.critical)}
                        helperText={touched?.vessel?.critical && errors?.vessel?.critical}
                      />
                    </Grid>
                  </Grid>
                </Fieldset>
              </Grid>

              <Grid item xs={12}>
                <Fieldset marginBottom="0" legend={"Destination Port"}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        label="Late After"
                        InputLabelProps={{ shrink: true }}
                        name="destinationPort.late"
                        size="small"
                        value={values.destinationPort.late}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.destinationPort?.late && Boolean(errors?.destinationPort?.late)}
                        helperText={touched?.destinationPort?.late && errors?.destinationPort?.late}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        label="Critical After"
                        InputLabelProps={{ shrink: true }}
                        name="destinationPort.critical"
                        size="small"
                        value={values.destinationPort.critical}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.destinationPort?.critical && Boolean(errors?.destinationPort?.critical)}
                        helperText={touched?.destinationPort?.critical && errors?.destinationPort?.critical}
                      />
                    </Grid>
                  </Grid>
                </Fieldset>
              </Grid>

              <Grid item xs={12}>
                <Fieldset marginBottom="0" legend={"Destination Land"}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        label="Late After"
                        InputLabelProps={{ shrink: true }}
                        name="destinationLand.late"
                        size="small"
                        value={values.destinationLand.late}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.destinationLand?.late && Boolean(errors?.destinationLand?.late)}
                        helperText={touched?.destinationLand?.late && errors?.destinationLand?.late}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        label="Critical After"
                        InputLabelProps={{ shrink: true }}
                        name="destinationLand.critical"
                        size="small"
                        value={values.destinationLand.critical}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.destinationLand?.critical && Boolean(errors?.destinationLand?.critical)}
                        helperText={touched?.destinationLand?.critical && errors?.destinationLand?.critical}
                      />
                    </Grid>
                  </Grid>
                </Fieldset>
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Update
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EtaStatus;

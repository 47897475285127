export const shipmentData = {
  labels: [["Origin", "Land"], ["Origin", "Port"], ["Ocean"], ["Destination", "Port"], ["Destination", "Land"]],
  datasets: [
    {
      label: "On-time",
      data: [25, 13, 28, 16, 19],
      backgroundColor: "#4BD37B",
      borderWidth: 1,
      barThickness: 35,
    },
    {
      label: "Late",
      data: [25, 13, 28, 16, 19],
      backgroundColor: "#FB8B23",
      borderWidth: 1,
      barThickness: 35,
    },
    {
      label: "Critical",
      data: [25, 13, 28, 16, 19],
      backgroundColor: "#FF1000",
      borderWidth: 1,
      barThickness: 35,
    },
  ],
};

export const etaData = {
  labels: ["On-time", "Late", "Critical"],
  datasets: [
    {
      label: "My First Dataset",
      data: [33, 57, 30],
      backgroundColor: ["#4BD37B", "#FB8B23", "#FF1000"],
      hoverOffset: 4,
    },
  ],
};

export const etaOptions = {
  plugins: {
    datalabels: {
      color: ["#111", "#111", "#111"],
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
};

export const shipmentOptions = {
  elements: {
    borderColor: "red",
  },
  plugins: {
    maintainAspectRatio: false,
    font: {
      size: "3px",
    },
    datalabels: {
      display: (context) => context.dataset.data[context.dataIndex] > 0,
      align: "center",
      anchor: "center",
      padding: 2,
      font: (context) => ({ size: context.dataset.data[context.dataIndex] > 1 ? 12 : 12, weight: "bold" }),
      color: (context) => {
        return context?.dataset?.label == "Critical" ? "#111" : context?.dataset?.label == "On-time" ? "#111" : "#111";
      },
    },
    title: {
      display: true,
    },
    legend: {
      display: true,
    },
  },
  skipNull: true,
  scales: {
    y: {
      stacked: true,
      beginAtZero: true,
      step: 0.5,
      grid: {
        drawTicks: false,
      },
      title: { display: true, align: "left", text: "Shipments" },
      ticks: {
        color: "#858585",
        padding: 5,
        font: {
          size: 14,
        },
      },
    },
    x: {
      stacked: true,
      grid: {
        borderColor: "white",
        tickColor: "white",
        color: "white",
        drawTicks: false,
      },
      ticks: {
        padding: 7,
        autoSkip: false,
        autoSkipPadding: 3,
        maxRotation: 0,
        minRotation: 0,
        fontFamily: "Inter",
      },
    },
  },
};

export const doughnutStatus = [
  {
    color: "#4BD37B",
    status: "On-time",
  },
  {
    color: "#FB8B23",
    status: "Late",
  },
  {
    color: "#FF1000",
    status: "Critical",
  },
];
export const formMOptions = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: false,
    title: {
      display: true,
      text: "% Usage",
    },
  },
};

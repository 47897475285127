import React from "react";
import { useField } from "formik";
import "./checkbox.scss";

const Checkbox = ({ children, ...props }) => {
  const [field] = useField({ ...props, type: "checkbox" });
  return (
    <div>
      <label>
        <input type="checkbox" {...field} {...props} className="checkbox-custom" />
        <span className="checkbox-text">{children}</span>
      </label>
    </div>
  );
};

export default Checkbox;

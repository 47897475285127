import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowNotification } from "../../redux/data/data.actions";
import ModalNotification from "../Modals/ModalNotification";
import { containerNotFoundReasonsMapper } from "../../utils/data";

export default function NotificationComponent() {
  const dispatch = useDispatch();
  const showNotification = useSelector((state) => state?.data?.showNotification);
  return (
    showNotification && (
      <ModalNotification
        title={showNotification?.type}
        handleClose={() => dispatch(setShowNotification(null))}
        open={showNotification?.isOpen}
        msgs={
          Array.isArray(showNotification?.message)
            ? showNotification?.message?.map(containerNotFoundReasonsMapper)
            : typeof showNotification?.message === "object"
            ? containerNotFoundReasonsMapper("Oops unexpected error")
            : containerNotFoundReasonsMapper(showNotification?.message)
        }
        severity={showNotification?.severity || showNotification?.color || showNotification?.type || "error"}
        actionText={showNotification?.actionText}
        action={showNotification?.action}
      />
    )
  );
}

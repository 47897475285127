import React, { useEffect } from "react";

import { DialogTitle, DialogContent, Dialog } from "@mui/material";
import MapBg from "../../assets/images/map-bg.svg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../Form/CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const StripePaymentModal = ({ open, handleClose, clientCode }) => {
  const closeUp = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: { backgroundColor: "#fcfcff", backgroundImage: `url(${MapBg})`, backgroundPosition: "contain" },
      }}
      onClose={closeUp}
      fullWidth
    >
      <DialogTitle sx={{ color: "#033b6c", fontWeight: "bold" }}>Subscribe</DialogTitle>
      <DialogContent>
        <Elements stripe={stripePromise} options={{ clientSecret: clientCode }}>
          <CheckoutForm closeUp={closeUp} clientCode={clientCode} />
        </Elements>
      </DialogContent>
    </Dialog>
  );
};

import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";

import { color } from "../../styles/constants";
import Profile from "./Profile";
import TradeTerms from "./TradeTerms";
import EtaStatus from "./EtaStatus";

const ProfileTabs = () => {
  const styleBorder = {
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: "24px 0" }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      style: {
        color: index === value ? color.warning : color.default,
        backgroundColor: "transparent",
        textTransform: "capitalize",
      },
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Container maxWidth="100%">
        <Box sx={styleBorder}>
          <Tabs
            indicatorColor={null}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Trade Terms" {...a11yProps(1)} />
            <Tab label="ETA Status" {...a11yProps(2)} />
            {/* <Tab label="Notifications" {...a11yProps(2)} />
            <Tab label="User Management" {...a11yProps(3)} /> */}
          </Tabs>
        </Box>
      </Container>
      <Container maxWidth="100%">
        <TabPanel value={value} index={0}>
          <Profile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TradeTerms />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EtaStatus />
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <UserManagement />
        </TabPanel> */}
      </Container>
    </Box>
  );
};

export default ProfileTabs;

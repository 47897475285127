import { gql } from "@apollo/client";

export const UPLOAD_FILE_LINK = gql`
  query uploadFileLink($input: InputGenerateUploadURL!) {
    commercialInvoiceUploadURL(input: $input) {
      url
      signedUrl
      headers {
        key
        value
      }
    }
  }
`;

export const UPLOAD_CUSTOMS_DOCUMENT_LINK = gql`
  query uploadFileLink($input: InputGenerateUploadURL!) {
    nigerianCustomsUploadURL(input: $input) {
      url
      signedUrl
      headers {
        key
        value
      }
    }
  }
`;

export const UPLOAD_BL_DOCUMENT_LINK = gql`
  query uploadFileLink($input: InputGenerateUploadURL!) {
    billOfLadingDocumentUploadURL(input: $input) {
      url
      signedUrl
      headers {
        key
        value
      }
    }
  }
`;
export const UPLOAD_3SP_DOCUMENT_LINK = gql`
  query serviceProviderInvoiceDocumentUploadURL($input: InputGenerateUploadURL!) {
    serviceProviderInvoiceDocumentUploadURL(input: $input) {
      url
      signedUrl
      headers {
        key
        value
      }
    }
  }
`;

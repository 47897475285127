import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/logo_name_1.png";
import "./form.scss";
import { Box } from "@mui/material";

const FormHeader = (props) => {
  const history = useHistory();
  return (
    <Fragment>
      <div className="form-header">
        <Box
          onClick={() => history.push("/")}
          sx={{
            height: "100%",
            width: "30%",
          }}
        >
          <img src={Logo} alt="Logo" />
        </Box>
        <h5 className="title">{props.title}</h5>
        <p className="desc">{props.paragraph}</p>
      </div>
    </Fragment>
  );
};

export default FormHeader;

import { useState, useEffect } from "react";
import EventsTimeline from "../../components/EventsTimeline/EventsTimeline";
import { useParams } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { TiWarningOutline } from "react-icons/ti";
import { Maps } from "../../components/Map/Map";
import useTracking from "../../hooks/useTracking";
import Loader from "../../components/Loader/Loader";
import "./tracking.scss";
import { ParseServerDate } from "../../utils/helpers";
import { SearchBillContainer } from "../../components/SearchBillContainer/SearchBillContainer";
import { setGetShipment, setDynamicMapCenter, setShowNotification } from "../../redux/data/data.actions";
import ConfirmationDialog from "../../components/Confirmation/ConfirmationDialog";
import { OnboardingProcessTracking } from "../../components/Onboarding";
import useDashboardInfo from "../../hooks/useDashboardInfo";
import { shippingOptionsFullName } from "../../utils/shippingLine";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdExpandMore } from "react-icons/md";
import { EVENT_STAGES } from "../../utils/data";
const analytics = window?.analytics;

export default function Tracking() {
  document.title = `aTrace - Visibility - Tracking`;
  const dispatch = useDispatch();
  const { handleGetUserCredits } = useDashboardInfo();
  const { searchType, shippingLine, searchNumber } = useParams();
  const tempContainerNo = searchType === "container" ? searchNumber : "";
  const tempBillOfLading = searchType === "bill" ? searchNumber : "";
  const tempShippingLine = shippingLine;
  const { searchBillHandler, searchTrackingIdHandler, searchContainerHandler, handleStartTracking } = useTracking();
  const user = useSelector((state) => state.user.userData);
  const getShipment = useSelector((state) => state.data.getShipment);
  const creditInfo = useSelector((state) => state?.data?.creditInfo);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    // if (showOnboarding) {
    //   dispatch(setGetShipment(dummyTrackingData));
    // } else

    if (
      (searchType && shippingLine && searchNumber) ||
      (searchType && searchType.toString().toLowerCase() == "trackingid" && searchNumber)
    ) {
      getShipmentInfo();
    } else if (getShipment === "no-record") {
      dispatch(setGetShipment(null));
    }
    // eslint-disable-next-line
  }, [searchType, shippingLine, searchNumber]);

  const getShipmentInfo = () => {
    if (searchType?.toString()?.toLowerCase() === "trackingid") {
      searchTrackingIdHandler(searchNumber);
    } else if (searchType === "container") {
      searchContainerHandler(shippingLine, searchNumber);
    } else {
      searchBillHandler(shippingLine, searchNumber);
    }
  };

  const getLocation = (location) => getShipment?.locations.find((locData) => locData?.id == location);

  const startTrackingItems = (container) => {
    let dataArray = [];

    const dataToTrack = {
      shippingLine: getShipment?.shippingLine,
      containerNumber: container,
      billOfLading: getShipment?.billOfLadingNumber,
    };

    dataArray.push(dataToTrack);
    handleStartTracking(dataArray);

    analytics?.track("Start Tracking", {
      method: "Direct",
      container: container,
      BL: getShipment?.billOfLadingNumber,
      ShippingLine: shippingLine,
    });
  };

  const startTrackingAll = (containers) => {
    let dataArray = [];
    for (let container of containers) {
      dataArray.push({
        shippingLine: getShipment?.shippingLine,
        containerNumber: container,
        billOfLading: getShipment?.billOfLadingNumber,
      });
    }

    handleStartTracking(dataArray);

    analytics?.track("Start Tracking", {
      method: "Direct",
      container: containers,
      BL: getShipment?.billOfLadingNumber,
      ShippingLine: shippingLine,
    });
  };

  const hasExpiredAction = () => {
    analytics?.track("Insufficient Credits", {
      username: user?.email || "",
      message: "User has insufficient tracking credit",
    });
    dispatch(
      setShowNotification({
        isOpen: true,
        message: [
          "Oops you have insufficient tracking credit.",
          "Upgrade to a higer plan to enjoy more trackings and features.",
        ],
        severity: "warning",
        title: "Insufficient credits",
        actionText: "subscribe",
        action: () => history.push("/dashboard/subscription/subscribe"),
      })
    );
  };

  useEffect(() => {
    if (creditInfo) {
      const hasExpired = creditInfo?.meter?.trackingsRemaining * 1 + creditInfo?.meter?.overageRemaining * 1 <= 0;
      setExpired(hasExpired);
    }
  }, [creditInfo]);
  const onClickContinue = () => {
    getShipmentInfo();
    handleGetUserCredits();
  };

  return (
    <>
      <div className="tracking-page-container">
        {!(getShipment?.containerNumber || getShipment?.billOfLadingNumber) && (
          <>
            <div className="search-container">
              <SearchBillContainer
                containerNo={tempContainerNo}
                billOfLading={tempBillOfLading}
                shipingLine={tempShippingLine}
                onClickContinueAdditionalFxns={onClickContinue}
                presentRoute="tracking-empty"
              />
            </div>
            <div className="tracking-main">
              <div className="info-map"></div>
            </div>
            {getShipment === "loading" && <Loader />}

            {getShipment === "no-record" && (
              <div className="no-data">
                <div className="icon">
                  <TiWarningOutline color="#fb8b23" style={{ margin: "10px auto" }} size="100" />
                </div>
                <div className="text">No record found!</div>
              </div>
            )}

            {getShipment === null && (
              <div className="no-data">
                <div className="icon">
                  <TiWarningOutline color="#fb8b23" style={{ margin: "10px auto" }} size="100" />
                </div>
                <div className="no-data-content">
                  <div className="text">To begin;</div>
                  <div className="text">1. Select Shipping Line</div>
                  <div className="text">2. Input Container No or Bill of Lading</div>
                </div>
              </div>
            )}
          </>
        )}
        <SmallTracking
          {...{
            getShipment,
            onClickContinue,
            startTrackingItems,
            startTrackingAll,
            getLocation,
            searchType,
            hasExpiredAction,
            expired,
          }}
        />
        <LargeTracking
          {...{
            getShipment,
            onClickContinue,
            startTrackingItems,
            startTrackingAll,
            getLocation,
            searchType,
            hasExpiredAction,
            expired,
          }}
        />
      </div>
    </>
  );
}

const SmallTracking = ({
  getShipment,
  onClickContinue,
  startTrackingItems,
  startTrackingAll,
  getLocation,
  searchType,
  hasExpiredAction,
  expired,
}) => {
  const [expanded, setExpanded] = useState("panel2");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className="small-tracking" style={{ display: "none" }}>
      {getShipment && typeof getShipment === "object" && (
        <div className="tracking-main">
          <div className="info-map">
            <div className="search-container">
              <SearchBillContainer
                onClickContinueAdditionalFxns={onClickContinue}
                containerNo={getShipment?.containerNumber}
                billOfLading={getShipment?.billOfLadingNumber}
                shipingLine={getShipment?.shippingLine}
                presentRoute="tracking"
              />
            </div>
            <Accordion>
              <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <div className="info-row-one">
                  {getShipment?.containerNumber ? (
                    <div className="info1">
                      <div className="info1-title">
                        {getShipment?.containerNumber ? getShipment?.containerNumber : "N/A"}{" "}
                      </div>
                      <div className="info1-desc">
                        <span style={{ margin: ".25rem" }}> -&nbsp;Container&nbsp;№</span>
                        {getShipment?.trackingActive === false && getShipment?.trackingActive != null ? (
                          <span style={{ backgroundColor: "#e44", padding: ".2rem", margin: ".25", color: "#eee" }}>
                            Closed
                          </span>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="info1">
                      <div className="info1-title">{getShipment?.billOfLadingNumber || "N/A"}</div>
                      <div className="info1-desc">-&nbsp;Bill&nbsp;of&nbsp;Lading</div>
                      {getShipment?.trackingActive === false && getShipment?.trackingActive != null ? (
                        <span style={{ backgroundColor: "#e44", padding: ".2rem", margin: ".25", color: "#eee" }}>
                          Closed
                        </span>
                      ) : null}
                    </div>
                  )}

                  <div className="info1 cram">
                    {getShipment?.containerNumber && (
                      <>
                        <div className="info1-title">
                          {getShipment?.lastEvent ? EVENT_STAGES[getShipment?.lastEvent] : "N/A"}
                        </div>
                        <div className="info1-desc"> -&nbsp;Last&nbsp;Event</div>
                      </>
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="info-row-two">
                  <div className="info2 cram">
                    {getShipment?.containerNumber && (
                      <>
                        <div className="info2-title">{getShipment?.lastEvent ? getShipment?.lastEvent : "N/A"}</div>
                        <div className="info2-desc">Last Event</div>
                      </>
                    )}
                  </div>

                  <div className="info2">
                    <div className="info2-title">{getShipment?.billOfLadingNumber || "-"}</div>
                    <div className="info2-desc">Bill of Lading</div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">
                      <small style={{ color: "#c17777" }}>
                        {searchType === "bill" ? "Check each container" : getShipment?.leadTime || 0}
                      </small>
                    </div>
                    <div className="info2-desc">Total Lead Time</div>
                  </div>
                  <div className="info2">
                    <div className="info2-title">
                      {shippingOptionsFullName(getShipment?.shippingLine)
                        ? shippingOptionsFullName(getShipment?.shippingLine)
                        : "N/A"}
                    </div>
                    <div className="info2-desc">Shipping Line</div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">
                      {getShipment?.locations && getShipment?.portOfDischarge?.location
                        ? `${getLocation(getShipment?.portOfDischarge?.location)?.name}, ${
                            getLocation(getShipment?.portOfDischarge?.location)?.country
                          }`
                        : "N/A"}
                    </div>
                    <div className="info2-desc">Destination</div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">{getShipment.lastVessel || "N/A"}</div>
                    <div className="info2-desc">Vessel</div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">
                      {!getShipment?.containerType && !getShipment.isoCodeDescription && !getShipment?.containerSize
                        ? "N/A"
                        : `${getShipment?.containerSize ? getShipment?.containerSize : "-"} / ${
                            getShipment?.containerType ? getShipment?.containerType : ""
                          } / ${getShipment?.isoCodeDescription ? getShipment?.isoCodeDescription : ""}`}
                    </div>
                    <div className="info2-desc">Container Description</div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">
                      {getShipment?.facilityName
                        ? `${getShipment?.facilityName} - ${
                            getLocation(getShipment?.portOfDischarge?.location)?.Locode || ""
                          }`
                        : getShipment?.locations && getShipment?.portOfDischarge?.location
                        ? getShipment?.locations.map((location) =>
                            location?.id === getShipment?.portOfDischarge?.location
                              ? `${location?.name} - ${location?.Locode}`
                              : ""
                          )
                        : "N/A"}
                    </div>
                    <div className="info2-desc">Discharge Port</div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">
                      {getShipment?.portOfLoading?.date ? (
                        <div>{ParseServerDate(getShipment?.portOfLoading?.date, "DD/MM/YYYY")}</div>
                      ) : (
                        "N/A"
                      )}
                    </div>
                    <div className="info2-desc">Departure</div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">
                      {getShipment?.portOfDischarge?.date ? (
                        <div>{ParseServerDate(getShipment?.portOfDischarge?.date, "DD/MM/YYYY")}</div>
                      ) : (
                        "N/A"
                      )}
                    </div>
                    <div className="info2-desc">
                      {getShipment?.portOfDischarge?.actual ? "Actual ETA" : "Declared ETA"}
                    </div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">
                      {getShipment?.lastStage ? EVENT_STAGES[getShipment?.lastStage] : "N/A"}
                    </div>
                    <div className="info2-desc">Current Stage</div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">N/A</div>
                    <div className="info2-desc">Shipping Line DO Validity</div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">{getShipment?.storagePaidThroughDate || "N/A"}</div>
                    <div className="info2-desc">Terminal DO Validity</div>
                  </div>

                  <div className="info2">
                    <div className="info2-title">N/A</div>
                    <div className="info2-desc">Custom Release</div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <ConfirmationDialog
              isOpen={showConfirmation}
              handleClose={() => {
                setShowConfirmation(false);
              }}
              title={`Start tracking all Containers`}
              desc={[
                "All containers will be added to your shipment list.",
                "You would be charged 1 credit for each container.",
              ]}
              okayText="Confirm"
              cancelText="Cancel"
              onConfirm={() => {
                startTrackingAll(getShipment?.containers?.map((containerN) => containerN?.containerNumber));
              }}
            />
            <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
              <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
                <div className="events-title">List of Events</div>
              </AccordionSummary>
              <AccordionDetails>
                {searchType === "bill" ? (
                  <div className="unlock-all">
                    <div
                      className="pry-btn pry-blink"
                      style={{ marginTop: "0px !important" }}
                      onClick={() => {
                        // if (!showOnboarding) {
                        if (expired) {
                          hasExpiredAction();
                        } else {
                          setShowConfirmation(true);
                        }
                        // }
                      }}
                    >
                      Start Tracking All
                    </div>
                  </div>
                ) : null}
                <div className="timeline-wrapper">
                  {getShipment?.containers && getShipment?.containerNumber ? (
                    <Timeline
                      container={getShipment?.containers && getShipment?.containers[0]}
                      startTrackingItems={startTrackingItems}
                      {...{ hasExpiredAction, expired }}
                    />
                  ) : (
                    getShipment?.containers &&
                    getShipment?.containers.map((container) => (
                      <Timeline
                        key={Math.random()}
                        container={container}
                        bill={true}
                        startTrackingItems={startTrackingItems}
                        {...{ hasExpiredAction, expired }}
                      />
                    ))
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
            <div className="map-container">
              <div className="map-wrapper">{getShipment?.locations ? <Maps data={getShipment} /> : <Loader />}</div>

              <div className="download-report">
                <div className="pry-btn">Download Report</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const LargeTracking = ({
  getShipment,
  onClickContinue,
  startTrackingItems,
  startTrackingAll,
  getLocation,
  searchType,
  hasExpiredAction,
  expired,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const dashboardData = useSelector((state) => state.data.dashboardData);

  return (
    <div className="large-tracking" style={{ display: "none" }}>
      <ConfirmationDialog
        isOpen={showConfirmation}
        handleClose={() => {
          setShowConfirmation(false);
        }}
        title={`Start tracking all Containers`}
        desc={[
          "All containers will be added to your shipment list.",
          "You would be charged 1 credit for each container.",
        ]}
        okayText="Confirm"
        cancelText="Cancel"
        onConfirm={() => {
          startTrackingAll(getShipment?.containers?.map((containerN) => containerN?.containerNumber));
        }}
      />
      {getShipment && typeof getShipment === "object" && (
        <div className="tracking-main">
          <div className="info-map">
            <div className="search-container">
              <SearchBillContainer
                onClickContinueAdditionalFxns={onClickContinue}
                containerNo={getShipment?.containerNumber}
                billOfLading={getShipment?.billOfLadingNumber}
                shipingLine={getShipment?.shippingLine}
                presentRoute="tracking"
              />
            </div>
            <div className="info-row-one">
              {getShipment?.containerNumber ? (
                <div className="info1">
                  <div className="info1-title">
                    {getShipment?.containerNumber
                      ? getShipment?.containerNumber
                      : searchType?.toString()?.toLowerCase() === "trackingid"
                      ? "-"
                      : "N/A"}{" "}
                    {searchType?.toString()?.toLowerCase() === "trackingid"
                      ? `/ ${getShipment?.billOfLadingNumber || "-"}`
                      : ""}
                  </div>
                  <div
                    className="info1-desc"
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <span style={{ margin: ".25rem" }}>
                      {" "}
                      Container Number {searchType?.toString()?.toLowerCase() === "trackingid" ? "/ BL" : ""}
                    </span>
                    {getShipment?.trackingActive === false && getShipment?.trackingActive != null ? (
                      <span style={{ backgroundColor: "#e44", padding: ".2rem", margin: ".25", color: "#eee" }}>
                        Closed
                      </span>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="info1">
                  <div className="info1-title">{getShipment?.billOfLadingNumber || "N/A"}</div>
                  <div className="info1-desc">Bill of Lading</div>
                </div>
              )}

              <div className="info1">
                <div className="info1-title">
                  {shippingOptionsFullName(getShipment?.shippingLine)
                    ? shippingOptionsFullName(getShipment?.shippingLine)
                    : "N/A"}
                </div>
                <div className="info1-desc">Shipping Line</div>
              </div>
            </div>

            <div className="info-row-two">
              <div className="info2">
                <div className="info2-title">
                  {getShipment?.locations && getShipment?.portOfLoading?.location
                    ? `${getLocation(getShipment?.portOfLoading?.location)?.name}, ${
                        getLocation(getShipment?.portOfLoading?.location)?.country
                      }`
                    : "N/A www"}
                </div>
                <div className="info2-desc">Origin</div>
              </div>

              <div className="info2">
                <div className="info2-title">
                  {getShipment?.locations && getShipment?.portOfDischarge?.location
                    ? `${getLocation(getShipment?.portOfDischarge?.location)?.name}, ${
                        getLocation(getShipment?.portOfDischarge?.location)?.country
                      }`
                    : "N/A"}
                </div>
                <div className="info2-desc">Destination</div>
              </div>

              <div className="info2">
                <div className="info2-title">{getShipment.lastVessel || "N/A"}</div>
                <div className="info2-desc">Vessel</div>
              </div>
              <div className="info2">
                <div className="info2-title">
                  <small style={{ color: "#c17777" }}>
                    {searchType === "bill" ? "Check each container" : getShipment?.leadTime || 0}
                  </small>
                </div>
                <div className="info2-desc">Total Lead Time</div>
              </div>
              <div className="info2">
                <div className="info2-title">
                  {!getShipment?.containerType && !getShipment.isoCodeDescription && !getShipment?.containerSize
                    ? "N/A"
                    : `${getShipment?.containerSize ? getShipment?.containerSize : "-"} / ${
                        getShipment?.containerType ? getShipment?.containerType : ""
                      } / ${getShipment?.isoCodeDescription ? getShipment?.isoCodeDescription : ""}`}
                </div>
                <div className="info2-desc">Container Description</div>
              </div>
              <div className="info2">
                <div className="info2-title">
                  {getShipment?.facilityName
                    ? `${getShipment?.facilityName} - ${
                        getLocation(getShipment?.portOfDischarge?.location)?.Locode || ""
                      }`
                    : getShipment?.locations && getShipment?.portOfDischarge?.location
                    ? getShipment?.locations.map((location) =>
                        location?.id === getShipment?.portOfDischarge?.location
                          ? `${location?.name} - ${location?.Locode}`
                          : ""
                      )
                    : "N/A"}
                </div>
                <div className="info2-desc">Discharge Port</div>
              </div>

              <div className="info2">
                <div className="info2-title">
                  {getShipment?.portOfLoading?.date ? (
                    <div>{ParseServerDate(getShipment?.portOfLoading?.date, "DD/MM/YYYY")}</div>
                  ) : (
                    "N/A"
                  )}
                </div>
                <div className="info2-desc">Departure</div>
              </div>

              <div className="info2">
                <div className="info2-title">
                  {getShipment?.portOfDischarge?.date ? (
                    <div>{ParseServerDate(getShipment?.portOfDischarge?.date, "DD/MM/YYYY")}</div>
                  ) : (
                    "N/A"
                  )}
                </div>
                <div className="info2-desc">{getShipment?.portOfDischarge?.actual ? "Actual ETA" : "Declared ETA"}</div>
              </div>

              {/* <div className="info2">
                <div className="info2-title">
                  <small style={{ color: "#c17777" }}>coming soon</small>
                </div>
                <div className="info2-desc">Calculated ETA</div>
              </div> */}

              <div className="info2">
                <div className="info2-title">
                  {getShipment?.lastStage ? EVENT_STAGES[getShipment?.lastStage] : "N/A"}
                </div>
                <div className="info2-desc">Current Stage</div>
              </div>

              <div className="info2">
                <div className="info2-title">N/A</div>
                <div className="info2-desc">Shipping Line DO Validity</div>
              </div>

              <div className="info2">
                <div className="info2-title">N/A</div>
                {/* <div className="info2-title">{getShipment?.storagePaidThroughDate || "N/A"}</div> */}
                <div className="info2-desc">Terminal DO Validity</div>
              </div>

              <div className="info2">
                <div className="info2-title">N/A</div>
                <div className="info2-desc">Custom Release</div>
              </div>

              <div className="info2">
                {getShipment?.containerNumber && (
                  <>
                    <div className="info2-title">{getShipment?.lastEvent ? getShipment?.lastEvent : "N/A"}</div>
                    <div className="info2-desc">Last Event</div>
                  </>
                )}
              </div>
            </div>

            <div className="list-events">
              <div className="pry-btn">List of events</div>
            </div>

            <div className="map-container">
              <div className="map-wrapper">{getShipment?.locations ? <Maps data={getShipment} /> : <Loader />}</div>
            </div>
          </div>

          <div className="events">
            <div className="events-title">List of Events</div>

            {searchType === "bill" ? (
              <div className="unlock-all">
                <div
                  className="pry-btn pry-blink"
                  style={{ marginTop: "0px !important" }}
                  onClick={() => {
                    // if (!showOnboarding) {
                    if (expired) {
                      hasExpiredAction();
                    } else {
                      setShowConfirmation(true);
                    }
                    // }
                  }}
                >
                  Start Tracking All
                </div>
              </div>
            ) : null}

            <div className="timeline-wrapper">
              {getShipment?.containers && getShipment?.containerNumber ? (
                <Timeline
                  container={getShipment?.containers && getShipment?.containers[0]}
                  startTrackingItems={startTrackingItems}
                  {...{ hasExpiredAction, expired }}
                />
              ) : (
                getShipment?.containers &&
                getShipment?.containers.map((container) => (
                  <Timeline
                    key={Math.random()}
                    container={container}
                    bill={true}
                    startTrackingItems={startTrackingItems}
                    {...{ hasExpiredAction, expired }}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Timeline = ({ container, bill, startTrackingItems, hasExpiredAction, expired }) => {
  const [showOrigin, setShowOrigin] = useState(true);
  const [showOcean, setShowOcean] = useState(true);
  const [showDestination, setShowDestination] = useState(true);
  const [collapseContainer, setCollapseContainer] = useState(bill);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const dispatch = useDispatch();
  const getShipment = useSelector((state) => state.data.getShipment);
  const showOnboarding = useSelector((state) => state.user.userData.onboarding);

  const handleDynamicMapCenter = (id) => {
    if (getShipment?.locations.length > 0) {
      for (let i = 0; i < getShipment?.locations.length; i++) {
        if (id === getShipment?.locations[i]?.id) {
          dispatch(setDynamicMapCenter({ lat: getShipment?.locations[i]?.lat, lng: getShipment?.locations[i]?.lng }));
        }
      }
    }
  };

  return (
    <>
      {showOnboarding ? <OnboardingProcessTracking /> : null}

      <ConfirmationDialog
        isOpen={showConfirmation}
        handleClose={() => {
          setShowConfirmation(false);
        }}
        title={`Start tracking Container ${container?.containerNumber}`}
        desc={[
          "This container will be added to yor shipment list.",
          "You would be charged 1 credit for each container.",
        ]}
        okayText="Confirm"
        cancelText="Cancel"
        onConfirm={() => {
          startTrackingItems(container?.containerNumber);
        }}
      />
      {bill && (
        <div
          className="top-bar container"
          onClick={() => {
            setCollapseContainer(!collapseContainer);
          }}
        >
          <div className="">Container {container?.containerNumber}</div>
          <div className="toggle">{!collapseContainer ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
        </div>
      )}

      {!collapseContainer && (
        <div className={`timeline onboard-tracking ${showOnboarding ? "add-padding" : ""}`}>
          {!container?.isTrackedByUser && (
            <div className="unlock-all">
              <div
                className="pry-btn pry-blink"
                style={{ marginTop: "0px !important" }}
                onClick={() => {
                  if (!showOnboarding) {
                    if (expired) {
                      hasExpiredAction();
                    } else {
                      setShowConfirmation(true);
                    }
                  }
                }}
              >
                Start Tracking
              </div>
            </div>
          )}

          <div className="timeline-group">
            <div
              className="top-bar"
              onClick={() => {
                setShowOrigin(!showOrigin);
              }}
            >
              <div className="title">Origin</div>
              <div className="toggle">{showOrigin ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
            </div>
            {showOrigin && (
              <div className="timeline-content">
                <EventsTimeline
                  type="origin"
                  land={container?.originLand}
                  port={container?.originPort}
                  handleDynamicMapCenter={handleDynamicMapCenter}
                />
              </div>
            )}
          </div>

          <div className="timeline-group">
            <div
              className="top-bar"
              onClick={() => {
                setShowOcean(!showOcean);
              }}
            >
              <div className="title">Ocean</div>
              <div className="toggle">{showOcean ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
            </div>
            {showOcean && (
              <div className="timeline-content">
                <EventsTimeline type="ocean" ocean={container?.ocean} handleDynamicMapCenter={handleDynamicMapCenter} />
              </div>
            )}
          </div>

          {container?.isTrackedByUser && (
            <div className="timeline-group">
              <div
                className="top-bar"
                onClick={() => {
                  setShowDestination(!showDestination);
                }}
              >
                <div className="title">Destination</div>
                <div className="toggle">{showDestination ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
              </div>
              {showDestination && (
                <div className="timeline-content">
                  <EventsTimeline
                    type="destination"
                    land={container?.destinationLand}
                    port={container?.destinationPort}
                    handleDynamicMapCenter={handleDynamicMapCenter}
                  />
                </div>
              )}
            </div>
          )}

          {!container?.isTrackedByUser && (
            <div className="unlock-all">
              <div className="text121">To unlock all events of this container and live location!</div>
              <div
                className="pry-btn pry-blink"
                onClick={() => {
                  if (!showOnboarding) {
                    if (expired) {
                      hasExpiredAction();
                    } else {
                      setShowConfirmation(true);
                    }
                  }
                }}
              >
                Start Tracking
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

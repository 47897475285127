import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import { Input } from "@mui/base";
import Grid from "@mui/material/Grid";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

import { UPDATE_USER_DATA } from "../../../graphql/profile";
import { setUserData } from "./../../../redux/user/user.actions";
import { color, fontSize } from "../../../styles/constants";
import { Autocomplete, TextField } from "@mui/material";
import { useFormik } from "formik";
import MuiPhone from "../../../components/MuiPhone";
import { countries, findCountry } from "../../../utils/all-countries";
import { ALL_CURRENCIES, USAGE_INFO, findCompleteCurrency } from "../../../utils/data";

function UpdateProfile() {


  const userData = useSelector((state) => state.user.userData);
  // const name = userData?.name.split(" ");
  // const [user, setUser] = useState();
  const dispatch = useDispatch();

  const [updateUser, { loading }] = useMutation(UPDATE_USER_DATA, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data.updateUser) {
        const { name, telephone, companyName, usageInfo, country, localCurrency, dateTermsAccepted } = data.updateUser;
        const user = userData;
        user.name = name;
        user.telephone = telephone;
        user.companyName = companyName;
        user.usageInfo = usageInfo;
        user.country = country;
        user.localCurrency = localCurrency;
        user.dateTermsAccepted = dateTermsAccepted;

        dispatch(
          setUserData({
            ...user,
          })
        );

        toast.success("Profile was updated successfully.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      } else {
        toast.error("Profile connot be updated.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    },
    onError(error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
  });

  const { values, touched, errors, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      firstName: (userData?.name?.split(" ") || [""])[0],
      lastName: (userData?.name?.split(" ") || ["", ""])[1] || "",
      email: userData.email,
      telephone: userData?.telephone || "",
      country: userData?.country || "",
      localCurrency: userData?.localCurrency || "",
      companyName: userData?.companyName || "",
      usageInfo: userData?.usageInfo || "",
    },
    onSubmit: (values) => {
      const { firstName, lastName, telephone, companyName, usageInfo, country, localCurrency } = values;
      const user = {
        name: `${firstName} ${lastName}`,
        telephone,
        companyName,
        usageInfo,
        country,
        localCurrency,
      };
      updateUser({
        variables: {
          userUpdateRequest: { ...user },
        },
      });
    },
  });
  return (
    <Box>
      <CssBaseline />
      <Box>
        <Box component="form" className="form-settings" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Grid container spacing={2} py={1}>
            <Grid item xs={12} md={6}>
              <TextField
                name="firstName"
                label="First Name"
                // size="small"
                value={values.firstName}
                error={errors.firstName}
                helperText={errors.firstName || ""}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="lastName"
                label="Last Name"
                // size="small"
                value={values.lastName}
                error={errors.lastName}
                helperText={errors.lastName || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                label="Email"
                // size="small"
                required
                value={values.email}
                error={errors.email}
                helperText={errors.email || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MuiPhone
                label="Telephone"
                // size="small"
                value={values.telephone}
                onChange={(phone) => {
                  setFieldValue("telephone", phone);
                }}
                onBlur={handleBlur}
                error={errors.telephone}
                helperText={errors.telephone || ""}
                type="tel"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                // size="small"
                fullWidth
                getOptionLabel={(country) => country?.name || ""}
                options={countries}
                value={findCountry(values?.country) || ""}
                onChange={(_, v) => {
                  setFieldValue("country", v?.code || "");
                  if (!values?.localCurrency || v?.currency?.code) {
                    setFieldValue("localCurrency", v?.currency?.code || "");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="country"
                    label="Country"
                    required
                    error={errors.country}
                    helperText={errors.country || ""}
                    onBlur={handleBlur}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                // size="small"
                fullWidth
                getOptionLabel={(curr) => curr?.name || ""}
                options={ALL_CURRENCIES}
                value={findCompleteCurrency(values?.localCurrency) || ""}
                onChange={(_, v) => {
                  setFieldValue("localCurrency", v?.value || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="localCurrency"
                    label="Local Currency"
                    required
                    error={errors.localCurrency}
                    helperText={errors.localCurrency || ""}
                    onBlur={handleBlur}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="companyName"
                label="Company Name"
                // size="small"
                value={values.companyName}
                error={errors.companyName}
                helperText={errors.companyName || ""}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                // size="small"
                fullWidth
                getOptionLabel={(usage) => usage || ""}
                options={USAGE_INFO}
                value={values.usageInfo}
                onChange={(_, v) => {
                  setFieldValue("usageInfo", v || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="usageInfo"
                    label="Company Type"
                    required
                    error={errors.usageInfo}
                    helperText={errors.usageInfo || ""}
                    onBlur={handleBlur}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                disabled={!Object.keys(touched).length}
                sx={{
                  borderRadius: "8px",
                  padding: "15px 40px",
                  background: "#033B6C",
                  textAlign: "center",
                  color: "#FFFFFF",
                  display: "block",
                  textTransform: "Capitalize",
                  "&:hover": {
                    backgroundColor: "#033B6C",
                    opacity: 0.9,
                    boxShadow: "none",
                  },
                  "&:disabled": {
                    background: "#93a0ab",
                    color: "#FFFFFF",
                  },
                }}
              >
                {!loading ? "Update" : "Loading..."}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default UpdateProfile;

import React, { useState, useEffect, useMemo } from "react";
import { useSelector , useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  ButtonGroup,
  Portal,
  FormControlLabel,
  Radio,
  Typography,
  useTheme,
  RadioGroup,
  Backdrop,
} from "@mui/material";
import { usePaystackPayment } from "react-paystack";
import { toast } from "react-toastify";
import usePayment from "../../hooks/usePayment";
import SubscriptionContext from "../../contexts/SubscriptionContext";
import {
  SUPPORTED_LOCAL_PAYMENT,
  annualPlan,
  getDescriptions,
  getEnterpriceDescriptions,
  monthlyPlan,
} from "../../utils/data";
import { StripePaymentModal } from "../../components/Modals/StripePaymentModal";
import Logo from "../../assets/images/logo_name_1.png";
import SubscriptionCard from "../../components/SubscriptionCard";
import { MdArrowBackIos } from "react-icons/md";
import Loader from "../../components/Loader/Loader";
import { readableNumber } from "../../utils/helpers";
import { setShowNotification } from "../../redux/data/data.actions";

const analytics = window?.analytics;
const BuyCoins = () => {
  useEffect(() => {
    document.title = `aTrace - Visibility - Buy Coins`;
  }, []);

  const subscriptionInfo = useSelector((state) => state.data.subscriptionInfo);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { handleGetSubscriptionInfo, handleCreateStripeSubscriptionSession } = usePayment();
  const [confirmingPayment, setConfirmingPayment] = useState(false);
  const user = useSelector((state) => state.user.userData);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const subscriptionStatus = query.get("subcriptionStatus");
  const history = useHistory();
  const [frequency, setFrequency] = useState(query.get("oldFreq") || "annually");
  const [clientCode, setClientCode] = useState("");
  const [openStripe, setOpenStripe] = useState(false);
  const [selectedPlan, setPlan] = useState(frequency === "monthly" ? monthlyPlan[0] : annualPlan[0]);
  const [selectedCurrency, setSelectedCurrency] = useState(query.get("curr") || "");
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const PAYMENT_FREQUENCIES = [
    {
      value: "monthly",
      label: "Monthly",
      percentOff: 0,
    },
    {
      value: "annually",
      label: "Annually",
      percentOff: 0,
    },
  ];

  useEffect(() => {
    if (user) {
      analytics?.track("Viewed Subscription Page", {
        username: user?.email || "",
      });
    }
  }, [user]);

  useEffect(() => {
    if (!query.get("curr") || !selectedCurrency) {
      const currency = (
        SUPPORTED_LOCAL_PAYMENT.includes((user?.localCurrency || "").trim().toUpperCase())
          ? (user?.localCurrency || "").trim().toUpperCase()
          : "INT"
      )
        .trim()
        ?.toUpperCase();
      setSelectedCurrency(currency);
    }
  }, [user?.localCurrency]);

  const currencyVariant = (index) => {
    return index === selectedCurrency ? "contained" : "outlined";
  };

  const config = {
    publicKey: process.env.REACT_APP_PAYSTACK,
    reference: Date.now(),
    amount: selectedPlan.currencies[selectedCurrency]?.amount * 100,
    currency: selectedPlan.currencies[selectedCurrency]?.symbl,
    plan: selectedPlan.currencies[selectedCurrency]?.id,
    email: user.email,
    firstname: user.name,
    phone: user.telephone,
    account_id: user?.accountId || "",
    metadata: {
      email: user.email,
      phonenumber: user.telephone,
      name: user.name,
      account_id: user?.accountId || "",
      plan_code: selectedPlan.customId,
    },
    customizations: {
      title: "aTrace",
      description: "Supply chain visibility management",
      logo: Logo,
    },
  };

  const handlePaystackPayment = (() => {
    try {
      return usePaystackPayment(config);
    } catch (e) {
      console.error(e);
      setLoading("false");
      return () => alert("Payment not initialized");
    }
  })();

  const handleClose = () => {
    setOpenStripe(false);
    setLoading(false);
    setClientCode("");
  };

  const handlePayment = async (selectedPlan) => {
    if (selectedPlan.title === "Free") {
      return;
    }
    let symbol = selectedPlan.currencies[selectedCurrency]?.symbl?.trim()?.toUpperCase();
    let amount = selectedPlan.currencies[selectedCurrency]?.amount;
    let title = selectedPlan.title;
    config.amount = amount;

    if (symbol === "NGN") {
      return handlePaystackPayment(
        (reference) => {
          if (reference) {
            history?.push("/dashboard/subscription");
            toast.success("Your payment was successful. Your account will be credited soon.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
          } else {
            toast.error("Oops! Your payment could not be completed.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
          }
          setLoading(false);
        },
        () => setLoading(false)
      );
    } else if (symbol === "GBP" || symbol === "USD" || symbol === "EUR") {
      let sessionAddress = await handleCreateStripeSubscriptionSession(
        selectedPlan.customId,
        frequency === "monthly" ? "MONTHLY" : "YEARLY",
        symbol,
        `${process.env.REACT_APP_URL}/dashboard/subscription/subscribe?curr=${selectedCurrency}&oldFreq=${frequency}&title=${title}&subcriptionStatus=passed`,
        `${process.env.REACT_APP_URL}/dashboard/subscription/subscribe?curr=${selectedCurrency}&oldFreq=${frequency}&title=${title}&subcriptionStatus=failed`
      );
      setLoading(false);
      if (
        sessionAddress?.createStripeSubscriptionSession &&
        typeof sessionAddress?.createStripeSubscriptionSession === "string"
      ) {
        window.location.replace(sessionAddress?.createStripeSubscriptionSession);
      }
    } else {
      toast.error("Unsupported currency");
    }
  };

  useEffect(() => {
    if (subscriptionStatus) {
      if (subscriptionStatus === "passed") {
        analytics?.track("User Subscribed", {
          username: user?.email || "",
          plan: query.get("title") || "",
        });
        dispatch(
          setShowNotification({
            isOpen: true,
            severity: "success",
            title: "success",
            message: `Hooray 🎉! your ${frequency} subscription to the ${query.get(
              "title"
            )} plan was successful. your account will be updated so you can enjoy your unlocked features.`,
          })
        );
        history.push("/dashboard/subscription");
      } else if (subscriptionStatus === "failed") {
        analytics?.track("Subscription Failed", {
          username: user?.email || "",
          plan: query.get("title") || "",
        });
        dispatch(
          setShowNotification({
            isOpen: true,
            severity: "error",
            title: "error",
            message: `Yikes 😞, something went wrong while subscribing to the ${frequency} ${query.get(
              "title"
            )} plan. Try again later or contact support@atrace.co if it persists.`,
          })
        );
      }
    }
  }, [subscriptionStatus]);

  useEffect(() => {
    if (loading && config.plan && config.plan === selectedPlan.currencies[selectedCurrency]?.id) {
      handlePayment(selectedPlan);
    }
  }, [selectedPlan, loading, config]);

  useEffect(() => {
    if (!subscriptionInfo) {
      handleGetSubscriptionInfo();
    } else {
      setFrequency(subscriptionInfo?.billingPeriod === "MONTHLY" ? "monthly" : "annually");
      let planIndex = monthlyPlan.findIndex(
        (plan) => `${plan.title}`.trim().toLowerCase() === `${subscriptionInfo?.planName}`.trim().toLowerCase()
      );
      planIndex = planIndex < 0 ? 0 : planIndex;
      setPlan(subscriptionInfo?.billingPeriod === "MONTHLY" ? monthlyPlan[planIndex] : annualPlan[planIndex]);
      setLoadingPage(false);
    }
  }, [subscriptionInfo]);
  return loadingPage ? (
    <Backdrop open={loadingPage}>
      <Loader />
    </Backdrop>
  ) : (
    <SubscriptionContext.Provider value={{ plan: selectedPlan?.currencies?.[selectedCurrency] }}>
      <Grid
        sx={{ pb: { xs: "100px", md: 0 } }}
        container
        spacing={2}
        px={2}
        py={2}
        justifyContent="center"
        flexDirection="column"
      >
        <Grid item xs={12}>
          <RadioGroup
            value={frequency}
            onChange={(e) => {
              const value = e.target.value;
              setFrequency(value);
              setPlan(value === "monthly" ? monthlyPlan[0] : annualPlan[0]);
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 1,
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
          >
            {PAYMENT_FREQUENCIES.map((item) => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                disabled={loading || confirmingPayment}
                label={
                  <Typography variant="subtitle1">
                    {`${item.label} `}
                    {(item.percentOff && (
                      <Typography
                        component={"span"}
                        p={0.5}
                        display="inline-block"
                        fontSize={"0.8rem"}
                        borderRadius={"5px"}
                        mx={0.5}
                        textTransform="uppercase"
                        bgcolor={theme.palette.warning.main}
                        variant="subtitle2"
                        color="white"
                      >
                        {`${item.percentOff}% off`}
                      </Typography>
                    )) ||
                      ""}
                  </Typography>
                }
                sx={{
                  maxWidth: "250px",
                  textAlign: "center",
                  width: "80%",
                  borderRadius: "5px",
                  border: `solid 2px ${frequency == item.value ? theme.palette.primary.main : "#999"}`,
                  p: 0.5,
                }}
                textAlign={"center"}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <Box
                display="flex"
                sx={{
                  width: "100%",
                  px: 1,
                }}
              >
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                  {user.localCurrency === "NGN" && (
                    <Button
                      variant={currencyVariant("NGN")}
                      onClick={() => {
                        setSelectedCurrency("NGN");
                      }}
                      sx={{ borderRadius: 0 }}
                    >
                      NGN
                    </Button>
                  )}

                  {user.localCurrency === "EUR" && (
                    <Button
                      variant={currencyVariant("EUR")}
                      onClick={() => {
                        setSelectedCurrency("EUR");
                      }}
                      sx={{ borderRadius: 0 }}
                    >
                      EUR
                    </Button>
                  )}

                  {user.localCurrency === "GBP" && (
                    <Button
                      variant={currencyVariant("GBP")}
                      onClick={() => {
                        setSelectedCurrency("GBP");
                      }}
                      sx={{ borderRadius: 0 }}
                    >
                      GBP
                    </Button>
                  )}

                  <Button
                    variant={currencyVariant("INT")}
                    onClick={() => {
                      setSelectedCurrency("INT");
                    }}
                    sx={{ borderRadius: 0 }}
                  >
                    USD
                  </Button>
                </ButtonGroup>
              </Box>
            </Grid>
            <Grid item>
              <Button
                startIcon={<MdArrowBackIos />}
                variant={"text"}
                onClick={() => history.push("/dashboard/subscription")}
                sx={{ textTransform: "none", justifySelf: "right" }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            py={2}
            display="flex"
            sx={{
              justifyContent: { xs: "center", md: "space-between" },
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
            gap={2}
          >
            {(frequency === "monthly" ? monthlyPlan : frequency === "annually" ? annualPlan : []).map((item, i) => (
              <SubscriptionCard
                key={i}
                loading={loading || confirmingPayment}
                onClick={() => !loading && !confirmingPayment && setPlan(item)}
                name={item.title}
                desc={
                  `${item.title}`.trim()?.toLowerCase() === "enterprise"
                    ? getEnterpriceDescriptions(`${item.title}`.trim()?.toLowerCase())
                    : getDescriptions(`${item.title}`.trim()?.toLowerCase(), selectedCurrency)
                }
              handleSelect={
                  `${item.title}`.trim()?.toLowerCase() === "enterprise"
                    ? () => {
                        window.open("https://meetings.hubspot.com/hello-atrace/enterprise-service-request", "_blank");
                      }
                    : () => setLoading(true)
                }
                selected={item?.currencies[selectedCurrency]?.id === selectedPlan?.currencies[selectedCurrency]?.id}
                curr={
                  `${item.title}`.trim()?.toLowerCase() === "enterprise"
                    ? ""
                    : item?.currencies[selectedCurrency]?.symbol
                }
                price={
                  `${item.title}`.trim()?.toLowerCase() === "enterprise"
                    ? ""
                    : readableNumber(item?.currencies[selectedCurrency]?.amount || 0)
                }
                frequency={frequency}
                disabled={
                  `${item.title}`.trim()?.toLowerCase() === `${subscriptionInfo?.planName}`.trim()?.toLowerCase()
                }
                boldFirst={item.title !== "Free"}
                buttonText={`${item.title}`.trim()?.toLowerCase() === "enterprise" ? "Contact Sales" : "Subscribe"}
                featureList={item?.features || []}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
      <Portal>
        {openStripe && clientCode && (
          <StripePaymentModal open={open} handleClose={handleClose} clientCode={clientCode} />
        )}
      </Portal>
    </SubscriptionContext.Provider>
  );
};

export default BuyCoins;

import React from 'react';
import Shipments from './Shipments';

const ClosedShipments = () => {
  return (
    <Shipments title="aTrace - Visibility - closed shipments" q={{active:false}}/>
  );
};

export default ClosedShipments;

import React, { useState , useEffect } from "react";
import "./shipmenttable.scss";
import SortButton from "../SortButton";
import { FORMM_REPORT_TABE_PREFEENCE as FRTP, FORMM_REPORT_TABE_PREFEENCE_VERSION as FV } from "../../utils/data";
import { formattedNumber, formattedRate, fixDate, formattedMoney, getAmountInLocal } from "../../utils/helpers";
import CollapsibleRow from "../CollapsibleTable";
import {
  Grid,
  Table,
  Stack,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
} from "@mui/material";
import ColumnFilter from "../ColumnFilter/ColumnFilter";

const FormMTable = (props) => {
  const {
    data,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    changeSortOrder,
    sortOrder,
    sortValue,
    sortFieldName,
  } = props;

  const [columnPreferences, setColumnPreferences] = useState(() => {
    const storedPref = JSON.parse(localStorage.getItem("formMColumnPreferences"));
    return storedPref?.version == FV ? storedPref?.data : FRTP;
  });

  useEffect(() => {
    if (localStorage.getItem("formMColumnPreferences") !== JSON.stringify({ data: columnPreferences, version: FV })) {
      let version = JSON.parse(localStorage.getItem("formMColumnPreferences"))?.version;
      localStorage.setItem(
        "formMColumnPreferences",
        JSON.stringify({ data: version === FV ? columnPreferences : FRTP, version: FV })
      );
    }
  }, [columnPreferences]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <ColumnFilter preferences={columnPreferences} setPreferences={setColumnPreferences} />
          </Grid>
        </Grid>
        <TableContainer >
          <Table aria-label="customized table">
            <TableHead>
              <TableCell align="left">
                <div className="flex">
                  <span>Form&nbsp;M&nbsp;Number</span>
                  <SortButton
                    {...{
                      filterBy: ["formMNumber"],
                      sortValue,
                      sortOrder,
                      changeSortOrder,
                    }}
                  />
                </div>
              </TableCell>
              <>
                {columnPreferences?.map((pref, index) => (
                  <TableCell
                    key={`tableHead${index}`}
                    align="left"
                    className={` ${pref?.show ? "" : "hide-column"}`}
                    sx={{ width: "fit-content" }}
                  >
                    <div className="flex">
                      <span>{pref?.title?.replaceAll(" ", "\u00A0")}</span>
                      <SortButton {...{ filterBy: pref?.filterBy, sortValue, sortOrder, changeSortOrder }} />
                    </div>
                  </TableCell>
                ))}
              </>
              <TableCell align="right" className=""></TableCell>
            </TableHead>
            <TableBody>
              {data?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)?.map((row, i) => (
                <>
                  <CollapsibleRow
                    key={row?.id}
                    buttonPosition="end"
                    cellClass="stage"
                    row={[
                      <TableCell component="th" align="center" scope="row" key="1">
                        <div className="flex container">
                          <Stack>
                            <div className={sortValue == "containerNumber" ? "sorting" : ""}>
                              {row?.formMNumber || "NA"}
                              {"\n"}
                            </div>
                          </Stack>
                        </div>
                      </TableCell>,
                      <TableCell
                        key={2}
                        align="left"
                        className={`stage ${columnPreferences?.[0].show ? "" : "hide-column"}`}
                      >
                        {row?.formMValue ? formattedMoney(row?.formMValue) : "NA"}
                      </TableCell>,
                      <TableCell
                        key={3}
                        align="left"
                        className={`grey-text ${columnPreferences?.[1]?.show ? "" : "hide-column"}`}
                      >
                        {formattedNumber(row?.amountUsed) || "NA"}
                      </TableCell>,
                      <TableCell
                        key={4}
                        align="left"
                        className={`grey-text ${columnPreferences?.[2]?.show ? "" : "hide-column"}`}
                      >
                        <span color={row?.percentLeft < 0 ? "#cf2222" : "inherit"}>
                          {formattedRate(row?.percentLeft) || "NA"}
                        </span>
                      </TableCell>,
                      <TableCell
                        key={5}
                        className={`stage ${columnPreferences?.[3]?.show ? "" : "hide-column"}`}
                        align="left"
                      >
                        {fixDate(row?.formMApprovalDate) || "NA"}
                      </TableCell>,
                      <TableCell
                        className={`${columnPreferences?.[4]?.show ? "" : "hide-column"}`}
                        align="left"
                        key={6}
                      >
                        <Stack>
                          <div>
                            {row?.formMExpiryDate?.replaceAll(/([0-9]{4})-([0-9]{2})-([0-9]{2}).*/g, "$3/$2/$1") ||
                              "NA"}
                          </div>
                          <div
                            style={{
                              fontWeight: "500",
                              fontStyle: "italic",
                              color: row?.expiryDaysFromNow > 30 ? "#22cf22" : "#cf2222",
                            }}
                          >
                            <small>
                              {fixDate(row?.formMExpiryDate)
                                ? row?.expiryDaysFromNow == 0
                                  ? `expired today`
                                  : row?.expiryDaysFromNow < 0
                                  ? `expired ${Math.abs(row?.expiryDaysFromNow)} ${
                                      row?.expiryDaysFromNow == -1 ? "day" : "days"
                                    } ago`
                                  : `expires in ${row?.expiryDaysFromNow} ${
                                      row?.expiryDaysFromNow == 1 ? "day" : "days"
                                    }`
                                : ""}
                            </small>
                          </div>
                        </Stack>
                      </TableCell>,
                      <TableCell
                        className={`${columnPreferences?.[5]?.show ? "" : "hide-column"}`}
                        align="left"
                        key={7}
                      >
                        {row?.bankName || "NA"}
                      </TableCell>,
                      <TableCell
                        key={8}
                        align="left"
                        className={`grey-text ${columnPreferences?.[6]?.show ? "" : "hide-column"}`}
                      >
                        {row?.baNumber || "NA"}
                      </TableCell>,
                    ]}
                  >
                    <Table aria-label="customized table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <div className="flex">
                              <span>Bill&nbsp;of&nbsp;Lading</span>
                              <SortButton
                                {...{
                                  filterBy: ["billOfLadingNumber"],
                                  sortValue,
                                  sortOrder,
                                  changeSortOrder,
                                  base: [i, "billsOfLading"],
                                  filterField: `${i}billOfLading`,
                                  sortFieldName,
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="flex">
                              <span>Amount</span>
                              <SortButton
                                {...{
                                  filterBy: ["blValue"],
                                  sortValue,
                                  sortOrder,
                                  changeSortOrder,
                                  base: [i, "billsOfLading"],
                                  filterField: `${i}billOfLadingAmount`,
                                  sortFieldName,
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="flex">
                              <span>Form&nbsp;M&nbsp;%</span>
                              <SortButton
                                {...{
                                  filterBy: ["blValue"],
                                  sortValue,
                                  sortOrder,
                                  changeSortOrder,
                                  base: [i, "billsOfLading"],
                                  filterField: `${i}billOfLadingpercent`,
                                  sortFieldName,
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.billsOfLading?.map((blInfo) => (
                          <>
                            <TableRow>
                              <TableCell
                                key={2}
                                align="left"
                                className={` ${columnPreferences?.[0].show ? "" : "hide-column"}`}
                              >
                                {blInfo?.billOfLadingNumber ? blInfo?.billOfLadingNumber : "NA"}
                              </TableCell>
                              <TableCell
                                key={2}
                                align="left"
                                className={` ${columnPreferences?.[0].show ? "" : "hide-column"}`}
                              >
                                {blInfo?.blValue ? formattedMoney(blInfo?.blValue) : "NA"}
                              </TableCell>
                              <TableCell
                                key={2}
                                align="left"
                                className={` ${columnPreferences?.[0].show ? "" : "hide-column"}`}
                              >
                                {blInfo?.blValue
                                  ? formattedRate(getAmountInLocal(blInfo?.blValue) / getAmountInLocal(row?.formMValue))
                                  : "NA"}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </CollapsibleRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="view-more"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default FormMTable;

import { gql } from "@apollo/client";

export const USER_TRANSACTIONS = gql`
  query getUserTransactions($pageStuff: Page!, $trans: TransactionSearchRequest!) {
    getUserTransactions(page: $pageStuff, q: $trans) {
      ... on TransactionSearchResult {
        items {
          id
          reference
          narration
          balanceAfter
          transactionType
          createdAt
        }
        pageInfo {
          page
          size
          totalCount
          hasNextPage
          hasPreviousPage
        }
      }

      ... on ResponseError {
        message
        code
      }
    }
  }
`;

import React, { useState, useEffect } from "react";
import { useSelector , useDispatch } from "react-redux";
import ShipmentTable from "../../components/Tables/ShipmentTable";
import useTracking from "../../hooks/useTracking";
import Loader from "../../components/Loader/Loader";
import { TiWarningOutline } from "react-icons/ti";
import writeXlsxFile from "write-excel-file";
import { saveAs } from "file-saver";
import { MdDownload } from "react-icons/md";
import { Fab } from "@mui/material";
import { useHistory } from "react-router-dom";
import { AiOutlineReload } from "react-icons/ai";
import {
  ParseServerDate,
  addMoney,
  fixDate,
  formattedMoney,
  getAmountInForeign,
  getAmountInLocal,
  getNormalizedRate,
} from "../../utils/helpers";
import { setTablePreference } from "../../redux/data/data.actions";
import useCost from "../../hooks/useCost";
import useQuery from "../../hooks/useQuery";

export default function Shipments({ q = { active: true }, title = `aTrace - Visibility - Shipments` }) {
  const query = useQuery();
  const history = useHistory();
  const localCurrency = useSelector((state) => state.user?.userData.localCurrency);
  const getRateOrDefault = (money, def = 1) =>
    money?.currency && money?.currency !== localCurrency ? (money?.rate ? getNormalizedRate(money.rate) : def) : 1;
  const dispatch = useDispatch();
  const { handleGetTrackings, handleGetBill, handleGetAllDownloadableTrackings } = useTracking();
  const { handleGetAllUserInvoicesWithBL } = useCost();
  const [selectedShippingLine, setSelectedShippingLine] = useState(query.get("shippingLine") || "");
  const [selectedBill, setSelectedBill] = useState(query.get("bill") || null);
  const [selectedStage, setSelectedStage] = useState(query.get("stage") || null);
  const [selectedStatus, setSelectedStatus] = useState(query.get("status") || null);
  const [selectedAge, setSelectedAge] = useState(query.get("age") || "");
  const [selectedSign, setSelectedSign] = useState(query.get("sign") || 2);
  const [stagesTemp, setStagesTemp] = useState(null);
  const [providerTemp, setProviderTemp] = useState(null);
  const [sortValue, setSortValue] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [data, setData] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const [formM, setFormM] = useState(() => params.get("formm"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    if (event.target.value != tablePreference) {
      dispatch(setTablePreference(event.target.value));
    }
    setPage(0);
  };

  const stagesArray = [
    { label: "All Stages", value: "default" },
    { label: "Origin Land", value: 1 },
    { label: "Origin Port", value: 2 },
    { label: "Ocean", value: 3 },
    { label: "Destination Port", value: 4 },
    { label: "Destination Land", value: 5 },
  ];
  const getStage = (v) =>
    stagesArray.find((stage) => stage?.value == v || stage?.label?.toLocaleLowerCase() == `${v}`?.toLocaleLowerCase());
  const { handleGetETAStatusPrefences } = useTracking();
  const trackedShipments = useSelector((state) => state.data.trackedShipments);
  const downloadableTrackedShipments = useSelector((state) => state?.data?.downloadableTrackedShipments?.trackings);
  const downloadableInvoices = useSelector((state) => state?.data?.allUserDownloadInvoices);
  const tablePreference = useSelector((state) => state.data.tablePreference);

  const bill = useSelector((state) => state.data.bills);

  useEffect(() => {
    handleGetTrackings(
      {
        number: 1,
        size: -1,
      },
      q
    );
    handleGetBill(q?.active ? "opened" : "close");
    document.title = title;
  }, []);

  useEffect(() => {
    setRowsPerPage(tablePreference);
  }, [tablePreference]);

  useEffect(() => {
    if (downloadableTrackedShipments && downloadLoading) {
      if (downloadableTrackedShipments?.length > 0 && downloadableInvoices) {
        downloadShipmentExcel(downloadableTrackedShipments, downloadableInvoices);
      }
      setDownloadLoading(false);
    }
  }, [downloadableTrackedShipments, downloadableInvoices]);

  useEffect(() => {
    setData(trackedShipments?.items ? trackedShipments?.items : []);
  }, [trackedShipments]);


  useEffect(() => {
    filter({
      sign: selectedSign,
      age: selectedAge,
      bill: selectedBill,
      stage: selectedStage,
      shippingLine: selectedShippingLine,
      status: selectedStatus,
    });
  }, [selectedSign, selectedAge, selectedBill, selectedStage, selectedShippingLine, selectedStatus, trackedShipments]);

  const handleRefresh = () => {
    setSelectedAge("default");
    setSelectedStatus("default");
    setSelectedBill("default");
    setSelectedShippingLine("default");
    setSelectedSign(2);
    setSelectedStage("default");
    filter({}, true);
    setStagesTemp(null);
    setProviderTemp(null);
    history.replace({ search: "" });
  };

  const sortBy = (value, order = "asc", tdata) => {
    tdata = tdata ? tdata : data ? data : [];
    if (value) {
      if (order == "asc") {
        setData([
          ...tdata?.sort((b, a) => {
            if (typeof a[value] == "number") {
              return a[value] - b[value];
            } else {
              return a[value]?.localeCompare(b[value]);
            }
          }),
        ]);
      } else {
        setData([
          ...tdata?.sort((a, b) => {
            if (typeof a[value] == "number") {
              return a[value] - b[value];
            } else {
              return a[value]?.localeCompare(b[value]);
            }
          }),
        ]);
      }
    } else {
      setData(tdata);
    }
  };

  const changeSortOrder = (kind, order) => {
    if (order == "asc") {
      sortBy(kind, "asc");
      setSortOrder("desc");
    } else {
      sortBy(kind, "desc");
      setSortOrder("asc");
    }
    setSortValue(kind);
  };

  const filter = ({ sign, age, bill, stage, shippingLine, status }, reset, data) => {
    let internalData = [...(data || trackedShipments?.items || [])];
    if (!reset) {
      sign = sign || sign == 0 ? sign : selectedSign;
      age = age || selectedAge;
      bill = bill || selectedBill;
      stage = stage || selectedStage;
      shippingLine = shippingLine || selectedShippingLine;
      status = status || selectedStatus;
    }
    /*filter by sign */

    if ((sign && age && age !== "default") || (age && sign == 0 && age !== "default")) {
      switch (sign) {
        case 1:
          internalData = internalData.filter((item) => item?.ageing <= age);
          break;

        case 2:
          internalData = internalData.filter((item) => item?.ageing >= age);
          break;

        case 0:
          internalData = internalData.filter((item) => item?.ageing == age);
          break;

        default:
          break;
      }
    }

    /*filter by bill */
    if (bill && bill !== "default") {
      internalData = internalData.filter((item) => item?.billOfLadingNumber?.toLowerCase() == bill?.toLowerCase());
    }

    /*filter by status */
    if (status && status !== "default") {
      internalData = internalData.filter((item) => item?.status?.toLowerCase() == status?.toLowerCase());
    }

    /*filter by stage */
    if (stage && stage !== "default") {
      internalData = internalData.filter((item) => item?.lastStage?.toLowerCase() == stage?.toLowerCase());
    }

    /*filter by shipping Line */
    if (shippingLine && shippingLine !== "default") {
      internalData = internalData.filter((item) => item?.shippingLine?.toLowerCase() == shippingLine?.toLowerCase());
    }

    /*filter by formM query paramenter */
    if (formM) {
      internalData = internalData.filter((item) => item?.formMNumber?.toLowerCase() == formM?.toLowerCase());
    }

    if (!data) {
      setData(internalData);
      setPage(0);
    }
    return internalData;
  };

  const downloadShipmentExcelInit = () => {
    setDownloadLoading(true);
    handleGetAllUserInvoicesWithBL();
    handleGetETAStatusPrefences();
    handleGetAllDownloadableTrackings({ ...q });
  };

  const downloadShipmentExcel = async (data, invoices) => {
    data = filter(
      {},
      null,
      data?.map((item) => ({ ...item?.tracking, ...item?.leadTime }))
    );
    const bls = {};
    let i = 0;

    data.forEach((item) => {
      if (item?.billOfLadingNumber) {
        const {
          billOfLadingNumber,
          shippingLine,
          commodityDescription,
          blValue,
          formMNumber,
          formMValue,
          customsDutyExcludingVAT,
          vat,
          clearingMonth,
          nafdacCharges,
          quarantineCharges,
          sonCharges,
          levies,
          ciss,
          dutyExchangeRate,
          surcharge,
          lastEventTime,
          etls,
        } = item;

        bls[item?.billOfLadingNumber] = {
          i: bls[item?.billOfLadingNumber]?.i ? bls[item?.billOfLadingNumber]?.i : ++i,
          dutyExchangeRate,
          count: (bls[item?.billOfLadingNumber]?.count || 0) + 1,
          billOfLadingNumber,
          commodityDescription: bls[item?.billOfLadingNumber]?.commodityDescription || commodityDescription,
          shippingLine: bls[item?.billOfLadingNumber]?.shippingLine || shippingLine,
          blValue: bls[item?.billOfLadingNumber]?.blValue || blValue,
          formMNumber: bls[item?.billOfLadingNumber]?.formMNumber || formMNumber,
          formMValue: bls[item?.billOfLadingNumber]?.formMValue?.value
            ? bls[item?.billOfLadingNumber]?.formMValue
            : formMValue,
          customsDutyIncludingVAT: bls[item?.billOfLadingNumber]?.customsDutyIncludingVAT?.value
            ? bls[item?.billOfLadingNumber]?.customsDutyIncludingVAT
            : addMoney(customsDutyExcludingVAT, vat),
          nafdacCharges: bls[item?.billOfLadingNumber]?.nafdacCharges?.value
            ? bls[item?.billOfLadingNumber]?.nafdacCharges
            : nafdacCharges,
          quarantineCharges: bls[item?.billOfLadingNumber]?.quarantineCharges?.value
            ? bls[item?.billOfLadingNumber]?.quarantineCharges
            : quarantineCharges,
          sonCharges: bls[item?.billOfLadingNumber]?.sonCharges?.value
            ? bls[item?.billOfLadingNumber]?.sonCharges
            : sonCharges,
          levies: bls[item?.billOfLadingNumber]?.levies?.value ? bls[item?.billOfLadingNumber]?.levies : levies,
          ciss: bls[item?.billOfLadingNumber]?.ciss?.value ? bls[item?.billOfLadingNumber]?.ciss : ciss,
          surcharge: bls[item?.billOfLadingNumber]?.surcharge?.value
            ? bls[item?.billOfLadingNumber]?.surcharge
            : surcharge,
          etls: bls[item?.billOfLadingNumber]?.etls?.value ? bls[item?.billOfLadingNumber]?.etls : etls,
          clearingMonth: bls[item?.billOfLadingNumber]?.clearingMonth || clearingMonth,
          lastEventTime: bls[item?.billOfLadingNumber]?.lastEventTime || lastEventTime,
        };
      }
    });

    let totalCosts;
    let totalDuty;
    let totalClearing;
    Object.keys(bls).forEach((key) => {
      const {
        blValue,
        customsDutyIncludingVAT,
        nafdacCharges,
        quarantineCharges,
        sonCharges,
        levies,
        ciss,
        surcharge,
        etls,
      } = bls[key];

      totalDuty =
        getAmountInLocal(customsDutyIncludingVAT) +
        getAmountInLocal(levies) +
        getAmountInLocal(ciss) +
        getAmountInLocal(surcharge) +
        getAmountInLocal(etls);
      totalClearing =
        getAmountInLocal(nafdacCharges) + getAmountInLocal(quarantineCharges) + getAmountInLocal(sonCharges);
      totalCosts =
        getAmountInLocal(blValue) +
        getAmountInLocal(customsDutyIncludingVAT) +
        getAmountInLocal(nafdacCharges) +
        getAmountInLocal(quarantineCharges) +
        getAmountInLocal(sonCharges) +
        getAmountInLocal(levies) +
        getAmountInLocal(ciss) +
        getAmountInLocal(surcharge) +
        getAmountInLocal(etls);
      bls[key] = { ...bls[key], totalDuty, totalClearing, totalCosts };
    });
    let allInvoices = [];
    const groupInvoices = invoices || [];

    groupInvoices.forEach((item) => {
      let serviceShippingCost = 0;
      let serviceTerminalCost = 0;
      let serviceTransportCost = 0;
      let serviceClearingCost = 0;
      let serviceOtherCosts = 0;
      let totalCosts = 0;
      if (bls[item?.blNumber]) {
        item?.invoices?.forEach((invoice) => {
          if (invoice?.serviceType?.toLowerCase()?.trim() == "shipping line") {
            serviceShippingCost += getAmountInLocal(invoice?.invoiceValue);
          }
          if (invoice?.serviceType?.toLowerCase()?.trim() == "terminal") {
            serviceTerminalCost += getAmountInLocal(invoice?.invoiceValue);
          }
          if (invoice?.serviceType?.toLowerCase()?.trim() == "transporter") {
            serviceTransportCost += getAmountInLocal(invoice?.invoiceValue);
          }
          if (invoice?.serviceType?.toLowerCase()?.trim() == "clearing agency") {
            serviceClearingCost += getAmountInLocal(invoice?.invoiceValue);
          }
          if (invoice?.serviceType?.toLowerCase()?.trim() == "others") {
            serviceOtherCosts += getAmountInLocal(invoice?.invoiceValue);
          }
          totalCosts += getAmountInLocal(invoice?.invoiceValue);
          allInvoices.push({ ...invoice, billOfLadingNumber: item?.blNumber });
        });

        bls[item?.blNumber] = {
          ...bls[item?.blNumber],
          serviceShippingCost,
          serviceTerminalCost,
          serviceTransportCost,
          serviceClearingCost,
          serviceOtherCosts,
          totalCosts: bls[item?.blNumber]?.totalCosts + totalCosts,
        };
      }
    });

    const schema = [
      {
        column: "Bill of Lading",
        value: (row) => row?.billOfLadingNumber || "N/A",
        width: 15,
        type: String,
      },
      {
        column: "Container Number",
        value: (row) => row?.containerNumber || "N/A",
        width: 20,
        type: String,
      },
      {
        column: "Size",
        value: (row) => row?.containerSize || "N/A",
        width: 13,
        type: String,
      },
      {
        column: "Type",
        value: (row) => row?.containerType || "N/A",
        width: 13,
        type: String,
      },
      {
        column: "Commodity Description",
        value: (row) => row?.commodityDescription || "N/A",
        width: 25,
        type: String,
      },
      {
        column: "Reference Number",
        value: (row) => row?.externalReferenceNumber || "N/A",
        width: 18,
        type: String,
      },
      {
        column: "Commodity Code",
        value: (row) => row?.commodityCode || "N/A",
        width: 15,
        type: String,
      },
      {
        column: "Shipping Line",
        value: (row) => row?.shippingLine || "N/A",
        width: 13,
        type: String,
      },
      {
        column: "Clearing Agent",
        value: (row) => row?.clearingAgent || "N/A",
        width: 25,
        type: String,
      },
      {
        column: "Transporter",
        value: (row) => row?.transporter || "N/A",
        width: 25,
        type: String,
      },
      {
        column: "Stage",
        value: (row) => row?.lastStage || "N/A",
        width: 20,
        type: String,
      },
      {
        column: "Ageing",
        value: (row) => (row?.ageing && row?.ageing > 0 ? row?.ageing : 0),
        width: 7,
        type: Number,
      },
      {
        column: "Last Event",
        value: (row) => row?.lastEvent || "N/A",
        width: 30,
        type: String,
      },
      {
        column: "Last Event Date",
        value: (row) => (row?.lastEventTime ? ParseServerDate(row?.lastEventTime, "DD/MM/YYYY") : "N/A"),
        width: 15,
        type: String,
      },
      {
        column: "Goods Ready Days",
        value: (row) => parseInt(row?.goodsReadyDays) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Transit Days",
        value: (row) => parseInt(row?.transitDays) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Delivery Days",
        value: (row) => parseInt(row?.deliveryDays) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "Total Lead Time",
        value: (row) => parseInt(row?.total) || 0,
        width: 20,
        type: Number,
      },
      {
        column: "FCL",
        value: (row) => parseInt(row?.fcl) || 0,
        width: 25,
        type: Number,
      },
      {
        column: "FCL Type",
        value: (row) => parseInt(row?.fclType) || 0,
        width: 12,
        type: Number,
      },
      {
        column: "Customs Command",
        value: (row) => row?.customsCommand || "N/A",
        width: 15,
        type: String,
      },
      {
        column: "Shipment Advice Reception Date",
        value: (row) =>
          fixDate(row?.shipmentAdviceReceptionDate) ? new Date(fixDate(row?.shipmentAdviceReceptionDate)) : null,
        width: 25,
        format: "yyyy/mm/dd",
        type: Date,
      },

      {
        column: "Draft Document Reception Date",
        value: (row) =>
          fixDate(row?.draftDocumentsReceptionDate) ? new Date(fixDate(row?.draftDocumentsReceptionDate)) : null,
        width: 25,
        format: "yyyy/mm/dd",
        type: Date,
      },
      {
        column: "Original Document Reception Date",
        value: (row) =>
          fixDate(row?.originalDocumentsReceptionDate) ? new Date(fixDate(row?.originalDocumentsReceptionDate)) : null,
        width: 25,
        format: "yyyy/mm/dd",
        type: Date,
      },
      {
        column: "PAAR Submission Date",
        value: (row) => (fixDate(row?.paarSubmissionDate) ? new Date(fixDate(row?.paarSubmissionDate)) : null),
        width: 25,
        format: "yyyy/mm/dd",
        type: Date,
      },
      {
        column: "PAAR Copy Reception Date",
        value: (row) => (fixDate(row?.paarCopyReceptionDate) ? new Date(fixDate(row?.paarCopyReceptionDate)) : null),
        width: 25,
        format: "yyyy/mm/dd",
        type: Date,
      },
      {
        column: "SDG Number",
        value: (row) => row?.sgdNumber || "N/A",
        width: 18,
        type: String,
      },
      {
        column: "SDG Date",
        value: (row) => (fixDate(row?.sgdDate) ? new Date(fixDate(row?.sgdDate)) : null),
        width: 15,
        format: "yyyy/mm/dd",
        type: Date,
      },
      {
        column: "Assessment Reception Date",
        value: (row) => 
        fixDate(row?.assessmentReceptionDate) ? new Date(fixDate(row?.assessmentReceptionDate)) : null,
        width: 25,
        format: "yyyy/mm/dd",
        type: Date,
      },
      {
        column: "Duty Assessment Number",
        value: (row) => row?.dutyAssessmentNumber || "N/A",
        width: 15,
        type: String,
      },
      {
        column: "Invoice Number",
        value: (row) => row?.invoiceNumber || "N/A",
        width: 15,
        type: String,
      },
      {
        column: "Invoice Qty",
        value: (row) => parseInt(row?.invoiceQuantity) || 0,
        width: 10,
        type: Number,
      },
      {
        column: `BL Value `,
        value: (row) => formattedMoney(row?.blValue),
        width: 15,
        type: String,
      },
      {
        column: "Form M Number",
        value: (row) => row?.formMNumber || "N/A",
        width: 15,
        type: String,
      },

      {
        column: `Form M Value`,
        value: (row) => formattedMoney(row?.formMValue),
        width: 20,
        type: String,
      },
      {
        column: "Form M Approval Date ",
        value: (row) => (fixDate(row?.formMApprovalDate) ? new Date(fixDate(row?.formMApprovalDate)) : null),
        width: 20,
        format: "yyyy/mm/dd",
        type: Date,
      },
      {
        column: "Form M Expiry Date",
        value: (row) => (fixDate(row?.formMExpiryDate) ? new Date(fixDate(row?.formMExpiryDate)) : null),
        width: 20,
        format: "yyyy/mm/dd",
        type: Date,
      },
      {
        column: "BA Number",
        value: (row) => row?.baNumber || "N/A",
        width: 10,
        type: String,
      },
      {
        column: "Bank Name",
        value: (row) => row?.bankName || "N/A",
        width: 12,
        type: String,
      },
      {
        column: `Customs Duty excl VAT `,
        value: (row) => formattedMoney(row?.customsDutyExcludingVAT),
        width: 15,
        type: String,
      },
      {
        column: `VAT `,
        value: (row) => formattedMoney(row?.vat),
        width: 15,
        type: String,
      },
      {
        column: `Customs Duty incl VAT `,
        value: (row) => formattedMoney(addMoney(row?.customsDutyExcludingVAT, row?.vat)),
        width: 15,
        type: String,
      },
      {
        column: "Duty Rate",
        value: (row) => parseFloat(row?.dutyRate) / 100 || 0,
        width: 10,
        format: "0%",
        type: Number,
      },
      {
        column: "Duty Exchange Rate",
        value: (row) => parseFloat(row?.dutyExchangeRate) || 0,
        width: 10,
        type: Number,
      },
      {
        column: "Duty Payment Date",
        value: (row) => (fixDate(row?.dutyPaymentDate) ? new Date(fixDate(row?.dutyPaymentDate)) : null),
        width: 15,
        format: "yyyy/mm/dd",
        type: Date,
      },
      {
        column: "HSCODE",
        value: (row) => row?.hscode || "N/A",
        width: 10,
        type: String,
      },
      {
        column: `NAFDAC Charges `,
        value: (row) => formattedMoney(row?.nafdacCharges),
        width: 15,
        type: String,
      },
      {
        column: `Quarantine Charges `,
        value: (row) => formattedMoney(row?.quarantineCharges),
        width: 15,
        type: String,
      },
      {
        column: `SON Charges `,
        value: (row) => formattedMoney(row?.sonCharges),
        width: 15,
        type: String,
      },
      {
        column: `ETLS `,
        value: (row) => formattedMoney(row?.etls),
        width: 15,
        type: String,
      },
      {
        column: `Levies `,
        value: (row) => formattedMoney(row?.levies),
        width: 15,
        type: String,
      },
      {
        column: `CISS `,
        value: (row) => formattedMoney(row?.ciss),
        width: 15,
        type: String,
      },
      {
        column: `Surcharge `,
        value: (row) => formattedMoney(row?.surcharge),
        width: 15,
        type: String,
      },
      {
        column: "Tracking Status",
        value: (row) =>
          row?.active === false && row?.active != null
            ? "Closed"
            : row?.createStatus
            ? row.createStatus == "CREATED"
              ? "Tracked"
              : "In progress"
            : "N/A",
        width: 15,
        type: String,
      },
    ];

    const schemaCosts = [
      {
        column: "S/N",
        value: (row) => row?.i,
        width: 5,
        type: Number,
      },
      {
        column: "Bill of Lading",
        value: (row) => row?.billOfLadingNumber || "N/A",
        width: 15,
        type: String,
      },
      {
        column: "Commodity Description",
        value: (row) => row?.commodityDescription || "N/A",
        width: 15,
        type: String,
      },
      {
        column: "Container (count)",
        value: (row) => parseInt(row?.count) || 0,
        width: 15,
        type: Number,
      },
      {
        column: "Clearing Month",
        value: (row) =>
          fixDate(row?.clearingMonth)
            ? new Date(fixDate(row?.clearingMonth))
            : !q?.active && row?.lastEventTime
            ? new Date(fixDate(row?.lastEventTime))
            : null,
        width: 15,
        format: "mm/yyyy",
        type: Date,
      },
      {
        column: "Exchange Rate",
        value: (row) => getRateOrDefault(row?.blValue) || 0,
        width: 10,
        format: "#,##0.00",
        type: Number,
      },
      {
        column: `BL Value`,
        value: (row) => getAmountInForeign(row?.blValue),
        width: 15,
        format: "#,##0.00",
        type: Number,
      },
      {
        column: `Currency`,
        value: (row) => row?.blValue?.currency || "N/A",
        width: 5,
        type: String,
      },
      {
        column: `BL Value (${localCurrency})`,
        value: (row) => getAmountInLocal(row?.blValue, getRateOrDefault(row?.blValue)),
        width: 15,
        format: "#,##0.00",
        type: Number,
      },
      {
        column: `Terminal Charges (${localCurrency || "USD"})`,
        value: (row) => row?.serviceTerminalCost || 0,
        width: 20,
        format: "#,##0.00",
        type: Number,
      },
      {
        column: `Shipping Line Charges (${localCurrency || "USD"})`,
        value: (row) => row?.serviceShippingCost || 0,
        width: 20,
        format: "#,##0.00",
        type: Number,
      },
      {
        column: `Transport Charges (${localCurrency || "USD"})`,
        value: (row) => row?.serviceTransportCost || 0,
        width: 20,
        format: "#,##0.00",
        type: Number,
      },
      {
        column: `Clearing Agency Charges (${localCurrency || "USD"})`,
        value: (row) => row?.serviceClearingCost || 0,
        width: 25,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#E1BC29",
      },
      {
        column: `SON Charges (${localCurrency || "USD"})`,
        value: (row) => getAmountInLocal(row?.sonCharges, getRateOrDefault(row?.blValue)),
        width: 15,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#E1BC29",
      },
      {
        column: `NAFDAC Charges (${localCurrency || "USD"})`,
        value: (row) => getAmountInLocal(row?.nafdacCharges, getRateOrDefault(row?.blValue)),
        width: 15,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#E1BC29",
      },
      {
        column: `Quarantine Charges (${localCurrency || "USD"})`,
        value: (row) => getAmountInLocal(row?.quarantineCharges, getRateOrDefault(row?.blValue)),
        width: 15,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#E1BC29",
      },
      {
        column: `Duty Including VAT (${localCurrency || "USD"})`,
        value: (row) => getAmountInLocal(row?.customsDutyIncludingVAT, getRateOrDefault(row?.blValue)),
        width: 15,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#C6DABF",
      },
      {
        column: `Surcharges (${localCurrency || "USD"})`,
        value: (row) => getAmountInLocal(row?.surcharge, getRateOrDefault(row?.blValue)),
        width: 15,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#C6DABF",
      },
      {
        column: `CISS (${localCurrency || "USD"})`,
        value: (row) => getAmountInLocal(row?.ciss, getRateOrDefault(row?.blValue)),
        width: 15,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#C6DABF",
      },
      {
        column: `Levies (${localCurrency || "USD"})`,
        value: (row) => getAmountInLocal(row?.levies, getRateOrDefault(row?.blValue)),
        width: 15,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#C6DABF",
      },
      {
        column: `ETLS (${localCurrency || "USD"})`,
        value: (row) => getAmountInLocal(row?.etls, getRateOrDefault(row?.blValue)),
        width: 15,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#C6DABF",
      },
      {
        column: `Other costs (${localCurrency || "USD"})`,
        value: (row) => row?.serviceOtherCosts || 0,
        width: 25,
        format: "#,##0.00",
        type: Number,
      },
      {
        column: `Duty Total (${localCurrency || "USD"})`,
        value: (row) => row?.totalDuty || 0,
        width: 15,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#C6DABF",
      },
      {
        column: `Clearing Total (${localCurrency || "USD"})`,
        value: (row) => row?.totalClearing + row?.serviceClearingCost || 0,
        width: 25,
        format: "#,##0.00",
        type: Number,
        backgroundColor: "#E1BC29",
      },
      {
        column: `Grand Total (${localCurrency || "USD"})`,
        value: (row) => row?.totalCosts || 0,
        width: 15,
        format: "#,##0.00",
        type: Number,
      },
    ];

    const schemaServices = [
      {
        column: "Bill of Lading",
        value: (row) => row?.billOfLadingNumber || "N/A",
        width: 15,
        type: String,
      },
      {
        column: "Service Type",
        value: (row) => row?.serviceType || "N/A",
        width: 18,
        type: String,
      },
      {
        column: "Service Provider",
        value: (row) => row?.serviceProvider || 0,
        width: 18,
        type: String,
      },
      {
        column: "Description",
        value: (row) => row?.description || "N/A",
        width: 35,
        type: String,
      },
      {
        column: "Invoice Number",
        value: (row) => row?.invoiceNumber || "N/A",
        width: 25,
        type: String,
      },
      {
        column: "Currency",
        value: (row) => row?.invoiceValue?.currency || "",
        width: 25,
        type: String,
      },
      {
        column: "Exchange Rate",
        value: (row) => row?.invoiceValue?.rate || 1,
        width: 25,
        format: "#,##0.00",
        type: Number,
      },
      {
        column: "Invoice Value",
        value: (row) => getAmountInForeign(row?.invoiceValue),
        width: 25,
        format: "#,##0.00",
        type: Number,
      },
      {
        column: `Invoice Value (${localCurrency || "USD"})`,
        value: (row) => getAmountInLocal(row?.invoiceValue),
        width: 25,
        format: "#,##0.00",
        type: Number,
      },
      {
        column: `Invoice Value (${localCurrency || "USD"})`,
        value: (row) => row?.invoiceValue * (row?.rate || 1) || 0,
        width: 25,
        format: "#,##0.00",
        type: Number,
      },
    ];

    const costs = Object.values(bls || {})?.sort((a, b) => a.i - b.i);

    let result = await writeXlsxFile([data, costs, allInvoices], {
      schema: [schema, schemaCosts, schemaServices],
      sheets: ["Shipment", "Cost Analysis", "Services"],
    });
    saveAs(result, "shipment.xlsx");
  };

  return (
    <div className="mb-3">
      {trackedShipments === "loading" && <Loader />}

      {trackedShipments && trackedShipments !== "loading" && (
        <>
          <ShipmentTable
            {...{
              // stage
              stagesArray,
              getStage,
              selectedStage,
              setSelectedStage,

              // status
              selectedStatus,
              setSelectedStatus,

              // bill
              setSelectedBill,
              selectedBill,
              bill,

              // shippingLine
              setSelectedShippingLine,
              selectedShippingLine,

              //sign and age
              selectedSign,
              setSelectedSign,
              selectedAge,
              setSelectedAge,

              // Data and Filter
              data,
              filter,

              // sorting data
              changeSortOrder,
              sortOrder,
              sortValue,

              //Pagination data
              page,
              rowsPerPage,
              handleChangePage,
              handleChangeRowsPerPage,
            }}
          />
          <Fab
            color="primary"
            aria-label="add"
            disabled={downloadLoading || !data?.length}
            size="small"
            onClick={downloadShipmentExcelInit}
            sx={{
              position: "sticky",
              left: { xs: 10, md: 0 },
              bottom: { xs: 78, md: 0 },
              opacity: 0.85,
              "&:hover": {
                opacity: 0.95,
              },
            }}
          >
            <MdDownload />
            {data?.length}
          </Fab>
        </>
      )}

      {((trackedShipments !== "loading" && data && data.length === 0) || data === null) && (
        <div className="no-data">
          <div className="icon">
            <TiWarningOutline color="#fb8b23" style={{ margin: "10px auto" }} size="100" />
          </div>
          <div className="text">No shipment found!</div>
          <div
            className="refresh-button"
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "500",
              color: "#fb8b23",
              cursor: "pointer",
            }}
            onClick={handleRefresh}
          >
            <span style={{ margin: "auto 4px" }}>
              <AiOutlineReload color="#fb8b23" />
            </span>
            Refresh
          </div>
        </div>
      )}
    </div>
  );
}

//Notes: setPageFilter data is delayed and was replaced wit immedaite values but is subsequently updated

import { QuickActionsProps } from "@/types/chatbot";

export const QuickActions = ({ setCurrentMessage }: QuickActionsProps) => {
  const messages = [
    "Tell me all about IPAFFS?",
    "What is BTOM policy about?",
    "What are BCP posts?",
  ];

  return (
    <div className="text-center text-blue-100 flex flex-col justify-end pb-8 h-full">
      {messages.map((message, index) => (
        <button
          key={index}
          onClick={(e) => setCurrentMessage(e.currentTarget.innerText)}
          className="font-light text-blue-100 text-sm border border-blue-100 p-2 rounded-lg w-max mx-auto my-2 outline-none"
        >
          {message}
        </button>
      ))}
    </div>
  );
};

import { gql } from "@apollo/client";
const SHIPMENT_RESPONSE_DATA = `
  ... on ShipmentDetails {
    id
    billOfLadingNumber
    containerNumber
    shippingLine
    lastStage
    lastEvent
    lastVessel
    containerSize
    containerType
    isoCodeDescription
    facilityName
    # storagePaidThroughDate
    terminalID
    trackingActive
    leadTime
    locations {
      id
      lat
      lng
      name
      state
      country
      countryCode
      Locode
    }
    containers {
      containerNumber
      isTrackedByUser
      originLand {
        name
        date
        location
        description
        actual
        source
        kind
      }
      originPort {
        name
        date
        location
        description
        actual
        source
        kind
      }
      ocean {
        name
        date
        location
        description
        actual
        source
        kind
      }
      destinationPort {
        name
        date
        location
        description
        actual
        source
        kind
      }
      destinationLand {
        name
        date
        location
        description
        actual
        source
        kind
      }
    }
    placeOfDispatch {
      date
      actual
      location
    }
    portOfLoading {
      date
      actual
      location
    }
    portOfDischarge {
      date
      actual
      location
    }
    waypoints {
      pin {
        latitude
        longitude
      }
      path {
        latitude
        longitude
      }
    }
    placeOfDestination {
      date
      actual
      location
    }

  }
  ... on ResponseError {
    message
    code
  }
`;

export const GET_SHIPMENTS = gql`
  query getShipments($filters: FilterShipments!) {
    shipments(filters: $filters) {
      containerNumber
      shippingLine
      waypoints {
        pin {
          latitude
          longitude
        }
      }
    }
  }
`;

export const GET_SHIPMENT_BY_BILL_OF_LADING = gql`
  query getShipmentByBill($billNumber: String!, $shippingLine: String!) {
    getShipmentByBillOfLadingNo(billNumber: $billNumber, shippingLine: $shippingLine) {
      ${SHIPMENT_RESPONSE_DATA}
    }
  }
`;
export const GET_SHIPMENT_BY_CONTAINER = gql`
  query getShipmentByContainer($containerNumber: String!, $shippingLine: String!) {
    getShipmentByContainerNo(containerNumber: $containerNumber, shippingLine: $shippingLine) {
      ${SHIPMENT_RESPONSE_DATA}
    }
  }
`;

export const GET_SHIPMENT_BY_TRACKING_ID = gql`
  query getShipmentByTrackingId($trackingId: String!) {
    getShipmentByTrackingId(trackingId: $trackingId) {
      ${SHIPMENT_RESPONSE_DATA}
    }
  }
`;

import { gql } from "@apollo/client";

export const UPLOAD_DOCUMENT_LINK = gql`
  mutation prepareUpload($input: InputPrepareUpload!) {
    prepareUpload(input: $input) {
      url
      signedUrl
      headers {
        key
        value
      }
    }
  }
`;
export const CREATE_DOCUMENT = gql`
  mutation createDocument($input: InputCreateDocument!) {
    createDocument(input: $input) {
      title
      extension
      contentType
      url
      contentLength
      documentType
      parent
    }
  }
`;
export const GET_DOCUMENTS = gql`
  query document($filter: FilterDocuments!) {
    documents(filter: $filter) {
      documents {
        title
        extension
        contentType
        url
        contentLength
        parent
        documentType
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

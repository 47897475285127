// import dayjs from "dayjs";
// import axios from "axios";
// import { monthsShort } from "moment";
const dayjs = require("dayjs");
const { monthsShort } = require("moment");
//Dates
/**
 *
 * @param {*} date
 * @param {*} format
 * @returns
 */
export const ParseServerDate = function (date, format) {
  // example of server date :: '2021-05-16 15:40:00 +0000 UTC'
  if (!date) {
    return null;
  }
  let res = dayjs(date).format(format);
  return res;
};

export const addDate = function (date, value = 0, unit = "day") {
  // example of server date :: '2021-05-16 15:40:00 +0000 UTC'
  if (!date) {
    return null;
  }
  let res = formatDate(dayjs(date).add(value, unit));
  return res;
};

export const firstDayCurrentYear = () => {
  const year = new Date().getFullYear();
  return `${year}-01-01`;
};

export const convertToLocalTime = (date) => {
  let res = dayjs(date).format("DD/MM/YYYY, hh:mm A");
  return res;
};

export const formatDate = (date) => {
  return ParseServerDate(date, "DD/MM/YYYY");
};

export const formatDateF = (date, format) => {
  format = format.toLocaleUpperCase();
  return ParseServerDate(date, format);
};

export const fixDate = (date) => {
  if (date && new Date(date).getFullYear() > 1980) {
    return ParseServerDate(date, "YYYY-MM-DD");
  }
  return null;
};

//---Dates
export const fixPDFDate = (date) => {
  if (date) {
    return dayjs(date, "DD/MM/YYYY").format("YYYY-MM-DD");
  }
  return null;
};
const prepareNumber = (number) => {
  let num = parseFloat(`${number}`.replaceAll(/[, ]/g, ""));
  return num;
};

export const formattedNumber = (number, options = { maximumFractionDigits: 2 }) =>
  number
    ? prepareNumber(number)
        .toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          currencyDisplay: "symbol",
          maximumFractionDigits: options.maximumFractionDigits,
        })
        ?.replaceAll("$", "")
    : 0;

export const formattedRate = (num) => {
  if (!num && num !== 0) {
    return "";
  }
  num = parseFloat(num);

  if (typeof num === "number") {
    num = num.toFixed(2)?.toString();
  }
  let rate = num?.replaceAll(/%/g, "");
  return `${rate || 0}%`;
};

export const toTitleCase = (str) => {
  return str?.toLowerCase()?.replaceAll(/(^.| [a-z])/g, (txt) => `${txt.toUpperCase()}`);
};

export const a11yProps = (index, value, color) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: {
      color: index === value ? color.warning : color.default,
      fontSize: "16px",
      backgroundColor: "transparent",
      textTransform: "capitalize",
    },
  };
};

export const planProps = (id, compId, color) => {
  return {
    id: `plan-${id}`,
    style: {
      background: id === compId ? color.warning : "",
      color: id === compId ? "#FFFFFF" : "",
    },
  };
};

export const printNCharacters = (str, n) => {
  if (str?.length > n) {
    return `${str?.substring(0, n)}...`;
  }
  return str;
};

export const objectToStringCurrency = (obj) => {
  let str = "";
  Object.keys(obj).forEach((key) => {
    str += `${key} ${formattedNumber(obj[key])}; `;
  });
  return str?.slice(0, -2);
};

export const getNormalizedRate = (rate) => {
  let price = Math.floor(rate);
  return price > 0 ? price / 100 : 0;
};

export const patchRate = (rate) => {
  if (typeof rate !== "number") {
    rate = fixNumber(rate);
  }
  return rate * 100;
};

export const getAmountInLocal = (money, fallBack) => {
  let price = money?.value * (getNormalizedRate(money?.rate) || fallBack || 1);
  return Math.floor(price) >= 0 ? price / 100 : 0;
};

export const getAmountInForeign = (money) => {
  let price = Math.floor(money?.value * 1);
  return Math.floor(price) >= 0 ? price / 100 : 0;
};

export const fixNumber = (num) => {
  if (num) {
    return parseFloat(num?.toString()?.replaceAll(/[, ]/g, ""));
  }
  return 0;
};

export const fixMoneyUpload = (number) => Math.floor(fixNumber(number) * 100) || 0;

export const formattedMoney = (money) => {
  if (money) {
    return `${money?.currency || ""} ${formattedNumber(getAmountInForeign(money)) || 0}`;
  }
  return "";
};

export const toMoney = (number, currency = "USD", rate = 1) => {
  return {
    value: fixMoneyUpload(number),
    currency,
    rate: patchRate(rate),
  };
};

export const getMonthRange = (startDate = 0, endDate = Date.now()) => {
  let startyear = new Date(startDate).getFullYear();
  let startmonth = new Date(startDate).getMonth();
  let endyear = new Date(endDate).getFullYear();
  let endmonth = new Date(endDate).getMonth();
  const months = [];
  while ((startyear < endyear || (startyear === endyear && startmonth <= endmonth)) && months.length < 12) {
    months.push(monthsShort(startmonth));
    if (startmonth === 11) {
      startyear++;
      startmonth = 0;
    } else {
      startmonth++;
    }
  }
  return months;
};

export const bulletProofSum = (...nums) => nums.reduce((t, c) => (parseInt(c) || 0) + t);

export const bulletProofDifference = (a, b) => bulletProofSum(a, -1 * b);

export const stringToColour = (str) => {
  str = `${str}`;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) * (1 + ((hash << 3) - hash));
  }

  let b = ((hash & 255) % 95) + 128;
  let g = (((hash >> 8) & 255) % 95) + 128;
  let r = (((hash >> 16) & 255) % 95) + 128;
  return `rgb(${r},${g},${b})`;
};

export const addMoneySameCurrency = (moneyA, moneyB) => {
  return {
    ...moneyA,
    value: moneyA?.value + moneyB?.value,
  };
};

export const addMoney = (moneyA, moneyB) => {
  if (moneyA?.currency === moneyB?.currency) {
    return addMoneySameCurrency(moneyA, moneyB);
  }
  return {
    ...moneyA,
    value: moneyA?.value + (moneyB?.value * moneyB?.rate) / moneyA?.rate,
  };
};

export const getSlabText = (slab, currency) =>
  slab ? `${slab?.startDay || "-"} ➝ ${slab?.endDay || "-"} @ ${currency || "-"}${slab?.rate || "-"} per day` : "-";

export const readableNumber = (num) => Intl.NumberFormat("en-US").format(num);

export const a11yPropsPanel = (index, idPrefix = "panel-tab", panelIdPrefix = "pills-panel") => {
  return {
    id: `${idPrefix}-${index}`,
    "aria-controls": `${panelIdPrefix}-${index}`,
  };
};

export const fieldsArrayToObject = (fields) => {
  let obj = {};
  fields.forEach((field) => {
    obj[field?.key] = field?.value || field?.valueArray;
  });
  return obj;
};

export const stringToCurrency = (str = "NGN", rate = 1, curr = "NOK") => {
  str = str?.replaceAll(/[^0-9.a-zA-Z]/g, "");
  let currency = `${str?.match(/^[a-zA-Z]{3}/g)?.[0] || curr}`.toUpperCase() || "NGN";
  let value = str?.match(/[0-9.]+/g)?.[0];
  return { rate: rate * 100, currency, value: fixNumber(value) * 100 };
};

export const sanitizeContainerNumber = (inputString) => {
  const [firstPart] = inputString.trim().split(" ");
  return firstPart.slice(0, 4).replace(/0/g, "O") + firstPart.slice(4);
};
export const convertToSize = (size) => {
  let units = ["b", "Kb", "Mb", "Gb", "Tb", "Pb"];
  let ui = 0;
  while (size >= 1000 && ui < units.length) {
    size /= 1000;
    ui++;
  }
  return `${size.toFixed(2)}${units[ui]}`;
};
const DOCUMENT_TYPES_ENUM_MAP = {
  SERVICE_PROVIDER_INVOICE: "Service Provider Invoice",
  BILL_OF_LADING: "Bill of Lading",
  CUSTOMS_DECLARATION: "Customs Declaration",
  PACKING_LIST: "Packing List",
  COMMERCIAL_INVOICE: "Commercial Invoice",
  DELIVERY_NOTE: "Delivery Note",
};
export const cleanUpEnum = (word) => {
  return DOCUMENT_TYPES_ENUM_MAP[`${word || ""}`.trim()];
};

// currently unused
export const toEnum = (word) => {
  return `${word || ""}`.trim().toLocaleUpperCase().replace(/\W/g, "_");
};

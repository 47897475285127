import { CustomButtonProps } from "@/types/form";

const CustomButton = ({
  children,
  bgColor,
  textColor,
  borderColor,
  borderRadius,
  px,
  py,
  onClick,
  isDisabled,
}: CustomButtonProps) => {
  return (
    <div>
      <button
        onClick={onClick}
        disabled={isDisabled}
        type="submit"
        className={`${borderRadius || "rounded-lg"} w-full ${
          py || "py-2"
        } ${px || ""} ${textColor} ${bgColor} ${borderColor} ${
          isDisabled
            ? "opacity-80  cursor-not-allowed"
            : "opacity-100  cursor-pointer"
        }`}
      >
        {children}
      </button>
    </div>
  );
};

export { CustomButton };

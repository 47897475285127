import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { useIntercom } from "react-use-intercom";
import { MdMoreVert } from "react-icons/md";

import {
  DashboardIconMobile,
  ServicesIcon,
  TrackingIcon,
  ShippingIconMobile,
  SupportIcon,
  LogoutIcon,
  SettingsIcon,
  CostIcon,
} from "../SvgIcons";
import "./mobilenav.scss";
import { ClickAwayListener, Stack } from "@mui/material";

export default function MobileNav({ shipmentOpen, setShipmentOpen }) {
  const [menuModal, setMenuModal] = useState(false);
  const handleMenuModal = () => setMenuModal(!menuModal);
  const dispatch = useDispatch();
  const history = useHistory();
  // const { hardShutdown } = useIntercom();
  return (
    <div className="mobile-nav">
      <div className="mobile-bottom-nav w-full fixed left-0 bottom-0 z-30 bg-white-100 flex flex-col justify-center py-5 px-5">
        <div className="flex justify-between items-center">
          <NavLink to="/dashboard/overview" activeClassName="active-mobile">
            <button className="outline-none button-mobile">
              <Stack alignItems="center">
                <DashboardIconMobile color="#FB8B23" />
                <span className="tiny-text">Home</span>
              </Stack>
            </button>
          </NavLink>
          <ClickAwayListener
            onClickAway={() => {
              setShipmentOpen(false);
            }}
          >
            <div>
              <button
                className="outline-none button-mobile"
                onClick={() => {
                  setShipmentOpen(!shipmentOpen);
                }}
              >
                <Stack alignItems="center">
                  <ShippingIconMobile color="#9F9F9F" />
                  <span className="tiny-text">Shipment</span>
                </Stack>
              </button>
              <div
                className="absolute border-2 border-orange-600 bottom-[110%] py-4 px-4 bg-white-100 rounded-[4px] flex flex-col gap-4 transition-all delay-150 ease-in-out"
                style={{
                  transform: shipmentOpen ? "translateX(-30%" : "translateX(-400%)",
                }}
              >
                <NavLink to="/dashboard/shipments" activeClassName="active-mobile">
                  <button className="outline-none button-mobile" onClick={() => setShipmentOpen(false)}>
                    <span className="text-sm opacity-90" style={{ color: "#393939" }}>
                      Summary
                    </span>
                  </button>
                </NavLink>

                {/* <NavLink to="/dashboard/edit/shipment" activeClassName="active-mobile">
                <button className="outline-none button-mobile" onClick={() => setShipmentOpen(false)}>
                  <span className="text-sm opacity-90" style={{ color: "#393939" }}>
                    Edit
                  </span>
                </button>
              </NavLink> */}

                <NavLink to="/dashboard/closedshipments" activeClassName="active-mobile">
                  <button className="outline-none button-mobile" onClick={() => setShipmentOpen(false)}>
                    <span className="text-sm opacity-90" style={{ color: "#393939" }}>
                      Closed
                    </span>
                  </button>
                </NavLink>
              </div>
            </div>
          </ClickAwayListener>

          <NavLink to="/dashboard/tracking" activeClassName="active-mobile">
            <button className="outline-none button-mobile">
              <Stack alignItems="center">
                <TrackingIcon color="#9F9F9F" />
                <span className="tiny-text">Tracking</span>
              </Stack>
            </button>
          </NavLink>

          <NavLink to="/dashboard/costs" activeClassName="active-mobile">
            <button className="outline-none button-mobile">
              <Stack alignItems="center">
                <CostIcon color="#9F9F9F" />
                <span className="tiny-text">Costs</span>
              </Stack>
            </button>
          </NavLink>

          <NavLink to="/dashboard/subscription" activeClassName="active-mobile">
            <button className="outline-none button-mobile">
              <Stack alignItems="center">
                <ServicesIcon color="#9F9F9F" />
                <span className="tiny-text">Subscription</span>
              </Stack>
            </button>
          </NavLink>

          <ClickAwayListener
            onClickAway={() => {
              setMenuModal(false);
            }}
          >
            <div>
              <button
                className="outline-none button-mobile"
                onClick={(e) => {
                  handleMenuModal();
                }}
              >
                <Stack alignItems="center" className="more-menu">
                  <MdMoreVert color="#9F9F9F" size={24} />
                  <span className="tiny-text">More</span>
                </Stack>
              </button>

              <div
                className="absolute right-3 bottom-[110%] py-4 px-4 bg-white-100 rounded-[4px] flex flex-col gap-4 transition-all delay-150 ease-in-out"
                style={{
                  transform: menuModal ? "translateX(0)" : "translateX(1000%)",
                }}
              >
                <NavLink to="/dashboard/settings" activeClassName="active-mobile">
                  <button className="outline-none button-mobile" onClick={() => setMenuModal(false)}>
                    <SettingsIcon color="#393939" />
                  </button>
                </NavLink>

                <button className="outline-none button-mobile" onClick={() => setMenuModal(false)}>
                  <SupportIcon color="#393939" />
                </button>

                <button
                  className="outline-none button-mobile"
                  onClick={() => {
                    dispatch({ type: "DESTROY_SESSION" });
                    localStorage.clear();
                    sessionStorage.clear();
                    // hardShutdown();
                    history.push("/login");
                    setMenuModal(false);
                  }}
                >
                  <LogoutIcon color="#393939" />
                </button>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FcGoogle } from "react-icons/fc";
import FormHeader from "../../components/Form/FormHeader";
import classes from "../../styles/FormButton.module.css";
import FormInput from "../../components/Form/FormInput";
import Checkbox from "../../components/Checkbox/Checkbox";
import FormFooter from "../../components/Form/FormFooter";
import { setUserData, setPreviousLocation } from "./../../redux/user/user.actions";
import LogoWhite from "../../assets/images/logo_wide_name_white.png";
import AirSupport from "../../assets/images/air_support_1.png";
import Ship from "../..//assets/images/container_ship_1.png";
import "./auth.scss";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";

const Login = () => {
  document.title = `aTrace - Visibility - Login`;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const previousLocation = useSelector((state) => state?.user?.previousLocation);
  const { getAuthUser, getLoginByEmail } = useAuth();

  const handleGoogleAuth = async (res) => {
    const { data } = await getAuthUser(res.accessToken);
    const { user } = data?.authenticateWithGoogle;

    if (user) {
      history.push("/dashboard/overview");
    }
  };

  const loginHandler = async (email, password) => {
    setIsLoading(true);

    const { data } = await getLoginByEmail({
      email,
      password,
    });

    if (!data?.login?.user) {
      setIsLoading(false);
      return;
    }

    if (!data?.login?.user?.emailVerified) {
      return history.push("/verify-email?unverified=true&&userID=" + data?.login?.user?.id);
    }

    if (data?.login?.user) {
      toast.success("Login Successful");
      dispatch(
        setUserData({
          ...data?.login?.user,
          accessToken: data?.login?.token,
          refreshToken: data?.login?.refresh,
          onboardingComplete: data?.login?.user?.status === "OK",
        })
      );

      localStorage.setItem("onboardingComplete", data?.login?.user?.status === "OK");
      history.push("/dashboard/overview");

      //   if (previousLocation) {
      //     history.push(previousLocation);
      //     dispatch(setPreviousLocation(""));
      //   } else {
      //  history.push("/dashboard/overview");
      //   }
    }
    setIsLoading(false);
  };

  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  });

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          loginHandler(values.email, values.password);
        }}
      >
        {(formik) => (
          <div className="auth-page">
            {/* Left Container */}

            <div className="left">
              <div className="form-wrapper">
                <FormHeader title="Login" paragraph={<span>Welcome, let&#8217;s track your container</span>} />

                <Form className="form-content">
                  <FormInput label="Your Email" name="email" type="email" placeholder="Enter your email" />
                  <FormInput label="Password" name="password" type="password" placeholder="xxxxxxxxxxxxx" />

                  <div className="flex flex-row justify-between bottom-control">
                    <div className="remember">
                      <Checkbox name="rememberMe">
                        <span className="text">Remember me</span>
                      </Checkbox>
                    </div>

                    <div className="forgot">
                      <Link to="/forgot-password">Forgot Password? </Link>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className={`form-button ${!(formik.isValid && formik.dirty) ? "disabled" : ""}`}
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    {isLoading ? "Loading ..." : "Sign In"}
                  </button>

                  <div>
                    <p className={classes.side_dashes}>
                      <span className={classes.side_dashes_span} style={{ fontSize: "17px" }}>
                        or continue with
                      </span>
                    </p>
                  </div>

                  {/* Social Button */}
                  <div>
                    <GoogleLogin
                      clientId="435795694804-chfkcus37k2focsod0ktveidm135sebh.apps.googleusercontent.com"
                      onSuccess={handleGoogleAuth}
                      onFailure={(error) => console.error(error)}
                      cookiePolicy="single_host_origin"
                      render={(renderProps) => (
                        <button onClick={renderProps.onClick} className="google-button">
                          <FcGoogle className="icon" />
                          <span> Google</span>
                        </button>
                      )}
                    />
                  </div>
                </Form>

                {/* <div className="bottom medium:hidden pt-3 my-5 border-t-2 border-slate-400 flex justify-center text-[18px]">
                  <span className="text-dark pr-1">Don&#8217;t have an account?</span>
                  <Link to="/create-account">
                    <div className="text-red-400">Sign Up</div>
                  </Link>
                </div> */}
                <FormFooter parag={"Don't have an account?"} linkTitle="Sign Up" linkPath="/create-account" />
              </div>
              {/* Only for Smaller Screens */}
            </div>

            {/* Right Container */}
            <div className="right">
              <div className="top">
                <div className="img-wrapper">
                  <img src={LogoWhite} alt="background" />
                </div>
                <p className="desc text-[18px]">Supply chain visibility management</p>
              </div>

              <div className="middle">
                <div className="air">
                  <img src={AirSupport} alt="logo flight" />
                </div>

                <div className="ship">
                  <img src={Ship} alt="logo container" />
                </div>
              </div>

              {/* <div className="bottom flex justify-center text-[18px]">
                <span className="text-white-100 pr-1">Don&#8217;t have an account?</span>
                <Link to="/create-account">
                  <div className="text-red-400">Sign Up</div>
                </Link>
              </div> */}
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default Login;

import { Plan, PlanCurrencyType, PlanDescription, PlanType } from "@/types/plan";
import { ServiceProvider } from "@/types/trader";

export const processCardContent = [
  {
    num: "1",
    iconPath: "/assets/icons/login-icon.svg",
    content: "Sign up to create your shipment tracking order",
    numColor: "#086A3C",
    bgColor: "#D0FFE9",
  },
  {
    num: "2",
    iconPath: "/assets/icons/collaborate-icon.svg",
    content: "Invite your logistics company/importer for collaboration",
    numColor: "#7D240B",
    bgColor: "#FFD8CD",
  },
  {
    num: "3",
    iconPath: "/assets/icons/credit-card-icon.svg",
    content: "Buy credits to start sharing shipment location information",
    numColor: "#060B88",
    bgColor: "#CFD1FE",
  },
  {
    num: "4",
    iconPath: "/assets/icons/track-icon.svg",
    content: "Start tracking the shipments and the ETA",
    numColor: "#817400",
    bgColor: "#FEF9CF",
  },
];

export const partnersCard = [
  {
    name: "axon airlines",
    urlPath: "/assets/images/alitalia.png",
  },
  {
    name: "jetstar",
    urlPath: "/assets/images/qantas.png",
  },
  {
    name: "expedia",
    urlPath: "/assets/images/expedia.png",
  },
  {
    name: "alitalia",
    urlPath: "/assets/images/alitalia.png",
  },
  {
    name: "qantas",
    urlPath: "/assets/images/qantas.png",
  },
];

export const getDescriptions = (plan: PlanType, currency: string) => {
  const curr = getPaymentCurrency(`${currency}`?.trim()?.toUpperCase()) as PlanCurrencyType;
  const overageObj = PLAN_DESC_AND_OVERAGE[plan];
  return overageObj
    ? `${overageObj.desc}. ${overageObj.overage && overageObj?.rate?.[curr] ? `*Overage is allowed, T&C applies` : ""}`
    : ``;
};

export const getEnterpriceDescriptions = (plan: PlanType) => {
  const overageObj = PLAN_DESC_AND_OVERAGE[plan];
  return overageObj ? `${overageObj.desc}.` : ``;
};

export const FEATURE_LIST = {
  free: ["Dashboard", "Tracking", "Summary (active and closed)"],
  lite: ["All features in the Free plan", "Add Events", "Basic Reports"],
  standard: [
    "All features in the Lite plan",
    "Costs management",
    "Document Intelligence [Extract data from BLs and Commercial Invoices PDFs for instant data entry]",
    "Advance Reports [Govt Form M, Clearing Efficiency, Shipping Line Efficiency, etc]",
  ],
  enterprise: [
    "All features in the Standard plan",
    "AI actionable insights",
    "Automated Integration to Customs & Govt systems",
  ],
};

export const monthlyPlan: Plan[] = [
  {
    title: "Lite",
    desc: "Track 5 shipments monthly",
    customId: "PL-LITE",
    currencies: {
      NGN: {
        id: process.env.REACT_APP_MONTHLY_NGN_LITE || "",
        symbl: "NGN",
        symbol: "₦",
        amount: 20000,
      },
      GBP: {
        id: process.env.REACT_APP_MONTHLY_GBP_LITE || "",
        symbl: "GBP",
        symbol: "£",
        amount: 24,
      },
      EUR: {
        id: process.env.REACT_APP_MONTHLY_EUR_LITE || "",
        symbl: "EUR",
        symbol: "€",
        amount: 29,
      },
      INT: {
        id: process.env.REACT_APP_MONTHLY_USD_LITE || "",
        symbl: "USD",
        symbol: "$",
        amount: 30,
      },
    },

    features: FEATURE_LIST.lite,
  },
  {
    title: "Standard",
    desc: "Track 50 shipments monthly",
    customId: "PL-STANDARD",
    overage: 10,
    currencies: {
      NGN: {
        id: process.env.REACT_APP_MONTHLY_NGN_STANDARD || "",
        symbl: "NGN",
        symbol: "₦",
        amount: 179600,
      },
      EUR: {
        id: process.env.REACT_APP_MONTHLY_EUR_STANDARD || "",
        symbl: "EUR",
        symbol: "€",
        amount: 190,
      },
      GBP: {
        id: process.env.REACT_APP_MONTHLY_GBP_STANDARD || "",
        symbl: "GBP",
        symbol: "£",
        amount: 160,
      },
      INT: {
        id: process.env.REACT_APP_MONTHLY_USD_STANDARD || "",
        symbl: "USD",
        symbol: "$",
        amount: 200,
      },
    },
    features: FEATURE_LIST.standard,
  },
  {
    title: "Enterprise",
    desc: "Track Unlimited shipments monthly",
    info: "Contact Sales",
    customId: "PL-ENTERPRISE",
    overage: "unlimited",
    currencies: {
      NGN: {
        id: process.env.REACT_APP_ANNUAL_NGN_ENTERPRISE || "",
        symbl: "NGN",
        symbol: "₦",
        amount: 717312,
      },
      GBP: {
        id: process.env.REACT_APP_ANNUAL_USD_ENTERPRISE || "",
        symbl: "GBP",
        symbol: "£",
        amount: 632,
      },
      EUR: {
        id: process.env.REACT_APP_ANNUAL_USD_ENTERPRISE || "",
        symbl: "EUR",
        symbol: "€",
        amount: 728,
      },
      INT: {
        id: process.env.REACT_APP_ANNUAL_USD_ENTERPRISE || "",
        symbl: "USD",
        symbol: "$",
        amount: 800,
      },
    },
    features: FEATURE_LIST.enterprise,
  },
];

export const annualPlan: Plan[] = [
  {
    title: "Lite",
    desc: "Track 5 shipments monthly",
    customId: "PL-LITE",
    currencies: {
      NGN: {
        id: process.env.REACT_APP_ANNUAL_NGN_LITE || "",
        symbl: "NGN",
        symbol: "₦",
        amount: 220000,
      },
      EUR: {
        id: process.env.REACT_APP_ANNUAL_EUR_LITE || "",
        symbl: "EUR",
        symbol: "€",
        amount: 314,
      },
      GBP: {
        id: process.env.REACT_APP_ANNUAL_GBP_LITE || "",
        symbl: "GBP",
        symbol: "£",
        amount: 264,
      },
      INT: {
        id: process.env.REACT_APP_ANNUAL_USD_LITE || "",
        symbl: "USD",
        symbol: "$",
        amount: 330,
      },
    },
    features: FEATURE_LIST.lite,
  },
  {
    title: "Standard",
    desc: "Track 50 shipments monthly",
    customId: "PL-STANDARD",
    currencies: {
      NGN: {
        id: process.env.REACT_APP_ANNUAL_NGN_STANDARD || "",
        symbl: "NGN",
        symbol: "₦",
        amount: 2047440,
      },
      EUR: {
        id: process.env.REACT_APP_ANNUAL_EUR_STANDARD || "",
        symbl: "EUR",
        symbol: "€",
        amount: 2166,
      },
      GBP: {
        id: process.env.REACT_APP_ANNUAL_GBP_STANDARD || "",
        symbl: "GBP",
        symbol: "£",
        amount: 1824,
      },
      INT: {
        id: process.env.REACT_APP_ANNUAL_USD_STANDARD || "",
        symbl: "USD",
        symbol: "$",
        amount: 2280,
      },
    },

    features: FEATURE_LIST.standard,
  },
  {
    title: "Enterprise",
    desc: "Track Unlimited shipments monthly",
    info: "Contact Sales",
    customId: "PL_ENTERPRISE",
    currencies: {
      NGN: {
        id: process.env.REACT_APP_ANNUAL_NGN_ENTERPRISE || "",
        symbl: "NGN",
        symbol: "₦",
        amount: 8607744,
      },
      GBP: {
        id: process.env.REACT_APP_ANNUAL_USD_ENTERPRISE || "",
        symbl: "GBP",
        symbol: "£",
        amount: 7584,
      },
      EUR: {
        id: process.env.REACT_APP_ANNUAL_USD_ENTERPRISE || "",
        symbl: "EUR",
        symbol: "€",
        amount: 8736,
      },
      INT: {
        id: process.env.REACT_APP_ANNUAL_USD_ENTERPRISE || "",
        symbl: "USD",
        symbol: "$",
        amount: 9600,
      },
    },
    features: FEATURE_LIST.enterprise,
  },
];

export const PLAN_DESC_AND_OVERAGE: PlanDescription = {
  free: {
    desc: "Track 2 shipments monthly",
    overage: 0,
    rate: {
      NGN: 0,
      EUR: 0,
      GBP: 0,
      INT: 0,
    },
  },
  lite: {
    desc: "Track 5 shipments monthly",
    overage: 1,
    rate: {
      NGN: 4000,
      EUR: 5,
      GBP: 5,
      INT: 6,
    },
  },
  standard: {
    desc: "Track 50 shipments monthly",
    overage: 10,
    rate: {
      NGN: 3586.56,
      EUR: 3.64,
      GBP: 3.16,
      INT: 4,
    },
  },
  enterprise: {
    desc: "Track Unlimited shipments monthly",
    overage: "unlimited",
    rate: {
      NGN: 3586.56,
      EUR: 3.64,
      GBP: 3.16,
      INT: 4,
    },
  },
};

export const SUPPORTED_LOCAL_PAYMENT = ["NGN", "GBP", "EUR"];

export const getPaymentCurrency = (currency: string) => {
  return SUPPORTED_LOCAL_PAYMENT.includes(currency) ? currency : "INT";
};

export const containerNotFoundReasonsMapper = (reasons: string) => {
  const reason = `${reasons}`.trim()?.toLocaleUpperCase();
  let result = "";
  switch (reason) {
    case "API_KEY_EXPIRED":
    case "API_KEY_LIMIT_REACHED":
    case "SEALINE_UNDER_MAINTENANCE":
    case "SEALINE_NO_RESPONSE":
      result = "Sorry. We ran into a glitch on our end, we will resolve this and restore your service quickly.";
      break;
    case "WRONG_NUMBER":
      result = "This search returned unsuccessful. Please check if we’ve got the right Container number.";
      break;
    case "NO_CONTAINERS":
      result =
        "This search returned unsuccessful. Please check that the Shipping Line and BL number / Container numbers are still linked. You can attempt the search again with the Container number.";
      break;
    case "NO_EVENTS":
    case "SEALINE_HASNT_PROVIDE_INFO":
      result =
        "This search returned a “No Events” message on the container number(s). You can try again in a few hours.";
      break;
    default:
      result = reasons?.trim();
      break;
  }
  return result;
};

export const EVENT_STAGES = {
  0: "Unknown",
  1: "Origin Land",
  2: "Origin Port",
  3: "Ocean",
  4: "Destination Port",
  5: "Destination Land",
};
export const eventList = [
  { ID: 0, Description: "Unknown", Stage: "StageUnknown" },
  { ID: 1, Description: "Empty Container to shipper", Stage: "StageOriginLand" },
  { ID: 2, Description: "Full Container pickup at shipper", Stage: "StageOriginLand" },
  { ID: 3, Description: "Container dropped at first POL", Stage: "StageOriginLand" },
  { ID: 4, Description: "Container arrival at first POL (Gate in)", Stage: "StageOriginPort", Notification: true },
  { ID: 5, Description: "Container arrival at first POL by Barge", Stage: "StageOriginPort", Notification: true },
  { ID: 6, Description: "Container loaded at first POL (LOAD OUT)", Stage: "StageOriginPort", Notification: true },
  { ID: 7, Description: "Vessel departure from first POL", Stage: "StageOcean" },
  { ID: 8, Description: "Vessel departure from first POL", Stage: "StageOcean" },
  { ID: 9, Description: "Vessel arrival at T/S port", Stage: "StageOcean" },
  { ID: 10, Description: "Container loaded at T/S port", Stage: "StageOcean", Notification: true },
  { ID: 11, Description: "Transshipment Notification", Stage: "StageOcean" },
  { ID: 12, Description: "Container discharge at T/S port", Stage: "StageOcean", Notification: true },
  { ID: 13, Description: "Transshipment Delay", Stage: "StageOcean", Severity: "SeverityHigh", Notification: true },
  { ID: 14, Description: "Land / Barge Transfers", Stage: "StageOriginLand" },
  { ID: 15, Description: "Vessel departure from T/S", Stage: "StageOcean", Notification: true },
  { ID: 16, Description: "Vessel arrived at final POD", Stage: "StageOcean" },
  { ID: 17, Description: "Container discharge at final POD", Stage: "StageDestinationPort", Notification: true },
  { ID: 18, Description: "Container prepared for Customs inspection", Stage: "StageDestinationPort" },
  {
    ID: 19,
    Description: "Container release by Customs",
    Stage: "StageDestinationPort",
    Severity: "SeverityHigh",
    Notification: true,
  },
  {
    ID: 20,
    Description: "Container blocked by Customs",
    Stage: "StageDestinationPort",
    Severity: "SeverityHigh",
    Notification: true,
  },
  {
    ID: 21,
    Description: "Container unblocked by other Govt agency",
    Stage: "StageDestinationPort",
    Notification: true,
  },
  {
    ID: 22,
    Description: "Container departure from final POD (Gate out)",
    Stage: "StageDestinationLand",
    Notification: true,
  },
  {
    ID: 23,
    Description: "Container departure from final POD (Barge Out)",
    Stage: "StageDestinationLand",
    Notification: true,
  },
  {
    ID: 24,
    Description: "Container loaded on barge at POD",
    Stage: "StageDestinationLand",
    Comments: "to be captured from an interface developed for Barge operator",
    Notification: true,
  },
  {
    ID: 25,
    Description: "Barge location",
    Stage: "StageDestinationLand",
    Comments: "to be captured  from device on barge vessel",
  },
  {
    ID: 26,
    Description: "Container discharged from barge at destination",
    Stage: "StageDestinationLand",
    Comments: "to be captured from an interface developed for Barge operator",
    Notification: true,
  },
  {
    ID: 27,
    Description: "Container loaded on Truck to destination",
    Stage: "StageDestinationLand",
    Notification: true,
  },
  {
    ID: 28,
    Description: "Truck location",
    Stage: "StageDestinationLand",
    Comments: "to be captured  from device on truck/ from truck service provider",
  },
  {
    ID: 29,
    Description: "Container delivered to shipper",
    Stage: "StageDestinationLand",
    Comments: "to be captured  from device on truck/ from truck service provider",
  },
  { ID: 30, Description: "Empty container return to depot", Stage: "StageDestinationLand" },
  { ID: 31, Description: "Vessel route change", Stage: "StageDestinationLand", Severity: "SeverityHigh" },
  { ID: 32, Description: "BL change", Stage: "StageDestinationLand" },
  { ID: 33, Description: "ETA Update", Stage: "StageOcean" },
  { ID: 34, Description: "Vessel Docked ", Stage: "StageOcean" },
  { ID: 35, Description: "Vessel Undocked", Stage: "StageOcean" },
  { ID: 36, Description: "Vessel Arrival Risk", Stage: "StageOcean", Notification: true },
  { ID: 37, Description: "Terminal Storage Validity", Stage: "StageDestinationPort" },
  { ID: 38, Description: "Terminal Storage Updated", Stage: "StageDestinationPort" },
  { ID: 39, Description: "Container details updated", Stage: "StageDestinationPort" },
  { ID: 40, Description: "Container Operator Updated", Stage: "StageDestinationPort" },
  { ID: 41, Description: "Container entry from Barge", Stage: "StageDestinationPort" },
  { ID: 42, Description: "Yard Position Updated", Stage: "StageDestinationPort" },
  { ID: 43, Description: "Yard Internal movement", Stage: "StageDestinationPort" },
  { ID: 44, Description: "Container Inspection", Stage: "StageDestinationPort" },
  { ID: 45, Description: "Container State", Stage: "StageDestinationPort" },
  { ID: 46, Description: "Container Ready for Delivery", Stage: "StageDestinationPort", Notification: true },
  { ID: 47, Description: "Container Appointment", Stage: "StageDestinationPort" },
  { ID: 48, Description: "Shipping Line DO Validity", Stage: "StageDestinationPort" },
  { ID: 49, Description: "Invoice (Terminal/Delivery/Inspection charges)", Stage: "StageDestinationPort" },
  { ID: 50, Description: "Document updated", Stage: "StageDestinationPort" },
  { ID: 51, Description: "Seal updated", Stage: "StageDestinationPort" },
  { ID: 52, Description: "Container delivered on Truck", Stage: "StageDestinationPort" },
  { ID: 53, Description: "Container routing changed", Stage: "StageDestinationPort" },
  { ID: 54, Description: "Line Release - Import", Stage: "StageDestinationPort" },
  { ID: 55, Description: "Line permission - Import", Stage: "StageDestinationPort" },
  { ID: 56, Description: "Container arrival - Vessel", Stage: "StageDestinationPort" },
  { ID: 57, Description: "Invoice (Vessel/Lines billing)", Stage: "StageDestinationPort" },
  { ID: 58, Description: "Container entry from Gate", Stage: "StageDestinationPort" },
  { ID: 59, Description: "Container LOAD OUT - Vessel", Stage: "StageDestinationPort" },
  { ID: 60, Description: "Empty Container to shipper (Gate Out)", Stage: "StageDestinationPort" },
  { ID: 61, Description: "Customs Exit Release", Stage: "StageDestinationPort" },
  { ID: 62, Description: "Land / Barge Transfers", Stage: "StageOriginPort" },
  { ID: 63, Description: "Land / Barge Transshipment", Stage: "StageOcean" },
  { ID: 66, Description: "Container Arrival Notification cancelled", Stage: "StageOriginPort" },
];

export const eventStagesList = [
  {
    label: "Origin land",
    value: "StageOriginLand",
    events: [
      {
        ID: 1,
        Description: "Empty Container to shipper",
        Stage: "StageOriginLand",
      },
      {
        ID: 2,
        Description: "Full Container pickup at shipper",
        Stage: "StageOriginLand",
      },
      {
        ID: 3,
        Description: "Container dropped at first POL",
        Stage: "StageOriginLand",
      },
      {
        ID: 14,
        Description: "Land / Barge Transfers",
        Stage: "StageOriginLand",
      },
    ],
  },
  {
    label: "Origin port",
    value: "StageOriginPort",
    events: [
      {
        ID: 4,
        Description: "Container arrival at first POL (Gate in)",
        Stage: "StageOriginPort",
        Notification: true,
      },
      {
        ID: 5,
        Description: "Container arrival at first POL by Barge",
        Stage: "StageOriginPort",
        Notification: true,
      },
      {
        ID: 6,
        Description: "Container loaded at first POL (LOAD OUT)",
        Stage: "StageOriginPort",
        Notification: true,
      },
      {
        ID: 62,
        Description: "Land / Barge Transfers",
        Stage: "StageOriginPort",
      },
      {
        ID: 66,
        Description: "Container Arrival Notification cancelled",
        Stage: "StageOriginPort",
      },
    ],
  },
  {
    label: "Ocean",
    value: "StageOcean",
    events: [
      {
        ID: 7,
        Description: "Vessel departure from first POL",
        Stage: "StageOcean",
      },
      {
        ID: 8,
        Description: "Vessel departure from first POL",
        Stage: "StageOcean",
      },
      {
        ID: 9,
        Description: "Vessel arrival at T/S port",
        Stage: "StageOcean",
      },
      {
        ID: 10,
        Description: "Container loaded at T/S port",
        Stage: "StageOcean",
        Notification: true,
      },
      {
        ID: 11,
        Description: "Transshipment Notification",
        Stage: "StageOcean",
      },
      {
        ID: 12,
        Description: "Container discharge at T/S port",
        Stage: "StageOcean",
        Notification: true,
      },
      {
        ID: 13,
        Description: "Transshipment Delay",
        Stage: "StageOcean",
        Severity: "SeverityHigh",
        Notification: true,
      },
      {
        ID: 15,
        Description: "Vessel departure from T/S",
        Stage: "StageOcean",
        Notification: true,
      },
      {
        ID: 16,
        Description: "Vessel arrived at final POD",
        Stage: "StageOcean",
      },
      {
        ID: 33,
        Description: "ETA Update",
        Stage: "StageOcean",
      },
      {
        ID: 34,
        Description: "Vessel Docked ",
        Stage: "StageOcean",
      },
      {
        ID: 35,
        Description: "Vessel Undocked",
        Stage: "StageOcean",
      },
      {
        ID: 36,
        Description: "Vessel Arrival Risk",
        Stage: "StageOcean",
        Notification: true,
      },
      {
        ID: 63,
        Description: "Land / Barge Transshipment",
        Stage: "StageOcean",
      },
    ],
  },
  {
    label: "Destination port",
    value: "StageDestinationPort",
    events: [
      {
        ID: 17,
        Description: "Container discharge at final POD",
        Stage: "StageDestinationPort",
        Notification: true,
      },
      {
        ID: 18,
        Description: "Container prepared for Customs inspection",
        Stage: "StageDestinationPort",
      },
      {
        ID: 19,
        Description: "Container release by Customs",
        Stage: "StageDestinationPort",
        Severity: "SeverityHigh",
        Notification: true,
      },
      {
        ID: 20,
        Description: "Container blocked by Customs",
        Stage: "StageDestinationPort",
        Severity: "SeverityHigh",
        Notification: true,
      },
      {
        ID: 21,
        Description: "Container unblocked by other Govt agency",
        Stage: "StageDestinationPort",
        Notification: true,
      },
      {
        ID: 37,
        Description: "Terminal Storage Validity",
        Stage: "StageDestinationPort",
      },
      {
        ID: 38,
        Description: "Terminal Storage Updated",
        Stage: "StageDestinationPort",
      },
      {
        ID: 39,
        Description: "Container details updated",
        Stage: "StageDestinationPort",
      },
      {
        ID: 40,
        Description: "Container Operator Updated",
        Stage: "StageDestinationPort",
      },
      {
        ID: 41,
        Description: "Container entry from Barge",
        Stage: "StageDestinationPort",
      },
      {
        ID: 42,
        Description: "Yard Position Updated",
        Stage: "StageDestinationPort",
      },
      {
        ID: 43,
        Description: "Yard Internal movement",
        Stage: "StageDestinationPort",
      },
      {
        ID: 44,
        Description: "Container Inspection",
        Stage: "StageDestinationPort",
      },
      {
        ID: 45,
        Description: "Container State",
        Stage: "StageDestinationPort",
      },
      {
        ID: 46,
        Description: "Container Ready for Delivery",
        Stage: "StageDestinationPort",
        Notification: true,
      },
      {
        ID: 47,
        Description: "Container Appointment",
        Stage: "StageDestinationPort",
      },
      {
        ID: 48,
        Description: "Shipping Line DO Validity",
        Stage: "StageDestinationPort",
      },
      {
        ID: 49,
        Description: "Invoice (Terminal/Delivery/Inspection charges)",
        Stage: "StageDestinationPort",
      },
      {
        ID: 50,
        Description: "Document updated",
        Stage: "StageDestinationPort",
      },
      {
        ID: 51,
        Description: "Seal updated",
        Stage: "StageDestinationPort",
      },
      {
        ID: 52,
        Description: "Container delivered on Truck",
        Stage: "StageDestinationPort",
      },
      {
        ID: 53,
        Description: "Container routing changed",
        Stage: "StageDestinationPort",
      },
      {
        ID: 54,
        Description: "Line Release - Import",
        Stage: "StageDestinationPort",
      },
      {
        ID: 55,
        Description: "Line permission - Import",
        Stage: "StageDestinationPort",
      },
      {
        ID: 56,
        Description: "Container arrival - Vessel",
        Stage: "StageDestinationPort",
      },
      {
        ID: 57,
        Description: "Invoice (Vessel/Lines billing)",
        Stage: "StageDestinationPort",
      },
      {
        ID: 58,
        Description: "Container entry from Gate",
        Stage: "StageDestinationPort",
      },
      {
        ID: 59,
        Description: "Container LOAD OUT - Vessel",
        Stage: "StageDestinationPort",
      },
      {
        ID: 60,
        Description: "Empty Container to shipper (Gate Out)",
        Stage: "StageDestinationPort",
      },
      {
        ID: 61,
        Description: "Customs Exit Release",
        Stage: "StageDestinationPort",
      },
    ],
  },
  {
    label: "Destination land",
    value: "StageDestinationLand",
    events: [
      {
        ID: 22,
        Description: "Container departure from final POD (Gate out)",
        Stage: "StageDestinationLand",
        Notification: true,
      },
      {
        ID: 23,
        Description: "Container departure from final POD (Barge Out)",
        Stage: "StageDestinationLand",
        Notification: true,
      },
      {
        ID: 24,
        Description: "Container loaded on barge at POD",
        Stage: "StageDestinationLand",
        Comments: "to be captured from an interface developed for Barge operator",
        Notification: true,
      },
      {
        ID: 25,
        Description: "Barge location",
        Stage: "StageDestinationLand",
        Comments: "to be captured  from device on barge vessel",
      },
      {
        ID: 26,
        Description: "Container discharged from barge at destination",
        Stage: "StageDestinationLand",
        Comments: "to be captured from an interface developed for Barge operator",
        Notification: true,
      },
      {
        ID: 27,
        Description: "Container loaded on Truck to destination",
        Stage: "StageDestinationLand",
        Notification: true,
      },
      {
        ID: 28,
        Description: "Truck location",
        Stage: "StageDestinationLand",
        Comments: "to be captured  from device on truck/ from truck service provider",
      },
      {
        ID: 29,
        Description: "Container delivered to shipper",
        Stage: "StageDestinationLand",
        Comments: "to be captured  from device on truck/ from truck service provider",
      },
      {
        ID: 30,
        Description: "Empty container return to depot",
        Stage: "StageDestinationLand",
      },
      {
        ID: 31,
        Description: "Vessel route change",
        Stage: "StageDestinationLand",
        Severity: "SeverityHigh",
      },
      {
        ID: 32,
        Description: "BL change",
        Stage: "StageDestinationLand",
      },
    ],
  },
];
export const EXPIRED_AFTER_DAYS = 30;
export const OVER_EXPIRED_AFTER_DAYS = 60;

export const terminalOption = [
  {
    name: "APM Terminals Apapa",
    code: "NGAPPAPMTER",
  },
  {
    name: "Fivestar Logistics Terminal D",
    code: "NGTINFIVEST",
  },
  {
    name: "West Africa Container Terminal",
    code: "NGONNBURN",
  },
  {
    name: "ONNE MULTIPURPOSE TERMINAL",
    code: "NGONNOMT",
  },
  {
    name: "Ports and Cargo SIFAX Terminal C",
    code: "NGTINTCIPC",
  },
  {
    name: "PTML Grimaldi",
    code: "NGTINPTML",
  },
  {
    name: "Tin Can Island Container Terminal B",
    code: "NGTINTICT",
  },
  {
    name: "Josepdam Terminal A",
    code: "NGTINJOSEP",
  },
  {
    name: "PORTS AND TERMINAL OPERATORS Terminal A",
    code: "NGPHCAPMT",
  },
];

export const getTerminalOption = (code: string) => terminalOption?.find((t) => t.code === code || t.name === code);

export const currencies = [
  {
    value: "NGN",
    label: "₦",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "GBP",
    label: "£",
  },
];

export const findCurrency = (value: string) =>
  currencies.find((c) => (c.value || "").trim().toLocaleUpperCase() === (value || "").trim().toLocaleUpperCase());

export const ALL_CURRENCIES = [
  {
    value: "AED",
    label: "د.إ;",
    name: "UAE dirham",
  },
  {
    value: "AFN",
    label: "Afs",
    name: "Afghan afghani",
  },
  {
    value: "ALL",
    label: "L",
    name: "Albanian lek",
  },
  {
    value: "AMD",
    label: "AMD",
    name: "Armenian dram",
  },
  {
    value: "ANG",
    label: "NAƒ",
    name: "Netherlands Antillean gulden",
  },
  {
    value: "AOA",
    label: "Kz",
    name: "Angolan kwanza",
  },
  {
    value: "ARS",
    label: "$",
    name: "Argentine peso",
  },
  {
    value: "AUD",
    label: "$",
    name: "Australian dollar",
  },
  {
    value: "AWG",
    label: "ƒ",
    name: "Aruban florin",
  },
  {
    value: "AZN",
    label: "AZN",
    name: "Azerbaijani manat",
  },
  {
    value: "BAM",
    label: "KM",
    name: "Bosnia and Herzegovina konvertibilna marka",
  },
  {
    value: "BBD",
    label: "Bds$",
    name: "Barbadian dollar",
  },
  {
    value: "BDT",
    label: "৳",
    name: "Bangladeshi taka",
  },
  {
    value: "BGN",
    label: "BGN",
    name: "Bulgarian lev",
  },
  {
    value: "BHD",
    label: ".د.ب",
    name: "Bahraini dinar",
  },
  {
    value: "BIF",
    label: "FBu",
    name: "Burundi franc",
  },
  {
    value: "BMD",
    label: "BD$",
    name: "Bermudian dollar",
  },
  {
    value: "BND",
    label: "B$",
    name: "Brunei dollar",
  },
  {
    value: "BOB",
    label: "Bs.",
    name: "Bolivian boliviano",
  },
  {
    value: "BRL",
    label: "R$",
    name: "Brazilian real",
  },
  {
    value: "BSD",
    label: "B$",
    name: "Bahamian dollar",
  },
  {
    value: "BTN",
    label: "Nu.",
    name: "Bhutanese ngultrum",
  },
  {
    value: "BWP",
    label: "P",
    name: "Botswana pula",
  },
  {
    value: "BYR",
    label: "Br",
    name: "Belarusian ruble",
  },
  {
    value: "BZD",
    label: "BZ$",
    name: "Belize dollar",
  },
  {
    value: "CAD",
    label: "$",
    name: "Canadian dollar",
  },
  {
    value: "CDF",
    label: "F",
    name: "Congolese franc",
  },
  {
    value: "CHF",
    label: "Fr.",
    name: "Swiss franc",
  },
  {
    value: "CLP",
    label: "$",
    name: "Chilean peso",
  },
  {
    value: "CNY",
    label: "¥",
    name: "Chinese/Yuan renminbi",
  },
  {
    value: "COP",
    label: "Col$",
    name: "Colombian peso",
  },
  {
    value: "CRC",
    label: "₡",
    name: "Costa Rican colon",
  },
  {
    value: "CUC",
    label: "$",
    name: "Cuban peso",
  },
  {
    value: "CVE",
    label: "Esc",
    name: "Cape Verdean escudo",
  },
  {
    value: "CZK",
    label: "Kč",
    name: "Czech koruna",
  },
  {
    value: "DJF",
    label: "Fdj",
    name: "Djiboutian franc",
  },
  {
    value: "DKK",
    label: "Kr",
    name: "Danish krone",
  },
  {
    value: "DOP",
    label: "RD$",
    name: "Dominican peso",
  },
  {
    value: "DZD",
    label: "د.ج",
    name: "Algerian dinar",
  },
  {
    value: "EEK",
    label: "KR",
    name: "Estonian kroon",
  },
  {
    value: "EGP",
    label: "£",
    name: "Egyptian pound",
  },
  {
    value: "ERN",
    label: "Nfa",
    name: "Eritrean nakfa",
  },
  {
    value: "ETB",
    label: "Br",
    name: "Ethiopian birr",
  },
  {
    value: "EUR",
    label: "€",
    name: "European Euro",
  },
  {
    value: "FJD",
    label: "FJ$",
    name: "Fijian dollar",
  },
  {
    value: "FKP",
    label: "£",
    name: "Falkland Islands pound",
  },
  {
    value: "GBP",
    label: "£",
    name: "British pound",
  },
  {
    value: "GEL",
    label: "GEL",
    name: "Georgian lari",
  },
  {
    value: "GHS",
    label: "GH₵",
    name: "Ghanaian cedi",
  },
  {
    value: "GIP",
    label: "£",
    name: "Gibraltar pound",
  },
  {
    value: "GMD",
    label: "D",
    name: "Gambian dalasi",
  },
  {
    value: "GNF",
    label: "FG",
    name: "Guinean franc",
  },
  {
    value: "GQE",
    label: "CFA",
    name: "Central African CFA franc",
  },
  {
    value: "GTQ",
    label: "Q",
    name: "Guatemalan quetzal",
  },
  {
    value: "GYD",
    label: "GY$",
    name: "Guyanese dollar",
  },
  {
    value: "HKD",
    label: "HK$",
    name: "Hong Kong dollar",
  },
  {
    value: "HNL",
    label: "L",
    name: "Honduran lempira",
  },
  {
    value: "HRK",
    label: "kn",
    name: "Croatian kuna",
  },
  {
    value: "HTG",
    label: "G",
    name: "Haitian gourde",
  },
  {
    value: "HUF",
    label: "Ft",
    name: "Hungarian forint",
  },
  {
    value: "IDR",
    label: "Rp",
    name: "Indonesian rupiah",
  },
  {
    value: "ILS",
    label: "₪",
    name: "Israeli new sheqel",
  },
  {
    value: "INR",
    label: "₹",
    name: "Indian rupee",
  },
  {
    value: "IQD",
    label: "د.ع",
    name: "Iraqi dinar",
  },
  {
    value: "IRR",
    label: "IRR",
    name: "Iranian rial",
  },
  {
    value: "ISK",
    label: "kr",
    name: "Icelandic króna",
  },
  {
    value: "JMD",
    label: "J$",
    name: "Jamaican dollar",
  },
  {
    value: "JOD",
    label: "JOD",
    name: "Jordanian dinar",
  },
  {
    value: "JPY",
    label: "¥",
    name: "Japanese yen",
  },
  {
    value: "KES",
    label: "KSh",
    name: "Kenyan shilling",
  },
  {
    value: "KGS",
    label: "сом",
    name: "Kyrgyzstani som",
  },
  {
    value: "KHR",
    label: "៛",
    name: "Cambodian riel",
  },
  {
    value: "KMF",
    label: "KMF",
    name: "Comorian franc",
  },
  {
    value: "KPW",
    label: "W",
    name: "North Korean won",
  },
  {
    value: "KRW",
    label: "W",
    name: "South Korean won",
  },
  {
    value: "KWD",
    label: "KWD",
    name: "Kuwaiti dinar",
  },
  {
    value: "KYD",
    label: "KY$",
    name: "Cayman Islands dollar",
  },
  {
    value: "KZT",
    label: "T",
    name: "Kazakhstani tenge",
  },
  {
    value: "LAK",
    label: "KN",
    name: "Lao kip",
  },
  {
    value: "LBP",
    label: "£",
    name: "Lebanese lira",
  },
  {
    value: "LKR",
    label: "Rs",
    name: "Sri Lankan rupee",
  },
  {
    value: "LRD",
    label: "L$",
    name: "Liberian dollar",
  },
  {
    value: "LSL",
    label: "M",
    name: "Lesotho loti",
  },
  {
    value: "LTL",
    label: "Lt",
    name: "Lithuanian litas",
  },
  {
    value: "LVL",
    label: "Ls",
    name: "Latvian lats",
  },
  {
    value: "LYD",
    label: "LD",
    name: "Libyan dinar",
  },
  {
    value: "MAD",
    label: "MAD",
    name: "Moroccan dirham",
  },
  {
    value: "MDL",
    label: "MDL",
    name: "Moldovan leu",
  },
  {
    value: "MGA",
    label: "FMG",
    name: "Malagasy ariary",
  },
  {
    value: "MKD",
    label: "MKD",
    name: "Macedonian denar",
  },
  {
    value: "MMK",
    label: "K",
    name: "Myanma kyat",
  },
  {
    value: "MNT",
    label: "₮",
    name: "Mongolian tugrik",
  },
  {
    value: "MOP",
    label: "P",
    name: "Macanese pataca",
  },
  {
    value: "MRO",
    label: "UM",
    name: "Mauritanian ouguiya",
  },
  {
    value: "MUR",
    label: "Rs",
    name: "Mauritian rupee",
  },
  {
    value: "MVR",
    label: "Rf",
    name: "Maldivian rufiyaa",
  },
  {
    value: "MWK",
    label: "MK",
    name: "Malawian kwacha",
  },
  {
    value: "MXN",
    label: "$",
    name: "Mexican peso",
  },
  {
    value: "MYR",
    label: "RM",
    name: "Malaysian ringgit",
  },
  {
    value: "MZM",
    label: "MTn",
    name: "Mozambican metical",
  },
  {
    value: "NAD",
    label: "N$",
    name: "Namibian dollar",
  },
  {
    value: "NGN",
    label: "₦",
    name: "Nigerian naira",
  },
  {
    value: "NIO",
    label: "C$",
    name: "Nicaraguan córdoba",
  },
  {
    value: "NOK",
    label: "kr",
    name: "Norwegian krone",
  },
  {
    value: "NPR",
    label: "NRs",
    name: "Nepalese rupee",
  },
  {
    value: "NZD",
    label: "NZ$",
    name: "New Zealand dollar",
  },
  {
    value: "OMR",
    label: "OMR",
    name: "Omani rial",
  },
  {
    value: "PAB",
    label: "B./",
    name: "Panamanian balboa",
  },
  {
    value: "PEN",
    label: "S/.",
    name: "Peruvian nuevo sol",
  },
  {
    value: "PGK",
    label: "K",
    name: "Papua New Guinean kina",
  },
  {
    value: "PHP",
    label: "₱",
    name: "Philippine peso",
  },
  {
    value: "PKR",
    label: "Rs.",
    name: "Pakistani rupee",
  },
  {
    value: "PLN",
    label: "zł",
    name: "Polish zloty",
  },
  {
    value: "PYG",
    label: "₲",
    name: "Paraguayan guarani",
  },
  {
    value: "QAR",
    label: "QR",
    name: "Qatari riyal",
  },
  {
    value: "RON",
    label: "L",
    name: "Romanian leu",
  },
  {
    value: "RSD",
    label: "din.",
    name: "Serbian dinar",
  },
  {
    value: "RUB",
    label: "R",
    name: "Russian ruble",
  },
  {
    value: "SAR",
    label: "SR",
    name: "Saudi riyal",
  },
  {
    value: "SBD",
    label: "SI$",
    name: "Solomon Islands dollar",
  },
  {
    value: "SCR",
    label: "SR",
    name: "Seychellois rupee",
  },
  {
    value: "SDG",
    label: "SDG",
    name: "Sudanese pound",
  },
  {
    value: "SEK",
    label: "kr",
    name: "Swedish krona",
  },
  {
    value: "SGD",
    label: "S$",
    name: "Singapore dollar",
  },
  {
    value: "SHP",
    label: "£",
    name: "Saint Helena pound",
  },
  {
    value: "SLL",
    label: "Le",
    name: "Sierra Leonean leone",
  },
  {
    value: "SOS",
    label: "Sh.",
    name: "Somali shilling",
  },
  {
    value: "SRD",
    label: "$",
    name: "Surinamese dollar",
  },
  {
    value: "SYP",
    label: "LS",
    name: "Syrian pound",
  },
  {
    value: "SZL",
    label: "E",
    name: "Swazi lilangeni",
  },
  {
    value: "THB",
    label: "฿",
    name: "Thai baht",
  },
  {
    value: "TJS",
    label: "TJS",
    name: "Tajikistani somoni",
  },
  {
    value: "TMT",
    label: "m",
    name: "Turkmen manat",
  },
  {
    value: "TND",
    label: "DT",
    name: "Tunisian dinar",
  },
  {
    value: "TRY",
    label: "TRY",
    name: "Turkish new lira",
  },
  {
    value: "TTD",
    label: "TT$",
    name: "Trinidad and Tobago dollar",
  },
  {
    value: "TWD",
    label: "NT$",
    name: "New Taiwan dollar",
  },
  {
    value: "TZS",
    label: "TZS",
    name: "Tanzanian shilling",
  },
  {
    value: "UAH",
    label: "UAH",
    name: "Ukrainian hryvnia",
  },
  {
    value: "UGX",
    label: "USh",
    name: "Ugandan shilling",
  },
  {
    value: "USD",
    label: "US$",
    name: "United States dollar",
  },
  {
    value: "UYU",
    label: "$U",
    name: "Uruguayan peso",
  },
  {
    value: "UZS",
    label: "UZS",
    name: "Uzbekistani som",
  },
  {
    value: "VEB",
    label: "Bs",
    name: "Venezuelan bolivar",
  },
  {
    value: "VND",
    label: "₫",
    name: "Vietnamese dong",
  },
  {
    value: "VUV",
    label: "VT",
    name: "Vanuatu vatu",
  },
  {
    value: "WST",
    label: "WS$",
    name: "Samoan tala",
  },
  {
    value: "XAF",
    label: "CFA",
    name: "Central African CFA franc",
  },
  {
    value: "XCD",
    label: "EC$",
    name: "East Caribbean dollar",
  },
  {
    value: "XDR",
    label: "SDR",
    name: "Special Drawing Rights",
  },
  {
    value: "XOF",
    label: "CFA",
    name: "West African CFA franc",
  },
  {
    value: "XPF",
    label: "F",
    name: "CFP franc",
  },
  {
    value: "YER",
    label: "YER",
    name: "Yemeni rial",
  },
  {
    value: "ZAR",
    label: "R",
    name: "South African rand",
  },
  {
    value: "ZMK",
    label: "ZK",
    name: "Zambian kwacha",
  },
  {
    value: "ZWR",
    label: "Z$",
    name: "Zimbabwean dollar",
  },
];

export const USAGE_INFO = [
  "Shipper (Importer/Exporter)",
  "3PL/4PL",
  "Transporter",
  "Clearing / Forwarder Agent",
  "Regulator",
  "Barge Operator",
];

export const findCompleteCurrency = (value: string) =>
  ALL_CURRENCIES.find(
    (c) =>
      (c.value || "").trim().toLocaleUpperCase() === (value || "").trim().toLocaleUpperCase() ||
      (c.label || "").trim().toLocaleUpperCase() === (value || "").trim().toLocaleUpperCase() ||
      (c.name || "").trim().toLocaleUpperCase() === (value || "").trim().toLocaleUpperCase()
  ) || "";

export const SMALL_EFFICIENCY_TABLE_DEFAULT_DATA = [
  {
    title: "Container",
    fullName: "ContainerNumber",
    show: true,
    editable: false,
    filterBy: ["containerNumber"],
  },
  { title: "BL", fullName: "Bill of Lading", show: false, editable: true, filterBy: ["billOfLadingNumber"] },

  {
    title: "Cleared On",
    fullName: "Cleared On",
    show: true,
    editable: true,
    filterBy: ["clearingMonth"],
  },
  {
    title: "Goods Ready",
    fullName: "Goods Ready Days",
    show: false,
    editable: true,
    filterBy: ["goodsReadyDays"],
  },
  {
    title: "Origin Land",
    fullName: "Origin Land Days",
    show: false,
    editable: true,
    filterBy: ["ageOriginLand"],
  },
  {
    title: "Origin Port",
    fullName: "origin Port (Days)",
    show: false,
    editable: true,
    filterBy: ["ageOriginPort"],
  },
  {
    title: "Ocean",
    fullName: "Ocean (Days)",
    show: false,
    editable: true,
    filterBy: ["ageOcean"],
  },
  {
    title: "Destination Port",
    fullName: "Destination Port (Days)",
    show: false,
    editable: true,
    filterBy: ["ageDestinationPort"],
  },
  {
    title: "Destination Land",
    fullName: "Destination Land (Days)",
    show: false,
    editable: true,
    filterBy: ["ageDestinationLand"],
  },
  {
    title: "Clearing Days",
    fullName: "Total Clearing Days",
    show: true,
    editable: true,
    filterBy: ["totalClearingDays"],
  },
  {
    title: "Expected Clearing Days",
    fullName: "Expected Clearing Days",
    show: true,
    editable: true,
    filterBy: ["expectedClearingDays"],
  },
  {
    title: "Efficiency Score",
    fullName: "Efficiency Score",
    show: true,
    editable: true,
    filterBy: ["efficiencyScore"],
  },
];

export const EFFICIENCY_TABLE_DEFAULT_DATA = [
  { title: "Year", fullName: "Clearing Year", show: true, editable: false, filterBy: ["year"] },
  {
    title: "Month",
    fullName: "Clearing Month",
    show: true,
    editable: false,
    filterBy: ["month"],
  },
  { title: "№ of Containers", fullName: "Containers count", show: true, editable: true, filterBy: ["count"] },

  { title: "Clearing Days ", fullName: "Average Clearing Days", show: true, editable: true, filterBy: ["avgTCD"] },
  {
    title: "Efficiency Score",
    fullName: "Average Efficiency Score",
    show: true,
    editable: true,
    filterBy: ["avgTES"],
  },
];

export const FORMM_REPORT_TABE_PREFEENCE = [
  { title: "Value", show: true, editable: true, value: "", filterBy: ["convertedFormMVal"] },
  { title: "Amount Used", show: true, editable: true, filterBy: ["amountUsed"] },
  { title: "Percent Left", show: true, editable: true, filterBy: ["percentLeft"] },
  { title: "Approval Date", show: true, editable: true, filterBy: ["formMApprovalDate"] },
  { title: "Expiry Date", show: true, editable: true, filterBy: ["formMExpiryDate"] },
  { title: "Bank Name", show: false, editable: true, filterBy: ["bankName"] },
  { title: "Account Number", show: false, editable: true, filterBy: ["baNumber"] },
];

export const SMALL_EFFICIENCY_TABLE_DEFAULT_DATA_VERSION = 1;

export const EFFICIENCY_TABLE_DEFAULT_DATA_VERSION = 1;

export const FORMM_REPORT_TABE_PREFEENCE_VERSION = 2;

export const TIMEZONE_LOCATION: { [key: string]: string } = {
  Andorra: "AD",
  Dubai: "AE",
  Kabul: "AF",
  Tirane: "AL",
  Yerevan: "AM",
  Casey: "AQ",
  Davis: "AQ",
  Mawson: "AQ",
  Palmer: "AQ",
  Rothera: "AQ",
  Troll: "AQ",
  Vostok: "AQ",
  Buenos_Aires: "AR",
  Cordoba: "AR",
  Salta: "AR",
  Jujuy: "AR",
  Tucuman: "AR",
  Catamarca: "AR",
  La_Rioja: "AR",
  San_Juan: "AR",
  Mendoza: "AR",
  San_Luis: "AR",
  Rio_Gallegos: "AR",
  Ushuaia: "AR",
  Pago_Pago: "AS",
  Vienna: "AT",
  Lord_Howe: "AU",
  Macquarie: "AU",
  Hobart: "AU",
  Melbourne: "AU",
  Sydney: "AU",
  Broken_Hill: "AU",
  Brisbane: "AU",
  Lindeman: "AU",
  Adelaide: "AU",
  Darwin: "AU",
  Perth: "AU",
  Eucla: "AU",
  Baku: "AZ",
  Barbados: "BB",
  Dhaka: "BD",
  Brussels: "BE",
  Sofia: "BG",
  Bermuda: "BM",
  Brunei: "BN",
  La_Paz: "BO",
  Noronha: "BR",
  Belem: "BR",
  Fortaleza: "BR",
  Recife: "BR",
  Araguaina: "BR",
  Maceio: "BR",
  Bahia: "BR",
  Sao_Paulo: "BR",
  Campo_Grande: "BR",
  Cuiaba: "BR",
  Santarem: "BR",
  Porto_Velho: "BR",
  Boa_Vista: "BR",
  Manaus: "BR",
  Eirunepe: "BR",
  Rio_Branco: "BR",
  Thimphu: "BT",
  Minsk: "BY",
  Belize: "BZ",
  St_Johns: "CA",
  Halifax: "CA",
  Glace_Bay: "CA",
  Moncton: "CA",
  Goose_Bay: "CA",
  Toronto: "CA",
  Nipigon: "CA",
  Thunder_Bay: "CA",
  Iqaluit: "CA",
  Pangnirtung: "CA",
  Winnipeg: "CA",
  Rainy_River: "CA",
  Resolute: "CA",
  Rankin_Inlet: "CA",
  Regina: "CA",
  Swift_Current: "CA",
  Edmonton: "CA",
  Cambridge_Bay: "CA",
  Yellowknife: "CA",
  Inuvik: "CA",
  Dawson_Creek: "CA",
  Fort_Nelson: "CA",
  Whitehorse: "CA",
  Dawson: "CA",
  Vancouver: "CA",
  Cocos: "CC",
  Zurich: "CH",
  Abidjan: "CI",
  Rarotonga: "CK",
  Santiago: "CL",
  Punta_Arenas: "CL",
  Easter: "CL",
  Shanghai: "CN",
  Urumqi: "CN",
  Bogota: "CO",
  Costa_Rica: "CR",
  Havana: "CU",
  Cape_Verde: "CV",
  Christmas: "CX",
  Nicosia: "CY",
  Famagusta: "CY",
  Prague: "CZ",
  Berlin: "DE",
  Copenhagen: "DK",
  Santo_Domingo: "DO",
  Algiers: "DZ",
  Guayaquil: "EC",
  Galapagos: "EC",
  Tallinn: "EE",
  Cairo: "EG",
  El_Aaiun: "EH",
  Madrid: "ES",
  Ceuta: "ES",
  Canary: "ES",
  Helsinki: "FI",
  Fiji: "FJ",
  Stanley: "FK",
  Chuuk: "FM",
  Pohnpei: "FM",
  Kosrae: "FM",
  Faroe: "FO",
  Paris: "FR",
  London: "GB",
  Tbilisi: "GE",
  Cayenne: "GF",
  Gibraltar: "GI",
  Nuuk: "GL",
  Danmarkshavn: "GL",
  Scoresbysund: "GL",
  Thule: "GL",
  Athens: "GR",
  South_Georgia: "GS",
  Guatemala: "GT",
  Guam: "GU",
  Bissau: "GW",
  Guyana: "GY",
  Hong_Kong: "HK",
  Tegucigalpa: "HN",
  "Port-au-Prince": "HT",
  Budapest: "HU",
  Jakarta: "ID",
  Pontianak: "ID",
  Makassar: "ID",
  Jayapura: "ID",
  Dublin: "IE",
  Jerusalem: "IL",
  Kolkata: "IN",
  Calcutta: "IN",
  Chagos: "IO",
  Baghdad: "IQ",
  Tehran: "IR",
  Reykjavik: "IS",
  Rome: "IT",
  Jamaica: "JM",
  Amman: "JO",
  Tokyo: "JP",
  Nairobi: "KE",
  Bishkek: "KG",
  Tarawa: "KI",
  Kanton: "KI",
  Kiritimati: "KI",
  Pyongyang: "KP",
  Seoul: "KR",
  Almaty: "KZ",
  Qyzylorda: "KZ",
  Qostanay: "KZ",
  Aqtobe: "KZ",
  Aqtau: "KZ",
  Atyrau: "KZ",
  Oral: "KZ",
  Beirut: "LB",
  Colombo: "LK",
  Monrovia: "LR",
  Vilnius: "LT",
  Luxembourg: "LU",
  Riga: "LV",
  Tripoli: "LY",
  Casablanca: "MA",
  Monaco: "MC",
  Chisinau: "MD",
  Majuro: "MH",
  Kwajalein: "MH",
  Yangon: "MM",
  Ulaanbaatar: "MN",
  Hovd: "MN",
  Choibalsan: "MN",
  Macau: "MO",
  Martinique: "MQ",
  Malta: "MT",
  Mauritius: "MU",
  Maldives: "MV",
  Mexico_City: "MX",
  Cancun: "MX",
  Merida: "MX",
  Monterrey: "MX",
  Matamoros: "MX",
  Mazatlan: "MX",
  Chihuahua: "MX",
  Ojinaga: "MX",
  Hermosillo: "MX",
  Tijuana: "MX",
  Bahia_Banderas: "MX",
  Kuala_Lumpur: "MY",
  Kuching: "MY",
  Maputo: "MZ",
  Windhoek: "NA",
  Noumea: "NC",
  Norfolk: "NF",
  Lagos: "NG",
  Managua: "NI",
  Amsterdam: "NL",
  Oslo: "NO",
  Kathmandu: "NP",
  Nauru: "NR",
  Niue: "NU",
  Auckland: "NZ",
  Chatham: "NZ",
  Panama: "PA",
  Lima: "PE",
  Tahiti: "PF",
  Marquesas: "PF",
  Gambier: "PF",
  Port_Moresby: "PG",
  Bougainville: "PG",
  Manila: "PH",
  Karachi: "PK",
  Warsaw: "PL",
  Miquelon: "PM",
  Pitcairn: "PN",
  Puerto_Rico: "PR",
  Gaza: "PS",
  Hebron: "PS",
  Lisbon: "PT",
  Madeira: "PT",
  Azores: "PT",
  Palau: "PW",
  Asuncion: "PY",
  Qatar: "QA",
  Reunion: "RE",
  Bucharest: "RO",
  Belgrade: "RS",
  Kaliningrad: "RU",
  Moscow: "RU",
  Simferopol: "RU",
  Kirov: "RU",
  Volgograd: "RU",
  Astrakhan: "RU",
  Saratov: "RU",
  Ulyanovsk: "RU",
  Samara: "RU",
  Yekaterinburg: "RU",
  Omsk: "RU",
  Novosibirsk: "RU",
  Barnaul: "RU",
  Tomsk: "RU",
  Novokuznetsk: "RU",
  Krasnoyarsk: "RU",
  Irkutsk: "RU",
  Chita: "RU",
  Yakutsk: "RU",
  Khandyga: "RU",
  Vladivostok: "RU",
  "Ust-Nera": "RU",
  Magadan: "RU",
  Sakhalin: "RU",
  Srednekolymsk: "RU",
  Kamchatka: "RU",
  Anadyr: "RU",
  Riyadh: "SA",
  Guadalcanal: "SB",
  Mahe: "SC",
  Khartoum: "SD",
  Stockholm: "SE",
  Singapore: "SG",
  Paramaribo: "SR",
  Juba: "SS",
  Sao_Tome: "ST",
  El_Salvador: "SV",
  Damascus: "SY",
  Grand_Turk: "TC",
  Ndjamena: "TD",
  Kerguelen: "TF",
  Bangkok: "TH",
  Dushanbe: "TJ",
  Fakaofo: "TK",
  Dili: "TL",
  Ashgabat: "TM",
  Tunis: "TN",
  Tongatapu: "TO",
  Istanbul: "TR",
  Funafuti: "TV",
  Taipei: "TW",
  Kiev: "UA",
  Uzhgorod: "UA",
  Zaporozhye: "UA",
  Wake: "UM",
  New_York: "US",
  Detroit: "US",
  Louisville: "US",
  Monticello: "US",
  Indianapolis: "US",
  Vincennes: "US",
  Winamac: "US",
  Marengo: "US",
  Petersburg: "US",
  Vevay: "US",
  Chicago: "US",
  Tell_City: "US",
  Knox: "US",
  Menominee: "US",
  Center: "US",
  New_Salem: "US",
  Beulah: "US",
  Denver: "US",
  Boise: "US",
  Phoenix: "US",
  Los_Angeles: "US",
  Anchorage: "US",
  Juneau: "US",
  Sitka: "US",
  Metlakatla: "US",
  Yakutat: "US",
  Nome: "US",
  Adak: "US",
  Honolulu: "US",
  Montevideo: "UY",
  Samarkand: "UZ",
  Tashkent: "UZ",
  Caracas: "VE",
  Ho_Chi_Minh: "VN",
  Efate: "VU",
  Wallis: "WF",
  Apia: "WS",
  Johannesburg: "ZA",
  Antigua: "AG",
  Anguilla: "AI",
  Luanda: "AO",
  McMurdo: "AQ",
  DumontDUrville: "AQ",
  Syowa: "AQ",
  Aruba: "AW",
  Mariehamn: "AX",
  Sarajevo: "BA",
  Ouagadougou: "BF",
  Bahrain: "BH",
  Bujumbura: "BI",
  "Porto-Novo": "BJ",
  St_Barthelemy: "BL",
  Kralendijk: "BQ",
  Nassau: "BS",
  Gaborone: "BW",
  "Blanc-Sablon": "CA",
  Atikokan: "CA",
  Creston: "CA",
  Kinshasa: "CD",
  Lubumbashi: "CD",
  Bangui: "CF",
  Brazzaville: "CG",
  Douala: "CM",
  Curacao: "CW",
  Busingen: "DE",
  Djibouti: "DJ",
  Dominica: "DM",
  Asmara: "ER",
  Addis_Ababa: "ET",
  Libreville: "GA",
  Grenada: "GD",
  Guernsey: "GG",
  Accra: "GH",
  Banjul: "GM",
  Conakry: "GN",
  Guadeloupe: "GP",
  Malabo: "GQ",
  Zagreb: "HR",
  Isle_of_Man: "IM",
  Jersey: "JE",
  Phnom_Penh: "KH",
  Comoro: "KM",
  St_Kitts: "KN",
  Kuwait: "KW",
  Cayman: "KY",
  Vientiane: "LA",
  St_Lucia: "LC",
  Vaduz: "LI",
  Maseru: "LS",
  Podgorica: "ME",
  Marigot: "MF",
  Antananarivo: "MG",
  Skopje: "MK",
  Bamako: "ML",
  Saipan: "MP",
  Nouakchott: "MR",
  Montserrat: "MS",
  Blantyre: "MW",
  Niamey: "NE",
  Muscat: "OM",
  Kigali: "RW",
  St_Helena: "SH",
  Ljubljana: "SI",
  Longyearbyen: "SJ",
  Bratislava: "SK",
  Freetown: "SL",
  San_Marino: "SM",
  Dakar: "SN",
  Mogadishu: "SO",
  Lower_Princes: "SX",
  Mbabane: "SZ",
  Lome: "TG",
  Port_of_Spain: "TT",
  Dar_es_Salaam: "TZ",
  Kampala: "UG",
  Midway: "UM",
  Vatican: "VA",
  St_Vincent: "VC",
  Tortola: "VG",
  St_Thomas: "VI",
  Aden: "YE",
  Mayotte: "YT",
  Lusaka: "ZM",
  Harare: "ZW",
};

export const findCountryFromCity = (city: string) => TIMEZONE_LOCATION[city];

export const SERVICE_TYPES = ["STORAGE", "DETENTION", "DEMURRAGE", "MERGED"];
export const findProvider = (provider: string, PROVIDERS: ServiceProvider[] = []) =>
  PROVIDERS?.find((p) => p && p?.id === provider);

export const CONTAINER_TYPES = [
  { type: "SP", name: "Special Purpose" },
  { type: "GP", name: "General Purpose" },
  { type: "RF", name: "Reefer" },
  { type: "GP-HAZ", name: "General Purpose Hazardous" },
];

export const findContainerType = (type: string) => CONTAINER_TYPES.find((c) => c.type === type);

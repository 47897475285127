import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_CONTAINER_EVENT,
  START_TRACKING,
  GET_ALL_CONTAINERS,
  GET_SERVICE_PROVIDERS,
  SAVE_TRADE_TERM,
  DELETE_TRADE_TERM,
  GET_TRADE_TERMS,
  GET_COST_ESTIMATES,
  GET_ALL_MANAGED_VENDORS,
  UPDATE_ETA_STATUS_PREFERENCE,
  GET_ETA_STATUS_PREFERENCE,
  GET_FORM_M_DATA,
  UPDATE_FORM_M,
  GET_ALL_USER_TRACKED_INVOICES,
  ADD_INVOICE,
  EDIT_INVOICE,
  DELETE_INVOICE,
  DELETE_TRACKING,
} from "../graphql/auth";
import {
  UPDATE_TRACKING,
  GET_SHIPMENT_TRACKING_OPERATION,
  TRACK_SHIPMENTS,
  GET_ALL_BILL,
  GET_ALL_CONTAINER_ID,
  GET_ALL_TRACKINGS,
  GET_ALL_DOWNLOADABLE_TRACKINGS,
  GET_HELPER_DATA,
  GET_EFFICIENCY_REPORT,
} from "../graphql/tracking";
import {
  GET_SHIPMENT_BY_BILL_OF_LADING,
  GET_SHIPMENT_BY_CONTAINER,
  GET_SHIPMENT_BY_TRACKING_ID,
} from "../graphql/shipment";
import {
  setGetShipment,
  setTrackedShipments,
  setDownloadableTrackedShipments,
  setShowNotification,
  setTrackingStartedModal,
  setDynamicMapCenter,
  setAllBill,
  setHelperData,
  setManagedVendors,
  setAllContainers,
  setServiceProviders,
  setTradeTerm,
  setCostEstimates,
  setParsedShipments,
  setETAStatusPreferences,
  setFormMData,
  setAllInvoices,
  setBLInvoices,
  deleteTrackedShipment,
  setEfficiencyReportData,
} from "../redux/data/data.actions";
import useDashboardInfo from "./useDashboardInfo";
import { useHistory } from "react-router-dom";
import { getAmountInLocal } from "../utils/helpers";

export default function useTracking() {
  const { handleGetUserCredits, handleGetDashboardData, logOut } = useDashboardInfo();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cacheSearchbyContainer, setCacheSearchbyContainer] = useState({});
  const [cacheSearchbyBill, setCacheSearchbyBill] = useState({});
  const parsedShipments = useSelector((state) => state?.data?.parsedShipments);

  const deduceLastEvent = (getShipment) => {
    if (!getShipment?.containers[0]?.originLand) {
      return null;
    } else if (!getShipment?.containers[0]?.originPort) {
      return "Origin Land";
    } else if (!getShipment?.containers[0]?.ocean) {
      return "Origin Port";
    } else if (!getShipment?.containers[0]?.destinationPort) {
      return "Ocean";
    } else if (!getShipment?.containers[0]?.destinationLand) {
      return "Destination Port";
    } else if (getShipment?.containers[0]?.destinationLand) {
      return "Destination Land";
    }
  };

  const [SearchByContainer] = useLazyQuery(GET_SHIPMENT_BY_CONTAINER, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getShipmentByContainerNo?.code && data?.getShipmentByContainerNo?.message) {
        if (data?.getShipmentByContainerNo?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.getShipmentByContainerNo?.message, type: "error" })
        );
        dispatch(setGetShipment("no-record"));
      } else {
        const lastStage = deduceLastEvent(data?.getShipmentByContainerNo);

        dispatch(setGetShipment({ ...data?.getShipmentByContainerNo, lastStage }));
        if (data?.getShipmentByContainerNo?.locations && data?.getShipmentByContainerNo?.locations.length > 0) {
          dispatch(
            setDynamicMapCenter({
              lat: data?.getShipmentByContainerNo?.locations[0]?.lat,
              lng: data?.getShipmentByContainerNo?.locations[0]?.lng,
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(setGetShipment("no-record"));
    },
  });

  const searchContainerHandler = (selectedShippingLine, containerNumber, condition) => {
    setCacheSearchbyContainer({ selectedShippingLine, containerNumber });
    setCacheSearchbyBill({});

    if (selectedShippingLine && containerNumber) {
      if (!condition) {
        dispatch(setGetShipment("loading"));
      }
      const shippingLine = selectedShippingLine;

      SearchByContainer({
        variables: {
          containerNumber,
          shippingLine,
        },
      });
    }
  };

  const [SearchByBill] = useLazyQuery(GET_SHIPMENT_BY_BILL_OF_LADING, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getShipmentByBillOfLadingNo?.code && data?.getShipmentByBillOfLadingNo?.message) {
        if (data?.getShipmentByBillOfLadingNo?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.getShipmentByBillOfLadingNo?.message, type: "error" })
        );
        dispatch(setGetShipment("no-record"));
      } else {
        dispatch(setGetShipment(data?.getShipmentByBillOfLadingNo));
      }
    },
    onError(error) {
      dispatch(setGetShipment("no-record"));
    },
  });

  const searchBillHandler = (selectedShippingLine, billNumber, condition) => {
    setCacheSearchbyBill({ selectedShippingLine, billNumber });
    setCacheSearchbyContainer({});

    if (selectedShippingLine && billNumber) {
      if (!condition) {
        dispatch(setGetShipment("loading"));
      }

      const shippingLine = selectedShippingLine;

      SearchByBill({
        variables: {
          billNumber,
          shippingLine,
        },
      });
    }
  };

  const [SearchByTrackingId] = useLazyQuery(GET_SHIPMENT_BY_TRACKING_ID, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getShipmentByTrackingId?.code && data?.getShipmentByTrackingId?.message) {
        if (data?.getShipmentByTrackingId?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getShipmentByTrackingId?.message, type: "error" }));
        dispatch(setGetShipment("no-record"));
      } else {
        dispatch(setGetShipment(data?.getShipmentByTrackingId));
      }
    },
    onError(error) {
      dispatch(setGetShipment("no-record"));
    },
  });

  const searchTrackingIdHandler = (trackingNumber, condition) => {
    if (trackingNumber) {
      if (!condition) {
        dispatch(setGetShipment("loading"));
      }

      const trackingId = trackingNumber;

      SearchByTrackingId({
        variables: {
          trackingId,
        },
      });
    }
  };

  // const [getBill] = useLazyQuery(GET_ALL_BILL, {
  //   errorPolicy: "all",
  //   onCompleted(data) {
  //     if (data?.getUserBillsOfLading?.code && data?.getUserBillsOfLading?.message) {
  //       if (data?.getUserBillsOfLading?.code == 401) {
  //         return logOut();
  //       }
  //       dispatch(setShowNotification({ isOpen: true, message: data?.getUserBillsOfLading?.message, type: "error" }));
  //       dispatch(setAllBill([]));
  //     } else {
  //       dispatch(setAllBill(data?.getUserBillsOfLading?.result));
  //     }
  //   },
  //   onError(error) {
  //     console.error(error);
  //     dispatch(setAllBill([]));
  //   },
  // });

  const [getBill] = useLazyQuery(GET_ALL_BILL, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getAllTrackings?.code && data?.getAllTrackings?.message) {
        if (data?.getUserBillsOfLading?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getUserBillsOfLading?.message, type: "error" }));
        dispatch(setAllBill([]));
      } else {
        const result = data?.getAllTrackings?.items?.map((item) => item?.billOfLadingNumber);
        dispatch(setAllBill(result));
      }
    },
    onError(error) {
      console.error(error);
      dispatch(setAllBill([]));
    },
  });

  const handleGetBill = (openType = "", q = { active: openType === "opened" }) => {
    getBill({
      // eslint-disable-next-line no-unneeded-ternary
      variables: { page: { number: 1, size: -1 }, q, openType },
    });
  };

  const [getallContainerID] = useLazyQuery(GET_ALL_CONTAINER_ID, {
    errorPolicy: "all",
    onError(error) {
      console.error(error);
    },
  });

  const handleGetAllContainerID = async (billOfLadingNumber) => {
    let data = await getallContainerID({
      variables: { page: { number: 1, size: -1 }, q: { billOfLadingNumber } },
    });
    const result = data?.data?.getAllTrackings?.items?.reduce(
      (old, item) => (item?.containerNumber ? { [item?.containerNumber]: item?.id, ...old } : old),
      {}
    );
    return result;
  };

  const [getContainers] = useLazyQuery(GET_ALL_CONTAINERS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getUserContainerNumbers?.code && data?.getUserContainerNumbers?.message) {
        if (data?.getUserContainerNumbers?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getUserContainerNumbers?.message, type: "error" }));
        dispatch(setAllContainers([]));
      } else {
        dispatch(setAllContainers(data?.getUserContainerNumbers.result));
      }
    },
    onError(error) {
      console.error(error);
      dispatch(setAllContainers([]));
    },
  });

  const handleGetContainers = (filter = {}) => {
    getContainers({ variables: { filter } });
  };

  const [getHelperData] = useLazyQuery(GET_HELPER_DATA, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getAllTrackings?.code && data?.getAllTrackings?.message) {
        if (data?.getAllTrackings?.code == 401) {
          return logOut();
        }
        dispatch(setHelperData([]));
      } else {
        dispatch(setHelperData(data?.getAllTrackings?.items));
      }
    },
    onError(error) {
      console.error(error);
      dispatch(setHelperData([]));
    },
  });

  const handleGetHelperData = (
    page = {
      number: 1,
      size: -1,
    },
    q = {}
  ) => {
    getHelperData({
      variables: {
        page,
        q,
      },
    });
  };

  const [getServiceProviders] = useLazyQuery(GET_SERVICE_PROVIDERS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.serviceProviders?.code && data?.serviceProviders?.message) {
        if (data?.serviceProviders?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.serviceProviders?.message, type: "error" }));
        dispatch(setServiceProviders(null));
      } else {
        dispatch(setServiceProviders(data?.serviceProviders));
      }
    },
    onError(error) {
      console.error(error);
      dispatch(setServiceProviders(null));
    },
  });

  const handleGetServiceProviders = (country = "", provision = "") => {
    dispatch(setServiceProviders("loading"));
    getServiceProviders({
      variables: { filter: { country, provision } },
    });
  };

  const [getTrackings] = useLazyQuery(GET_ALL_TRACKINGS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getAllTrackings?.code && data?.getAllTrackings?.message) {
        if (data?.getAllTrackings?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getAllTrackings?.message, type: "error" }));
        dispatch(setTrackedShipments(null));
        return null;
      } else {
        dispatch(setTrackedShipments(data?.getAllTrackings));
        return data?.getAllTrackings;
      }
    },
    onError(error) {
      console.error(error);
      dispatch(setTrackedShipments(null));
      return null;
    },
  });

  const handleGetTrackings = (
    page = {
      number: 1,
      size: -1,
    },
    q = {}
  ) => {
    dispatch(setTrackedShipments("loading"));

    getTrackings({
      variables: {
        page,
        q,
      },
    });
  };

  const [getEfficiencyReport] = useLazyQuery(GET_EFFICIENCY_REPORT, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getDownloadableTrackings?.code && data?.getDownloadableTrackings?.message) {
        if (data?.getDownloadableTrackings?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.getDownloadableTrackings?.message, type: "error" })
        );
        dispatch(setEfficiencyReportData([]));
      } else {
        dispatch(setEfficiencyReportData(data?.getDownloadableTrackings));
      }
    },
    onError(error) {
      console.error(error);
      dispatch(
        setShowNotification({ isOpen: true, message: "An error occured while trying to download", type: "error" })
      );
      dispatch(setDownloadableTrackedShipments([]));
    },
  });

  const handleGetEfficiencyReport = (q) => {
    dispatch(setEfficiencyReportData("loading"));
    getEfficiencyReport({
      variables: {
        q,
      },
    });
  };

  const [getDownloadTrackings] = useLazyQuery(GET_ALL_DOWNLOADABLE_TRACKINGS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getDownloadableTrackings?.code && data?.getDownloadableTrackings?.message) {
        if (data?.getDownloadableTrackings?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.getDownloadableTrackings?.message, type: "error" })
        );
        dispatch(setDownloadableTrackedShipments([]));
      } else {
        dispatch(setDownloadableTrackedShipments(data?.getDownloadableTrackings));
      }
    },
    onError(error) {
      console.error(error);
      dispatch(
        setShowNotification({ isOpen: true, message: "An error occured while trying to download", type: "error" })
      );
      dispatch(setDownloadableTrackedShipments([]));
    },
  });

  const handleGetAllDownloadableTrackings = (q) => {
    getDownloadTrackings({
      variables: {
        q,
      },
    });
  };

  const [getManagedVendors] = useLazyQuery(GET_ALL_MANAGED_VENDORS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getVendoredTrackings?.code && data?.getVendoredTrackings?.message) {
        if (data?.getVendoredTrackings?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getVendoredTrackings?.message, type: "error" }));
        dispatch(setManagedVendors([]));
      } else {
        dispatch(setManagedVendors(data?.getVendoredTrackings.result ? data?.getVendoredTrackings.result : []));
        return true;
      }
    },
    onError(error) {
      console.error(error);
      dispatch(setManagedVendors([]));
    },
  });

  const handleGetManagedVendors = () => {
    return getManagedVendors();
  };

  const [trackingShipments] = useMutation(TRACK_SHIPMENTS, {
    errorPolicy: "none",
    onCompleted(data) {
      if (data?.trackShipments?.code && data?.trackShipments?.message) {
        if (data?.trackShipments?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.trackShipments?.message, type: "error" }));
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Please contact support@atrace.co`,
          type: "error",
        })
      );
    },
  });

  const handleTrackingShipments = async (input) => {
    const res = await trackingShipments({
      variables: {
        input,
      },
    });
    return res;
  };

  const [shipmentTrackingOperation] = useLazyQuery(GET_SHIPMENT_TRACKING_OPERATION, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.shipmentTrackingOperation?.code && data?.shipmentTrackingOperation?.message) {
        if (data?.shipmentTrackingOperation?.code == 401) {
          return logOut();
        }
        dispatch(
          setShowNotification({ isOpen: true, message: data?.shipmentTrackingOperation?.message, type: "error" })
        );
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Please contact support@atrace.co`,
          type: "error",
        })
      );
    },
  });

  const handleGetShipmentTrackingOperation = async (id) => {
    const res = await shipmentTrackingOperation({
      variables: {
        id,
      },
    });
    return res;
  };

  const [startTracking] = useMutation(START_TRACKING, {
    errorPolicy: "none",
    onCompleted(data) {
      if (data?.startTracking?.code && data?.startTracking?.message) {
        if (data?.startTracking?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.startTracking?.message, type: "error" }));
      } else {
        let errorCount = data?.startTracking?.results?.reduce((prev, current) => (prev += current?.success ? 0 : 1), 0);
        if (errorCount > 0) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Oops ${errorCount} / ${data?.startTracking?.results?.length || 0} failed!`,
              type: "error",
            })
          );
        } else {
          dispatch(setTrackingStartedModal(true));
          handleGetTrackings();
          setTimeout(() => {
            handleGetUserCredits();
          }, 2000);
        }

        let newParsedData = parsedShipments.map((shipData) => {
          let feedback = data?.startTracking?.results?.find((data) => shipData?.containerNumber == data?.container);
          if (feedback) {
            return { ...shipData, ...feedback, status: "finished" };
          } else {
            return shipData;
          }
        });
        dispatch(setParsedShipments(newParsedData));
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Please contact support@atrace.co`,
          type: "error",
        })
      );
    },
  });

  const handleStartTracking = async (input) => {
    const res = await startTracking({
      variables: {
        input,
      },
    });
    await handleGetUserCredits();
    // handleGet
    return res;
  };

  const handleShipmentByStageClick = (index) => {
    if (typeof index === "number") {
      const page = {
        number: 1,
        size: -1,
        sortBy: "created_at",
        sortDirectionDesc: true,
      };

      const q = {
        lastEvent: null,
        lastStage: index + 1,
        shippingLine: null,
        containerNumber: null,
        billOfLadingNumber: null,
      };

      getTrackings({
        variables: {
          page,
          q,
        },
      });
    }
  };

  const handleShipmentByStatusClick = (status) => {
    const page = {
      number: 1,
      size: 20,
      sortBy: "created_at",
      sortDirectionDesc: true,
    };

    let convertStatus = null;

    if (status === "On-time") {
      convertStatus = 1;
    } else if (status === "Late") {
      convertStatus = 2;
    } else if (status === "Critical") {
      convertStatus = 3;
    }

    const q = {
      lastEvent: null,
      lastStage: null,
      shippingLine: null,
      containerNumber: null,
      billOfLadingNumber: null,
      status: convertStatus,
    };

    getTrackings({
      variables: {
        page,
        q,
      },
    });
  };

  const [saveTradeTerm] = useMutation(SAVE_TRADE_TERM, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.saveTradeTerms?.code && data?.saveTradeTerms?.message) {
        if (data?.saveTradeTerms?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.saveTradeTerms?.message, type: "error" }));
        return false;
      } else {
        dispatch(setTradeTerm(data?.saveTradeTerms?.result));
        dispatch(
          setShowNotification({
            isOpen: true,
            message: `TradeTerm added successfully!!!`,
            type: "success",
            color: "success",
          })
        );
        handleGetCostEstimates();
        return true;
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while adding a new TradeTerm. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      console.error(error);
    },
  });

  const handleSaveTradeTerm = async (input) => {
    let res = await saveTradeTerm({
      variables: {
        input,
      },
    });
    return res;
  };

  const [updateTracking] = useMutation(UPDATE_TRACKING, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.updateTracking?.code && data?.updateTracking?.message) {
        if (data?.updateTracking?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.updateTracking?.message, type: "error" }));
      } else {
        handleGetHelperData();
        if (data?.updateTracking?.value) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Tracking updated successfully!!!`,
              severity: "success",
              type: "success",
              color: "success",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while updating tracking. Please try again or contact support@atrace.co`,
          type: "error",
        })
      );
      console.error(error);
    },
  });

  const handleUpdateTracking = async (updateFields, filter) => {
    const data = await updateTracking({
      variables: {
        updateFields,
        filter,
      },
    });
    return data?.data?.updateTracking?.value;
  };

  const handleUpdateTradeTerm = async (input) => {
    let res = await updateTradeTerm({
      variables: {
        input,
      },
    });
    return res;
  };

  const [updateTradeTerm] = useMutation(SAVE_TRADE_TERM, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.saveTradeTerms?.code && data?.saveTradeTerms?.message) {
        if (data?.saveTradeTerms?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.saveTradeTerms?.message, type: "error" }));
        return false;
      } else {
        dispatch(setTradeTerm(data?.saveTradeTerms?.result));
        dispatch(
          setShowNotification({
            isOpen: true,
            message: `TradeTerm updated successfully!!!`,
            type: "success",
            color: "success",
          })
        );
        handleGetCostEstimates();
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while adding a new TradeTerm. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      console.error(error);
    },
  });

  const [getTradeTerms] = useLazyQuery(GET_TRADE_TERMS, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getTradeTerms?.code && data?.getTradeTerms?.message) {
        if (data?.getTradeTerms?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getTradeTerms?.message, type: "error" }));
      } else {
        dispatch(setTradeTerm(data?.getTradeTerms?.result));
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleGetTradeTerms = () => {
    getTradeTerms();
  };

  const [getCostEstimates] = useLazyQuery(GET_COST_ESTIMATES, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getEstimatedCosts?.code && data?.getEstimatedCosts?.message) {
        if (data?.getEstimatedCosts?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getEstimatedCosts?.message, type: "error" }));
      } else {
        dispatch(setCostEstimates(data?.getEstimatedCosts.result));
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleGetCostEstimates = (projectionDate) => {
    getCostEstimates({
      variables: {
        projectionDate,
      },
    });
  };

  const [deleteTradeTerm] = useMutation(DELETE_TRADE_TERM, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.deleteTradeTerm?.code && data?.deleteTradeTerm?.message) {
        if (data?.deleteTradeTerm?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.deleteTradeTerm?.message, type: "error" }));
      } else {
        dispatch(setTradeTerm(data?.deleteTradeTerms?.result));
        dispatch(setShowNotification({ isOpen: true, message: `TradeTerm deleted successfully`, type: "success" }));
        handleGetCostEstimates();
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while deleting a new TradeTerm. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      console.error(error);
    },
  });

  const handleDeleteTradeTerm = (input) => {
    deleteTradeTerm({
      variables: {
        input,
      },
    });
  };

  const [getETAStatusPrefences] = useLazyQuery(GET_ETA_STATUS_PREFERENCE, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getETAStatusPreferences?.code && data?.getETAStatusPreferences?.message) {
        if (data?.getETAStatusPreferences?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getETAStatusPreferences?.message, type: "error" }));
      } else {
        dispatch(setETAStatusPreferences(data?.getETAStatusPreferences));
        handleGetDashboardData();
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleGetETAStatusPrefences = () => {
    getETAStatusPrefences();
  };

  const [updateETAStatusPreference] = useMutation(UPDATE_ETA_STATUS_PREFERENCE, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.setETAStatusPreferences?.code && data?.setETAStatusPreferences?.message) {
        if (data?.setETAStatusPreferences?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.setETAStatusPreferences?.message, type: "error" }));
      } else {
        if (data?.setETAStatusPreferences?.value) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `ETA Status Updated successfully!!!`,
              type: "success",
              color: "success",
            })
          );
          handleGetETAStatusPrefences();
        } else {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `ETA Status Update was unsuccessfull!!!`,
              type: "error",
              color: "error",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while Updating ETA Status Preferences. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      console.error(error);
    },
  });

  const handleUpdateETAStatusPreference = (input) => {
    updateETAStatusPreference({
      variables: {
        input,
      },
    });
  };

  const [addContainerEvent] = useMutation(ADD_CONTAINER_EVENT, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.addContainerEvent?.code && data?.addContainerEvent?.message) {
        if (data?.addContainerEvent?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.addContainerEvent?.message, type: "error" }));
      } else {
        if (data?.addContainerEvent?.value) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Event added successfully!!!`,
              type: "success",
              color: "success",
            })
          );
        } else {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Something went wrong. Please try again or contact support@atrace.co`,
              type: "error",
              color: "error",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred adding Event. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      console.error(error);
    },
  });

  const [getFormMData] = useLazyQuery(GET_FORM_M_DATA, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data?.getFormMData?.code && data?.getFormMData?.message) {
        if (data?.getFormMData?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.getFormMData?.message, type: "error" }));
      } else {
        if (
          data?.getFormMData?.result &&
          data?.getFormMData?.result?.length > 0 &&
          typeof data?.getFormMData?.result === "object"
        ) {
          const newFormData =
            data?.getFormMData?.result.map((fD) => {
              const expiryDaysFromNow = Math.floor(
                (new Date(fD?.formMExpiryDate) - new Date()) / (1000 * 60 * 60 * 24)
              );
              const billsOfLading = fD?.billsOfLading || [];
              const amountUsed = billsOfLading?.reduce((res, curr) => res + getAmountInLocal(curr?.blValue), 0) || 0;
              const percentLeft =
                (getAmountInLocal(fD?.formMValue) - amountUsed) / (getAmountInLocal(fD?.formMValue) || 1) || 0;
              return { ...fD, amountUsed, percentLeft, expiryDaysFromNow, billsOfLading };
            }) || [];

          dispatch(setFormMData(newFormData));
        } else {
          dispatch(setFormMData([]));
        }
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleGetFormMData = (q) => {
    dispatch(setFormMData("loading"));
    getFormMData({
      variables: {
        q,
      },
    });
  };

  const [updateFormM] = useMutation(UPDATE_FORM_M, {
    errorPolicy: "all",
    onCompleted: async (data) => {
      if (data?.editFormMNumber?.code && data?.editFormMNumber?.message) {
        if (data?.editFormMNumber?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.editFormMNumber?.message, type: "error" }));
      } else {
        if (data?.editFormMNumber?.success) {
          handleGetFormMData();
        } else {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Something went wrong. Please try again or contact support@atrace.co`,
              type: "error",
              color: "error",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while adding a new TradeTerm. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      console.error(error);
    },
  });

  const handleUpdateFormM = async (input) => {
    let result = await updateFormM({
      variables: {
        input,
      },
    });

    return result;
  };
  const handleAddContainerEvent = (input) => {
    addContainerEvent({
      variables: {
        input,
      },
    });
  };

  const [getAllInvoices] = useLazyQuery(GET_ALL_USER_TRACKED_INVOICES, {
    onCompleted: async (data) => {
      if (data?.getAllUserInvoices?.code == 401) {
        return logOut();
      }
      if (data?.getAllUserInvoices?.data) {
        dispatch(setAllInvoices(data?.getAllUserInvoices?.data));
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while getting all invoices. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
    },
  });

  // end of delete

  const [getBLInvoices] = useLazyQuery(GET_ALL_USER_TRACKED_INVOICES, {
    onCompleted: async (data) => {
      if (data?.getAllUserInvoices?.code == 401) {
        return logOut();
      }
      if (data?.getAllUserInvoices?.data) {
        dispatch(setBLInvoices(data?.getAllUserInvoices?.data));
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while getting BL invoices. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      dispatch(setBLInvoices([]));
    },
  });

  const handleGetBLInvoices = (bl) => {
    getBLInvoices({
      variables: { page: { number: 1, size: 1 }, q: { bl } },
    });
  };
  const handleGetAllInvoices = () => {
    getAllInvoices();
  };

  const [addInvoice] = useMutation(ADD_INVOICE, {
    errorPolicy: "all",
    onCompleted: async (data) => {
      if (data?.addInvoice?.code && data?.addInvoice?.message) {
        if (data?.addInvoice?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.addInvoice?.message, type: "error" }));
        return false;
      } else {
        if (data?.addInvoice?.success) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Invoice Added successfully!!!`,
              type: "success",
              color: "success",
            })
          );
          return true;
        } else {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Something went wrong. Please try again or contact support@atrace.co`,
              type: "error",
              color: "error",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while adding a new Invoice. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      console.error(error);
    },
  });

  const handleAddInvoice = async (blNumber, invoice) => {
    const res = await addInvoice({
      variables: {
        blNumber,
        invoice,
      },
    });
    if (res) {
      handleGetBLInvoices(blNumber);
    }
  };

  const [editInvoice] = useMutation(EDIT_INVOICE, {
    errorPolicy: "all",
    onCompleted: async (data) => {
      if (data?.editInvoice?.code && data?.editInvoice?.message) {
        if (data?.editInvoice?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.addInvoice?.message, type: "error" }));
        return false;
      } else {
        if (data?.editInvoice?.success) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Invoice Edited successfully!!!`,
              type: "success",
              color: "success",
            })
          );
          return true;
        } else {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Something went wrong. Please try again or contact support@atrace.co`,
              type: "error",
              color: "error",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while adding a new Invoice. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      console.error(error);
    },
  });

  const handleEditInvoice = async (id, invoice, bl) => {
    const res = await editInvoice({
      variables: {
        id,
        invoice,
      },
    });
    if (res) {
      handleGetBLInvoices(bl);
    }
  };

  const [deleteInvoice] = useMutation(DELETE_INVOICE, {
    errorPolicy: "all",
    onCompleted: async (data) => {
      if (data?.deleteInvoice?.code && data?.deleteInvoice?.message) {
        if (data?.addInvoice?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.deleteInvoice?.message, type: "error" }));
        return false;
      } else {
        if (data?.deleteInvoice?.success) {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Invoice Deleted successfully!!!`,
              type: "success",
              color: "success",
            })
          );
          return true;
        } else {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Something went wrong. Please try again or contact support@atrace.co`,
              type: "error",
              color: "error",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while deleting Invoice. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      console.error(error);
    },
  });

  const handleDeleteInvoice = async (id, bl) => {
    const res = await deleteInvoice({
      variables: {
        id,
      },
    });
    if (res) {
      handleGetBLInvoices(bl);
    }
  };

  const [deleteTracking] = useMutation(DELETE_TRACKING, {
    errorPolicy: "all",
    onCompleted: async (data) => {
      if (data?.deleteTracking?.code && data?.deleteTracking?.message) {
        if (data?.addInvoice?.code == 401) {
          return logOut();
        }
        dispatch(setShowNotification({ isOpen: true, message: data?.deleteTracking?.message, type: "error" }));
        return false;
      } else {
        if (data?.deleteTracking?.value) {
          return true;
        } else {
          dispatch(
            setShowNotification({
              isOpen: true,
              message: `Something went wrong. Please try again or contact support@atrace.co`,
              type: "error",
              color: "error",
            })
          );
        }
      }
    },
    onError(error) {
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `${error.message}! Occurred while deleting Tracking. Please try again or contact support@atrace.co`,
          type: "info",
        })
      );
      console.error(error);
    },
  });

  const handleDeleteTracking = async (id) => {
    const res = await await deleteTracking({
      variables: {
        tId: id,
      },
    });

    if (res) {
      dispatch(deleteTrackedShipment(id));
      dispatch(
        setShowNotification({
          isOpen: true,
          message: `Tracking Deleted successfully!!!`,
          type: "success",
          color: "success",
        })
      );
    }
  };
  return {
    logOut,
    searchBillHandler,
    searchTrackingIdHandler,
    searchContainerHandler,
    handleGetTrackings,
    handleTrackingShipments,
    handleGetShipmentTrackingOperation,
    handleStartTracking,
    handleShipmentByStageClick,
    handleShipmentByStatusClick,
    handleGetBill,
    handleGetServiceProviders,
    handleSaveTradeTerm,
    handleGetTradeTerms,
    handleDeleteTradeTerm,
    handleUpdateTradeTerm,
    handleGetCostEstimates,
    handleGetHelperData,
    handleGetManagedVendors,
    handleGetContainers,
    handleUpdateTracking,
    handleUpdateETAStatusPreference,
    handleGetETAStatusPrefences,
    handleGetAllDownloadableTrackings,
    handleGetEfficiencyReport,
    handleAddContainerEvent,
    handleGetFormMData,
    handleUpdateFormM,
    handleGetBLInvoices,
    handleGetAllInvoices,
    handleAddInvoice,
    handleEditInvoice,
    handleDeleteInvoice,
    handleDeleteTracking,
    handleGetAllContainerID,
  };
}

import React, { useState } from "react";
import { Box, DialogContent, DialogTitle, Dialog, Popover , Button , Grid, FormControlLabel, Switch } from "@mui/material";
import { formatDateF } from "../../utils/helpers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MdDownload, MdCancel } from "react-icons/md";

const SubscriptionDownloadForm = ({ open, handleClose, anchorEL, download }) => {
  const [debitSelected, setSelectedDebit] = useState(true);
  const [creditSelected, setSelectedCredit] = useState(true);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  let param = {};
  const readyForDownload = () => {
    let date = null;
    if (startDate || endDate) {
      date = {};
      if (startDate) {
        date.from = formatDateF(startDate, "MM/DD/YYYY");
      }
      if (endDate) {
        date.to = formatDateF(endDate, "MM/DD/YYYY");
      }
    }

    if (!(creditSelected && debitSelected)) {
      if (creditSelected) {
        param.transactionType = "credit";
      }

      if (debitSelected) {
        param.transactionType = "debit";
      }
    }
    if (date) {
      param.date = date;
    }
    handleClose();
    download(param);
    // return setDownloadParam(param);
  };
  return (
    <Popover
      onClose={handleClose}
      open={open}
      anchorEl={anchorEL}
      anchorOrigin={{ vertical: "center", horizontal: "center" }}
      PaperProps={{ sx: { maxWidth: { xs: "19rem" } } }}
    >
      <DialogTitle>Download filter</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="space-between" spacing={1} pt={1}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <DatePicker
                    disablePast
                    label={"Start Date"}
                    views={["year", "month", "day"]}
                    value={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    disablePast
                    label={"End Date"}
                    views={["year", "month", "day"]}
                    value={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} justifyContent="space-between" spacing={1} container>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={debitSelected} onClick={() => setSelectedDebit(!debitSelected)} />}
                label="Debit"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={creditSelected} onClick={() => setSelectedCredit(!creditSelected)} />}
                label="Credit"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} justifyContent="space-around" spacing={1} container>
          <Grid item xs={6}>
            <Button variant="outlined" color="primary" startIcon={<MdCancel />} onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" startIcon={<MdDownload />} onClick={readyForDownload}>
              Download
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Popover>
  );
};

export default SubscriptionDownloadForm;

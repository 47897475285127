import React, { useState } from "react";
import { Box, useTheme, Modal, Typography, Button, Grid } from "@mui/material";
import MapBg from "../../assets/images/map-bg.svg";
// import "./modals.scss";
import successImg from "../../assets/actions/success.gif";
import warningImg from "../../assets/actions/warning.gif";
import infoImg from "../../assets/actions/info.gif";
import errorImg from "../../assets/actions/error.gif";

export default function ModalNotification({
  open,
  handleClose,
  title,
  msgs,
  severity = "",
  action,
  actionText,
  alignment = "center",
}) {
  severity = `${severity}`.trim().toLowerCase();
  const theme = useTheme();
  const [cnt] = useState(typeof msgs === "object" && msgs.length ? msgs : typeof msgs !== "object" ? [msgs] : []);
  return (
    <Modal
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        maxWidth="500px"
        bgcolor="background.paper"
        width="90%"
        position="absolute"
        top="50%"
        left="50%"
        padding={2}
        boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
        borderRadius="7px"
        border="1.5px solid #E5E5E5"
        borderColor={
          severity === "error"
            ? theme.palette.error.main
            : severity === "warning"
            ? theme.palette.warning.main
            : severity === "success"
            ? theme.palette.success.main
            : theme.palette.primary.main
        }
        sx={{
          bgcolor: "#fff",
          backgroundImage: `url(${MapBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          height="70px"
          sx={{
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundImage: `url("${
              severity === "error"
                ? errorImg
                : severity === "warning"
                ? warningImg
                : severity === "success"
                ? successImg
                : infoImg
            }")`,
          }}
        ></Box>
        <Typography
          variant="h2"
          fontSize={"1.5rem"}
          lineHeight={2}
          textTransform="capitalize"
          align={alignment}
          color={
            severity === "error"
              ? theme.palette.error.main
              : severity === "warning"
              ? theme.palette.warning.main
              : severity === "success"
              ? theme.palette.success.main
              : theme.palette.primary.main
          }
          gutterBottom
        >
          {title}
        </Typography>
        {cnt?.map((msg, key) => (
          <Typography my={1} variant="body2" textAlign={alignment} px={0.5} key={key}>
            {msg}
          </Typography>
        ))}

        <Grid container spacing={2}>
          {(action && (
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{ my: 1 }}
                fullWidth
                onClick={() => {
                  action();
                  handleClose();
                }}
              >
                {actionText || "action"}
              </Button>
            </Grid>
          )) ||
            ""}
          <Grid item xs={action ? 6 : 12}>
            <Button
              sx={{ my: 1 }}
              fullWidth
              variant="outlined"
              onClick={handleClose}
              color={severity === "error" || severity === "warning" || severity === "success" ? severity : "primary"}
            >
              {actionText ? "Close" : "Continue"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

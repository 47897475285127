import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLazyQuery } from "@apollo/client";
import SubscriptionDownloadForm from "../../../components/SubscriptionDownloadForm";
import { color, fontSize, size, fontWeight } from "../../../styles/constants";
import { ReactComponent as TransactionIn } from "../../../assets/icons/transaction-in.svg";
import { ReactComponent as TransactionOut } from "../../../assets/icons/transaction-out.svg";
import { USER_TRANSACTIONS } from "../../../graphql/transactions";
import Loader from "../../../components/Loader/Loader";
import { convertToLocalTime } from "../../../utils/helpers";
import writeXlsxFile from "write-excel-file";
import { saveAs } from "file-saver";
import { Typography, Stack, Button, Box, Portal } from "@mui/material";
import { MdDownload } from "react-icons/md";

const TransactionBox = () => {
  const [userDownloadData, setUserDownloadData] = useState([]);
  const [userCanDownload, setUserCanDownload] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [getUserTrans, { loading }] = useLazyQuery(USER_TRANSACTIONS, {
    errorPolicy: "all",
    onCompleted(data) {
      const result = data?.getUserTransactions;
      setTrans(result);
      return result;
    },
    onError(error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
  });

  const [getUserTransForDownload] = useLazyQuery(USER_TRANSACTIONS, {
    errorPolicy: "all",
    onCompleted(data) {
      const result = data?.getUserTransactions?.items;
      setUserDownloadData(result || []);
    },
    onError(error) {
      console.error(error);
    },
  });

  const [transactions, setTrans] = useState({});

  useEffect(() => {
    // getUserTrans({
    //   variables: {
    //     pageStuff: {
    //       number: 1,
    //       size: 5,
    //       sortBy: "created_at",
    //       sortDirectionDesc: true,
    //     },
    //     trans: {},
    //   },
    // });
  }, []);

  useEffect(() => {
    const download = async () => {
      if (userCanDownload) {
        let schema = [
          {
            column: "Transaction type",
            value: (row) => row?.transactionType || "N/A",
            width: 15,
            type: String,
          },
          {
            column: "Description",
            value: (row) => row?.reference || "N/A",
            width: 50,
            type: String,
          },
          {
            column: "Balance after",
            value: (row) => row?.balanceAfter || 0,
            width: 12,
            format: "#,##0.00",
            type: Number,
          },
          {
            column: "Date",
            value: (row) => (row?.createdAt ? convertToLocalTime(row?.createdAt) : "N/A"),
            width: 21,
            type: String,
          },
        ];
        let result = await writeXlsxFile(userDownloadData, { schema });
        saveAs(result, "transactions.xlsx");
        setUserCanDownload(false);
      }
    };
    download();
  }, [userDownloadData]);

  const downloadData = async (param) => {
    getUserTransForDownload({
      variables: {
        pageStuff: {
          number: 1,
          size: -1,
          sortBy: "created_at",
          sortDirectionDesc: true,
        },
        trans: { ...param },
      },
    });

    setUserCanDownload(true);
  };
  return (
    <Box width="100%" p={1}>
      <Typography lineHeight={1.5} variant="h2" color="primary" fontSize="1.2rem" gutterBottom fontWeight={450}>
        Your Transactions
      </Typography>

      <Button
        fullWidth
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setShowDownloadModal(true);
        }}
        disabled={loading || !transactions?.items?.length}
        variant="contained"
        sx={{ textTransform: "capitalize", py: 1, my: 1.5 }}
        endIcon={<MdDownload />}
      >
        {" "}
        Download
      </Button>

      {loading && <Loader />}
      {(transactions?.items?.length > 0 &&
        transactions?.items.map((item, index) => (
          <Stack
            width={"100%"}
            key={index}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
            py={1}
            px={0.5}
            borderRadius="5px"
            sx={{
              ":hover": {
                bgcolor: "#fff3e9",
                transform: "scale(1.01)",
              },
            }}
          >
            <div style={{ width: "30px", aspectRatio: "1/1" }}>
              {item?.transactionType === "credit" ? <TransactionIn /> : <TransactionOut />}{" "}
            </div>

            <Typography variant="subtitle2" textAlign={"left"}>
              {(item.narration || item.reference || "").trim()}
            </Typography>
            <Typography variant="body2" textAlign="right">
              {`${convertToLocalTime(item.createdAt) || ""}`.toLowerCase()}
            </Typography>
          </Stack>
        ))) ||
        ""}
      {(!loading && transactions?.items?.length === 0 && (
        <Typography color="primary" variant="h2" fontSize="1rem" component="p" textAlign="center">
          You currently have no transactions!
        </Typography>
      )) ||
        ""}

      <Portal>
        <SubscriptionDownloadForm
          open={showDownloadModal}
          download={downloadData}
          anchorEL={anchorEl}
          handleClose={() => {
            setAnchorEl(null);
            setShowDownloadModal(false);
          }}
        />
      </Portal>
    </Box>
  );
};

export default TransactionBox;

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { Grid, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useTracking from "../../hooks/useTracking";
import { LoadingButton } from "@mui/lab";
import "./modals.scss";
import mapBg from "../../assets/images/map-bg.svg";
import { eventStagesList } from "../../utils/data";
import { GET_BILL_CONTAINERS } from "../../graphql/auth";
import { useLazyQuery } from "@apollo/client";
// import { useIntercom } from "react-use-intercom";
import { useDispatch } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import LimitTags from "../LimitTags";
const boxStyle = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -30%)",
  bgcolor: "background.paper",
  backgroundImage: "url('" + mapBg + "')",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

export default function AddEventModal({ open, handleClose, handleUploadShipping, billOfLading, containerNo }) {
  const stages = eventStagesList;
  const dispatch = useDispatch();
  // const { hardShutdown } = useIntercom();
  const logOut = () => {
    // hardShutdown();
    dispatch({ type: "DESTROY_SESSION" });
    localStorage.clear();
    sessionStorage.clear();
    history.push("/login");
  };
  const { handleAddContainerEvent } = useTracking();
  const [containerNos, setContainerNos] = React.useState(containerNo ? [containerNo] : []);
  const [blContainerNos, setBlContainerNos] = React.useState(containerNo ? [containerNo] : []);
  const [stage, setStage] = React.useState(null);
  const [event, setEvent] = React.useState(null);
  const [fetched, setFetched] = React.useState(false);
  const [eventDate, setEventDate] = React.useState(new Date().toISOString()?.split("T")[0]);
  const [eventList, setEventList] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const validateStage = (stage) => {
    if (!stage) {
      return { status: false, msg: "stage required" };
    }
    return { status: true, msg: "" };
  };
  const validateEventDate = (eventDate) => {
    if (!eventDate) {
      return { status: false, msg: "event date required" };
    }
    return { status: true, msg: "" };
  };
  const validateEvent = (events) => {
    if (!events) {
      return { status: false, msg: "event required" };
    }
    return { status: true, msg: "" };
  };
  const changeStage = (stage) => {
    const error = validateStage(stage?.value);
    setEventList(stage?.events || []);
    setErrors({ ...errors, event: "event required", stage: error?.msg });
    setEvent(null);
    setStage(stage);
  };
  const changeEvent = (event) => {
    const error = validateEvent(event);
    setErrors({ ...errors, event: error?.msg });
    setEvent(event);
  };
  const changeEventDate = (event) => {
    const error = validateEventDate(event);
    setErrors({ ...errors, eventDate: error?.msg });
    setEventDate(event);
  };

  const validateAll = () => {
    const eventError = validateEvent(event);
    const eventDateError = validateEventDate(eventDate);
    const stageError = validateStage(stage);
    setErrors({ event: eventError?.msg, eventDate: eventDateError?.msg, stage: stageError?.msg });
    return eventError?.status && eventDateError?.status && stageError?.status;
  };
  React.useEffect(() => {
    if (billOfLading) {
      getBLContainers();
    }
  }, [billOfLading]);

  const [getBillOfLadingContainers, { loading }] = useLazyQuery(GET_BILL_CONTAINERS, {
    onCompleted(data) {
      if (data?.getUserContainerNumbers?.code && data?.getUserContainerNumbers?.message) {
        if (data?.getUserContainerNumbers?.code == 401) {
          return logOut();
        }
        setBlContainerNos(containerNo);
      } else {
        setBlContainerNos([...data?.getUserContainerNumbers.result]);
      }
    },
    onError(error) {
      console.error(error);
      setBlContainerNos(containerNo);
    },
  });

  const updateAddByBL = () => {
    setContainerNos([...blContainerNos]);
  };

  const getBLContainers = () => {
    if (billOfLading) {
      getBillOfLadingContainers({ variables: { filter: { billOfLading: billOfLading } } });
    }
  };

  const submit = () => {
    if (validateAll()) {
      handleAddContainerEvent({ eventId: event?.ID, eventDate, containerNumbers: containerNos });
      handleClose();
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <Stack spacing={2}>
            <Grid container alignItems={"flex-end"} rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12} md={8}>
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start">BL&nbsp;-</InputAdornment>,
                  }}
                  fullWidth
                  size=""
                  type={"text"}
                  disabled
                  value={`${billOfLading}`}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <LoadingButton
                  elevation={3}
                  loading={loading}
                  fullWidth
                  disabled={Boolean(!billOfLading) || containerNos.length == blContainerNos.length}
                  sx={{
                    height: "55px",
                    backgroundColor: "#033b6c",
                    [":hover"]: { backgroundColor: "#033b9e" },
                  }}
                  variant="contained"
                  onClick={() => updateAddByBL()}
                >
                  apply&nbsp;on&nbsp;BL
                </LoadingButton>
              </Grid>
            </Grid>
            <LimitTags
              readOnly
              label={"Containers"}
              values={containerNos}
              onChange={(e, v) => {
                if (v?.length > 0) {
                  setContainerNos([...v]);
                }
              }}
              options={blContainerNos}
            />
            <TextField
              label={errors?.eventDate ? " " : "Event date"}
              type={"date"}
              error={Boolean(errors?.eventDate)}
              helperText={errors?.eventDate}
              value={eventDate}
              onChange={(e) => {
                changeEventDate(e.currentTarget.value);
              }}
              variant="standard"
            />
            <Autocomplete
              options={eventStagesList}
              getOptionLabel={(opt) => opt.label}
              isOptionEqualToValue={(opt, value) => opt?.value === value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={errors?.stage}
                  error={Boolean(errors?.stage)}
                  label="Stage"
                  variant="standard"
                />
              )}
              value={stage}
              onChange={(event, newValue) => changeStage(newValue)}
            />
            <Autocomplete
              options={eventList}
              getOptionLabel={(opt) => opt?.Description}
              error={Boolean(!event)}
              isOptionEqualToValue={(opt, val) => opt?.ID == val}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={errors?.event}
                  error={Boolean(errors?.event)}
                  label="Event"
                  variant="standard"
                />
              )}
              onChange={(evt, val) => {
                changeEvent(val);
              }}
              value={event}
            />
            <Button
              sx={{ color: "#033b6c", borderColor: "#033b6c", py: 1.5 }}
              variant="outlined"
              fullwidth
              color="primary"
              onClick={submit}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mapBg from "../../assets/images/map-bg.svg";
import { toast } from "react-toastify";
import MuiPhone from "../MuiPhone";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import { USAGE_INFO , ALL_CURRENCIES, findCompleteCurrency, findCountryFromCity } from "../../utils/data";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countries, findCountry } from "../../utils/all-countries";
import { Modal } from "@mui/base";
import { useAuth } from "../../hooks/useAuth";
import { setUserData } from "../../redux/user/user.actions";

const UpdateUserModal = ({ open, close }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const formik = useFormik({
    initialValues: {
      companyName: userData?.companyName || "",
      usageInfo: userData?.usageInfo || "",
      shippingCount: userData?.averageMonthlyShippingCount || "",
      country: userData?.country || "",
      localCurrency: userData?.localCurrency || "",
      telephone: userData?.telephone || "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string().typeError("Enter a valid company Name").required("Required"),
      usageInfo: Yup.string().required("Select a Use Case"),
      shippingCount: Yup.number().typeError("Enter a valid number").required("Required"),
      country: Yup.string().required("Required"),
      localCurrency: Yup.string().required("Required"),
      telephone: Yup.string()
        .min(6, "Enter a valid Phone Number")
        .required("Phone Number is required")
        .matches(/\+?([0-9]){3,}/, "Enter a valid Phone Number"),
    }),
    onSubmit: async (values) => {
      await handleUpdate(values);
    },
  });

  const { values, touched, errors, setFieldTouched, handleChange, handleBlur, handleSubmit, setFieldValue, setValues } =
    formik;

  const { getUpdateUser } = useAuth();

  // const handleNextTour = () => {
  //   props.tourHelpers.close();
  // };

  const updateUserHandler = async (
    companyName,
    usageInfo,
    averageMonthlyShippingCount,
    telephone,
    localCurrency,
    country
  ) => {
    setIsLoading(true);

    const { data } = await getUpdateUser({
      companyName,
      usageInfo,
      averageMonthlyShippingCount,
      telephone,
      localCurrency,
      country,
      onboardingComplete: true,
    });

    if (data?.updateUser) {
      dispatch(
        setUserData({
          ...userData,
          companyName,
          usageInfo,
          averageMonthlyShippingCount,
          telephone,
          localCurrency,
          country,
          status: data?.updateUser?.status,
          onboardingComplete: data?.updateUser?.status === "OK",
        })
      );
      localStorage.setItem("onboardingComplete", data?.updateUser?.status === "OK");
    }

    setIsLoading(false);
  };

  const handleUpdate = async (values) => {
    const { companyName, usageInfo, shippingCount, telephone, localCurrency, country } = values;
    if (companyName === "") {
      toast.error("Please enter company name", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else if (shippingCount === "") {
      toast.error("Please input average shipments number", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      await updateUserHandler(companyName, usageInfo, shippingCount, telephone, localCurrency, country);
      // handleNextTour();
      close();
    }
  };

  useEffect(() => {
    const city = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/")[1];
    const countryCode = findCountryFromCity(city);
    if (countryCode) {
      const country = findCountry(countryCode);
      if (country) {
        setValues((prev) => ({
          ...prev,
          country: values.country || countryCode,
          localCurrency: values.localCurrency || country?.currency?.code,
        }));
      }
    }
  }, []);

  return (
    <Box
      sx={{
        bg: "rgba(0,0,0,0.5)",
      }}
    >
      <Modal open={open}>
        <Box
          sx={{
            backgroundImage: `url(${mapBg})`,
            borderRadius: "1rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#fafafa",
            boxShadow: 24,
            p: 4,
            zIndex: "999",
            overflowY: "scroll !important",
          }}
          width={{
            xs: "100%",
            md: "70%",
          }}
          height={{
            xs: "100%",
            sm: "auto",
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Typography className="text-[#033B6C]" textAlign="center" component="h1" fontSize="1.7rem" lineHeight={2}>
                Thank you for signing up to use the platform
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl error={touched.usageInfo && errors.usageInfo}>
                <Grid container spacing={1} pb="2rem">
                  <Grid item xs={12}>
                    <FormLabel>
                      <Typography
                        className="text-[#033B6C] text-base lg:text-[22px] leading-tight lg:leading-[26px]"
                        // width="100%"
                        textAlign="left"
                        component="h2"
                      >
                        {" "}
                        Please tell us how you will use the platform *
                      </Typography>
                    </FormLabel>
                  </Grid>
                  {USAGE_INFO.map((usage, idx) => (
                    <Grid key={idx} item xs={12} sm={6} lg={4}>
                      <Button
                        variant={values?.usageInfo === usage ? "contained" : "outlined"}
                        color={values?.usageInfo === usage ? "success" : "primary"}
                        fullWidth
                        onClick={() => {
                          setFieldTouched("usageInfo", true);
                          setFieldValue("usageInfo", usage);
                        }}
                      >
                        {usage}
                      </Button>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    {touched?.usageInfo && errors?.usageInfo && (
                      <FormHelperText error>{errors?.usageInfo}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    className="text-[#033B6C] text-base lg:text-[22px] leading-tight lg:leading-[26px]"
                    width="100%"
                    textAlign="left"
                    component="h2"
                  >
                    Enter your company name
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={values.companyName}
                    name="companyName"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.companyName && errors.companyName}
                    helperText={touched.companyName && errors.companyName}
                    label="Company Name"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    className="text-[#033B6C] text-base lg:text-[22px] leading-tight lg:leading-[26px]"
                    width="100%"
                    textAlign="left"
                    component="h2"
                  >
                    Average shipments you process monthly?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={values.shippingCount}
                    name="shippingCount"
                    onChange={handleChange}
                    type="number"
                    required
                    onBlur={handleBlur}
                    error={touched.shippingCount && errors.shippingCount}
                    helperText={touched.shippingCount && errors.shippingCount}
                    label="Shipping Count"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                fullWidth
                getOptionLabel={(country) => country?.name || ""}
                options={countries}
                value={findCountry(values?.country) || ""}
                onChange={(_, v) => {
                  setFieldValue("country", v?.code || "");
                  if (!values?.localCurrency || v?.currency?.code) {
                    setFieldValue("localCurrency", v?.currency?.code || "");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="country"
                    label="Country"
                    required
                    error={errors.country}
                    helperText={errors.country || ""}
                    onBlur={handleBlur}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPhone
                label="Telephone"
                value={values.telephone || ""}
                defaultCountry={(`${values?.country}` || "ng").trim().toLocaleLowerCase()}
                onChange={(phone) => {
                  setFieldValue("telephone", phone);
                }}
                onBlur={handleBlur}
                error={errors?.telephone}
                helperText={errors?.telephone || ""}
                type="tel"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                // size="small"
                fullWidth
                getOptionLabel={(curr) => curr?.name || ""}
                options={ALL_CURRENCIES}
                value={findCompleteCurrency(values?.localCurrency) || ""}
                onChange={(_, v) => {
                  setFieldValue("localCurrency", v?.value || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="localCurrency"
                    label="Local Currency"
                    required
                    error={errors.localCurrency}
                    helperText={errors.localCurrency || ""}
                    onBlur={handleBlur}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                  py: 1.5,
                }}
              >
                {isLoading ? "Saving..." : "Save"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};
export default UpdateUserModal;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import mapBg from "../../assets/images/map-bg.svg";

export default function ConfirmationDialog({
  isOpen,
  handleClose,
  title = "",
  desc = "",
  okayText = "Sure",
  cancelText = "Not Sure",
  severity = "primary",
  onConfirm = () => {},
  closeAfterConfirm = true,
  additionalClose = () => {},
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: "400px",
          width: "90%",
          backgroundColor: "#fff",
          backgroundImage: "url(" + mapBg + ")",
          backgroundPosition: "contain",
        },
      }}
    >
      {title && (
        <DialogTitle textAlign="center" sx={{ fontWeight: "bold" }} color="#033b6c">
          {title}
        </DialogTitle>
      )}
      <DialogContent sx={{ pb: 1 }}>
        <DialogContentText textAlign={"center"} justifyContent={"center"} color={"#111"} variant="body2">
          <>
            {typeof desc === "string"
              ? desc
              : desc.map((d, i) => (
                  <p key={`description${i}`} style={{ margin: ".25rem auto", textAlign: "center" }}>{`${d}`}</p>
                ))}
          </>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mt: 0, mb: 2 }}>
        <Button
          color={severity}
          variant="outlined"
          sx={{ textTransform: "capitalize" }}
          onClick={() => {
            handleClose();
            additionalClose();
          }}
        >
          {cancelText}
        </Button>
        <Button
          color={severity}
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          onClick={async (e) => {
            await onConfirm();
            if (closeAfterConfirm) {
              handleClose(e);
            }
          }}
          autoFocus
        >
          {okayText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector , useDispatch } from "react-redux";
import { Grid , Fab, Button, ButtonGroup } from "@mui/material";
import FormMTable from "../../components/Tables/FormMTable";
import useTracking from "../../hooks/useTracking";
import Loader from "../../components/Loader/Loader";
import writeXlsxFile from "write-excel-file";
import { saveAs } from "file-saver";
import { MdDownload } from "react-icons/md";
import { fixDate, getAmountInLocal , firstDayCurrentYear } from "../../utils/helpers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import Report from "../../components/Report/Report";
import { setTablePreference } from "../../redux/data/data.actions";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      text: "Form M Usage and Validity Days",
    },
    datalabels: {
      align: "top",
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
  },
};

export default function Shipments({ q = { active: true }, title = `aTrace - Visibility - Form M Utilizations` }) {
  const dispatch = useDispatch();
  const { handleGetFormMData } = useTracking();
  const [sortValue, setSortValue] = useState("");
  const [sortFieldName, setSortFieldName] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [data, setData] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [page, setPage] = useState(0);
  const [currentChart, setCurrentChart] = useState("VD");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [chartDataset, setChartDataset] = useState([]);
  const [startDate, setStartDate] = useState(dayjs(firstDayCurrentYear()));
  const [endDate, setEndDate] = useState(dayjs());
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    if (event.target.value != tablePreference) {
      dispatch(setTablePreference(event.target.value));
    }
    setPage(0);
  };

  const tablePreference = useSelector((state) => state.data.tablePreference);
  const formMData = useSelector((state) => state.data.formMData);

  useEffect(() => {
    handleGetFormMData({
      fromDate: dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"),
      toDate: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"),
    });
    document.title = title;
  }, [startDate, endDate]);

  useEffect(() => {
    setRowsPerPage(tablePreference);
  }, [tablePreference]);

  useEffect(() => {
    if (formMData && typeof formMData === "object") {
      const data = formMData.map((info) => ({ ...info, convertedFormMVal: getAmountInLocal(info.formMValue) }));
      const currData = [...data];
      currData?.sort((a, b) => a.formMNumber.localeCompare(b.formMNumber));
      setLabels(currData?.map((info) => info.formMNumber));
      setChartDataset([
        {
          label: currentChart === "VD" ? "Validity Days" : "Usage",
          data:
            currentChart === "VD"
              ? currData?.map((info) => info?.expiryDaysFromNow || 0)
              : currData?.map(
                  (info) =>
                    `${((1 - info?.percentLeft) * 100).toFixed(2) > 0 ? ((1 - info?.percentLeft) * 100).toFixed(2) : 0}`
                ),
          borderColor: "rgb(3,59,108)",
          backgroundColor: "rgba(3,59,108, 0.5)",
          yAxisID: "y",
        },
      ]);
      setData(data);
    }
  }, [formMData, currentChart]);

  const sortBy = (value, order = "asc", tdata, base = []) => {
    tdata = tdata ? [...tdata] : data ? [...data] : [];
    let sortBase = tdata;

    for (let i = 0; i < base?.length; i++) {
      sortBase = sortBase[base[i]];
    }
    if (value) {
      if (order == "asc") {
        sortBase = sortBase?.sort((b, a) => {
          if (typeof a[value] == "number") {
            return a[value] - b[value];
          } else {
            return a[value]?.localeCompare(b[value]);
          }
        });
      } else {
        sortBase = sortBase?.sort((a, b) => {
          if (typeof a[value] == "number") {
            return a[value] - b[value];
          } else {
            return a[value]?.localeCompare(b[value]);
          }
        });
      }
      setData([...tdata]);
    }
  };

  const changeSortOrder = (kind, order, base = [], fieldName = "") => {
    if (order == "asc") {
      sortBy(kind, "asc", data, base);
      setSortOrder("desc");
    } else {
      sortBy(kind, "desc", data, base);
      setSortOrder("asc");
    }
    setSortFieldName(fieldName);
    setSortValue(kind);
  };

  const downloadFormMExcel = async () => {
    const HEADER_ROW = [
      {
        value: "Form M Number",
        fontWeight: "bold",
        width: 15,
      },
      {
        value: "Bill of Lading",
        fontWeight: "bold",
        width: 15,
      },
      {
        value: "Form M Value",
        fontWeight: "bold",
        width: 15,
      },
      {
        value: "Total Usage",
        fontWeight: "bold",
        width: 15,
      },
      {
        value: "% Usage",
        fontWeight: "bold",
        width: 5,
      },
      {
        value: "Form M Approval Date",
        fontWeight: "bold",
        width: 20,
      },
      {
        value: "Form M Expiry Date",
        fontWeight: "bold",
        width: 20,
      },
      {
        value: "Days to Expiry",
        fontWeight: "bold",
        width: 15,
      },
    ];
    const columns = [
      {
        width: 15,
      },
      {
        width: 15,
      },
      {
        width: 15,
      },
      {
        width: 15,
      },
      {
        width: 8,
      },
      {
        width: 16,
      },
      {
        width: 16,
      },
      {
        width: 12,
      },
    ];

    const excelData = data.map((d) => {
      return [
        [
          {
            value: d.formMNumber,
            type: String,
          },
          {},
          {
            value: parseFloat(`${d.formMValue}`?.replaceAll(/,/g, "")),
            type: Number,
            format: "#,##0.00",
          },
          {
            value: parseFloat(`${d.amountUsed}`?.replaceAll(/,/g, "")),
            type: Number,
            format: "#,##0.00",
            fontWeight: "bold",
          },
          {
            value: parseFloat(1 - d.percentLeft / 100),
            type: Number,
            format: "0.00%",
            fontWeight: "bold",
          },
          {
            value: fixDate(d.formMApprovalDate) ? new Date(fixDate(d.formMApprovalDate)) : null,
            format: "yyyy/mm/dd",
            type: Date,
          },
          {
            value: fixDate(d.formMExpiryDate) ? new Date(fixDate(d.formMExpiryDate)) : null,
            format: "yyyy/mm/dd",
            type: Date,
          },
          {
            value: d.expiryDaysFromNow,
            type: Number,
          },
        ],
        ...d?.billsOfLading?.map((bl) => [
          {},
          { value: bl?.billOfLadingNumber, type: String },
          {},
          { value: parseFloat(`${bl?.blValue}`?.replaceAll(/,/g, "")), type: Number, format: "#,##0.00" },
          { value: parseFloat((bl?.blValue / d.formMValue).toFixed(2)), type: Number, format: "0.00%" },
          {},
          {},
          {},
        ]),
      ];
    });

    let flatData = [];
    excelData.forEach((d) => (flatData = [...flatData, ...d]));
    let result = await writeXlsxFile([HEADER_ROW, ...flatData], { columns });
    saveAs(result, "form_M_Data.xlsx");
  };

  const getfilter = () => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Start Date"
            value={startDate}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
              },
            }}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="End Date"
            value={endDate}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
              },
            }}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
  const getChart = () =>
    formMData === "loading" ? (
      <Loader />
    ) : (
      <Grid container spacing={1} flexDirection={"column"}>
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <ButtonGroup variant="text" color="inherit" aria-label="text button group">
            <Button onClick={() => setCurrentChart("VD")} color={currentChart === "VD" ? "warning" : "inherit"}>
              Validity Days
            </Button>
            <Button onClick={() => setCurrentChart("FU")} color={currentChart === "FU" ? "warning" : "inherit"}>
              Usage (%)
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <Line options={options} data={{ labels, datasets: chartDataset }} />
        </Grid>
      </Grid>
    );

  const getData = () => {
    return formMData === "loading" ? (
      <Loader />
    ) : (
      <>
        <FormMTable
          {...{
            data,
            page,
            rowsPerPage,
            handleChangePage,
            handleChangeRowsPerPage,
            changeSortOrder,
            sortOrder,
            sortValue,
            sortFieldName,
          }}
        />
        <Fab
          color="primary"
          aria-label="download excel"
          disabled={downloadLoading}
          size="small"
          onClick={downloadFormMExcel}
          sx={{
            position: "fixed",
            right: 12,
            bottom: "50%",
            opacity: 0.85,
            "&:hover": {
              opacity: 0.95,
            },
          }}
        >
          <MdDownload />
        </Fab>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ position: "relative" }}>
        <Report title="Form M Utilization" renderFilter={getfilter} renderChart={getChart} renderData={getData} />
      </Grid>
    </Grid>
  );
}

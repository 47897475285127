import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import "./modals.scss";
import { useSelector , useDispatch } from "react-redux";
import { RiFileWarningLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { TiTick, TiTimes } from "react-icons/ti";
import useTracking from "../../hooks/useTracking";
import { LoadingButton } from "@mui/lab";
import { setParsedShipments, setShowNotification } from "../../redux/data/data.actions";
import useDashboardInfo from "../../hooks/useDashboardInfo";
import { toMoney } from "../../utils/helpers";
import { useHistory } from "react-router-dom";
import { grey } from "@mui/material/colors";
import Loader from "react-loader-spinner";
import { MdCheck } from "react-icons/md";
import { toast } from "react-toastify";
import mapBg from "../../assets/images/map-bg.svg";
const analytics = window?.analytics;

const shipmentModalClass = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -30%)",
  width: 900,
  height: 620,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const trackShipment = async (
  dataArray,
  handleTrackingShipments,
  handleGetShipmentTrackingOperation,
  setStage,
  setPercentCount,
  setTrackProgress,
  toast
) => {
  let progressData = null;

  setStage(1);
  try {
    const { data } = await handleTrackingShipments(dataArray);
    progressData = data;
    setStage(2);
  } catch (error) {
    setStage(0);
    toast.error("Error initiating tracking");
    return;
  }

  const pollForProgress = async () => {
    try {
      const { data } = await handleGetShipmentTrackingOperation(progressData?.trackShipments?.operationId);

      if (data?.shipmentTrackingOperation?.percentComplete !== 100) {
        setPercentCount(data?.shipmentTrackingOperation?.percentComplete);
        setTimeout(() => pollForProgress(), 2000);
        return;
      }

      setTrackProgress(data?.shipmentTrackingOperation);
    } catch (error) {
      setStage(0);
      toast.error("Error getting tracking result");
    }
  };

  await pollForProgress();
};

export default function SelectShipmentsToTrack({ open, handleClose }) {
  const userCredits = useSelector((state) => state?.data?.userCredits);
  const shipmentList = useSelector((state) => state.data.parsedShipments);
  const { handleTrackingShipments, handleGetShipmentTrackingOperation } = useTracking();
  const { handleGetUserCreditInformation } = useDashboardInfo();
  const dispatch = useDispatch();
  const history = useHistory();
  const [trackProgress, setTrackProgress] = React.useState(null);
  const [stage, setStage] = React.useState(0);
  const [percentCount, setPercentCount] = React.useState(0);
  const user = useSelector((state) => state.user.userData);

  const theme = useTheme();

  const hasExpiredAction = () => {
    analytics?.track("Insufficient Credits", {
      username: user?.email || "",
      message: "User has insufficient tracking credit",
    });
    dispatch(
      setShowNotification({
        isOpen: true,
        message: [
          "Oops you have insufficient tracking credit.",
          "Upgrade to a higer plan to enjoy more trackings and features.",
        ],
        severity: "warning",
        title: "Insufficient credits",
        actionText: "subscribe",
        action: () => history.push("/dashboard/subscription/subscribe"),
      })
    );
  };

  const expiredComponent = () => (
    <React.Fragment>
      <div className="row-4212">
        <p>Oops!!! Access to this feature requires an active subscription</p>
        <p>Unused credits are frozen, please subscribe to gain access.</p>
      </div>
      <Stack direction={"row"} spacing={2} sx={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={() => {
            history.push("/dashboard/subscription/subscribe");
            dispatch(setShowNotification(null));
          }}
          size="small"
        >
          Subscribe
        </Button>
        <Button color="error" onClick={() => dispatch(setShowNotification(null))} variant="contained" size="small">
          Cancel
        </Button>
      </Stack>
    </React.Fragment>
  );

  React.useEffect(() => {
    handleGetUserCreditInformation();
  }, []);

  const startTrackingItems = async (type, index) => {
    let dataArray = [];

    if (type === "single") {
      let {
        billOfLading,
        containerNumber,
        shippingLine,
        clearingAgent,
        transporter,
        commodityDescription,
        externalReferenceNumber,
        commodityCode,
        shipmentAdviceReceptionDate,
        fcl,
        fclType,
        customsCommand,
        draftDocumentsReceptionDate,
        originalDocumentsReceptionDate,
        paarSubmissionDate,
        paarCopyReceptionDate,
        sgdNumber,
        sgdDate,
        assessmentReceptionDate,
        dutyAssessmentNumber,
        invoiceNumber,
        invoiceQuantity,
        blValue,
        formMValue,
        formMApprovalDate,
        formMNumber,
        formMExpiryDate,
        baNumber,
        bankName,
        customsDutyExcludingVAT,
        vat,
        customsDutyIncludingVAT,
        dutyRate,
        dutyExchangeRate,
        dutyPaymentDate,
        hscode,
        nafdacCharges,
        quarantineCharges,
        sonCharges,
      } = shipmentList[index];

      dataArray.push({
        billOfLading,
        containerNumber,
        shippingLine,
        clearingAgent,
        transporter,
        commodityDescription,
        externalReferenceNumber,
        commodityCode,
        shipmentAdviceReceptionDate,
        fcl,
        fclType,
        customsCommand,
        draftDocumentsReceptionDate,
        originalDocumentsReceptionDate,
        paarSubmissionDate,
        paarCopyReceptionDate,
        sgdNumber,
        sgdDate,
        assessmentReceptionDate,
        dutyAssessmentNumber,
        invoiceNumber,
        invoiceQuantity,
        blValue: toMoney(blValue, "USD"),
        formMValue: toMoney(formMValue),
        formMApprovalDate,
        formMNumber,
        formMExpiryDate,
        baNumber,
        bankName,
        customsDutyExcludingVAT: toMoney(customsDutyExcludingVAT),
        vat: toMoney(vat),
        customsDutyIncludingVAT: toMoney(customsDutyIncludingVAT),
        dutyRate,
        dutyExchangeRate,
        dutyPaymentDate,
        hscode,
        nafdacCharges: toMoney(nafdacCharges),
        quarantineCharges: toMoney(quarantineCharges),
        sonCharges: toMoney(sonCharges),
      });

      trackShipment(
        dataArray,
        handleTrackingShipments,
        handleGetShipmentTrackingOperation,
        setStage,
        setPercentCount,
        setTrackProgress,
        toast
      );

      analytics?.track("Start Tracking", {
        method: "Excel Upload",
        container: containerNumber,
        BL: billOfLading,
        ShippingLine: shippingLine,
        username: user?.email || "",
      });

      let updatedList = [...shipmentList];
      updatedList[index] = { ...shipmentList[index], status: "loading" };

      dispatch(setParsedShipments(updatedList));
    } else if (type === "all") {
      if (shipmentList.length >= 5) {
        dispatch(
          setShowNotification({
            isOpen: true,
            message:
              "Tracking is in progress. The shipment page will reflect the updates, and you will be notified via email of the overall status.",
            type: "info",
          })
        );
      }

      let dataArray = shipmentList.map((shipData) => {
        let {
          billOfLading,
          containerNumber,
          shippingLine,
          clearingAgent,
          transporter,
          commodityDescription,
          externalReferenceNumber,
          commodityCode,
          shipmentAdviceReceptionDate,
          fcl,
          fclType,
          customsCommand,
          draftDocumentsReceptionDate,
          originalDocumentsReceptionDate,
          paarSubmissionDate,
          paarCopyReceptionDate,
          sgdNumber,
          sgdDate,
          assessmentReceptionDate,
          dutyAssessmentNumber,
          invoiceNumber,
          invoiceQuantity,
          blValue,
          formMValue,
          formMApprovalDate,
          formMNumber,
          formMExpiryDate,
          baNumber,
          bankName,
          customsDutyExcludingVAT,
          vat,
          customsDutyIncludingVAT,
          dutyRate,
          dutyExchangeRate,
          dutyPaymentDate,
          hscode,
          nafdacCharges,
          quarantineCharges,
          sonCharges,
        } = shipData;

        return {
          billOfLading,
          containerNumber,
          shippingLine,
          clearingAgent,
          transporter,
          commodityDescription,
          externalReferenceNumber,
          commodityCode,
          shipmentAdviceReceptionDate,
          fcl,
          fclType,
          customsCommand,
          draftDocumentsReceptionDate,
          originalDocumentsReceptionDate,
          paarSubmissionDate,
          paarCopyReceptionDate,
          sgdNumber,
          sgdDate,
          assessmentReceptionDate,
          dutyAssessmentNumber,
          invoiceNumber,
          invoiceQuantity,
          blValue: toMoney(blValue, "USD"),
          formMValue: toMoney(formMValue),
          formMApprovalDate,
          formMNumber,
          formMExpiryDate,
          baNumber,
          bankName,
          customsDutyExcludingVAT,
          vat: toMoney(vat),
          customsDutyIncludingVAT: toMoney(customsDutyIncludingVAT),
          dutyRate,
          dutyExchangeRate,
          dutyPaymentDate,
          hscode,
          nafdacCharges: toMoney(nafdacCharges),
          quarantineCharges: toMoney(quarantineCharges),
          sonCharges: toMoney(sonCharges),
        };
      });

      trackShipment(
        dataArray,
        handleTrackingShipments,
        handleGetShipmentTrackingOperation,
        setStage,
        setPercentCount,
        setTrackProgress,
        toast
      );

      analytics?.track("Start Tracking", {
        method: "Excel Upload",
        container: "",
        BL: "",
        ShippingLine: "",
        Status: "",
        username: user?.email || "",
      });

      let updatedShipments = shipmentList.map((shipData) => ({ ...shipData, status: "loading" }));
      dispatch(setParsedShipments(updatedShipments));
    }
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {stage === 0 ? (
          <Box sx={shipmentModalClass}>
            <div className="select-shipments-container">
              <div className="flex flex-row justify-end cursor-pointer close">
                <AiOutlineClose onClick={handleClose} size="20" />
              </div>
              <div className="flex flex-row justify-between">
                <div className="page-title font-semibold">Shipments</div>
                <div>
                  {shipmentList.length > 0 && (
                    <button
                      className="pry-btn"
                      onClick={() => {
                        if (userCredits * 1 >= shipmentList.length) {
                          startTrackingItems("all");
                        } else {
                          hasExpiredAction();
                        }
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Track all</span>
                    </button>
                  )}
                </div>
              </div>

              {shipmentList.length === 0 && (
                <div className="empty">
                  <div className="icon">
                    <RiFileWarningLine style={{ margin: "10px auto" }} size="100" color="#fb8b23" />
                  </div>
                  <div className="text">BL uploaded is empty!</div>
                </div>
              )}
              <Grid container sx={{ mt: 2 }}>
                <Grid item sm={3}>
                  <div className="upload-title">Bill&nbsp;of&nbsp;Laden</div>
                </Grid>
                <Grid item sm={3}>
                  <div className="upload-title">Container&nbsp;Number</div>
                </Grid>
                <Grid item sm={3}>
                  <div className="upload-title">Shipping&nbsp;Line</div>
                </Grid>
                <Grid item sm={3}>
                  <div className="upload-title"> </div>
                </Grid>
              </Grid>
              {shipmentList.length > 0 && (
                <div className="list-items" style={{ overflow: "scroll" }}>
                  {shipmentList.map((item, index) => (
                    <Accordion key={index} elevation={4}>
                      <AccordionSummary>
                        <Grid container>
                          <Grid item sm={3}>
                            <div className="upload-text">{item.billOfLading}</div>
                          </Grid>
                          <Grid item sm={3}>
                            <div className="upload-text">{item.containerNumber}</div>
                          </Grid>
                          <Grid item sm={3}>
                            <div className="upload-text">{item.shippingLine}</div>
                          </Grid>
                          <Grid item sm={3}>
                            {item.status == "ready" || item.status === "loading" ? (
                              <LoadingButton
                                className="pry-btn "
                                variant="contained"
                                sx={{
                                  textTransform: "capitalize",
                                  color: "#FFFFFF",
                                  backgroundColor: "#033B6C",
                                  maxHeight: "3.2rem",

                                  "&:hover": {
                                    opacity: 0.9,
                                    backgroundColor: "#033B6C",
                                  },
                                }}
                                onClick={() => {
                                  if (userCredits * 1 >= 1) {
                                    startTrackingItems("single", index);
                                  } else {
                                    hasExpiredAction();
                                  }
                                }}
                                loading={item.status === "loading"}
                              >
                                <span style={{ fontSize: "14px" }}>Start Tracking</span>
                              </LoadingButton>
                            ) : item?.success ? (
                              <>
                                <div className="title text-center">
                                  <TiTick
                                    className="text-center"
                                    color="orange"
                                    style={{
                                      fontSize: "1rem",
                                      textAlign: "center",
                                      margin: "auto",
                                      verticalAlign: "end",
                                    }}
                                  />
                                </div>
                                <div
                                  className="flex line justify-center"
                                  style={{ fontSize: "12px", textAlign: "center", color: "orange", mx: "auto" }}
                                >
                                  in-progress
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="title text-center">
                                  <TiTimes
                                    className="text-center"
                                    color="red"
                                    style={{ fontSize: "1rem", textAlign: "center", margin: "auto" }}
                                  />
                                </div>
                                <div
                                  className="flex line justify-center"
                                  style={{ fontSize: "12px", textAlign: "center", color: "rgb(220 38 38)" }}
                                >
                                  {item?.message || "Error"}
                                </div>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ backgroundColor: grey[100] }}>
                        <div className="excel-bd">
                          <Stack>
                            <div className="upload-title">Bill&nbsp;of&nbsp;Laden</div>
                            <div className="upload-text">{item.billOfLading}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Container&nbsp;Number</div>
                            <div className="upload-text">{item.containerNumber}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Shipping&nbsp;Line</div>
                            <div className="upload-text">{item.shippingLine}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Clearing&nbsp;Agent</div>
                            <div className="upload-text">{item.clearingAgent}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Transporter</div>
                            <div className="upload-text">{item?.transporter || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Commodity&nbsp;Description</div>
                            <div className="upload-text">{item?.commodityDescription || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">External&nbsp;Reference&nbsp;№</div>
                            <div className="upload-text">{item?.externalReferenceNumber || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Commodity Code</div>
                            <div className="upload-text">{item?.commodityCode || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Shipment Advice Reception Date</div>
                            <div className="upload-text">{item?.shipmentAdviceReceptionDate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">FCL</div>
                            <div className="upload-text">{item?.fcl || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">FCL Type</div>
                            <div className="upload-text">{item?.fclType || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Customs Command</div>
                            <div className="upload-text">{item?.customsCommand || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Draft Document Reception Date</div>
                            <div className="upload-text">{item?.draftDocumentsReceptionDate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Original Document Reception Date</div>
                            <div className="upload-text">{item?.originalDocumentsReceptionDate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">PAAR Submission Date</div>
                            <div className="upload-text">{item?.paarSubmissionDate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">PAAR Copy Reception Date</div>
                            <div className="upload-text">{item?.paarCopyReceptionDate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">SDG Number</div>
                            <div className="upload-text">{item?.sgdNumber || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">SDG Date</div>
                            <div className="upload-text">{item?.sgdDate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Assessment Reception Date</div>
                            <div className="upload-text">{item?.assessmentReceptionDate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Duty Assessment Number</div>
                            <div className="upload-text">{item?.dutyAssessmentNumber || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Invoice Number</div>
                            <div className="upload-text">{item?.invoiceNumber || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Invoice Qty</div>
                            <div className="upload-text">{item?.invoiceQuantity || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">BL Value</div>
                            <div className="upload-text">{item?.blValue || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Form M Number</div>
                            <div className="upload-text">{item?.formMNumber || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Form M Value</div>
                            <div className="upload-text">{item?.formMValue || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Form M Approval Date</div>
                            <div className="upload-text">{item?.formMApprovalDate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">BA Number</div>
                            <div className="upload-text">{item?.baNumber || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Bank Name</div>
                            <div className="upload-text">{item?.bankName || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Custom Duty excl. VAT</div>
                            <div className="upload-text">{item?.customsDutyExcludingVAT || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">VAT</div>
                            <div className="upload-text">{item?.vat || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Duty Rate</div>
                            <div className="upload-text">{item?.dutyRate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Duty Exchange Rate</div>
                            <div className="upload-text">{item?.dutyExchangeRate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Duty Payment Date</div>
                            <div className="upload-text">{item?.dutyPaymentDate || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">HS CODE</div>
                            <div className="upload-text">{item?.hscode || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">NAFDAC Charges</div>
                            <div className="upload-text">{item?.nafdacCharges || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">Quarantine Charges</div>
                            <div className="upload-text">{item?.quarantineCharges || "N/A"}</div>
                          </Stack>
                          <Stack>
                            <div className="upload-title">SON Charges</div>
                            <div className="upload-text">{item?.sonCharges || "N/A"}</div>
                          </Stack>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              )}
            </div>
          </Box>
        ) : (
          <Box
            maxWidth="550px"
            width="90%"
            height="95%"
            bgcolor="white"
            p={2}
            borderRadius={0.5}
            sx={{
              backgroundImage: "url(" + mapBg + ")",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            {!trackProgress ? (
              <Box>
                {stage >= 1 && (
                  <Box display="flex" alignItems="center" gap={1}>
                    {stage > 1 ? (
                      <MdCheck size={20} color={theme.palette.success.main} />
                    ) : (
                      <Loader type="ThreeDots" color={theme.palette.primary.main} height={20} width={20} />
                    )}
                    <Typography variant="body1" fontSize={"1rem"}>
                      Initiating tracking
                    </Typography>
                  </Box>
                )}

                {stage >= 2 && (
                  <Box display="flex" alignItems="center" gap={1} mt="1rem">
                    {stage > 2 ? (
                      <MdCheck size={20} color={theme.palette.success.main} />
                    ) : (
                      <Loader type="ThreeDots" color={theme.palette.primary.main} height={20} width={20} />
                    )}
                    <Typography variant="body1" fontSize={"1rem"}>
                      Getting tracking result...{percentCount}%
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Box overflow="scroll !important">
                <Box border="1px solid #033B6C" p="1rem" borderRadius=".5rem">
                  <Typography>Success: {trackProgress.numSuccesses}</Typography>
                  <Typography>Failure: {trackProgress.numFailures}</Typography>
                  <Typography>Percentage: {trackProgress.percentComplete}%</Typography>
                  <Typography>Status: {trackProgress.status}</Typography>
                </Box>

                {trackProgress?.items?.map((item, idx) => (
                  <Box key={idx} my="1rem" border="1px solid #033B6C" p="1rem" borderRadius=".5rem">
                    <Typography>
                      BL / Container: {item.billOfLading} / {item.containerNumber}
                    </Typography>
                    <Typography>Shipping Line: {item.shippingLine}</Typography>
                    <Typography textTransform="capitalize">Failure: {item.failureReason}</Typography>
                  </Box>
                ))}
              </Box>
            )}

            <Grid container spacing={1} mt=".5rem">
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    handleClose();
                    history.push("/dashboard/shipments");
                  }}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Modal>
    </div>
  );
}

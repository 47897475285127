import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CostTable from "../../components/CostTable/CostTable";
import useTracking from "../../hooks/useTracking";
import Loader from "../../components/Loader/Loader";
import { TiWarningOutline } from "react-icons/ti";
import { AiOutlineReload } from "react-icons/ai";
import writeXlsxFile from "write-excel-file";
import { saveAs } from "file-saver";
import { formatDate } from "../../utils/helpers";
import { setCostEstimates , setTablePreference } from "../../redux/data/data.actions";
import { MdDownload } from "react-icons/md";
import { Fab } from "@mui/material";

export default function Costs() {
  const { handleGetCostEstimates, handleGetBill } = useTracking();
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedBill, setSelectedBill] = useState(null);
  const [forecastEndDate, setForecastEndDate] = useState(null);
  const [forecastError, setForecastError] = useState(null);
  const [sumTotal, setSumtotal] = useState("0");
  const [containers, setContainers] = useState([]);
  const costEstimates = useSelector((state) => state.data.costEstimates);
  const [data, setData] = useState(useSelector((state) => state.data.costEstimates));
  const [pageNo, setPageNo] = useState(0);
  const bill = useSelector((state) => state.data.bills);
  const dispatch = useDispatch();
  const [serviceTypeTemp, setServiceTypeTemp] = useState(null);
  const [providerTemp, setProviderTemp] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    if (event.target.value != tablePreference) {
      dispatch(setTablePreference(event.target.value));
    }
    setPage(0);
  };

  const setForecastEndDateValid = (date) => {
    if (date) {
      setForecastEndDate(date);
      setForecastError("");
    }
  };

  const estimateForeCast = (date = forecastEndDate) => {
    if (date) {
      handleGetCostEstimates(formatDate(date));
      dispatch(setCostEstimates("loading"));
    } else {
      setForecastError("Prediction End date is required");
    }
  };

  const calculateSumtotal = (innerData = data) => {
    let total = {};

    innerData.forEach((estimate) => {
      estimate?.cost?.forEach((cost) => {
        if (!total[cost?.currency] && total[cost?.currency] != 0) {
          total[cost?.currency] = 0;
        }
        total[cost?.currency] += cost?.total?.amount > 0 ? cost?.total?.amount : 0;
      });
    });

    let totalString = "";

    for (let currency in total) {
      totalString += `${currency} ${total[currency].toLocaleString("en-US")}, `;
    }

    setSumtotal(totalString ? totalString.substring(0, totalString.length - 2) : "0");
    return total;
  };

  useEffect(() => {
    document.title = `aTrace - Visibility - Costs`;
    if (costEstimates === "loading") {
      handleGetCostEstimates();
      handleGetBill();
    }
  }, []);

  useEffect(() => {
    if (costEstimates !== "loading") {
      setData(costEstimates);
      setContainers([
        ...new Set([...costEstimates.map((estimate) => estimate?.container)].sort((a, b) => a.localeCompare(b))),
      ]);
      handleFilterCosts(selectedBill, selectedServiceType, selectedContainer, selectedProvider);
    }
  }, [costEstimates]);

  const tablePreference = useSelector((state) => state.data.tablePreference);

  useEffect(() => {
    setRowsPerPage(tablePreference);
  }, [tablePreference]);

  const handleFilterCosts = (bill, type, container, provider) => {
    let newCost = costEstimates.map((costEstimate) => {
      let cost = costEstimate?.cost?.filter(
        (currentCost) =>
          (type === null ||
            type === "" ||
            type === "default" ||
            currentCost?.serviceType?.toLowerCase() === type?.toLowerCase()) &&
          (provider === null ||
            provider === "" ||
            provider === "default" ||
            currentCost?.provider?.name?.toLowerCase() === provider?.toLowerCase() ||
            currentCost?.provider?.code?.toLowerCase() === provider?.toLowerCase())
      );
      return { ...costEstimate, cost };
    });

    let finalCost = newCost.filter(
      (estimate) =>
        estimate?.cost?.length > 0 &&
        (container === null ||
          container === "" ||
          container === "default" ||
          estimate?.container?.toLowerCase() === container?.toLowerCase()) &&
        (bill === null ||
          bill === "" ||
          bill === "default" ||
          estimate?.billOfLading?.toLowerCase() === bill?.toLowerCase())
    );
    setData([...finalCost]);
    calculateSumtotal(finalCost);
    setPageNo(0);
  };

  const handleRefresh = () => {
    setSelectedContainer("default");
    setSelectedProvider("default");
    setSelectedServiceType("default");
    setSelectedBill("default");
    setForecastEndDate(null);
    handleFilterCosts("default", "default", "default", "default");
  };

  const downloadExcel = async () => {
    const exceldataheader = [
      [
        {
          value: "Container",
          fontWeight: "bold",
          rowSpan: 2,
          align: "center",
        },
        {
          value: "Container Type",
          fontWeight: "bold",
          rowSpan: 2,
          align: "center",
        },
        {
          value: "Provider",
          fontWeight: "bold",
          rowSpan: 2,
          align: "center",
        },
        {
          value: "ServiceType",
          fontWeight: "bold",
          rowSpan: 2,
          align: "center",
        },
        {
          value: "Events",
          fontWeight: "bold",
          span: 2,
          align: "center",
        },
        null,
        {
          value: "Dates",
          fontWeight: "bold",
          span: 2,
          align: "center",
        },
        null,
        {
          value: "FreeTime",
          fontWeight: "bold",
          align: "center",
        },

        {
          value: "slab 1",
          fontWeight: "bold",
          span: 3,
          align: "center",
        },
        null,
        null,
        {
          value: "slab 2",
          fontWeight: "bold",
          span: 3,
          align: "center",
        },
        null,
        null,
        {
          value: "slab 3",
          fontWeight: "bold",
          span: 3,
          align: "center",
        },
        null,
        null,
        {
          value: "slab 4",
          fontWeight: "bold",
          span: 3,
          align: "center",
        },
        null,
        null,
        {
          value: "Total",
          fontWeight: "bold",
          span: 3,
          align: "center",
        },
        null,
        null,
      ],
      [
        null,
        null,
        null,
        null,
        {
          value: "Start Event",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "End Event",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Start date",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "End date",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Days",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Days",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Rate",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Total",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Days",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Rate",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Total",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Days",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Rate",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Total",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Days",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Rate",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Total",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Days",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Amount",
          fontWeight: "bold",
          align: "center",
        },
        {
          value: "Currency",
          fontWeight: "bold",
          align: "center",
        },
      ],
    ];

    const columns = [
      {
        width: 16,
      },
      {
        width: 8,
      },
      {
        width: 21,
      },
      {
        width: 16,
      },
      {
        width: 45,
      },
      {
        width: 45,
      },
      {
        width: 12,
      },
      {
        width: 12,
      },
      {
        width: 12,
      },
      {
        width: 12,
      },
      {
        width: 16,
      },
      {
        width: 16,
      },
      {
        width: 12,
      },
      {
        width: 16,
      },
      {
        width: 16,
      },
      {
        width: 12,
      },
      {
        width: 16,
      },
      {
        width: 16,
      },
      {
        width: 12,
      },
      {
        width: 16,
      },
      {
        width: 16,
      },
      {
        width: 10,
      },
      {
        width: 16,
      },
      {
        width: 9,
      },
    ];

    const Exceldata = data
      .map((estimate) =>
        estimate?.cost.map((cost) => [
          {
            value: estimate?.container ? estimate?.container : "",
          },
          {
            value: `${estimate?.containerSize ? estimate?.containerSize : "-"}/${
              estimate?.containerType ? estimate?.containerType : "-"
            }`,
          },
          {
            value: cost?.provider ? cost?.provider?.name : "",
          },
          {
            value: cost?.serviceType ? cost?.serviceType : "",
          },
          {
            value: cost?.events?.[0]?.name ? cost?.events?.[0]?.name : "",
          },
          {
            value: cost?.events?.[1]?.name ? cost?.events?.[1]?.name : "",
          },
          {
            value: cost?.events?.[0]?.date ? cost?.events?.[0]?.date : "",
          },
          {
            value: cost?.events?.[1]?.date ? cost?.events?.[1]?.date : "",
          },
          {
            value: cost?.freeTime?.days && cost?.freeTime?.days > 0 ? cost.freeTime?.days : 0,
          },
          {
            value: cost?.slabs?.[0]?.days && cost?.slabs?.[0]?.days > 0 ? cost?.slabs?.[0]?.days : 0,
          },
          {
            value: cost?.slabs?.[0]?.rate && cost?.slabs?.[0]?.rate > 0 ? cost?.slabs?.[0]?.rate : 0,
            type: Number,
            format: "#,##0.00",
          },
          {
            value: cost?.slabs?.[0]?.amount && cost?.slabs?.[0]?.amount > 0 ? cost?.slabs?.[0]?.amount : 0,
            type: Number,
            format: "#,##0.00",
          },
          {
            value: cost?.slabs?.[1]?.days && cost?.slabs?.[1]?.days > 0 ? cost?.slabs?.[1]?.days : 0,
          },
          {
            value: cost?.slabs?.[1]?.rate && cost?.slabs?.[1]?.rate > 0 ? cost?.slabs?.[1]?.rate : 0,
            type: Number,
            format: "#,##0.00",
          },
          {
            value: cost?.slabs?.[1]?.amount && cost?.slabs?.[1]?.amount > 0 ? cost?.slabs?.[1]?.amount : 0,
            type: Number,
            format: "#,##0.00",
          },
          {
            value: cost?.slabs?.[2]?.days && cost?.slabs?.[2]?.days > 0 ? cost?.slabs?.[2]?.days : 0,
          },
          {
            value: cost?.slabs?.[2]?.rate && cost?.slabs?.[2]?.rate > 0 ? cost?.slabs?.[2]?.rate : 0,
            type: Number,
            format: "#,##0.00",
          },
          {
            value: cost?.slabs?.[2]?.amount && cost?.slabs?.[2]?.amount > 0 ? cost?.slabs?.[2]?.amount : 0,
            type: Number,
            format: "#,##0.00",
          },
          {
            value: cost?.slabs?.[3]?.days && cost?.slabs?.[3]?.days > 0 ? cost?.slabs?.[3]?.days : 0,
          },
          {
            value: cost?.slabs?.[3]?.rate && cost?.slabs?.[3]?.rate > 0 ? cost?.slabs?.[3]?.rate : 0,
            type: Number,
            format: "#,##0.00",
          },
          {
            value: cost?.slabs?.[3]?.amount && cost?.slabs?.[3]?.amount > 0 ? cost?.slabs?.[3]?.amount : 0,
            type: Number,
            format: "#,##0.00",
          },
          {
            value: cost?.total?.days && cost?.total?.days > 0 ? cost?.total?.days : 0,
          },
          {
            value: cost?.total?.amount && cost?.total?.amount > 0 ? cost?.total?.amount : 0,
            type: Number,
            format: "#,##0.00",
          },
          {
            value: cost?.currency ? cost?.currency : "",
          },
        ])
      )
      ?.reduce((all, curr) => [...all, ...curr], []);

    let result = await writeXlsxFile([...exceldataheader, ...Exceldata], {
      columns,
    });
    saveAs(result, "cost.xlsx");
  };

  return (
    <div className="">
      {costEstimates === "loading" && <Loader />}

      {costEstimates && costEstimates !== "loading" && (
        <>
          <CostTable
            {...{
              setSelectedProvider,
              selectedProvider,
              setSelectedContainer,
              selectedContainer,
              setSelectedBill,
              selectedBill,
              setSelectedServiceType,
              selectedServiceType,
              data,
              handleFilterCosts,
              sumTotal,
              containers,
              bill,
              forecastEndDate,
              setForecastEndDateValid,
              estimateForeCast,
              forecastError,
              serviceTypeTemp,
              setServiceTypeTemp,
              providerTemp,
              setProviderTemp,
              //pagination data
              page,
              setPage,
              rowsPerPage,
              handleChangePage,
              handleChangeRowsPerPage,
            }}
          />
          <Fab
            color="primary"
            aria-label="add"
            onClick={downloadExcel}
            sx={{
              position: "sticky",
              left: { xs: 10, md: 0 },
              bottom: { xs: 78, md: 0 },
              opacity: 0.85,
              "&:hover": {
                opacity: 0.95,
              },
            }}
          >
            <MdDownload />
          </Fab>
        </>
      )}

      {((data && data.length === 0) || data === null) && (
        <div className="no-data">
          <div className="icon">
            <TiWarningOutline color="#fb8b23" style={{ margin: "10px auto" }} size="50" />
          </div>
          <div className="text">No Cost Estimates found! </div>
          {!(
            (selectedBill === "" || selectedBill === "default") &&
            (selectedContainer === "" || selectedContainer === "default") &&
            (selectedProvider === "" || selectedProvider === "default") &&
            (selectedContainer === "" || selectedContainer === "default")
          ) && (
            <div
              className="refresh-button"
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "500",
                color: "#fb8b23",
                cursor: "pointer",
              }}
              onClick={handleRefresh}
            >
              <span style={{ margin: "auto 4px" }}>
                <AiOutlineReload color="#fb8b23" />
              </span>
              Refresh
            </div>
          )}
        </div>
      )}
    </div>
  );
}

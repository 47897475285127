import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton , ListItemIcon } from "@mui/material";
import { useHistory } from "react-router-dom";
import { CgMoreO } from "react-icons/cg";
import { MdAdd, MdDelete, MdEdit, MdPreview } from "react-icons/md";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import useTracking from "../../hooks/useTracking";
export default function MoreMenu({ trackingId, showEventMenu, containers, billOfLading, isActive }) {
  const history = useHistory();
  const { handleDeleteTracking } = useTracking();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <CgMoreO color="#033b6c" />
      </IconButton>
      <Menu
        dense
        id={`trackingId-${trackingId}-menu`}
        aria-labelledby="more menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          elevation: 1,
          sx: {
            borderColor: "#033b6c",
            borderWidth: "2px",
            "&:after": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 25,
              right: -6,
              width: 12,
              height: 12,
              bgcolor: "#393939",
              borderWidth: "2px",
              borderColor: "#033b6c",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 800,
            },
          },
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            history.push(`/dashboard/tracking/trackingid/${trackingId}`);
          }}
          inset
        >
          <ListItemIcon>
            <MdPreview color="#033b6c" />
          </ListItemIcon>
          View Tracking
        </MenuItem>
        <MenuItem
          onClick={() => {
            showEventMenu(billOfLading, containers);
            handleClose();
          }}
          inset
        >
          <ListItemIcon>
            <MdAdd color="#033b6c" />
          </ListItemIcon>
          Add Event
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            history.push(`/dashboard/edit/shipment/${containers}/?active=${isActive}`);
          }}
          inset
        >
          <ListItemIcon>
            <MdEdit color="#033b6c" />
          </ListItemIcon>
          Edit
        </MenuItem>

        <ConfirmationButton
          severity="primary"
          desc={"Are you sure you want to delete this Tracking?"}
          onConfirm={() => {
            handleClose();
            handleDeleteTracking(trackingId);
          }}
          ButtonElement={(props) => (
            <MenuItem {...props} inset>
              <ListItemIcon>
                <MdDelete color="#ff1000" />
              </ListItemIcon>
              Delete
            </MenuItem>
          )}
        />
      </Menu>
    </div>
  );
}
